import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LoadingOverlay from 'components/common/LoadingOverlay';
import useRunFeedSourceDatabaseCodesQA from 'hooks/RunFeedSourceDatabaseCodesQA';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'absolute',
        width: '100%',
        padding: '14px 12px',
    },
    QAItem: {
        display: 'flex'
    },
    QAItemLabel: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginRight: '18px',
        width: 150
    },
    QAItemValue: {
    }
}));

const convertToSeconds = (milliseconds, showMiliSeconds = true) => {
    if (!milliseconds) return ''
    const _seconds = Math.round(milliseconds / 1000 * 100) / 100;
    if (!showMiliSeconds) {
        return `${_seconds}s`
    }
    return `${milliseconds}ms`
}
export default function StockDatabaseCodeQA(props) {
    const classes = useStyles();
    const { feedSource } = props
    const dispatch = useDispatch();
    const [triggerDatabaseCodeQA, { isLoading, qaResult }] = useRunFeedSourceDatabaseCodesQA(dispatch);

    useEffect(() => {
        triggerDatabaseCodeQA(feedSource)
    }, [
        feedSource
    ])
    
    return (
        <Box style={{ overflow: 'auto', height: '100%' }} className={classes.root}>
            <LoadingOverlay isLoading={isLoading}>
                <Box className={classes.QAItem}>
                    <Box className={classes.QAItemLabel}>Database Counts:</Box>
                    <Box className={classes.QAItemValue}>{qaResult.rowCount}</Box>
                </Box>
                <Box className={classes.QAItem}>
                    <Box className={classes.QAItemLabel}>Execution Time:</Box>
                    <Box className={classes.QAItemValue}> { (qaResult.executionTime/1000).toFixed(2)}s ({qaResult.executionTime}ms)</Box>
                </Box>
            </LoadingOverlay>
        </Box>
    );
}
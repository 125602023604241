import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    ListBox: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 12
    }
}))

export default useStyles
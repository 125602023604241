import React from 'react';
import { makeStyles } from "@mui/styles";

import {
  Grid,
  Box,
} from "@mui/material";
import clsx from "clsx";
import CustomizedTooltip from "../CustomizedTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px'
  },
  chartList: {
    width: '100%',
    //   paddingTop: '10px',
    //   paddingLeft: '10px',
    padding: '0',
    margin: '0',
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'wrap'
  },
  item: {
    cursor: 'pointer',
    margin: 4,
    width: 70,
    height: 70,
    fontSize: '20px',
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all ease-in .2s',
    '& a': {
      color: 'black'
    },
    '&.selected': {
      boxShadow: '0px 0px 3px -1px black',
      // background: '#2196f3',
      '& a': {
        color: 'white'
      }
    },
    '& > img': {
      width: 48,
      '&.Rotated90Deg': {
        transform: 'rotate(90deg)'
      }
    },
    '& > svg': {
        fontSize: '44px'
    },
    '&:hover': {
      transform: 'scale(1.2)'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ChartSidebar = (props) => {
  const { selectedChartType, onSelectChartType, chartTypes } = props
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item lg={12} md={12} xs={12}>
        <Box className={classes.BorderedBox}>
          <ul className={classes.chartList}>
            {
              chartTypes.map((item,index) => {
                return <li key={index} className={clsx(classes.item, selectedChartType === item.value && 'selected')} onClick={() => onSelectChartType(item.value)}>
                  {/*<Tooltip title={item.label} arrow>*/}
                  {/*  {*/}
                  {/*    item.svg ?*/}
                  {/*      <img alt={item.label} src={item.svg}/> :*/}
                  {/*      item.icon ?*/}
                  {/*        item.icon() :*/}
                  {/*        item.label*/}
                  {/*  }*/}
                  {/*</Tooltip>*/}
                  <CustomizedTooltip
                    title={
                      <React.Fragment>
                        <Box color="inherit" sx={{ fontWeight: 'bold' }}>{ item.label }</Box>
                        <Box>{item.description}</Box>
                      </React.Fragment>
                    }
                  >
                    {
                      item.svg ?
                        <img alt={item.label} src={item.svg}/> :
                        item.icon ?
                          item.icon() :
                          item.label
                    }
                  </CustomizedTooltip>
                </li>
              })
            }
          </ul>
        </Box>
      </Grid>
      {/*<Grid item lg={12} md={12} xs={12}>*/}
      {/*  <Button onClick={() => {*/}
      {/*    onUpdate(xAxis, yAxis)*/}
      {/*  }} variant="contained">Save</Button>*/}
      {/*</Grid>*/}
    </Grid>
  )
};

export default ChartSidebar

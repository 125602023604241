import React, { useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogActions,
    DialogContent,
    lighten,
    Grid,
    Button,
    Box,
} from "@mui/material";
import { useDispatch } from 'react-redux';
import {
    stopFrontendQAProcess,
} from 'store/slices/FrontendQA';
import { Typography } from 'antd';
import LoadingOverlay from 'components/common/LoadingOverlay';
import Pie from 'views/qa/PieChart';
import SuccessCheck from 'views/qa/components/SuccessCheck';

const useStyles = makeStyles((theme) => ({
    root: {
        // padding: 0,
        position: 'relative',
        height: '200px',
    },
    loadingBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1000,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: "#8080800a"
        background: "white"
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    },
    Txt: {
        color: 'black'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    BorderedBox: {
        display: 'flex',
        flexFlow: 'column',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        minHeight: '100px',
        height: '100%',
        padding: '10px',
        maxHeight: '500px',
        overflow: 'auto',
        paddingBottom: '35px'
    },
    ListItemText: {
        fontSize: 14
    },
    ListItemButton: {
        padding: '5px 11px'
    },
    selectedLine: {
        background: '#dfdfdf'
    },

    InputField: {
        // width: 'auto',
        '& input': {
            fontSize: '12px',
            // width: '182px',
            padding: '6px',
            marginBottom: 0
        }
    },
    score: {
        fontSize: '20px'
    },
    LoadingOverlay: {
        '& > div': {
            zIndex: 1000
        }
    },
    BatchConfirmBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '#ffffffed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column'
    },
    BatchconfirmBoxText: {
        fontSize: '18px',
        marginBottom: '27px'
    }
}));

const formatTimeDifference = (timeDifference) => {
    // Convert to hours and minutes
    const totalDiff = timeDifference / 1000
    const hours = Math.floor(totalDiff / 3600);
    const restMinutes = Math.floor((totalDiff % 3600) / 60);

    if (hours > 0) {
        if (restMinutes > 0) {
            return `${hours}:${restMinutes} h`
        }
        return `${hours} h`
    } else {
        const minutes = Math.round((totalDiff / 60));
        if (minutes > 0) {
            return `${minutes} mins`
        }
    }
    return '0h'
}

// const formatTimeDifference = (timeDifference) => {
//     // Convert to hours and minutes
//     var hours = Math.floor((timeDifference / 1000) / 3600);
//     var minutes = Math.round(((timeDifference / 1000) / 60));
//     var secs = Math.round(((timeDifference / 1000)));
//     console.log(hours, minutes, secs, 'secs')

//     if(hours > 0){
//         if(minutes > 0){
//             return `${hours}:${minutes} h`
//         }
//         return `${hours} h`
//     }else{
//         if(minutes > 0){
//             return `${minutes} mins`
//         }
//     }
//     return '0h'
// }

const ProgressPopupDialog = (props) => {
    const {
        open,
        handleClose,
        expectTime: initExpectTime = 0,
        apiRequest = () => {}
    } = props

    const classes = useStyles();
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [score, setScore] = useState(0)

    const onClose = () => {
        if (loading) return;
        handleClose()
    }
    const [progressVal, setProgressVal] = useState(0)
    const [consumedTime, setConsumedTime] = useState(0)
    const [expectTime, setInitExpectTime] = useState(initExpectTime)

    const startClock = () => {
        setLoading(true);
        let _interConsumedTime = 0;
        const intervalId = setInterval(() => {
          _interConsumedTime += 1000
          setConsumedTime(_interConsumedTime);
          // Calculate progress based on consumed time and expect time
          const newProgress = (_interConsumedTime / expectTime) * 100;
          if(newProgress + 100 < _interConsumedTime){
            setProgressVal(newProgress); 
          }
          console.log(_interConsumedTime)
          console.log(newProgress, 'newProgress')
    
          // Check if consumed time exceeds expect time
          if (_interConsumedTime > (expectTime + 100)) {
            clearInterval(intervalId);
            // setLoading(false);
            // Handle completion or timeout
          }
        }, 1000); // Update every second
      };
    useEffect(() => {
        startClock()
        apiRequest().then(r => {
            clearInterval();
            handleClose()
        })
        .catch(e => {
            console.log(e, 'e')
        })
        return () => {
            // Clear any ongoing intervals when the component unmounts
            clearInterval();
          };
    }, [
    ])


    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                className={classes.DialogContent}
                maxWidth="md"
            >
                <DialogContent>
                    <LoadingOverlay className={classes.LoadingOverlay} isLoading={loading}>
                        <Grid container spacing={1}>
                            <Grid item lg={12} md={12} xs={12} className={'mt-2'}>
                                <Box className={classes.root}>
                                    {
                                        loading && (
                                            <Box className={classes.loadingBox} sx={{ flexFlow: 'column' }} >
                                                <Box>
                                                    <Pie
                                                        percentage={progressVal}
                                                        colour="blue"
                                                        time={`${formatTimeDifference(consumedTime)}/${formatTimeDifference(expectTime)}`}
                                                    />
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    {
                                        (!loading && score > 0) && (
                                            <Box className={classes.loadingBox}>
                                                <Box>
                                                    <SuccessCheck />
                                                    {/* <Typography>Successfully Done!</Typography> */}
                                                    <Typography className={classes.score}>{score} Score</Typography>
                                                    <Typography color=''>{
                                                        score >= 90 ? "The product code performance is good." :
                                                            score >= 70 ? "The product code performance is average." :
                                                                "The product code performance needs improvement."
                                                    }</Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </LoadingOverlay>
                </DialogContent>
                <DialogActions>
                    {
                        loading && <Button variant='contained' color='error' onClick={() => {
                            dispatch(stopFrontendQAProcess())
                            handleClose();
                        }}>Stop</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
};

ProgressPopupDialog.propTypes = {
};

export default ProgressPopupDialog
import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit';
import { paramsToQueryParam } from 'store/UrlUtils';
// import { FETCH_ALL_MESSAGES_LANGUES_BY_LANGUE } from 'store/UrlConstants';

const appMessageAdapter = createEntityAdapter({});

// export const fetchAllMessagesLanguesByLangue = createAsyncThunk('MessagesSysteme/MessagesLangues/GetAllByLangue', async (langue) => {
//   const response = await axios.get(FETCH_ALL_MESSAGES_LANGUES_BY_LANGUE, { params: { langue: langue } });
//   return response.data;
// })

const initialState = appMessageAdapter.getInitialState(
{
    messages: [],
    mode: 0,
    show: false,
    msgId: null,
    title: null,
    message: null,
    buttons: [],
    onClick: null,
    duration: 6000,
    severity: "info"
});

function setState (state, action, mode) {
    let chg = {...initialState, ...action.payload}
    if (state.messages && chg.msgId) {
      var index = state.messages.findIndex(e => e.id === chg.msgId);
      if (index >= 0) {
        chg.message = state.messages[index].value
      }
    }
    state.mode = mode;
    state.show = true;
    state.msgId = chg.msgId;
    state.message = chg.message;
    if (mode === 1) {
      // showMessage
      state.duration = chg.duration;
      state.severity = chg.severity;    
    } else {
      // showDialog
      if (chg.buttons.length === 0){
        chg.buttons = Object.assign([], chg.buttons)
        chg.buttons.push('ok')
      }
      state.buttons = chg.buttons;
      state.onClick = chg.onClick;       
    }
  
};

const appMessageSlice = createSlice({
    name: "appMessage",
    initialState,
    reducers: {
      showMessage: (state, action) =>  {
        setState(state, action, 1);
      },    
      showDialog: (state, action) =>  {
        setState(state, action, 2);
      },    
      hideMessage: (state, action) =>  {
        state.show = false;
        state.msgId = null;
        state.message = null;
      },            
    },
    extraReducers: {
        // [fetchAllMessagesLanguesByLangue.fulfilled]: (state, action) => {
        //   state.messages = action.payload
        // }
    }
});

export const { showMessage, showDialog, hideMessage } = appMessageSlice.actions  

export default appMessageSlice.reducer;

import { useState } from 'react';
import { getFeedSourceDatabaseCodesQA } from 'store/slices/FrontendQA';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';

const useRunFeedSourceDatabaseCodesQA = (dispatch, callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [qaResult, setQAResult] = useState({})

    const errorBlock = (error) => {
        dispatch(showSnackbarMessage({
            message: 'Error is happened in running Database Codes QA process',
            severity: 'error'
        }))
        setQAResult({})
        console.log(error, 'in the run databasecodes qa hook')
    }

    const triggerRequest = async (feedSource) => {
        if (isLoading) return
        setIsLoading(true)
        const { payload: qaData } = await dispatch(getFeedSourceDatabaseCodesQA({feedSource}))
        setIsLoading(false)
        console.log(qaData, 'qaData')
        if (!(qaData)) {
            errorBlock()
            return
        };
        setQAResult(qaData)
    };

    return [triggerRequest,  {
        isLoading,
        qaResult,
    }];
};

export default useRunFeedSourceDatabaseCodesQA;
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { withRouter } from "react-router";

import { 
  Box
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Alert from '@mui/lab/Alert';

import HighNonMarketChart from "./Chart";
import CustomFormSelect from "components/common/Base/CustomFormSelect";

import _ from 'underscore'
import { TypeChooser } from "libs/rrag/lib/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    padding: '10px'
  }
}));

function NonMarketContainer(props) {
  const classes = useStyles();
  const { data } = props
  const { feedSource, databaseCode, productCode } = useParams()
  const [columns, setColumns] = useState([])
  const [rows, setRows] = useState([])
  
  // const chartType = nonMarket['chartType'];
  
  // if(!(chartType && xAxis && yAxis)){
  //   return <Alert severity="info">Please check Values of Object, Chart Type and Axis of Tool Bar!</Alert>
  // }

  useEffect(() => {
    const _columns = []
    const _rows = []
    if(Array.isArray(data) && data[0]){
        Object.keys(data[0]).forEach(item => {
            _columns.push({
                id: item,
                disablePadding: true,
                // isDate: data[0][item].value ? isDate(data[0][item].value) : isDate(data[0][item]),
                label: item.split('_').join(' ').toUpperCase(),
                disabled: item === 'nId',
                visible: item !== 'nId'
            })
        })
        data.forEach(item => {
            const _newItem = {}
            _columns.forEach(col => {
                if(item[col.id] && item[col.id].value !== undefined){
                    _newItem[col.id] = item[col.id].value
                }else{
                    _newItem[col.id] = item[col.id]
                }
            })
            _rows.push(_newItem)
        })
        setRows([..._rows])
    }
    setColumns([..._columns])
  }, [data])
  return (
    <Box className={classes.root}>
        <HighNonMarketChart
          rows={rows}
          columns={columns}
          path={`${feedSource}/${databaseCode}/${productCode}`}
        />
    </Box>
  );
};

export default NonMarketContainer
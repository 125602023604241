export const isDate = (val) => {
    let flag = false
    if (isNaN(val)) {
        flag = !isNaN(Date.parse(val))
    }
    return flag
}
export const filterRecordsWithFullYear = (arr, filterKey, year, whereField = null, whereValue = null) => {
    const results = arr.filter(obj => {
        const objYear = new Date(obj[filterKey]).getUTCFullYear();
        if (objYear !== parseInt(year)) {
            return false;
        }
        if (whereField !== null && whereValue !== null) {
            return obj[whereField] === whereValue;
        }
        return true
    });
    return results
}

export const filterWithFullYear = (arr, filterKey, year, whereField = null, whereValue = null) => {
    const result = arr.reduce((acc, obj) => {
        const objYear = new Date(obj[filterKey]).getUTCFullYear();
        if (objYear === parseInt(year)) {
            if (whereField !== null && whereValue !== null) {
                if (obj[whereField] === whereValue) {
                    return obj;
                }
            } else {
                return obj;
            }
        }
        return acc;
    }, {});
    return result;
}

export const filterWithYearQuarter = (arr, filterKey, year, quarter, whereField = null, whereValue = null) => {
    const results = arr.filter((item) => {
        const itemDate = new Date(item[filterKey]);
        const itemYear = itemDate.getUTCFullYear();
        const itemQuarter = Math.floor((itemDate.getUTCMonth() / 3)) + 1;

        if (!(itemYear == parseInt(year) && itemQuarter === parseInt(quarter))) {
            return false
        }
        if (whereField !== null && whereValue !== null) {
            return item[whereField] === whereValue;
        }
        return true
    });
    return results[0] || {}
}

export const filterWithYearMonth = (arr, filterKey, year, month, whereField = null, whereValue = null) => {
    const results = arr.filter(item => {
        const date = new Date(item[filterKey]);
        if (!(date.getUTCFullYear() === parseInt(year) && date.getUTCMonth() === parseInt(month) - 1)) {
            return false
        }
        if (whereField !== null && whereValue !== null) {
            return item[whereField] === whereValue;
        }
        return true
    });
    return results[0] || {}
}

export const toCapitalizeStr = (str) => {
    let capitalizedStr = str.trim()
    if (isNaN(capitalizedStr.charAt(0))) {
        return capitalizedStr.charAt(0).toUpperCase() + capitalizedStr.slice(1);
    }
    return capitalizedStr
}

export const sortData = (data, fieldName = 'id', additionalSortKey = null) => {
    /** Sort by Number and Text */
    return data.sort((a, b) => {
        let aChar = a[fieldName]
        let bChar = b[fieldName]
        if (aChar[0] === '_') aChar = aChar.substring(1)
        if (bChar[0] === '_') bChar = bChar.substring(1)
        const numA = parseInt(aChar);
        const numB = parseInt(bChar);

        if (!isNaN(numA) && !isNaN(numB)) {
            // Both are numbers, sort by value
            return numA - numB && (a.additionalSortKey || additionalSortKey) - (b.additionalSortKey || additionalSortKey);
        } else if (isNaN(numA) && isNaN(numB)) {
            // Both are non-numbers, sort alphabetically
            return aChar.localeCompare(bChar) && (a.additionalSortKey || additionalSortKey) - (b.additionalSortKey || additionalSortKey);
        } else {
            // One is a number, sort it first
            return (isNaN(numA) ? 1 : -1) && (a.additionalSortKey || additionalSortKey) - (b.additionalSortKey || additionalSortKey);
        }
    });
}

export const customizeData = (data, dateField) => {
    const _rows = []
    const _columns = []
    if (Array.isArray(data) && data[0]) {
        Object.keys(data[0]).forEach(item => {
            _columns.push({
                id: item,
                disablePadding: true,
                // isDate: data[0][item].value ? isDate(data[0][item].value) : isDate(data[0][item]),
                label: item.split('_').join(' ').toUpperCase(),
                disabled: item === 'nId',
                visible: item !== 'nId'
            })
        })
        data.forEach(item => {
            const _newItem = {}
            _columns.forEach(col => {
                if (item[col.id] && item[col.id].value !== undefined) {
                    _newItem[col.id] = item[col.id].value
                } else if (Array.isArray(item[col.id])) {
                    _newItem[col.id] = item[col.id][0]
                } else {
                    _newItem[col.id] = item[col.id]
                }
            })
            _rows.push(_newItem)
        })
    }
    const years = [...new Set(_rows.map(item => new Date(item[dateField]).getUTCFullYear()))]

    return {
        rows: _rows,
        years,
    }
}
import { useState } from 'react';
import { fetchFrontendQAResults } from 'store/slices/FrontendQA';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';

const useFetchQAResults = (dispatch, callback = () => {}) => {
    const [qaResults, setQAResults] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchQaResults = (params) => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            dispatch(fetchFrontendQAResults({...params})).then((r) => {
                if(r.payload){
                    setQAResults(r.payload)
                    callback()
                    setLoading(false)
                }
                resolve(r)
            }).catch((e) => {
                setLoading(false)
                reject(e)
            })
        })
    }

    return [fetchQaResults, {
        qaResults,
        loading
    }];
};

export default useFetchQAResults;
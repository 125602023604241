import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import { onAuthStateChanged, getAuth } from "firebase/auth";
import { setUserData } from "store/slices/UserSlice";

import LocalStorageService from 'store/services/LocalStorageService';
const storageService = LocalStorageService.getService()
const auth = getAuth();

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const [currentUser, setCurrentUser] = useState(null)

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user)
                dispatch(setUserData(user))
                storageService.setDefaultGoogleUser(user.email)
                if(user.uid) storageService.setPlotterUID(user.uid)
                if(user.accessToken) storageService.setAccessToken(user.accessToken)
            } else {
                setCurrentUser(null)
                dispatch(setUserData({}))
                storageService.clearPlotterUID()
                storageService.clearAccessToken()
                if(!['/demo', '/login', '/qa-login'].includes(window.location.pathname)) {
                    return history.push('/login');
                }
            }
        });
    }, [currentUser]);

    return (
        <AuthContext.Provider value={{}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider

export const chartSettings = {
    bls: {
       ap: {
           x: 'year',
           y: 'value'
       },
       bd: {
           x: 'year',
           y: 'value'
       },
       bg: {
           x: 'year',
           y: 'value'
       },
       bp: {
           x: 'year',
           y: 'value'
       },
       cc: {
           x: 'year',
           y: 'value'
       },
       cd: {
           x: 'year',
           y: 'value'
       },
       ce: {
           x: 'year',
           y: 'value'
       },
       cf: {
           x: 'year',
           y: 'value'
       },
       ch: {
           x: 'year',
           y: 'value'
       },
       ec: {
           x: ['begin_year', 'end_year'],
           y: 'value'
       }
    },
    fiscal_data: {
        fiscal_data: {
            x: 'record_date',
            y: [
                {
                    operator: 'ends_with',
                    options: ['_amt', '_bal']
                }
            ],
            entity: [
                {
                    operator: 'ends_with',
                    options: ['_nm', '_type', '_desc']
                },
                {
                    operator: 'contains',
                    options: ['_type_']
                }
            ], 
        }
    },
    bea: {
        nipa: {
            x: 'timeperiod',
            y: 'datavalue'
        },
        niunderlyingdetail: {
            x: 'timeperiod',
            y: 'datavalue'
        },
        fixedassets: {
            x: 'timeperiod',
            y: 'datavalue'
        },
        amne: {
            x: 'year',
            y: 'datavalue'
        },
        gdpbyindustry: {
            x: 'year',
            y: 'datavalue'
        },
        ita: {
            x: 'timeperiod',
            y: 'datavalue'
        },
        iip: {
            x: 'timeperiod',
            y: 'datavalue'
        },
        inputoutput: {
            x: 'year',
            y: 'datavalue'
        },
        underlyinggdpbyindustry: {
            x: 'year',
            y: 'datavalue'
        },
        intlservtrade: {
            x: 'timeperiod',
            y: 'datavalue'
        },
        regional: {
            x: 'timeperiod',
            y: 'datavalue'
        }
    },
    econoday: {
        events: {
            x: 'year',
            y: 'uid'
        }
    },
    quandl: {
        default: {
            x: 'reading_date',
            y: 'value',
        },
        sge: {
            x: 'date',
            y: 'value'
        },
        'sharadar/actions': {
            x: 'date',
            y: 'value'
        },
        'sharadar/sf1': {
            x: 'calendardate',
            y: 'assets',
            entity: 'dimension'
        },
        'sharadar/daily': {
            x: 'date',
            y: 'ev',
            entity: 'ticker'
        },
        // 'sharadar/events': {
        //     x: 'date',
        //     y: 'eventcodes',
        //     y_divider: '|'
        // },
        'umich': {
            x: ['date', 'reading_date'],
            y: 'relative'
        },
        fred: {
            x: 'reading_date',
            y: 'value'
        }
    },
    url: {
        default: {
            x: 'date',
            y: 'positive'
        },
        bing_covid19_data: {
            x: 'updated',
            y: 'confirmed'
        },
        git: {
            x: 'start',
            y: 'favor'
        },
        covidtracking_all_states_history: {
            x: 'date',
            y: 'death' 
        },
        covidtracking_historic_values_all_states: {
            x: 'date',
            y: 'death'
        },
        nyt_live_us_states: {
            x: 'date',
            y: 'confirmed_deaths'
        },
        senate_polls: {
            x: 'start_date',
            y: 'pct'
        },
        covidtracking_historic_us: {
            x: 'date',
            y: 'positive'
        },
        jhu_csse_covid19_dailyreports_us: {
            x: 'last_update',
            y: 'deaths',
            // entity: 'province_state'
        },
        nyt_excess_deaths: {
            x: {
                type: 'individuals',
                configs: [
                    {
                        start_date: 'year',
                        month: 'month',
                        day: 'start_date',
                    },
                    {
                        defaultDate: 'start_date'
                    }
                ]
            },
            y: 'deaths'
        },
        nyt_live_us_counties: {
            x: 'date',
            y: 'confirmed_deaths'
        },
        owid_covid: {
            x: 'date',
            y: 'total_cases'
        },
        generic_congressional_ballot_polls: {
            x: 'start_date',
            y: 'dem'
        },
        gubernatorial_polls: {
            x: 'start_date',
            y: 'pct'
        },
        house_polls:{
            x: 'start_date',
            y: 'pct'
        },
        presidential_general_polls:{
            x: 'start_date',
            y: 'pct'
        },
        presidential_approval_polls:{
            x: 'start_date',
            y: 'pct'
        },
        presidential_primary_polls:{
            x: 'start_date',
            y: 'pct'
        },
        senate_polls:{
            x: 'start_date',
            y: 'pct'
        }
    }
}

/*  1. For now, Y Axis and X Axis fields are not being fetched correctly, cause they are being determined based on 1st record of returned
    2. If no-matched fields, it should be draw without EntityField settings 
    - example http://localhost:3000/product/detail/FISCALDATA/FiscalData/Debt%20to%20the%20Penny/j_cloud#
    3. I think there should be a way to draw chart without entity field. Some proudcts doesn't need entity_field -> Refer `quandl/sge`, `quandl/sharadar/daily`
    4. sharadar/events databasecodes has got such json format 
    {
        "ticker": "AAOI",
        "date": "2016-08-04",
        "eventcodes": "22|91"
    }
*/
import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import Chart from 'kaktana-react-lightweight-charts'
import moment from 'moment'

import MainSeries from './Series/MainSeries'

const TradingViewDrawer = (props) =>  {
    const {
        chartType,
        indicatorOpts: {
          ma = {},
          macd = {},
        },
      } = useSelector(state => state.lightWeightChartData)

    const { data: initialData } = props
    const [data, setData] = useState([])
    const [chartLayoutOptions, setChartLayoutOptions] = useState({
        alignLabels: true,
        timeScale: {
            rightOffset: 12,
            barSpacing: 3,
            fixLeftEdge: true,
            lockVisibleTimeRangeOnResize: true,
            rightBarStaysOnScroll: true,
            borderVisible: false,
            borderColor: "#fff000",
            visible: true,
            timeVisible: true,
            secondsVisible: false
        }
    })

    const formatData = useCallback(() => {
        let _data = initialData?.map(item => {
          return {
            time: moment(item.reading_date).format('YYYY-MM-DD'),
            open: parseFloat(item.open),
            low: parseFloat(item.low),
            close: parseFloat(item.close),
            high: parseFloat(item.high),
            volume: parseFloat(item.volume)
          }
        })
        setData([..._data])
      }, [initialData])

    useEffect(() => {
        formatData(initialData)
    }, [formatData])
  
    return (
        <>
        {
            data.length > 0 && (
                <MainSeries
                    data={data}
                    chartType={chartType}
                    layoutOptions={chartLayoutOptions}
                    ma={ma}
                />
            )
        }
        </>
    )
}

export default TradingViewDrawer
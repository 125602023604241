import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import _ from "underscore"
import { useLocation } from 'react-router-dom';

import { makeStyles } from "@mui/styles";
// import AnalyzeView from '../Analyze';
import MarketContainer from './Market';
import NonMarketContainer from './NonMarket';
import BigQueryNonMarketContainer from './BigqueryNonMarket';

import utils from "shared/utilities/product"
import { chartSettings } from './NonMarket/settings'
import {
    initiTimeseriesData,
    setChartOpt,
    setFeedSourceType,
    setLoadedFeedSourceType
} from 'store/slices/ChartDataSlice'
import {
    setXAxisFields,
    setYAxisFields,
    setXAxis,
    setYAxis,
    setSelectedChartLines,
    setSelectedChartTimeIncrement,
} from 'store/slices/StockProductSlice'
import { useDispatch, useSelector } from 'react-redux';
import { ProductContext } from 'providers/product/ProductContext';
import { Box, IconButton } from '@mui/material';
import { setFieldConfig } from 'store/slices/HighChartDataSlice';
import ChartConfigDialog from './Components/ChartConfigDialog';
import { Settings } from '@mui/icons-material';
import BEAChartConfigDialog from './Components/BEAChartConfigDialog';
import { selectBEAProductDataByPath } from 'store/selectors/ProductData';


const TIMESEARIES_SOURCES = ['BLS', 'BOE', 'BEA', 'BARCHART', 'BCB', 'BP', 'CFTC', 'HKEX', 'RATEINF', 'ML', 'FISCAL_DATA', 'OECD', 'MULTPL', 'EIA', 'USTREASURY', 'ECONODAY', 'QUANDL', 'URL', 'FRED', 'BATS', 'YC', 'QOR', 'ZILLOW', 'SGE', 'ODA', 'FINRA', 'WB', 'WASDE', 'TIMESEARIES_SOURCES']

const isDate = (val) => {
    let flag = false
    if (isNaN(val)) {
        flag = !isNaN(Date.parse(val))
    }
    return flag
}

const deDuplicate = (res) => {
    let list = []
    let deDuplicated = []
    let _data = []
    if (res.data) {
        _data = res.data
    } else _data = [...res]
    _data.forEach(element => {
        if (!list.includes(JSON.stringify(element))) {
            deDuplicated.push(element)
            list.push(JSON.stringify(element))
        }
    })
    return deDuplicated
}

const formatToChartable = (value) => {
    let _value = value
    if (_value == null) {
        return _value
    }
    if (typeof _value === 'object' && _value.hasOwnProperty('value')) {
        _value = _value.value
    }
    if (_value == null) {
        return _value
    }
    if (!isNaN(_value)) {
        return +_value
    }
    if (!isNaN(Date.parse(_value))) {
        const fDate = new Date(_value)
        return [fDate.getFullYear(), fDate.getMonth() + 1, fDate.getDate()].join('-')
    }
    return _value
}

const accountForCommonFields = (item) => {
    if (item.year) {
        const fDate = new Date(item.year.toString())
        item.year = [fDate.getFullYear(), fDate.getMonth() + 1, fDate.getDate()].join('-')
    }
    if (item.Year) {
        const fDate = new Date(item.Year.toString())
        item.Year = [fDate.getFullYear(), fDate.getMonth() + 1, fDate.getDate()].join('-')
    }
    if (item.Date) {
        const sDate = new Date(item.Date)
        item.Date = [sDate.getFullYear(), sDate.getMonth() + 1, sDate.getDate()].join('-')
    }
}

const formatStock = (data) => {
    const _data = JSON.parse(JSON.stringify(data))
    _data.forEach((d, i) => {
        let sDate = null
        if (d.reading_date) {
            sDate = new Date(d.reading_date)
        } else if (d.timestamp) {
            sDate = new Date(d.timestamp)
        } else if (d.date) {
            sDate = new Date(d.date)
        }
        d.date = sDate
        d.close = +d.close
        d.open = +d.open
        d.high = +d.high
        d.low = +d.low
        d.volume = +d.volume
        d.absoluteChange = ""
        d.dividend = ""
        d.percentChange = ""
        d.split = ""
    })
    return _data
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
}))


function ChartGraphView(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const productDataContext = useContext(ProductContext)
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookmarkedXAxis = searchParams.get('xAxis');
    const bookmarkedYAxis = searchParams.get('yAxis');
    const bookmarkedTimeIncrement = searchParams.get('timeIncrement');
    const bookmarkedChartLine = searchParams.get('line');
    let {
        selectedDataOpt,
        data = [],
        feedSourceType,
        chartLibrary,
        timeRange,
        productCode,
        feedSource,
        databaseCode,
        identifier,
        isBeaDatabaseCode
    } = props
    const [formattedStocks, setFormattedStocks] = useState({})
    const [fields, setFields] = useState([])
    const productData = useSelector(state => state.productData)
    const productResource = useSelector((state) => selectBEAProductDataByPath(state, {
        feedSource,
        databaseCode,
        productCode,
        selectedDataOpt
    }))

    const [openSettingDialog, setOpenSettingDialog] = useState(false)

    const loadDefaultSettings = () => {
        let settings = {}
        let extraConfiguration = {}
        if (TIMESEARIES_SOURCES.indexOf(feedSource.toUpperCase()) > -1) {
            let defaultSettings = utils.getSafe(() => chartSettings[feedSource.toLowerCase()][decodeURIComponent(databaseCode).toLowerCase()], null)
            if (!defaultSettings) {
                defaultSettings = utils.getSafe(() => chartSettings[feedSource.toLowerCase()]['default'], null)
            }
            if (defaultSettings) {
                settings.hasDefault = true
                if (defaultSettings.x instanceof Array) settings.defaultX = defaultSettings.x
                else if (typeof defaultSettings.x === 'object') {
                    if (defaultSettings.x.type === 'individuals') {
                        extraConfiguration = {
                            xType: 'customized',
                            xConfiguration: [...defaultSettings.x.configs]
                        }
                        settings.defaultX = 'customized'
                    } else {
                        settings.xConfig = defaultSettings.x
                    }
                }
                else if (defaultSettings.x !== undefined) settings.defaultX = defaultSettings.x

                if (defaultSettings.y instanceof Object) settings.yConfig = defaultSettings.y
                else if (defaultSettings.y !== undefined) settings.defaultY = defaultSettings.y

                if (defaultSettings.entity instanceof Object) settings.entityConfig = defaultSettings.entity
                else if (defaultSettings.entity !== undefined) settings.defaultEntityField = defaultSettings.entity

            }
        }
        return {
            settings,
            extraConfiguration
        }
    }

    const evaluateSettings = (settings, name, value) => {
        if (!settings.defaultX && settings.xConfig && value && value !== 'null') {
            settings.defaultX = utils.evaluateDefaultSetting(settings.xConfig, name, value)
        }
        if (!settings.defaultY && settings.yConfig && value && value !== 'null') {
            settings.defaultY = utils.evaluateDefaultSetting(settings.yConfig, name, value)
        }
        if (!settings.defaultEntityField && settings.entityConfig) {
            settings.defaultEntityField = utils.evaluateDefaultSetting(settings.entityConfig, name, value)
        }
        return settings
    }

    const updateSettings = useCallback((settings) => {
        const { defaultX: _xAxis, defaultY: _yAxis, defaultEntityField, defaultEntityVal } = settings
        if (_xAxis) {
            dispatch(setChartOpt({
                path: 'timeseries.xAxis',
                value: _xAxis
            }))
            // props.setXAxis(Array.isArray(defaultX) ? defaultX[0] : defaultX)
        }
        if (_yAxis) {
            dispatch(setChartOpt({
                path: 'timeseries.yAxis',
                value: _yAxis
            }))
        }
        // console.log(_xAxis, _yAxis)
        if (chartLibrary === 'rrag') {
            if (defaultEntityField) {
                dispatch(setChartOpt({
                    path: 'timeseries.entityField',
                    value: defaultEntityField
                }))
                if (defaultEntityVal) {
                    dispatch(setChartOpt({
                        path: 'timeseries.checked',
                        value: [
                            defaultEntityVal
                        ]
                    }))
                    dispatch(setChartOpt({
                        path: 'timeseries.tableEntityId',
                        value: [
                            defaultEntityVal
                        ]
                    }))
                }
            }
        } else if (chartLibrary === 'highchart' && _xAxis) {
            dispatch(setFieldConfig({
                path: `${feedSource}/${databaseCode}/${productCode}`,
                configs: {
                    xAxis: _xAxis,
                    yAxis: _yAxis
                }
            }))
        }
    }, [
        chartLibrary,
        feedSource,
        databaseCode,
        productCode
    ])

    const updateNonMarketSettings = (res, settings) => {
        if (Array.isArray(res) && res.length > 0) {
            const { timeseries } = props.chartData
            if (!settings.hasDefault) {
                if (timeseries['entityField'] !== null && !(timeseries['entityField'] in res[0])) {
                    dispatch(setChartOpt({
                        path: 'timeseries.entityField',
                        value: null
                    }))
                }
                // if (timeseries['xAxis'] !== null && !(timeseries['xAxis'] in res[0])) {
                //     props.setChartOpt({
                //         path: 'timeseries.xAxis',
                //         value: null
                //     })
                // }
                // if (timeseries['yAxis'] !== null && !(timeseries['yAxis'] in res[0])) {
                //     props.setChartOpt({
                //         path: 'timeseries.yAxis',
                //         value: null
                //     })
                // }
            }
        }
    }

    const formatNonMarket = (initials, options = {}) => {
        const res = [];
        const { xType, xConfiguration } = options;
        if (Array.isArray(initials)) {
            initials.map(item => {
                const _record = {}
                if (xType && xType === 'customized') {
                    let customizedDate = null
                    for (let xConfig of xConfiguration) {
                        if (customizedDate !== null) break;
                        if (xConfig.defaultDate) {
                            if (new Date(item[xConfig.defaultDate]).toDateString() !== 'Invalid Date') {
                                customizedDate = utils.parsePotentialData(item[xConfig.defaultDate])
                            }
                        } else if (xConfig.start_date) {
                            const year = new Date(item[xConfig.start_date])
                            if (year.toDateString() !== 'Invalid Date') {
                                if (isNaN(item[xConfig.day])) {
                                    if (new Date(item[xConfig.day]).toDateString() !== 'Invalid Date') {
                                        customizedDate = utils.parsePotentialData(item[xConfig.day])
                                    }
                                } else {
                                    customizedDate = [year.getFullYear(), item[xConfig.month], item[xConfig.day]].join('-');
                                }
                            }
                        }
                    }
                    if (customizedDate && new Date(customizedDate).toDateString() !== 'Invalid Date') {
                        _record.customized = customizedDate;
                    } else {
                        _record.customized = undefined;
                    }
                }
                for (let key in item) {
                    _record[key.toLocaleLowerCase()] = item[key]
                }
                res.push(_record)
            })
        }
        return res;
    }

    const formatBeaMarket = useCallback((initials) => {
        const _rows = []
        if (productData.selectedChartLines && productData.selectedChartLines?.length > 0 && productData.selectedChartTimeIncrement !== null) {
            console.log('passed')
            let _selectedYears = []
            if (productData.selectedYears?.length < 1 && productResource.years.length > 0) {
                _selectedYears = productResource.years.slice(0, 5)
            }
            if (Array.isArray(initials)) {
                const _initialData = initials?.filter(d => productData.selectedChartLines.indexOf(d.id) > -1 || productData.selectedChartLines.indexOf(d.line) > -1)
                _initialData.map(item => {
                    if (productData.selectedChartTimeIncrement === 'Q') {
                        _selectedYears.map(y => {
                            for (let q = 1; q <= 4; q++) {
                                // Calculate the month based on the quarter
                                var month = (q - 1) * 3;

                                const _record = {
                                    [item.id]: item[`year_${y}_q${q}`] || '',
                                    date: new Date(y, month, 1)
                                }
                                _rows.push(_record)
                            }
                        })
                    } else if (productData.selectedChartTimeIncrement === 'M') {
                        _selectedYears.map(y => {
                            for (let m = 1; m <= 12; m++) {
                                const _record = {
                                    [item.id]: item[`year_${y}_m${m}`] || '',
                                    date: new Date(y, m, 1)
                                }
                                _rows.push(_record)
                            }
                        })
                    // } else if (productData.selectedChartTimeIncrement === 'A') {
                    } else {
                        _selectedYears.map(y => {
                            const _record = {
                                [item.id]: item['annual_' + y] || '',
                                date: new Date(y, 0)
                            }
                            _rows.push(_record)
                        })
                    }
                })
            }
        }
        console.log(_rows.length, 'count of _rows ')
        return _rows
    }, [
        productData.selectedChartTimeIncrement,
        productData.selectedChartLines,
        productData.selectedLinesData,
        productData.selectedYears,
        productResource.years
    ])

    const formatData = async () => {
        let verifiedX = null, verifiedY = null;
        let res = [];
        if (!(formattedStocks[identifier] && formattedStocks[identifier].length > 0)) {
            let initialData = deDuplicate(JSON.parse(JSON.stringify(data)))
            // dispatch(initiTimeseriesData())
            setFields([])
            if (feedSourceType === 'stock') {
                res = formatStock(initialData);
            } else {
                if (isBeaDatabaseCode) {
                    res = formatBeaMarket(initialData)
                } else {
                    let { settings, extraConfiguration } = loadDefaultSettings()
                    res = formatNonMarket(initialData, extraConfiguration);
                    let defaultX, defaultY, yDivider = settings.yDivider, defaultEntityField = null, defaultEntityVal = null
                    let isEvaluated = false
                    res.map((d, i) => {
                        /** Customize the data into chart format, and get the verified X & Y based on default settings*/
                        for (let field in d) {
                            d[field] = formatToChartable(d[field])
                            if (!isEvaluated) {
                                isEvaluated = true
                                settings = evaluateSettings(settings, field, d[field])
                                defaultX = settings.defaultX
                                defaultY = settings.defaultY
                            }
                        }
                        if (!verifiedX && defaultX) {
                            if (Array.isArray(defaultX)) {
                                for (let dx of defaultX) {
                                    if (d[dx] !== undefined) {
                                        verifiedX = dx
                                        break;
                                    }
                                }
                            } else if (d[defaultX] !== undefined) {
                                verifiedX = defaultX
                            }
                        }
                        if (!verifiedY && defaultY) {
                            if (Array.isArray(defaultY)) {
                                for (let dy of defaultY) {
                                    if (d[dy] !== undefined) {
                                        verifiedY = dy
                                        break;
                                    }
                                }
                            } else if (d[defaultY] !== undefined) {
                                verifiedY = defaultY
                            }
                        }

                        /** Customize Data based on verified X & Y */
                        if (verifiedX) {
                            if (chartLibrary === 'rrag') {
                                if (defaultEntityVal === null && defaultEntityField && !utils.isEmpty(d[defaultEntityField])) {
                                    defaultEntityVal = d[defaultEntityField]
                                }
                            }
                            if (verifiedX !== 'timeperiod') {
                                d[verifiedX] = utils.parsePotentialData(d[verifiedX])
                            } else {
                                let fDate = utils.parsePotentialData(d[verifiedX])
                                if (!(fDate instanceof Date)) {
                                    if (fDate.toString().length > 4) {
                                        fDate = new Date(fDate)
                                    } else {
                                        fDate = new Date(fDate, 0)
                                    }
                                }
                                if (fDate) {
                                    d[verifiedX] = [fDate.getFullYear(), fDate.getMonth() + 1, fDate.getDate()].join('-')
                                }
                            }
                        }
                        if (verifiedY && yDivider && isNaN(d[verifiedY])) {
                            d[verifiedY] = d[verifiedY].split(yDivider)[0]
                        }
                        d.nId = i

                        // Customize data when timeperiod field contains the "M", "Q"
                        if (d.timeperiod) {
                            let fDate
                            if (isNaN(d.timeperiod)) {
                                d.timeperiod = d.timeperiod.toLowerCase()
                                if (d.timeperiod.indexOf('m') > -1) {
                                    let [year, month] = d.timeperiod.split('m')
                                    fDate = new Date(year, month)
                                } else if (d.timeperiod.indexOf('q') > -1) {
                                    let [year, quater] = d.timeperiod.split('q')
                                    if (quater === undefined) quater = 1
                                    fDate = new Date(year, quater * 3 - 3)
                                }
                            } else {
                                fDate = new Date(d.timeperiod.toString())
                            }
                            d.timeperiod = [fDate.getFullYear(), fDate.getMonth() + 1, fDate.getDate()].join('-')
                        }
                        accountForCommonFields(d)
                        return utils.replaceSpace(d)
                    })
                    // updateNonMarketSettings(res, settings)
                    if (verifiedX && verifiedY) {
                        updateSettings({
                            ...settings,
                            defaultX: verifiedX,
                            defaultY: verifiedY
                        })
                    }
                }
            }
            const { setData } = productDataContext
            setData(res)
            if (!formattedStocks) {
                formattedStocks = {}
            }
            formattedStocks[identifier] = [...res]
            setFormattedStocks({
                ...formattedStocks,
                [identifier]: res
            })
        }

        //Update settings
        if (res.length > 0) {
            let xAxisFields = [], yAxisFields = []
            // console.log(res, 'res')
            const { fields: _fields } = utils.attributeTransformer(res)
            // console.log(_fields, '_fields')
            setFields([..._fields])
            xAxisFields = _fields.filter(f => f.type === 'date')
            yAxisFields = _fields.filter(f => f.type === 'number')
            dispatch(setXAxisFields([...xAxisFields]))
            dispatch(setYAxisFields([...yAxisFields]))
            if (feedSourceType !== 'stock') {
                if (fields.length < 1) {
                }
                if(bookmarkedXAxis){
                    dispatch(setXAxis(bookmarkedXAxis))
                }else{
                    if (verifiedX) {
                        dispatch(setXAxis(verifiedX))
                    } else if (xAxisFields[0] && xAxisFields[0].id) {
                        dispatch(setXAxis(xAxisFields[0].id))
                    }
                }
                if(bookmarkedYAxis){
                    dispatch(setYAxis(bookmarkedYAxis.split(',')))
                } else{
                    if (verifiedY) {
                        dispatch(setYAxis([verifiedY]))
                    } else if (yAxisFields[0] && yAxisFields[0].id) {
                        dispatch(setYAxis([yAxisFields[0].id]))
                    }
                }
                if(bookmarkedXAxis && bookmarkedYAxis){
                    if (!(verifiedX && verifiedY) && xAxisFields[0] && yAxisFields[0]) {
                        updateSettings({
                            defaultX: bookmarkedXAxis,
                            defaultY: bookmarkedYAxis.split(',')
                        })
                    }
                }else{
                    if (!(verifiedX && verifiedY) && xAxisFields[0] && yAxisFields[0]) {
                        updateSettings({
                            defaultX: xAxisFields[0].id,
                            defaultY: yAxisFields[0].id
                        })
                    }
                }
            }
        }
    }

    useEffect(() => {
        formatData()
    }, [
        data,
        selectedDataOpt,
        chartLibrary,
        identifier,
        productData.selectedChartLines,
        productData.selectedChartTimeIncrement,
        isBeaDatabaseCode
    ])

    useEffect(() => {
        if(bookmarkedChartLine){
            dispatch(setSelectedChartLines([bookmarkedChartLine]))
        }else{
            if (productResource?.selectedLinesData?.length > 0) {
                dispatch(setSelectedChartLines([productResource.selectedLinesData[0].id]))
            }
        }
        if(bookmarkedTimeIncrement){
            dispatch(setSelectedChartTimeIncrement(bookmarkedTimeIncrement))
        }else{
            if (productResource?.selectedFrequencies?.length > 0) {
                dispatch(setSelectedChartTimeIncrement(productResource.selectedFrequencies[0]))
            }
        }
    }, [
        productResource.selectedLinesData,
        productResource.selectedFrequencies,
        bookmarkedChartLine,
        bookmarkedTimeIncrement
    ])

    const renderGraph = useCallback(() => {
        const { xAxis } = productData
        let filteredData = formattedStocks[identifier] || []
        const xAxisKey = feedSourceType === 'stock' ? 'date' : xAxis
        if (xAxisKey) {
            filteredData.sort((a, b) => {
                if (a[xAxisKey] && b[xAxisKey]) {
                    return new Date(a[xAxisKey]).getTime() - new Date(b[xAxisKey]).getTime()
                }
                return 1;
            })
        }
        if (feedSourceType === 'stock') {
            if (chartLibrary === 'rrag') {
                let today = new Date()
                let startDateTime = today.getTime()
                if (timeRange === '5d') {
                    startDateTime = today.setDate(today.getDate() - 5)
                } else if (timeRange === '1m') {
                    startDateTime = today.setMonth(today.getMonth() - 1)
                } else if (timeRange === '6m') {
                    startDateTime = today.setMonth(today.getMonth() - 6)
                } else if (timeRange === 'ytd') {
                    startDateTime = new Date(today.getFullYear(), 0, 1)
                } else if (timeRange === '1y') {
                    startDateTime = today.setFullYear(today.getFullYear() - 1)
                } else if (timeRange === '5y') {
                    startDateTime = today.setFullYear(today.getFullYear() - 5)
                }
                if (timeRange !== 'max' && xAxisKey !== null) {
                    filteredData = filteredData.filter(i => new Date(i[xAxisKey]).getTime() >= startDateTime)
                    if (filteredData.length < 1) {
                        filteredData = [
                            { [xAxisKey]: new Date(startDateTime) },
                            { [xAxisKey]: today }
                        ]
                    }
                }
            }
        }
        return <>
            {
                feedSourceType === 'stock' ?
                    <MarketContainer
                        data={filteredData}
                        chartLibrary={chartLibrary}
                        timeRange={timeRange}
                        xAxisFields={productData.xAxisFields || []}
                        yAxisFields={productData.yAxisFields || []}
                        xAxis={productData.xAxis}
                        yAxis={productData.yAxis}
                    /> :
                    (
                        selectedDataOpt === 'big-query' ? (
                            <BigQueryNonMarketContainer
                                data={filteredData}
                                chartLibrary={chartLibrary}
                                productCode={productCode}
                                xAxis={productData.xAxis}
                                yAxis={productData.yAxis}
                            />
                        ) : (
                            <NonMarketContainer
                                data={filteredData}
                                chartLibrary={chartLibrary}
                                productCode={productCode}
                                xAxis={productData.xAxis}
                                yAxis={productData.yAxis}
                            />
                        )
                    )

            }
        </>
    }, [
        formattedStocks,
        identifier,
        productData.yAxis,
        productData.xAxis,
        productData.selectedChartLines,
        selectedDataOpt,
    ])
    return (
        <Box id={selectedDataOpt === 'big-query' ? 'BigqueryGraph' : 'CloudStorageGraph'}
            sx={{
                width: '100%',
                flex: 1
            }}
        >
            <Box display='flex' sx={{ justifyContent: 'end' }}>
                <IconButton onClick={() => setOpenSettingDialog(true)}>
                    <Settings />
                </IconButton>
            </Box>
            {
                renderGraph()
            }
            {/* <AnalyzeView fields={fields} data={filteredData} /> */}
            {
                openSettingDialog && (
                    !isBeaDatabaseCode ? <>
                        <ChartConfigDialog
                            xAxisFields={productData.xAxisFields}
                            yAxisFields={productData.yAxisFields}
                            xAxis={productData.xAxis}
                            yAxis={productData.yAxis}
                            open={openSettingDialog}
                            handleClose={() => {
                                setOpenSettingDialog(false)
                            }}
                            onUpdate={(_xAxis, _yAxis) => {
                                dispatch(setXAxis(_xAxis))
                                dispatch(setYAxis([..._yAxis]))
                                // setFormattedStocks({})
                            }}
                        />
                    </> : <>
                        <BEAChartConfigDialog
                            timeIncrements={productData.selectedFrequencies}
                            lines={productResource.selectedLinesData || []}
                            selectedTimeIncrement={productData.selectedChartTimeIncrement}
                            selectedLines={productData.selectedChartLines}
                            open={openSettingDialog}
                            handleClose={() => {
                                setOpenSettingDialog(false)
                            }}
                            onUpdate={(_selectedChartLines, _selectedChartTimeIncrement) => {
                                dispatch(setSelectedChartLines([..._selectedChartLines]))
                                dispatch(setSelectedChartTimeIncrement(_selectedChartTimeIncrement))
                                setFormattedStocks({})
                            }}
                        />
                    </>
                )
            }
        </Box>
    )
}

export default ChartGraphView
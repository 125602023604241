import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from 'react-router-dom'
import {
    Box, Chip, Typography
} from "@mui/material"

import ListItem from '../Details/ListItem';

import { fetchStockProducts, clearStockProducts } from "store/slices/EodDataSlice";
import { fetchSearchData } from 'store/slices/SearchSlice';
import QLink from '../Details/QLink';
import useRunIndividualQA from 'hooks/RunIndividualQA';
import IndividualResultDialog from './IndividualResultDialog';
import ProgressPopupDialog from '../Details/ProgressPopupDialog';
import config from 'config.json'
import { parseDateToLocalFormat } from 'shared/utilities/dateHelpers';
import useFetchQAResults from 'hooks/FetchQAResults';
import { updateFrontendQABatch } from 'store/slices/FrontendQA';

const ListItemComponent = ({ feedSource, item, active, clickHandler, clickQaHandler, setSelected, setHovered, inProgressOfQA = false }) => (
    <Box
        className={`item ${active ? "active" : ""}`}
        onClick={() => clickHandler(item)}
        onMouseEnter={() => setSelected(item)}
        onMouseLeave={() => setHovered(undefined)}
    >
        <ListItem
            selected={active}
        >
            <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
                <Typography flex={1}>
                {
                    ['Barchart', 'ALPACA'].indexOf(feedSource) > -1 ? item.product_code : (item.title ? item.title : item.product_code).replace('EOD/', '')
                }
                </Typography>
                <Box display="flex" sx={{ alignItems: 'center', gap: '2px' }}>
                    <Box display="flex" sx={{ alignItems: 'center', gap: '2px' }}>
                        {
                            item.pass_count && item.pass_count > 0 && <Chip label={item.pass_count} color="primary" size="small" sx={{ fontSize: '10px', height: '17px' }} />
                        }
                        {
                            item.fail_count && item.fail_count > 0 && <Chip label={item.fail_count} color="error" size="small" sx={{ fontSize: '10px', height: '17px' }} />
                        }
                    </Box>
                    <Typography className='text-uppercase' sx={{ fontSize: '11px' }}>{item.last_frontend_qa_date ? parseDateToLocalFormat(item.last_frontend_qa_date) : ''}</Typography>
                    <QLink
                        inProgress={inProgressOfQA}
                        onClick={(e) => {
                            e.stopPropagation();
                            clickQaHandler(item)
                        }}
                    />
                </Box>
            </Box>
            
        </ListItem>
    </Box>
)

const Lists = (props) => {
    const history = useHistory();
    const dispatch = useDispatch()
    const [selected, setSelected] = useState(undefined)
    const [cursor, setCursor] = useState(0)
    const [hovered, setHovered] = useState(undefined)
    const [productCodeInQA, setProductCodeInQa] = useState(null)
    const [showQAProgress, setShowQAProgress] = useState(false);
    const [showQAResult, setShowQAResult] = useState(false)
    const [expectTime, setExpectTime] = useState(0);
    const items = props.productCodes
    const { feedSource, feedDatabaseCode } = props
    const [qaResultType, setQaResultType] = useState('stored')

    const [triggerIndividualQA, {
        isLoading: inMeasuring,
        qaResults: newQaResults = []
    }] = useRunIndividualQA(dispatch, () => {
        setShowQAResult(true)
    });
    const [fetchQaResults, {
        qaResults: storedQaResults,
        loading: loadingQAResults
    }] = useFetchQAResults(dispatch)

    const qaResults = useMemo(() => {
        if(qaResultType === 'stored'){
            return storedQaResults
        }
        return newQaResults
    }, [
        qaResultType,
        newQaResults,
        storedQaResults
    ])
    const openStock = (item) => {
        if (item !== undefined && item.product_code !== undefined && item.series_type !== undefined) {
            if (feedSource === '' || feedDatabaseCode === '' || item.product_code === '') {
                return
            }
            // dispatch(setProductData(item))
            history.push(`/product/detail/${encodeURIComponent(feedSource)}/${encodeURIComponent(feedDatabaseCode)}/${encodeURIComponent(item.product_code)}`)
        }
    }

    useEffect(() => {
        if (items.length && hovered) {
            setCursor(items.indexOf(hovered))
        }
    }, [hovered])

    const clickHandler = (item) => {
        if(item.report_id){
            setProductCodeInQa(item.product_code)
            fetchQaResults({
                reportId: item.report_id,
                blobOpt: 'all',
                feedSource,
                databaseCode: feedDatabaseCode,
                productCode: item.product_code
            }).then(r => {
                if (r && r.payload) {
                    setShowQAResult(true)
                }
            })
        }
        return
    }
    const clickQaHandler = (item) => {
        if(inMeasuring) return;
        const _expectTime= item.memory_size / config.averageLoadCapacity * 1 + 5000
        setExpectTime(_expectTime)
        setProductCodeInQa(item.product_code)
        setShowQAProgress(true);
    }

    const qaRequest = (productCode) => {
        return new Promise((resolve, reject) => {
            triggerIndividualQA({
                feedSource,
                databaseCode: feedDatabaseCode,
                productCode: productCode
            }).then((r) => {
                resolve(r)
            }).catch(e => {
                console.log(e, 'e???')
                reject(e)
            })
        })
    }

    let pressedKeys = {}

    //When use press key
    function keyDownHandler(e) {
        pressedKeys[e.keyCode] = true
        if (items.length) {
            if (e.key === 'ArrowDown') {
                setCursor(prevState => {
                    return prevState < items.length - 1 ? cursor + 1 : cursor
                })
            } else if (e.key === 'ArrowUp') {
                setCursor(prevState => {
                    return prevState > 0 ? prevState - 1 : prevState
                })
            }
        }
    }

    //When user release key pressing
    function keyUpHandler(e) {
        pressedKeys[e.keyCode] = false
        const item = items[cursor]
        openStock(item)
    }

    React.useEffect(() => {
        window.addEventListener("keydown", keyDownHandler)
        window.addEventListener("keyup", keyUpHandler)
        return () => {
            if (window && window.removeEventListener) {
                window.removeEventListener("keydown", keyDownHandler)
                window.removeEventListener("keyup", keyUpHandler)
            }
        }
    })
    const selectedProductName = useSelector(state => state.productData.productName)

    return (
        <>
            {items.map((item, i) => (
                <ListItemComponent
                    feedSource={feedSource}
                    key={i}
                    active={cursor === 0 ? (selectedProductName?.toLocaleLowerCase() === item.product_code?.toLocaleLowerCase()) : i === cursor}
                    item={item}
                    setSelected={setSelected}
                    clickHandler={clickHandler}
                    setHovered={setHovered}
                    clickQaHandler={clickQaHandler}
                    inProgressOfQA={item.product_code === productCodeInQA && inMeasuring}
                />
            ))}
            {
                showQAResult && <IndividualResultDialog
                    open={showQAResult}
                    handleClose={() => {
                        setShowQAResult(false)
                        setProductCodeInQa(null)
                        setQaResultType('stored')
                    }}
                    feedSource={feedSource}
                    databaseCode={feedDatabaseCode}
                    productCode={productCodeInQA}
                    qaResults={qaResults}
                />
            }
            {
                showQAProgress && productCodeInQA && <ProgressPopupDialog
                    handleClose={() => {
                        setShowQAProgress(false)
                        setQaResultType('run')
                    }}
                    expectTime={expectTime}
                    open={showQAProgress}
                    apiRequest={() => qaRequest(productCodeInQA)}
                />
            }
        </>
    )
}

const StockProductCodes = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const {
        productCode: mProductCode,
        databaseCode: mDatabaseCode,
        feedSource: mFeedSource,
    } = useParams()
    const ref = useRef(null)
    const isLoading = useSelector(state => state.eodData.loadingInProgress)
    const [isSearching, setIsSearching] = useState(false)
    const stockProducts = useSelector(state => state.eodData.stockProducts)
    const isFull = useSelector(state => state.eodData.isStockProductsFull)
    const { feedSource, feedDatabaseCode, sectionName, searchWord, value: stepIndex } = props
    const [productCode, setProductCode] = useState('')
    const [searchResults, setResults] = useState([])
    const scrollHandler = useCallback(() => {
        if (
            stockProducts.length > 0 &&
            ref.current.scrollTop + ref.current.clientHeight >=
            ref.current.scrollHeight
        ) {
            if (!isFull && !isLoading && !isSearching) {
                dispatch(fetchStockProducts({
                    feed_source: feedSource,
                    database_code: feedDatabaseCode,
                    start_index: stockProducts?.length,
                    section_name: sectionName
                }))
            }
        }
    }, [
        feedSource,
        feedDatabaseCode,
        sectionName,
        stockProducts,
        isFull
    ])
    useEffect(() => {
        if (mProductCode && mDatabaseCode === feedDatabaseCode && mFeedSource === feedSource) {
            setProductCode(mProductCode)
        }
        if (ref.current) {
            ref.current.addEventListener("scroll", scrollHandler)
        }
        return () => {
            if (ref.current && ref.current.removeEventListener) {
                ref.current.removeEventListener('scroll', scrollHandler)
            }
            setProductCode('')
        }
    }, [
        ref,
        mProductCode,
        mDatabaseCode,
        mFeedSource,
        stockProducts,
        isFull
    ]);
    useEffect(() => {
        if (feedSource && feedDatabaseCode && stepIndex === 3) {
            // if (searchWord === '') {
                // this.props.clearStockProducts()
                dispatch(clearStockProducts())
                dispatch(fetchStockProducts({
                    feed_source: feedSource,
                    database_code: feedDatabaseCode,
                    section_name: sectionName,
                    search_word: searchWord,
                    isQa: 1
                }))
            // } else {
            //     const params = {
            //         search: searchWord,
            //         global: false
            //     }
            //     if (feedSource !== '') {
            //         params.feed_source = feedSource
            //     }
            //     if (feedDatabaseCode !== '') {
            //         params.database_code = feedDatabaseCode
            //     }
            //     // if (sectionName && sectionName !== '') {
            //     //     params.section_name = sectionName
            //     // }
            //     setIsSearching(true)
            //     try {
            //         dispatch(fetchSearchData(params)).then(r => {
            //             if (r.payload) {
            //                 const { suggestions } = r.payload
            //                 if (Array.isArray(suggestions)) {
            //                     setResults([...suggestions]);
            //                 }
            //                 setIsSearching(false)
            //             }
            //         })
            //     } catch (e) {
            //         setIsSearching(false)
            //     }
            // }
        }
    }, [
        feedSource,
        feedDatabaseCode,
        sectionName,
        searchWord,
        stepIndex
    ])
    const loadingMoreStyle = {
        textAlign: 'center',
        marginTop: '10px',
        marginBottom: '20px'
    }

    const onClickQa = () => {

    }

    return (
        <Box id="Qa-ProductCodeWrapper"
            ref={ref}
            style={{ overflow: 'auto', height: '100%' }}
        >
            <Lists productCodes={stockProducts}{...props} /> {
                isLoading ? <Box style={loadingMoreStyle}>Loading...</Box> : ''
            }
            
        </Box>
    )
}

export default StockProductCodes

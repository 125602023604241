import React, { useState, useEffect } from 'react';
import { useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {
    Button,
    FormControl,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '13px'
    },
    inputControl: {
        width: '100%',
        position: 'relative',
        '& > select, & > input': {
            fontFamily: 'Poppins',
            // textTransform: 'uppercase',
            // cursor: 'pointer',
            background: 'transparent',
            width: '100%',
            color: '#849095',
            borderRadius: '4px',
            backgroundColor: 'rgb(255, 255, 255)',
            boxShadow: '0 0px 4px 0 rgb(35 57 66 / 21%)',
            border: '1px solid transparent',
            marginBottom: '10px',
            padding: '9px 11px',
            fontSize: '13px',
            lineHeight: 1,
            '&:focus': {
                outline: 'none',
                boxShadow: '0 0 5px -1px #3d91ff'
            }
        }
    },
    inputIcon: {
        position: 'absolute',
        right: '5px',
        zIndex: '1',
        top: '9px',
        color: '#6f6868'
    },
    distinctGroup: {
        '& > ul': {
            listStyle: 'none',
            padding: 0,
            maxHeight: '115px',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '10px'
            },
              
            /* Track */
            '&::-webkit-scrollbar-track': {
                background: '#f1f1f1'
            },
              
              /* Handle */
            '&::-webkit-scrollbar-thumb': {
                background: '#888'
            },
              
              /* Handle on hover */
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#555'
            },
            '& > li': {
                padding: '0',
                margin: '6px 0',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                paddingLeft: '17px',
                position: 'relative',
                fontSize: '13px',
                fontFamily: 'sans-serif',
                '& svg': {
                    position: 'absolute',
                    left: 0,
                    color: '#6f6868'
                },
                '& > span': {
                    marginLeft: '10px',
                    color: '#6f6868'
                }
            }
        }
    },
    inputAction: {
        display: 'flex',
        borderTop: 'solid 1px #d1d4d6',
        paddingTop: '10px',
        '& > label': {
            marginLeft: '4px'
        },
        '& .MuiButton-root.Mui-disabled': {
            color: 'white',
            boxShadow: 'none',
            background: '#6979ce',
            cursor: 'not-allowed',
        }
    },
    FieldSet: {
        flex: 1
    },
    CheckboxControl: {
        margin: 0,
        '& .MuiCheckbox-root': {
            padding: '3px'
        },
        '& span':{
            fontSize: '12px'
        }
    }
}));
  
function PlotterDatumFilter(props) {
    const classes = useStyles()

    const [checkedItems, setCheckedItems] = useState(props.selected || [])
    const [items, setItems] = useState([])
    const [searchWord, setSearchWord] = useState('')
    
    const { onSelect } = props
    const datumFilterOptions = useSelector(state => state.componentData.datumFilterOptions)

    useEffect(() => {
        let arr = [...datumFilterOptions]
        if(searchWord !== ''){
            arr = datumFilterOptions.filter(item => (item.toLocaleString().toLowerCase()).indexOf(searchWord.toLowerCase()) > -1)
            setCheckedItems([...arr])
        }
        setItems([...arr])
    }, [searchWord, datumFilterOptions])

    const handleItemCheck = (value) => {
        const currentIndex = checkedItems.indexOf(value);
        const newChecked = [...checkedItems];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setAllCheck(false)
        setCheckedItems(newChecked)
    }

    const [allCheck, setAllCheck] = React.useState(false)
    const [allCleared, setAllCleared] = React.useState(false)

    const clearAll = () => {
        setCheckedItems([])
        setSearchWord('')
        setAllCheck(false);
    }
    const selectAll = (e) => {
        if(e.target.checked){
            setCheckedItems([...items])
            setAllCheck(true);
            setAllCleared(false);
        }else{
            setCheckedItems([])
            setAllCheck(false);
        }
    }

    const handleClickUpdate = () => {
        onSelect(checkedItems)
    }
    return (
        <div className={classes.root}>
            <div className={classes.inputControl}>
                <input value={searchWord === null ? '' : searchWord} onChange={(e) => {
                    setSearchWord(e.target.value)
                }} placeholder='Enter Filter Value'/>
                <div className={classes.inputIcon}>
                    <SearchIcon />
                </div>
            </div>
            <div className={classes.distinctGroup}>
                <ul>
                    {
                        items.map((d, i) => (
                            <li key={i} onClick={e => {
                                handleItemCheck(d)
                            }}>
                                {
                                    (checkedItems.indexOf(d) > -1) && <CheckIcon />
                                }
                                <span>{d}</span>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className={classes.inputAction}>
                <FormControl component="fieldset" className={classes.FieldSet}>
                    <FormControlLabel
                        className={classes.CheckboxControl}
                        control={<Checkbox value={1} checked={allCheck} onChange={selectAll} name={'selectedAll'} />}
                        label={'Select All'}
                    />
                    <FormControlLabel
                        className={classes.CheckboxControl}
                        control={
                            <Checkbox value={1} checked={allCleared} 
                                onChange={clearAll} name={'clearedAll'}  disabled={checkedItems.length < 1}
                                indeterminate={checkedItems.length > 0}
                                />
                        }
                        label={'Clear All'}
                    />
                </FormControl>
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" size="small" component="span" onClick={() => {
                        handleClickUpdate();
                    }}>
                        Update
                    </Button>
                </label>
                <label htmlFor="contained-button-file">
                    <Button variant="contained" size="small" component="span" onClick={(e) => {
                        props.goBack(0, 'catalog')
                    }}>
                        Cancel
                    </Button>
                </label>
            </div>
        </div>
    );
}

export default PlotterDatumFilter
import React, { useEffect, useMemo } from 'react';
import {
    Box, Typography,
} from "@mui/material"

import useStyles from './useStyles'
import ListItem from './Details/ListItem';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useDispatch, useSelector } from 'react-redux';
import QLink from './Details/QLink';
import useSearchQAData from 'hooks/SearchQA';
import { parseDateToLocalFormat } from 'shared/utilities/dateHelpers';

export default function StockSource({
    searchWord,
    changeStep2Index
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.eodData.loadingFeedSources)
    const feedSources = useSelector(state => state.eodData.sources)

    const [
        searchQA,
        {
            results = [],
            loading: searchLoading
        }
    ] = useSearchQAData(dispatch)

    useEffect(() => {
        // if(searchWord !== ''){
        //     searchQA({
        //         searchWord
        //     })
        // }
    }, [
        searchWord
    ])

    const filteredFeedSources = useMemo(() => {
        let _feedSources = [...feedSources]
        if (searchWord && searchWord !== '') {
            _feedSources = feedSources.filter(item => item.feed_source?.toLowerCase().indexOf(searchWord.toLowerCase()) > -1 || item.human_readable_name?.toLowerCase().indexOf(searchWord) > -1)
        }
        _feedSources = _feedSources.sort((a, b) => {
            // Use human_readable_name if available, otherwise use feed_source
            const aName = a?.human_readable_name || a?.feed_source;
            const bName = b?.human_readable_name || b?.feed_source;

            // Compare the names alphabetically
            // return aName - bName
            return aName.localeCompare(bName);
        })
        return _feedSources

    }, [
        feedSources,
        searchWord
    ])

    return (
        <Box id="Qa-FeedSourceWrapper">
            <LoadingOverlay isLoading={isLoading}>
                {
                    filteredFeedSources?.length > 0 || (searchWord !== '' && results.length > 0) ? (
                        <Box className={classes.ListBox}>
                            <ListItem
                                onClick={() => {
                                    changeStep2Index(4);
                                }}
                            >
                                <span style={{ fontWeight: 'bold', textTransform: 'uppercase' }}>Individual Tables</span>
                            </ListItem>
                            {
                                filteredFeedSources?.map((item, index) => {
                                    return <ListItem
                                        onClick={() => {
                                            changeStep2Index(1, item.feed_source);
                                        }}
                                        key={index}
                                    >
                                        <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography>{item.human_readable_name || item.feed_source}</Typography>
                                            <QLink onClick={(e) => {
                                                e.stopPropagation();
                                                changeStep2Index(1, item.feed_source, null, true);
                                            }}/>
                                        </Box>
                                    </ListItem>
                                })
                            }
                            {
                                results.length > 0 && searchWord !== '' && (
                                    results.map((item, idx) => (
                                        <ListItem
                                            display={'flex'}
                                            key={idx}
                                            sx={{
                                                justifyContent: 'space-between !important',
                                                borderBottom: 'solid 1px #ebeaea',
                                                cursor: 'pointer',
                                                padding: '10px 12px !important'
                                            }}
                                        >
                                            <Box>{item.feed_source}/{item.database_code}</Box>
                                            <Box className="mr-1" sx={{ fontSize: '13px' }}>{parseDateToLocalFormat(item.last_frontend_qa_date)}</Box>
                                        </ListItem>
                                    ))
                                )
                            }
                        </Box>
                    ) : (
                        <div style={{ marginTop: '10px', marginLeft: '18px' }}>There is no Feed Source</div>
                    )
                }
            </LoadingOverlay>
        </Box>
    );
}
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import API from 'store/services/AxiosService';
import {
    EOD_CHECK_FRONTEND_QA_BATCH,
    EOD_CHECK_FRONTEND_QA_REPORT,
    EOD_DELETE_FRONTEND_QA_BATCH_GROUP,
    EOD_DELETE_FRONTEND_QA_REPORT,
    EOD_DELETE_QA_REPORT,
    EOD_FETCH_FRONTEND_QA_PRODUCTS,
    EOD_FETCH_FRONTEND_QA_REPORTS,
    EOD_FETCH_FRONTEND_QA_RESULTS,
    EOD_GET_DATABASE_CODES_QA,
    EOD_GET_FEED_SOURCE_DATABASE_CODES_QA,
    EOD_GET_QA_DATA,
    EOD_GET_QA_RANGES,
    EOD_GET_QA_REPORT_DATA,
    EOD_GET_QA_RESULT,
    EOD_INIT_QA_RANGE,
    EOD_RETRY_QA_RANGE,
    EOD_SAVE_INDIVIDUAL_QA_REPORT,
    EOD_SAVE_QA_RANGE,
    EOD_SAVE_QA_REPORT,
    EOD_SAVE_QA_RESULT,
    EOD_SEARCH,
    EOD_SEARCH_FRONTEND_QA_REPORT,
    EOD_SEARCH_QA_DATA,
    EOD_SKIP_FRONTEND_QA_BATCH,
    EOD_UPDATE_FRONTEND_QA_BATCH,
    EOD_UPDATE_FRONTEND_QA_BATCH_GROUP,
    START_FRONTEND_PROUDCT_RUN_QA,
    START_FRONTEND_QA_REPORT,
    TEST_TRIGGER
} from 'store/UrlConstants'
import axios from 'axios'
import { paramsToQueryParam } from 'store/UrlUtils';
import LocalStorageService from 'store/services/LocalStorageService';
import { showSnackbarMessage } from './MessagesSystemSlice';
const storageService = LocalStorageService.getService()
createEntityAdapter()


var CancelToken = axios.CancelToken;
let frontendQAResource;
let fetchSearchDataSource;
let fetchSearchQAReportSource;
let fetchSearchQADataSource;

export const fetchFrontendQAReports = createAsyncThunk(EOD_FETCH_FRONTEND_QA_REPORTS, async (params) => {
    // if (frontendQAResource) {
    //     frontendQAResource.cancel()
    // }
    frontendQAResource = CancelToken.source();
    const { data } = await API.get(EOD_FETCH_FRONTEND_QA_REPORTS + paramsToQueryParam(params), {
        cancelToken: frontendQAResource.token
    });
    return data
})

export const getQaDataApi = createAsyncThunk(EOD_GET_QA_DATA, async (params) => {
    const { data } = await API.get(EOD_GET_QA_DATA + paramsToQueryParam(params));
    return data
})

export const getQaResultsApi = createAsyncThunk(EOD_GET_QA_RESULT, async (params) => {
  const { data } = await API.get(EOD_GET_QA_RESULT + paramsToQueryParam(params));
  return data
})

export const saveQAReportApi = createAsyncThunk(EOD_SAVE_QA_REPORT, async (params, thunkApi) => {
  
    if (frontendQAResource) {
      frontendQAResource.cancel()
    }
    frontendQAResource = CancelToken.source();
  
    try{
      const { data } = await axios({
        method: 'post',
        url: EOD_SAVE_QA_REPORT,
        cancelToken: frontendQAResource.token,
        data: { ...params }
      });
      return data; 
    }catch(e){
      thunkApi.dispatch(showSnackbarMessage({
        message: `Something went wrong, please try again later`,
        severity: 'error'
      }))
      return;
    }
})

export const deleteQAReportApi = createAsyncThunk(EOD_DELETE_QA_REPORT, async (params, thunkAPI) => {
  const { data } = await API.get(EOD_DELETE_QA_REPORT + paramsToQueryParam(params));

  if (data) {
      return {
        data,
        params
      }
  } else {
      thunkAPI.dispatch(showSnackbarMessage({
          message: `Something went wrong in deleting QA report. Please kindly try again later`,
          severity: 'error'
      }))
  }
})

export const getQaReportDataApi = createAsyncThunk(EOD_GET_QA_REPORT_DATA, async (qaReportId) => {
    const { data } = await API.get(EOD_GET_QA_REPORT_DATA + paramsToQueryParam({ qaReportId }));
    return data
})

export const getQARangesApi = createAsyncThunk(EOD_GET_QA_RANGES, async (qaReportId) => {
    const { data } = await API.get(EOD_GET_QA_RANGES + paramsToQueryParam({qaReportId}));
    return data
})

export const initQARangeApi = createAsyncThunk(EOD_INIT_QA_RANGE, async (params, thunkApi) => {
  try{
    const { data } = await axios({
      method: 'post',
      url: EOD_INIT_QA_RANGE,
      data: { ...params }
    });
    return data; 
  }catch(e){
    thunkApi.dispatch(showSnackbarMessage({
      message: `Something went wrong, please try again later`,
      severity: 'error'
    }))
    return;
  }
})

export const retryQARangeApi = createAsyncThunk(EOD_RETRY_QA_RANGE, async (params) => {
  const { data } = await API.get(EOD_RETRY_QA_RANGE + paramsToQueryParam(params));
  return data
})

export const saveQARangeApi = createAsyncThunk(EOD_SAVE_QA_RANGE, async (params, thunkApi) => {
    try{
      const { data } = await axios({
        method: 'post',
        url: EOD_SAVE_QA_RANGE,
        data: { ...params }
      });
      return data; 
    }catch(e){
      thunkApi.dispatch(showSnackbarMessage({
        message: `Something went wrong, please try again later`,
        severity: 'error'
      }))
      return;
    }
})


export const saveQaResultApi = createAsyncThunk(EOD_SAVE_QA_RESULT, async (params, thunkApi) => {
  try{
    const { data } = await axios({
      method: 'post',
      url: EOD_SAVE_QA_RESULT,
      data: { ...params }
    });
    return data; 
  }catch(e){
    thunkApi.dispatch(showSnackbarMessage({
      message: `Something went wrong, please try again later`,
      severity: 'error'
    }))
    return;
  }
})

export const saveIndividualQareportApi = createAsyncThunk(EOD_SAVE_INDIVIDUAL_QA_REPORT, async (params, thunkApi) => {
  try{
    const { data } = await axios({
      method: 'post',
      url: EOD_SAVE_INDIVIDUAL_QA_REPORT,
      data: { ...params }
    });
    return data; 
  }catch(e){
    thunkApi.dispatch(showSnackbarMessage({
      message: `Something went wrong, please try again later`,
      severity: 'error'
    }))
    return;
  }
})

export const updateFrontendQABatch = createAsyncThunk(EOD_UPDATE_FRONTEND_QA_BATCH, async (params, thunkApi) => {
  
    if (frontendQAResource) {
      frontendQAResource.cancel()
    }
    frontendQAResource = CancelToken.source();
  
    try{
      const { data } = await axios({
        method: 'post',
        url: EOD_UPDATE_FRONTEND_QA_BATCH,
        cancelToken: frontendQAResource.token,
        data: { ...params }
      });
      return data; 
    }catch(e){
      thunkApi.dispatch(showSnackbarMessage({
        message: `Something went wrong in Updateing the separate-batch`,
        severity: 'error'
      }))
      return;
    }
  })
  
  export const checkFrontendQAReport = createAsyncThunk(EOD_CHECK_FRONTEND_QA_REPORT, async (params, thunkApi) => {
    
    // if (frontendQAResource) {
    //   frontendQAResource.cancel()
    // }
    // frontendQAResource = CancelToken.source();
  
    try{
      const response = await API.get(EOD_CHECK_FRONTEND_QA_REPORT + paramsToQueryParam(params), {
        // cancelToken: frontendQAResource.token
      });
      return response.data;
    }catch(e){
      thunkApi.dispatch(showSnackbarMessage({
        message: `Something went wrong in checking error report. Please kindly try again later`,
        severity: 'error'
      }))
      return false
    }
  })
  export const checkFrontendQABatch = createAsyncThunk(EOD_CHECK_FRONTEND_QA_BATCH, async (params, thunkApi) => {
    
    // if (frontendQAResource) {
    //   frontendQAResource.cancel()
    // }
    // frontendQAResource = CancelToken.source();
  
    try{
      const response = await API.get(EOD_CHECK_FRONTEND_QA_BATCH + paramsToQueryParam(params), {
        // cancelToken: frontendQAResource.token
      });
      return response.data;
    }catch(e){
      thunkApi.dispatch(showSnackbarMessage({
        message: `Something went wrong in checking on-going batch. Please kindly try again later`,
        severity: 'error'
      }))
      return false
    }
  })

  export const deleteFrontendQABatchGroup = createAsyncThunk(EOD_DELETE_FRONTEND_QA_BATCH_GROUP, async (params, thunkApi) => {
    if (frontendQAResource) {
      frontendQAResource.cancel()
    }
    frontendQAResource = CancelToken.source();
    try{
      const response = await API.get(EOD_DELETE_FRONTEND_QA_BATCH_GROUP + paramsToQueryParam(params));
      return response.data;
    }catch(e){
      thunkApi.dispatch(showSnackbarMessage({
        message: `Something went wrong in resetting Batch. Please kindly try again later`,
        severity: 'error'
      }))
      return false
    }
  })
  
  export const skipFrontendQABatch = createAsyncThunk(EOD_SKIP_FRONTEND_QA_BATCH, async (params, thunkApi) => {
    
    if (frontendQAResource) {
      frontendQAResource.cancel()
    }
    frontendQAResource = CancelToken.source();
  
    try{
      const response = await API.get(EOD_SKIP_FRONTEND_QA_BATCH + paramsToQueryParam(params), {
        cancelToken: frontendQAResource.token
      });
      return response.data;
    }catch(e){
      thunkApi.dispatch(showSnackbarMessage({
        message: `Something went wrong in skipping Batch. Please kindly try again later`,
        severity: 'error'
      }))
      return false
    }
  })


export const triggerFrontendQAReport = createAsyncThunk(START_FRONTEND_QA_REPORT, async (payload, thunkAPI) => {
    const token = storageService.getAccessToken()
    const { data } = await API.post(START_FRONTEND_QA_REPORT, payload, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    });
    if (data) {
        // thunkAPI.dispatch(showSnackbarMessage(
        //     {
        //         message: "Successfully Done",
        //         severity: "info",
        //     }
        // ))
    } else {
        thunkAPI.dispatch(showSnackbarMessage({
            message: `Something went wrong in running QA process. Please kindly try again later`,
            severity: 'error'
        }))
    }
    return data
})
export const triggerFrontendProductQA = createAsyncThunk(START_FRONTEND_PROUDCT_RUN_QA, async (requestBody, thunkAPI) => {
    try {
        if (frontendQAResource) {
            frontendQAResource.cancel()
        }
        frontendQAResource = CancelToken.source();
        const token = storageService.getAccessToken()
        // console.log(requestBody.onDownloadProgress, 'requestBody.onDownloadProgress')
        const res = await API.post(START_FRONTEND_PROUDCT_RUN_QA, requestBody.payload, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            cancelToken: frontendQAResource.token,
            onDownloadProgress: requestBody.onDownloadProgress
        })
        // .then(a => {
        //     console.log(a, 'a')
        // }).catch(e => {
        //     console.log(e, 'catch e')
        // })
        if (res.data) {
            // thunkAPI.dispatch(showSnackbarMessage(
            //     {
            //         message: "Successfully Done",
            //         severity: "info",
            //     }
            // ))
            return res.data
        } else {
            // console.log(res, 'res')
        }
    } catch (e) {
        // thunkAPI.dispatch(showSnackbarMessage({
        //     message: `Something went wrong. Please kindly try again later`,
        //     severity: 'error'
        // }))
        console.log(e, 'e')
        // Axios.cancel(e)
        // console.log(e.request, 'e.request')
        console.log(e.response, 'e.resposne')
        // console.log(e.response.status, 'e.resposne.status')
        if(axios.isCancel(e)){
            console.log('cancelled')
            return {
                payload: {
                    status: 'cancelled'
                }
            }
        }
        return e.response
        // console.log(res.status, 'res')
        // return res
    }
})
export const updateFrontendQABatchGroup = createAsyncThunk(EOD_UPDATE_FRONTEND_QA_BATCH_GROUP, async (payload, thunkAPI) => {
    
    const { data } = await API.post(EOD_UPDATE_FRONTEND_QA_BATCH_GROUP, payload);
    if (data) {
    } else {
        thunkAPI.dispatch(showSnackbarMessage({
            message: `Something went wrong in modifying the Batch Metadata. Please kindly try again later`,
            severity: 'error'
        }))
    }
    return data
})

export const fetchFrontendQAResults = createAsyncThunk(EOD_FETCH_FRONTEND_QA_RESULTS, async (params) => {
    const { data } = await API.get(EOD_FETCH_FRONTEND_QA_RESULTS + paramsToQueryParam(params));
    return data
})
export const fetchFrontendQAProducts = createAsyncThunk(EOD_FETCH_FRONTEND_QA_PRODUCTS, async (params) => {
    const { data } = await API.get(EOD_FETCH_FRONTEND_QA_PRODUCTS + paramsToQueryParam(params));
    return data
})
export const testTrigger = createAsyncThunk(TEST_TRIGGER, async (params) => {
    const { data } = await API.get(TEST_TRIGGER + paramsToQueryParam(params));
    return data
})

export const fetchSearchData = createAsyncThunk(EOD_SEARCH, async (params) => {
    if (fetchSearchDataSource) {
        fetchSearchDataSource.cancel()
    }
    fetchSearchDataSource = CancelToken.source();
    let url = EOD_SEARCH
    const response = await API.get(
        url, {
        params: params,
        cancelToken: fetchSearchDataSource.token
    })
    const { data: suggestions } = response
    return {
        suggestions
    }
})

export const fetchSearchQAReport = createAsyncThunk(EOD_SEARCH_FRONTEND_QA_REPORT, async (params) => {
    if (fetchSearchQAReportSource) {
        fetchSearchQAReportSource.cancel()
    }
    fetchSearchQAReportSource = CancelToken.source();
    let url = EOD_SEARCH_FRONTEND_QA_REPORT
    const response = await API.get(
        url, {
        params: params,
        cancelToken: fetchSearchQAReportSource.token
    })
    const { data: suggestions } = response
    return {
        suggestions
    }
})


export const fetchSearchQAData = createAsyncThunk(EOD_SEARCH_QA_DATA, async (params) => {
    if (fetchSearchQADataSource) {
        fetchSearchQADataSource.cancel()
    }
    fetchSearchQADataSource = CancelToken.source();
    let url = EOD_SEARCH_QA_DATA
    const response = await API.get(
        url, {
        params: params,
        cancelToken: fetchSearchQADataSource.token
    })
    const { data } = response
    return {
        data
    }
})

export const deleteFrontendQAReport = createAsyncThunk(EOD_DELETE_FRONTEND_QA_REPORT, async (params, thunkAPI) => {
    if (frontendQAResource) {
        frontendQAResource.cancel()
    }
    frontendQAResource = CancelToken.source();
    const { data } = await API.get(EOD_DELETE_FRONTEND_QA_REPORT + paramsToQueryParam(params), {
        cancelToken: frontendQAResource.token
    });

    if (data) {
        return {
            data,
            params
        }
    } else {
        thunkAPI.dispatch(showSnackbarMessage({
            message: `Something went wrong in deleting QA report. Please kindly try again later`,
            severity: 'error'
        }))
    }
})

export const getFeedSourceDatabaseCodesQA = createAsyncThunk(EOD_GET_FEED_SOURCE_DATABASE_CODES_QA, async (params, thunkAPI) => {
    if (frontendQAResource) {
        frontendQAResource.cancel()
    }
    frontendQAResource = CancelToken.source();
    const { data } = await API.get(EOD_GET_FEED_SOURCE_DATABASE_CODES_QA + paramsToQueryParam(params), {
        cancelToken: frontendQAResource.token
    });

    if (data) {
        return data
    } else {
        thunkAPI.dispatch(showSnackbarMessage({
            message: `Something went wrong in fetching Databases QA report of Feed Source. Please kindly try again later`,
            severity: 'error'
        }))
    }
})
export const getDatabaseCodesQA = createAsyncThunk(EOD_GET_DATABASE_CODES_QA, async (params, thunkAPI) => {
    const { data } = await API.get(EOD_GET_DATABASE_CODES_QA);
    if (data) {
        return data
    } else {
        thunkAPI.dispatch(showSnackbarMessage({
            message: `Something went wrong in fetching Databases QA report. Please kindly try again later`,
            severity: 'error'
        }))
    }
})

export const stopFrontendQAProcess = createAsyncThunk('eod/frontend_qa/stop_process', async (params, thunkAPI) => {
    if (frontendQAResource) {
        frontendQAResource.cancel()
    }
})

const initialState = {
    reports: {},
    reportResults: [],
    products: [],
    endPercentage: 0,
    searchResults: [],
    loadingProducts: false,
    inProgressOfQA: false,
    loadingSearch: false,
    inDeleting: false,

    currentQaBatch: {},
    batchesInProgress: [],
    inProgressOfBatch: false
}

const frontendQASlice = createSlice({
    name: 'FrontendQA',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchFrontendQAProducts.pending]: (state, action) => {
            state.loadingProducts = true
            state.products = []
            state.endPercentage = 0
        },
        [fetchFrontendQAProducts.fulfilled]: (state, action) => {
            if (action.payload) {
                const { products, endPercentage } = action.payload
                state.products = products
                state.endPercentage = endPercentage
            }
            state.loadingProducts = false
        },
        [fetchFrontendQAProducts.rejected]: (state, action) => {
            state.loadingProducts = false
        },
        [triggerFrontendQAReport.pending]: (state, action) => {
            state.inProgressOfQA = true
            // state.reports = {}
        },
        [triggerFrontendQAReport.fulfilled]: (state, action) => {
            if (action.payload) {
                state.reports = action.payload
            }
            state.inProgressOfQA = false
        },
        [triggerFrontendQAReport.rejected]: (state, action) => {
            state.inProgressOfQA = false
        },
        [fetchSearchData.pending]: (state, action) => {
            state.loadingSearch = true
        },
        [fetchSearchData.fulfilled]: (state, action) => {
            const { suggestions } = action.payload
            if (suggestions) {
                let formatted = []
                suggestions.forEach(element => {
                    let label = element.value.product_code
                    // element.value.label = label
                    formatted.push({
                        ...element.value,
                        label: label
                    })
                })
                console.log(formatted, 'formatted')
                state.searchResults = formatted
            }
            state.loadingSearch = false
        },
        [fetchSearchData.rejected]: (state, action) => {
            state.loadingSearch = false
        },
        [deleteFrontendQAReport.pending]: (state, action) => {
            state.inDeleting = true
            // state.reports = {}
        },
        [deleteFrontendQAReport.fulfilled]: (state, action) => {
            // if (action.payload) {
            //     const { data, params } = action.payload
            //     if (data.status) {
            //         const _reports = state.reports.filter(item => item.id !== params.id)
            //         state.reports = _reports
            //     }
            // }
            state.inDeleting = false
        },
        [deleteFrontendQAReport.rejected]: (state, action) => {
            state.inDeleting = false
        },

        [checkFrontendQAReport.pending]: (state, action) => {
            state.inProgressOfBatch = true
            state.batchesInProgress = []
          },
          [checkFrontendQAReport.fulfilled]: (state, action) => {
            if (action.payload) {
              const { batches = [] } = action.payload
              state.batchesInProgress = batches
            }
            state.inProgressOfBatch = false
          },
          [checkFrontendQAReport.rejected]: (state, action) => {
            state.inProgressOfBatch = false
            state.batchesInProgress = []
            state.errorMsg = action.payload
          },
          [updateFrontendQABatch.pending]: (state, action) => {
            state.inProgressOfBatch = true
            state.currentQaBatch = {}
          },
          [updateFrontendQABatch.fulfilled]: (state, action) => {
            if (action.payload) {
              state.currentQaBatch = action.payload
            }
            state.inProgressOfBatch = false
          },
          [updateFrontendQABatch.rejected]: (state, action) => {
            state.inProgressOfBatch = false
            state.errorMsg = action.payload
          },
    },
})

export const prepareSelector = state => state.frontendQA
export default frontendQASlice.reducer

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import SwipeableViews from "react-swipeable-views";

import { getAuth, signOut } from "firebase/auth"
import {
    Box,
} from "@mui/material";
import {
    FolderCopy as FolderCopyIcon,
    Troubleshoot
} from '@mui/icons-material';
import DoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import {
    setVisibleStep2,
    selectStep2Sidebar,
    selectStep1Sidebar
} from "store/slices/AppLayoutSlice";

import StyledTooltip from "components/common/Base/StyledTooltip";
import StepSourceContainer from "./sources";
import StepQaContainer from "./qa";

import 'styles/custom/theme/step_sidebar.scss';
// import ChartOptions from "./chartOptions/index";
import CategoryOptions from "./categories/CategoryOptions";
import FavDisplay from "components/layout/sidebar/tags"

import { changeStep2Index, fetchFeedSources } from "store/slices/EodDataSlice";
import LocalStorageService from "store/services/LocalStorageService";


import DashboardIcon from 'components/icons/Dashboard'
import DashboardSelectedIcon from 'components/icons/DashboardSelected'
// import ChartFeatureIcon from "components/icons/ChartFeature";
// import ChartFeatureSelectedIcon from "components/icons/ChartFeatureSelected";
import TagIcon from "components/icons/Tag";
import TagSelectedIcon from "components/icons/TagSelected";

import { useStyles } from "./useStyles";

const storageService = LocalStorageService.getService()
const auth = getAuth();

/* Main Sidebar */
/*Tip 1: You can change the color of the sidebar using: data-color="blue | green | orange | red"*/
const StepSidebar = () => {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch();
    const appLayout = useSelector(state => state.appLayout)
    const step1Sidebar = useSelector(state => state.appLayout.step1Sidebar)
    const [activeAllStep1Sidebar, setActiveAllStep1Sidebar] = useState(false)
    const logCount = storageService.getLogCount()
    const [state, setState] = React.useState({
        menuVisible: false,
        minimizedSidebar: true,
        firstChoice: 'source',
    })

    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    useLayoutEffect(() => {
        if (mounted) {
            dispatch(fetchFeedSources())
            // fetchSources();
            // fetchGroups();
        }
    }, [
        mounted
    ])
    useLayoutEffect(() => {
        if (mounted && logCount < 3) {
            setActiveAllStep1Sidebar(true)
        }
    }, [
        mounted,
        logCount
    ])
    useLayoutEffect(() => {
        if (activeAllStep1Sidebar) {
            setTimeout(() => {
                setActiveAllStep1Sidebar(false)
            }, 3000)
        }
    }, [
        activeAllStep1Sidebar
    ])

    const toggleShow = () => {
        state.menuVisible = !state.menuVisible
        setState({ ...state })
    };
    const handleStep2Visible = (visible, choice, _step1Sidebar = '') => {
        let isStep2Shown = !appLayout.isStep2Shown;
        if (visible !== undefined) {
            if (appLayout.step1Sidebar === _step1Sidebar && choice !== '' && choice === appLayout.step2Sidebar && visible === appLayout.isStep2Shown) {
                isStep2Shown = !appLayout.isStep2Shown;
            } else {
                isStep2Shown = visible;
            }
        }
        if (isStep2Shown !== appLayout.isStep2Shown) {
            dispatch(setVisibleStep2(isStep2Shown));
        }
        if (choice !== appLayout.step2Sidebar) {
            dispatch(selectStep2Sidebar(choice))
        }
    }
    const setIsShown = (value) => {
        state.minimizedSidebar = value
        setState({ ...state })
    }

    const onClickLogOut = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        })
    }

    const toggleSidebar = () => {
        if (appLayout.isStep2Shown) {
            dispatch(setVisibleStep2(false));
        }else{
            dispatch(setVisibleStep2(true));
        }
    }

    const renderSwipeableViews = () => {
        return <Box
            className={classes.step2SidebarInner}
        >
            {/* <Tooltip title="Collpase">
                <IconButton aria-label="delete" className='stepToggleBtn' size="small" onClick={() => {
                    dispatch(setVisibleStep2(false));
                }}>
                    <ChevronLeftIcon />
                </IconButton>
            </Tooltip> */}
            {
                appLayout.step1Sidebar === 'data' && <StepSourceContainer wrapStyle={{ overflowX: 'hidden' }} />
            }
            <FavDisplay wrapStyle={{ overflowX: 'hidden', display: appLayout.step1Sidebar === 'tags' ? '' : 'none' }} />
            {/* <ChartOptions index={0} dir={'rtl'} wrapStyle={{ display: appLayout.step2Sidebar === 'features' ? '' : 'none' }}/> */}
            {
                ['dashboard'].indexOf(appLayout.step1Sidebar) > -1 &&
                <div index={0} dir={'rtl'}>
                    <h2 style={{ padding: '20px' }}>{appLayout.step2Sidebar.toUpperCase()}</h2>
                </div>
            }
            {
                appLayout.step1Sidebar === 'qa' && <StepQaContainer wrapStyle={{ overflowX: 'hidden' }} />
            }
            <SwipeableViews
                axis='x'
                index={0}
                style={{ overflowX: 'initial', display: appLayout.step1Sidebar === 'categories' ? '' : 'none' }}
            >
                <CategoryOptions value={0} index={0} dir={'rtl'} />
            </SwipeableViews>
        </Box>
    }

    const StyledTooltipProps = useMemo(() => {
        return {
            open: activeAllStep1Sidebar,
            enterDelay: logCount > 3 ? 2000 : 100,
            arrow: true,
            placement: 'left'
        }
    }, [activeAllStep1Sidebar, logCount])
    return (
        <>
            <Box className={classes.root}>
                <Box className={`${classes.stepSidebar} ${classes.minimizedStepSidebar}`}
                    onMouseEnter={() => setIsShown(false)}
                    onMouseLeave={() => setIsShown(true)}
                >
                    <Box
                        className={classes.stepSidebarInner}
                        display="flex"
                        sx={{ flexDirection: 'column' }}
                    >
                        <Box>
                            <Box className={classes.navList}>
                                <Box
                                  className={classes.navItem}
                                  onClick={toggleSidebar}
                                >
                                    {
                                        appLayout.isStep2Shown ? <Box></Box> : <DoubleArrowRightIcon />
                                    }
                                </Box>
                                <StyledTooltip {...StyledTooltipProps} title={'Data'}>
                                    <Box
                                        className={`${classes.navItem} ${step1Sidebar === 'data' ? 'selected' : ''}`}
                                        onClick={() => {
                                            if (appLayout.step1Sidebar !== 'data') {
                                                dispatch(setVisibleStep2(true));
                                                dispatch(selectStep2Sidebar('source'))
                                            } else {
                                                if (appLayout.isStep2Shown) {
                                                    dispatch(setVisibleStep2(false));
                                                }else{
                                                    dispatch(setVisibleStep2(true));
                                                }
                                            }
                                            dispatch(selectStep1Sidebar('data'))
                                            if(step1Sidebar !== 'data'){
                                                dispatch(changeStep2Index(0))
                                            }
                                        }}
                                        
                                    >
                                        <FolderCopyIcon className="mui-icon" />
                                        {
                                            // step1Sidebar === 'data' ? (
                                            //     <DatabaseSelectedIcon />
                                            // ) : (
                                            //     <DatabaseIcon />
                                            // )
                                        }
                                    </Box>
                                </StyledTooltip>
                                {/* <StyledTooltip {...StyledTooltipProps} title={'Features'}>
                                    <Box
                                        className={`${classes.navItem} ${step1Sidebar === 'features' ? 'selected' : ''}`}
                                        onClick={() => {
                                            handleStep2Visible(true, 'features')
                                            dispatch(selectStep1Sidebar('features'))
                                        }}
                                    >
                                        {
                                            step1Sidebar === 'features' ? (
                                                <ChartFeatureSelectedIcon />
                                            ) : (
                                                <ChartFeatureIcon />
                                            )
                                        }
                                    </Box>
                                </StyledTooltip> */}
                                <StyledTooltip {...StyledTooltipProps} title={'Tags'}>
                                    <Box
                                        className={`${classes.navItem} ${step1Sidebar === 'tags' ? 'selected' : ''}`}
                                        onClick={() => {
                                            handleStep2Visible(true, 'tags')
                                            dispatch(selectStep1Sidebar('tags'))
                                        }}
                                    >
                                        {
                                            step1Sidebar === 'tags' ? (
                                                <TagSelectedIcon />
                                            ) : (
                                                <TagIcon />
                                            )
                                        }
                                    </Box>
                                </StyledTooltip>
                                <StyledTooltip {...StyledTooltipProps} title={'Boards'}>
                                    <Box
                                        className={`${classes.navItem} ${step1Sidebar === 'dashboard' ? 'selected' : ''}`}
                                        onClick={() => {
                                            history.push('/home')
                                            dispatch(selectStep1Sidebar('dashboard'))
                                        }}
                                    >
                                        {
                                            step1Sidebar === 'dashboard' ? (
                                                <DashboardSelectedIcon />
                                            ) : (
                                                <DashboardIcon style={{ color: 'white' }} />
                                            )
                                        }
                                    </Box>
                                </StyledTooltip>
                                <StyledTooltip {...StyledTooltipProps} title={'Quality Analytic'}>
                                    <Box
                                        className={`${classes.navItem} ${step1Sidebar === 'qa' ? 'selected' : ''}`}
                                        onClick={() => {
                                            handleStep2Visible(true, 'source', 'qa')
                                            dispatch(selectStep1Sidebar('qa'))
                                            if(step1Sidebar !== 'qa'){
                                                dispatch(changeStep2Index(0))
                                            }
                                        }}
                                    >
                                        <Troubleshoot className="mui-icon" />
                                    </Box>
                                </StyledTooltip>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {
                    appLayout.step2Sidebar !== '' &&
                    <Box className={`${classes.stepSidebar} ${classes.stepSidebar2} ${!appLayout.isStep2Shown ? classes.noDisplay : ''}`}>
                        <Box
                          style={{ position: 'absolute', right: 10, top: 10, cursor: 'pointer', zIndex: 1000 }}
                          onClick={toggleSidebar}
                        >
                            <DoubleArrowLeftIcon />
                        </Box>
                        {renderSwipeableViews()}
                        {/* <Box className={classes.stepSidebar} sx={{ backgroundColor: 'white'}}>
                            This is the 3rd step
                        </Box> */}
                    </Box>
                }
            </Box>
        </>

    );
}
export default StepSidebar;

import {Box, Divider, lighten, List, ListItem, ListItemButton, ListItemText, Popover, Typography} from "@mui/material";
import {ArrowDropDown, Check as CheckIcon} from "@mui/icons-material";
import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {setProductOpt} from "../../../store/slices/StockProductSlice";
import CustomFormInput from "../Base/CustomFormInput";

const useStyles = makeStyles((theme) => ({
  Txt: {
    color: 'black'
  },
  Input: {
    flex: 1,
    color: '#849095',
    padding: '0.4rem',
    fontFamily: 'Poppins',
    // textTransform: 'uppercase',
    // cursor: 'pointer',
    background: 'transparent',
    width: '100%',
    backgroundColor: 'rgb(255, 255, 255)',
    border: 'none',
    borderRight: '1px solid #d8dde6',
    fontSize: '13px',
    lineHeight: '1.25rem',
    '&:focus': {
      outline: 'none',
    },
  },
  ListItemText: {
    fontSize: 14,
    textTransform: 'capitalize'
  },
  ListItemButton: {
    padding: '5px 11px'
  }
}));

interface DropdwonSelectProps {
  placeholder?: string
  label?: string;
  options?: any[]
  selectValue?: any
  inputValue?: any
  onSelectChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
}

export const DropdownSelectWithInput: React.FC<DropdwonSelectProps> = ({
    label,
    placeholder,
    options = [],
    selectValue = '',
    inputValue = '',
    onSelectChange = () => {},
    onInputChange = () => {},
    ...rest
}) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const handleFieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
       setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const openPop = Boolean(anchorEl);

    return <Box {...rest}>
        <Box>
            <span>{label}</span>
        </Box>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            border: 'solid 1px #dfd4d4',
            borderRadius: '4px',
            overflow: 'hidden'
        }}>
            <input
              type="text"
              className={classes.Input}
              value={inputValue}
              onChange={(e) => onInputChange(e.target.value)}
            />
            <Box
                display="flex"
                sx={{
                    overflow: 'hidden',
                    cursor: 'pointer',
                    alignItems: 'center'
                }}
                onClick={handleFieldClick}
            >
                <ArrowDropDown />
            </Box>
        </Box>
        <Popover
          open={openPop}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{ width: '270px', maxHeight: '300px', bgcolor: 'background.paper' }}>
            <Divider />
            <nav>
              <List>
                {
                  options.map((item, idx) => (
                    <ListItem key={idx} disablePadding>
                      <ListItemButton
                        classes={{ root: classes.ListItemButton }}
                        onClick={() => {
                          setAnchorEl(null)
                          onSelectChange(item.value)
                          onInputChange(item.value)
                        }}
                      >
                        <ListItemText classes={{ primary: classes.ListItemText }} primary={item.label} />
                          {
                              item.value === selectValue && <CheckIcon />
                          }
                      </ListItemButton>
                    </ListItem>
                  ))
                }
              </List>
            </nav>
          </Box>
        </Popover>
      </Box>
}
import React, { useState, useMemo } from 'react';
import reactCSS from 'reactcss';
import {
    Popover
} from "@mui/material";
import { TwitterPicker  } from 'react-color';
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    swatch: {
        borderRadius: '1px',
        boxShadow: 'rgb(0 0 0 / 27%) 2px 3px 4px 1px',
        display: 'inline-block',
        cursor: 'pointer'
    },
    popover: {
        transform: 'translate(0px, 9px)',
        "& span > div": {
            border: 'solid 1px #9d9d9d29',
        }
    },
}));

const ColorPicker = (props) => {
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const openPop = Boolean(anchorEl);

    const { pickerLable, color, defaultColor } = props;
    const colors = useMemo(() => {
        const innerColors = props.colors || ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]
        if(defaultColor){
            innerColors.unshift(defaultColor)
        }
        return innerColors
    }, [
        props.colors,
        defaultColor
    ])

    const styles = reactCSS({
        'default': {
            color: {
                width: '14px',
                height: '14px',
                borderRadius: '2px',
                background: `${ color }`,
            },
        },
    });

    return (
      <>
          <div className='colorPickerWrap' style={{padding: '0'}}>
              <div className='colorPickerLabel' style={{marginBottom: '6px'}}>{pickerLable}</div>
              <div className='colorPickerField'>
                  <div className={ classes.swatch } onClick={ handleClick }>
                      <div style={ styles.color } />
                  </div>
                  <Popover
                    open={openPop}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className={classes.popover}
                  >
                      <TwitterPicker
                        color={ color || '#fff' }
                        colors={colors}
                        onChange={(e) => {
                            props.handleChange(e)
                        }}
                      />
                  </Popover>
              </div>
          </div>
      </>
    );
}

export default ColorPicker;
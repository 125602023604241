import React, { useState, forwardRef, useMemo, useEffect } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Slide,
    Box,
    TableContainer,
    Tooltip,
    FormControlLabel,
    Switch,
    TableCell,
    TableRow,
    Chip,
    Table,
    TableHead,
    TableBody,
} from "@mui/material";
import { useDispatch } from 'react-redux';
import useRunDatabaseCodesQA from 'hooks/RunDatabaseCodesQA';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    },
    productStaticBarButton: {
        color: '#4e4b4b',
        cursor: 'pointer',
        margin: '0 3px',
        position: 'relative',
        boxShadow: '0 0 1px 1px #e5e5f1',
        transition: 'all ease-in .2s',
        borderRadius: '4px',
        height: '37px',
        width: '37px',
        fontSize: '13px',
        textAlign: 'center',
        '&.selected': {
            boxShadow: '0 0 1px 1px #646467'
        },
        '&:hover': {
            background: '#ececec'
        },
        '&.selectedViewMode': {
            background: '#3c54db',
            color: 'white',
            '&:hover': {
                background: '#5e72e4'
            },
        },
        '& svg': {
            display: 'block',
            margin: '0 auto',
            fontSize: '35px'
        },
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     height: '1px',
        //     borderTop: 'solid 1px #e2dcdc',
        //     bottom: '-6px',
        //     width: 'calc(100% - 6px)'
        // },
        // '&:last-child': {
        //     '&::after': {
        //         content: 'initial'
        //     }
        // }
    },
    
    TableCap: {
        padding: '13px',
        alignItems: 'center',
        background: 'white',
        boxShadow: '14px 6px 4px -21px rgba(0,0,0,0.2), 0px -4px 2px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    Table: {
        marginTop: '11px',
        background: 'white'
    },
    TableTitle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '140%',
        margin: '0px',
        fontSize: '14px',
        letterSpacing: '-0.006em',
        fontWeight: '600',
        color: 'rgb(67, 74, 84)',
        marginRight: '10px'
    },
    TableRow: {
        cursor: 'pointer',
        "&.selected": {
            background: theme.palette.primary.main,
            '& td': {
                color: 'white'
            }
        }
    }
}));

const Transition = forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const convertToSeconds = (milliseconds, showMiliSeconds = true) => {
    if (!milliseconds) return ''
    const _seconds = Math.round(milliseconds / 1000 * 100) / 100;
    if (!showMiliSeconds) {
        return `${_seconds}s`
    }
    return `${milliseconds}ms`
}

const getPercentageOfTotal = (total, time) => {
    return time > 0 ? `${(time / total * 100).toFixed(2)}%` : 'N/A'
}

const DatabaseCodesQADialog = (props) => {
    const {
        open,
        handleClose,
    } = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const [timeIncrements, setTimeIncrements] = React.useState([])
    const [selectedInc, setSelectedInc] = React.useState('')
    const [showMiliSeconds, setShowMiliSeconds] = React.useState(false)
    const [showTimePercentage, setShowTimePercentage] = React.useState(false)

    
    const [triggerDatabaseCodeQA, { isLoading, qaResults = [] }] = useRunDatabaseCodesQA(dispatch);
    useEffect(() => {
        triggerDatabaseCodeQA()
    }, [
    ])
    console.log(qaResults, 'qaResults')
    
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'md'}
        >

            <DialogContent>
                <TableContainer className={classes.root}>
                    <Box display="flex" className={classes.TableCap}>
                        <Box display={"flex"} sx={{ flex: 1, justifyContent: 'end', alignItems: 'center' }}>
                            {/* <Chip label={rows.length} /> */}
                            <Box display={"flex"} sx={{ alignItems: 'center' }}>
                                <Box className={"mr-3"}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={showMiliSeconds} size="small" onChange={(e) => { setShowMiliSeconds(e.target.checked) }} name="showms" />
                                        }
                                        className='m-0'
                                        label="Show MS"
                                    />
                                </Box>
                                {/* <Box className={"mr-3"}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={showTimePercentage} size="small" onChange={(e) => { setShowTimePercentage(e.target.checked) }} name="showtimePercentage" />
                                        }
                                        className='m-0'
                                        label="Show %"
                                    />
                                </Box> */}
                            </Box>
                        </Box>
                        {/* <Typography className={classes.TableSubTitle}>{subTitle}</Typography> */}
                    </Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.Table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Feed Source</TableCell>
                                <TableCell>Count of Database codes</TableCell>
                                <TableCell align="right">Execution Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !isLoading ? (
                                    qaResults.length > 0 ? (
                                        <>
                                            {qaResults.map((item, idx) => (
                                                // row.items?.map((item, iIdx) => (
                                                <TableRow
                                                    key={idx}
                                                    className={`${classes.TableRow}`}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="td" scope="row">
                                                        {item.feedSource.human_readable_name || item.feedSource.feed_source}
                                                    </TableCell>
                                                    <TableCell component="td" scope="row">
                                                        {item.rowCount}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Tooltip title="Asset Load Time">
                                                            <Box>
                                                                {
                                                                    convertToSeconds(item.executionTime, showMiliSeconds)
                                                                }
                                                            </Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                                // ))
                                            ))}
                                        </>
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={9}><Box className={"text-center"}>There is no Data</Box></TableCell>
                                        </TableRow>
                                    )
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={9}><Box className={"text-center"}>Loading...</Box></TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

DatabaseCodesQADialog.propTypes = {
};

export default DatabaseCodesQADialog
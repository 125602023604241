import React, { useMemo } from "react";
import {  useSelector } from "react-redux";

import {parseDateLocalizedHours} from "shared/utilities/dateHelpers";
import MaterialUiDataTable from "components/common/Base/MaterialUiDataTable";

function TimeFramesBox() {
  const {
    selectedBlobTimeIncrement,
    timeframes,
  } = useSelector(state => state.productData)
  const timeframeData = useMemo(() => {
    return timeframes[selectedBlobTimeIncrement] || {}
  }, [
    selectedBlobTimeIncrement,
    timeframes
  ])
  const formatter = (cellVal) => cellVal ? parseDateLocalizedHours(cellVal) : 'N/A'

  return (
    <MaterialUiDataTable
      hasPagination={false}
      columns={[
        { id: 'first_added', label: 'First Add', formatter },
        { id: 'from_date', label: 'From Date', formatter },
        { id: 'to_date', label: 'To Date', formatter },
        { id: 'last_update', label: 'Last Update', formatter },
        { id: 'last_load_attempt', label: 'Last Load Attempt', formatter },
      ]}
      rows={[
        timeframeData
      ]}
    />
  );
}

export default  TimeFramesBox

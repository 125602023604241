import { useState } from 'react';
import { saveIndividualQareportApi, saveQARangeApi, triggerFrontendProductQA, updateFrontendQABatch } from 'store/slices/FrontendQA';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';
const cluster = process.env.REACT_APP_QA_CLUSTER

const useRunIndividualQA = (dispatch, callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [qaResults, setQAResults] = useState([])

    const errorBlock = (error) => {
        setIsLoading(false)
        setQAResults([])
        // dispatch(showSnackbarMessage({
        //     message: `Something went wrong. Please kindly try again later`,
        //     severity: 'error'
        // }))
        console.log(error, 'in the run individual qa hook')
    }

    const triggerRequest = async ({
        feedSource,
        databaseCode,
        productCode
    }) => {
        if (isLoading) return
        setIsLoading(true)
        const { payload: { qaRange, qaReport, productUid } } = await dispatch(saveIndividualQareportApi({
            feedSource,
            databaseCode,
            productCode
        }))
        if(qaRange){
            try {
                const r = await dispatch(triggerFrontendProductQA({
                    payload: {
                        cluster,
                        productUid,
                        reportId: qaRange.id,
                        isIndividual: 1
                    }
                }))
                if (r && r.payload && Array.isArray(r.payload)) {
                    const newResults = r.payload
                    setQAResults(newResults)
                    console.log(newResults, 'newResults')
                    let newScore = 0
                    const scoreResults = newResults.filter(item => item.score && item.score > 0)
                    if (scoreResults.length > 0) {
                        const sum = scoreResults.reduce((acc, obj) => acc + obj.score, 0);
                        newScore = sum / scoreResults.length
                    }
                    if(newScore){
                        const { payload } = await dispatch(saveQARangeApi({
                            id: qaRange.id,
                            score: newScore,
                            moveNext: 0
                        }))
                    }
                    setIsLoading(false);
                    callback()
                    return r;
                } else {
                    let _errorType = 400
                    if ((r.payload && r.payload.status == 503) || !r.payload) {
                        dispatch(showSnackbarMessage({
                            message: `503 error happened in running QA process. Please kindly try again later`,
                            severity: 'error'
                        }))
                        _errorType = 503
                    } else if (r.payload.status == 500) {
                        dispatch(showSnackbarMessage({
                            message: `500 error happened in running QA process. Please kindly try again later`,
                            severity: 'error'
                        }))
                        _errorType = 500
                        return
                    } else if (r.payload.status === "cancelled") {
                        return
                    }
                    errorBlock()
                }
            } catch (error) {
                errorBlock(error)
            }
        }
        setIsLoading(false)
        setQAResults([])
    };

    return [triggerRequest, {
        isLoading,
        qaResults
    }];
};

export default useRunIndividualQA;
import React from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from 'react-fusioncharts';

ReactFC.fcRoot(FusionCharts, TimeSeries);
FusionCharts.options.license({
    key:
        "BzA11vmaG5E1E3A16A3A5A3C1A1B3D1E2gvxC3E5qyuiI-7B6B1mA-13tF5H2E4J2A4D4C3C6E2A2F4F1B1A7C8A3cE-11B-9F2A2H2vB2D3D2knyE1C3C11gapB4E4D4kH-7C-21D7E4F4C1H5G1G1I4B6C1E3pzzC1G4AB3zD-13mD1e1H3A33fb1qD8B1B4B1B1B1G1B17A1B3B1D6C4A2i== ",
    creditLabel: false
});

const jsonify = res => res.json();
const dataFetch = fetch(
    "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/column-chart-with-time-axis-data.json"
).then(jsonify);
const schemaFetch = fetch(
    "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/column-chart-with-time-axis-schema.json"
).then(jsonify);

const dataSource = {
    chart: {
        showlegend: 0
    },
    caption: {
        text: "Daily Visitors Count of a Website"
    },
    yaxis: [
        {
            plot: {
                value: "Daily Visitors",
                type: "column"
            },
            format: {
                suffix: "k"
            },
            title: "Daily Visitors Count"
        }
    ]
};


class PlottedSeriesChart extends React.Component {
    constructor(props) {
        super(props);
        this.onFetchData = this.onFetchData.bind(this);
        this.state = {
            timeseriesDs: {
                type: "timeseries",
                renderAt: "container",
                width: "600",
                height: "400",
                dataSource
            }
        };
    }

    componentDidMount() {
        this.onFetchData();
    }

    onFetchData() {
        Promise.all([dataFetch, schemaFetch]).then(res => {
            const data = res[0];
            const schema = res[1];
            const fusionTable = new FusionCharts.DataStore().createDataTable(
                data,
                schema
            );
            const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
            timeseriesDs.dataSource.data = fusionTable;
            this.setState({
                timeseriesDs
            });
        });
    }

    render() {
        return (
            <div>
                {this.state.timeseriesDs.dataSource.data ? (
                    <ReactFC {...this.state.timeseriesDs} />
                ) : (
                    "loading"
                )}
            </div>
        );
    }
}
export default PlottedSeriesChart;
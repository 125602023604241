import React from "react";

import {format} from "d3-format";
import {timeFormat} from "d3-time-format";
import {
    AreaSeries, StraightLine, AlternatingFillAreaSeries
} from "../../../../../libs/rrag/lib/series";

import { Chart } from "../../../../../libs/rrag";
import {XAxis, YAxis} from "../../../../../libs/rrag/lib/axes";
import {MouseCoordinateY, MouseCoordinateX} from "../../../../../libs/rrag/lib/coordinates";
import {
    SingleValueTooltip
} from "../../../../../libs/rrag/lib/tooltip";

export default function FiEMAIndicator(props) {
    const {fiEMACalc} = props.indicator

    return (
        <>
            {/* <AreaSeries baseAt={scale => scale(0)} yAccessor={fiEMACalc.accessor()} /> */}
            <AlternatingFillAreaSeries
                baseAt={0}
                yAccessor={fiEMACalc.accessor()}
            />
            <StraightLine yValue={0} />

            <SingleValueTooltip
                yAccessor={fiEMACalc.accessor()}
                yLabel={`ForceIndex (${fiEMACalc.options().windowSize})`}
                yDisplayFormat={format(".4s")}
                origin={[-40, 15]}
            />
        </>
    );
}
import React from 'react'
import { SvgIcon } from "@mui/material";

const DefaultDataSetLogoIcon = (props) => {
  return (
    <SvgIcon
      style={{ color: 'white' }}
      {...props}
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
        <path d="M0.5 2C0.5 0.895431 1.39543 0 2.5 0H30.5C31.6046 0 32.5 0.895431 32.5 2V30C32.5 31.1046 31.6046 32 30.5 32H2.5C1.39543 32 0.5 31.1046 0.5 30V2Z" fill="url(#paint0_linear_2146_18347)"/>
        <defs>
            <linearGradient id="paint0_linear_2146_18347" x1="0.5" y1="0" x2="36.4471" y2="5.31762" gradientUnits="userSpaceOnUse">
                <stop stopColor="#EECDA3"/>
                <stop offset="1" stopColor="#EF629F"/>
            </linearGradient>
        </defs>
    </SvgIcon>
    
    // fill="none" xmlns="http://www.w3.org/2000/svg"
  );
};

export default DefaultDataSetLogoIcon;

import * as React from 'react';

import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px"
  },
  TableCap: {
    padding: '13px',
    alignItems: 'center',
    background: 'white',
    boxShadow: '14px 6px 4px -21px rgba(0,0,0,0.2), 0px -4px 2px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  Table: {
    marginTop: '9px',
    background: 'white'
  },
  TableTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '140%',
    margin: '0px',
    fontSize: '14px',
    letterSpacing: '-0.006em',
    fontWeight: '600',
    color: 'rgb(67, 74, 84)',
    marginRight: '10px'
  },
  th: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 1,
  },
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function ResultStatusArea({
  rows = []
}) {
  const history = useHistory()
  const classes = useStyles()

  const statusData = React.useMemo(() => {
    return rows.map(item => {
      const countPerRange = Math.ceil(item.total_products / 100 * item.range_percentage)
      const progressedPercentage = (item.progressed_count/countPerRange * 100).toFixed(2)
      return {
        ...item,
        count_per_range: countPerRange,
        progressed_percentage: progressedPercentage > 100 ? 100 : progressedPercentage
      }
    })
  }, [
    rows
  ])
  // console.log(results, 'results')

  return (
    <>
    <TableContainer className={classes.root}>
      {/* <Box display="flex" sx={{ justifyContent: 'space-between' }} className={classes.TableCap}>
        <Box display={"flex"} alignItems="center" justifyContent={'space-between'}>
          <Box display={"flex"} alignItems="center">
            <Typography className={classes.TableTitle}>Status of Ongoing  {rows.length} QAs</Typography>
            <Typography className={classes.TableTitle}>{`(${completedCount}/${total})`}</Typography>
          </Box>
        </Box>
      </Box> */}
      <Table sx={{ minWidth: 650 }} className={classes.Table} aria-label="qa report table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>Range Percentage</TableCell>
            <TableCell className={classes.th}>Report</TableCell>
            <TableCell className={classes.th}>Started At</TableCell>
            <TableCell className={classes.th}>Issued At</TableCell>
            <TableCell className={classes.th}>% Completed</TableCell>
            <TableCell className={classes.th}>Range Index</TableCell>
            <TableCell className={classes.th}>Score</TableCell>
            <TableCell className={classes.th}>Note</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            statusData && statusData.length > 0 ? (
              <>
                {
                  statusData.map((r, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    >
                      <TableCell>
                        <span>{`${r.range_percentage} (${r.run_type})`}</span>
                      </TableCell>
                      <TableCell>
                        <Button color='error' onClick={() => {
                          let url = `/qa-result-mockup/${r.qa_report_id}`
                          history.push(url)
                        }}>View Report</Button>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Started At">
                          <span>
                            {new Date(r.created_at).toLocaleDateString(undefined, {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                            })}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Issued At">
                          <span>
                            {new Date(r.updated_at).toLocaleDateString(undefined, {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                            })}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <BorderLinearProgress variant="determinate" value={r.progressed_percentage > 0 ? Number(r.progressed_percentage) : 0} />
                          <Box display="flex" sx={{ justifyContent: 'space-between' }}>
                            <span>{`${r.progressed_percentage || 0}%`}</span>
                            <span>{`${r.progressed_count || 0}/${r.count_per_range}`}</span>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Range Index">
                          <span>{ r.range_index }</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Score">
                          <span>
                            {r.score ? Number(r.score).toFixed(2) : 'N/A'}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <p style={{ maxWidth: '200px' }}>
                          {r.note ? <span>{r.note}</span> : (
                            r.paused_product ? (<span>Paused</span>) : (<span>Need to be confirmed to start</span>)
                          )}
                        </p>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </>
            ) : (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={8}>
                  There is no Data
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
import React, { useEffect, useMemo } from 'react'
import _ from "underscore"

import { makeStyles } from "@mui/styles";
import MaterialTable from "material-table";
import { Box, TableCell, TableHead, TableRow } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    table: {
        borderCollapse: 'collapse',
        width: '100%',
    },
    TableRoot:{
        "& .MuiTableBody-root": {
            '& .MuiTableRow-root': {
                '& td': {
                    background: 'white',
                },
                '& .MuiTableCell-root:nth-child(1)': {
                    position: 'sticky',
                    zIndex: 1,
                    left: 0,
                    minWidth: '100px'
                },
                '& .MuiTableCell-root:nth-child(2)': {
                    position: 'sticky',
                    zIndex: 1,
                    left: '100px',
                },
                '& .MuiTableCell-root:nth-child(3)': {
                    position: 'sticky',
                    zIndex: 1,
                    left: '200px',
                }
            },
        }
    },
    th: {
        backgroundColor: '#f2f2f2',
        border: '1px solid #ddd',
        padding: '8px',
        // textAlign: 'left',
    },
    td: {
        border: '1px solid #ddd',
        padding: '8px',
        // textAlign: 'left',
    },
    tr: {
        '&:nth-child(even)': {
            backgroundColor: '#f2f2f2',
        },
    },
    stickTD: {
        position: 'sticky',
        left: 0,
        "&:nth-child(1)": {
            minWidth: '100px'
        },
        "&:nth-child(2)": {
            left: '100px',
        },
        "&:nth-child(3)": {
            left: '200px',
        }
    }
}))

const getHeaderRows = (columns) => {
    const headerRows = [];
    const allColumns = []
    function updateParentColSpan(cell, columnLength){
        const parentColIdx = headerRows[cell.depth - 1].findIndex(item => item.id === cell.parentId)
        if (parentColIdx > -1) {
            const parentCol = headerRows[cell.depth - 1][parentColIdx]
            headerRows[cell.depth - 1][parentColIdx].colSpan += parentCol.colSpan + (columnLength - 1)
            if(parentCol.depth > 0){
                updateParentColSpan(parentCol, columnLength)
            }
        }
    }
    function processColumns(cols, depth, parentId = null) {
        if (headerRows.length <= depth) {
            headerRows.push([]);
        }

        cols.forEach((col) => {
            const cell = {
                id: col.id,
                label: col.label,
                colSpan: col.columns ? col.columns.length : 1,
                rowSpan: col.rowSpan || null,
                depth: depth,
                parentId: parentId,
                width: col.width,
                align: col.align,
                sticky: col.sticky
            };

            headerRows[depth].push(cell);

            if (col.columns) {
                //If Sub column has got its own sub columns, update a current give column's parent's colspan 
                if (cell.depth > 0) {
                    updateParentColSpan(cell, col?.columns?.length)
                    // const parentColIdx = headerRows[cell.depth - 1].findIndex(item => item.id === cell.parentId)
                    // if (parentColIdx > -1) {
                    //     const parentCol = headerRows[cell.depth - 1][parentColIdx]
                    //     headerRows[cell.depth - 1][parentColIdx].colSpan += parentCol.colSpan + (col.columns.length - 1)
                    // }
                }
                processColumns(col.columns, depth + 1, cell.id);
            } else {
                const _column = {
                    title: col.label,
                    field: col.id,
                    width: col.width,
                    align: col.align,
                }
                if (col.id === 'lineNumber') {
                    // _column.tableData = {
                    //     // "columnOrder": 0,
                    //     // "groupSort": "asc",
                    //     // "width": "calc((100% - (0px + 20% + 70%)) / 2)",
                    //     // "initialWidth": "calc((100% - (0px + 20% + 70%)) / 2)",
                    //     // "additionalWidth": 0,
                    //     id: 0
                    // };
                    // _column.render = rawData => rawData.tableData.id + 1
                } else if (col.id === 'line') {
                    _column.render = (rawData, idx) => {
                        return <Box sx={{ marginLeft: rawData?.tableData?.path?.length * 2, fontWeight: rawData.tableData.childRows ? 'bold' : '' }}>
                            {rawData.line}
                        </Box>
                    }
                }
                allColumns.push(_column)
            }
        });
    }

    processColumns(columns, 0);

    return {
        headerRows,
        allColumns
    };
}

function autoIncrement(data, start) {
    let index = start;
    for (const item of data) {
        item.lineNumber = index++;
        // console.log(item, 'item')
        if (item.tableData.childRows) {
            index = autoIncrement(item.tableData.childRows, index);
        }
    }
    return index;
}

function TransformTable({ columns, data, tableId }) {
    const classes = useStyles()
    const { headerRows, allColumns } = getHeaderRows(columns, classes.stickTD)
    // console.log(headerRows, 'headerRows')
    // console.log(allColumns, 'allColumns')
    // console.log(data, 'data')

    return <>
        <Box className={classes.TableRoot} id={tableId}>
            <MaterialTable
                columns={allColumns}
                data={data}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                    // selection: true,
                    // fixedColumns: {
                    //     left: 3,
                    //     right: 0
                    // }
                }}
                title=""
                parentChildData={(row, rows) => rows.find(a => a.id !== a.parentId && a.id === row.parentId)}
                components={{
                    Header: props => {
                        return (
                            <TableHead>
                                {
                                    headerRows.map((cols, idx) => {
                                        return <TableRow>
                                            {
                                                idx === 0 && <TableCell className={`${classes.th} ${classes.stickTD}`} rowSpan={headerRows.length}></TableCell>
                                            }
                                            {
                                                cols.map((col, colIdx) => {
                                                    const rowSpan = col.rowSpan > 1 ? col.rowSpan : (col.colSpan < 2 ? headerRows.length - col.depth : 1)
                                                    return <TableCell
                                                        className={`${classes.th} ${col.sticky ? classes.stickTD : ""}`}
                                                        align={col.align || ''}
                                                        colSpan={col.colSpan}
                                                        rowSpan={rowSpan}
                                                        style={{ minWidth: col.width ? col.width : '' }}
                                                        key={colIdx}
                                                    >
                                                        {
                                                            col.id === 'line' ? (
                                                                <Box minWidth="300px">
                                                                    {col.label}
                                                                </Box>
                                                            ) : (<>{col.label}</>)
                                                        }
                                                    </TableCell>
                                                })
                                            }
                                        </TableRow>
                                    })
                                }
                            </TableHead>
                        );
                    },
                    // Row: (_props) => {
                    //     console.log(_props, '_props')
                    //     // return (
                    //     //     <TableRow>
                    //     //         {/* <TableCell>{data.approve}</TableCell>
                    //     //         <TableCell align="center">{data.inTime}</TableCell>
                    //     //         <TableCell align="center">{data.inDate}</TableCell>
                    //     //         <TableCell align="center">{data.outTime}</TableCell>
                    //     //         <TableCell align="center">{data.outDate}</TableCell>
                    //     //         <TableCell align="center">{data.action}</TableCell> */}
                    //     //     </TableRow>
                    //     // );
                    // }
                }}
            />
        </Box>
    </>
}

export default TransformTable
import React from "react";

import {makeStyles} from "@mui/styles";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TableHead,
    Paper
} from "@mui/material";


const useStyles = makeStyles(theme => ({
    bold: { fontWeight: "bold" },
    italic: { fontStyle: "italic" },
    underline: { textDecoration: "underline" }
}));

export default function MaterialUiDataTable(props) {
    const { rows, columns, hasPagination, paginationProps, triggerChange } = props
    const onCellCliks = props.onCellCliks || []

    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState( paginationProps ? (paginationProps.rowsPerPage || 25) : null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if(triggerChange){
            triggerChange('page', newPage)
        }
    };
  
    const handleChangeRowsPerPage = (event) => {
        const newVal = parseInt(event.target.value, 10)
        setRowsPerPage(newVal);
        setPage(0);
        if(triggerChange){
            triggerChange('rowsPerPage', newVal)
        }
    };

    const classes = useStyles()
    return (
        <>
            {
                <>
                    <Paper>
                        <TableContainer className={classes.TableContainer}>
                            <Table
                                stickyHeader
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="enhanced table"
                            >
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => {
                                            const { options } = column
                                            return <TableCell
                                                key={column.id}
                                                align={'left'}
                                                // padding='normal'
                                                style={{ minWidth: 'auto' }}
                                            >
                                                {
                                                    options && options.customHeadLabelRender ?
                                                        options.customHeadLabelRender(column.label) :
                                                        <div className={classes.TableCellFilter}>
                                                            <span>{column.label}</span>
                                                        </div>         
                                                }
                                            </TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows.map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={index}
                                                >
                                                    {
                                                        columns?.map((column, c_index) => {
                                                            let rowInd = index
                                                            let cellVal = column.formatter ? column.formatter(row[column.id]) : row[column.id];
                                                            if(column.autoIncrement){
                                                                rowInd += 1
                                                                if(hasPagination){
                                                                    rowInd += page * rowsPerPage
                                                                }
                                                                cellVal = rowInd
                                                            }
                                                            return <TableCell key={c_index} component="th" scope="row">
                                                                {
                                                                    onCellCliks[column.id] !== undefined ?
                                                                        <a href='#' onClick={() => onCellCliks[column.id](row)}>
                                                                            <span title={cellVal}>{cellVal}</span>
                                                                        </a> :
                                                                        <span title={cellVal}>{cellVal}</span>
                                                                }
                                                            </TableCell>
                                                        })
                                                    }
                                                </TableRow>
                                            );
                                        })
                                    }
                                    {rows.length < 1 && (
                                        <TableRow style={{ height: 53 }}>
                                            <TableCell colSpan={columns.length + 1} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            hasPagination && <TablePagination
                                {...paginationProps}
                                component="div"
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        }
                    </Paper>
                </>
            }
        </>
    );
}
import React, { useState, useMemo } from 'react';
import { makeStyles } from "@mui/styles";

import {
  Grid,
  Box,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import ExpandablePanelBox from "components/common/Base/ExpandablePanelBox";

const useStyles = makeStyles(() => ({
  AccordionItem: {
    marginBottom: 10
  },
  ParagraphHeader: {
    fontWeight: 'bold',
    color: '#000000a6',
    fontSize: '12px',
    textTransform: 'uppercase',
    margin: '3px 0px'
  }
}));

export const AdvancedCustomizePanel = () => {
  const { series } = useSelector(state => state.productData)
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState('series1')

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} md={12} xs={12}>
        <Box className={classes.ParagraphHeader}>
          Indicators:
        </Box>
        <ExpandablePanelBox panel={{label: 'Acceleration Bands'}} collapsed={true}>
          <h1>Acceleration Band</h1>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'DEMA (Double Exponential Moving Average)'}} collapsed={true}>
          <h1>DEMA (Double Exponential Moving Average)</h1>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Axes'}} collapsed={true}>
          <h1>Axes</h1>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Pivot Points'}} collapsed={true}>
          <h1>Pivot Points</h1>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Super Trend'}} collapsed={true}>
          <h1>Super Trend</h1>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Volume by Price (VBP)'}} collapsed={true}>
          <h1>Volume by Price (VBP)</h1>
        </ExpandablePanelBox>
        <ExpandablePanelBox panel={{label: 'Zigzag'}} collapsed={true}>
          <h1>Zigzag</h1>
        </ExpandablePanelBox>
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Acceleration Bands"*/}
        {/*  isExpanded={expandedPanel === 'acceleration-bands'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('acceleration-bands')}*/}
        {/*>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="DEMA (Double Exponential Moving Average)"*/}
        {/*  isExpanded={expandedPanel === 'dema'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('dema')}*/}
        {/*>*/}
        {/*  <h1>DEMA (Double Exponential Moving Average)</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Axes"*/}
        {/*  isExpanded={expandedPanel === 'axes'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('axes')}*/}
        {/*>*/}
        {/*  <h1>axes</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Pivot Points"*/}
        {/*  isExpanded={expandedPanel === 'pivot-points'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('pivot-points')}*/}
        {/*>*/}
        {/*  <h1>pivot-points</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Super Trend"*/}
        {/*  isExpanded={expandedPanel === 'super-trend'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('super-trend')}*/}
        {/*>*/}
        {/*  <h1>super-trend</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Volume by Price (VBP)"*/}
        {/*  isExpanded={expandedPanel === 'vbp'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('vbp')}*/}
        {/*>*/}
        {/*  <h1>vbp</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Zigzag"*/}
        {/*  isExpanded={expandedPanel === 'zigzag'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('zigzag')}*/}
        {/*>*/}
        {/*  <h1>zigzag</h1>*/}
        {/*</BaseAccordion>*/}
        <Box className={classes.ParagraphHeader}>
          Flags:
        </Box>
        <ExpandablePanelBox panel={{label: 'Flags'}} collapsed={true}>
          <h1>Flags</h1>
        </ExpandablePanelBox>
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Flags"*/}
        {/*  isExpanded={expandedPanel === 'flags'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('flags')}*/}
        {/*>*/}
        {/*  <h1>flags</h1>*/}
        {/*</BaseAccordion>*/}
        <Box className={classes.ParagraphHeader}>
          Annotations:
        </Box>
        <ExpandablePanelBox panel={{label: 'Annotations'}} collapsed={true}>
          <h1>Annotations</h1>
        </ExpandablePanelBox>
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Annotations"*/}
        {/*  isExpanded={expandedPanel === 'annotations'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('annotations')}*/}
        {/*>*/}
        {/*  <h1>annotations</h1>*/}
        {/*</BaseAccordion>*/}
      </Grid>
    </Grid>
  )
};

import React from 'react';
import {
    Typography,
} from "@mui/material"

import { makeStyles } from '@mui/styles';
import { Autorenew } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
    QualityAnaylicCap: {
        // fontSize: '34px',
        padding: '5px 9px',
        lineHeight: 1,
        fontSize: '17px',
        border: 'solid 1px #cfc7c7',
        borderRadius: '3px',
        transition: 'all ease-in .3s',
        cursor: 'pointer',
        "&:hover": {
            background: 'white'
        }
    },
    '@keyframes rotate-circle': {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
    QualityAnaylicProgress: {
        // fontSize: '39px !important',
        // padding: 0,
        fontSize: '27px !important',
        margin: '3px !important',
        animation: '$rotate-circle 2s linear infinite',
    }
}))

export default function QLink(props) {
    const classes = useStyles();

    return (
        <>
            {
                props.inProgress ? 
                    <Autorenew className={classes.QualityAnaylicProgress}/> :
                    <Typography className={`${classes.QualityAnaylicCap}`} onClick={props.onClick}>Q</Typography>
            }
        </>
    );
}
import {logout, manualLogout, setSessionExpired} from "store/slices/AuthenticationSlice";
import LocalStorageService from "store/services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

export default store => next => action => {
  // console.log(action, 'action')
  if(action.type.endsWith('/rejected')) {
    // if(action.error && action.error.message === 'failed_refresh_auth') {
    //   localStorageService.setRefreshingToken('idle');
    //   store.dispatch(setSessionExpired(true));

    //   if(action.type === 'authentication/login/rejected')
    //     store.dispatch(manualLogout());
    //   else
    //     store.dispatch(logout());
    // }
  }

  return next(action);
}
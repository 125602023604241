import { createSelector } from "@reduxjs/toolkit";

export const selectConfigByTypeAndPath = createSelector(
    (state) => state.highChartData,
    (state, params) => params,
    (configurations, {chartType, path}) => {
        if(typeof configurations == "undefined")
            return {};
      
        if(!chartType || (chartType && !configurations[chartType]))
            return {};
        if(!path)
            return {}
        return configurations[chartType][path] || {}
    }
);
export const selectConfigByPath = createSelector(
    (state) => state.highChartData.fieldConfigs,
    (state, path) => path,
    (configurations, path) => {
        if(typeof configurations == "undefined")
            return {};
      
        if(!path)
            return {}
        return configurations[path] || {}
    }
);

import React from 'react';
import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import Chart from './Chart';
import { getData } from "./utils"

import { TypeChooser } from "libs/rrag/lib/helper";

class ChartComponent extends React.Component {
	componentDidMount() {
		getData().then(data => {
			this.setState({ data })
		})
	}
	render() {
		if (this.state == null) {
			return <div>Loading...</div>
		}
		return (
			<TypeChooser>
				{type => <Chart type={type} data={this.state.data} />}
			</TypeChooser>
		)
	}
}


export default compose(
    withRouter,
    connect(null, null),
)(ChartComponent);

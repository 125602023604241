import React from "react";

import { curveMonotoneX } from "d3-shape";
import {createVerticalLinearGradient, hexToRGBA} from "libs/rrag/lib/utils";
import {
    LineSeries, AreaSeries, CandlestickSeries, BarSeries, GroupedBarSeries, StackedBarSeries, KagiSeries,
    PointAndFigureSeries,
    RenkoSeries,
	ScatterSeries, CircleMarker, SquareMarker, TriangleMarker
} from "libs/rrag/lib/series";
// import {
// 	SquareMarker,
// 	TriangleMarker,
// } from "react-stockcharts/lib/series";


import {
    EdgeIndicator,
    CurrentCoordinate
} from "libs/rrag/lib/coordinates";

import {
    OHLCTooltip,
    MovingAverageTooltip,
} from "libs/rrag/lib/tooltip";

import { ema } from "libs/rrag/lib/indicator";
const canvasGradient = createVerticalLinearGradient([
    { stop: 0, color: hexToRGBA("#b5d0ff", 0.2) },
    { stop: 0.7, color: hexToRGBA("#6fa4fc", 0.4) },
    { stop: 1, color: hexToRGBA("#4286f4", 0.8) },
]);

export function LineChart(props) {
    const { yAccessor, stroke, highlightOnHover } = props; 

    return (
        <>
            <LineSeries
                yAccessor={yAccessor || (d => d.open)}
                fill="url(#MyGradient)"
                strokeWidth={2}
                stroke={stroke || '#4682B4'}
                highlightOnHover={highlightOnHover}
                /*yAccessor={ema20.accessor()} stroke={ema20.stroke()}*//>
        </>
    )
}

export function MultipleLineChart(props) {
    const ema20 = ema()
        .id(0)
        .options({ windowSize: 20 })
        .merge((d, c) => { d.ema20 = c; })
        .accessor(d => d.ema20);

    const ema50 = ema()
        .id(2)
        .options({ windowSize: 50 })
        .merge((d, c) => { d.ema50 = c; })
        .accessor(d => d.ema50);

    return (
        <>
            <CandlestickSeries />
            <LineSeries yAccessor={ema20.accessor()} stroke={ema20.stroke()}/>
            <LineSeries yAccessor={ema50.accessor()} stroke={ema50.stroke()}/>

            <CurrentCoordinate yAccessor={ema20.accessor()} fill={ema20.stroke()} />
            <CurrentCoordinate yAccessor={ema50.accessor()} fill={ema50.stroke()} />

            <EdgeIndicator itemType="last" orient="right" edgeAt="right"
                           yAccessor={ema20.accessor()} fill={ema20.fill()}/>
            <EdgeIndicator itemType="last" orient="right" edgeAt="right"
                           yAccessor={ema50.accessor()} fill={ema50.fill()}/>
            <EdgeIndicator itemType="last" orient="right" edgeAt="right"
                           yAccessor={d => d.open} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"}/>
            <EdgeIndicator itemType="first" orient="left" edgeAt="left"
                           yAccessor={ema20.accessor()} fill={ema20.fill()}/>
            <EdgeIndicator itemType="first" orient="left" edgeAt="left"
                           yAccessor={ema50.accessor()} fill={ema50.fill()}/>
            <EdgeIndicator itemType="first" orient="left" edgeAt="left"
                           yAccessor={d => d.open} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"}/>

            <OHLCTooltip origin={[-40, 0]}/>
            <MovingAverageTooltip
                onClick={e => {}}
                origin={[-38, 15]}
                options={[
                    {
                        yAccessor: ema20.accessor(),
                        type: "EMA",
                        stroke: ema20.stroke(),
                        windowSize: ema20.options().windowSize,
                    },
                    {
                        yAccessor: ema50.accessor(),
                        type: "EMA",
                        stroke: ema50.stroke(),
                        windowSize: ema50.options().windowSize,
                    },
                ]}
            />
        </>
    )
}

export function AreaChart(props) {
    const { yAccessor, strokes, highlightOnHover } = props; 
    return (
        <>
            <defs>
                <linearGradient id="MyGradient" x1="0" y1="100%" x2="0" y2="0%">
                    <stop offset="0%" stopColor={strokes && strokes[0] ? strokes[0] : '#b5d0ff'} stopOpacity={0.2} />
                    <stop offset="70%" stopColor={strokes && strokes[1] ? strokes[1] : '#6fa4fc'} stopOpacity={0.4} />
                    <stop offset="100%" stopColor={strokes && strokes[2] ? strokes[2] : '#4286f4'} stopOpacity={0.8} />
                </linearGradient>
            </defs>
            <AreaSeries
                yAccessor={yAccessor || (d => d.open)}
                fill="url(#MyGradient)"
                strokeWidth={2}
                stroke={strokes && strokes[2] ? strokes[2] : '#4682B4'}
                interpolation={curveMonotoneX}
                canvasGradient={canvasGradient}
            />
        </>
    )
}

export function CandlestickChart(props) {
    let candlesAppearance = {
        ...props.appearance,
        opacity: 1
    }
    const { type } = props
    if (type === 'hollow-candle'){
        candlesAppearance = {
            wickStroke: "#225437",
            fill: function fill(d) {
                // return d.close > d.open ? "#ff6960" : "rgba(196, 205, 211, 0.8)";
                return d.close > d.open ? "#FF0000" : "#6BA583" ;
            },
            stroke: "#225437",
            candleStrokeWidth: 1,
            widthRatio: 0.8,
            opacity: 1,
        }
    }
    return (
        <>
            <CandlestickSeries {...candlesAppearance}/>
        </>
    )
}

export function BarChart(props) {
    const { yAccessor, fill, highlightOnHover } = props; 
    return (
        <>
            <BarSeries yAccessor={yAccessor || (d => d.open)} fill={fill || (d => d.close > d.open ? "#6BA583" : "#FF0000")} opacity={1} />
        </>
    )
}


export function GroupedBarChart(props) {
    const { yAccessor } = props;
    const fills = ['#d44444','#2121ca', '#43d643','#9c27b0', '#e4e63e']
    const fill = (d, i) => {
        return fills[i]
    }
    return (
        <>
            <GroupedBarSeries 
                yAccessor={[d => d.volume, d => d.close, d => d.open, d => d.high, d => d.low]}
                fill={fill}
                spaceBetweenBar={3}/>
        </>
    )
}

export function StackedBarChart(props) {
    const { yAccessor } = props;
    const fills = ['#d44444','#2121ca', '#43d643','#9c27b0', '#e4e63e']
    const fill = (d, i) => {
        return fills[i]
    }
    return (
        <>
            <StackedBarSeries 
                yAccessor={[d => d.volume, d => d.close, d => d.open, d => d.high, d => d.low]}
                fill={fill}
                spaceBetweenBar={3}/>
        </>
    )
}

export function HorizontalBarChart(props) {
    return (
        <>
            <BarSeries yAccessor={d => d.date} xAccessor={d => d.open} swapScales />
        </>
    )
}


export function HorizontalStackedBarChart(props) {
    return (
        <>
            <StackedBarSeries yAccessor={d => d.date} xAccessor={d => d.open} swapScales />
        </>
    )
}

export function KagiChart(props) {
    return (
        <>
            <KagiSeries />
        </>
    )
}

export function PointAndFigureChart(props) {
    return (
        <>
            <PointAndFigureSeries />
        </>
    )
}

export function RenkoChart(props) {
    return (
        <>
            <RenkoSeries />
        </>
    )
}

export function ScatterAshiChart(props) {
    const { marker, width, fill, stroke } = props
    let scatterSeriesMarker = CircleMarker
    let markerProps = { fill: '#2ca02c', stroke: '#2ca02c', width: 6}
    if(fill && fill !== '') markerProps.fill = fill
    if(stroke && stroke !== '') markerProps.stroke = stroke
    if(width && width !== '') markerProps.width = width
    if(marker === 'square'){
        scatterSeriesMarker = SquareMarker
    }else if(marker === 'triangle'){
        scatterSeriesMarker = TriangleMarker
    }else if(marker === 'circle'){
        markerProps.r = width
    }
    return (
        <>
            <LineSeries
                yAccessor={d => d.close}
                stroke={markerProps.stroke}
                strokeDasharray="LongDash" />
            <ScatterSeries
                yAccessor={d => d.close}
                marker={scatterSeriesMarker}
                markerProps={markerProps} 
            />
        </>
    )
}
import React, {useMemo} from 'react';
import PieChartIcon from '@mui/icons-material/PieChart';
import {useDispatch, useSelector} from "react-redux";
import LineChartSvg from "assets/images/chart/Line.svg";
import AreaChartSvg from "assets/images/chart/Area.svg";
// import AreaRangeChartSvg from "assets/images/chart/AreaRange.svg";
import BarChartIcon from "@mui/icons-material/BarChart";
import HorizontalBarChart from "assets/images/chart/HorizontalBar.svg";
import ColumnRangeSvg from "assets/images/chart/ColumnRange.svg";
// import PointMarkerSvg from "assets/images/chart/PointMarker.svg";
// import ScatterLineChart from "assets/images/chart/ScatterLine.svg";
// import DepthChart from "assets/images/chart/DepthChart.svg";
import {setProductOpt, setXAxis} from "store/slices/StockProductSlice";
import ChartSidebar from "../details/ChartSidebar";

export const ChartPanel = () => {
  const dispatch = useDispatch()
  const { series, fields, xAxisFields } = useSelector(state => state.productData)
  const chartTypes = [
    {
      value: 'line',
      label: 'Line',
      svg: LineChartSvg,
      description: 'A series of datapoints connected with a straight line'
    },
    {
      value: 'area',
      label: 'Area',
      svg: AreaChartSvg,
      description: 'Visual representation of data that utilizes both lines and filled areas to convey information'
    },
    // { value: 'arearange', label: 'Area Range', svg: AreaRangeChartSvg},
    {
      value: 'column',
      label: 'Column',
      icon: () => <BarChartIcon />,
      description: 'Display data as vertical bars'
    },
    // {
    //   value: 'bar', label: 'Bar', svg: HorizontalBarChart
    // },
    // { value: 'point-marker', label: 'Point Marker', svg: PointMarkerSvg},
    // { value: 'scatter-line', label: 'Scatter Marker Line', svg: ScatterLineChart},
    // { value: 'depth-chart', label: 'Depth Chart', svg: DepthChart},
    {
      value: 'pie',
      label: 'Pie Chart',
      icon: () => <PieChartIcon />,
      description: 'The Pie chart is showing a compact overview of a composition or comparison'
    },
  ];
  const onChangeChartType = (newType) => {
    const idx = series?.findIndex(item => item.id === 'default')
    if(idx > -1){
      dispatch(setProductOpt({ path: `series.${idx}.chartType`, value: newType }))
    }
    if(newType === 'pie'){
      const field = fields.filter(item => item.type === 'string')[0]
      const categoryField = field?.id || xAxisFields[0]?.id || fields[0]?.id;
      dispatch(setProductOpt({ path: 'categoryField', value: categoryField}))
    }
    dispatch(setXAxis(xAxisFields[0]?.id))
  }
  const chartType = useMemo(() => {
    const idx = series?.findIndex(item => item.id === 'default')
    if(idx > -1){
      return series[idx]?.chartType || 'line'
    }
    return 'line'
  }, [
    series
  ])

  return (
    <ChartSidebar
      selectedChartType={chartType}
      onSelectChartType={(value) => onChangeChartType(value)}
      chartTypes={chartTypes}
    />
  )
};

import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt} from "store/slices/StockProductSlice";
import BaseAccordion from "../../BaseAccordion";
import {DropdownSelectWithInput} from "components/common/Product/DropdownSelectWithInput";
import {makeStyles} from "@mui/styles";
import {CustomizeItem, EndNode, StartNode} from "components/common/Product/CustomizeItem";
import {Box, FormControlLabel, Button} from "@mui/material";
import {BpCheckbox} from "../../BpCheckbox";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import ColorPicker from "components/common/ColorPicker";

const useStyles = makeStyles(() => ({
  AccordionItem: {
    marginBottom: 10
  },
  Button: {
    width: '35px',
    height: '35px',
    padding: 0,
    minWidth: 0,
    fontSize: '19px'
  }
}));
export const VerticalAxis = () => {
  const dispatch = useDispatch()
  const { axes } = useSelector(state => state.productData.chartSettings)
  const classes = useStyles()

  const [expandedPanel, setExpandedPanel] = useState('')
  const [isLabelStyleOpened, setIsLabelStyleOpened] = useState(true)
  const onPanelChange = (val) => {
    if(expandedPanel === val){
      setExpandedPanel('')
    }else{
      setExpandedPanel(val)
    }
  }
  const axisFormatOptions = [
    { label: '{Value}', value: '{value}' },
    { label: '$12 (${Value})', value: '${value}' },
    { label: '12 USD ({Value} USD)', value: '{value} USD' },
    { label: '12.00 ({Value:.2f})', value: '{value:.2f}' },
  ]

  return (
    <BaseAccordion
      seriesLabel="Vertical Axis"
      isExpanded={expandedPanel === 'vertical-axis'}
      className={classes.AccordionItem}
      onChange={() => onPanelChange('vertical-axis')}
    >
      <Box display="flex" sx={{ flexFlow: 'column', gap: '5px' }}>
        <DropdownSelectWithInput
          onInputChange={(value) => {
            dispatch(setProductOpt({
              path: 'chartSettings.axes.vertical.input', value
            }))
          }}
          onSelectChange={(value) => {
            dispatch(setProductOpt({
              path: 'chartSettings.axes.vertical.select', value
            }))
          }}
          label="Axis labels format"
          selectValue={axes.vertical.select}
          inputValue={axes.vertical.input}
          options={axisFormatOptions}
        />
        <CustomizeItem>
          <StartNode>Visibility</StartNode>
          <EndNode>
            <FormControlLabel
              value="visibility"
              control={<BpCheckbox
                sx={{ padding: '4px 6px' }}
                onChange={(e) => {
                  dispatch(setProductOpt({
                    path: 'chartSettings.axes.vertical.visible', value: !!e.target.checked
                  }))
                }}
                checked={Boolean(axes?.vertical?.visible)}
              />}
              label={axes?.vertical?.visible ? 'On' : 'Off'}
              labelPlacement="start"
              sx={{ margin: 0, fontSize: '12px' }}
            />
          </EndNode>
        </CustomizeItem>
        <DropdownSelect
          onChange={(newValue) => {
            dispatch(setProductOpt({
              path: 'chartSettings.axes.vertical.opposite', value: newValue === 'right'
            }))
          }}
          selectedValue={axes?.vertical?.opposite ? 'right' : 'left'}
          placeholder="Placement"
          options={[
            {label: 'Right', id: 'right'},
            {label: 'Left', id: 'left'},
          ]}
        />
        <DropdownSelect
          onChange={(newValue) => {
            dispatch(setProductOpt({
              path: 'chartSettings.axes.vertical.reversed', value: newValue === 'desc'
            }))
          }}
          selectedValue={axes?.vertical?.reversed ? 'desc' : 'asc'}
          placeholder="Direction"
          options={[
            {label: 'Ascending', id: 'asc'},
            {label: 'Descending', id: 'desc'},
          ]}
        />
        <BaseAccordion
          seriesLabel="Label Style"
          isExpanded={isLabelStyleOpened}
          className={classes.AccordionItem}
          onChange={() => setIsLabelStyleOpened(!isLabelStyleOpened)}
        >
          <Box display="flex" sx={{ gap: '10px', alignItems: 'center' }}>
            <Button
              className={classes.Button}
              variant={axes?.vertical?.style?.fontWeight === 'normal' ? 'outlined' : 'contained'}
              onClick={() => {
                dispatch(setProductOpt({
                  path: 'chartSettings.axes.vertical.style.fontWeight',
                  value: axes?.vertical?.style?.fontWeight === 'normal' ? 'bold' : 'normal'
                }))
              }}
            >
              B
            </Button>
            <Button
              className={classes.Button}
              variant={axes?.vertical?.style?.fontStyle === 'normal' ? 'outlined' : 'contained'}
              onClick={() => {
                dispatch(setProductOpt({
                  path: 'chartSettings.axes.vertical.style.fontStyle',
                  value: axes?.vertical?.style?.fontStyle === 'normal' ? 'italic' : 'normal'
                }))
              }}
              sx={{ fontStyle: 'italic' }}
            >I</Button>
            <ColorPicker
              color={axes?.vertical?.style?.color}
              pickerLable={''}
              defaultColor="black"
              handleChange={(color) => {
                // dispatch(setProductOpt({
                //   path: `chartSettings.colors.${key}`, value: color.hex
                // }))
                dispatch(setProductOpt({
                  path: 'chartSettings.axes.vertical.style.color',
                  value: color.hex
                }))
              }}
            />
          </Box>
        </BaseAccordion>
      </Box>
    </BaseAccordion>
  )
};

import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import {
  Close,
  Check,
  Delete as DeleteIcon
} from '@mui/icons-material';

import MUIDataTable from "mui-datatables";
import CollapsableTextWrap from 'components/common/CollapsableTextWrap';

const useStyles = makeStyles(theme => ({
    Paper: {
        padding: '10px',
    },
    Box: {
        border: '1px solid #0078d7',
        padding: '11px 11px',
        margin: 5,
        marginBottom: 20,
        borderRadius: 2,
        '& h5': {
            margin: '10px 0'
        }
    },
    cellTxt: {
        fontSize: '12px'
    },
    muiDataTable: {
        "& .MuiTableCell-root": {
        //   padding: "10px",
        },
    },
    highlightCell: {
        padding: '10px',
        maxWidth: '400px',
        maxHeight: '300px'
    }
}));

export default function AnalyzeView(props) {
    const classes = useStyles();
    const { fields, data: initialData } = props
    const [ columns, setColumns ] = useState([])
    const [hAnchorEl, setHAnchorEl] = React.useState(null)

    const highlightCell = (event) => {
        setHAnchorEl(event.currentTarget)
    }
    const openhighlightCell = Boolean(hAnchorEl)

    useEffect(() => {
        if(Array.isArray(fields)){
            fields.map(f => {
                columns.push({
                    label: f.id,
                    name: f.id,
                    options: {
                        customBodyRender: (value) => {
                            return (
                                <Box maxWidth={230} className={classes.WordBreakCell} onClick={highlightCell} title={value}>
                                    <CollapsableTextWrap txt={value} txtClassName={classes.cellTxt} showToggleLink={false}/>
                                </Box>
                            );
                        },
                    },
                })
            })
            setColumns([...columns])
        }
    }, [fields])

    return (
        <>
            <Grid container spacing={1} className={classes.Paper}>
                <Grid item xs={12} lg={6} md={6}>
                        <TextField
                            select
                            label="Categorize By"
                            fullWidth
                        >
                            <MenuItem value={''}></MenuItem>
                            {
                                fields.map((f, idx) => (
                                    <MenuItem value={f.id} key={idx}>{f.id}</MenuItem>
                                ))
                            }
                        </TextField>
                </Grid>
                <Grid item xs={12}>
                    
                </Grid>
            </Grid>
            <MUIDataTable
                className={classes.muiDataTable}
                data={initialData || []}
                columns={columns}
                options={{
                    print: false,
                    download: false,
                    searchOpen: true,
                    searchAlwaysOpen: true,
                    filterType: "multiselect",
                    selectToolbarPlacement: "none",
                    selectableRowsOnClick: true,
                    selectableRowsHideCheckboxes: true,
                    // selectableRows: "multiple",
                    rowsPerPageOptions: [10, 20, 40, 80],
                    sortOrder: {
                        name: "dateDebut",
                        direction: "desc",
                    },
                    setTableProps: () => ({
                        size: "small",
                    }),
                    setFilterChipProps: (colIndex, colName, data) => {
                        return {
                            color: "secondary",
                        };
                    },
                }}
                onPageChange={() => {}}
            />
            <Popover
                id={'highlightCell-popover'}
                open={openhighlightCell}
                anchorEl={hAnchorEl}
                onClose={() => {
                    setHAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className={classes.highlightCell}>
                    {
                        hAnchorEl && hAnchorEl.innerText
                    }
                </div>
            </Popover>
        </>
    );
}
import React, { useEffect, useMemo, useState } from 'react'
import { compose } from "recompose"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router"
import _ from "underscore"

import { makeStyles } from "@mui/styles";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Paper,
    Popover,
    Button,
    IconButton,
    TableHead,
    Typography,
    ClickAwayListener,
    Tabs,
    Tab
} from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import FilterBar from './FilterBar'

import { stableSort, getComparator } from 'shared/utilities/tableUtils'
import { setDatumFilterOptions } from "store/slices/ComponentSlice"
import DataTableColumnFilterPopover from 'components/common/DataTableColumnFilterPopover'
import ColumnSettingPanel from './ColumnSetting'
import ChartSetting from './ChartSetting'
import Close from '@mui/icons-material/Close'
import StyledTooltip from 'components/common/Base/StyledTooltip'
import CustomFormInput from 'components/common/Base/CustomFormInput'
import DataLogistic from './DataLogistic'
// import IndexedDBService  from 'shared/system/indexedDB/indexedDB.service'

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
        height: '100%'
    },
    IconButton: {
        padding: 0,
        marginLeft: '10px'
    },
    TableContainer: {
        flex: 1,
        overflow: 'auto',
        // maxHeight: '300px'
        '& th': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '230px',
            '& span': {
                cursor: 'pointer'
            }
        },
        '&::-webkit-scrollbar': {
            width: '10px',
            height: '11px'
        },
        /* Track */
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1'
        },

        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            background: '#888'
        },

        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
        },
    },
    TableContainerRoot: {
        overflowX: "initial",
    },
    table: {
        minWidth: 750,
    },
    FilterBox: {
        width: '248px',
        border: 'solid 1px #aba8a8',
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 40%)'
    },
    FilterBoxHeader: {
        display: 'flex',
        padding: '0.75rem',
        alignItems: 'center',
        borderBottom: 'solid 1px rgb(0 0 0 / 10%)',
        paddingBottom: '0.4rem',
    },
    FilterBoxHeaderTxt: {
        flex: 1,
    },
    FilterBoxBody: {
        padding: '0.75rem',
    },
    FilterItem: {
        marginBottom: '10px',
        border: 'solid 1px rgb(0 0 0 / 10%)',
        borderRadius: '4px',
        padding: '12px',
        cursor: 'pointer'
    },
    NewFilterItem: {
        background: '#f1e9002e'
    },
    FilterItemHeader: {
        display: 'flex',
        alignItems: 'center',
        '& button': {
            padding: 0,

        },
        '& svg': {
            fontSize: '20px'
        }
    },
    FilterItemName: {
        flex: 1,
        fontSize: '12px'
    },
    FilterItemBody: {
        '& p': {
            fontSize: '12px'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    highlightCell: {
        padding: '10px',
        maxWidth: '400px',
        maxHeight: '300px'
    },
    NoRecord: {
        width: '100%',
        textAlign: 'center',
        padding: 12,
        color: 'rgba(0, 0, 0, 0.6) !important'
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{
                height: '100%',
                flex: 1,
                width: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column'
            }}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </Box>
    );
}

function TableView(props) {
    let { rows, fields = [], tableId } = props

    const [columns, setColumns] = useState([])

    useEffect(() => {
        const _columns = []
        if (Array.isArray(fields)) {
            fields.map(item => {
                _columns.push({
                    id: item.id,
                    disablePadding: true,
                    label: item.id.split('_').join(' ').toUpperCase(),
                    disabled: item.id === 'nId',
                    visible: item.id !== 'nId'
                })
            })
        }
        setColumns([..._columns])
    }, [fields])

    const classes = useStyles()
    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)

    const [hiddenColumns, setHiddenColumns] = React.useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }


    const [hAnchorEl, setHAnchorEl] = React.useState(null)
    const [fAnchorEl, setFAchorEl] = React.useState(null)
    const handleClose = () => {
        setHAnchorEl(null)
        setFAchorEl(null)
    }
    const openFilterPop = Boolean(fAnchorEl)

    const [openedColumn, setOpenedColumn] = useState(null)
    const filterPop = (openedColumn) => (event) => {
        setFAchorEl(event.currentTarget)
        setOpenedColumn(openedColumn)
    }
    const [columnSort, setColumnSort] = useState({})
    const [columnFilterValues, setColumnFilterValues] = useState({})


    const highlightCell = (event) => {
        setHAnchorEl(event.currentTarget)
    }
    const openhighlightCell = Boolean(hAnchorEl)

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

    const visibleColumns = useMemo(() => {
        if (hiddenColumns.length > 0) {
            return columns.filter(item => hiddenColumns.indexOf(item.id) < 0)
        }
        return columns
    }, [columns, hiddenColumns])

    const [openTooltip, setOpenTooltip] = React.useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };

    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    // const columnOrders = selectedFilter ? resolvePath(filters, selectedFilter + '.orders', {}) : {}
    // for (let orderBy in columnOrders) {
    //     console.log(orderBy, columnOrders[orderBy])
    //     rows = stableSort(rows, getComparator('asc', 'record_date'))
    // }

    const handleSaveFilter = (items) => {
        handleClose()
        setOpenedColumn(null)
        setColumnFilterValues({
            ...columnFilterValues, [openedColumn]: [...items]
        })
    }

    const [showFilterBox, setShowFilterBox] = useState(false)
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const records = useMemo(() => {
        let _rows = [...rows];
        for (let col in columnFilterValues) {
            if (columnFilterValues[col].length > 0) {
                _rows = _rows.filter(item => columnFilterValues[col].indexOf(item[col]) > -1)
            }
        }
        for (let col in columnSort) {
            _rows = stableSort(_rows, getComparator(columnSort[col], col))
        }
        return _rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }, [
        rows,
        columnSort,
        getComparator,
        columnFilterValues,
        page,
        rowsPerPage
    ])
    useEffect(() => {
        if (openedColumn) {
            const groupedData = _.groupBy(records, function (i) {
                return i[openedColumn]
            })
            const keys = _.keys(groupedData)
            const filterDatumOptions = keys.map(i => {
                if (isNaN(i)) return i
                else return Number(i)
            })
            dispatch(setDatumFilterOptions([...filterDatumOptions]))
        }
    }, [
        records,
        openedColumn,
        dispatch
    ])


    const StyledTooltipProps = useMemo(() => {
        return {
            open: openTooltip,
            arrow: true,
            placement: 'left',
            disableFocusListener: true,
            disableHoverListener: true,
            disableTouchListener: true,
            PopperProps: {
                disablePortal: true,
            },
            onClose: handleTooltipClose
        }
    }, [openTooltip, handleTooltipClose])
    const TooltipContent = useMemo(() => {
        return <Box>
            <CustomFormInput
                select
                label="Field"
                value={'product_code'}
                options={[
                    { label: 'product_code', value: 'product_code' }
                ]}
            ></CustomFormInput>
            <CustomFormInput
                select
                label="Operator"
                value={'equals'}
                options={[
                    { label: 'equals', value: 'equals' }
                ]}
            ></CustomFormInput>

            <CustomFormInput
                label="Value"
                value={'CPI'}
            ></CustomFormInput>
        </Box>
    }, [])
    return (
        columns.length > 0 && (
            <Paper className={classes.paper}>
                {
                    Object.keys(columnFilterValues).length > 0 && (
                        <FilterBar
                            filters={columnFilterValues}
                            handleClearFilter={(fKey) => {
                                const _columnFilterValues = { ...columnFilterValues }
                                delete _columnFilterValues[fKey]
                                setColumnFilterValues({ ..._columnFilterValues })
                            }}
                        />
                    )
                }
                <Box display="flex" sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: '9px' }}>
                    <Box sx={{ flex: 1 }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label="Table" />
                            <Tab label={`Columns (${visibleColumns?.length}/${columns?.length || 0})`} />
                        </Tabs>
                    </Box>
                    <Box display="flex" sx={{ justifyContent: 'flex-end', paddingRight: 1 }}>
                        {/* <ColumnVisblePop 
                            headCells={columns}
                            hiddenColumns={hiddenColumns} 
                            setHiddenColumns={setHiddenColumns}
                        /> */}
                        <IconButton className={classes.IconButton} variant="contained" color="primary" onClick={() => {
                            setShowFilterBox(!showFilterBox)
                        }}>
                            <FilterAltIcon />
                        </IconButton>
                        <ChartSetting
                            data={rows}
                            fields={fields}
                        />
                        <DataLogistic
                            data={rows}
                            fields={fields}
                        />
                    </Box>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <Box display="flex" sx={{
                        width: '100%',
                        flex: 1,
                        overflow: 'auto',
                        border: 'solid 1px rgb(0 0 0 / 10%)',
                        borderLeft: 0,
                        borderRight: 0,
                        height: '100%'
                    }}>
                        {
                            rows.length > 0 ? (
                                <>
                                    <Box className={classes.TableContainer}>
                                        <TableContainer classes={{ root: classes.TableContainerRoot }} id={records.length > 0 ? tableId : ''}>
                                            <Table
                                                stickyHeader
                                                classes={{ root: classes.table }}
                                                aria-labelledby="tableTitle"
                                                size={'medium'}
                                                aria-label="enhanced table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">No</TableCell>
                                                        {visibleColumns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={'left'}
                                                                style={{ minWidth: 'auto' }}
                                                            >
                                                                <div className={classes.TableCellFilter}>
                                                                    <div>
                                                                        <span>{column.label}</span>
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            className={classes.margin}
                                                                            size="small"
                                                                            onClick={filterPop(column.id, column.isDate ? 'date' : null)}>
                                                                            <ExpandMoreIcon fontSize="inherit" />
                                                                        </IconButton>
                                                                    </div>
                                                                </div>
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {records.map((row, index) => {
                                                        return (
                                                            <TableRow hover tabIndex={-1} key={index}>
                                                                <TableCell align="left">{index + 1}</TableCell>
                                                                {visibleColumns.map((column, c_index) => (
                                                                    <TableCell
                                                                        key={c_index}
                                                                        component="th"
                                                                        scope="row"
                                                                    >
                                                                        <span title={row[column.id]} onClick={highlightCell}>
                                                                            {
                                                                                row[column.id] instanceof Date ?
                                                                                    row[column.id].toLocaleDateString('en-US') : (
                                                                                        row[column.id] && typeof row[column.id] === 'object' ? row[column.id].value : row[column.id]
                                                                                    )
                                                                            }
                                                                        </span>
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        )
                                                    })}
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 53 * emptyRows }}> <TableCell colSpan={visibleColumns.length + 1} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    {
                                        showFilterBox && (
                                            <Box className={classes.FilterBox}>
                                                <Box className={classes.FilterBoxHeader}>
                                                    <Typography className={classes.FilterBoxHeaderTxt}>Filters</Typography>
                                                    <IconButton className={classes.FilterBoxHeaderAction} onClick={() => { setShowFilterBox(false) }}>
                                                        <Close />
                                                    </IconButton>
                                                </Box>
                                                <Box className={classes.FilterBoxBody}>
                                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                                        <div>
                                                            <StyledTooltip {...StyledTooltipProps} title={TooltipContent}>
                                                                <Box className={classes.FilterItem} onClick={handleTooltipOpen}>
                                                                    <Box className={classes.FilterItemHeader}>
                                                                        <Typography className={classes.FilterItemName}>Product Code *</Typography>
                                                                        <Box>
                                                                            <Close />
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className={classes.FilterItemBody}>
                                                                        <Typography>equals CPI</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </StyledTooltip>
                                                        </div>
                                                    </ClickAwayListener>
                                                    {/* <StyledTooltip {...StyledTooltipProps} title={TooltipContent}> */}
                                                    <Box className={`${classes.FilterItem} ${classes.NewFilterItem}`} onClick={handleTooltipOpen}>
                                                        <Box className={classes.FilterItemHeader}>
                                                            <Typography className={classes.FilterItemName}>New Filter *</Typography>
                                                            <Box>
                                                                <Close />
                                                            </Box>
                                                        </Box>
                                                        <Box className={classes.FilterItemBody}>
                                                            <Typography></Typography>
                                                        </Box>
                                                    </Box>
                                                    {/* </StyledTooltip> */}
                                                    <Box className={classes.FilterAction}>
                                                        <Button>Add Filter</Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </>
                            ) : (
                                <Typography className={classes.NoRecord}>There is no data</Typography>
                            )
                        }
                    </Box>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <ColumnSettingPanel
                        headCells={columns}
                        hiddenColumns={hiddenColumns}
                        setHiddenColumns={setHiddenColumns}
                    />
                </TabPanel>
                <Popover
                    id={'highlightCell-popover'}
                    open={openhighlightCell}
                    anchorEl={hAnchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.highlightCell}>
                        {
                            hAnchorEl && hAnchorEl.innerText
                        }
                    </div>
                </Popover>
                <DataTableColumnFilterPopover
                    open={openFilterPop}
                    anchorEl={fAnchorEl}
                    handleClose={handleClose}
                    handleSelection={handleSaveFilter}
                    direction={columnSort[openedColumn] || null}
                    filterValues={columnFilterValues[openedColumn] || []}
                    handleSortClick={(direction) => {
                        setColumnSort({
                            ...columnSort, [openedColumn]: direction
                        })
                    }}
                />
            </Paper>
        )
    )
}

const mapStateToProps = (state) => ({
    productData: state.productData,
});

const mapDispatchToProps = (dispatch) => ({})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(TableView)
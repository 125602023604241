import React from "react";

import {format} from "d3-format";
import {
    LineSeries
} from "../../../../../libs/rrag/lib/series";
import {
    SingleValueTooltip
} from "../../../../../libs/rrag/lib/tooltip";

export default function AtrIndicator(props) {
    const {indicator} = props

    return (
        <>
            <LineSeries yAccessor={indicator['calc'].accessor()} stroke={indicator['calc'].stroke()} strokeWidth={2}/>
            <SingleValueTooltip
                yAccessor={indicator['calc'].accessor()}
                yLabel={`ATR (${indicator['calc'].options().windowSize})`}
                yDisplayFormat={format(".2f")}
                /* valueStroke={atr14.stroke()} - optional prop */
                /* labelStroke="#4682B4" - optional prop */
                origin={[-40, 15]}
                {...props.toolTipApperance}/>
        </>
    );
}
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import {
    Box,
    IconButton
} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {deleteSeries} from "store/slices/StockProductSlice";

interface SeriesItem {
    id: any;
    value: string;
    label?: string;
}

interface SeriesAccordionProps {
    isExpanded?: boolean;
    seriesItem: SeriesItem;
    seriesLabel: string;
    onUpdateSerie: (field: string, value: string) => void;
    onChange?: () => void
}


const SeriesAccordion: React.FC<SeriesAccordionProps> = ({
     isExpanded = false,
     seriesItem = {},
     seriesLabel = '',
     onUpdateSerie,
     onChange = () => {},
     ...rest
}) => {
    const dispatch = useDispatch()
  const fields = useSelector((state: any) => state.productData.fields)
  const yAxisFields = useSelector((state: any) => state.productData.yAxisFields)
  return (
    <div {...rest}>
      <Accordion expanded={isExpanded} onChange={onChange} style={{ padding: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{ seriesLabel }</span>
                <span style={{ fontSize: '11px', fontWeight: 'bold', textTransform: 'capitalize' }}>{ seriesItem?.value?.split('_').join(" ") }</span>
            </Box>
        </AccordionSummary>
        <AccordionDetails>
            <DropdownSelect
                selectedValue={seriesItem?.value}
                onChange={(val: string) => onUpdateSerie('value', val)}
                options={yAxisFields}
                placeholder={'Value'}
            />
            <DropdownSelect
                selectedValue={seriesItem?.label}
                onChange={(val: string) => onUpdateSerie('label', val)}
                options={fields}
                placeholder={'Label'}
          />
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton aria-label="delete" onClick={() => dispatch(deleteSeries(seriesItem.id))}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default SeriesAccordion;

import React from 'react';

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Box, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";

import {
    IconButton,
} from "@mui/material";

import ClearIcon from '@mui/icons-material/Clear';

const useToolbarStyles = makeStyles((theme) => ({
    Toolbar: {
        display: 'flex',
        // alignItems: 'center',
        padding: '17px'
    },
    SavedFilters: {
        flex: 1,
        marginRight: '10px'
    },
    FiltersList: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '10px'
    },
    FilterItem: {
        borderRadius: '20px',
        padding: '2px 9px',
        background: '#8965e047',
        marginRight: '4px',
        marginBottom: '4px',
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            color: '#5a5a5a',
            fontSize: '12px',
            '& > b': {
                fontWeight: '500',
                fontSize: '13px'
            }
        }
    },
    FilterDelete: {
        color: '#5a5a5a',
        padding: '2px',
        marginLeft: '2px',
        '& svg': {
            fontSize: '17px'
        }
    }
}));

const FilterPop = (props) => {
    const {
        filters,
        handleClearFilter
    } = props
    const classes = useToolbarStyles();
    
    return (
        <Box className={classes.Toolbar}>
            <Box className={classes.SavedFilters}>
                <Box className={classes.FiltersList}>
                    {
                        Object.keys(filters).map(fKey => {
                            const fItem = filters[fKey] || []
                            return <Box className={classes.FilterItem} key={fKey}>
                                <Box display="flex">
                                    <Typography sx={{
                                        textTransform: 'uppercase',
                                        marginRight: '5px',
                                        fontSize: '13px',
                                        paddingTop: '2px'
                                    }}>{fKey}:</Typography>
                                    <Typography sx={{ fontSize: '13px', paddingTop: '2px' }}>
                                        {
                                            fItem.join(', ')
                                        }
                                    </Typography>
                                </Box>
                                <IconButton
                                    className={classes.FilterDelete}
                                    aria-describedby={'Filter Delete'}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleClearFilter(fKey)}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </Box>
                        })
                    }
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    user: state.user.user
});

const mapDispatchToProps = (dispatch) => ({
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(FilterPop);
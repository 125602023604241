import React, { useState } from 'react';
import {makeStyles} from "@mui/styles";
import {
    Button
} from "@mui/material";

import { DateRange, Calendar } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '13px'
    },
    operator: {
        width: '100%',
        '& > select': {
            fontFamily: 'Poppins',
            textTransform: 'uppercase',
            cursor: 'pointer',
            background: 'transparent',
            width: '100%',
            color: '#849095',
            borderRadius: '4px',
            backgroundColor: 'rgb(255, 255, 255)',
            boxShadow: '0 0px 4px 0 rgb(35 57 66 / 21%)',
            border: '1px solid transparent',
            marginBottom: '10px',
            padding: '9px 11px',
            fontSize: '13px',
            lineHeight: 1,
            '&:focus': {
                outline: 'none',
                boxShadow: '0 0 5px -1px #3d91ff'
            }
        }
    },
    inputAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& > label': {
            marginLeft: '4px'
        },
        '& .MuiButton-root.Mui-disabled': {
            color: 'white',
            boxShadow: 'none',
            background: '#6979ce',
            cursor: 'not-allowed',
        }
    }
  }));
  
export default function PlotterDateFilter(props) {
    const classes = useStyles()
    const [range, setRange] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);
    const [equalDate, setEqualDate] = useState(null)
    const [operator, setOperator] = useState('equal')
    return (
        <div className={classes.root}>
            <div className={classes.operator}>
                <select defaultValue={ operator === null ? '' : operator } onChange={(e) => {
                    setOperator(e.target.value)
                }}>
                    <option value='equal'>Equal</option>
                    <option value='between'>Between</option>
                </select>
            </div>
            {
                operator === 'equal' ? 
                    <Calendar onChange={item => setEqualDate(item)}
                        date={equalDate} /> :
                operator === 'between' ?
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => setRange([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                    /> :  
                    ''
            }
            <div className={classes.inputAction}>
                <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" size="small" component="span" disabled={operator === 'equal' && equalDate === null}>
                        Update
                    </Button>
                </label>
                <label htmlFor="contained-button-file">
                    <Button variant="contained" size="small" component="span" onClick={(e) => {
                        props.goBack(0, 'catalog')
                    }}>
                        Cancel
                    </Button>
                </label>
            </div>
        </div>
    );
}
const setPath = (object, path, value) => path
    .split('.')
    .reduce((o,p,i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)

const resolvePath = (object, path, defaultValue) => path
   .split('.')
   .reduce((o, p) => o ? (o[p] ? o[p] : defaultValue) : defaultValue, object);

const checkPath = (object, path) => {
    const args = path.split('.')
    for (var i = 1; i < args.length; i++) {
        if (!object.hasOwnProperty(args[i])) {
            return false;
        }
        object = object[args[i]];
    }
    return true;
}
export {
    setPath, resolvePath, checkPath
}
import React, { useEffect, useMemo, useState } from "react";
// import { useSelector } from 'react-redux'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import _ from 'underscore'
import Highcharts from 'highcharts/highstock'
import StockData from 'highcharts/modules/data'
import PatternFill from 'highcharts/modules/pattern-fill'
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import ReactHighcharts from 'highcharts-react-official'
import CustomAutocomplete from "components/common/Base/CustomAutocomplete";
import { Box, Divider, Grid } from "@mui/material";
StockData(Highcharts)
HighchartsMore(Highcharts);
PatternFill(Highcharts)
PatternFill(Highcharts)
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

function AreaRangeChart(props) {
    const { data: initialData, configs: {
        xAxis, yAxis, minimumField, maximumField, groupByField
    } } = props
    
    const [entities, setEntities] = useState([])
    const [selectedEntity, setSelectedEntity] = useState(null)
    useEffect(() => {
        let splittedData = []
        if(groupByField && Array.isArray(initialData)){
            splittedData = _.groupBy(initialData, function(i){ return i[groupByField] });
            const _entities = _.keys(splittedData).map(item => {
                return { label: item, value: item }
            });
            setEntities([..._entities])
        }
    }, [
        initialData, groupByField
    ])
    const seriesData = useMemo(() => {
        const _seriesData1 = []
        const _seriesData2 = []
        console.log(initialData, 'in the series memo')
        if(Array.isArray(initialData)){
            const _data = initialData.filter(item => {
                if(groupByField && selectedEntity){
                    return item[groupByField] === selectedEntity 
                }
                return true
            })
            _data.map(item => {
                const t = new Date(item[xAxis])
                _seriesData1.push({
                    x: t.getTime(),
                    high: item[maximumField] * 1,
                    low: item[minimumField] * 1
                })
                _seriesData2.push({
                    x: t.getTime(),
                    y: item[yAxis] * 1
                })
            })
        }
        return {
            seriesData1: _seriesData1,
            seriesData2: _seriesData2
        }
    }, [
        initialData, xAxis, yAxis, minimumField, maximumField, selectedEntity, groupByField
    ])
    const configStock = useMemo(() => {
        const { seriesData1, seriesData2 } = seriesData
        const _configStock = {
            xAxis: {
                crosshair: true,
                type: "datetime"
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            tooltip: {
                crosshairs: true,
                shared: true,
                // valueSuffix: "m/s",
                xDateFormat: "%A, %b %e"
            },
            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    },
                    marker: {
                        enabled: false
                    },
                    pointStart: Date.UTC(2022, 3, 3, 15),
                    pointInterval: 36e5 // one hour
                }
            },
            stockTools: {
              enabled: false,
            },
            series: [
                {
                    type: "areasplinerange",
                    color: {
                        patternIndex: 0
                    },
                    data: seriesData1
                },
                {
                    type: "spline",
                    lineWidth: 3,
                    color: "#AA00F2",
                    data: seriesData2,
                    name: 'spline chart'
                }
            ]
        }
        console.log(seriesData1, 'seriesData1')
        console.log(seriesData2, 'seriesData2')
        return _configStock
    }, [seriesData])
    const [s, setS] = useState(1)
    return (
        <Grid container spacing={1}>
            <Grid item lg={6} md={6} xs={12}>
                <Box mt={1}>
                    <CustomAutocomplete
                        options={entities}
                        onChange={(newValue) => {
                            setSelectedEntity(newValue)
                        }}
                        label="Select Entity"
                    />
                </Box>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
                <Box mb={1}>
                    <Divider />
                </Box>
                <ReactHighcharts
                    highcharts={Highcharts}
                    constructorType={"chart"}
                    options={configStock} />
            </Grid>
        </Grid>
    )
}

export default AreaRangeChart
import React, { useCallback, useMemo, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt, deleteAnnotation, addPlotBand, deletePlotBand} from "store/slices/StockProductSlice";
import CustomFormInput from "components/common/Base/CustomFormInput";
import BaseAccordion from "../../BaseAccordion";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Divider,
  IconButton,
  Button,
  Tooltip
} from "@mui/material";
import {showSnackbarMessage} from "store/slices/MessagesSystemSlice";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import {CustomizeItem, EndNode, StartNode} from "components/common/Product/CustomizeItem";
import ColorPicker from "components/common/ColorPicker";
import {DeleteOutline} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import {useProductContext} from "providers/product/ProductProvider";

const useStyles = makeStyles(() => ({
  IconButton: {
    // flexGrow: 1,
    minWidth: 0,
    padding: '4px',
    '& svg': {
      width: '20px',
      height: '20px'
    }
  }
}));

const bgColors = [
  '#fff','#fce4ec', '#ffcdd2', '#f3e5f5', '#e8eaf6', '#e3f2fd', '#d1c4e9',
  '#c5cae9', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#e0f2f1', '#e6ee9c', '#fff9c4',
  '#eeeeee', '#ffccbc'
]
export const AnnotationHighlights = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { chartSettings, plotBands } = useSelector(state => state.productData)
  const { defaultXHighlights } = useProductContext()

  const [selectedId, setSelectedId] = useState()
  const textOptions = useMemo(() => {
    return plotBands.map((item, idx) => {
      return {
        id: item.id,
        label: item?.label?.text || `Highlight ${idx + 1}`
      }
    })
  }, [
    plotBands
  ])
  const selectedBand = useMemo(() => {
    return plotBands.filter(item => item.id === selectedId)[0] || {}
  }, [
    plotBands,
    selectedId
  ])
  const onClickAdd = () => {
    if(defaultXHighlights){
      dispatch(addPlotBand(defaultXHighlights))
    }
  }
  const onClickDelete = () => {
    dispatch(deletePlotBand(selectedId))
    setSelectedId(null)
  }
  const onChangeBand = useCallback((field, newValue) => {
    const idx = plotBands.findIndex(item => item.id === selectedId)
    if(idx > -1){
      if(plotBands[idx].isFresh){
        dispatch(setProductOpt({
          path: `plotBands.${idx}.isFresh`, value: false
        }))
      }
      dispatch(setProductOpt({
        path: `plotBands.${idx}.${field}`, value: newValue
      }))
    }
  }, [
    plotBands,
    selectedId
  ])

  return (
    <BaseAccordion
      seriesLabel="Highlights"
      style={{ marginBottom: '10px' }}
    >
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '10px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
          <Box sx={{ flex: 1}}>
            <DropdownSelect
              selectedValue={selectedId}
              placeholder={selectedId ? undefined : 'Highlights' }
              onChange={(val) => setSelectedId(val)}
              options={textOptions}
            />
          </Box>
          <Button onClick={onClickAdd} className={classes.IconButton} size='small' variant='contained'>
            <AddCircleIcon />
          </Button>
          <Button onClick={onClickDelete} disabled={!selectedId} className={classes.IconButton} size='small' variant='contained' color='error'>
            <DeleteOutline />
          </Button>
        </Box>
        {
          selectedId && (
            <>
              <Divider />
              <CustomFormInput
                label="Label"
                fieldName="text"
                value={selectedBand?.label?.text || ''}
                onChange={(field, value) => onChangeBand('label.text', value)}
              />
              <DropdownSelect
                selectedValue={selectedId}
                placeholder={'Type' }
                onChange={(val) => setSelectedId(val)}
                options={[
                  { id: 'range', label: 'Range' },
                  { id: 'line', label: 'Line' },
                ]}
              />
              <DropdownSelect
                selectedValue={selectedId}
                placeholder={'Orientation' }
                onChange={(val) => setSelectedId(val)}
                options={[
                  { id: 'horizontal', label: 'Horizontal' },
                  { id: 'vertical', label: 'Vertical' },
                ]}
              />
              <DropdownSelect
                selectedValue={selectedId}
                placeholder={'Dash Style' }
                onChange={(val) => setSelectedId(val)}
                options={[
                  {id: 'Solid', label: 'Solid'},
                  {id: 'ShortDash', label: 'Short Dash'},
                  {id: 'ShortDot', label: 'Short Dot'},
                  {id: 'ShortDashDot', label: 'Short DashDot'},
                  {id: 'ShortDashDotDot', label: 'Short DashDotDot'},
                  {id: 'Dot', label: 'Dot'},
                  {id: 'Dash', label: 'Dash'},
                  {id: 'LongDash', label: 'Long Dash'},
                  {id: 'DashDot', label: 'Dash Dot'},
                  {id: 'LongDashDot', label: 'LongDashDot'},
                  {id: 'LongDashDotDot', label: 'Long DashDotDot'},
                ]}
              />
              <CustomizeItem>
                <StartNode>Label Color</StartNode>
                <EndNode>
                  <span>{selectedBand?.label?.style?.color || '#fff'}</span>
                  <ColorPicker
                    color={selectedBand?.label?.style?.color}
                    pickerLable={''}
                    handleChange={(color) => {
                      onChangeBand('label.style.color', color.hex)
                    }}
                  />
                </EndNode>
              </CustomizeItem>
              <CustomizeItem>
                <StartNode>Color</StartNode>
                <EndNode>
                  <span>{selectedBand?.color || '#fff'}</span>
                  <ColorPicker
                    colors={bgColors}
                    color={selectedBand?.color}
                    pickerLable={''}
                    handleChange={(color) => {
                      onChangeBand('color', color.hex)
                    }}
                  />
                </EndNode>
              </CustomizeItem>
              <DropdownSelect
                selectedValue={selectedBand?.label?.verticalAlign}
                placeholder={'Vertical align' }
                onChange={(val) => onChangeBand('label.verticalAlign', val)}
                options={[
                  { id: 'top', label: 'Top' },
                  { id: 'middle', label: 'Middle' },
                  { id: 'bottom', label: 'Bottom' },
                ]}
              />
              <DropdownSelect
                placeholder={'Horizontal align' }
                selectedValue={selectedBand?.label?.textAlign}
                onChange={(val) => onChangeBand('label.textAlign', val)}
                options={[
                  { id: 'left', label: 'Left' },
                  { id: 'center', label: 'Center' },
                  { id: 'right', label: 'Right' },
                ]}
              />
            </>
          )
        }
      </Box>
    </BaseAccordion>
  )
};

import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt} from "store/slices/StockProductSlice";
import BaseAccordion from "../../BaseAccordion";
import {DropdownSelectWithInput} from "components/common/Product/DropdownSelectWithInput";
import {makeStyles} from "@mui/styles";
import {CustomizeItem, EndNode, StartNode} from "components/common/Product/CustomizeItem";
import {Box, FormControlLabel, Button} from "@mui/material";
import {BpCheckbox} from "../../BpCheckbox";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import ColorPicker from "components/common/ColorPicker";
import {useProductContext} from "providers/product/ProductProvider";

const useStyles = makeStyles(() => ({
  AccordionItem: {
    marginBottom: 10
  },
  Button: {
    width: '35px',
    height: '35px',
    padding: 0,
    minWidth: 0,
    fontSize: '19px'
  }
}));
const bgColors = [
  '#fff','#fce4ec', '#ffcdd2', '#f3e5f5', '#e8eaf6', '#e3f2fd', '#d1c4e9',
  '#c5cae9', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#e0f2f1', '#e6ee9c', '#fff9c4',
  '#eeeeee', '#ffccbc'
]

export const DataLabelsFeature = () => {
  const dispatch = useDispatch()
  const { feedSourceType } = useProductContext()
  const { series: seriesData, dataLabelsData } = useSelector(state => state?.productData)
  const classes = useStyles()
  const [applyTo, setApplyTo] = useState('all')

  const [isLabelStyleOpened, setIsLabelStyleOpened] = useState(true)

  const selectedDataLabel = useMemo(() => {
    return dataLabelsData[applyTo] || dataLabelsData.all
  }, [
    dataLabelsData,
    applyTo
  ])


  const seriesList = useMemo(() => {
    let innerList = [{label: 'All Series', id: 'all'}]
    if(feedSourceType === 'stock'){
      innerList = innerList.concat([{
        id: 'stock', label: 'stock'
      }, {
        id: 'volume', label: 'volume'
      }])
    }else{
    }
    seriesData.map(item => {
      if(!(feedSourceType === 'stock' && item.id === 'default')){
        innerList.push({
          id: item.value, label: item.value
        })
      }
    })
    return innerList
  }, [
    feedSourceType,
    seriesData
  ])

  const axisFormatOptions = [
    { label: '{Value}', value: '{y}' },
    { label: '$12 (${Value})', value: '${y}' },
    { label: '12 USD ({Value} USD)', value: '{y} USD' },
    { label: '12.00 ({Value:.2f})', value: '{y:.2f}' },
  ]

  const onChangeVisibility = useCallback((newValue) => {
    console.log(newValue, 'newValue')
    dispatch(setProductOpt({
      path: `dataLabelsData.${applyTo}.enabled`, value: newValue
    }))
  }, [
    applyTo
  ])


  return (
      <Box display="flex" sx={{ flexFlow: 'column', gap: '5px' }}>
        <DropdownSelect
          onChange={(newValue) => {
            setApplyTo(newValue)
          }}
          sx={{ marginBottom: '10px' }}
          selectedValue={applyTo}
          placeholder="Apply to"
          options={seriesList}
        />
        <CustomizeItem>
          <StartNode>Visibility</StartNode>
          <EndNode>
            <FormControlLabel
              value="visibility"
              control={<BpCheckbox
                sx={{ padding: '4px 6px' }}
                onChange={(e) => {
                  onChangeVisibility(!!e.target.checked)
                }}
                checked={!!selectedDataLabel?.enabled}
              />}
              label={selectedDataLabel?.enabled ? 'On' : 'Off'}
              labelPlacement="start"
              sx={{ margin: 0, fontSize: '12px' }}
            />
          </EndNode>
        </CustomizeItem>
        <DropdownSelectWithInput
          onInputChange={(value) => {
            dispatch(setProductOpt({
              path: `dataLabelsData.${applyTo}.format`, value
            }))
          }}
          onSelectChange={(value) => {
            dispatch(setProductOpt({
              path: `dataLabelsData.${applyTo}.select`, value
            }))
          }}
          label="Label format"
          selectValue={selectedDataLabel.select}
          inputValue={selectedDataLabel.format}
          options={axisFormatOptions}
        />
        <BaseAccordion
          seriesLabel="Label Style"
          isExpanded={isLabelStyleOpened}
          className={classes.AccordionItem}
          onChange={() => setIsLabelStyleOpened(!isLabelStyleOpened)}
        >
          <Box display="flex" sx={{ gap: '10px', alignItems: 'center' }}>
            <Button
              className={classes.Button}
              variant={selectedDataLabel?.style?.fontWeight === 'normal' ? 'outlined' : 'contained'}
              onClick={() => {
                dispatch(setProductOpt({
                  path: `dataLabelsData.${applyTo}.style.fontWeight`,
                  value: selectedDataLabel?.style?.fontWeight === 'normal' ? 'bold' : 'normal'
                }))
              }}
            >
              B
            </Button>
            <Button
              className={classes.Button}
              variant={selectedDataLabel?.style?.fontStyle === 'italic' ? 'contained' : 'outlined'}
              onClick={() => {
                dispatch(setProductOpt({
                  path: `dataLabelsData.${applyTo}.style.fontStyle`,
                  value: selectedDataLabel?.style?.fontStyle === 'italic' ? 'normal' : 'italic'
                }))
              }}
              sx={{ fontStyle: 'italic' }}
            >I</Button>
            <ColorPicker
              color={selectedDataLabel?.style?.color || 'black'}
              pickerLable={''}
              defaultColor="black"
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: `dataLabelsData.${applyTo}.style.color`,
                  value: color.hex
                }))
              }}
            />
          </Box>
        </BaseAccordion>
        <CustomizeItem>
          <StartNode>Background Color</StartNode>
          <EndNode>
            <span>{selectedDataLabel.backgroundColor || '#fff'}</span>
            <ColorPicker
              colors={bgColors}
              color={selectedDataLabel.backgroundColor}
              pickerLable={''}
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: `dataLabelsData.${applyTo}.backgroundColor`,
                  value: color.hex
                }))
              }}
            />
          </EndNode>
        </CustomizeItem>
        <CustomizeItem>
          <StartNode>Border Color</StartNode>
          <EndNode>
            <span>{selectedDataLabel.borderColor || '#fff'}</span>
            <ColorPicker
              colors={bgColors}
              color={selectedDataLabel.borderColor}
              pickerLable={''}
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: `dataLabelsData.${applyTo}.borderColor`,
                  value: color.hex
                }))
              }}
            />
          </EndNode>
        </CustomizeItem>
      </Box>
  )
};

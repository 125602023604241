import React, { useMemo } from "react";
// import { useSelector } from 'react-redux'

import {parseDateToLocalFormat} from "shared/utilities/dateHelpers";
import {getRandomColor} from "shared/utilities/common";
import NonMarketHighChart from "views/product/details/chart/NonMarketHighChart";
import {getOptions, numberFormat} from "highcharts";

function LineChart(props) {
  const {
    data: initialData,
    xAxis,
    xAxisType,
    yAxisFields,
    chartType,
    defaultConfigs,
    dataLabelsData,
    tooltipFormatData,
    annotations
  } = props


  const data = useMemo(() => {
    if(xAxisType === 'date'){
      return initialData.sort((a, b) => {
        if (a[xAxis] && b[xAxis]) {
          return new Date(a[xAxis]).getTime() - new Date(b[xAxis]).getTime()
        }
        return 1;
      })
    }
    return initialData
  }, [
    initialData,
    xAxis,
    xAxisType
  ])

  const categories = useMemo(() => {
    if(xAxisType === 'date'){
      return data.map(item => parseDateToLocalFormat(item[xAxis]))
    }
    return data.map(item => item[xAxis])
  }, [
    data,
    xAxis,
    xAxisType
  ])
  console.log(xAxisType, categories,  'categories')

  const chartMinWidth = useMemo(() =>{
    if(data.length > 100){
      return {
        minWidth: 1900,
        scrollPositionX: 1
      }
    }
    return {}
  }, [
    data
  ])

  const series = useMemo(() => {
    const duelSeries = {}
    if (!(xAxis && yAxisFields && yAxisFields.length > 0)) return [{}]
    if (yAxisFields?.length > 0) {
      console.log(yAxisFields, 'yAxisFields')
      console.log(dataLabelsData, 'datalabesData')
        yAxisFields.map((chartLine) => {
          if(chartLine?.value){
            const formatBody = tooltipFormatData[chartLine?.value] || tooltipFormatData['all']
            duelSeries[chartLine.value] = {
              ...chartLine,
              type: chartLine.chartType || 'line',
              name: chartLine.value.split('_').join(' ').toUpperCase(),
              data: data.map(item => item[chartLine.value]),
              tooltip: {
                valueDecimals: 2,
                pointFormat: `${formatBody.pointFormatBody}`
              },
            }
            if(chartLine?.marker?.symbol && chartLine?.marker?.symbol !== 'none'){
              duelSeries[chartLine.value].marker = {
                enabled: true,
                symbol: chartLine?.marker?.symbol,
                radius: 3
              }
            }
            console.log(chartLine.value, 'chartLIne.value')
            if(dataLabelsData[chartLine?.value]){
              duelSeries[chartLine.value].dataLabels = dataLabelsData[chartLine.value]
            }
          }
          // const chartLine = chartLinesData?.filter(item => item.id === chartLineId)[0]
          // if (chartLine) {
          //   if (item[chartLineId]) {
          //     if (duelSeries[chartLineId] === undefined) {
          //       duelSeries[chartLineId] = {
          //         name: chartLine.label.toUpperCase(),
          //         color: getRandomColor(),
          //         data: []
          //       }
          //     }
          //     const duelPoint = [_dateVal, isNaN(item[chartLineId]) ? 0 : item[chartLineId]]
          //     duelSeries[chartLineId].data.push(duelPoint)
          //   }
          // }
        })
    }
    // console.log(duelSeries, 'duelSeries')
    // console.log(xAxis, yAxisFields, 'yAxisFields')
    return Object.values(duelSeries)

  }, [
    categories,
    data,
    dataLabelsData,
    yAxisFields,
    xAxis,
  ])
  const chartConfig = useMemo(() => {
    return {
      ...defaultConfigs,
      chart: {
        ...defaultConfigs.chart,
        events: {
          click: function (event) {
            const label = this.renderer.label(
              'x: ' + numberFormat(event.xAxis[0].value, 2) +
              ', y: ' + numberFormat(event.yAxis[0].value, 2),
              event.xAxis[0].axis.toPixels(event.xAxis[0].value),
              event.yAxis[0].axis.toPixels(event.yAxis[0].value)
            )
              .attr({
                fill: getOptions().colors[0],
                padding: 10,
                r: 5,
                zIndex: 8
              })
              .css({
                color: '#FFFFFF'
              })
              .add();
            this.renderer.text('Hello world', 200, 100).attr({ rotation: 45. }).css({ fontSize: '16pt', color: 'green' }).add();

            setTimeout(function () {
              label.fadeOut();
            }, 1000);
          }
        },
        type: chartType,
        zoomType: 'x',
        scrollablePlotArea: chartMinWidth,
      },
      xAxis: {
        ...defaultConfigs.xAxis,
        categories: categories,

        // crosshair: true,
        // type: "datetime",
        // labels: {
        //   formatter: function () {
        //     return Highcharts.dateFormat('%b/%e/%Y', this.value);
        //   }
        // }
      },
      yAxis: {
        ...defaultConfigs.yAxis,
        backgroundColor: 'transparent',
        min: 0,
      },
      annotations: annotations,
      stockTools: {
        gui: {
          enabled: false,
          visible: false,
        },
        buttons: []
      },
      plotOptions: {
        ...defaultConfigs.yAxis,
        series: {
          dataLabels: dataLabelsData?.all || {},
          marker: {
            enabled: false
          }
        },
        line: {
          // pointPadding: 0.2,
          // borderWidth: 0
        }
      },
      series: series,
      navigation: {
        events: {
          "showPopup": "function(t){var n=t.annotation,r=(this.chart.annotations||[]).findIndex((function(e){return e===n}));P8.dispatch(Pc({type:n.labels&&n.labels.length>0?\"label\":\"shape\",index:r,annotation:n})),this.chart.indicatorsPopupContainer||(this.chart.indicatorsPopupContainer=document.getElementsByClassName(\"highcharts-popup-indicators\")[0]),\"indicators\"===t.formType?this.chart.indicatorsPopupContainer.style.display=\"block\":\"annotation-toolbar\"===t.formType&&(this.chart.activeButton||(this.chart.currentAnnotation=t.annotation)),this.popup&&(e=this.popup)}",
          "closePopup": "function(){this.chart.currentAnnotation=null}",
          "selectButton": "function(e){var t=e.button.className+\" highcharts-active\";e.button.classList.contains(\"highcharts-active\")||(e.button.className=t,this.chart.activeButton=e.button)}",
          "deselectButton": "function(e){e.button&&e.button.classList.remove(\"highcharts-active\"),this.chart.activeButton=null}"
        },
        "bindingsClassName": "tools-container"
      }
    }
  }, [
    annotations,
    categories,
    chartMinWidth,
    chartType,
    dataLabelsData,
    defaultConfigs,
    series
  ])

  console.log(defaultConfigs, 'defaultConfigs config')
  console.log(chartConfig, 'chart config')
  return (
    <NonMarketHighChart options={chartConfig}/>
  )
}

export default LineChart
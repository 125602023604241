import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import SwipeableViews from "react-swipeable-views";

import {
    Typography,
    Box,
    InputAdornment,
    Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import StockSource from "./StockSource";
// import StockDatabaseCodes from "./StockDatabaseCodes";
import StockProductCodes from "./ProductCode/StockProductCodes";

import {
    changeStep2Index,
    setFeedDatabaseCode,
    setFeedSource,
    clearStockProducts,
    setFeedProductCode,
    setFeedSectionName
} from "store/slices/EodDataSlice"

import ListItem from "./Details/ListItem";
import StockSections from "./StockSections";
// import StockDatabaseCodeQA from "./StockDatabaseCodesQA";
import StockDatabaseCodeContainer from "./DatabaseCode";
import SavedIndividual from "./SavedIndividual";
import SearchBox from "./Details/SearchBox";
import QLink from "./Details/QLink";
import DatabaseCodesQADialog from "./Details/DatabaseCodesQADialog";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'absolute',
        width: '100%'
    },
    padding: {
        padding: '12px 12px 0 12px',
    },
    HeaderLabel: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '-0.02em',
        color: '#374151',
    },
    BreadcrumbItem: {
        // display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 8px',
        gap: '8px',
        height: '20px',
        background: '#5A88ED',
        borderRadius: '24px',
        color: 'white',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '20px',
        marginRight: 6,
        cursor: 'pointer',
        overflow: 'hidden',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        maxWidth: '250px',
    },
    BreadCrumbDivider: {
        background: '#E5ECFA',
        marginBottom: 12,
    },
    SelectedBreadcrumbItem: {
        background: '#2255C3',
        cursor: 'initial'
    },
    SwipeableViewsContainer: {
        flex: '1 0 0',
        minHeight: 0,
        // overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 9,
        },
        /* Track */
        '&::-webkit-scrollbar-track': {
            background: '#e4e5e9',
            borderRadius: 4,
        },
        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            background: '#b0b3bd',
            borderRadius: 4,
        },
        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#9e1e1e',
        },
    },
    SwipeableViewsInner: {
        overflow: 'hidden',
        height: '100%',
        position: 'relative',
        '& .react-swipeable-view-container': {
            position: 'relative',
            height: '100%'
        },
        '& [data-swipeable="true"]': {
            // overflow: 'initial !important',
            height: '100%'
        }
    }
}))

const BreadcrumbItem = (props) => {
    const classes = useStyles()
    return <Box
        className={`${classes.BreadcrumbItem} ${props.selected ? classes.SelectedBreadcrumbItem : ''}`}
        onClick={() => {
            if (props.onClick) props.onClick()
        }}
        title={props.labelTxt}
    >
        {props.labelTxt}
    </Box>
}

/* Step2Sidebar's Source Container */
const StepSourceContainer = ({ wrapStyle }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()

    const step1Sidebar = useSelector(state => state.appLayout.step1Sidebar)
    const step2Sidebar = useSelector(state => state.appLayout.step2Sidebar);
    const searchWords = useSelector(state => state.eodData.qaSearchWords)
    const step2Index = useSelector(state => state.eodData.step2Index)
    const feedSource = useSelector(state => state.eodData.feedSource)
    const feedDatabaseCode = useSelector(state => state.eodData.feedDatabaseCode)
    const feedProductCode = useSelector(state => state.eodData.feedProductCode)
    const feedSectionName = useSelector(state => state.eodData.feedSectionName)

    const [results, setResults] = useState(null)
    const [isStep2Qa, setIsStep2Qa] = useState(false)
    const [openDatabaseCodesQADialog, setOpenDatabaseCodesQADialog] = useState(false)

    const handelStep2Index = useCallback((index, val = '', flag = null, isQa = false) => {
        let updates = {
            feedSource, feedDatabaseCode, feedProductCode, feedSectionName
        };
        if (index === 1) {
            if (val !== undefined) {
                dispatch(setFeedSource(val))
            }
            updates.feedSource = val;
            updates.feedDatabaseCode = '';
            updates.feedSectionName = null;
            updates.feedProductCode = '';
        } else if (index === 2) {
            updates.feedDatabaseCode = val;
            if (val !== undefined) {
                dispatch(setFeedDatabaseCode(val))
            }
            updates.feedSectionName = null;
            updates.feedProductCode = '';
        } else if (index === 3) {
            if (flag == 'databaseCode') {
                updates.feedSectionName = ''
                updates.feedDatabaseCode = val
            } else {
                updates.feedSectionName = val;
            }
            updates.feedProductCode = '';
            // if (val !== undefined) {
            //     dispatch(setFeedDatabaseCode(val))
            // }
        } else if (index === 4) {
            updates.feedSource = ''
            updates.feedDatabaseCode = '';
            updates.feedSectionName = null;
            updates.feedProductCode = '';
        } else if (index === 0) {
            updates.feedSource = ''
            updates.feedDatabaseCode = '';
            updates.feedSectionName = null;
            updates.feedProductCode = '';
        }
        dispatch(clearStockProducts())
        dispatch(changeStep2Index(index))
        dispatch(setFeedSource(updates.feedSource))
        dispatch(setFeedDatabaseCode(updates.feedDatabaseCode))
        dispatch(setFeedProductCode(updates.feedProductCode))
        dispatch(setFeedSectionName(updates.feedSectionName))
        setIsStep2Qa(isQa)
    }, [
        feedSource,
        feedDatabaseCode,
        feedProductCode,
        feedSectionName
    ])

    const renderBreadCrumb = useMemo(() => {
        return <Box>
            <Divider className={classes.BreadCrumbDivider} />
            {
                step2Index > 0 && (
                    <BreadcrumbItem labelTxt="Dataset" onClick={() => { handelStep2Index(0) }} />
                )
            }
            {
                step2Index < 4 && (
                    <>
                        {
                            step2Index >= 1 && (
                                <BreadcrumbItem
                                    labelTxt={feedSource}
                                    selected={step2Index === 1}
                                    onClick={() => {
                                        if (step2Index > 1) handelStep2Index(1, feedSource)
                                    }}
                                />
                            )
                        }
                        {
                            step2Index >= 2 && (
                                <BreadcrumbItem
                                    labelTxt={feedDatabaseCode}
                                    selected={step2Index === 2}
                                    onClick={() => {
                                        if (step2Index > 2) handelStep2Index(2, feedDatabaseCode)
                                    }}
                                />
                            )
                        }
                    </>
                )
            }
        </Box>
    }, [
        feedSource,
        feedDatabaseCode,
        step2Index
    ])
    const handleProductCode = (val) => {
        dispatch(setFeedProductCode(val))
    }
    const searchResults = useMemo(() => {
        // if(searchWord && searchWord !== ''){
        return results
        // }
        // return null
    }, [
        results,
        // searchWord
    ])
    const searchWord = useMemo(() => {
        if (step2Index === 0) {
            return searchWords?.feedSource || ''
        } else if (step2Index === 1) {
            return searchWords?.databaseCode || ''
        } else if (step2Index === 3) {
            return searchWords?.productCode || ''
        } else if (step2Index === 4) {
            return searchWords?.individual || ''
        }
        return ''
    }, [
        searchWords,
        step2Index
    ])
    return (
        <Box className={classes.root} style={wrapStyle}>
            <Box className={classes.padding}>
                <Box display="flex" sx={{ alignItems: 'center', justifyContent: "space-between" }}>
                    <Typography className={classes.HeaderLabel}>
                        Quality Analytic
                    </Typography>
                    <QLink onClick={(e) => {
                        e.stopPropagation();
                        setOpenDatabaseCodesQADialog(true)
                    }} />
                </Box>
                <Box mt={'10px'} mb={'24px'}>
                    <SearchBox
                        searchWord={searchWord}
                        step2Index={step2Index}
                        feedSource={feedSource}
                        feedDatabaseCode={feedDatabaseCode}
                        feedProductCode={feedProductCode}
                    />
                </Box>
                {renderBreadCrumb}
            </Box>
            {
                Array.isArray(searchResults) ? (
                    searchResults.length < 1 ? (
                        <div style={{ marginTop: '10px', marginLeft: '18px' }}>There is no match</div>
                    ) : (
                        <Box className={classes.ListBox}>
                            {
                                searchResults?.map((item, index) => {
                                    return <ListItem
                                        onClick={() => {
                                            const url = `/product/detail/${encodeURIComponent(item.value.feed_source)}/${encodeURIComponent(item.value.database_code)}/${encodeURIComponent(item.value.product_code)}`
                                            history.push(url)
                                        }}
                                        key={index}
                                    >
                                        {item.value.feed_source}/{item.value.database_code}/{item.value.product_code}
                                    </ListItem>
                                })
                            }
                        </Box>
                    )
                ) : (
                    <Box className={classes.SwipeableViewsContainer}>
                        <SwipeableViews
                            axis='x'
                            index={step2Index}
                            className={classes.SwipeableViewsInner}
                            style={{ display: step2Sidebar === 'source' ? '' : 'none' }}
                        >
                            <StockSource
                                value={step2Index}
                                changeStep2Index={handelStep2Index}
                                index={0}
                                dir={'rtl'}
                                searchWord={searchWords?.feedSource || ''}
                            />
                            <StockDatabaseCodeContainer
                                value={step2Index}
                                changeStep2Index={handelStep2Index}
                                feedSource={feedSource}
                                index={1}
                                dir={'rtl'}
                                searchWord={searchWords?.databaseCode || ''}
                                isQa={isStep2Qa}
                            />
                            <StockSections
                                value={step2Index}
                                changeStep2Index={handelStep2Index}
                                feedSource={feedSource}
                                feedDatabaseCode={feedDatabaseCode}
                                index={2}
                                dir={'rtl'}
                                searchWord={searchWords?.databaseCode || ''}
                            />
                            <StockProductCodes
                                value={step2Index}
                                handleProductCode={handleProductCode}
                                feedSource={feedSource}
                                feedDatabaseCode={feedDatabaseCode}
                                sectionName={feedSectionName}
                                searchWord={searchWords?.productCode || ''}
                                index={3}
                                dir={'rtl'}
                            />
                            <SavedIndividual
                                feedSource={feedSource}
                                changeStep2Index={handelStep2Index}
                                value={step2Index}
                                searchWord={searchWords?.individual || ''}
                                index={4}
                                dir={'rtl'}
                            />
                        </SwipeableViews>
                    </Box>
                )
            }
            {
                openDatabaseCodesQADialog && (
                    <DatabaseCodesQADialog
                        open={openDatabaseCodesQADialog}
                        qaResults={[]}
                        handleClose={() => setOpenDatabaseCodesQADialog(false)}
                    />
                )
            }
        </Box>
    );
}

export default StepSourceContainer;
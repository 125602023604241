import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    InputAdornment,
    CircularProgress,
    IconButton,
} from "@mui/material"
import {
    Search as SearchIcon,
    Close as CloseIcon,
} from '@mui/icons-material';
import CustomTextField from 'components/common/Base/CustomTextField';
import { setQaSearchWord } from 'store/slices/EodDataSlice';

export default function SearchBox(props) {
    const dispatch = useDispatch()
    const { step2Index, searchWord } = props
    // const [searchWord, setSearchWord] = useState(props.searchWord)
    const [loading, setLoading] = useState(false)

    const searchField = useMemo(() => {
        if (step2Index === 0) {
            return 'feedSource'
        } else if (step2Index === 1) {
            return 'databaseCode'
        } else if (step2Index === 3) {
            return 'productCode'
        } else if (step2Index === 4) {
            return 'individual'
        }
    }, [
        step2Index
    ])

    const handleSearchWord = (e) => {
        const _searchWord = e.target.value
        // setSearchWord(_searchWord)
        console.log(searchField, 'searchField')
        console.log(step2Index, 'step2Index')
        dispatch(setQaSearchWord({
            field: searchField,
            value: _searchWord
        }))
    }


    return (
        <>
            <CustomTextField
                value={searchWord}
                onChange={handleSearchWord}
                size="small"
                style={{
                    background: 'white'
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>,
                    endAdornment: <InputAdornment position="end">
                        {
                            searchWord && searchWord !== '' && (
                                loading ? (
                                    <CircularProgress size={20}/>           
                                ) : (
                                    <IconButton edge="end" onClick={() => {
                                        dispatch(setQaSearchWord({
                                            field: searchField,
                                            value: ''
                                        }))
                                    }}>
                                        <CloseIcon />    
                                    </IconButton>
                                )
                            )
                        }
                    </InputAdornment>
                }}
            />
        </>
    );
}
/* eslint no-restricted-globals:0 */
import React from "react";

import { makeStyles } from "@mui/styles";

/*Rrag Chart Importing*/
import RragChartContainer from 'components/common/Rrag';
import { TypeChooser } from "libs/rrag/lib/helper";
/** High Chart  */
// import HighChartContainer from 'components/common/High'
// import HighChartContainer from 'components/common/High/DepthChart'
import HighChartContainer from 'components/common/High/OfficalChart'
// import HighChartContainer from 'components/common/High/ChartComponent'

import ChartTimeRanger from "../../Components/ChartTimeRanger";
// import TradingViewDrawer from "components/common/TradingView/TradingViewDrawer";
import TradingViewDrawer from "components/common/TradingView/LightWeight";
import TradingViewEmbedder from "components/common/TradingView/TradingViewEmbedder";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        paddingTop: '10px',
        // marginTop: '20px',
    }
}));
function MarketContainer(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const {
        chartLibrary,
        data,
        timeRange,
        feedSource,
        databaseCode,
        productCode,
        xAxis,
        yAxis
    } = props
    return (
        <div className={classes.root}>
            
            {
                chartLibrary === 'rrag' && (<ChartTimeRanger timeRange={timeRange} />)
            }
            {
                chartLibrary === 'rrag' ? (
                    <TypeChooser>
                        {type => <RragChartContainer data={data} type={type} />}
                    </TypeChooser>
                ) : chartLibrary === 'tradingview-widget' ? (
                    <TradingViewEmbedder
                        feedSource={feedSource}
                        databaseCode={databaseCode}
                        productCode={productCode}
                    />
                ) : chartLibrary === 'light-weight' ? (
                    <TradingViewDrawer
                        data={data}
                        base
                    />
                ) : (
                    <HighChartContainer
                        xAxis={xAxis}
                        yAxis={yAxis}
                        data={data}
                    />
                )
            }

            
        </div>
    )
};

export default MarketContainer;
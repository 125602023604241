import React, { useMemo } from "react"
import {useSelector} from "react-redux"
import { useParams } from 'react-router-dom'

import {
  Typography,
  Box
} from "@mui/material"

const ProductHeader = () => {
  const { productCode = '' } = useParams()

  const productName = useSelector(state => state.productData.productName)

  const formattedProductName = useMemo(() => {
    try {
      return productName ?
        decodeURIComponent(productName) :
        decodeURIComponent(productCode)
    } catch (e) {
      return productName ? productName : productCode
    }
  }, [
    productName,
    productCode
  ])
  return (
    <Box display="flex" sx={{ alignItems: 'center', padding: '10px 16px' }}>
      <Typography style={{
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '-0.02em',
        color: '#374151',
      }} id={productName ? 'QAProductName' : ''}>
        {
          formattedProductName
        }
      </Typography>
    </Box>
  )
}

export default ProductHeader

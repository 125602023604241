import React, { useCallback, useEffect, useState } from 'react'
import _ from "underscore"

import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { selectBEAProductDataByPath, selectProductDataByRawPath } from 'store/selectors/ProductData';
import TransformContainer from '../TransformContainer';
import { sortData, customizeData } from '../utils';

function NIPATableView(props) {
    const {
        blob,
        dateColumn = 'time_period_start',
        feedSource,
        databaseCode,
        productCode = ''
    } = props

    const productResource = useSelector((state) => selectBEAProductDataByPath(state, {
        feedSource,
        databaseCode,
        productCode,
        blob
    }))

    return (
        <>
            <TransformContainer
                {...props}
                dateField={dateColumn}
                productResource={{
                    years: productResource.years || [],
                    lines: productResource.lines,
                    data: [...productResource.data],
                    areas: productResource.areas,
                    series: productResource.series,
                    rows: productResource.transformRows,
                    columns: productResource.transformColumns
                }}
            />
        </>
    )
}

export default NIPATableView
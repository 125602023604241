
import React from "react";
import PropTypes from "prop-types";

import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import { scaleTime } from "d3-scale";
import {format} from "d3-format";
import {timeFormat} from "d3-time-format";
import moment from "moment";
import shortid from "shortid";

import {XAxis, YAxis} from "libs/rrag/lib/axes";
import { BarSeries, ElderRaySeries, OHLCSeries, SARSeries, VolumeProfileSeries } from "libs/rrag/lib/series";
import { 
    LineChart, AreaChart, CandlestickChart, BarChart, GroupedBarChart, StackedBarChart, KagiChart,
    PointAndFigureChart, RenkoChart, ScatterAshiChart
} from "./partials/series"
import InteractiveCoordinateDialog from './partials/interactives/CoordinateDialog'
import InteractiveTextDialog from './partials/interactives/TextDialog'

import { ChartCanvas, Chart, ZoomButtons } from "libs/rrag";
import { fitWidth } from "libs/rrag/lib/helper";
import { discontinuousTimeScaleProvider, discontinuousTimeScaleProviderBuilder} from "libs/rrag/lib/scale";
import { head, last, toObject } from "libs/rrag/lib/utils";

import {MouseCoordinateY, CrossHairCursor, MouseCoordinateX, PriceCoordinate} from "libs/rrag/lib/coordinates";

import { heikinAshi, change, elderRay, kagi, pointAndFigure, renko, elderImpulse } from "libs/rrag/lib/indicator";
import { Brush, ClickCallback } from "libs/rrag/lib/interactive";
import {InteractiveYCoordinate, DrawingObjectSelector, GannFan, StandardDeviationChannel, EquidistantChannel, FibonacciRetracement, TrendLine, InteractiveText} from "libs/rrag/lib/interactive";
import { getMorePropsForChart } from "libs/rrag/lib/interactive/utils";
import { isNotDefined, isDefined } from "libs/rrag/lib/utils";
import {
    MovingAverage, BollingerBands, MacdIndicator, RsiIndicator, AtrIndicator, FiIndicator, FiEMAIndicator, EdgeIndicatorContainer, StoIndicator
} from "./partials/indicators";


import {
    getMAIndicatorEdge, getBBIndicator, getMacdIndicator, getRsiIndicator, getAtrIndicator,
    getStoIndicator,
    getSarCalc, getForceIndexCalc
} from "./utils/calculator";

import {
    getTooltipContent, getIntervalNumber
} from "./utils";

import {OHLCTooltip, HoverTooltip} from "libs/rrag/lib/tooltip";

import { CustomAnnotation, Annotate, AnnotateCustom, SvgPathAnnotation, SvgPathAnnotationCustom, buyPath, sellPath } from "libs/rrag/lib/annotation";
import { saveInteractiveNode, getInteractiveNodes, saveInteractiveNodes } from './utils/interactiveUtils'
import algo from "libs/rrag/lib/algorithm";

const bb = getBBIndicator()
const numberFormat = format(".2f");

function round(number, precision = 0) {
	const d = Math.pow(10, precision);
	return Math.round(number * d) / d;
}

function getMaxUndefined(calculators) {
	return calculators.map(each => each.undefinedLength()).reduce((a, b) => Math.max(a, b));
}

class ChartContainer extends React.Component {
    constructor(props) {
        super(props);
        this.renderChartSeries = this.renderChartSeries.bind(this);
        this.returnContainerConfig = this.returnContainerConfig.bind(this);
        this.handleReset = this.handleReset.bind(this);
        // this.handleProps = this.handleProps.bind(this);
        this.handleDownloadMore = this.handleDownloadMore.bind(this);
        this.drawChartTheme = this.drawChartTheme.bind(this);
        this.saveCanvasNode = this.saveCanvasNode.bind(this);
        //Brush
        this.handleBrush = this.handleBrush.bind(this)
		// this.saveInteractiveNode = saveInteractiveNode.bind(this);

        this.handleSelection = this.handleSelection.bind(this);
        this.saveInteractiveNodes = saveInteractiveNodes.bind(this)
		this.getInteractiveNodes = getInteractiveNodes.bind(this);
        //Coordinate - Y
		this.onDragComplete = this.onDragComplete.bind(this);
        this.handleInteractiveCoordinateChoosePosition = this.handleInteractiveCoordinateChoosePosition.bind(this)
        this.handleDoubleClickAlert = this.handleDoubleClickAlert.bind(this)
        this.onDelete = this.onDelete.bind(this)
        this.handleInteractiveCoordinateDialogClose = this.handleInteractiveCoordinateDialogClose.bind(this)
        this.handleChangeAlert = this.handleChangeAlert.bind(this)
        this.handleDeleteAlert = this.handleDeleteAlert.bind(this)
    
        //Gan Fan
        this.onGannFanDrawComplete = this.onGannFanDrawComplete.bind(this)

        //Channel
        this.onStandardChannelDrawComplete = this.onStandardChannelDrawComplete.bind(this)
        this.onEquidistantChannelDrawComplete = this.onEquidistantChannelDrawComplete.bind(this)

        //Fibonacci
        this.onFibComplete = this.onFibComplete.bind(this)

        //TrendLine
        this.onDrawTrendLineComplete = this.onDrawTrendLineComplete.bind(this)

        //Interactive Text
        this.onDrawInteractiveText = this.onDrawInteractiveText.bind(this)
        this.handleInteractiveTextDialogClose = this.handleInteractiveTextDialogClose.bind(this)
        this.handleInteractiveTextChange = this.handleInteractiveTextChange.bind(this)
        this.handleInteractiveChoosePosition = this.handleInteractiveChoosePosition.bind(this)

        this.state = {
			xExtents:[],
			brushEnabled: true,

            enableInteractiveObject: true,
            //coordinate - y
			yCoordinateList: [],
			showInteractiveCoordinateDialog: false,
			alertToEdit: {},

            fans: [],

            standardDeviationChannels: [],
            equidistantChannels: [],

            fibonacciRetracements: [],

            trends: [],

            textList: [],
            interactiveText: '',
            showInteractiveTextDialog: false
		};
    }
    componentWillMount() {
        this.setState({
            suffix: 1
        });
    }
    
	componentDidMount() {
		// document.addEventListener("keyup", this.onKeyPress);
	}
	componentWillUnmount() {
		// document.removeEventListener("keyup", this.onKeyPress);
	}
    saveCanvasNode(node) {
		this.canvasNode = node;
	}
    /** Interactive Tools */
    handleSelection(interactives, moreProps, e) {
        const { interactiveTool } = this.props.rragChartData
        const { enableInteractiveObject } = this.state
        if(interactiveTool === 'interactive-text'){
            if (enableInteractiveObject) {
                const independentCharts = moreProps.currentCharts
                if (independentCharts.length > 0) {
                    const first = head(independentCharts);
    
                    const morePropsForChart = getMorePropsForChart(moreProps, first)
                    const {
                        mouseXY: [, mouseY],
                        rragChartData: { yScale },
                        xAccessor,
                        currentItem,
                    } = morePropsForChart;
    
                    const position = [xAccessor(currentItem), yScale.invert(mouseY)];
                    const newText = {
                        ...InteractiveText.defaultProps.defaultText,
                        position,
                    };
                    this.handleInteractiveChoosePosition(newText, e);
                }
            } else {
                // const state = toObject(interactives, each => {
                //     return [
                //         `textList`,
                //         each.objects,
                //     ];
                // });
                // this.setState(state);
            }
        }else if(interactiveTool === 'interactive-y-coordinate'){
            if (enableInteractiveObject) {
                const independentCharts = moreProps.currentCharts;
                if (independentCharts.length > 0) {
                    const first = head(independentCharts);
    
                    const morePropsForChart = getMorePropsForChart(moreProps, first);
                    const {
                        mouseXY: [, mouseY],
                        rragChartData: { yScale },
                    } = morePropsForChart;
    
                    const yValue = round(yScale.invert(mouseY), 2);
                    const newAlert = {
                        ...InteractiveYCoordinate.defaultProps.defaultPriceCoordinate,
                        yValue,
                        id: shortid.generate()
                    };
                    this.handleInteractiveCoordinateChoosePosition(newAlert, morePropsForChart, e);
                }
            } else {
                // const state = toObject(interactives, each => {
                //     return [
                //         `yCoordinateList`,
                //         each.objects,
                //     ];
                // });
                // this.setState(state);
            }
    
        }

        /**GannFan  */
        // const state = toObject(interactives, each => {
		// 	return [
		// 		"fans",
		// 		each.objects,
		// 	];
		// });
		// this.setState(state);

        /**Channel */
        // const state = toObject(interactives, each => {
		// 	return [
		// 		`channels_${each.chartId}`,
		// 		each.objects,
		// 	];
		// });
		// this.setState(state);

        /** Finbonacci */
        // const state = toObject(interactives, each => {
		// 	return [
		// 		`retracements_${each.chartId}`,
		// 		each.objects,
		// 	];
		// });
		// this.setState(state);
        
        /** Trend Line */
        // const state = toObject(interactives, each => {
		// 	return [
		// 		`trends`,
		// 		each.objects,
		// 	];
		// });
		// this.setState(state);
	}

    /** Start Handle of Coordinate - y */
    handleInteractiveCoordinateDialogClose(){
		this.setState(state => {
			const { originalAlertList, alertToEdit } = state;
			const key = `yCoordinateList`;
			const list = originalAlertList || state[key];
			return {
				showInteractiveCoordinateDialog: false,
				[key]: list,
			};
		});
    }
    handleChangeAlert(alert){
        const yCoordinateList = this.state[`yCoordinateList`];
		const newAlertList = yCoordinateList.map(d => {
			return d.id === alert.id ? alert : d;
		});

		this.setState({
			[`yCoordinateList`]: newAlertList,
			showInteractiveCoordinateDialog: false,
			enableInteractiveObject: false,
		});
    }
    onDragComplete(yCoordinateList, moreProps, draggedAlert) {
        // this gets called on drag complete of drawing object

		const key = `yCoordinateList`;
		const alertDragged = draggedAlert != null;

		this.setState({
			enableInteractiveObject: false,
			[key]: yCoordinateList,
			showInteractiveCoordinateDialog: alertDragged,
			alertToEdit: {
				alert: draggedAlert
			},
			originalAlertList: this.state[key],
		});
	}
	handleInteractiveCoordinateChoosePosition(alert, moreProps) {
        this.setState({
			yCoordinateList: [
				...this.state.yCoordinateList,
				alert
			],
			showInteractiveCoordinateDialog: true,
            alertToEdit: {
                alert
            },
			enableInteractiveObject: false,
		});
	}
	handleDoubleClickAlert(item) {
        this.setState({
			showInteractiveCoordinateDialog: true,
			alertToEdit: {
				alert: item.object
			},
		});
	}
    onDelete(yCoordinate, moreProps) {
        this.setState(state => {
			const key = `yCoordinateList`;

			const list = state[key];
			return {
				[key]: list.filter(d => d.id !== yCoordinate.id)
			};
		});
	}
    handleDeleteAlert() {
        const { alertToEdit } = this.state;
		const key = `yCoordinateList`;
		const yCoordinateList = this.state[key].filter(d => {
			return d.id !== alertToEdit.alert.id;
		});
		this.setState({
			showInteractiveCoordinateDialog: false,
			alertToEdit: {},
			[key]: yCoordinateList
		});
	}
    /** End Handle of Coordinate - y */

    /** Start Handle of Gan Fan */
	onGannFanDrawComplete(fans) {
		// this gets called on
		// 1. draw complete of drawing object
		// 2. drag complete of drawing object
		this.setState({
			enableInteractiveObject: false,
			fans
		});
	}
    /** End Handle of Gan Fan */

    /** Start handle of Channel */
    onStandardChannelDrawComplete(channels) {
		// this gets called on
		// 1. draw complete of drawing object
		// 2. drag complete of drawing object
		this.setState({
			enableInteractiveObject: false,
			standardDeviationChannels: channels
		});
	}
    onEquidistantChannelDrawComplete(channels) {
		// this gets called on
		// 1. draw complete of drawing object
		// 2. drag complete of drawing object
		this.setState({
			enableInteractiveObject: false,
			equidistantChannels: channels
		});
	}
    /** End handle of Channel */

    /** Start handle of Fibonacci */
    onFibComplete(retracements) {
		this.setState({
			fibonacciRetracements: retracements,
			enableFib: false
		});
	}
    /** End handle of Fibonacci */
    
    /** Start handle of TrendLine */
    onDrawTrendLineComplete(trends){
        this.setState({
			enableInteractiveObject: false,
			trends
		});
    }
    /** End Handle of TrendLine */

    /** Start handle of Interactive Text */
    onDrawInteractiveText(textList, moreProps) {
		// this gets called on
		// 1. draw complete of drawing object
		// 2. drag complete of drawing object
		this.setState({
			enableInteractiveObject: false,
			textList: textList,
		});
	}
    handleInteractiveTextDialogClose(){
        this.setState({
			showInteractiveTextDialog: false,
		});
		// this.componentDidMount();
    }
    handleInteractiveTextChange(text){
        const textList = this.state.textList;
		const allButLast = textList
			.slice(0, textList.length - 1);

		const lastText = {
			...last(textList),
			text: text,
		};
		this.setState({
			textList: [
				...allButLast,
				lastText
			],
			showInteractiveTextDialog: false,
			enableInteractiveObject: false,
		});
		// this.componentDidMount();
    }
    handleInteractiveChoosePosition(text) {
		// this.componentWillUnmount();

		this.setState({
			textList: [
				...this.state.textList,
				text
			],
			showInteractiveTextDialog: true,
			interactiveText: text.text,
		});
	}
    /** End Handle of Interactive Text */

	onKeyPress(e) {
		const keyCode = e.which;
		switch (keyCode) {
		case 46: { // DEL
			const fans = this.state.fans
				.filter(each => !each.selected);

			this.canvasNode.cancelDrag();
			this.setState({
				fans,
			});

			break;
		}
		case 27: { // ESC
			this.node.terminate();
			this.canvasNode.cancelDrag();
			this.setState({
				enableInteractiveObject: false
			});
			break;
		}
		case 68:   // D - Draw drawing object
		case 69: { // E - Enable drawing object
			this.setState({
				enableInteractiveObject: true
			});
			break;
		}
		}
	}

    handleReset() {
        this.setState({
            suffix: this.state.suffix + 1
        });
    }
    renderChartSeries(seriesProps){
        const { chartType, features } = this.props.rragChartData;
        let series = <h1>{ chartType }</h1>
        switch (chartType) {
            case 'line':
                series = <LineChart appearance={seriesProps}/>
                break;
            case 'kagi':
                series = <KagiChart appearance={seriesProps}/>
                break;
            case 'point-figure':
                series = <PointAndFigureChart />
                break;
            case 'area':
                series = <AreaChart appearance={seriesProps}/>
                break;
            case 'candlestick':
                series = <CandlestickChart appearance={seriesProps}/>
                break;
            case 'hollow-candle':
                series = <CandlestickChart type={'hollow-candle'} appearance={seriesProps}/>
                break;
            case 'heikinAshi':
                series = <CandlestickChart appearance={seriesProps}/>
                break;
            case 'bar':
                series = <BarChart />
                break;
            case 'grouped-bar':
                series = <GroupedBarChart />
                break;
            case 'stacked-bar':
                series = <StackedBarChart />
                break;
            case 'renko':
                series = <RenkoChart />
                break;
            case 'scatter-ashi':
                series = <ScatterAshiChart 
                    marker={features.scatterLineMarker || 'circle'}
                    width={features.scatterLineWidth} 
                    stroke={features.scatterLineStroke} 
                    fill={features.scatterLineFill} 
                />
                break;
            default:
                series = <LineChart appearance={seriesProps}/>;
                break;
                
        }
        return series;
    }
    returnContainerConfig(calculators){
        const {maIndicatorEdges, macdIndicator, rsiIndicator, atrIndicator, stoIndicator, elderImpulseIndicator, sarIndicator, forceIndexIndicator} = calculators;
        // const {maIndicatorEdges, macdIndicator, rsiIndicator, atrIndicator} = this.state;
        const { type, width, ratio } = this.props;
        const { chartType, scaleXType, indicatorOpts } = this.props.rragChartData;
        let initialData = this.props.data
        // let initialData = this.props.data;
        const emptyD = {
            date: new Date(),
            close: 0,
            open: 0,
            high: 0,
            low: 0,
            volume: 0,
            absoluteChange: "",
            dividend: "",
            percentChange: "",
            split: ""
        }
        if(initialData.length <= 1){
            initialData.push(emptyD);
        }
        
        const isMaVisible = indicatorOpts['ma'] && indicatorOpts['ma'].visible
        const isMacdVisible = indicatorOpts['macd'] && indicatorOpts['macd'].visible;
        const isElderImpulseVisible = indicatorOpts['elder_impulse'] && indicatorOpts['elder_impulse'].visible && isMaVisible && isMacdVisible;
        const isVolumeProfile = indicatorOpts['volume_profile'] && indicatorOpts['volume_profile'].visible;
        const isForceIndexVisible = indicatorOpts['force_index'] && indicatorOpts['force_index'].visible;
        let buySell = null
        if(isMaVisible){
            // const {ema20, sma20, wma20, tma20, ema50, smaVolume50} = this.state.maIndicators;
            // calculatedData = ema20(sma20(wma20(tma20(ema50(smaVolume50(initialData))))));
            maIndicatorEdges.map(edge => {
                initialData = edge['edgeCalc'](initialData);  
            })
            if(maIndicatorEdges.length > 1){
                const prevKey = maIndicatorEdges[0].edgeName + maIndicatorEdges[0].edgeVal
                const nowKey = maIndicatorEdges[1].edgeName + maIndicatorEdges[1].edgeVal
                buySell = algo()
                    .windowSize(2)
                    .accumulator(([prev, now]) => {
                        const { [prevKey]: prevShortTerm, [nowKey]: prevLongTerm } = prev;
                        const { [prevKey]: nowShortTerm, [nowKey]: nowLongTerm } = now;
                        if (prevShortTerm < prevLongTerm && nowShortTerm > nowLongTerm) return "LONG";
                        if (prevShortTerm > prevLongTerm && nowShortTerm < nowLongTerm) return "SHORT";
                    })
                    .merge((d, c) => { d.longShort = c; });
            }
        }else if(indicatorOpts['edge'] && indicatorOpts['edge']['visible']){
            maIndicatorEdges.map(edge => {
                initialData = edge['edgeCalc'](initialData);  
            })
        }
        if(indicatorOpts['bb'] && indicatorOpts['bb']['visible']){
            initialData = bb(initialData);  
        }
        if(indicatorOpts['elder_ray'] && indicatorOpts['elder_ray']['visible']){
            const elder = elderRay();
            const changeCalculator = change();
            initialData = changeCalculator(elder(initialData));            
        }
        if(isMacdVisible){
            initialData = macdIndicator.calc(initialData);            
        }
        if(indicatorOpts['atr'] && indicatorOpts['atr']['visible']){
            initialData = atrIndicator['calc'](initialData);
        }
        if(indicatorOpts['rsi'] && indicatorOpts['rsi']['visible']){
            initialData = rsiIndicator['calc'](initialData);            
        }
        
        if(indicatorOpts['sto'] && indicatorOpts['sto']['visible']){
            initialData = stoIndicator['slow']['calc'](initialData);            
            initialData = stoIndicator['fast']['calc'](initialData);            
            initialData = stoIndicator['full']['calc'](initialData);          
        }
        if(indicatorOpts['sar'] && indicatorOpts['sar']['visible']){
            initialData = sarIndicator['calc'](initialData);
        }
        if(isForceIndexVisible){
            initialData = forceIndexIndicator['fiCalc'](initialData)
            initialData = forceIndexIndicator['fiEMACalc'](initialData)
        }
        if(isVolumeProfile){
            const changeCalculator = change();
            initialData = changeCalculator(initialData);            
        }
        if(isElderImpulseVisible && elderImpulseIndicator.calc){
            initialData = elderImpulseIndicator['calc'](initialData)
        }
        if(chartType === 'kagi'){
            const kagiCalculator = kagi();
            initialData = kagiCalculator(initialData);
        }else if(chartType === 'point-figure'){
            const pAndF = pointAndFigure();
            initialData = pAndF(initialData);
            if(initialData.length <= 1){
                initialData.push({
                    "date": new Date(),
                    "close": 0,
                    "open": 0,
                })
            }
            initialData.map(item => {
                item.date = new Date(item.date)
            })
        }else if(chartType === 'heikinAshi'){
            const ha = heikinAshi();
            initialData = ha(initialData);
        }else if(chartType === 'renko'){
            const renkoCalculator = renko();
            initialData = renkoCalculator(initialData);
        }
        if(buySell){
            initialData = buySell(initialData)
        }

        if(initialData.length <= 1){
            // initialData = [
            //     {
            //         "boxes": [],
            //         "close": 0,
            //         "open": 0,
            //         "date": "2010-01-03T23:00:00.000Z",
            //     },
            // ]
        }
        let data = initialData;
        let xAccessor = d => d.date;
        let displayXAccessor = null;
        let xScale = scaleTime();
        // if(scaleXType === 'discontinous'){
        const xScaleProvider = discontinuousTimeScaleProvider
            .inputDateAccessor(d => d.date);
        ({
            data,
            xScale,
            xAccessor,
            displayXAccessor,
        } = xScaleProvider(initialData));
        // }
        this.state.xExtents = [
            xAccessor(last(data)),
            xAccessor(data[data.length - (data.length > 100 ? 100 : data.length)])
        ] 
        let config = {
            ratio: 1,
            width: width,
            // height: 400,
            margin: { left: 50, right: 50, top: 10, bottom: 30 },
            // seriesName: 'MSFT',
            data: data,
            type: isVolumeProfile ? 'hybrid' : type,
            mouseMoveEvent: true,
            panEvent: true,
            zoomEvent: true,
            clamp: false,
            seriesName: `MSFT_${this.state.suffix}`,
            xScale: xScale,
            xAccessor: xAccessor,
            displayXAccessor: displayXAccessor
        }
        switch (chartType) {
            case 'line':
                // const ha = heikinAshi();
                // const calculatedData = ha(calculatedData);
                // const lineXScaleData = xScaleProvider(calculatedData);
                this.state.xExtents = [config.xAccessor(last(data)), config.xAccessor(data[Math.max(0, data.length - (data.length > 150 ? 150 : data.length))])]
                break;
            case 'grouped-bar':
                this.state.xExtents = [
                    xAccessor(last(data)),
                    xAccessor(data[data.length - (data.length > 20 ? 20 : data.length)])
                ];
                break;
        }
        return config
    }
	handleDownloadMore(maIndicatorEdges, start, end) {
		if (Math.ceil(start) === end) return;
        // console.log(maIndicatorEdges, start, end)
        return
		// console.log("rows to download", rowsToDownload, start, end)
		const { data: prevData, ema26, ema12, macdCalculator, smaVolume50 } = this.state;
		const { data: inputData } = this.props;


		if (inputData.length === prevData.length) return;

		const rowsToDownload = end - Math.ceil(start);

		const maxWindowSize = getMaxUndefined([ema26,
			ema12,
			macdCalculator,
			smaVolume50
		]);

		/* SERVER - START */
		const dataToCalculate = inputData
			.slice(-rowsToDownload - maxWindowSize - prevData.length, - prevData.length);

		const calculatedData = ema26(ema12(macdCalculator(smaVolume50(dataToCalculate))));
		const indexCalculator = discontinuousTimeScaleProviderBuilder()
			.initialIndex(Math.ceil(start))
			.indexCalculator();
		const { index } = indexCalculator(
			calculatedData
				.slice(-rowsToDownload)
				.concat(prevData));
		/* SERVER - END */

		const xScaleProvider = discontinuousTimeScaleProviderBuilder()
			.initialIndex(Math.ceil(start))
			.withIndex(index);

		const { data: linearData, xScale, xAccessor, displayXAccessor } = xScaleProvider(calculatedData.slice(-rowsToDownload).concat(prevData));

		// console.log(linearData.length)
		setTimeout(() => {
			// simulate a lag for ajax
			this.setState({
				data: linearData,
				xScale,
				xAccessor,
				displayXAccessor,
			});
		}, 300);
	}
    handleBrush(brushCoords, moreProps) {
		const { start, end } = brushCoords;
		const left = Math.min(start.xValue, end.xValue);
		const right = Math.max(start.xValue, end.xValue);

		const low = Math.min(start.yValue, end.yValue);
		const high = Math.max(start.yValue, end.yValue);

		// uncomment the line below to make the brush to zoom
		this.setState({
			xExtents: [left, right],
			brushEnabled: false,
		});
	}
    handleProps(){
        const { indicatorOpts } = this.props.rragChartData;
        let maIndicatorEdges = [];
        let macdIndicator = {}, rsiIndicator = {}, atrIndicator;

        const isMaVisible = indicatorOpts['ma'] && indicatorOpts['ma'].visible || false
        const isMacdVisible = indicatorOpts['macd'] && indicatorOpts['macd'].visible || false
        const isRsiVisible = indicatorOpts['rsi'] && indicatorOpts['rsi'].visible || false
        const isAtrVisible = indicatorOpts['atr'] && indicatorOpts['atr'].visible || false
        for(let indicatorId in indicatorOpts){
            let indicatorOpt = indicatorOpts[indicatorId]
            if(isMaVisible && indicatorId === 'ma'){
                indicatorOpt.edges.map(item => {
                    const maEdge = getMAIndicatorEdge(item)
                    maIndicatorEdges.push({
                        ...item,
                        edgeCalc: maEdge
                    })
                })
            }else if(isMacdVisible && indicatorId === 'macd'){
                macdIndicator = indicatorOpt;
                macdIndicator.calc = getMacdIndicator(indicatorOpt?.macd?.fast, indicatorOpt?.macd?.slow, indicatorOpt?.signal?.val);
                // macdIndicator.elderImpulseCalc = 
            }else if(isRsiVisible && indicatorId === 'rsi'){
                rsiIndicator = indicatorOpt;
                rsiIndicator.calc = getRsiIndicator(indicatorOpt?.val)
            }else if(isAtrVisible && indicatorId === 'atr'){
                atrIndicator = indicatorOpt;
                atrIndicator.calc = getAtrIndicator(indicatorOpt?.val, indicatorOpt?.stroke?.line)
            }
        }
        this.setState({
            maIndicatorEdges: maIndicatorEdges,
            macdIndicator: macdIndicator,
            rsiIndicator: rsiIndicator,
            atrIndicator: atrIndicator
        })
    }
    drawChartTheme(){
        const { themeBg } = this.props.rragChartData
        const themeProps = {
            XAxis:{ tickStrokeOpacity: 0.5},
            YAxis: { tickStrokeOpacity: 0.5, tickStroke:"black", stroke: "black" }, 

            toolTipApperance: {},

            CandlestickSeries: {},
            ElderRaySeries: {}, 
            MacdSeries: {},
            RsiSeries: {},
            chart1: {
                EdgeIndicator: {}
            },
            chart3: {},
            chart4: {},
            chart5: {},
            chart6: {},
        }
        if(themeBg?.name !== undefined){
            switch(themeBg.name){
                case 'white':
                    break;
                // case 'dark':
                default:
                    const chart1 = {
                        EdgeIndicator: {lineStroke: '#fff'}
                    }
                    themeProps.XAxis = { tickStrokeOpacity: 0.5, tickLabelFill:'#fff', stroke: "#fff" };
                    themeProps.YAxis = { tickStrokeOpacity: 0.5, tickLabelFill:'#fff', stroke: "#fff" };
                    themeProps.CandlestickSeries = { wickStroke: "white"}
                    themeProps.ElderRaySeries = { straightLineStroke: '#fff' }
                    themeProps.MacdSeries = { zeroLineStroke: '#fff' };
                    themeProps.RsiSeries = { 
                        stroke: {
                            top: "#fff",
                            middle: "#fff",
                            bottom: "#fff"
                        }
                    };
                    themeProps.toolTipApperance = { labelFill: '#7698ce', textFill: '#a5a2a2' }
                    themeProps.chart1 = chart1
                    break
            }
        }
        return themeProps
    }
    render() {
        const { chartType, volumeType, indicatorOpts, annotation, themeBg, grid, features, interactiveTool } = this.props.rragChartData;
        const { priceCoordinate } = features
        const { width, gridProps, data } = this.props;
        const isMaVisible = indicatorOpts['ma'] && indicatorOpts['ma'].visible || false
        const isEdgeVisible = indicatorOpts['edge'] && indicatorOpts['edge'].visible || false
        const isBbVisible = indicatorOpts['bb'] && indicatorOpts['bb'].visible || false
        const isElderVisible = indicatorOpts['elder_ray'] && indicatorOpts['elder_ray'].visible || false; 
        const isMacdVisible = indicatorOpts['macd'] && indicatorOpts['macd'].visible || false;
        const isRsiVisible = indicatorOpts['rsi'] && indicatorOpts['rsi'].visible || false;
        const isAtrVisible = indicatorOpts['atr'] && indicatorOpts['atr'].visible || false;
        const isStoVisible = indicatorOpts['sto'] && indicatorOpts['sto'].visible || false;
        const isElderImpulseVisible = isMaVisible && isMacdVisible && indicatorOpts['elder_impulse'] && indicatorOpts['elder_impulse'].visible || false;
        const isSarVisible = indicatorOpts['sar'] && indicatorOpts['sar'].visible || false;
        const isVolumeProfile = indicatorOpts['volume_profile'] && indicatorOpts['volume_profile'].visible || false;
        const isForceIndexVisible = indicatorOpts['force_index'] && indicatorOpts['force_index'].visible || false
        let maIndicatorEdges = [];
        let stoIndicator = [];
        let macdIndicator = {}, rsiIndicator = {}, atrIndicator, elderImpulseIndicator, sarIndicator, forceIndexIndicator;
        for(let indicatorId in indicatorOpts){
            let indicatorOpt = JSON.parse(JSON.stringify(indicatorOpts[indicatorId]))
            if(isMaVisible && indicatorId === 'ma'){
                indicatorOpt.edges.map(item => {
                    const maEdge = getMAIndicatorEdge(item)
                    maIndicatorEdges.push({
                        ...item,
                        edgeCalc: maEdge
                    })
                })
            }else if(isMacdVisible && indicatorId === 'macd'){
                macdIndicator = indicatorOpt;
                macdIndicator.calc = getMacdIndicator(indicatorOpt?.macd?.fast, indicatorOpt?.macd?.slow, indicatorOpt?.signal?.val)
            }else if(isRsiVisible && indicatorId === 'rsi'){
                rsiIndicator = indicatorOpt;
                rsiIndicator.calc = getRsiIndicator(indicatorOpt?.val)
            }else if(isAtrVisible && indicatorId === 'atr'){
                atrIndicator = indicatorOpt;
                atrIndicator.calc = getAtrIndicator(indicatorOpt?.val, indicatorOpt?.stroke?.line)
            }else if(isStoVisible && indicatorId === 'sto'){
                stoIndicator = indicatorOpt;
                if(stoIndicator.slow === undefined) stoIndicator.slow = {}
                if(stoIndicator.fast === undefined) stoIndicator.fast = {}
                if(stoIndicator.full === undefined) stoIndicator.full = {}
                const calc = getStoIndicator(indicatorOpt);
                stoIndicator.slow.calc = calc['slowSTO']
                stoIndicator.fast.calc = calc['fastSTO']
                stoIndicator.full.calc = calc['fullSTO']
            }else if(isElderImpulseVisible && indicatorId === 'elder_impulse'){
                elderImpulseIndicator = indicatorOpt;
                if(maIndicatorEdges?.length > 0){
                    elderImpulseIndicator.calc = elderImpulse()
                        .macdSource(macdIndicator['calc'].accessor());
                    maIndicatorEdges.map(item => {
                        elderImpulseIndicator.calc = elderImpulseIndicator['calc'].emaSource(item.edgeCalc.accessor())
                    })
                }
            }else if(isSarVisible && indicatorId === 'sar'){
                sarIndicator = indicatorOpt;
                sarIndicator.calc = getSarCalc(sarIndicator)
            }else if(isForceIndexVisible && indicatorId === 'force_index'){
                forceIndexIndicator = indicatorOpt;
                const {fi, fiEMA} = getForceIndexCalc(indicatorOpt?.fiEMA?.windowSize)
                forceIndexIndicator.fiCalc = fi;
                forceIndexIndicator.fiEMACalc = fiEMA;
            }
        }
        // this.handleProps();
        // const {maIndicatorEdges, macdIndicator, rsiIndicator, atrIndicator} = this.state;
        const calculators = {
            maIndicatorEdges, macdIndicator, rsiIndicator, atrIndicator, stoIndicator, elderImpulseIndicator, sarIndicator, forceIndexIndicator
        }
        const config = this.returnContainerConfig(calculators)
        
        let  chartHeight = 420, maYExtents= [d => d.open], consumedH = 330, elder, elderH, macdH, rsiH, atrH,
            stoSlowH, stoFastH, stoFullH, fiH, fiEMAH;
        
        const tooltipYs = [];
        const tooltipProps = {
            fontSize: 13,
            fill: 'white',
            opacity: 1,
            stroke: 'grey'
        }
        let chart1YScale;
        if(chartType === 'stacked-bar'){
            maYExtents = [0, d => [d.high + d.low + d.close + d.volume + d.open]]
        }else if(['candlestick', 'hollow-candle', 'heikinAshi'].indexOf(chartType) > -1){
            maYExtents = [d => [d.high, d.low]]
        }
        if(isVolumeProfile){
            maYExtents = maYExtents.concat([d => [d.high, d.low]])
        }
        if(isMaVisible){
            // const {ema20, sma20, wma20, tma20, ema50} = this.state.maIndicators
            // maYExtents = [d => [d.high, d.low], sma20.accessor(), wma20.accessor(), tma20.accessor(), ema20.accessor(), ema50.accessor()]
            // maYExtents = [d => [d.high, d.low]];
            maIndicatorEdges.map(edge => {
                maYExtents.push(edge['edgeCalc'].accessor())
                tooltipYs.push({
                    label: `${edge['edgeCalc'].type()}(${edge['edgeCalc'].options()
                        .windowSize})`,
                    value: d => numberFormat(edge['edgeCalc'].accessor()(d)),
                    stroke: edge['edgeCalc'].stroke()
                })
            })
        }
        if(isElderVisible){
            elder = elderRay();
            elderH = consumedH + 75
            consumedH += 75
            chartHeight += 75
        }
        if(isMacdVisible){
            macdH = consumedH + 75
            consumedH += 85
            chartHeight += 85
        }
        if(isRsiVisible){
            rsiH = consumedH + 75
            consumedH += 75
            chartHeight += 75
        }
        if(isAtrVisible){
            atrH = consumedH + 75
            consumedH += 85
            chartHeight += 85
        }
        if(isStoVisible){
            stoSlowH = consumedH + 75
            consumedH += 85
            chartHeight += 85

            stoFastH = consumedH + 75
            consumedH += 85
            chartHeight += 85

            stoFullH = consumedH + 75
            consumedH += 85
            chartHeight += 85
        }
        if(isForceIndexVisible){
            fiH = consumedH + 75
            consumedH += 85
            chartHeight += 85

            fiEMAH = consumedH + 75
            consumedH += 85
            chartHeight += 85
        }
        const volumeBarH = consumedH + 100

        const annotationProps = {
			fontFamily: "Glyphicons Halflings",
			fill: "#060F8F",
			opacity: 0.8,
			text: "H",
			y: ({ yScale }) => yScale.range()[0],
			onClick: console.log.bind(console),
			// tooltip: d => timeFormat("%B")(d.date),
			tooltip: d => d.close,
            // tooltip: d => {
            //     // timeFormat("%B")(d.date)
            //     return d.description
            // },
			onMouseOver: () => {
            },
		};
        const annotationWhen = (d) => {
            
            const index = annotation?.items.findIndex(item => {
                const dDate = new Date(d.date);
                const aDate = new Date(item.date);
                // console.log(item.date, dDate.toDateString(), aDate.toDateString)
                return dDate.toDateString() === aDate.toDateString()
            })
            // console.log(index, 'here?')
            return index > -1 ? true : false
        }


        // const margin = { left: 70, right: 70, top: 20, bottom: 30 };

        const { 
            XAxis: xAixProps, YAxis: yAixProps,
            CandlestickSeries: CandlestickSeriesProps, ElderRaySeries: ElderRaySeriesProps, MacdSeries: MacdSeriesProps, RsiSeries: RsiSeriesProps,
            toolTipApperance,
            chart1: chart1Props, chart2, chart3, chart4, chart5, chart6} = this.drawChartTheme();

        const chart1SeriesProps = ['candlestick', 'hollow-candle','heikinAshi'].indexOf(chartType) > -1 ? CandlestickSeriesProps : {};

        
		const height = 300;
		const gridHeight = height;
		const gridWidth = width - config.margin.left - config.margin.right;

		const showGrid = grid?.show || false;
		const yGrid = showGrid ? { innerTickSize: -1 * gridWidth } : {};
		const xGrid = showGrid ? { innerTickSize: -1 * gridHeight } : {};
        xAixProps.tickStroke = grid?.tickStroke
        yAixProps.tickStroke = grid?.tickStroke
        
        const BRUSH_TYPE = "2D";
        const { 
            xExtents, enableInteractiveObject, fans, 
            showInteractiveTextDialog, interactiveText,
            showInteractiveCoordinateDialog, alertToEdit  
        } = this.state

        const defaultAnnotationProps = {
			onClick: console.log.bind(console),
		};
        const longAnnotationProps = {
			...defaultAnnotationProps,
			y: ({ yScale, datum }) => yScale(datum.low),
			fill: "#006517",
			path: buyPath,
			tooltip: "Go long",
		};

		const shortAnnotationProps = {
			...defaultAnnotationProps,
			y: ({ yScale, datum }) => yScale(datum.high),
			fill: "#FF0000",
			path: sellPath,
			tooltip: "Go short",
		};

        return (
            <>
                <ChartCanvas {...config } 
                    xExtents={xExtents}  
                    height={chartHeight} 
                    bg={themeBg && themeBg?.color ? themeBg?.color: ''}
                    onLoadMore={(start, end) => {
                        this.handleDownloadMore(maIndicatorEdges, start, end)
                    }}
                    ref={this.saveCanvasNode}
                    >
                    <Chart id={1}
                            // yExtents={maYExtents}
                            yExtents={d => [d.high, d.low]}
                            yScale={chart1YScale}
                            height={300}>
                        <MouseCoordinateY
                            at="right"
                            orient="right"
                            displayFormat={format(".1f")} />
                        <XAxis axisAt="bottom" orient="bottom"
                            {...gridProps}
                            {...xGrid}
                            {...xAixProps}
                        />
                        <YAxis axisAt="right" orient="right" ticks={5} 
                            {...gridProps}
                            {...yGrid}
                            {...yAixProps}
                        />
                            {this.renderChartSeries(chart1SeriesProps)}
                        <ZoomButtons
                            onReset={this.handleReset}
                            // rx={10}
                            // ry={10}
                            heightFromBase={300}
                            fill={'#e0dfdf'}
                            stroke={'#e0dfdf'}
                            textFill={'black'}
                        />
                        <PriceCoordinate
                            at="right"
                            orient={priceCoordinate.orient || "right"}
                            price={priceCoordinate.price || 0}
                            stroke={priceCoordinate.stroke || "#3490DC"}
                            strokeWidth={1}
                            fill={priceCoordinate.fill || "#FFFFFF"}
                            textFill={priceCoordinate.textFill || "#22292F"}
                            arrowWidth={7}
                            strokeDasharray="ShortDash"
                            displayFormat={format(".2f")}
                        />
                        { 
                            isMaVisible && <MovingAverage maIndicatorEdges={maIndicatorEdges} appearance={toolTipApperance}/>
                        }
                        {
                            isBbVisible && <BollingerBands bb={bb} appearance={toolTipApperance}/>
                        }
                        {
                            isEdgeVisible && <EdgeIndicatorContainer maIndicatorEdges={maIndicatorEdges} appearance={chart1Props?.EdgeIndicator}/>
                        }
                        {
                            isElderImpulseVisible && elderImpulseIndicator['calc'] && (
                                <OHLCSeries stroke={d => elderImpulseIndicator['calc'].stroke()[d.elderImpulse]} />
                            )
                        }
                        {
                            isSarVisible && <SARSeries yAccessor={d => d.sar}/>
                        }
                        {
                            isVolumeProfile && <VolumeProfileSeries bySession orient="right" showSessionBackground /> 
                        }
                        {/* <OHLCTooltip forChart={1} origin={[0, 0]}/> */}
                        <AnnotateCustom with={CustomAnnotation}
                            when={annotationWhen}
                            usingProps={annotationProps} />

                            
                        <Annotate with={SvgPathAnnotation} when={d => d.longShort === "LONG"}
                            usingProps={longAnnotationProps} />
                        <Annotate with={SvgPathAnnotation} when={d => d.longShort === "SHORT"}
                            usingProps={shortAnnotationProps} />

                        <HoverTooltip
                            yAccessor={() => maYExtents}
                            tooltipContent={getTooltipContent(tooltipYs)}
                            {...tooltipProps}
                        />
                        {/* <Brush
                            ref={this.saveInteractiveNode(1)}
                            enabled={brushEnabled}
                            type={BRUSH_TYPE}
                            onBrush={this.handleBrush1}/> */}
                        <ClickCallback
                            // onMouseMove={ (moreProps, e) => { console.log("onMouseMove", moreProps, e); } }
                            // onMouseDown={ (moreProps, e) => { console.log("onMouseDown", moreProps, e); } }
                            onClick={ (moreProps, e) => { 
                                // console.log("onClick", moreProps, e);
                                // console.log(this.state.yCoordinateList, 'this.state.yCoordinatelist')
                                // if(interactiveTool !== 'interactive-y-coordinate'){
                                    this.setState({
                                        enableInteractiveObject: true
                                    });
                                // } 
                            }}
                            // onDoubleClick={ (moreProps, e) => { console.log("onDoubleClick", moreProps, e); } }
                            // onContextMenu={ (moreProps, e) => { console.log("onContextMenu", moreProps, e); } }
                            // onPan={ (moreProps, e) => { console.log("onPan", moreProps, e); } }
                            // onPanEnd={ (moreProps, e) => { console.log("onPanEnd", moreProps, e); } }
                        />
                        <InteractiveYCoordinate
                            ref={this.saveInteractiveNodes("InteractiveYCoordinate", 1)}
                            enabled={interactiveTool === 'interactive-y-coordinate' && enableInteractiveObject}
                            onDragComplete={this.onDragComplete}
                            onDelete={this.onDelete}
                            yCoordinateList={this.state.yCoordinateList}
                        />

                        <GannFan
                            ref={this.saveInteractiveNodes("GannFan", 1)}
                            enabled={interactiveTool === 'gann-fan' && enableInteractiveObject}
                            onStart={() => console.log("START")}
                            onComplete={this.onGannFanDrawComplete}
                            fans={fans}
                        />

                        <StandardDeviationChannel
                            ref={this.saveInteractiveNodes("StandardDeviationChannel", 1)}
                            enabled={interactiveTool === 'standard-deviation' && enableInteractiveObject}
                            onStart={() => console.log("START")}
                            onComplete={this.onStandardChannelDrawComplete}
                            channels={this.state.standardDeviationChannels}
                        />

                        <EquidistantChannel
                            ref={this.saveInteractiveNodes("EquidistantChannel", 1)}
                            enabled={interactiveTool === 'equidistant' && enableInteractiveObject}
                            onStart={() => console.log("START")}
                            onComplete={this.onEquidistantChannelDrawComplete}
                            channels={this.state.equidistantChannels}
                        />

                        <FibonacciRetracement
                            ref={this.saveInteractiveNodes("FibonacciRetracement", 1)}
                            // enabled={interactiveTool === 'fibonacci' && enableInteractiveObject}
                            enabled={interactiveTool === 'fibonacci'}
                            retracements={this.state.fibonacciRetracements}
                            onComplete={this.onFibComplete}
                        />

                        <TrendLine
                            ref={this.saveInteractiveNodes("Trendline", 1)}
                            enabled={interactiveTool == 'trendline' && enableInteractiveObject}
                            type="RAY"
                            snap={true}
                            snapTo={d => [d.high, d.low]}
                            onSelect={(e) => {
                                console.log(e, 'trendline selected')
                            }}
                            onStart={() => console.log("onStart trendline")}
                            onComplete={this.onDrawTrendLineComplete}
                            trends={this.state.trends}
                        />

                        <InteractiveText
							ref={this.saveInteractiveNodes("InteractiveText", 1)}
							enabled={interactiveTool === 'interactive-text' && this.state.enableInteractiveObject}
							text="Lorem ipsum..."
							onDragComplete={this.onDrawInteractiveText}
							textList={this.state.textList}
						/>
                    </Chart>
                    <Chart id={2} origin={(w, h) => [0, 150]} height={150} yExtents={d => d.volume}>
                        <XAxis axisAt="bottom" orient="bottom"/>
                        <YAxis axisAt="left" orient="left" ticks={5} tickFormat={format(".2s")}/>
                        <BarSeries yAccessor={d => d.volume} fill={(d) => d.close > d.open ? "#6BA583" : "red"} />
                    </Chart>
                    <Chart id={3} yExtents={d => d.open} height={50}
                            origin={(w, h) => [0, 320]}>
                        <MouseCoordinateY
                            at="right"
                            orient="right"
                            displayFormat={format(".1f")} />
                        <XAxis axisAt="bottom" orient="bottom" ticks={6} {...xAixProps}/>
                        <YAxis axisAt="right" orient="right" ticks={5} {...yAixProps}/>
                        {volumeType === 'line' ? <LineChart yAccessorKey={'open'} /> : <BarChart/>}
                    </Chart>
                    {
                        isElderVisible && 
                            <Chart id={4} height={70}
                                yExtents={[0, elder.accessor()]}
                                origin={(w, h) => [0, elderH]}
                                padding={{ top: 10, bottom: 10 }}
                        >
                            <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0}  {...xAixProps}/>
                            <YAxis axisAt="right" orient="right" ticks={4} tickFormat={format(".2f")} {...yAixProps}/>

                            <MouseCoordinateY
                                at="right"
                                orient="right"
                                displayFormat={format(".2f")} />

                            <ElderRaySeries yAccessor={elder.accessor()} opacity={1} {...ElderRaySeriesProps}/>
                            <OHLCTooltip forChart={3} origin={[-38, 15]}  {...toolTipApperance}>
                            </OHLCTooltip>
                        </Chart>
                    }
                    {
                        isMacdVisible && <Chart id={5} height={70}
                                yExtents={macdIndicator['calc'].accessor()}
                                origin={(w, h) => [0, macdH]} padding={{ top: 10, bottom: 10 }}
                        >
                            <XAxis axisAt="bottom" orient="bottom" {...xAixProps}/>
                            <YAxis axisAt="right" orient="right" ticks={2}  {...yAixProps}/>
                            <MacdIndicator indicator={macdIndicator} seriesApperance={MacdSeriesProps}/>
                        </Chart>
                    }
                    {
                        isRsiVisible && <Chart id={6}
                                yExtents={[0, 100]}
                                height={70} origin={(w, h) => [0, rsiH]}
                        >
                            <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} {...xAixProps}/>
                            <YAxis axisAt="right" orient="right" tickValues={[30, 50, 70]} {...yAixProps}/>
                            <MouseCoordinateY
                                at="right"
                                orient="right"
                                displayFormat={format(".2f")} />
                            <RsiIndicator indicator={rsiIndicator} seriesApperance={RsiSeriesProps} toolTipApperance={toolTipApperance}/>
                        </Chart>
                    }
                    {
                        isAtrVisible && 
                        <Chart id={7}
                                yExtents={atrIndicator['calc'].accessor()}
                                height={70} origin={(w, h) => [0, atrH]} padding={{ top: 10, bottom: 10 }}
                        >
                            <XAxis axisAt="bottom" orient="bottom" {...xAixProps}/>
                            <YAxis axisAt="right" orient="right" ticks={2} {...yAixProps}/>

                            <MouseCoordinateX
                                at="bottom"
                                orient="bottom"
                                displayFormat={timeFormat("%Y-%m-%d")} />
                            <MouseCoordinateY
                                at="right"
                                orient="right"
                                displayFormat={format(".2f")} />
                            <AtrIndicator indicator={atrIndicator}  toolTipApperance={toolTipApperance}/>
                        </Chart>
                    }
                    {
                        isStoVisible &&
                            <Chart id={8}
                                yExtents={[0, 100]}
                                height={75} origin={(w, h) => [0, stoSlowH]} padding={{ top: 10, bottom: 10 }}
                            >
                                <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} {...xAixProps}/>
                                <YAxis axisAt="right" orient="right"
                                    tickValues={[20, 50, 80]} {...yAixProps}/>

                                <MouseCoordinateY
                                    at="right"
                                    orient="right"
                                    displayFormat={format(".2f")} />
                                <StoIndicator
                                    indicator={stoIndicator['slow']}
                                    toolTipApperance={toolTipApperance}
                                    yAccessor={d => d.slowSTO}
                                    stoAppearance={{}}
                                    label={'Slow STO'}
                                />
                            </Chart>
                    }
                    {
                        isStoVisible && 
                            <Chart id={9}
                                yExtents={[0, 100]}
                                height={75} origin={(w, h) => [0, stoFastH]} padding={{ top: 10, bottom: 10 }}
                            >
                                <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} {...xAixProps}/>
                                <YAxis axisAt="right" orient="right"
                                    tickValues={[20, 50, 80]} {...yAixProps}/>

                                <MouseCoordinateY
                                    at="right"
                                    orient="right"
                                    displayFormat={format(".2f")} />
                                <StoIndicator
                                    indicator={stoIndicator['fast']}
                                    toolTipApperance={toolTipApperance}
                                    yAccessor={d => d.fastSTO}
                                    stoAppearance={{}}
                                    label={'Fast STO'}
                                />
                            </Chart>
                    }
                    {
                        isStoVisible && 
                            <Chart id={10}
                                yExtents={[0, 100]}
                                height={75} origin={(w, h) => [0, stoFullH]} padding={{ top: 10, bottom: 10 }}
                            >
                                <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} {...xAixProps}/>
                                <YAxis axisAt="right" orient="right"
                                    tickValues={[20, 50, 80]} {...yAixProps}/>

                                <MouseCoordinateY
                                    at="right"
                                    orient="right"
                                    displayFormat={format(".2f")} />
                                <StoIndicator
                                    indicator={stoIndicator['full']}
                                    toolTipApperance={toolTipApperance}
                                    yAccessor={d => d.fullSTO}
                                    stoAppearance={{}}
                                    label={'Full STO'}
                                />
                            </Chart>
                    }
                    {
                        isForceIndexVisible && <Chart id={11} height={85}
                            yExtents={forceIndexIndicator['fiCalc'].accessor()}
                            origin={(w, h) => [0, fiH]}
                            padding={{ top: 10, right: 0, bottom: 10, left: 0 }}
                        >
                            <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} {...xAixProps}/>
                            <YAxis axisAt="right" orient="right" ticks={4} tickFormat={format(".2s")} {...yAixProps}/>
                            <MouseCoordinateY
                                at="right"
                                orient="right"
                                displayFormat={format(".4s")} />
                            <FiIndicator indicator={forceIndexIndicator} />
                        </Chart>
                    }
                    {
                        isForceIndexVisible && <Chart id={12} height={85}
                            yExtents={forceIndexIndicator['fiEMACalc'].accessor()}
                            origin={(w, h) => [0, fiEMAH]}
                            padding={{ top: 10, right: 0, bottom: 10, left: 0 }}
                        >
                            <XAxis axisAt="bottom" orient="bottom" showTicks={false} outerTickSize={0} {...xAixProps}/>
                            <YAxis axisAt="right" orient="right" ticks={4} tickFormat={format(".2s")} {...yAixProps}/>
                            <MouseCoordinateY
                                at="right"
                                orient="right"
                                displayFormat={format(".4s")} />
                            <FiEMAIndicator indicator={forceIndexIndicator} />
                        </Chart>
                    }
                    <CrossHairCursor stroke="grey" />
                    <DrawingObjectSelector
                        enabled
                        getInteractiveNodes={this.getInteractiveNodes}
                        drawingObjectMap={{
                            // InteractiveText: "textList",
                            // InteractiveYCoordinate: "yCoordinateList",
                            // GannFan: "fans",
                            // TrendLine: "trends"
                        }}
                        onSelect={this.handleSelection}
                        onDoubleClick={this.handleDoubleClickAlert}
                    />
                </ChartCanvas>
                <InteractiveTextDialog 
                    open={showInteractiveTextDialog}
					text={interactiveText}
					onClose={this.handleInteractiveTextDialogClose}
					onSave={this.handleInteractiveTextChange}
                />
                <InteractiveCoordinateDialog
					open={showInteractiveCoordinateDialog}
					alert={alertToEdit.alert}
					onClose={this.handleInteractiveCoordinateDialogClose}
					onSave={this.handleChangeAlert}
					onDeleteAlert={this.handleDeleteAlert}
				/>
            </>
        );
    }
}


ChartContainer.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    ratio: PropTypes.number.isRequired,
    type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

ChartContainer.defaultProps = {
    type: "svg",
    chartType: 'line'
};
ChartContainer = fitWidth(ChartContainer);

const mapStateToProps = (state) => ({
    rragChartData: state.rragChartData
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(ChartContainer);
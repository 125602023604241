import React, { useState, useCallback, useMemo } from 'react';

import {
  Box,
  IconButton,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import AddCircleIcon from '@mui/icons-material/Add';
import { setProductOpt} from "store/slices/StockProductSlice";
import ExpandablePanelBox from "components/common/Base/ExpandablePanelBox";
import ColorPicker from "components/common/ColorPicker";
import {useProductContext} from "providers/product/ProductProvider";
import {CustomizeItem, EndNode, StartNode} from "components/common/Product/CustomizeItem";
import CustomFormInput from "components/common/Base/CustomFormInput";
import CustomFormSelect from "components/common/Base/CustomFormSelect";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import Divider from "@mui/material/Divider";

const bgColors = [
  '#fff','#fce4ec', '#ffcdd2', '#f3e5f5', '#e8eaf6', '#e3f2fd', '#d1c4e9',
  '#c5cae9', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#e0f2f1', '#e6ee9c', '#fff9c4',
  '#eeeeee', '#ffccbc'
]

export const TooltipFeature = () => {
  const dispatch = useDispatch()

  const tooltip = useSelector(state => state?.productData?.chartSettings?.tooltip)
  const { marketChartType, series: seriesData, fields, tooltipFormatData } = useSelector(state => state?.productData)
  const { feedSourceType } = useProductContext()
  const [tempDataField, setTempDataField] = useState('x')
  const [applyTo, setApplyTo] = useState('all')

  const selectedTooltip = useMemo(() => {
    return tooltipFormatData[applyTo] || tooltipFormatData['all']
  }, [
    tooltipFormatData,
    applyTo
  ])

  const onAddFormat = useCallback(() => {
    if(tempDataField){
      dispatch(setProductOpt({
        path: `tooltipFormatData.${applyTo}.pointFormatBody`,
        value: `${selectedTooltip.pointFormatBody} <br /> ${tempDataField.split('.').join(' ').toLocaleUpperCase()}: {point.${tempDataField}}`
      }))
    }
  }, [
    applyTo,
    selectedTooltip,
    tempDataField,
  ])

  const seriesList = useMemo(() => {
    let innerList = [{label: 'All Series', id: 'all'}]
    if(feedSourceType === 'stock'){
      innerList = innerList.concat([{
        id: 'stock', label: 'stock'
      }, {
        id: 'volume', label: 'volume'
      }])
    }else{
    }
    seriesData.map(item => {
      if(!(feedSourceType === 'stock' && item.id === 'default')){
        innerList.push({
          id: item.id, label: item.value
        })
      }
    })
    return innerList
  }, [
    feedSourceType,
    seriesData
  ])
  const addInformationData = useMemo(() => {
    let options = [
      { value: 'x', label: 'X' },
      { value: 'series.name', label: 'Series Name' },
      { value: 'key', label: 'Point Key' },
      { value: 'color', label: 'Color' },
    ]
    if(feedSourceType === 'stock' && ['ohlc', 'hlc', 'candlestick', 'heikinashi', 'hollowcandlestick'].includes(marketChartType)){
      options = options.concat([
        { value: 'open', label: 'Open' },
        { value: 'high', label: 'High' },
        { value: 'low', label: 'Low' },
        { value: 'close', label: 'Close' },
      ])
    }
    return options
  }, [
    fields,
    feedSourceType,
    marketChartType,
  ])

  return (
    <ExpandablePanelBox panel={{label: 'ToolTip'}} collapsed={true}>
      <Box display={'flex'} sx={{ flexFlow: 'column', gap: '10px' }}>
        <DropdownSelect
          onChange={(newValue) => {
            setApplyTo(newValue)
            if(!tooltipFormatData[newValue]){
              dispatch(setProductOpt({
                path: `tooltipFormatData.${newValue}`, value: tooltipFormatData['all']
              }))
            }
            // dispatch(setProductOpt({
            //   path: 'chartSettings.legend.horizontal.opposite', value: newValue === 'top'
            // }))
          }}
          sx={{ marginBottom: '10px' }}
          selectedValue={applyTo}
          placeholder="Apply to"
          options={seriesList}
        />
        {
          feedSourceType === 'stock' && (
            <CustomFormInput
              label=""
              fieldName="text"
              value={selectedTooltip.pointFormatHeader}
              onChange={(field, value) => {
                dispatch(setProductOpt({
                  path: `tooltipFormatData.${applyTo}.pointFormatHeader`,
                  value
                }))
              }}
            />
          )
        }
        <CustomFormInput
          label=""
          type="textarea"
          fieldName="text"
          placeholder={`{series.name}: {point.y}`}
          value={selectedTooltip.pointFormatBody}
          onChange={(field, value) => {
            dispatch(setProductOpt({
              path: `tooltipFormatData.${applyTo}.pointFormatBody`,
              value
            }))
          }}
        />
        <Box>
          <span>Add information from data</span>
          <Box display="flex" sx={{ alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
              <CustomFormSelect
                size="small"
                options={addInformationData}
                value={tempDataField}
                onChange={(field, value) => setTempDataField(value)}
              />
            </Box>
            <IconButton onClick={onAddFormat}>
              <AddCircleIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ margin: '10px 0px' }}/>
        <CustomizeItem>
          <StartNode>Background Color</StartNode>
          <EndNode>
            <span>{tooltip?.backgroundColor || '#fff'}</span>
            <ColorPicker
              colors={bgColors}
              color={tooltip?.backgroundColor}
              pickerLable={''}
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: 'chartSettings.tooltip.backgroundColor',
                  value: color.hex
                }))
              }}
            />
          </EndNode>
        </CustomizeItem>
        <CustomizeItem>
          <StartNode>Border Color</StartNode>
          <EndNode>
            <span>{tooltip?.borderColor || '#fff'}</span>
            <ColorPicker
              colors={bgColors}
              color={tooltip?.borderColor}
              pickerLable={''}
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: 'chartSettings.tooltip.borderColor',
                  value: color.hex
                }))
              }}
            />
          </EndNode>
        </CustomizeItem>
      </Box>
    </ExpandablePanelBox>
  )
};

import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import {makeStyles} from "@mui/styles";



interface  ChildrenProp {
  children?: ReactNode;
}
export const StartNode: React.FC<ChildrenProp> = ({ children }) => <>{children}</>;
export const EndNode: React.FC<ChildrenProp> = ({ children }) => <>{children}</>;

const useStyles = makeStyles(() => ({
  FieldItem: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f7f8f8',
    borderRadius: '4px',
    padding: '10px',
  },
}));

export const  CustomizeItem: React.FC<ChildrenProp> = ({
  children,
}) =>  {
  const classes = useStyles()

  let startNode: ReactNode | undefined;
  let endNode: ReactNode | undefined;

  // Iterate over the children to identify header and body components
  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.type === StartNode) {
        startNode = child;
      } else if (child.type === EndNode) {
        endNode = child;
      }
    }
  });

  return (
    <Box className={classes.FieldItem}>
      <Box sx={{ flex: 1 }}>{startNode}</Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {endNode}
      </Box>
    </Box>
  );
}

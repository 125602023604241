import {makeStyles} from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    noDisplay: {
        display: 'none',
    },
    stepSidebar: {
        position: 'relative',
        width: 390,
        maxWidth: 390,
        boxShadow: 'rgba(0, 0, 0, 0.1) 0 2px 22px 0, rgba(0, 0, 0, 0.15) 0 4px 20px 0',
        transitionProperty: 'top, bottom, width',
        transitionDuration: '0.2s, 0.2s, 0.35s',
        transitionTimingFunction: 'linear, linear, ease',
        transition: 'all ease .3s',
        backgroundColor: '#F5F8FF',
        marginLeft: 20,
        borderRadius: 6,
        height: '100vh',
        maxHeight: 'calc(100vh - 100px)',
        zIndex: 1030,
        '&::before': {
            content: "",
            position: 'absolute',
            top: '-5px',
            left: '40px',
            transform: 'translate(-50%)',
            borderLeft: '5px solid transparent',
            borderright: '5px solid transparent',
            borderBottomColor: 'rgb(255, 141, 114)',
        },
    },
    minimizedStepSidebar: {
        width: 100,
        maxWidth: 100,
        minWidth: 100,
    },
    stepSidebar2: {
        background: '#f5f8ff',
    },
    stepSidebarInner: {
        height: '100%',
        /* Track */
        '&::-webkit-scrollbar-track': {
            background: '#0a0a0aed',
            border: 'solid 1px white',
            borderRadius: 4,
        },
        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            background: '#f30000',
            borderRadius: 4,
        },
        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#9e1e1e',
        },
    },
    navList: {
        marginTop: 20,
        display: 'block',
    },
    navItem: { 
        // color: '#707071',
        display: 'block',
        position: 'relative',
        textTransform: 'uppercase',
        cursor: 'pointer',
        fontSize: '0.85475rem',
        fontWeight: '400',
        lineHeight: '1.625rem',
        margin: 0,
        textDecoration: 'none',
        padding: '14px 0 14px 37px',
        '&.selected': {
            background: '#2255C3',
            '& .mui-icon': {
                color: 'white'
            }
        }
    },
    step2SidebarInner: {
        width: '100%',
        minHeight: '100%',
        maxHeight: '100%',
        zIndex: 4,
        position: 'relative',
        overflow: 'auto',
    }
}))
import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import Highcharts3D from 'highcharts/highcharts-3d'
import IndicatorsAll from 'highcharts/indicators/indicators-all'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import StockData from 'highcharts/modules/data'
import HighchartAccessibility from 'highcharts/modules/accessibility'
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import DragPane from 'highcharts/modules/drag-panes'
import AnnotationsAdvanced from 'highcharts/modules/annotations-advanced'
import PriceIndicator from 'highcharts/modules/price-indicator'
import FullScreen from 'highcharts/modules/full-screen'
import StockTools from 'highcharts/modules/stock-tools'
import Heikinashi from 'highcharts/modules/heikinashi'
import HollowCandlestick from 'highcharts/modules/hollowcandlestick'
import HistogramBellCurve from 'highcharts/modules/histogram-bellcurve'
import Gantt from 'highcharts/modules/gantt'

import HighchartsMore from 'highcharts/highcharts-more';
import { theme as DefaultOpts } from './DefaultOpts'
import { HighContrastDarkTheme
 } from './ThemeOpts'

// Highcharts3D(Highcharts)
StockData(Highcharts)
DragPane(Highcharts)
IndicatorsAll(Highcharts)
StockTools(Highcharts)
AnnotationsAdvanced(Highcharts)
PriceIndicator(Highcharts)
FullScreen(Highcharts)
HighchartAccessibility(Highcharts)

Heikinashi(Highcharts)
HollowCandlestick(Highcharts)
Gantt(Highcharts)
HistogramBellCurve(Highcharts)

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

const HighChartWrapper = ({options, constructorType, themeModule, themeName}) => {
    Highcharts.theme = {...DefaultOpts, ...HighContrastDarkTheme
    }
    Highcharts.setOptions(Highcharts.theme)

    return(
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                constructorType={constructorType}
            />
    );
}

export default HighChartWrapper;
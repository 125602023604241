import React, { useEffect, useState, useMemo } from "react";
// import { useSelector } from 'react-redux'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import moment from 'moment'
import _ from 'underscore'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

import Highcharts from 'highcharts/highstock'
import StockData from 'highcharts/modules/data'
import PatternFill from 'highcharts/modules/pattern-fill'
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import ReactHighcharts from 'highcharts-react-official'
import CustomFormSelect from "components/common/Base/CustomFormSelect";
import CustomAutocomplete from "components/common/Base/CustomAutocomplete";
import { Box } from "@mui/system";
StockData(Highcharts)
HighchartsMore(Highcharts);
PatternFill(Highcharts)
PatternFill(Highcharts)
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

function ColumnChart(props) {
    const { data: initialData, configs: {
        xAxis, yAxis
    } } = props
    const [periodType, setPeriodType] = useState('yearDuration');
    const [periodValue, setPeriodValue] = useState({
      from: '2010-01-01',
      to: new Date(),
      year: new Date()
    });

    const categories = useMemo(() => {
      let _categories = []
      if(periodType === 'yearDuration'){
        const _from = new Date(periodValue.from).getFullYear()
        const _to = new Date(periodValue.to).getFullYear()
        if(periodValue.from){
          if(!periodValue.to) {
            _categories.push([_from])
          }else{
            for(let i = _from; i <= _to; i++){
              _categories.push(i)
            }
          }
        }
      }else{
        _categories = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
      }
      return _categories
    }, [
      periodType, periodValue
    ])
    const series = useMemo(() => {
      const _initialSeries = []
      const _pFrom = new Date(periodValue.from).getFullYear()
      const _pTo = new Date(periodValue.to).getFullYear()
      const _pYear = new Date(periodValue.year).getFullYear()
      const _yAxis = Array.isArray(yAxis) && yAxis[0] !== undefined ? yAxis[0] : yAxis
      
      initialData.sort((a, b) => {
        if(a[xAxis] && b[xAxis]){
            return new Date(a[xAxis]).getTime() - new Date(b[xAxis]).getTime()
        }
        return 1;
      })
      .map(item => {
        let _x
        if(periodType === 'yearDuration'){
          _x = new Date(item[xAxis]).getTime()
        }else{
          _x = new Date(item[xAxis]).getMonth()
          _x = categories[_x]
        }
        // const _chkIdx = _initialSeries[item[groupByField]].findIndex(item => item.x === _x)
        // if(_chkIdx > -1){
        //   _initialSeries[item[groupByField]][_chkIdx].y += item[_yAxis] * 1 
        // }else{
          if(!isNaN(item[_yAxis] * 1) && item[_yAxis] !== null){
            _initialSeries.push({
              x: _x,
              y: isNaN(item[_yAxis] * 1) ? null : item[_yAxis] * 1,
            })
          }
        // }
      })
      // let _series = {}
      // for(let key in _initialSeries){
      //   if(!_series[key]) {
      //     _series[key] = {
      //       name: key,
      //       data: []
      //     }
      //   }
      //   categories.map(ca => {
      //     let _chk = _initialSeries[key].filter(item => item.x == ca)[0]
      //     if(_chk){
      //       _series[key].data.push(_chk.y)
      //     }else{
      //       _series[key].data.push(null)
      //     }
      //   })
      // }
      // const _values = _.values(_series)
      // return _values
      // console.log(_data, '_data')
      console.log(_yAxis, xAxis, _initialSeries, '_initialSeries')
      return [{
        data: _initialSeries
      }]
    }, [
      periodType,
      periodValue,
      categories,
      initialData,
      yAxis,
      xAxis,
    ])
    const configStock = useMemo(() => {
      return {
        chart: {
          type: 'column',
          zoomType: 'x'
        },
        mapNavigation: {
          enabled: true,
          enableDoubleClickZoom: true,
          enableMouseWheelZoom: true,
          enableTouchZoom: true,
          mouseWheelSensitivity:2
        },
        xAxis: {
          // categories: categories,
          crosshair: true,
          type: "datetime",
          labels: {
            formatter: function() {
              return Highcharts.dateFormat('%b/%e/%Y', this.value);
            }
          } 
        },
        yAxis: {
          min: 0,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        stockTools: {
          enabled: false,
        },
        series: series
      }
    }, [categories, series])
    
    return (
      <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
        <Box display="flex" sx={{ flexDirection: 'column', height: '100%' }}>
          <Grid container spacing={2}>
            <Grid item container lg={12} md={12} xs={12} spacing={2}>
              <Grid item lg={4} md={4} xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="period-type-label">Period Type</InputLabel>
                  <Select
                    labelId="period-type-label"
                    id="period-type"
                    value={periodType}
                    label="Period Type"
                    onChange={(e) => {
                      setPeriodType(e.target.value)
                    }}
                  >
                    {/* <MenuItem value={'yearly'}>Yearly</MenuItem> */}
                    <MenuItem value={'yearDuration'}>Year Duration</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {
                periodType === 'yearDuration' ? (
                  <>
                    <Grid item container lg={6} md={6} xs={12} alignItems="flex-end" spacing={2}>
                      <Grid item lg={6} md={6} xs={12}>
                        <DesktopDatePicker
                          label="From"
                          inputFormat="yyyy"
                          value={periodValue?.from || null}
                          views={['year']}
                          onChange={(newVal) => {
                            setPeriodValue({
                              ...periodValue, from: newVal
                            })
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xs={12}>
                        <DesktopDatePicker
                          label="To"
                          inputFormat="yyyy"
                          value={periodValue?.to || null}
                          views={['year']}
                          onChange={(newVal) => {
                            setPeriodValue({
                              ...periodValue, to: newVal
                            })
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Grid item lg={6} md={6} xs={12}>
                    <DesktopDatePicker
                      label="Year"
                      inputFormat="yyyy"
                      value={periodValue?.year || null}
                      views={['year']}
                      onChange={(newVal) => {
                        setPeriodValue({
                          ...periodValue, year: newVal
                        })
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                )
              }
            </Grid>
          </Grid>
          <Box flex={1}>
            <ReactHighcharts
              highcharts={Highcharts}
              constructorType={"chart"}
              options={configStock}
              containerProps={{ style: { height: "100%" } }}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    )
}

export default ColumnChart
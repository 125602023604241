export const theme = {
    "colors": [
        "#7cb5ec",
        "#434348",
        "#90ed7d",
        "#f7a35c",
        "#8085e9",
        "#f15c80",
        "#e4d354",
        "#2b908f",
        "#f45b5b",
        "#91e8e1"
    ],
    "symbols": [
        "circle",
        "diamond",
        "square",
        "triangle",
        "triangle-down"
    ],
    "lang": {
        "viewFullscreen": "View in full screen",
        "exitFullscreen": "Exit from full screen",
        "printChart": "Print chart",
        "downloadPNG": "Download PNG image",
        "downloadJPEG": "Download JPEG image",
        "downloadPDF": "Download PDF document",
        "downloadSVG": "Download SVG vector image",
        "contextButtonTitle": "Chart context menu",
        "loading": "Loading...",
        "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ],
        "shortMonths": [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        "weekdays": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ],
        "decimalPoint": ".",
        "numericSymbols": [
            "k",
            "M",
            "G",
            "T",
            "P",
            "E"
        ],
        "resetZoom": "Reset zoom",
        "resetZoomTitle": "Reset zoom level 1:1",
        "thousandsSep": " ",
        "rangeSelectorZoom": "Zoom",
        "rangeSelectorFrom": "",
        "rangeSelectorTo": "→",
        "navigation": {
            "popup": {
                "simpleShapes": "Simple shapes",
                "lines": "Lines",
                "circle": "Circle",
                "ellipse": "Ellipse",
                "rectangle": "Rectangle",
                "label": "Label",
                "shapeOptions": "Shape options",
                "typeOptions": "Details",
                "fill": "Fill",
                "format": "Text",
                "strokeWidth": "Line width",
                "stroke": "Line color",
                "title": "Title",
                "name": "Name",
                "labelOptions": "Label options",
                "labels": "Labels",
                "backgroundColor": "Background color",
                "backgroundColors": "Background colors",
                "borderColor": "Border color",
                "borderRadius": "Border radius",
                "borderWidth": "Border width",
                "style": "Style",
                "padding": "Padding",
                "fontSize": "Font size",
                "color": "Color",
                "height": "Height",
                "shapes": "Shape options",
                "segment": "Segment",
                "arrowSegment": "Arrow segment",
                "ray": "Ray",
                "arrowRay": "Arrow ray",
                "line": "Line",
                "arrowInfinityLine": "Arrow line",
                "horizontalLine": "Horizontal line",
                "verticalLine": "Vertical line",
                "crooked3": "Crooked 3 line",
                "crooked5": "Crooked 5 line",
                "elliott3": "Elliott 3 line",
                "elliott5": "Elliott 5 line",
                "verticalCounter": "Vertical counter",
                "verticalLabel": "Vertical label",
                "verticalArrow": "Vertical arrow",
                "fibonacci": "Fibonacci",
                "fibonacciTimeZones": "Fibonacci Time Zones",
                "pitchfork": "Pitchfork",
                "parallelChannel": "Parallel channel",
                "infinityLine": "Infinity line",
                "measure": "Measure",
                "measureXY": "Measure XY",
                "measureX": "Measure X",
                "measureY": "Measure Y",
                "timeCycles": "Time Cycles",
                "flags": "Flags",
                "addButton": "add",
                "saveButton": "save",
                "editButton": "edit",
                "removeButton": "remove",
                "series": "Series",
                "volume": "Volume",
                "connector": "Connector",
                "innerBackground": "Inner background",
                "outerBackground": "Outer background",
                "crosshairX": "Crosshair X",
                "crosshairY": "Crosshair Y",
                "tunnel": "Tunnel",
                "background": "Background",
                "noFilterMatch": "No match",
                "searchIndicators": "Search Indicators",
                "clearFilter": "✕ clear filter",
                "index": "Index",
                "period": "Period",
                "periods": "Periods",
                "standardDeviation": "Standard deviation",
                "periodTenkan": "Tenkan period",
                "periodSenkouSpanB": "Senkou Span B period",
                "periodATR": "ATR period",
                "multiplierATR": "ATR multiplier",
                "shortPeriod": "Short period",
                "longPeriod": "Long period",
                "signalPeriod": "Signal period",
                "decimals": "Decimals",
                "algorithm": "Algorithm",
                "topBand": "Top band",
                "bottomBand": "Bottom band",
                "initialAccelerationFactor": "Initial acceleration factor",
                "maxAccelerationFactor": "Max acceleration factor",
                "increment": "Increment",
                "multiplier": "Multiplier",
                "ranges": "Ranges",
                "highIndex": "High index",
                "lowIndex": "Low index",
                "deviation": "Deviation",
                "xAxisUnit": "x-axis unit",
                "factor": "Factor",
                "fastAvgPeriod": "Fast average period",
                "slowAvgPeriod": "Slow average period",
                "average": "Average",
                "indicatorAliases": {
                    "abands": [
                        "Acceleration Bands"
                    ],
                    "bb": [
                        "Bollinger Bands"
                    ],
                    "dema": [
                        "Double Exponential Moving Average"
                    ],
                    "ema": [
                        "Exponential Moving Average"
                    ],
                    "ikh": [
                        "Ichimoku Kinko Hyo"
                    ],
                    "keltnerchannels": [
                        "Keltner Channels"
                    ],
                    "linearRegression": [
                        "Linear Regression"
                    ],
                    "pivotpoints": [
                        "Pivot Points"
                    ],
                    "pc": [
                        "Price Channel"
                    ],
                    "priceenvelopes": [
                        "Price Envelopes"
                    ],
                    "psar": [
                        "Parabolic SAR"
                    ],
                    "sma": [
                        "Simple Moving Average"
                    ],
                    "supertrend": [
                        "Super Trend"
                    ],
                    "tema": [
                        "Triple Exponential Moving Average"
                    ],
                    "vbp": [
                        "Volume by Price"
                    ],
                    "vwap": [
                        "Volume Weighted Moving Average"
                    ],
                    "wma": [
                        "Weighted Moving Average"
                    ],
                    "zigzag": [
                        "Zig Zag"
                    ],
                    "apo": [
                        "Absolute price indicator"
                    ],
                    "ad": [
                        "Accumulation/Distribution"
                    ],
                    "aroon": [
                        "Aroon"
                    ],
                    "aroonoscillator": [
                        "Aroon oscillator"
                    ],
                    "atr": [
                        "Average True Range"
                    ],
                    "ao": [
                        "Awesome oscillator"
                    ],
                    "cci": [
                        "Commodity Channel Index"
                    ],
                    "chaikin": [
                        "Chaikin"
                    ],
                    "cmf": [
                        "Chaikin Money Flow"
                    ],
                    "cmo": [
                        "Chande Momentum Oscillator"
                    ],
                    "disparityindex": [
                        "Disparity Index"
                    ],
                    "dmi": [
                        "Directional Movement Index"
                    ],
                    "dpo": [
                        "Detrended price oscillator"
                    ],
                    "klinger": [
                        "Klinger Oscillator"
                    ],
                    "linearRegressionAngle": [
                        "Linear Regression Angle"
                    ],
                    "linearRegressionIntercept": [
                        "Linear Regression Intercept"
                    ],
                    "linearRegressionSlope": [
                        "Linear Regression Slope"
                    ],
                    "macd": [
                        "Moving Average Convergence Divergence"
                    ],
                    "mfi": [
                        "Money Flow Index"
                    ],
                    "momentum": [
                        "Momentum"
                    ],
                    "natr": [
                        "Normalized Average True Range"
                    ],
                    "obv": [
                        "On-Balance Volume"
                    ],
                    "ppo": [
                        "Percentage Price oscillator"
                    ],
                    "roc": [
                        "Rate of Change"
                    ],
                    "rsi": [
                        "Relative Strength Index"
                    ],
                    "slowstochastic": [
                        "Slow Stochastic"
                    ],
                    "stochastic": [
                        "Stochastic"
                    ],
                    "trix": [
                        "TRIX"
                    ],
                    "williamsr": [
                        "Williams %R"
                    ]
                }
            }
        },
        "stockTools": {
            "gui": {
                "simpleShapes": "Simple shapes",
                "lines": "Lines",
                "crookedLines": "Crooked lines",
                "measure": "Measure",
                "advanced": "Advanced",
                "toggleAnnotations": "Toggle annotations",
                "verticalLabels": "Vertical labels",
                "flags": "Flags",
                "zoomChange": "Zoom change",
                "typeChange": "Type change",
                "saveChart": "Save chart",
                "indicators": "Indicators",
                "currentPriceIndicator": "Current Price Indicators",
                "zoomX": "Zoom X",
                "zoomY": "Zoom Y",
                "zoomXY": "Zooom XY",
                "fullScreen": "Fullscreen",
                "typeOHLC": "OHLC",
                "typeLine": "Line",
                "typeCandlestick": "Candlestick",
                "typeHLC": "HLC",
                "typeHollowCandlestick": "Hollow Candlestick",
                "typeHeikinAshi": "Heikin Ashi",
                "circle": "Circle",
                "ellipse": "Ellipse",
                "label": "Label",
                "rectangle": "Rectangle",
                "flagCirclepin": "Flag circle",
                "flagDiamondpin": "Flag diamond",
                "flagSquarepin": "Flag square",
                "flagSimplepin": "Flag simple",
                "measureXY": "Measure XY",
                "measureX": "Measure X",
                "measureY": "Measure Y",
                "segment": "Segment",
                "arrowSegment": "Arrow segment",
                "ray": "Ray",
                "arrowRay": "Arrow ray",
                "line": "Line",
                "arrowInfinityLine": "Arrow line",
                "horizontalLine": "Horizontal line",
                "verticalLine": "Vertical line",
                "infinityLine": "Infinity line",
                "crooked3": "Crooked 3 line",
                "crooked5": "Crooked 5 line",
                "elliott3": "Elliott 3 line",
                "elliott5": "Elliott 5 line",
                "verticalCounter": "Vertical counter",
                "verticalLabel": "Vertical label",
                "verticalArrow": "Vertical arrow",
                "fibonacci": "Fibonacci",
                "fibonacciTimeZones": "Fibonacci Time Zones",
                "pitchfork": "Pitchfork",
                "parallelChannel": "Parallel channel",
                "timeCycles": "Time Cycles"
            }
        },
        "accessibility": {
            "defaultChartTitle": "Chart",
            "chartContainerLabel": "{title}. Highcharts interactive chart.",
            "svgContainerLabel": "Interactive chart",
            "drillUpButton": "{buttonText}",
            "credits": "Chart credits: {creditsStr}",
            "thousandsSep": ",",
            "svgContainerTitle": "",
            "graphicContainerLabel": "",
            "screenReaderSection": {
                "beforeRegionLabel": "Chart screen reader information, {chartTitle}.",
                "afterRegionLabel": "",
                "annotations": {
                    "heading": "Chart annotations summary",
                    "descriptionSinglePoint": "{annotationText}. Related to {annotationPoint}",
                    "descriptionMultiplePoints": "{annotationText}. Related to {annotationPoint}{ Also related to, #each(additionalAnnotationPoints)}",
                    "descriptionNoPoints": "{annotationText}"
                },
                "endOfChartMarker": "End of interactive chart."
            },
            "sonification": {
                "playAsSoundButtonText": "Play as sound, {chartTitle}",
                "playAsSoundClickAnnouncement": "Play"
            },
            "legend": {
                "legendLabelNoTitle": "Toggle series visibility, {chartTitle}",
                "legendLabel": "Chart legend: {legendTitle}",
                "legendItem": "Show {itemName}"
            },
            "zoom": {
                "mapZoomIn": "Zoom chart",
                "mapZoomOut": "Zoom out chart",
                "resetZoomButton": "Reset zoom"
            },
            "rangeSelector": {
                "dropdownLabel": "{rangeTitle}",
                "minInputLabel": "Select start date.",
                "maxInputLabel": "Select end date.",
                "clickButtonAnnouncement": "Viewing {axisRangeDescription}"
            },
            "table": {
                "viewAsDataTableButtonText": "View as data table, {chartTitle}",
                "tableSummary": "Table representation of chart."
            },
            "announceNewData": {
                "newDataAnnounce": "Updated data for chart {chartTitle}",
                "newSeriesAnnounceSingle": "New data series: {seriesDesc}",
                "newPointAnnounceSingle": "New data point: {pointDesc}",
                "newSeriesAnnounceMultiple": "New data series in chart {chartTitle}: {seriesDesc}",
                "newPointAnnounceMultiple": "New data point in chart {chartTitle}: {pointDesc}"
            },
            "seriesTypeDescriptions": {
                "boxplot": "Box plot charts are typically used to display groups of statistical data. Each data point in the chart can have up to 5 values: minimum, lower quartile, median, upper quartile, and maximum.",
                "arearange": "Arearange charts are line charts displaying a range between a lower and higher value for each point.",
                "areasplinerange": "These charts are line charts displaying a range between a lower and higher value for each point.",
                "bubble": "Bubble charts are scatter charts where each data point also has a size value.",
                "columnrange": "Columnrange charts are column charts displaying a range between a lower and higher value for each point.",
                "errorbar": "Errorbar series are used to display the variability of the data.",
                "funnel": "Funnel charts are used to display reduction of data in stages.",
                "pyramid": "Pyramid charts consist of a single pyramid with item heights corresponding to each point value.",
                "waterfall": "A waterfall chart is a column chart where each column contributes towards a total end value."
            },
            "chartTypes": {
                "emptyChart": "Empty chart",
                "mapTypeDescription": "Map of {mapTitle} with {numSeries} data series.",
                "unknownMap": "Map of unspecified region with {numSeries} data series.",
                "combinationChart": "Combination chart with {numSeries} data series.",
                "defaultSingle": "Chart with {numPoints} data {#plural(numPoints, points, point)}.",
                "defaultMultiple": "Chart with {numSeries} data series.",
                "splineSingle": "Line chart with {numPoints} data {#plural(numPoints, points, point)}.",
                "splineMultiple": "Line chart with {numSeries} lines.",
                "lineSingle": "Line chart with {numPoints} data {#plural(numPoints, points, point)}.",
                "lineMultiple": "Line chart with {numSeries} lines.",
                "columnSingle": "Bar chart with {numPoints} {#plural(numPoints, bars, bar)}.",
                "columnMultiple": "Bar chart with {numSeries} data series.",
                "barSingle": "Bar chart with {numPoints} {#plural(numPoints, bars, bar)}.",
                "barMultiple": "Bar chart with {numSeries} data series.",
                "pieSingle": "Pie chart with {numPoints} {#plural(numPoints, slices, slice)}.",
                "pieMultiple": "Pie chart with {numSeries} pies.",
                "scatterSingle": "Scatter chart with {numPoints} {#plural(numPoints, points, point)}.",
                "scatterMultiple": "Scatter chart with {numSeries} data series.",
                "boxplotSingle": "Boxplot with {numPoints} {#plural(numPoints, boxes, box)}.",
                "boxplotMultiple": "Boxplot with {numSeries} data series.",
                "bubbleSingle": "Bubble chart with {numPoints} {#plural(numPoints, bubbles, bubble)}.",
                "bubbleMultiple": "Bubble chart with {numSeries} data series."
            },
            "axis": {
                "xAxisDescriptionSingular": "The chart has 1 X axis displaying {names[0]}. {ranges[0]}",
                "xAxisDescriptionPlural": "The chart has {numAxes} X axes displaying {#each(names, -1) }and {names[-1]}.",
                "yAxisDescriptionSingular": "The chart has 1 Y axis displaying {names[0]}. {ranges[0]}",
                "yAxisDescriptionPlural": "The chart has {numAxes} Y axes displaying {#each(names, -1) }and {names[-1]}.",
                "timeRangeDays": "Range: {range} days.",
                "timeRangeHours": "Range: {range} hours.",
                "timeRangeMinutes": "Range: {range} minutes.",
                "timeRangeSeconds": "Range: {range} seconds.",
                "rangeFromTo": "Range: {rangeFrom} to {rangeTo}.",
                "rangeCategories": "Range: {numCategories} categories."
            },
            "exporting": {
                "chartMenuLabel": "Chart menu",
                "menuButtonLabel": "View chart menu, {chartTitle}"
            },
            "series": {
                "summary": {
                    "default": "{name}, series {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
                    "defaultCombination": "{name}, series {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
                    "line": "{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
                    "lineCombination": "{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
                    "spline": "{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
                    "splineCombination": "{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
                    "column": "{name}, bar series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bars, bar)}.",
                    "columnCombination": "{name}, series {ix} of {numSeries}. Bar series with {numPoints} {#plural(numPoints, bars, bar)}.",
                    "bar": "{name}, bar series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bars, bar)}.",
                    "barCombination": "{name}, series {ix} of {numSeries}. Bar series with {numPoints} {#plural(numPoints, bars, bar)}.",
                    "pie": "{name}, pie {ix} of {numSeries} with {numPoints} {#plural(numPoints, slices, slice)}.",
                    "pieCombination": "{name}, series {ix} of {numSeries}. Pie with {numPoints} {#plural(numPoints, slices, slice)}.",
                    "scatter": "{name}, scatter plot {ix} of {numSeries} with {numPoints} {#plural(numPoints, points, point)}.",
                    "scatterCombination": "{name}, series {ix} of {numSeries}, scatter plot with {numPoints} {#plural(numPoints, points, point)}.",
                    "boxplot": "{name}, boxplot {ix} of {numSeries} with {numPoints} {#plural(numPoints, boxes, box)}.",
                    "boxplotCombination": "{name}, series {ix} of {numSeries}. Boxplot with {numPoints} {#plural(numPoints, boxes, box)}.",
                    "bubble": "{name}, bubble series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bubbles, bubble)}.",
                    "bubbleCombination": "{name}, series {ix} of {numSeries}. Bubble series with {numPoints} {#plural(numPoints, bubbles, bubble)}.",
                    "map": "{name}, map {ix} of {numSeries} with {numPoints} {#plural(numPoints, areas, area)}.",
                    "mapCombination": "{name}, series {ix} of {numSeries}. Map with {numPoints} {#plural(numPoints, areas, area)}.",
                    "mapline": "{name}, line {ix} of {numSeries} with {numPoints} data {#plural(numPoints, points, point)}.",
                    "maplineCombination": "{name}, series {ix} of {numSeries}. Line with {numPoints} data {#plural(numPoints, points, point)}.",
                    "mapbubble": "{name}, bubble series {ix} of {numSeries} with {numPoints} {#plural(numPoints, bubbles, bubble)}.",
                    "mapbubbleCombination": "{name}, series {ix} of {numSeries}. Bubble series with {numPoints} {#plural(numPoints, bubbles, bubble)}."
                },
                "description": "{description}",
                "xAxisDescription": "X axis, {name}",
                "yAxisDescription": "Y axis, {name}",
                "nullPointValue": "No value",
                "pointAnnotationsDescription": "{Annotation: #each(annotations). }"
            }
        },
        "downloadCSV": "Download CSV",
        "downloadXLS": "Download XLS",
        "exportData": {
            "annotationHeader": "Annotations",
            "categoryHeader": "Category",
            "categoryDatetimeHeader": "DateTime"
        },
        "viewData": "View data table",
        "hideData": "Hide data table"
    },
    "global": {},
    "time": {
        "timezoneOffset": 0,
        "useUTC": true
    },
    "chart": {
        "panning": {
            "enabled": false,
            "type": "x"
        },
        "styledMode": false,
        "borderRadius": 0,
        "colorCount": 10,
        "defaultSeriesType": "line",
        "ignoreHiddenSeries": true,
        "spacing": [
            10,
            10,
            15,
            10
        ],
        "resetZoomButton": {
            "theme": {
                "zIndex": 6
            },
            "position": {
                "align": "right",
                "x": -10,
                "y": 10
            }
        },
        "zoomBySingleTouch": false,
        "width": null,
        "height": null,
        "borderColor": "#335cad",
        "backgroundColor": "#ffffff",
        "plotBorderColor": "#cccccc"
    },
    "title": {
        "text": "Chart title",
        "align": "center",
        "margin": 15,
        "widthAdjust": -44
    },
    "subtitle": {
        "text": "",
        "align": "center",
        "widthAdjust": -44
    },
    "caption": {
        "margin": 15,
        "text": "",
        "align": "left",
        "verticalAlign": "bottom"
    },
    "plotOptions": {
        "line": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x"
        },
        "area": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "threshold": 0
        },
        "spline": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x"
        },
        "areaspline": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "threshold": 0
        },
        "column": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6
            },
            "threshold": 0,
            "borderColor": "#ffffff"
        },
        "bar": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6
            },
            "threshold": 0,
            "borderColor": "#ffffff"
        },
        "scatter": {
            "lineWidth": 0,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": true
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "xy",
            "jitter": {
                "x": 0,
                "y": 0
            },
            "tooltip": {
                "headerFormat": "<span style=\"color:{point.color}\">●</span> <span style=\"font-size: 10px\"> {series.name}</span><br/>",
                "pointFormat": "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
            }
        },
        "pie": {
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0,
                "allowOverlap": true,
                "connectorPadding": 5,
                "connectorShape": "fixedOffset",
                "crookDistance": "70%",
                "distance": 30,
                "enabled": true,
                "softConnector": true
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    },
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "center": [
                null,
                null
            ],
            "clip": false,
            "colorByPoint": true,
            "ignoreHiddenPoint": true,
            "inactiveOtherPoints": true,
            "legendType": "point",
            "size": null,
            "showInLegend": false,
            "slicedOffset": 10,
            "tooltip": {
                "followPointer": true
            },
            "borderColor": "#ffffff",
            "borderWidth": 1
        },
        "hlc": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1,
                    "lineWidth": 3
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
            },
            "threshold": null,
            "borderColor": "#ffffff"
        },
        "ohlc": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1,
                    "lineWidth": 3
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
            },
            "threshold": null,
            "borderColor": "#ffffff"
        },
        "candlestick": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1,
                    "lineWidth": 2
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
            },
            "threshold": null,
            "borderColor": "#ffffff",
            "line": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x"
            },
            "area": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "threshold": 0
            },
            "spline": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x"
            },
            "areaspline": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "threshold": 0
            },
            "column": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6
                },
                "threshold": 0,
                "borderColor": "#ffffff"
            },
            "bar": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6
                },
                "threshold": 0,
                "borderColor": "#ffffff"
            },
            "scatter": {
                "lineWidth": 0,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    },
                    "enabled": true
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "xy",
                "jitter": {
                    "x": 0,
                    "y": 0
                },
                "tooltip": {
                    "headerFormat": "<span style=\"color:{point.color}\">●</span> <span style=\"font-size: 10px\"> {series.name}</span><br/>",
                    "pointFormat": "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
                }
            },
            "pie": {
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0,
                    "allowOverlap": true,
                    "connectorPadding": 5,
                    "connectorShape": "fixedOffset",
                    "crookDistance": "70%",
                    "distance": 30,
                    "enabled": true,
                    "softConnector": true
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        },
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "center": [
                    null,
                    null
                ],
                "clip": false,
                "colorByPoint": true,
                "ignoreHiddenPoint": true,
                "inactiveOtherPoints": true,
                "legendType": "point",
                "size": null,
                "showInLegend": false,
                "slicedOffset": 10,
                "tooltip": {
                    "followPointer": true
                },
                "borderColor": "#ffffff",
                "borderWidth": 1
            },
            "hlc": {
                "lineWidth": 1,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1,
                        "lineWidth": 3
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6,
                    "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
                },
                "threshold": null,
                "borderColor": "#ffffff"
            },
            "ohlc": {
                "lineWidth": 1,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1,
                        "lineWidth": 3
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6,
                    "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
                },
                "threshold": null,
                "borderColor": "#ffffff"
            },
            "lineColor": "#000000",
            "upColor": "#ffffff"
        },
        "flags": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1,
                    "lineColor": "#000000",
                    "fillColor": "#ccd6eb"
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "{point.text}"
            },
            "threshold": null,
            "borderColor": "#ffffff",
            "allowOverlapX": false,
            "shape": "flag",
            "stackDistance": 12,
            "textAlign": "center",
            "y": -30,
            "fillColor": "#ffffff",
            "style": {
                "fontSize": "11px",
                "fontWeight": "bold"
            }
        },
        "arearange": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0,
                "y": 0,
                "xLow": 0,
                "xHigh": 0,
                "yLow": 0,
                "yHigh": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "threshold": null,
            "tooltip": {
                "pointFormat": "<span style=\"color:{series.color}\">●</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b><br/>"
            },
            "trackByArea": true
        },
        "areasplinerange": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0,
                "y": 0,
                "xLow": 0,
                "xHigh": 0,
                "yLow": 0,
                "yHigh": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "threshold": null,
            "tooltip": {
                "pointFormat": "<span style=\"color:{series.color}\">●</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b><br/>"
            },
            "trackByArea": true
        },
        "boxplot": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Maximum: {point.high}<br/>Upper quartile: {point.q3}<br/>Median: {point.median}<br/>Lower quartile: {point.q1}<br/>Minimum: {point.low}<br/>"
            },
            "threshold": null,
            "borderColor": "#ffffff",
            "whiskerLength": "50%",
            "fillColor": "#ffffff",
            "medianWidth": 2,
            "whiskerWidth": 2
        },
        "bubble": {
            "lineWidth": 0,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": null,
                "lineWidth": 1,
                "radius": null,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 0,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": true,
                "fillOpacity": 0.5,
                "symbol": "circle"
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "middle",
                "x": 0,
                "y": 0,
                "inside": true
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": false,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 5,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 0,
            "findNearestPointBy": "xy",
            "jitter": {
                "x": 0,
                "y": 0
            },
            "tooltip": {
                "headerFormat": "<span style=\"color:{point.color}\">●</span> <span style=\"font-size: 10px\"> {series.name}</span><br/>",
                "pointFormat": "({point.x}, {point.y}), Size: {point.z}"
            },
            "animationLimit": 250,
            "minSize": 8,
            "maxSize": "20%",
            "zThreshold": 0,
            "zoneAxis": "z"
        },
        "columnrange": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0,
                "y": 0,
                "xLow": 0,
                "xHigh": 0,
                "yLow": 0,
                "yHigh": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "<span style=\"color:{series.color}\">●</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b><br/>"
            },
            "threshold": null,
            "borderColor": "#ffffff",
            "trackByArea": true
        },
        "columnpyramid": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6
            },
            "threshold": 0,
            "borderColor": "#ffffff"
        },
        "errorbar": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> {series.name}: <b>{point.low}</b> - <b>{point.high}</b><br/>"
            },
            "threshold": null,
            "borderColor": "#ffffff",
            "whiskerLength": "50%",
            "fillColor": "#ffffff",
            "medianWidth": 2,
            "whiskerWidth": null,
            "color": "#000000",
            "grouping": false,
            "linkedTo": ":previous"
        },
        "gauge": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": false,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "top",
                "x": 0,
                "y": 15,
                "borderColor": "#cccccc",
                "borderRadius": 3,
                "borderWidth": 1,
                "crop": false,
                "enabled": true,
                "zIndex": 2
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "dial": {},
            "pivot": {},
            "tooltip": {
                "headerFormat": ""
            },
            "showInLegend": false
        },
        "packedbubble": {
            "lineWidth": 0,
            "allowPointSelect": false,
            "crisp": false,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": null,
                "lineWidth": 1,
                "radius": null,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 0,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": true,
                "fillOpacity": 0.5,
                "symbol": "circle"
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 0,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast",
                    "transition": "opacity 2000ms"
                },
                "verticalAlign": "middle",
                "x": 0,
                "y": 0,
                "inside": true,
                "parentNodeTextPath": {
                    "enabled": true
                }
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": false,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 5,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 0,
            "findNearestPointBy": "xy",
            "jitter": {
                "x": 0,
                "y": 0
            },
            "tooltip": {
                "headerFormat": "<span style=\"color:{point.color}\">●</span> <span style=\"font-size: 10px\"> {series.name}</span><br/>",
                "pointFormat": "Value: {point.value}"
            },
            "animationLimit": 250,
            "minSize": "10%",
            "maxSize": "50%",
            "zThreshold": 0,
            "zoneAxis": "y",
            "sizeBy": "area",
            "draggable": true,
            "useSimulation": true,
            "parentNode": {
                "allowPointSelect": false
            },
            "layoutAlgorithm": {
                "initialPositions": "circle",
                "initialPositionRadius": 20,
                "bubblePadding": 5,
                "parentNodeLimit": false,
                "seriesInteraction": true,
                "dragBetweenSeries": false,
                "parentNodeOptions": {
                    "maxIterations": 400,
                    "gravitationalConstant": 0.03,
                    "maxSpeed": 50,
                    "initialPositionRadius": 100,
                    "seriesInteraction": true,
                    "marker": {
                        "fillColor": null,
                        "fillOpacity": 1,
                        "lineWidth": null,
                        "lineColor": null,
                        "symbol": "circle"
                    }
                },
                "enableSimulation": true,
                "type": "packedbubble",
                "integration": "packedbubble",
                "maxIterations": 1000,
                "splitSeries": false,
                "maxSpeed": 5,
                "gravitationalConstant": 0.01,
                "friction": -0.981
            }
        },
        "polygon": {
            "lineWidth": 0,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": false,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "xy",
            "jitter": {
                "x": 0,
                "y": 0
            },
            "tooltip": {
                "headerFormat": "<span style=\"color:{point.color}\">●</span> <span style=\"font-size: 10px\"> {series.name}</span><br/>",
                "pointFormat": "",
                "followPointer": true
            },
            "trackByArea": true
        },
        "waterfall": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0,
                "inside": true
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 0,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6
            },
            "threshold": 0,
            "borderColor": "#333333",
            "lineColor": "#333333",
            "dashStyle": "Dot"
        },
        "sma": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14
            }
        },
        "ema": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 9
            }
        },
        "ad": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 14,
                "volumeSeriesID": "volume"
            }
        },
        "ao": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": false,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 0,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {},
            "greaterBarColor": "#06b535",
            "lowerBarColor": "#f21313",
            "threshold": 0,
            "groupPadding": 0.2,
            "pointPadding": 0.2
        },
        "aroon": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span><b> {series.name}</b><br/>Aroon Up: {point.y}<br/>Aroon Down: {point.aroonDown}<br/>"
            },
            "compareToMain": false,
            "params": {
                "period": 25
            },
            "aroonDown": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "aroonoscillator": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span><b> {series.name}</b>: {point.y}"
            },
            "compareToMain": false,
            "params": {
                "period": 25
            },
            "aroonDown": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "atr": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 14
            }
        },
        "bb": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span><b> {series.name}</b><br/>Top: {point.top}<br/>Middle: {point.middle}<br/>Bottom: {point.bottom}<br/>"
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 20,
                "standardDeviation": 2
            },
            "bottomLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "topLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "cci": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 14
            }
        },
        "cmf": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 14,
                "volumeSeriesID": "volume"
            }
        },
        "dmi": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color: {point.color}\">●</span><b> {series.name}</b><br/><span style=\"color: {point.color}\">DX</span>: {point.y}<br/><span style=\"color: {point.series.options.plusDILine.styles.lineColor}\">+DI</span>: {point.plusDI}<br/><span style=\"color: {point.series.options.minusDILine.styles.lineColor}\">-DI</span>: {point.minusDI}<br/>"
            },
            "compareToMain": false,
            "params": {
                "period": 14
            },
            "plusDILine": {
                "styles": {
                    "lineWidth": 1,
                    "lineColor": "#06b535"
                }
            },
            "minusDILine": {
                "styles": {
                    "lineWidth": 1,
                    "lineColor": "#f21313"
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "dpo": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 0,
                "period": 21
            }
        },
        "chaikin": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 9,
                "volumeSeriesID": "volume",
                "periods": [
                    3,
                    10
                ]
            }
        },
        "cmo": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 20
            }
        },
        "dema": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 9
            }
        },
        "tema": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 9
            }
        },
        "trix": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 9
            }
        },
        "apo": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "periods": [
                    10,
                    20
                ]
            }
        },
        "ikh": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>TENKAN SEN: {point.tenkanSen:.3f}<br/>KIJUN SEN: {point.kijunSen:.3f}<br/>CHIKOU SPAN: {point.chikouSpan:.3f}<br/>SENKOU SPAN A: {point.senkouSpanA:.3f}<br/>SENKOU SPAN B: {point.senkouSpanB:.3f}<br/>"
            },
            "compareToMain": false,
            "params": {
                "period": 26,
                "periodTenkan": 9,
                "periodSenkouSpanB": 52
            },
            "tenkanLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "kijunLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "chikouLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "senkouSpanA": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "senkouSpanB": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "senkouSpan": {
                "styles": {
                    "fill": "rgba(255, 0, 0, 0.5)"
                }
            },
            "dataGrouping": {
                "approximation": "ichimoku-averages"
            }
        },
        "keltnerchannels": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span><b> {series.name}</b><br/>Upper Channel: {point.top}<br/>EMA({series.options.params.period}): {point.middle}<br/>Lower Channel: {point.bottom}<br/>"
            },
            "compareToMain": false,
            "params": {
                "index": 0,
                "period": 20,
                "periodATR": 10,
                "multiplierATR": 2
            },
            "bottomLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "topLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "klinger": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color: {point.color}\">●</span><b> {series.name}</b><br/><span style=\"color: {point.color}\">Klinger</span>: {point.y}<br/><span style=\"color: {point.series.options.signalLine.styles.lineColor}\">Signal</span>: {point.signal}<br/>"
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14,
                "fastAvgPeriod": 34,
                "slowAvgPeriod": 55,
                "signalPeriod": 13,
                "volumeSeriesID": "volume"
            },
            "signalLine": {
                "styles": {
                    "lineWidth": 1,
                    "lineColor": "#ff0000"
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "macd": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": false,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 0,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Value: {point.MACD}<br/>Signal: {point.signal}<br/>Histogram: {point.y}<br/>"
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 26,
                "shortPeriod": 12,
                "longPeriod": 26,
                "signalPeriod": 9
            },
            "signalLine": {
                "zones": [],
                "styles": {
                    "lineWidth": 1
                }
            },
            "macdLine": {
                "zones": [],
                "styles": {
                    "lineWidth": 1
                }
            },
            "threshold": 0,
            "groupPadding": 0.1,
            "pointPadding": 0.1,
            "dataGrouping": {
                "approximation": "averages"
            },
            "minPointLength": 0
        },
        "mfi": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 14,
                "volumeSeriesID": "volume",
                "decimals": 4
            }
        },
        "momentum": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14
            }
        },
        "natr": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "valueSuffix": "%"
            },
            "compareToMain": false,
            "params": {
                "period": 14
            }
        },
        "obv": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 0
            },
            "compareToMain": false,
            "params": {
                "volumeSeriesID": "volume"
            }
        },
        "pivotpoints": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0,
                "enabled": true,
                "format": "{point.pivotLine}"
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 28,
                "algorithm": "standard"
            },
            "enableMouseTracking": false,
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "ppo": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "periods": [
                    12,
                    26
                ]
            }
        },
        "pc": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 20
            },
            "topLine": {
                "styles": {
                    "lineColor": "#90ed7d",
                    "lineWidth": 1
                }
            },
            "bottomLine": {
                "styles": {
                    "lineColor": "#f45b5b",
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "priceenvelopes": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span><b> {series.name}</b><br/>Top: {point.top}<br/>Middle: {point.middle}<br/>Bottom: {point.bottom}<br/>"
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 20,
                "topBand": 0.1,
                "bottomBand": 0.1
            },
            "bottomLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "topLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "psar": {
            "lineWidth": 0,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": true
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 0,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 2,
                "initialAccelerationFactor": 0.02,
                "maxAccelerationFactor": 0.2,
                "increment": 0.02,
                "decimals": 4
            }
        },
        "roc": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 9
            }
        },
        "rsi": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14,
                "decimals": 4
            }
        },
        "stochastic": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span><b> {series.name}</b><br/>%K: {point.y}<br/>%D: {point.smoothed}<br/>"
            },
            "compareToMain": false,
            "params": {
                "periods": [
                    14,
                    3
                ]
            },
            "smoothedLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "slowstochastic": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span><b> {series.name}</b><br/>%K: {point.y}<br/>%D: {point.smoothed}<br/>"
            },
            "compareToMain": false,
            "params": {
                "periods": [
                    14,
                    3,
                    3
                ]
            },
            "smoothedLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "supertrend": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 10,
                "multiplier": 3
            },
            "risingTrendColor": "#06b535",
            "fallingTrendColor": "#f21313",
            "changeTrendLine": {
                "styles": {
                    "lineWidth": 1,
                    "lineColor": "#333333",
                    "dashStyle": "LongDash"
                }
            }
        },
        "vbp": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 0,
                "style": {
                    "fontSize": "7px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "top",
                "x": 0,
                "y": 0,
                "allowOverlap": true,
                "enabled": true,
                "format": "P: {point.volumePos:.2f} | N: {point.volumeNeg:.2f}"
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "ranges": 12,
                "volumeSeriesID": "volume"
            },
            "zoneLines": {
                "enabled": true,
                "styles": {
                    "color": "#0A9AC9",
                    "dashStyle": "LongDash",
                    "lineWidth": 1
                }
            },
            "volumeDivision": {
                "enabled": true,
                "styles": {
                    "positiveColor": "rgba(144, 237, 125, 0.8)",
                    "negativeColor": "rgba(244, 91, 91, 0.8)"
                }
            },
            "animationLimit": 1000,
            "enableMouseTracking": false,
            "pointPadding": 0,
            "zIndex": -1,
            "dataGrouping": {
                "enabled": false
            }
        },
        "vwap": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 30,
                "volumeSeriesID": "volume"
            }
        },
        "williamsr": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "period": 14
            }
        },
        "wma": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 9
            }
        },
        "zigzag": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "lowIndex": 2,
                "highIndex": 1,
                "deviation": 1
            }
        },
        "linearRegression": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14,
                "xAxisUnit": null
            }
        },
        "linearRegressionSlope": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14,
                "xAxisUnit": null
            }
        },
        "linearRegressionIntercept": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14,
                "xAxisUnit": null
            }
        },
        "linearRegressionAngle": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4,
                "pointFormat": "<span style=\"color:{point.color}\">●</span>{series.name}: <b>{point.y}°</b><br/>"
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14,
                "xAxisUnit": null
            }
        },
        "abands": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 20,
                "factor": 0.001
            },
            "topLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "bottomLine": {
                "styles": {
                    "lineWidth": 1
                }
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "trendline": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                }
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3
            }
        },
        "disparityindex": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "tooltip": {
                "valueDecimals": 4
            },
            "compareToMain": false,
            "params": {
                "index": 3,
                "period": 14,
                "average": "sma"
            },
            "dataGrouping": {
                "approximation": "averages"
            }
        },
        "heikinashi": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1,
                    "lineWidth": 2
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
            },
            "threshold": null,
            "borderColor": "#ffffff",
            "line": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x"
            },
            "area": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "threshold": 0
            },
            "spline": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x"
            },
            "areaspline": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "threshold": 0
            },
            "column": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6
                },
                "threshold": 0,
                "borderColor": "#ffffff"
            },
            "bar": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6
                },
                "threshold": 0,
                "borderColor": "#ffffff"
            },
            "scatter": {
                "lineWidth": 0,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    },
                    "enabled": true
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "xy",
                "jitter": {
                    "x": 0,
                    "y": 0
                },
                "tooltip": {
                    "headerFormat": "<span style=\"color:{point.color}\">●</span> <span style=\"font-size: 10px\"> {series.name}</span><br/>",
                    "pointFormat": "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
                }
            },
            "pie": {
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0,
                    "allowOverlap": true,
                    "connectorPadding": 5,
                    "connectorShape": "fixedOffset",
                    "crookDistance": "70%",
                    "distance": 30,
                    "enabled": true,
                    "softConnector": true
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        },
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "center": [
                    null,
                    null
                ],
                "clip": false,
                "colorByPoint": true,
                "ignoreHiddenPoint": true,
                "inactiveOtherPoints": true,
                "legendType": "point",
                "size": null,
                "showInLegend": false,
                "slicedOffset": 10,
                "tooltip": {
                    "followPointer": true
                },
                "borderColor": "#ffffff",
                "borderWidth": 1
            },
            "hlc": {
                "lineWidth": 1,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1,
                        "lineWidth": 3
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6,
                    "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
                },
                "threshold": null,
                "borderColor": "#ffffff"
            },
            "ohlc": {
                "lineWidth": 1,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1,
                        "lineWidth": 3
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6,
                    "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
                },
                "threshold": null,
                "borderColor": "#ffffff"
            },
            "lineColor": "#000000",
            "upColor": "#ffffff",
            "dataGrouping": {
                "groupAll": true
            }
        },
        "hollowcandlestick": {
            "lineWidth": 1,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1,
                    "lineWidth": 2
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
            },
            "threshold": null,
            "borderColor": "#ffffff",
            "line": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x"
            },
            "area": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "threshold": 0
            },
            "spline": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x"
            },
            "areaspline": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    }
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "threshold": 0
            },
            "column": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6
                },
                "threshold": 0,
                "borderColor": "#ffffff"
            },
            "bar": {
                "lineWidth": 2,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6
                },
                "threshold": 0,
                "borderColor": "#ffffff"
            },
            "scatter": {
                "lineWidth": 0,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": {
                    "enabledThreshold": 2,
                    "lineColor": "#ffffff",
                    "lineWidth": 0,
                    "radius": 4,
                    "states": {
                        "normal": {
                            "animation": true
                        },
                        "hover": {
                            "animation": {
                                "duration": 50
                            },
                            "enabled": true,
                            "radiusPlus": 2,
                            "lineWidthPlus": 1
                        },
                        "select": {
                            "fillColor": "#cccccc",
                            "lineColor": "#000000",
                            "lineWidth": 2
                        }
                    },
                    "enabled": true
                },
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        }
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "xy",
                "jitter": {
                    "x": 0,
                    "y": 0
                },
                "tooltip": {
                    "headerFormat": "<span style=\"color:{point.color}\">●</span> <span style=\"font-size: 10px\"> {series.name}</span><br/>",
                    "pointFormat": "x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>"
                }
            },
            "pie": {
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "align": "center",
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "verticalAlign": "bottom",
                    "x": 0,
                    "y": 0,
                    "allowOverlap": true,
                    "connectorPadding": 5,
                    "connectorShape": "fixedOffset",
                    "crookDistance": "70%",
                    "distance": 30,
                    "enabled": true,
                    "softConnector": true
                },
                "cropThreshold": 300,
                "opacity": 1,
                "pointRange": 0,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": {
                            "size": 10,
                            "opacity": 0.25
                        },
                        "brightness": 0.1
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        }
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": false,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "center": [
                    null,
                    null
                ],
                "clip": false,
                "colorByPoint": true,
                "ignoreHiddenPoint": true,
                "inactiveOtherPoints": true,
                "legendType": "point",
                "size": null,
                "showInLegend": false,
                "slicedOffset": 10,
                "tooltip": {
                    "followPointer": true
                },
                "borderColor": "#ffffff",
                "borderWidth": 1
            },
            "hlc": {
                "lineWidth": 1,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1,
                        "lineWidth": 3
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6,
                    "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
                },
                "threshold": null,
                "borderColor": "#ffffff"
            },
            "ohlc": {
                "lineWidth": 1,
                "allowPointSelect": false,
                "crisp": true,
                "showCheckbox": false,
                "animation": {
                    "duration": 1000
                },
                "events": {},
                "marker": null,
                "point": {
                    "events": {}
                },
                "dataLabels": {
                    "animation": {},
                    "defer": true,
                    "padding": 5,
                    "style": {
                        "fontSize": "11px",
                        "fontWeight": "bold",
                        "color": "contrast",
                        "textOutline": "1px contrast"
                    },
                    "x": 0
                },
                "cropThreshold": 50,
                "opacity": 1,
                "pointRange": null,
                "softThreshold": true,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "lineWidthPlus": 1,
                        "marker": {},
                        "halo": false,
                        "brightness": 0.1,
                        "lineWidth": 3
                    },
                    "select": {
                        "animation": {
                            "duration": 0
                        },
                        "color": "#cccccc",
                        "borderColor": "#000000"
                    },
                    "inactive": {
                        "animation": {
                            "duration": 50
                        },
                        "opacity": 0.2
                    }
                },
                "stickyTracking": true,
                "turboThreshold": 1000,
                "findNearestPointBy": "x",
                "borderRadius": 0,
                "centerInCategory": false,
                "groupPadding": 0.2,
                "pointPadding": 0.1,
                "minPointLength": 0,
                "startFromThreshold": true,
                "tooltip": {
                    "distance": 6,
                    "pointFormat": "<span style=\"color:{point.color}\">●</span> <b> {series.name}</b><br/>Open: {point.open}<br/>High: {point.high}<br/>Low: {point.low}<br/>Close: {point.close}<br/>"
                },
                "threshold": null,
                "borderColor": "#ffffff"
            },
            "lineColor": "#f21313",
            "upColor": "#06b535",
            "color": "#f21313",
            "dataGrouping": {
                "groupAll": true,
                "groupPixelWidth": 10
            },
            "upLineColor": "#06b535"
        },
        "xrange": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "middle",
                "x": 0,
                "inside": true
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 3,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "headerFormat": "<span style=\"font-size: 10px\">{point.x} - {point.x2}</span><br/>",
                "pointFormat": "<span style=\"color:{point.color}\">●</span> {series.name}: <b>{point.yCategory}</b><br/>"
            },
            "threshold": 0,
            "borderColor": "#ffffff",
            "colorByPoint": true
        },
        "gantt": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "middle",
                "x": 0,
                "inside": true,
                "enabled": true
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 3,
            "centerInCategory": false,
            "groupPadding": 0.2,
            "pointPadding": 0.1,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "headerFormat": "<span style=\"font-size: 10px\">{series.name}</span><br/>",
                "pointFormat": null
            },
            "threshold": 0,
            "borderColor": "#ffffff",
            "colorByPoint": true,
            "grouping": false,
            "connectors": {
                "type": "simpleConnect",
                "animation": {
                    "reversed": true
                },
                "startMarker": {
                    "enabled": true,
                    "symbol": "arrow-filled",
                    "radius": 4,
                    "fill": "#fa0",
                    "align": "left"
                },
                "endMarker": {
                    "enabled": false,
                    "align": "right"
                }
            }
        },
        "histogram": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": null,
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "x": 0
            },
            "cropThreshold": 50,
            "opacity": 1,
            "pointRange": null,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": false,
                    "brightness": 0.1
                },
                "select": {
                    "animation": {
                        "duration": 0
                    },
                    "color": "#cccccc",
                    "borderColor": "#000000"
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": false,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "borderRadius": 0,
            "centerInCategory": false,
            "groupPadding": 0,
            "pointPadding": 0,
            "minPointLength": 0,
            "startFromThreshold": true,
            "tooltip": {
                "distance": 6,
                "headerFormat": "",
                "pointFormat": "<span style=\"font-size: 10px\">{point.x} - {point.x2}</span><br/><span style=\"color:{point.color}\">●</span> {series.name} <b>{point.y}</b><br/>"
            },
            "threshold": 0,
            "borderColor": "#ffffff",
            "binsNumber": "square-root",
            "grouping": false,
            "pointPlacement": "between"
        },
        "bellcurve": {
            "lineWidth": 2,
            "allowPointSelect": false,
            "crisp": true,
            "showCheckbox": false,
            "animation": {
                "duration": 1000
            },
            "events": {},
            "marker": {
                "enabledThreshold": 2,
                "lineColor": "#ffffff",
                "lineWidth": 0,
                "radius": 4,
                "states": {
                    "normal": {
                        "animation": true
                    },
                    "hover": {
                        "animation": {
                            "duration": 50
                        },
                        "enabled": true,
                        "radiusPlus": 2,
                        "lineWidthPlus": 1
                    },
                    "select": {
                        "fillColor": "#cccccc",
                        "lineColor": "#000000",
                        "lineWidth": 2
                    }
                },
                "enabled": false
            },
            "point": {
                "events": {}
            },
            "dataLabels": {
                "animation": {},
                "align": "center",
                "defer": true,
                "padding": 5,
                "style": {
                    "fontSize": "11px",
                    "fontWeight": "bold",
                    "color": "contrast",
                    "textOutline": "1px contrast"
                },
                "verticalAlign": "bottom",
                "x": 0,
                "y": 0
            },
            "cropThreshold": 300,
            "opacity": 1,
            "pointRange": 0,
            "softThreshold": true,
            "states": {
                "normal": {
                    "animation": true
                },
                "hover": {
                    "animation": {
                        "duration": 50
                    },
                    "lineWidthPlus": 1,
                    "marker": {},
                    "halo": {
                        "size": 10,
                        "opacity": 0.25
                    }
                },
                "select": {
                    "animation": {
                        "duration": 0
                    }
                },
                "inactive": {
                    "animation": {
                        "duration": 50
                    },
                    "opacity": 0.2
                }
            },
            "stickyTracking": true,
            "turboThreshold": 1000,
            "findNearestPointBy": "x",
            "threshold": 0,
            "intervals": 3,
            "pointsInInterval": 3
        }
    },
    "labels": {
        "style": {
            "position": "absolute",
            "color": "#333333"
        }
    },
    "legend": {
        "enabled": true,
        "align": "center",
        "alignColumns": true,
        "className": "highcharts-no-tooltip",
        "layout": "horizontal",
        "borderColor": "#999999",
        "borderRadius": 0,
        "navigation": {
            "activeColor": "#003399",
            "inactiveColor": "#cccccc"
        },
        "itemStyle": {
            "color": "#333333",
            "cursor": "pointer",
            "fontSize": "12px",
            "fontWeight": "bold",
            "textOverflow": "ellipsis"
        },
        "itemHoverStyle": {
            "color": "#000000"
        },
        "itemHiddenStyle": {
            "color": "#cccccc"
        },
        "shadow": false,
        "itemCheckboxStyle": {
            "position": "absolute",
            "width": "13px",
            "height": "13px"
        },
        "squareSymbol": true,
        "symbolPadding": 5,
        "verticalAlign": "bottom",
        "x": 0,
        "y": 0,
        "title": {
            "style": {
                "fontWeight": "bold"
            }
        },
        "bubbleLegend": {
            "borderWidth": 2,
            "connectorDistance": 60,
            "connectorWidth": 1,
            "enabled": false,
            "labels": {
                "allowOverlap": false,
                "format": "",
                "align": "right",
                "style": {
                    "fontSize": "10px",
                    "color": "#000000"
                },
                "x": 0,
                "y": 0
            },
            "maxSize": 60,
            "minSize": 10,
            "legendIndex": 0,
            "ranges": {},
            "sizeBy": "area",
            "sizeByAbsoluteValue": false,
            "zIndex": 1,
            "zThreshold": 0
        },
        "accessibility": {
            "enabled": true,
            "keyboardNavigation": {
                "enabled": true
            }
        }
    },
    "loading": {
        "labelStyle": {
            "fontWeight": "bold",
            "position": "relative",
            "top": "45%"
        },
        "style": {
            "position": "absolute",
            "backgroundColor": "#ffffff",
            "opacity": 0.5,
            "textAlign": "center"
        }
    },
    "tooltip": {
        "enabled": true,
        "animation": true,
        "borderRadius": 3,
        "dateTimeLabelFormats": {
            "millisecond": "%A, %b %e, %H:%M:%S.%L",
            "second": "%A, %b %e, %H:%M:%S",
            "minute": "%A, %b %e, %H:%M",
            "hour": "%A, %b %e, %H:%M",
            "day": "%A, %b %e, %Y",
            "week": "Week from %A, %b %e, %Y",
            "month": "%B %Y",
            "year": "%Y"
        },
        "footerFormat": "",
        "headerShape": "callout",
        "hideDelay": 500,
        "padding": 8,
        "shape": "callout",
        "shared": false,
        "snap": 10,
        "headerFormat": "<span style=\"font-size: 10px\">{point.key}</span><br/>",
        "pointFormat": "<span style=\"color:{point.color}\">●</span> {series.name}: <b>{point.y}</b><br/>",
        "backgroundColor": "rgba(247,247,247,0.85)",
        "borderWidth": 1,
        "shadow": true,
        "stickOnContact": false,
        "style": {
            "color": "#333333",
            "cursor": "default",
            "fontSize": "12px",
            "whiteSpace": "nowrap"
        },
        "useHTML": false
    },
    "credits": {
        "enabled": true,
        "href": "https://www.highcharts.com?credits",
        "position": {
            "align": "right",
            "x": -10,
            "verticalAlign": "bottom",
            "y": -5
        },
        "style": {
            "cursor": "pointer",
            "color": "#999999",
            "fontSize": "9px"
        },
        "text": "Highcharts.com"
    },
    "scrollbar": {
        "height": 14,
        "barBorderRadius": 0,
        "buttonBorderRadius": 0,
        "margin": 10,
        "minWidth": 6,
        "opposite": true,
        "step": 0.2,
        "zIndex": 3,
        "barBackgroundColor": "#cccccc",
        "barBorderWidth": 1,
        "barBorderColor": "#cccccc",
        "buttonArrowColor": "#333333",
        "buttonBackgroundColor": "#e6e6e6",
        "buttonBorderColor": "#cccccc",
        "buttonBorderWidth": 1,
        "rifleColor": "#333333",
        "trackBackgroundColor": "#f2f2f2",
        "trackBorderColor": "#f2f2f2",
        "trackBorderWidth": 1
    },
    "navigator": {
        "height": 40,
        "margin": 25,
        "maskInside": true,
        "handles": {
            "width": 7,
            "height": 15,
            "symbols": [
                "navigator-handle",
                "navigator-handle"
            ],
            "enabled": true,
            "lineWidth": 1,
            "backgroundColor": "#f2f2f2",
            "borderColor": "#999999"
        },
        "maskFill": "rgba(102,133,194,0.3)",
        "outlineColor": "#cccccc",
        "outlineWidth": 1,
        "series": {
            "type": "areaspline",
            "fillOpacity": 0.05,
            "lineWidth": 1,
            "compare": null,
            "dataGrouping": {
                "approximation": "average",
                "enabled": true,
                "groupPixelWidth": 2,
                "firstAnchor": "firstPoint",
                "anchor": "middle",
                "lastAnchor": "lastPoint",
                "units": [
                    [
                        "millisecond",
                        [
                            1,
                            2,
                            5,
                            10,
                            20,
                            25,
                            50,
                            100,
                            200,
                            500
                        ]
                    ],
                    [
                        "second",
                        [
                            1,
                            2,
                            5,
                            10,
                            15,
                            30
                        ]
                    ],
                    [
                        "minute",
                        [
                            1,
                            2,
                            5,
                            10,
                            15,
                            30
                        ]
                    ],
                    [
                        "hour",
                        [
                            1,
                            2,
                            3,
                            4,
                            6,
                            8,
                            12
                        ]
                    ],
                    [
                        "day",
                        [
                            1,
                            2,
                            3,
                            4
                        ]
                    ],
                    [
                        "week",
                        [
                            1,
                            2,
                            3
                        ]
                    ],
                    [
                        "month",
                        [
                            1,
                            3,
                            6
                        ]
                    ],
                    [
                        "year",
                        null
                    ]
                ]
            },
            "dataLabels": {
                "enabled": false,
                "zIndex": 2
            },
            "id": "highcharts-navigator-series",
            "className": "highcharts-navigator-series",
            "lineColor": null,
            "marker": {
                "enabled": false
            },
            "threshold": null
        },
        "xAxis": {
            "overscroll": 0,
            "className": "highcharts-navigator-xaxis",
            "tickLength": 0,
            "lineWidth": 0,
            "gridLineColor": "#e6e6e6",
            "gridLineWidth": 1,
            "tickPixelInterval": 200,
            "labels": {
                "align": "left",
                "style": {
                    "color": "#999999"
                },
                "x": 3,
                "y": -4
            },
            "crosshair": false
        },
        "yAxis": {
            "className": "highcharts-navigator-yaxis",
            "gridLineWidth": 0,
            "startOnTick": false,
            "endOnTick": false,
            "minPadding": 0.1,
            "maxPadding": 0.1,
            "labels": {
                "enabled": false
            },
            "crosshair": false,
            "title": {
                "text": null
            },
            "tickLength": 0,
            "tickWidth": 0
        }
    },
    "rangeSelector": {
        "allButtonsEnabled": false,
        "buttonSpacing": 5,
        "dropdown": "responsive",
        "verticalAlign": "top",
        "buttonTheme": {
            "width": 28,
            "height": 18,
            "padding": 2,
            "zIndex": 7
        },
        "floating": false,
        "x": 0,
        "y": 0,
        "inputBoxBorderColor": "none",
        "inputBoxHeight": 17,
        "inputDateFormat": "%b %e, %Y",
        "inputEditDateFormat": "%Y-%m-%d",
        "inputEnabled": true,
        "inputPosition": {
            "align": "right",
            "x": 0,
            "y": 0
        },
        "inputSpacing": 5,
        "buttonPosition": {
            "align": "left",
            "x": 0,
            "y": 0
        },
        "inputStyle": {
            "color": "#335cad",
            "cursor": "pointer"
        },
        "labelStyle": {
            "color": "#666666"
        }
    },
    "navigation": {
        "buttonOptions": {
            "symbolSize": 14,
            "symbolX": 12.5,
            "symbolY": 10.5,
            "align": "right",
            "buttonSpacing": 3,
            "height": 22,
            "verticalAlign": "top",
            "width": 24,
            "symbolFill": "#666666",
            "symbolStroke": "#666666",
            "symbolStrokeWidth": 3,
            "theme": {
                "padding": 5
            }
        },
        "menuStyle": {
            "border": "1px solid #999999",
            "background": "#ffffff",
            "padding": "5px 0"
        },
        "menuItemStyle": {
            "padding": "0.5em 1em",
            "color": "#333333",
            "background": "none",
            "fontSize": "11px",
            "transition": "background 250ms, color 250ms"
        },
        "menuItemHoverStyle": {
            "background": "#335cad",
            "color": "#ffffff"
        },
        "bindingsClassName": "highcharts-bindings-container",
        "bindings": {
            "circleAnnotation": {
                "className": "highcharts-circle-annotation",
                "steps": [
                    null
                ]
            },
            "ellipseAnnotation": {
                "className": "highcharts-ellipse-annotation",
                "steps": [
                    null,
                    null
                ]
            },
            "rectangleAnnotation": {
                "className": "highcharts-rectangle-annotation",
                "steps": [
                    null
                ]
            },
            "labelAnnotation": {
                "className": "highcharts-label-annotation"
            },
            "segment": {
                "className": "highcharts-segment",
                "steps": [
                    null
                ]
            },
            "arrowSegment": {
                "className": "highcharts-arrow-segment",
                "steps": [
                    null
                ]
            },
            "ray": {
                "className": "highcharts-ray",
                "steps": [
                    null
                ]
            },
            "arrowRay": {
                "className": "highcharts-arrow-ray",
                "steps": [
                    null
                ]
            },
            "infinityLine": {
                "className": "highcharts-infinity-line",
                "steps": [
                    null
                ]
            },
            "arrowInfinityLine": {
                "className": "highcharts-arrow-infinity-line",
                "steps": [
                    null
                ]
            },
            "horizontalLine": {
                "className": "highcharts-horizontal-line"
            },
            "verticalLine": {
                "className": "highcharts-vertical-line"
            },
            "crooked3": {
                "className": "highcharts-crooked3",
                "steps": [
                    null,
                    null
                ]
            },
            "crooked5": {
                "className": "highcharts-crooked5",
                "steps": [
                    null,
                    null,
                    null,
                    null
                ]
            },
            "elliott3": {
                "className": "highcharts-elliott3",
                "steps": [
                    null,
                    null,
                    null
                ]
            },
            "elliott5": {
                "className": "highcharts-elliott5",
                "steps": [
                    null,
                    null,
                    null,
                    null,
                    null
                ]
            },
            "measureX": {
                "className": "highcharts-measure-x",
                "steps": [
                    null
                ]
            },
            "measureY": {
                "className": "highcharts-measure-y",
                "steps": [
                    null
                ]
            },
            "measureXY": {
                "className": "highcharts-measure-xy",
                "steps": [
                    null
                ]
            },
            "fibonacci": {
                "className": "highcharts-fibonacci",
                "steps": [
                    null,
                    null
                ]
            },
            "parallelChannel": {
                "className": "highcharts-parallel-channel",
                "steps": [
                    null,
                    null
                ]
            },
            "pitchfork": {
                "className": "highcharts-pitchfork",
                "steps": [
                    null,
                    null
                ]
            },
            "verticalCounter": {
                "className": "highcharts-vertical-counter"
            },
            "timeCycles": {
                "className": "highcharts-time-cycles",
                "steps": [
                    null
                ]
            },
            "verticalLabel": {
                "className": "highcharts-vertical-label"
            },
            "verticalArrow": {
                "className": "highcharts-vertical-arrow"
            },
            "fibonacciTimeZones": {
                "className": "highcharts-fibonacci-time-zones",
                "steps": [
                    null
                ]
            },
            "flagCirclepin": {
                "className": "highcharts-flag-circlepin"
            },
            "flagDiamondpin": {
                "className": "highcharts-flag-diamondpin"
            },
            "flagSquarepin": {
                "className": "highcharts-flag-squarepin"
            },
            "flagSimplepin": {
                "className": "highcharts-flag-simplepin"
            },
            "zoomX": {
                "className": "highcharts-zoom-x"
            },
            "zoomY": {
                "className": "highcharts-zoom-y"
            },
            "zoomXY": {
                "className": "highcharts-zoom-xy"
            },
            "seriesTypeLine": {
                "className": "highcharts-series-type-line"
            },
            "seriesTypeOhlc": {
                "className": "highcharts-series-type-ohlc"
            },
            "seriesTypeCandlestick": {
                "className": "highcharts-series-type-candlestick"
            },
            "seriesTypeHeikinAshi": {
                "className": "highcharts-series-type-heikinashi"
            },
            "seriesTypeHLC": {
                "className": "highcharts-series-type-hlc"
            },
            "seriesTypeHollowCandlestick": {
                "className": "highcharts-series-type-hollowcandlestick"
            },
            "fullScreen": {
                "className": "highcharts-full-screen",
                "noDataState": "normal"
            },
            "currentPriceIndicator": {
                "className": "highcharts-current-price-indicator"
            },
            "indicators": {
                "className": "highcharts-indicators"
            },
            "toggleAnnotations": {
                "className": "highcharts-toggle-annotations"
            },
            "saveChart": {
                "className": "highcharts-save-chart",
                "noDataState": "normal"
            }
        },
        "events": {},
        "annotationsOptions": {
            "animation": {
                "defer": 0
            }
        }
    },
    "stockTools": {
        "gui": {
            "enabled": true,
            "className": "highcharts-bindings-wrapper",
            "toolbarClassName": "stocktools-toolbar",
            "buttons": [
                "indicators",
                "separator",
                "simpleShapes",
                "lines",
                "crookedLines",
                "measure",
                "advanced",
                "toggleAnnotations",
                "separator",
                "verticalLabels",
                "flags",
                "separator",
                "zoomChange",
                "fullScreen",
                "typeChange",
                "separator",
                "currentPriceIndicator",
                "saveChart"
            ],
            "definitions": {
                "separator": {
                    "symbol": "separator.svg"
                },
                "simpleShapes": {
                    "items": [
                        "label",
                        "circle",
                        "ellipse",
                        "rectangle"
                    ],
                    "circle": {
                        "symbol": "circle.svg"
                    },
                    "ellipse": {
                        "symbol": "ellipse.svg"
                    },
                    "rectangle": {
                        "symbol": "rectangle.svg"
                    },
                    "label": {
                        "symbol": "label.svg"
                    }
                },
                "flags": {
                    "items": [
                        "flagCirclepin",
                        "flagDiamondpin",
                        "flagSquarepin",
                        "flagSimplepin"
                    ],
                    "flagSimplepin": {
                        "symbol": "flag-basic.svg"
                    },
                    "flagDiamondpin": {
                        "symbol": "flag-diamond.svg"
                    },
                    "flagSquarepin": {
                        "symbol": "flag-trapeze.svg"
                    },
                    "flagCirclepin": {
                        "symbol": "flag-elipse.svg"
                    }
                },
                "lines": {
                    "items": [
                        "segment",
                        "arrowSegment",
                        "ray",
                        "arrowRay",
                        "line",
                        "arrowInfinityLine",
                        "horizontalLine",
                        "verticalLine"
                    ],
                    "segment": {
                        "symbol": "segment.svg"
                    },
                    "arrowSegment": {
                        "symbol": "arrow-segment.svg"
                    },
                    "ray": {
                        "symbol": "ray.svg"
                    },
                    "arrowRay": {
                        "symbol": "arrow-ray.svg"
                    },
                    "line": {
                        "symbol": "line.svg"
                    },
                    "arrowInfinityLine": {
                        "symbol": "arrow-line.svg"
                    },
                    "verticalLine": {
                        "symbol": "vertical-line.svg"
                    },
                    "horizontalLine": {
                        "symbol": "horizontal-line.svg"
                    }
                },
                "crookedLines": {
                    "items": [
                        "elliott3",
                        "elliott5",
                        "crooked3",
                        "crooked5"
                    ],
                    "crooked3": {
                        "symbol": "crooked-3.svg"
                    },
                    "crooked5": {
                        "symbol": "crooked-5.svg"
                    },
                    "elliott3": {
                        "symbol": "elliott-3.svg"
                    },
                    "elliott5": {
                        "symbol": "elliott-5.svg"
                    }
                },
                "verticalLabels": {
                    "items": [
                        "verticalCounter",
                        "verticalLabel",
                        "verticalArrow"
                    ],
                    "verticalCounter": {
                        "symbol": "vertical-counter.svg"
                    },
                    "verticalLabel": {
                        "symbol": "vertical-label.svg"
                    },
                    "verticalArrow": {
                        "symbol": "vertical-arrow.svg"
                    }
                },
                "advanced": {
                    "items": [
                        "fibonacci",
                        "fibonacciTimeZones",
                        "pitchfork",
                        "parallelChannel",
                        "timeCycles"
                    ],
                    "pitchfork": {
                        "symbol": "pitchfork.svg"
                    },
                    "fibonacci": {
                        "symbol": "fibonacci.svg"
                    },
                    "fibonacciTimeZones": {
                        "symbol": "fibonacci-timezone.svg"
                    },
                    "parallelChannel": {
                        "symbol": "parallel-channel.svg"
                    },
                    "timeCycles": {
                        "symbol": "time-cycles.svg"
                    }
                },
                "measure": {
                    "items": [
                        "measureXY",
                        "measureX",
                        "measureY"
                    ],
                    "measureX": {
                        "symbol": "measure-x.svg"
                    },
                    "measureY": {
                        "symbol": "measure-y.svg"
                    },
                    "measureXY": {
                        "symbol": "measure-xy.svg"
                    }
                },
                "toggleAnnotations": {
                    "symbol": "annotations-visible.svg"
                },
                "currentPriceIndicator": {
                    "symbol": "current-price-show.svg"
                },
                "indicators": {
                    "symbol": "indicators.svg"
                },
                "zoomChange": {
                    "items": [
                        "zoomX",
                        "zoomY",
                        "zoomXY"
                    ],
                    "zoomX": {
                        "symbol": "zoom-x.svg"
                    },
                    "zoomY": {
                        "symbol": "zoom-y.svg"
                    },
                    "zoomXY": {
                        "symbol": "zoom-xy.svg"
                    }
                },
                "typeChange": {
                    "items": [
                        "typeOHLC",
                        "typeLine",
                        "typeCandlestick",
                        "typeHollowCandlestick",
                        "typeHLC",
                        "typeHeikinAshi"
                    ],
                    "typeOHLC": {
                        "symbol": "series-ohlc.svg"
                    },
                    "typeLine": {
                        "symbol": "series-line.svg"
                    },
                    "typeCandlestick": {
                        "symbol": "series-candlestick.svg"
                    },
                    "typeHLC": {
                        "symbol": "series-hlc.svg"
                    },
                    "typeHeikinAshi": {
                        "symbol": "series-heikin-ashi.svg"
                    },
                    "typeHollowCandlestick": {
                        "symbol": "series-hollow-candlestick.svg"
                    }
                },
                "fullScreen": {
                    "symbol": "fullscreen.svg"
                },
                "saveChart": {
                    "symbol": "save-chart.svg"
                }
            }
        }
    },
    "accessibility": {
        "enabled": true,
        "screenReaderSection": {
            "beforeChartFormat": "<{headingTagName}>{chartTitle}</{headingTagName}><div>{typeDescription}</div><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{playAsSoundButton}</div><div>{viewTableButton}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div><div>{annotationsTitle}{annotationsList}</div>",
            "afterChartFormat": "{endOfChartMarker}",
            "axisRangeDateFormat": "%Y-%m-%d %H:%M:%S"
        },
        "series": {
            "describeSingleSeries": false,
            "pointDescriptionEnabledThreshold": 200
        },
        "point": {
            "valueDescriptionFormat": "{index}. {xDescription}{separator}{value}."
        },
        "landmarkVerbosity": "all",
        "linkedDescription": "*[data-highcharts-chart=\"{index}\"] + .highcharts-description",
        "keyboardNavigation": {
            "enabled": true,
            "focusBorder": {
                "enabled": true,
                "hideBrowserFocusOutline": true,
                "style": {
                    "color": "#335cad",
                    "lineWidth": 2,
                    "borderRadius": 3
                },
                "margin": 2
            },
            "order": [
                "series",
                "zoom",
                "rangeSelector",
                "legend",
                "chartMenu"
            ],
            "wrapAround": true,
            "seriesNavigation": {
                "skipNullPoints": true,
                "pointNavigationEnabledThreshold": false
            }
        },
        "announceNewData": {
            "enabled": false,
            "minAnnounceInterval": 5000,
            "interruptUser": false
        },
        "highContrastTheme": {
            "chart": {
                "backgroundColor": "window"
            },
            "title": {
                "style": {
                    "color": "windowText"
                }
            },
            "subtitle": {
                "style": {
                    "color": "windowText"
                }
            },
            "colorAxis": {
                "minColor": "windowText",
                "maxColor": "windowText",
                "stops": []
            },
            "colors": [
                "windowText"
            ],
            "xAxis": {
                "gridLineColor": "windowText",
                "labels": {
                    "style": {
                        "color": "windowText"
                    }
                },
                "lineColor": "windowText",
                "minorGridLineColor": "windowText",
                "tickColor": "windowText",
                "title": {
                    "style": {
                        "color": "windowText"
                    }
                }
            },
            "yAxis": {
                "gridLineColor": "windowText",
                "labels": {
                    "style": {
                        "color": "windowText"
                    }
                },
                "lineColor": "windowText",
                "minorGridLineColor": "windowText",
                "tickColor": "windowText",
                "title": {
                    "style": {
                        "color": "windowText"
                    }
                }
            },
            "tooltip": {
                "backgroundColor": "window",
                "borderColor": "windowText",
                "style": {
                    "color": "windowText"
                }
            },
            "plotOptions": {
                "series": {
                    "lineColor": "windowText",
                    "fillColor": "window",
                    "borderColor": "windowText",
                    "edgeColor": "windowText",
                    "borderWidth": 1,
                    "dataLabels": {
                        "connectorColor": "windowText",
                        "color": "windowText",
                        "style": {
                            "color": "windowText",
                            "textOutline": "none"
                        }
                    },
                    "marker": {
                        "lineColor": "windowText",
                        "fillColor": "windowText"
                    }
                },
                "pie": {
                    "color": "window",
                    "colors": [
                        "window"
                    ],
                    "borderColor": "windowText",
                    "borderWidth": 1
                },
                "boxplot": {
                    "fillColor": "window"
                },
                "candlestick": {
                    "lineColor": "windowText",
                    "fillColor": "window"
                },
                "errorbar": {
                    "fillColor": "window"
                }
            },
            "legend": {
                "backgroundColor": "window",
                "itemStyle": {
                    "color": "windowText"
                },
                "itemHoverStyle": {
                    "color": "windowText"
                },
                "itemHiddenStyle": {
                    "color": "#555"
                },
                "title": {
                    "style": {
                        "color": "windowText"
                    }
                }
            },
            "credits": {
                "style": {
                    "color": "windowText"
                }
            },
            "labels": {
                "style": {
                    "color": "windowText"
                }
            },
            "drilldown": {
                "activeAxisLabelStyle": {
                    "color": "windowText"
                },
                "activeDataLabelStyle": {
                    "color": "windowText"
                }
            },
            "navigation": {
                "buttonOptions": {
                    "symbolStroke": "windowText",
                    "theme": {
                        "fill": "window"
                    }
                }
            },
            "rangeSelector": {
                "buttonTheme": {
                    "fill": "window",
                    "stroke": "windowText",
                    "style": {
                        "color": "windowText"
                    },
                    "states": {
                        "hover": {
                            "fill": "window",
                            "stroke": "windowText",
                            "style": {
                                "color": "windowText"
                            }
                        },
                        "select": {
                            "fill": "#444",
                            "stroke": "windowText",
                            "style": {
                                "color": "windowText"
                            }
                        }
                    }
                },
                "inputBoxBorderColor": "windowText",
                "inputStyle": {
                    "backgroundColor": "window",
                    "color": "windowText"
                },
                "labelStyle": {
                    "color": "windowText"
                }
            },
            "navigator": {
                "handles": {
                    "backgroundColor": "window",
                    "borderColor": "windowText"
                },
                "outlineColor": "windowText",
                "maskFill": "transparent",
                "series": {
                    "color": "windowText",
                    "lineColor": "windowText"
                },
                "xAxis": {
                    "gridLineColor": "windowText"
                }
            },
            "scrollbar": {
                "barBackgroundColor": "#444",
                "barBorderColor": "windowText",
                "buttonArrowColor": "windowText",
                "buttonBackgroundColor": "window",
                "buttonBorderColor": "windowText",
                "rifleColor": "windowText",
                "trackBackgroundColor": "window",
                "trackBorderColor": "windowText"
            }
        }
    },
    "exporting": {
        "type": "image/png",
        "url": "https://export.highcharts.com/",
        "printMaxWidth": 780,
        "scale": 2,
        "buttons": {
            "contextButton": {
                "className": "highcharts-contextbutton",
                "menuClassName": "highcharts-contextmenu",
                "symbol": "menu",
                "titleKey": "contextButtonTitle",
                "menuItems": [
                    "viewFullscreen",
                    "printChart",
                    "separator",
                    "downloadPNG",
                    "downloadJPEG",
                    "downloadPDF",
                    "downloadSVG",
                    "separator",
                    "downloadCSV",
                    "downloadXLS",
                    "viewData"
                ]
            }
        },
        "menuItemDefinitions": {
            "viewFullscreen": {
                "textKey": "viewFullscreen"
            },
            "printChart": {
                "textKey": "printChart"
            },
            "separator": {
                "separator": true
            },
            "downloadPNG": {
                "textKey": "downloadPNG"
            },
            "downloadJPEG": {
                "textKey": "downloadJPEG"
            },
            "downloadPDF": {
                "textKey": "downloadPDF"
            },
            "downloadSVG": {
                "textKey": "downloadSVG"
            },
            "downloadCSV": {
                "textKey": "downloadCSV"
            },
            "downloadXLS": {
                "textKey": "downloadXLS"
            },
            "viewData": {
                "textKey": "viewData"
            }
        },
        "accessibility": {
            "enabled": true
        },
        "csv": {
            "annotations": {
                "itemDelimiter": "; ",
                "join": false
            },
            "columnHeaderFormatter": null,
            "dateFormat": "%Y-%m-%d %H:%M:%S",
            "decimalPoint": null,
            "itemDelimiter": null,
            "lineDelimiter": "\n"
        },
        "showTable": false,
        "useMultiLevelHeaders": true,
        "useRowspanHeaders": true
    },
    "connectors": {
        "type": "straight",
        "lineWidth": 1,
        "marker": {
            "enabled": false,
            "align": "center",
            "verticalAlign": "middle",
            "inside": false,
            "lineWidth": 1
        },
        "startMarker": {
            "symbol": "diamond"
        },
        "endMarker": {
            "symbol": "arrow-filled"
        }
    }
}
import React, { useEffect, useState, useMemo } from "react";
// import { useSelector } from 'react-redux'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from 'moment'
import _ from 'underscore'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Highcharts from 'highcharts/highstock'
import StockData from 'highcharts/modules/data'
import PatternFill from 'highcharts/modules/pattern-fill'
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import ReactHighcharts from 'highcharts-react-official'
import { Box } from "@mui/system";
import { Settings } from "@mui/icons-material";
import ChartSettingDialog from "../Components/ChartSettingDialog";
StockData(Highcharts)
HighchartsMore(Highcharts);
PatternFill(Highcharts)
PatternFill(Highcharts)
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

const useStyles = makeStyles(() => ({
  Select: {
    height: '400px'
  }
}))

function LineChart(props) {
  const classes = useStyles()
  const { rows: initialData, columns } = props
  const [xAxis, setXAxis] = useState(null)
  const [yAxis, setYAxis] = useState(null)
  const [openSettingDialog, setOpenSettingDialog] = useState(false)


  const series = useMemo(() => {
    if (!xAxis || !yAxis) return []
    const _initialSeries = []
    initialData.sort((a, b) => {
      if (a[xAxis] && b[xAxis]) {
        return new Date(a[xAxis]).getTime() - new Date(b[xAxis]).getTime()
      }
      return 1;
    }).map(item => {
      let _x = new Date(item[xAxis]).getTime()
      if (!isNaN(item[yAxis] * 1) && item[yAxis] !== null) {
        // _initialSeries.push({
        //   x: _x,
        //   y: isNaN(item[yAxis] * 1) ? null : item[yAxis] * 1,
        // })
        _initialSeries.push([_x, isNaN(item[yAxis] * 1) ? null : item[yAxis] * 1])
      }
    })
    return [{
      data: _initialSeries
    }]
  }, [
    initialData,
    yAxis,
    xAxis,
  ])
  const configStock = useMemo(() => {
    return {
      chart: {
        type: 'line',
        zoomType: 'x',
      },
      title: {
        text: ''
      },
      xAxis: {
        crosshair: true,
        type: "datetime",
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b/%e/%Y', this.value);
          }
        }
      },
      yAxis: {
        min: 0,
      },
      plotOptions: {
        line: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      stockTools: {
        enabled: false,
      },
      series: series
    }
  }, [series])

  return (
    <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
      <Box display="flex" sx={{ flexDirection: 'column', height: '100%' }}>
        <Grid container spacing={2}>
          <Grid item container lg={12} md={12} xs={12} spacing={2}>
            <Grid item lg={4} md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="x-axis-label">X-Axis</InputLabel>
                <Select
                  labelId="x-axis-label"
                  id="x-axis"
                  value={xAxis || ''}
                  label="X Axis"
                  onChange={(e) => {
                    setXAxis(e.target.value)
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    }
                  }}
                >
                  {
                    columns?.map(item => (
                      <MenuItem value={item.id} key={item.id}>
                        <Box>
                          {item?.label?.toLowerCase()}
                        </Box>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="y-axis-label">Y-Axis</InputLabel>
                <Select
                  labelId="y-axis-label"
                  id="y-axis"
                  value={yAxis || ''}
                  label="X Axis"
                  onChange={(e) => {
                    setYAxis(e.target.value)
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    }
                  }}
                >
                  {
                    columns?.map(item => (
                      <MenuItem value={item.id} key={item.id}>
                        <Box>
                          {item.label.toLowerCase()}
                        </Box>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Box display="flex" justifyContent="end">
                <Tooltip title="Chart Settings">
                  <IconButton onClick={() => {
                    setOpenSettingDialog(true)
                  }}>
                    <Settings />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Box flex={1}>
          <ReactHighcharts
            highcharts={Highcharts}
            constructorType={"chart"}
            options={configStock}
            containerProps={{ style: { height: "100%" } }}
          />
        </Box>
      </Box>
      {
        openSettingDialog && (
          <ChartSettingDialog
            open={openSettingDialog}
            handleClose={() => {
              setOpenSettingDialog(false)
            }}
          />
        )
      }
    </LocalizationProvider>
  )
}

export default LineChart
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import Chart from 'kaktana-react-lightweight-charts'
import { smaInc, emaInc, wmaInc } from '../../Utils/indicators'

const SeriesTypes = {
    line: 'lineSeries',
    area: 'areaSeries',
    'base-line': 'baseSeries',
    histogram: 'histogramSeries',
    bar: 'barSeries',
    candlestick: 'candlestickSeries'
}

const usePrevious = (value) => {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();
	// Store current value in ref
	useEffect(() => {
	  ref.current = value;
	}, [value]); // Only re-run if value changes
	return ref.current;
}

const MainSeries = (props) =>  {
    const {
        data = [],
        layoutOptions = {},
        chartType = 'line',
        ma = {}
    } = props

    const [options, setOptions] = useState({})
    const [mainSeries, setMainSeries] = useState({})
    const [volumeSeries, setVolumeSeries] = useState({})
    const [macdSeries, setMacdSeries] = useState([])
    const [seriesData, setSeriesData] = useState({})
    
    // const ref = useRef();
    // useEffect(() => {
    //     ref.current = seriesArr;
    //   }, [seriesArr]); // Only re-run if value changes

    const getSeriesItem = useCallback(({
        type, options = {}, data: initialData
    }) => {
        let _data = []
        let _seriesType = SeriesTypes[type]
        let _options = options
        if(Array.isArray(initialData)){
            _data = initialData
        }else{
            if(['line', 'area', 'base-line', 'histogram'].indexOf(type) > -1){
                _data = data.map(item => ({
                    time: item.time,
                    value: item.open
                }))
            }else{
                _data = [...data]
            }
        }
        const _seriesItem = {
            data: [..._data],
            options: _options,
            seriesType: _seriesType
        }
        return _seriesItem
    }, [data])

    
    useEffect(() => {
        setOptions({...layoutOptions})
    }, [layoutOptions])
    useEffect(() => {
        const _mainSeries = getSeriesItem({
            type: chartType,
        })
        setMainSeries({..._mainSeries})
    }, [chartType])
    useEffect(() => {
        const _data = data.map(item => ({
            time: item.time,
            value: item.close
        }))
        const _volumeSeries = getSeriesItem({
            type: 'histogram',
            data: _data,
            options: {
                color: '#dfd6d6',
                lineWidth: 2,
                priceFormat: {
                    type: 'volume',
                },
                overlay: true,
                scaleMargins: {
                    top: 0.8,
                    bottom: 0,
                },
            }
        })
        setVolumeSeries({..._volumeSeries})
    }, [data])
    useEffect(() => {
        const _macdSeries = []
        console.log(ma, 'ma')
        if(ma.visible){
            ma.edges.map((edge, idx) => {
                if(edge.edgeVal > 0){
                    const _series = {
                        type: 'line',
                    }
                    if(edge.edgeId === 'sma'){
                        let _edgeData = smaInc(data, edge.edgeVal)
                        _edgeData = _edgeData.map(item => ({
                            time: item.time,
                            value: item.sma
                        }))
                        _series.data = _edgeData
                        _series.options = {
                            title: 'SMA',
                            color: edge.lineColor
                        }
                    } else if(edge.edgeId === 'ema'){
                        let _edgeData = emaInc(data, edge.edgeVal)
                        _edgeData = _edgeData.map(item => ({
                            time: item.time,
                            value: item.ema
                        }))
                        _series.data = _edgeData
                        _series.options = {
                            title: 'EMA',
                            color: edge.lineColor
                        }
                    } else if(edge.edgeId === 'wma'){
                        let _edgeData = wmaInc(data, edge.edgeVal)
                        _edgeData = _edgeData.map(item => ({
                            time: item.time,
                            value: item.wma
                        }))
                        _series.data = _edgeData
                        _series.options = {
                            title: 'WMA',
                            color: edge.lineColor
                        }
                    }
                    const _seriesItem = getSeriesItem({..._series})
                    _macdSeries.push(_seriesItem)   
                }
            })
        }
        setMacdSeries([..._macdSeries])
    }, [data, ma])
    
    useEffect(() => {
        const _seriesData = {}
        const _seriesArr = macdSeries.concat([
            mainSeries, volumeSeries
        ])
        _seriesArr.map(s => {
            if(_seriesData[s.seriesType] === undefined){
                _seriesData[s.seriesType] = []
            }
            _seriesData[s.seriesType].push(s)
        })
        setSeriesData({..._seriesData})
    }, [mainSeries, volumeSeries, macdSeries])
  
    return (
        <Chart options={options} {...seriesData} autoWidth height={320} />
    )
}

export default MainSeries
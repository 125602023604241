import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit';

/* ======================================================================= */
// Main Slice
/* ======================================================================= */
const initialState = {
    snackbar: {
        show: false,
        message: "",
        severity: "info",
        autoHideDuration: 6000,
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
    },
    alertDialog: {
        show: false,
        title: "",
        message: "",
        buttonLabel: { left: "OK", right: "Close" },
        dialogAction: "",
        clickedButton: "",
        data: "",
    },
};

const messagesSystemSlice = createSlice({
    name: "alertMessages",
    initialState,
    reducers: {
        showSnackbarMessage(state, action) {
            const { message, severity, autoHideDuration, anchorOrigin } = action.payload;
            state.snackbar.message = message
            state.snackbar.severity = severity;
            state.snackbar.autoHideDuration = autoHideDuration ? autoHideDuration : initialState.snackbar.autoHideDuration;
            state.snackbar.anchorOrigin = anchorOrigin ? anchorOrigin : initialState.snackbar.anchorOrigin;
            state.snackbar.show = true;
        },
        hideSnackbarMessage(state) {
            state.snackbar = initialState.snackbar;
        },
        showAlertDialog(state, action) {
            const { title,message, buttonLabel, dialogAction, data } = action.payload;
            state.alertDialog.title = title ? title : initialState.alertDialog.title;
            state.alertDialog.message = message;

            state.alertDialog.buttonLabel = buttonLabel ? buttonLabel : initialState.alertDialog.buttonLabel;
            state.alertDialog.dialogAction = dialogAction ? dialogAction : initialState.alertDialog.dialogAction;
            state.alertDialog.data = data ? data : initialState.alertDialog.data;
            state.alertDialog.show = true;
        },
        hideAlertDialog(state, action) {
            state.alertDialog.show = false;
            const { clickedButton } = action.payload;
            state.alertDialog.clickedButton = clickedButton;
        },
        resetAlertDialog(state) {
            state.alertDialog = initialState.alertDialog;
        }
    },
    extraReducers: {
    }
});

export const { showSnackbarMessage, hideSnackbarMessage, showAlertDialog, hideAlertDialog, resetAlertDialog } = messagesSystemSlice.actions;

export default messagesSystemSlice.reducer;
import React, { useState } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogActions,
    DialogContent,
    lighten,
    Grid,
    Button,
    TextField,
} from "@mui/material";
import { ArrowDropDown } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    },
    Txt: {
        color: 'black'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    BorderedBox: {
        display: 'flex',
        flexFlow: 'column',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        minHeight: '100px',
        height: '100%',
        padding: '10px',
        maxHeight: '500px',
        overflow: 'auto',
        paddingBottom: '35px'
    },
    ListItemText: {
        fontSize: 14
    },
    ListItemButton: {
        padding: '5px 11px'
    },
    selectedLine: {
        background: '#dfdfdf'
    }
}));

const DialogComponent = (props) => {
    const {
        open,
        handleClose,
        onUpdate
    } = props
    const classes = useStyles();
    const [title, setTitle] = useState('')

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.DialogContent}
            maxWidth="md"
        >
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                        <TextField
                            variant="outlined"
                            value={title}
                            fullWidth
                            placeholder='New Quality Analytic'
                            onChange={(e) => {
                                setTitle(e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onUpdate(title)
                    handleClose()
                }} variant="contained">Save</Button>
                <Button onClick={handleClose} autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DialogComponent.propTypes = {
};

export default DialogComponent
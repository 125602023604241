import React from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from 'react-fusioncharts';
import moment from "moment";

ReactFC.fcRoot(FusionCharts, TimeSeries);
FusionCharts.options.license({
    key:
        "BzA11vmaG5E1E3A16A3A5A3C1A1B3D1E2gvxC3E5qyuiI-7B6B1mA-13tF5H2E4J2A4D4C3C6E2A2F4F1B1A7C8A3cE-11B-9F2A2H2vB2D3D2knyE1C3C11gapB4E4D4kH-7C-21D7E4F4C1H5G1G1I4B6C1E3pzzC1G4AB3zD-13mD1e1H3A33fb1qD8B1B4B1B1B1G1B17A1B3B1D6C4A2i== ",
    creditLabel: false
});

const jsonify = res => res.json();
const dataFetch = fetch(
    "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/step-line-data.json"
).then(jsonify);
const schemaFetch = fetch(
    "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/step-line-schema.json"
).then(jsonify);
const schema = [{
    "name": "Time",
    "type": "date",
    "format": "%d/%m/%y %-H:%M"
}, {
    "name": "Volume",
    "type": "number"
}];

class TimeSeriesStepLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.onFetchData = this.onFetchData.bind(this);
        this.state = {
            timeseriesDs: {
                type: "timeseries",
                renderAt: "container",
                width: "100%",
                height: "500",
                dataSource : {
                    styledefinition: {
                        areaplot: {
                            "stroke-opacity": 0.95,
                            "fill-opacity": 0.6,
                            stroke: "#f30e0e"
                        }
                    },
                    caption: {
                        text: "Daily Visitors Count of a Website"
                    }
                }
            }
        };
    }

    componentDidMount() {
        this.onFetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.plotType !== this.props.plotType){
            this.onFetchData();
        }
    }

    onFetchData() {
        /*const {stockValues} = this.props;
        let stockData = [];
        stockValues.map((item,index) => {
            let stock = [
                moment(item.reading_date).format('DD-MM-YY h:mm'),item.stock_val, index
            ];
            stockData.push(stock)
        });
        console.log(stockData,'stockdata');
        const fusionTable = new FusionCharts.DataStore().createDataTable(
            stockData,
            schema
        );
        const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
        timeseriesDs.dataSource.data = fusionTable;
        this.setState({
            timeseriesDs
        });*/
        Promise.all([dataFetch, schemaFetch]).then(res => {
            const data = res[0];
            const fusionTable = new FusionCharts.DataStore().createDataTable(
                data,
                schema
            );
            const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
            timeseriesDs.dataSource.data = fusionTable;
            this.setState({
                timeseriesDs
            });
        });
    }

    render() {
        const {theme, bgColor,patternColor} = this.props;
        const {timeseriesDs} = this.state;
        timeseriesDs.dataSource.chart = {
            theme: theme,
            bgColor: bgColor,
            showlegend: 0,
            palettecolors: patternColor
        };
        timeseriesDs.dataSource.yaxis = [
            {
                plot: "Volume",
                title: "Pressure (in atm)",
                // min: "0",
                // max: "104000",
                plottype: "step-line"
            }
        ];
        return (
            <>
                {timeseriesDs.dataSource.data ? (
                    <ReactFC {...timeseriesDs} />
                ) : (
                    "loading"
                )}
            </>
        );
    }
}

export default TimeSeriesStepLineChart;
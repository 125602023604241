/** 

import {  showAlertDialog, resetAlertDialog, } from 'store/slices/MessagesSystemSlice'

- Structure:
    dispatch(showAlertDialog({
        title: "",
        buttonLabel: { left: "", right: "" },
        dialogAction: "",
        data: ""
    }))


- InitialState:
    alertDialog: {
      show: false,
      title: "",
      message: "",
      buttonLabel: { left: "OK", right: "Cancel" },
      dialogAction: "",
      clickedButton: "",
      data: { dataId: rowDataId, stateName: section.id },
    }


- Execution in the component
  useEffect(() => {
    const { dialogAction, clickedButton, data } = alertDialog;
    if (dialogAction === "userDataDelete" && clickedButton === "alertDialogButtonLeft") {
      handleUserDataDelete(data.dataId, data.stateName);
    }
    dispatch(resetAlertDialog());
  }, [alertDialog.clickedButton]);

  dispatch(showAlertDialog({
    title: "Confirmation"
    buttonLabel: { left: "Ok", right: "Cancel" },
    dialogAction: "deleteUser",
    data: userId,
  }));
*/

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {makeStyles} from "@mui/styles";

import { hideAlertDialog } from "store/slices/MessagesSystemSlice";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: theme.palette.primary.light,
  },
  button: {
    color: theme.palette.primary.light,
  },
}));

export default function AlertDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { title, message, buttonLabel, show } = useSelector(
    (state) => state.messagesSystem.alertDialog
  );

  const handleClose = (buttonId) => {
    dispatch(hideAlertDialog({ clickedButton: buttonId }));
  };

  return (
    <Box>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            id="alertDialogButtonLeft"
            onClick={() => handleClose("alertDialogButtonLeft")}
            className={classes.button}
          >
            {buttonLabel.left}
          </Button>
          {
            buttonLabel.right &&
              <Button
                id="alertDialogButtonRight"
                autoFocus
                onClick={() => handleClose("alertDialogButtonRight")}
                className={classes.button}
              >
                {buttonLabel.right}
              </Button> 
          }
        </DialogActions>
      </Dialog>
    </Box>
  );
}

import React from 'react'
import {
    NavLink
} from "react-router-dom";

import {makeStyles} from "@mui/styles";
import NotFoundBg from 'assets/images/error/notfound-bg.jpg'
import ErrorBoundary from 'shared/system/error/ErrorBoundary';

const useStyles = makeStyles((theme) => ({
    root: {
        // padding: '10px',
        // position: 'absolute',
        // width: '100%',
        // height: '100%',
        display: 'flex',
        // flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0
    },
    notFound: {
        maxWidth: '529px',
        paddingBottom: '113px'
    },
    notFoundH: {
        height: '160px',
        zIndex: '-1'
    },
    h1:{
        fontFamily: 'montserrat,sans-serif',
        fontSize: '120px',
        margin: 0,
        fontWeight: 900,
        background: 'url(' + NotFoundBg + ') no-repeat',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    h2: {
        fontFamily: 'montserrat,sans-serif',
        color: '#000',
        fontSize: '24px',
        fontWeight: 700,
        textTransform: 'uppercase',
        marginTop: 0
    },
    p: {
        fontFamily: 'montserrat,sans-serif',
        color: '#000',
        fontSize: '14px',
        fontWeight: 400,
        marginBottom: '20px',
        marginTop: 0,
    },
    a: {
        fontFamily: 'montserrat,sans-serif',
        fontSize: '14px',
        textDecoration: 'none',
        textTransform: 'uppercase',
        background: '#0046d5',
        display: 'inline-block',
        padding: '15px 30px',
        borderRadius: '40px',
        color: '#fff',
        fontWeight: 700,
        '-webkit-box-shadow': '0 4px 15px -5px #0046d5',
        boxShadow: '0 4px 15px -5px #0046d5'
    },
    img: {
        maxWidth: '400px',
        width: '100%'
    },
    info: {

    }
}));
const NotFound = () => {
    const classes = useStyles()
    // throw new Error('here is test error for handling error')
    return (
        <ErrorBoundary>
            <div className={classes.root}>
                <div className={classes.notFound}>
                    <div className={classes.notFoundH}>
                        <h1 className={classes.h1}>Oops!</h1>
                    </div>
                    <h2 className={classes.h2}>404 - Page not found</h2>
                    <p className={classes.p}>
                        The Page you requested could nout Found
                    </p>
                    <NavLink className={classes.a} to='/'>Go To Home</NavLink>
                    {/* <a className='btn btn-primary' onClick={() => {
                        // throw new Error('here is test error for handling error')
                    }}>Test Error</a> */}
                </div>
            </div >
        </ErrorBoundary>
    )
}

export default NotFound
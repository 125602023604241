import React from 'react'
import { SvgIcon } from "@mui/material";

const DashboardIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      // fillRule="evenodd"
      // clipRule="evenodd"
      fill="none"
    >
      <path d="M21.5 8.5V21.5M15 13.375V21.5M8.5 18.25V21.5M5.25 28H24.75C26.5449 28 28 26.5449 28 24.75V5.25C28 3.45507 26.5449 2 24.75 2H5.25C3.45507 2 2 3.45507 2 5.25V24.75C2 26.5449 3.45507 28 5.25 28Z" stroke="#1F2937" strokeWidth="2.67" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
    // fill="none" xmlns="http://www.w3.org/2000/svg"
  );
};

export default DashboardIcon;

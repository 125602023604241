import React, { useMemo, useState } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogActions,
    DialogContent,
    lighten,
    Grid,
    Button,
    Box,
} from "@mui/material";
import { ArrowDropDown } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    }
}));

const SkipConfirmationDialog = (props) => {
    const {
        open,
        batchSize,
        batchIndex,
        handleCancel,
        handleSkip,
        handleRun
    } = props
    const classes = useStyles();

    const txt = useMemo(() => {
        const batches = []
        for(let i = batchIndex; batchSize >= i; i++ ){
            batches.push(`${i}/${batchSize}`)
        }
        return `There are ongoing batches (${batches.join(', ')}). You can skip or run alongside these batches.`;
    }, [
        batchSize,
        batchIndex
    ])
    return (
        <Dialog
            open={open}
            onClose={() => {}}
            className={classes.DialogContent}
            maxWidth="md"
        >
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Box>
                            {txt}
                        </Box>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} autoFocus>
                    Cancel
                </Button>
                <Button onClick={handleSkip} autoFocus>
                    Skip
                </Button>
                <Button onClick={handleRun} variant="contained">Run</Button>
            </DialogActions>
        </Dialog>
    );
};

SkipConfirmationDialog.propTypes = {
};

export default SkipConfirmationDialog

import React from 'react';

import Chart from './Chart';
import { getData } from "./utils"

export default class Statistic extends React.Component {
	componentDidMount() {
		getData().then(data => {
			this.setState({ data })
		})
	}
	render() {
		return (
			<div style={{padding: '30px 0'}}>
				{
					this.state == null ? 
						<div>Loading...</div> :
						<Chart data={this.state.data} />
				}
			</div>
		);
	}
}


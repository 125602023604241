import React, {useCallback, useEffect, useState} from 'react'
import _ from "underscore"

import { makeStyles } from "@mui/styles";
import TableView from './DetailView'
import { Box } from '@mui/material';
import NIPATableView from './Transform/BEADataTableView1';


const isDate = (val) => {
    let flag = false
    if (isNaN(val)) {
        flag = !isNaN(Date.parse(val))
    }
    return flag
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display:'flex',
        flex: '1',
        flexFlow: 'column',
        overflow: 'auto',
    },
}))


function DataTableView(props) {
    const classes = useStyles()
    let { blob, data, identifier } = props
    const [columns, setColumns] = useState({})
    const [rows, setRows] = useState({})

    const customizeData = useCallback(() => {
        if(!rows[identifier] || (Array.isArray(rows[identifier]) && rows[identifier].length < 1)){
            // if(blob === 'big-query'){
                const _columns = []
                const _rows = []
                if(Array.isArray(data) && data[0]){
                    Object.keys(data[0]).forEach(item => {
                        _columns.push({
                            id: item,
                            disablePadding: true,
                            // isDate: data[0][item].value ? isDate(data[0][item].value) : isDate(data[0][item]),
                            label: item.split('_').join(' ').toUpperCase(),
                            disabled: item === 'nId',
                            visible: item !== 'nId'
                        })
                    })
                    data.forEach(item => {
                        const _newItem = {}
                        _columns.forEach(col => {
                            if(item[col.id] && item[col.id].value !== undefined){
                                _newItem[col.id] = item[col.id].value
                            }else{
                                _newItem[col.id] = item[col.id]
                            }
                        })
                        _rows.push(_newItem)
                    })
                    setRows({
                        ...rows,
                        [identifier]: _rows
                    })
                }
                setColumns({
                    ...columns,
                    [identifier]: _columns
                })
            // }
        }
    }, [
        rows,
        columns,
        identifier,
    ])


    useEffect(() => {
        customizeData()
    }, [
        data,
        identifier
    ])
    return (
        <Box id={blob === 'big-query' ? 'BigqueryDatatable' : 'CloudStorageDatatable'} className={classes.root}>
            <TableView
                rows={rows[identifier] || []}
                fields={columns[identifier] || []}
                tableId={`${identifier}Inner`}
            />
        </Box>
    )
}

export default DataTableView
import React from "react";
import moment from "moment";
import {
    FusionCharts,
    ReactFC
} from  './ChartContainer';

const jsonify = res => res.json();
const dataFetch = fetch(
    "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/column-line-combination-data.json"
).then(jsonify);
const schemaFetch = fetch(
    "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/column-line-combination-schema.json"
).then(jsonify);
const schema = [{
        "name": "Time",
        "type": "date",
        "format": "%e-%b-%Y"
    },
    {
        "name": "Close",
        "type": "number"
    },
    {
        "name": "Volume",
        "type": "number"
    }
];

class TimeSeriesLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.onFetchData = this.onFetchData.bind(this);
        this.state = {
            timeseriesDs: {
                type: "timeseries",
                renderAt: "container",
                width: "100%",
                height: "100%",
                dataSource : {
                    chart: {
                    },
                    subcaption: {
                        text: "since 2015"
                    }
                }
            },
            lineConfig:{
                referenceZoneMin: 0,
                referenceZoneMax: 0
            },
            columnConfig:{
                referenceZoneMin: 0,
                referenceZoneMax: 0
            }
        };
    }

    componentDidMount() {
        this.onFetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.plotType !== this.props.plotType){
            this.onFetchData();
        }
    }

    onFetchData() {
        // const schema = res[1];
        const {stockValues} = this.props;
        let data = [];
        stockValues.map(item => {
            data.push([
                moment(item.reading_date).format('DD-MMM-YYYY'),item.close,item.stock_val
            ]);
        });
        const fusionTable = new FusionCharts.DataStore().createDataTable(
            data,
            schema
        );
        const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
        timeseriesDs.dataSource.data = fusionTable;
        this.setState({
            timeseriesDs
        });
        /*Promise.all([dataFetch, schemaFetch]).then(res => {
            const data = res[0];
            const schema = res[1];
            console.log(data,'here');
            const fusionTable = new FusionCharts.DataStore().createDataTable(
                data,
                schema
            );
            const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
            timeseriesDs.dataSource.data = fusionTable;
            this.setState({
                timeseriesDs
            });
        });*/
    }

    render() {
        const {theme, bgColor, patternColor,productCode, events, highlights} = this.props;
        const {timeseriesDs} = this.state;
        timeseriesDs.dataSource.chart = {
            theme: theme,
            bgColor: bgColor,
            // palettecolors: patternColor
        };
        timeseriesDs.dataSource.caption = {
            // text: productCode
        };
        timeseriesDs.dataSource.yaxis = [
            {
                plot: [
                    {
                        value: "Close",
                        /*value: {
                            close: "Close"
                        },*/
                        type: 'line',
                        connectnulldata: true,
                        style: {
                            "plot.null": {
                                "stroke-dasharray": "none",
                                // stroke: "#FF0000"
                            }
                        }
                    }
                ],
                referencezone: [
                    {
                        label: "Reference Zone",
                        valuemin: "3",
                        valuemax: "5",
                        style: {
                            marker: {
                                fill: "#B4F5E6",
                                stroke: "#B4F5E6"
                            },
                            "marker-text": {
                                fill: "#000000"
                            },
                            "marker:hover": {
                                fill: "#98DECD"
                            },
                            "marker-zone:hover": {
                                stroke: "#B4F5E6"
                            },
                            "marker-notch:hover": {
                                stroke: "#B4F5E6"
                            }
                        }
                    }
                ],
                showgridband: "1",
                style: {
                    "grid-band": {
                        fill: "#f5f5ef"
                    }
                }
            },
            {
                plot: [
                    {
                        value: "Volume",
                        type: 'column',
                        height: '10'
                    }
                ],
                referencezone: [
                    {
                        label: "Reference Zone",
                        valuemin: '10000000',
                        valuemax: "50000000",
                        style: {
                            marker: {
                                fill: "#B4F5E6",
                                stroke: "#B4F5E6"
                            },
                            "marker-text": {
                                fill: "#000000"
                            },
                            "marker:hover": {
                                fill: "#98DECD"
                            },
                            "marker-zone:hover": {
                                stroke: "#B4F5E6"
                            },
                            "marker-notch:hover": {
                                stroke: "#B4F5E6"
                            }
                        }
                    }
                ]
            }
        ];
        timeseriesDs.dataSource.datamarker = [
            /*{
                value: "Close",
                time: "15-Sep-2020",
                identifier: "H",
                timeformat: "%e-%b-%Y",
                tooltext:
                    "default"
            },*/
            {
                value: "Volume",
                time: "14-Sep-2016",
                identifier: "H",
                timeformat: "%e-%b-%Y",
                tooltext:
                    "default2"
            },
        ];
        highlights.map(item => {
            let h = {
                value:item.value,
                time: moment(item.date).format('DD-MMM-YYYY'),
                identifier: item.name,
                timeFormat: '%e-%b-%Y',
                toolText: item.description
            };
            timeseriesDs.dataSource.datamarker.push(
                h
            );
        });
        timeseriesDs.dataSource.xaxis = {
            plot: 'time',
            timemarker: []
        };
        events.map(item => {
            let e = {
                start: moment(item.date).format('DD-MMM-YYYY'),
                label: item.label,
                timeformat: "%e-%b-%Y",
                style: {
                    marker: {
                        fill: "#D0D6F4"
                    }
                },
                // type: "full"
            };
           timeseriesDs.dataSource.xaxis.timemarker.push(e)
        });

        const chartConfigStyle = {

        };
        return (
            <>
                {timeseriesDs.dataSource.data ? (
                    <ReactFC {...timeseriesDs} />
                ) : (
                    "Loading..."
                )}
            </>
        );
    }
}

export default TimeSeriesLineChart;
import React, { useCallback, useEffect, useState } from 'react'
import _ from "underscore"

import { useParams } from 'react-router-dom'

import { useSelector } from 'react-redux';
import { selectProductDataByRawPath } from 'store/selectors/ProductData';
import TransformContainer from '../TransformContainer';

const customizeData = (data) => {
    const _rows = []
    const _columns = []
    if (Array.isArray(data) && data[0]) {
        Object.keys(data[0]).forEach(item => {
            _columns.push({
                id: item,
                disablePadding: true,
                // isDate: data[0][item].value ? isDate(data[0][item].value) : isDate(data[0][item]),
                label: item.split('_').join(' ').toUpperCase(),
                disabled: item === 'nId',
                visible: item !== 'nId'
            })
        })
        data.forEach(item => {
            const _newItem = {}
            _columns.forEach(col => {
                if (item[col.id] && item[col.id].value !== undefined) {
                    _newItem[col.id] = item[col.id].value
                } else if (Array.isArray(item[col.id])) {
                    _newItem[col.id] = item[col.id][0]
                } else {
                    _newItem[col.id] = item[col.id]
                }
            })
            _rows.push(_newItem)
        })
    }
    const years = [...new Set(_rows.map(item => new Date(item.time_period_start).getUTCFullYear()))]

    return {
        rows: _rows,
        years,
    }
}

const sortData = (data, fieldName = 'id') => {
    /** Sort by Number and TExt */
    return data.sort((a, b) => {
        let aChar = a[fieldName]
        let bChar = b[fieldName]
        if(aChar[0] === '_') aChar = aChar.substring(1)
        if(bChar[0] === '_') bChar = bChar.substring(1)
        const numA = parseInt(aChar);
        const numB = parseInt(bChar);

        if (!isNaN(numA) && !isNaN(numB)) {
            // Both are numbers, sort by value
            return numA - numB;
        } else if (isNaN(numA) && isNaN(numB)) {
            // Both are non-numbers, sort alphabetically
            return aChar.localeCompare(bChar);
        } else {
            // One is a number, sort it first
            return isNaN(numA) ? 1 : -1;
        }
    });
}

function BEADataTableView1(props) {
    const { feedSource, databaseCode, productCode = '' } = useParams()
    const { blob } = props

    const rawData = useSelector((state) => selectProductDataByRawPath(state, {
        feedSource,
        databaseCode,
        productCode,
        blob
    }))
    const displaySchemas = useSelector(state => state.productData.displaySchemas)

    const [isInitialized, setIsInitialized] = useState(false)
    const [productResource, setProductResource] = useState({
        years: [],
        lines: [],
        data: []
    })
    // const [formattedData, setData] = useState([])

    const customizeDataMemoized = useCallback(customizeData, [])
    useEffect(() => {
        setIsInitialized(false)
    }, [
        feedSource,
        databaseCode,
        productCode,
        blob
    ])
    useEffect(() => {
        if (!isInitialized) {
            let _years = [];
            let _formattedData = {}
            let _lines = []
            if(Object.keys(displaySchemas).length > 0){
                for(let schemaKey in displaySchemas){
                    const displaySchema = displaySchemas[schemaKey]
                    if(displaySchema.length > 0){
                        for(let attribute of displaySchema){
                            if(['FLOAT', 'NUMBER', 'INTEGER'].indexOf(attribute.type?.toUpperCase()) > -1 && _lines.findIndex(item => item.id === attribute.ID) < 0){
                                _lines.push({
                                    id: attribute.ID,
                                    label: attribute.displayName,
                                    column: attribute.column,
                                    parentId: Array.isArray(attribute.parents) && attribute.parents.length > 0 ? attribute.parents[attribute.parents.length - 1] : null 
                                })
                            }
                        }
                    }
                }
            }

            if (Array.isArray(rawData)) {
                const { years: rYears, rows } = customizeDataMemoized(rawData);
                _years = [...new Set(rYears)];
                _formattedData = rows
                // _lines = lines
            } else {
                for (let path in rawData) {
                    const { years: rYears, rows } = customizeData(rawData[path]);
                    _years = [...new Set(_years.concat(rYears))];
                    _formattedData[path] = rows
                }
            }
            setProductResource({
                years: _years.sort((a, b) => b - a),
                data: _formattedData,
                lines: sortData(_lines)
            });
            setIsInitialized(true)
        }
    }, [
        rawData,
        displaySchemas,
        isInitialized
    ]);
    // console.log(rawData, 'rawData')
    // console.log(productResource, 'productResource')

    return (
        <>
            <TransformContainer
                {...props}
                productResource={productResource}
            />
        </>
    )
}

export default BEADataTableView1
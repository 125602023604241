import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'
import API from 'store/services/AxiosService'
import {USER_FAVORITE_CONFIG, USER_FAVORITE_GROUP, USER_FAVORITES} from 'store/UrlConstants'
import axios from "store/services/AxiosService"



const initialState = {
  user: {},
  loadingInProgress: false,
  favs: {},
  favKeys: [],
  favsLoading: false,
}

export const fetchUserFavorites = createAsyncThunk('user/favorites/fetch', async () => {
  const response = await API.get(USER_FAVORITES)
  return response.data
})

export const deleteUserFavorite = createAsyncThunk('user/favorites/delete', async (group) => {
  await API.delete(USER_FAVORITE_GROUP(group.id))
  return group
})

export const deleteUserFavoriteConfig = createAsyncThunk('user/favorites/config/delete', async ({
  group_id,
  favorite,
  config_id,
  groupName
}) => {
  // console.log(config_id, 'config_id')
  // const res = await API.put(USER_FAVORITE_CONFIG(group_id), {
  //   favorite: 'asdfa', config_id
  // })
  return {
    // res,
    groupName,
    group_id,
    favorite,
    config_id
  }
})

export const updateUserFavorite = createAsyncThunk('user/favorites/update',
  async ({groupToChange, name, favorites}) => {
    return axios.put(USER_FAVORITE_GROUP(groupToChange.id), {'group_name': name, 'favorites': favorites})
  })

function deleteGroupFromFavorites(state, name) {
  const index = state.favKeys.indexOf(name)
  if (index > -1) {
    state.favKeys.splice(index, 1)
  }
  delete state.favs[name]
}

function addFavGroup(state, group){
  state.favs[group.groupName] = group
  state.favKeys.push(group.groupName)
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload
    },
    appendFavoriteGroup: (state, action) => {
      addFavGroup(state, action.payload)
    },
    appendToFav: (state, action) => {
      if (!state.favs[action.payload.groupName]['favorites'].includes(action.payload.fav))
        state.favs[action.payload.groupName]['favorites'].push(action.payload.fav)
    }
  },
  extraReducers: {
    [fetchUserFavorites.pending]: (state, action) => {
      state.favsLoading = true
    },
    [fetchUserFavorites.fulfilled]: (state, action) => {
      state.favs = {}
      state.favKeys = []
      if(action.payload){
        action.payload.forEach(element => {
          state.favs[element.groupName] = element
          state.favKeys.push(element.groupName)
        })
      }
      state.favsLoading = false
    },
    [fetchUserFavorites.rejected]: (state, action) => {
      state.favsLoading = false
    },
    [deleteUserFavorite.fulfilled]: (state, action) => {
      deleteGroupFromFavorites(state, action.payload.groupName)
    },
    [deleteUserFavoriteConfig.fulfilled]: (state, action) => {
      console.log('hi?')
      console.log(action.payload)
      const {
        group_id,
        favorite,
        config_id,
        groupName
      } = action.payload
      const favGroup = state.favs[groupName]
      if(favGroup && favGroup.configuration){
        const configurations = (favGroup.configuration[favorite] || []).filter(config => config.id !== config_id)
        console.log(favGroup.configuration[favorite], 'favGroup.configuration[favorite]')
        state.favs[groupName].configuration[favorite] = configurations
      }
      // deleteGroupFromFavorites(state, action.payload.groupName)
    },
    [updateUserFavorite.pending]: (state, action) => {
      state.favsLoading = true
    },
    [updateUserFavorite.fulfilled]: (state, action) => {
      console.log(action)
      const oldName = action.meta.arg.groupToChange.groupName
      deleteGroupFromFavorites(state, oldName)
      addFavGroup(state, action.payload.data)
      state.favsLoading = false
    },
  },
})

export const prepareSelector = state => state.chartData
export const {setUserData, appendFavoriteGroup, appendToFav} = userSlice.actions
export default userSlice.reducer

import React, { useEffect, useMemo, useState } from "react";
// import { useSelector } from 'react-redux'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import { makeStyles } from "@mui/styles";
import moment from 'moment'
import _ from 'underscore'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Highcharts from 'highcharts/highcharts'
import HighchartsTimeline from 'highcharts/modules/timeline';
import ReactHighcharts from 'highcharts-react-official'
import { Box } from "@mui/system";
HighchartsTimeline(Highcharts)

const useStyles = makeStyles(() => ({
  Select: {
    height: '400px'
  },
}))
const convertToSeconds = (milliseconds) => {
  return Math.round(milliseconds / 1000 * 100) / 100;
}


function TimeLineChart(props) {
  const classes = useStyles()
  const { data: initialData, timeIncrements, hasCloudStorage } = props
  // console.log(initialData, 'initialData')
  // const [timeIncrement, setTimeIncrement] = useState(null)
  const dataOpts = [{
    'id': 'cloud-storage',
    'label': 'C'
  }, {
    'id': 'big-query',
    'label': 'B'
  }]
  // const initialData = [
  //   {
  //     "time_increment": "A",
  //     "loading_source": "cloud-storage",
  //     "blob_load_seconds": 1325,
  //     "time_to_draw_seconds": 30,
  //     "last_error": " ",
  //     "feed_source": "BEA",
  //     "database_code": "NIPA",
  //     "product_code": "T10105",
  //     "metadata_load_seconds": 1759,
  //     "initial_load_seconds": 1978
  //   },
  //   {
  //     "time_increment": "A",
  //     "loading_source": "big-query",
  //     "blob_load_seconds": 2319,
  //     "time_to_draw_seconds": 30,
  //     "last_error": " ",
  //     "feed_source": "BEA",
  //     "database_code": "NIPA",
  //     "product_code": "T10105",
  //     "metadata_load_seconds": 1759,
  //     "initial_load_seconds": 1978
  //   },
  //   {
  //     "time_increment": "Q",
  //     "loading_source": "cloud-storage",
  //     "blob_load_seconds": 597,
  //     "time_to_draw_seconds": 32,
  //     "last_error": " ",
  //     "feed_source": "BEA",
  //     "database_code": "NIPA",
  //     "product_code": "T10105",
  //     "metadata_load_seconds": 1759,
  //     "initial_load_seconds": 1978
  //   },
  //   {
  //     "time_increment": "Q",
  //     "loading_source": "big-query",
  //     "blob_load_seconds": 299,
  //     "time_to_draw_seconds": 72,
  //     "last_error": " ",
  //     "feed_source": "BEA",
  //     "database_code": "NIPA",
  //     "product_code": "T10105",
  //     "metadata_load_seconds": 1759,
  //     "initial_load_seconds": 1978
  //   }
  // ]
  // useEffect(() => {
    // if(timeIncrements.length > 0 && timeIncrements[0] !== ''){
    //   setTimeIncrement(timeIncrements[0])
    // }
  // }, [
  //   timeIncrements,
  //   hasCloudStorage
  // ])
  // {
  //     name: 'First dogs <span style="font-family: FontAwesome; color:red">\uf1b0</span>',
  //     label: '1951: First dogs in space',
  //     description: '22 July 1951 First dogs in space (Dezik and Tsygan) '
  //   }, {
  //     useHTML: true,
  //     name: 'Sputnik 1 <span style="font-family: FontAwesome; color:red">\uf135</span',
  //     label: '1957: First artificial satellite<i class="fas fa-satellite"></i>',
  //     description: '4 October 1957 First artificial satellite. First signals from space.'
  //   }, {
  //     name: 'First human spaceflight  <span style="font-family: FontAwesome; color:red">\uf183</span>',
  //     label: '1961: First human spaceflight (Yuri Gagarin)',
  //     description: 'First human spaceflight (Yuri Gagarin), and the first human-crewed orbital flight'
  //   }, {
  //     name: 'First human on the Moon <span style="font-family: FontAwesome; color:blue">\uf186</span>',
  //     label: '1969: First human on the Moon',
  //     description: 'First human on the Moon, and first space launch from a celestial body other than the Earth. First sample return from the Moon.'
  //   }
  const series = useMemo(() => {
    const _series = []
    if (initialData[0]) {
      const { initial_load_seconds, metadata_load_seconds } = initialData[0]
      _series.push({
        name: 'Initial Load Time',
        label: `${convertToSeconds(initial_load_seconds)}s (${initial_load_seconds}) ms`,
        description: 'This includes the time it takes to load JavaScript and CSS assets, images, and other resources'
      })
      _series.push({
        name: 'Product Table Metadata Load Time',
        label: `${convertToSeconds(metadata_load_seconds)}s (${metadata_load_seconds}) ms`,
        description: `"Product Table Metadata Load Time" refers to the duration it takes to load the essential product information displayed in the product table.`
      })
      let _data = [...initialData];
      _data.map(item => {
        _series.push({
          name: `Blob Load Seconds of ${item.loading_source.split('-').join(' ').toUpperCase()} ${item.time_increment ? ('-' + item.time_increment) : ''}`,
          label: `${convertToSeconds(item.blob_load_seconds)}s (${item.blob_load_seconds}) ms`,
          description: "Duration it to load blob"
        })
        _series.push({
          name: `Time to draw Seconds of ${item.loading_source.split('-').join(' ').toUpperCase()} ${item.time_increment ? ('-' + item.time_increment) : ''}`,
          label: `${convertToSeconds(item.time_to_draw_seconds)}s (${item.time_to_draw_seconds}) ms`,
          description: "Duration it takes to draw the chart with loaded blob"
        })
      })
      console.log(_series, '_series')
    }
    return _series
  }, [
    initialData,
  ])
  const data = []

  const configStock = useMemo(() => {
    return {

      chart: {
        type: 'timeline'
      },

      xAxis: {
        type: 'datetime',
        visible: false
      },
      navigator: {
        enabled: false
      },
      yAxis: {
        visible: false
      },

      title: {
        text: 'Timeline of Page Loading',
        // useHTML: true,
        // text: `<button onclick="javascript:testUp();">Click Me</button>`
      },

      series: [{
        dataLabels: {
          allowOverlap: false,
          connectorColor: 'silver',
          connectorWidth: 2
        },
        marker: {
          symbol: 'circle'
        },
        useHTML: true,
        data: [...series]
      }]
    }
  }, [series])

  return (
    <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
      <Box display="flex" sx={{ flexDirection: 'column', height: '100%' }} className="mt-3">
        <Box flex={1}>
          <ReactHighcharts
            highcharts={Highcharts}
            // constructorType={"chart"}
            options={configStock}
            containerProps={{ style: { height: "100%" } }}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  )
}

export default TimeLineChart
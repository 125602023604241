import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux'

import DepthChart from './DepthChart'

import Highcharts from 'highcharts/highstock'
// import ReactHighcharts from 'highcharts-react-official'

import moment from 'moment'

import DefaultChart from './Themes/DefaultChart'
import AvocadoChart from './Themes/AvocadoChart';
import BrandDarkChart from "./Themes/BrandDarkChart";
import BrandLightChart from "./Themes/BrandLightChart";
import DarkBlueChart from "./Themes/DarkBlueChart";
import DarkGreenChart from "./Themes/DarkGreenChart";
import DarkUnicaChart from "./Themes/DarkUnicaChart";
import GrayChart from "./Themes/GrayChart";
import GridChart from "./Themes/GridChart";
import GridLightChart from "./Themes/GridLightChart";
import HighContrastDarkChart from "./Themes/HighContrastDarkChart ";
import HighContrastLightChart from "./Themes/HighContrastLightChart";
import SandSignikaChart from "./Themes/SandSignikaChart";
import SkiesChart from "./Themes/SkiesChart";
import SunsetChart from "./Themes/SunsetChart";

function getRandomColor() {
    // Generate random values for red, green, and blue channels
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Combine the channels into a CSS color string
    const color = `rgb(${red}, ${green}, ${blue})`;

    return color;
}

const useData = (initialData, xAxis = null, yAxis = null) => {
    let defaultSeries = [], volume = [], duelSeries = {}
    const _dateField = xAxis || 'date'
    const isDuelYAxis = yAxis && yAxis.length > 0
    const _valueFields = yAxis && yAxis.length > 0 ? yAxis : ['open', 'high', 'low', 'close']
    for (let d of initialData) {
        const t = new Date(d[_dateField])
        const pointDate = t.getTime();
        let seriesPoint = [
            pointDate
        ]
        _valueFields.map(field => {
            if (isDuelYAxis) {
                if (duelSeries[field] === undefined) {
                    duelSeries[field] = {
                        name: field.toUpperCase(),
                        color: getRandomColor(),
                        data: []
                    }
                }
                const duelPoint = [pointDate, d[field]]
                duelSeries[field].data.push(duelPoint)
            }
            seriesPoint.push(d[field])
        })
        defaultSeries.push(seriesPoint)
        volume.push([
            pointDate, d.volume
        ])
    }
    let data = []
    if (isDuelYAxis) {
        data = duelSeries
        data.default = defaultSeries
    } else {
        data = defaultSeries
    }
    return {
        data, volume
    }
}
const useSeries = ({ data: seriesData, volume, config }) => {
    if (!seriesData) return []
    const {
        chartType: initialChartType, step, spline, dashStyle, zones: initialZones
    } = config
    let { scatterEnabled, scatterRadius, shadow } = config
    let chartType = initialChartType
    let useOhlcData = false, groupingUnits = [
        [
            'week', // unit name
            [1] // allowed multiples
        ], [
            'month',
            [1, 2, 3, 4, 6]
        ]
    ], lineWidth = 2
    if (initialChartType === 'scatter-line') {
        chartType = 'line'
    }
    if (initialChartType === 'line' && spline) {
        chartType = 'spline'
    } else if (initialChartType === 'area' && spline) {
        chartType = 'areaspline'
    } else if (initialChartType === 'arearange' && spline) {
        chartType = 'areasplinerange'
    } else if (initialChartType === 'bellcurve') {
        chartType = 'scatter'
    } else if (initialChartType === 'point-marker') {
        chartType = 'line'
        lineWidth = 0
        scatterEnabled = true
        scatterRadius = 2
        shadow = false
    } else if (initialChartType === 'ohlc') {
        useOhlcData = true
    } else if (initialChartType === 'grouped-bar' || initialChartType === 'stacked-bar') {
        chartType = 'column'
    } else if (initialChartType === 'columnpyramid-stack' || initialChartType === 'columnpyramid-grouped') {
        chartType = 'columnpyramid'
    } else if (initialChartType === 'percent-area') {
        chartType = 'area'
    }
    const _data = seriesData.default ? seriesData.default : seriesData;
    let seriesList = []
    if (seriesData.default) {
        delete seriesData.default
        seriesList = Object.values(seriesData)
    }
    let stockSeries = {
        name: 'Stock',
        type: chartType,
        id: 'stockseries',
        // type: 'gantt',
        marker: {
            enabled: scatterEnabled,
            radius: scatterRadius
        },
        lineWidth: lineWidth,
        shadow: shadow,
        step: step,
        data: _data,
        tooltip: {
            valueDecimals: 2
        },
        dataGrouping: {
            // units: groupingUnits
        },
        states: {
            hover: {
                lineWidthPlus: 0
            }
        },
        dashStyle: dashStyle,
        zones: [...initialZones],
        useOhlcData: useOhlcData
    },
        volumeSeries = {
            type: 'column',
            name: 'Volume',
            data: volume,
            yAxis: 1,
            dataGrouping: {
                // units: groupingUnits
            }
        }
    if (initialChartType === 'bellcurve') {
        return [
            {
                name: '',
                type: 'bellcurve',
                xAxis: 0,
                yAxis: 0,
                baseSeries: 'stockseries',
                zIndex: -1,
                intervals: 4,
                pointsInInterval: 5
            },
            {
                ...stockSeries,
                accessibility: {
                    exposeAsGroupOnly: true
                },
                marker: {
                    radius: 1.5
                }
            },
            volumeSeries
        ]
    } else if (['grouped-bar', 'stacked-bar', 'columnpyramid-stack', 'columnpyramid-grouped', 'percent-area'].indexOf(initialChartType) > -1) {
        const initialSeries = { ...stockSeries, id: undefined, data: [] }
        const oSeries = { ...initialSeries, id: 'stockseries', name: 'Open', color: Highcharts.getOptions().colors[0] },
            hSeries = { ...initialSeries, name: 'High', color: Highcharts.getOptions().colors[1] },
            lSeries = { ...initialSeries, name: 'Low', color: Highcharts.getOptions().colors[2] },
            cSeries = { ...initialSeries, name: 'Close', color: Highcharts.getOptions().colors[3] }
        for (let d of seriesData) {
            oSeries.data.push([d[0], d[1]])
            hSeries.data.push([d[0], d[2]])
            lSeries.data.push([d[0], d[3]])
            cSeries.data.push([d[0], d[4]])
        }
        return [oSeries, hSeries, lSeries, cSeries, volumeSeries]
    }
    return [stockSeries, volumeSeries, ...seriesList]
}
const useYAxis = ({ chartType, yPlotBands, yPlotLines }) => {
    const yAxis = [{
        labels: {
            align: 'right',
            x: -3
        },
        title: {
            text: 'Stock'
        },
        height: '60%',
        lineWidth: 2,
        resize: {
            enabled: true
        },
        crosshair: true,
        plotBands: [...yPlotBands],
        plotLines: [...yPlotLines],
    }]
    // if(chartType === 'bellcurve'){
    //     yAxis.push({
    //         title: { text: 'Bell curve' },
    //         opposite: true
    //     })
    // }else{
    yAxis.push({
        labels: {
            align: 'right',
            x: -3
        },
        title: {
            text: 'Volume'
        },
        top: '65%',
        height: '35%',
        offset: 0,
        lineWidth: 2
    })
    // }
    return yAxis
}
const useXAxis = ({ chartType, xPlotBands, xPlotLines }) => {
    const xAxis = [{
        // type: 'datetime',
        crosshair: true,
        plotBands: [...xPlotBands],
        plotLines: [...xPlotLines]
    }]
    // if(chartType === 'bellcurve'){
    //     xAxis.push({
    //         title: {
    //             text: 'Bell Curve'
    //         },
    //         alignTicks: false,
    //         opposite: true
    //     })
    // }
    return xAxis
}
const usePlotOptions = ({ chartType, config }) => {
    let {
        pointDataLabelEnabled
    } = config
    let stacking, columnDataLabelEnabled = false
    if (['stacked-bar', 'columnpyramid-stack'].indexOf(chartType) > -1) {
        stacking = 'normal'
        columnDataLabelEnabled = true
    } else if (['percent-area'].indexOf(chartType) > -1) {
        stacking = 'percent'
    }
    return {
        series: {
            showInNavigator: true,
            gapSize: 6,
            allowPointSelect: true,
            // compare: 'value',
            // cumulative: true,
            enabled: pointDataLabelEnabled,
            dataLabels: {
                enabled: columnDataLabelEnabled
            }
        },
        flags: {
            color: Highcharts.getOptions().colors[0], // same as onSeries
            fillColor: Highcharts.getOptions().colors[0],
            // width: 16,
            style: { // text style
                color: 'white'
            },
            states: {
                hover: {
                    fillColor: '#395C84' // darker
                }
            },
            textAlign: 'center'
        },
        line: {
        },
        column: {
            stacking: stacking,
            dataLabels: {
                enabled: columnDataLabelEnabled
            }
            // depth: 25
        },
        columnpyramid: {
            stacking: stacking,
            dataLabels: {
                enabled: columnDataLabelEnabled
            }
        },
        area: {
            stacking: stacking,
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
                lineWidth: 1,
                lineColor: '#666666'
            }
        },
        scatter: {
            marker: {
                radius: 5,
                states: {
                    hover: {
                        enabled: true,
                        lineColor: 'rgb(100,100,100)'
                    }
                }
            },
            states: {
                hover: {
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.x} cm, {point.y} kg'
            }
        }
    }
}

function ChartContainer(props) {
    const chartComponent = useRef(null);
    const { data: initialData } = props
    const highChartData = useSelector(state => state.highChartData) || {}
    const {
        chartType, plotBands, plotLines, theme
    } = highChartData
    const xPlotBands = plotBands.filter(item => item.axis = 'x')
    const yPlotBands = plotBands.filter(item => item.axis = 'y')
    const xPlotLines = plotLines.filter(item => item.axis = 'x')
    const yPlotLines = plotLines.filter(item => item.axis = 'y')

    const { data, volume } = useData(initialData, props.xAxis, props.yAxis)
    const series = useSeries({
        config: highChartData, data, volume
    })
    const yAxis = useYAxis({ chartType, yPlotBands, yPlotLines })
    const xAxis = useXAxis({ chartType, xPlotBands, xPlotLines })
    const plotOptions = usePlotOptions({ chartType, config: highChartData })
    const [chart, setChart] = useState(null)
    const { id: themeName, color: themeColor } = theme || {}

    if (chartType === 'depth-chart') {
        return <DepthChart />
    }

    // const options = { style: 'currency', currency: 'USD' };
    // const numberFormat = new Intl.NumberFormat('en-US', options);
    const configStock = {
        chart: {
            height: 600,
            alignTicks: true,
            zoomType: 'x',
            // options3d: {
            //     enabled: true,
            //     alpha: 15,
            //     beta: 15,
            //     depth: 50,
            //     viewDistance: 25
            // }
            events: {
                // addSeries: function(event) {
                //     // Function which saves the new background color.
                //     console.log(event, 'event')
                //     console.log(this.chart, 'this.chart')
                //     if(event.options.type === 'flags'){
                //         event.preventDefault()
                //         // console.log(this.chart, 'this.chart.series')
                //         event.target.options.series.push(event.options.data[0])
                //         // event.target.series.redraw()
                //     }
                // }
            }
        },
        stockTools: {
            enabled: false,
            gui: {
                buttons: [
                    // 'typeChange',
                    // 'separator',

                    // 'thresholds',
                    // 'separator',
                    // 'indicators',
                    // 'separator',
                    // 'simpleShapes',
                    // 'lines',
                    // 'crookedLines',
                    // 'measure',
                    // 'advanced',
                    // 'toggleAnnotations',
                    // 'separator',
                    // 'verticalLabels',
                    // 'flags',
                    // 'separator',
                    // 'zoomChange',
                    // 'fullScreen',
                    // 'separator',
                    // 'currentPriceIndicator',
                    // 'saveChart'
                ],
                definitions: {
                    thresholds: {
                        className: 'highcharts-threshold-annotation',
                        symbol: 'horizontal-line.svg'
                    },
                    typeChange: {
                        items: ['typeOHLC', 'typeLine']
                    },
                    flags: {
                        items: ['flagCirclepin', 'flagDiamondpin', 'flagSquarepin', 'flagSimplepin']
                    }
                }
            }
        },
        navigation: {
            bindings: {
                thresholds: {
                    className: 'highcharts-threshold-annotation',
                    start: function (event) {
                        var chart = this.chart,
                            x = chart.xAxis[0].toValue(event.chartX),
                            y = chart.yAxis[0].toValue(event.chartY),
                            colors = chart.options.colors,
                            series = chart.series[0],
                            zones = series.userOptions.zones || [];

                        chart.customColorIndex = chart.customColorIndex || 1;

                        chart.customColorIndex++;

                        if (
                            chart.customColorIndex === colors.length
                        ) {
                            chart.customColorIndex = 1;
                        }

                        zones.push({
                            color: colors[chart.customColorIndex],
                            value: y
                        });

                        chart.addAnnotation({
                            langKey: 'thresholds',
                            zoneIndex: zones.length - 1,
                            type: 'infinityLine',
                            draggable: 'y',
                            events: {
                                drag: function (e) {
                                    var newZones = series.userOptions.zones;

                                    newZones[this.userOptions.zoneIndex].value =
                                        chart.yAxis[0].toValue(e.chartY);

                                    chart.series[0].update({
                                        zones: newZones
                                    });
                                }
                            },
                            typeOptions: {
                                type: 'horizontalLine',
                                points: [{
                                    x: x,
                                    y: y
                                }]
                            }
                        });

                        chart.series[0].update({
                            zones: zones
                        });
                    }
                }
            }
        },
        yAxis: yAxis,
        navigator: {
            series: {
                color: 'red'
            }
        },
        tooltip: {
            shared: true,
            formatter: function () {
                return this.y + '</b><br/>' + moment(this.x).format('MMMM Do YYYY, h:mm')
            },
            split: true,
        },
        plotOptions: plotOptions,
        title: {
            // text: `High Chart Stock`
        },

        credits: {
            enabled: false
        },

        legend: {
            enabled: true
        },
        xAxis: xAxis,
        rangeSelector: {
            buttons: [{
                type: 'day',
                count: 1,
                text: '1d',
            }, {
                type: 'day',
                count: 7,
                text: '7d'
            }, {
                type: 'month',
                count: 1,
                text: '1m'
            }, {
                type: 'month',
                count: 3,
                text: '3m'
            },
            {
                type: 'all',
                text: 'All'
            }],
            selected: 4
        },
        series: series,
        responsive: {
            rules: [{
                condition: {
                    minWidth: 600
                },
                chartOptions: {
                    yAxis: [{
                        labels: {
                            align: 'left'
                        }
                    }, {
                        labels: {
                            align: 'left'
                        }
                    }]
                }
            }]
        }
    };
    const params = {
        constructorType: "stockChart",
        options: configStock
    }
    return (
        <div>
            {
                themeName === 'avocado' ?
                    <AvocadoChart {...params} /> :
                    themeName === 'brand-dark' ?
                        <BrandDarkChart {...params}
                        /> :
                        themeName === 'brand-light' ?
                            <BrandLightChart {...params} /> :
                            themeName === 'dark-blue' ?
                                <DarkBlueChart {...params} /> :
                                themeName === 'dark-green' ?
                                    <DarkGreenChart {...params} /> :
                                    themeName === 'dark-unica' ?
                                        <DarkUnicaChart {...params} /> :
                                        themeName === 'gray' ?
                                            <GrayChart {...params} /> :
                                            themeName === 'grid' ?
                                                <GridChart {...params} /> :
                                                themeName === 'grid-light' ?
                                                    <GridLightChart {...params} /> :
                                                    themeName === 'high-contrast-dark' ?
                                                        <HighContrastDarkChart {...params} /> :
                                                        themeName === 'high-contrast-light' ?
                                                            <HighContrastLightChart {...params} /> :
                                                            themeName === 'sand-signika' ?
                                                                <SandSignikaChart {...params} /> :
                                                                themeName === 'skies' ?
                                                                    <SkiesChart {...params} /> :
                                                                    themeName === 'sunset' ?
                                                                        <SunsetChart {...params} /> :
                                                                        <DefaultChart
                                                                            key="2"
                                                                            constructorType={"stockChart"}
                                                                            options={configStock}
                                                                        />
            }
        </div>
    )
}

export default ChartContainer
import React  from "react";
import { Alert, AlertTitle } from '@mui/lab';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false, 
        error: null, 
        errorInfo: null 
      };
    }

    static getDerivedStateFromError(error, errorInfo) {
      return { hasError:  true};  
    }

    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      console.log('error chatch', errorInfo)
    //   logErrorToMyService
      // You can also log error messages to an error reporting service here
    }
    componentDidUpdate(prevProps) {
      if (prevProps.identifier !== this.props.identifier) {
        this.setState({ hasError: false });
      }
    }
    
    render() {
      const {hasError, error, errorInfo} = this.state
      if (hasError) {
        // Error path
        return (
          <div>
            <Alert severity="error">
                <AlertTitle>Something went wrong.</AlertTitle>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                {error && error.toString()}
                <br />
                {errorInfo?.componentStack}
                </details>
            </Alert>
            <h2></h2>
          </div>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }
  
  export default ErrorBoundary
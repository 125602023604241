import React, { useState } from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {Col, Container, Row} from "shards-react";

import MainNavbar from "../components/layout/header/MainHeader";

import StepSidebar from "../components/layout/sidebar/StepSidebar";
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 510;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '120px'
    // width: `calc(${theme.spacing(7)} + 1px)`,
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(${theme.spacing(8)} + 1px)`,
    // },
});

const drawPaper = {
    top: '82px',
    height: 'calc(100% - 82px)',
    border: 'none !important',
    boxShadow: 'none',
    position: 'absolute',
    background: 'transparent',
}
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
      width: drawerWidth,
      // flexShrink: 0,
      // whiteSpace: 'nowrap',
      // boxSizing: 'border-box',
      ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': {
              ...drawPaper,
              ...openedMixin(theme)
          },
      }),
      ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': {
              ...drawPaper,
              ...closedMixin(theme)
          },
      }),
  }),
);

const DefaultLayout = (props) => {
    const [state, setState] = useState({
        menuVisible: false,
        navOpen: false,
        isStep2Shown: true,
        selectedSItem: 'features'
    })
    const toggleMenu = () => {
        setState({
            ...state,
            menuVisible: !state.menuVisible
        })
    }

    const toggleNav = () => {
        setState({
            ...state,
            navOpen: !state.navOpen
        })
    }
    const isStep2Shown = useSelector(state => state.appLayout.isStep2Shown)
    return (
      <>
          <div
            className={`white-content ${!state.menuVisible ? 'sidebar-mini' : ''} ${state.navOpen ? 'nav-open' : ''}`}>
              <div className="wrapper">
                  <div className="navbar-minimize-fixed">
                      <button className="minimize-sidebar btn btn-link btn-just-icon">
                          <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
                          <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
                      </button>
                  </div>
                  <Container fluid>
                      <Row>
                          {
                            !props.noNavbar &&
                            <MainNavbar
                              toggleMenu={toggleMenu}
                              toggleNav={toggleNav}
                              navOpened={state.navOpen}/>
                          }
                          <Drawer variant="permanent" open={isStep2Shown}>
                              <StepSidebar
                                navOpt={props.navOpt}
                                /*
                                isStep2Shown={isStep2Shown}
                                selectedItem={selectedSItem}*/
                              />
                          </Drawer>
                          <Col
                            className="main-panel"
                            /*lg={{ size: 10, offset: 2 }}
                            md={{ size: 9, offset: 3 }}
                            sm="12"*/
                            tag="div"
                          >
                              <div className="content">
                                  {props.children}
                              </div>
                              {/*{!this.props.noFooter && <MainFooter/>}*/}
                          </Col>
                      </Row>
                  </Container>
              </div>
          </div>
      </>
    )
}

/*const DefaultLayout = ({ children, noNavbar, noFooter }) => (

);*/

DefaultLayout.propTypes = {
    /**
     * Whether to display the navbar, or not.
     */
    noNavbar: PropTypes.bool,
    /**
     * Whether to display the footer, or not.
     */
    noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
    noNavbar: false,
    noFooter: false
};

export default DefaultLayout;

import { createSlice } from '@reduxjs/toolkit';
import { setPath } from 'shared/utilities/helpers'
import update from 'react-addons-update';

const initialState = {
  chartType: 'line',
  


  features: {
    scatterLineMarker: 'circle',
    scatterLineWidth: 7,
    scatterLineStroke: '#2ca02c',
    scatterLineFill: '#2ca02c',
    priceCoordinate: {}
  },
  coordinateY: [],
  interactiveTool: null,
  
  themeBg: {
    name: 'white',
    color: 'white'
  },
  grid: {
      tickStroke: 'white',
      show: false
  },
  volumeType: 'line',
  indicatorOpts: {
    ma: {
        visible: false,
        edges: [
          { edgeId: 'ema', edgeName: 'ema', edgeVal: 0, lineColor: 'red' },
          { edgeId: 'sma', edgeName: 'sma', edgeVal: 0, lineColor: 'red' },
          // { edgeId: 'macd', edgeName: 'macd', edgeVal: 0, lineColor: 'red' },
          { edgeId: 'wma', edgeName: 'wma', edgeVal: 0, lineColor: 'red' },
        ]
    },
    macd: {
        visible: false,
        macd: {
            fast: 0, 
            slow: 0,
            stroke: '#FF0000'   
        }, 
        signal: {
            val: 0,
            stroke: '#00F300'
        },
        fill: {
            divergence: '#4682B4'
        }
    },
    rsi: {
        visible: false,
        stroke: {
            line: '#FF0000'
        },
        val: 0
    },
    atr: {
        visible: false,
        stroke: {
            line: '#FF0000'
        },
        val: 0
    },
    sto: {
        visible: false,
        slow: {
            stroke: {
                line: '#FF0000'
            },
            windowSize: 0,
            kWindowSize: 0,
        },
        fast: {
            stroke: {
                line: '#FF0000'
            },
            windowSize: 0,
            kWindowSize: 0,
        },
        full: {
            stroke: {
                line: '#FF0000'
            },
            windowSize: 0,
            kWindowSize: 0,
            dWindowSize: 0,
        }
    },
    force_index: {
        visible: false,
        fi: {},
        fiEMA: {}
    }
  },
  annotation: {
    visible: true, 
    items: []
  },
  timeseries: {
    
  }
};

const LightWeightChartDataSlice = createSlice({
  name: 'lightWeightChartData',
  initialState,
  reducers: {
    setChartType: (state, action) => {
      state.chartType = action.payload
    },
    setChartOpt: (state, action) => {
      const {path, value} = action.payload
      setPath(state, path, value)
    },
    setChartFeature: (state, action) => {
      const {path, value} = action.payload
      const _features = {
        ...state.features, [path]: value
      }
      state.features = {..._features}
    },
    setCoordinateY: (state, action) => {
      state.coordinateY = [...action.payload]
    },
    setInteractiveTool: (state, action) => {
      state.interactiveTool = action.payload
    },
    setThemeBg: (state, action) => {
      state.themeBg = action.payload
    },
    setGridOption: (state, action) => {
      const {path, value} = action.payload
      const grid = state.grid || {}      
      setPath(grid, path, value)
      state.grid = {...grid} 
    },
    setVolumeType: (state, action) => {
      state.volumeType = action.payload
    },
    setIndicatorVisible: (state, action) => {
      const {indicatorId, visible} = action.payload
      const _indicatorOpts = {...state.indicatorOpts};
      if(_indicatorOpts[indicatorId] === undefined) _indicatorOpts[indicatorId] = {};
      _indicatorOpts[indicatorId].visible = visible
      state.indicatorOpts = _indicatorOpts
    },
    setIndicatorOption: (state, action) => {
      const {indicatorId, path, value} = action.payload;
      const _indicatorOpts = {...state.indicatorOpts};
      let _indicatorOpt = _indicatorOpts[indicatorId] === undefined ? {} : _indicatorOpts[indicatorId]
      // console.log(state.indicatorOpts, '_indicatorOpt', indicatorId)
      // console.log(_indicatorOpt, '_indicatorOpt', indicatorId)
      setPath(_indicatorOpt, path, value)
      _indicatorOpts[indicatorId] = _indicatorOpt
      state.indicatorOpts = _indicatorOpts
    },
    addIndicatorEdge: (state, action) => {
      const { indicatorId, edge } = action.payload;
      const _indicatorOpts = {...state.indicatorOpts};
      if(_indicatorOpts[indicatorId] === undefined) {
        _indicatorOpts[indicatorId] = {
          visible: false,
          edges: []
        }
      }
      if(_indicatorOpts[indicatorId]['edges'] === undefined){
        _indicatorOpts[indicatorId]['edges'] = []
      }
      _indicatorOpts[indicatorId]['edges'].push(edge);
      state.indicatorOpts = _indicatorOpts
    },
    updateIndicatorEdge: (state, action) => {
      const { indicatorId, edgeId, edgeKey, edgeUpdateVal } = action.payload;
      const _indicatorOpts = {...state.indicatorOpts};

      const edgeIndex = _indicatorOpts[indicatorId]['edges'].findIndex(item => item.edgeId === edgeId);
      if(edgeIndex > -1){
          _indicatorOpts[indicatorId]['edges'][edgeIndex][edgeKey] = edgeUpdateVal;
      }
      state.indicatorOpts = _indicatorOpts
    },
    deleteIndicatorEdge: (state, action) => {
      const { indicatorId, edgeId } = action.payload
      const _indicatorOpts = {...state.indicatorOpts};
      _indicatorOpts[indicatorId]['edges'] = _indicatorOpts[indicatorId]['edges'].filter(item => item.edgeId !== edgeId)
      state.indicatorOpts = _indicatorOpts
    },
    addAnnotation: (state, action) => {
      if(state.annotation === undefined){
        state.annotation = {
          items: [action.payload]   
        }
      }
      const _annotation = {
        ...state.annotation, items: [
            ...state.annotation.items, action.payload
        ]
      }
      state.annotation = _annotation
    },
    updateAnnotation: (state, action) => {
      const { id } = action.payload
      const _annotationIdx = state?.annotation?.items.findIndex(item => item.id === id);
      if(_annotationIdx > -1){
        state = update(state, { 
          annotation: { items: {
              [_annotationIdx]: {
                  label: {$set: action.payload.label},
                  date: {$set: action.payload.date},
                  description: {$set: action.payload.description},
                  type: {$set: action.payload.type}
              } 
          }
        }})
      }
    },
    deleteAnnotation: (state, action) => {
      state.annotation = {
        ...state.annotation, items: state.annotation.items.filter(item => item.id !== action.payload)
      }
    }
  },
  extraReducers: {},
});

export const prepareSelector = state => state.chartData;
export const {
  setChartOpt,
  setChartFeature,
  setCoordinateY,
  setInteractiveTool,
  setThemeBg,
  setGridOption,
  setVolumeType,
  setIndicatorVisible,
  setIndicatorOption,
  addIndicatorEdge,
  updateIndicatorEdge,
  deleteIndicatorEdge,
  addAnnotation,
  updateAnnotation,
  deleteAnnotation,
} = LightWeightChartDataSlice.actions
export default LightWeightChartDataSlice.reducer;

import React from "react";

class AuthLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className='auth-page'>
                <div className='wrapper wrapper-full-page'>
                    {   this.props.children}
                </div>
            </div>
        )
    }
}

export default AuthLayout;

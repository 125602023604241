import { useState } from 'react';
import { deleteFrontendQAReport, deleteQAReportApi, fetchFrontendQAReports, getQaDataApi, getQaReportDataApi, saveQAReportApi } from 'store/slices/FrontendQA';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';

const useFetchQAReports = (dispatch, callback = () => {}) => {
    const [qaData, setQaData] = useState({})
    const [qaReports, setQaReports] = useState([])
    const [qaReportData, setQaReportData] = useState({})
    const [loading, setLoading] = useState(false)
    const [totalScore, setTotalScore] = useState(0)

    const fetchQaReports = (params) => {
        setLoading(true)
        dispatch(fetchFrontendQAReports(params)).then(r => {
            if(r.payload){
                setQaReports(r.payload)
                callback()
            }
            setLoading(false)
        })
    }

    const deleteQAReport = (params) => {
        dispatch(deleteQAReportApi(params)).then(r => {
            if (r.payload) {
                const { data: { status } } = r.payload
                // const { data } = r.payload
                if (status) {
                    setQaData((prev) => ({
                        ...prev,
                        qaReports: prev?.qaReports?.filter(item => item.id !== params.id),
                        statusRows: prev?.statusRows?.filter(item => item.qa_report_id !== params.id)
                    }))
                }
            }
        })
    }

    const getQaData = (params) => {
        setLoading(true)
        dispatch(getQaDataApi(params)).then(r => {
            if(r.payload){
                setQaData(r.payload)
                callback()
            }
            setLoading(false)
        })
    }

    const saveQAReport = async (payload) => {
        if (loading) return
        setLoading(true)
        const { payload: { status, row: _qaReportData } } = await dispatch(saveQAReportApi(payload))
        setLoading(false)
        if (!(_qaReportData && _qaReportData.id !== '')) {
            dispatch(showSnackbarMessage({
                message: 'Error happened in QA report',
                severity: 'error'
            }))
            return
        };
        return _qaReportData
    }

    const getQaReportData = (_qaReportId) => {
        setLoading(true)
        dispatch(getQaReportDataApi(_qaReportId)).then(r => {
            if(r.payload){
                setQaReportData(r.payload)
                setTotalScore(r.payload.average_score)
                callback()
            }
            setLoading(false)
        })
    }

    return {
        getQaData,
        getQaReportData,
        fetchQaReports,
        deleteQAReport,
        saveQAReport,
        setTotalScore,
        state: {
            qaData,
            qaReports,
            qaReportData,
            loading,
            totalScore,
        }
    };
};

export default useFetchQAReports;
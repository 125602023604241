import React from "react";

import {
	EdgeIndicator
} from "../../../../../libs/rrag/lib/coordinates";
import {
    LineSeries,
} from "../../../../../libs/rrag/lib/series";
import {
    CurrentCoordinate
} from "../../../../../libs/rrag/lib/coordinates";

export default  function EdgeIndicatorContainer(props) {
    return (
        <>
            {
                props.maIndicatorEdges.map(edge => {
                    const edgeCalculator = edge['edgeCalc']
                    return <>
                            <LineSeries yAccessor={edgeCalculator.accessor()} stroke={edgeCalculator.stroke()}/>
                            <CurrentCoordinate yAccessor={edgeCalculator.accessor()} fill={edgeCalculator.stroke()} />
                            <EdgeIndicator itemType="first" orient="left" edgeAt="left"
                                yAccessor={edgeCalculator.accessor()} fill={edgeCalculator.stroke()} {...props.appearance}/>
                            <EdgeIndicator itemType="last" orient="right" edgeAt="right"
                                yAccessor={edgeCalculator.accessor()} fill={edgeCalculator.stroke()} {...props.appearance}/>
                        </>
                })   
            }
            <EdgeIndicator itemType="last" orient="right" edgeAt="right"
                yAccessor={d => d.close} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"} {...props.appearance}/>
            <EdgeIndicator itemType="first" orient="left" edgeAt="left"
                yAccessor={d => d.close} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"} {...props.appearance}/>
        </>
    );
}
import { useState } from 'react';
import { 
    getQARangesApi,
    getQaResultsApi,
    initQARangeApi,
    retryQARangeApi,
    saveQARangeApi
} from 'store/slices/FrontendQA';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';

const useQaRange = (dispatch, callback = () => {}) => {
    const [qaRanges, setQARanges] = useState([])
    const [loading, setLoading] = useState(false)

    const getQARanges = (_qaReportId) => {
        setLoading(true)
        dispatch(getQARangesApi(_qaReportId)).then(r => {
            if(r.payload){
                setQARanges(r.payload)
                callback()
            }
            setLoading(false)
        })
    }

    const getQaResults = async (qaRangeId) => {
        setLoading(true)
        const { payload } = await dispatch(getQaResultsApi({
            reportId: qaRangeId
        }))
        setLoading(false)
        console.log(payload, 'payload')
        return payload || []
    }
    
    const initQARange = async (params) => {
        try{
            if (loading) return
            setLoading(true)
            const { payload : { status, range } } = await dispatch(initQARangeApi(params))
            setLoading(false)
            if (!status) {
                dispatch(showSnackbarMessage({
                    message: 'Error happened in QA report',
                    severity: 'error'
                }))
                return
            };
            const _qaRanges = [...qaRanges]
            _qaRanges.push(range)
            setQARanges(_qaRanges)
            return range
        }catch(e){
            console.log(e, 'e')
        }
    }

    
    const retryQARange = async (params) => {
        try{
            if (loading) return
            const { payload } = await dispatch(retryQARangeApi(params))
            return payload || {}
        }catch(e){
            console.log(e, 'e')
        }
    }

    const saveQARange = async (payload) => {
        if (loading) return
        setLoading(true)
        const { payload: { row, status, nextRange } } = await dispatch(saveQARangeApi(payload))
        setLoading(false)
        if (!status) {
            dispatch(showSnackbarMessage({
                message: 'Error happened in QA report',
                severity: 'error'
            }))
            return
        };
        // const _qaRanges = [...qaRanges]
        // const _qIdx = _qaRanges.findIndex(item => item.id === row.id)
        // if(_qIdx > -1){
        //     _qaRanges[_qIdx] = {...row}
        // }else{
        //     _qaRanges.push(row)
        // }
        // if(nextRange){
        //     _qaRanges.push(nextRange)
        // }
        // console.log(_qaRanges, '_qaranges')
        // setQARanges([..._qaRanges])
        return {
            range: row,
            nextRange
        }
    }

    return {
        initQARange,
        getQaResults,
        getQARanges,
        saveQARange,
        setQARanges,
        retryQARange,
        state: {
            qaRanges,
            loading
        }
    };
};

export default useQaRange;
import { useState } from 'react';
import { fetchSearchQAReport, fetchSearchQAData } from 'store/slices/FrontendQA';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';

export const useSearchQAReport = (dispatch) => {
    const [isLoading, setIsLoading] = useState(false);
    const [qaReports, setQAReports] = useState([])

    const errorBlock = (error) => {
        setIsLoading(false)
        setQAReports([])
        dispatch(showSnackbarMessage({
            message: `Something went wrong. Please kindly try again later`,
            severity: 'error'
        }))
        console.log(error, 'in the search report in qa sidebar')
    }

    const triggerRequest = async (searchWord) => {
        if (isLoading) return
        setIsLoading(true)
        setQAReports([])
        const r = await dispatch(fetchSearchQAReport({
            searchWord
        }))
        
        if (r && r.payload) {
            // console.log(r.payload, 'r.payload')
            setQAReports(r?.payload?.suggestions || [])
            // setShowQAResult(true)
            setIsLoading(false);
            return r;
        } else {
            errorBlock()
        }
        
        // Axios.get('https://plotter-frontend-qa-runtime-service-ycyfoaj6aa-uc.a.run.app/search?q=hello222???????', {
        // Axios.get('http://localhost:8080/search?q=ok???', {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // })
    };

    return [triggerRequest, {
        isLoading,
        qaReports
    }];
};


export const useSearchQAData = (dispatch) => {
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState([])

    const errorBlock = (error) => {
        setIsLoading(false)
        setResults([])
        dispatch(showSnackbarMessage({
            message: `Something went wrong. Please kindly try again later`,
            severity: 'error'
        }))
        console.log(error, 'in the search report in qa sidebar')
    }

    const triggerRequest = async (params) => {
        if (isLoading) return
        setIsLoading(true)
        setResults([])
        const r = await dispatch(fetchSearchQAData(params))
        
        if (r && r?.payload?.data) {
            console.log(r?.payload.data, 'r.payload')
            setResults(r?.payload.data || [])
            // setShowQAResult(true)
            setIsLoading(false);
            return r;
        } else {
            errorBlock()
        }
        
        // Axios.get('https://plotter-frontend-qa-runtime-service-ycyfoaj6aa-uc.a.run.app/search?q=hello222???????', {
        // Axios.get('http://localhost:8080/search?q=ok???', {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // })
    };

    return [triggerRequest, {
        isLoading,
        results
    }];
};

export default useSearchQAData;
import * as React from 'react';
import {
  TreeView,
  TreeItem
} from '@mui/lab';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon
} from '@mui/icons-material'

export default function CustomTreeView(props) {
  const {data} = props
  const renderTree = (nodes) => (
    <TreeItem key={`${nodes.id}-${nodes.children ? 'has-child' : ''}`} className={nodes.className} nodeId={nodes.id} label={nodes.label}>
      {Array.isArray(nodes.children) && nodes.children.length > 0
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['root']}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
      className={props.className}
    >
      {
        Array.isArray(data) ? (
          data.map(item => renderTree(item))
        ) : (
          renderTree(data)
        )
      }
    </TreeView>
  );
}
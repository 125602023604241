import * as React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@mui/styles";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Chip, FormControlLabel, IconButton, Switch, Tooltip, Typography } from '@mui/material';

import DataTableColumnFilterPopover from 'components/common/DataTableColumnFilterPopover';

import { Reply, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { getComparator, stableSort } from 'shared/utilities/tableUtils';
import useFetchQAResults from 'hooks/FetchQAResults';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "10px",
        background: '#F5F8FF'
    },
    ColorCap: {
        position: 'relative',
        marginRight: '10px',
        paddingLeft: '12px',
        fontSize: '11px',
        color: 'black',
        "&::before": {
            content: '""',
            position: 'absolute',
            background: 'inherit',
            width: '10px',
            height: '10px',
            left: 0,
            top: '3px'
        },
        "&.success": {
            "&::before": {
                backgroundColor: '#00f2c3'
            },
        },
        "&.warning": {
            "&::before": {
                backgroundColor: '#ff8d72'
            },
        },
        "&.danger": {
            "&::before": {
                backgroundColor: '#fd5d93'
            },
        },
    },
    TableCap: {
        padding: '13px',
        alignItems: 'center',
        background: 'white',
        boxShadow: '14px 6px 4px -21px rgba(0,0,0,0.2), 0px -4px 2px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    Table: {
        marginTop: '11px',
        background: 'white',
        "& .success": {
            background: '#00f2c3'
        },
        "& .warning": {
            background: '#ff8d72'
        },
        "& .danger": {
            background: '#fd5d93'
        },
        "& td": {
            borderRight: 'solid 1px white'
        }
    },
    TableTitle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '140%',
        margin: '0px',
        fontSize: '14px',
        letterSpacing: '-0.006em',
        fontWeight: '600',
        color: 'rgb(67, 74, 84)',
        marginRight: '10px'
    },
    TableSubTitle: {

    },

    productStaticBarButton: {
        color: '#4e4b4b',
        cursor: 'pointer',
        margin: '0 3px',
        position: 'relative',
        boxShadow: '0 0 1px 1px #e5e5f1',
        transition: 'all ease-in .2s',
        borderRadius: '4px',
        height: '37px',
        width: '37px',
        fontSize: '13px',
        textAlign: 'center',
        '&.selected': {
            boxShadow: '0 0 1px 1px #646467'
        },
        '&:hover': {
            background: '#ececec'
        },
        '&.selectedViewMode': {
            background: '#3c54db',
            color: 'white',
            '&:hover': {
                background: '#5e72e4'
            },
        },
        '& svg': {
            display: 'block',
            margin: '0 auto',
            fontSize: '35px'
        },
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     height: '1px',
        //     borderTop: 'solid 1px #e2dcdc',
        //     bottom: '-6px',
        //     width: 'calc(100% - 6px)'
        // },
        // '&:last-child': {
        //     '&::after': {
        //         content: 'initial'
        //     }
        // }
    },
}))

const convertToSeconds = (milliseconds, showMiliSeconds = true) => {
    if (!milliseconds) return ''
    const _seconds = Math.round(milliseconds / 1000 * 100) / 100;
    if (!showMiliSeconds) {
        return `${_seconds}s`
    }
    return `${milliseconds}ms`
}

const getPercentageOfTotal = (total, time) => {
    return time > 0 ? `${(time / total * 100).toFixed(2)}%` : 'N/A'
}

function createData(name, status, fat, carbs, protein, score) {
    return { name, status, fat, carbs, protein, score };
}

const rows = [
    createData('AAC', 1, 6.0, 24, 1.5, 94),
    createData('ACAQ', 0, 9.0, 37, 1.3, 94),
    createData('AAMC', 1, 16.0, 24, 1.6, 96),
    createData('AMC', -1, 16.0, 0, 0, 96),
];

const thresholdConfigs = {
    MAX_AL: {
        secs: 3707,
        dangerF: 0.5,
        warnF: 1/10
    },
    MAX_MDL: {
        secs: 4670,
        dangerF: 0.5,
        warnF: 1/20
    },
    MAX_BDL: {
        secs: 297801,
        dangerF: 0.5,
        warnF: 1/20
    },
    MAX_TDT: {
        secs: 22700,
        dangerF: 0.5,
        warnF: 1/20
    },
    TOTAL: {
        secs: 3000,
        dangerF: 1,
        warnF: 0.7
    },
}

export default function QAResultsTable() {
    const { feedSource, databaseCode, reportId } = useParams();

    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation();

    const [fetchQaResults, { qaResults: results, loading }] = useFetchQAResults(dispatch)

    const [productCode, setProductCode] = React.useState('');
    const [blobOpt, setBlobOpt] = React.useState('cloud-storage')
    const [showMiliSeconds, setShowMiliSeconds] = React.useState(false)
    const [showTimePercentage, setShowTimePercentage] = React.useState(false)
    const [sortOption, setSortOption] = React.useState(null)

    const [fAnchorEl, setFAchorEl] = React.useState(null)
    const openFilterPop = Boolean(fAnchorEl)

    const [openedColumn, setOpenedColumn] = React.useState(null)
    const onOpenFilterPop = (openedColumn) => (event) => {
        setFAchorEl(event.currentTarget)
        setOpenedColumn(openedColumn)
    }
    const [columnSort, setColumnSort] = React.useState({})

    const handleClose = () => {
        setFAchorEl(null)
    }

    React.useEffect(() => {
        const _productCode = new URLSearchParams(location.search).get('productCode');
        setProductCode(_productCode || "")
        const _params = {
            reportId,
            blobOpt,
            feedSource,
            databaseCode,
        }
        if(_productCode){
            _params.productCode = _productCode
        }
        fetchQaResults({ ..._params })
    }, [
        feedSource,
        databaseCode,
        reportId,
        blobOpt
    ])

    const sortedResults = React.useMemo(() => {
        let _rows = [...results]
        for (let col in columnSort) {
            _rows = stableSort(_rows, getComparator(columnSort[col], col))
        }
        return _rows
    }, [
        columnSort,
        results
    ])

    const evaluateThreshold = (thresholdType, thresholdVal) => {
        if(!thresholdVal || thresholdVal <= 0) return ''
        let color = 'success'
        const _maxThresHold = thresholdConfigs[thresholdType]
        if (thresholdVal > _maxThresHold.secs * _maxThresHold.dangerF){
            color = 'danger'
        } else if(thresholdVal >= _maxThresHold.secs * _maxThresHold.warnF){
            color = 'warning'
        }
        return color;
    }

    return (
        <>
            <TableContainer className={classes.root}>
                <Box display="flex" className={classes.TableCap}>
                    <Box display={"flex"} sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box>
                            <Typography className={classes.TableTitle}>{`${feedSource}/${databaseCode}${productCode !== '' ? '/' + productCode : ''}`}</Typography>
                            <Box display={'flex'}>
                                <div className={`${classes.ColorCap} success`}>Success</div>
                                <div className={`${classes.ColorCap} warning`}>Warn</div>
                                <div className={`${classes.ColorCap} danger`}>Danger</div>
                            </Box>
                        </Box>
                        {/* <Chip label={rows.length} /> */}
                        <Box display={"flex"} sx={{ alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'end' }} className={"mr-3"}>
                                <Tooltip title="Cloud Storage">
                                    <Box
                                        sx={{ padding: '10px !important' }}
                                        className={`${classes.productStaticBarButton} ${blobOpt === 'cloud-storage' ? 'selected' : ''}`}
                                        mr={1}
                                        onClick={() => setBlobOpt('cloud-storage')}
                                    >CS</Box>
                                </Tooltip>
                                <Tooltip title="Big Query">
                                    <Box
                                        sx={{ padding: '10px !important' }}
                                        className={`${classes.productStaticBarButton} ${blobOpt === 'big-query' ? 'selected' : ''}`}
                                        onClick={() => setBlobOpt('big-query')}
                                    >BG</Box>
                                </Tooltip>
                            </Box>
                            <Box className={"mr-3"}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={showMiliSeconds} size="small" onChange={(e) => { setShowMiliSeconds(e.target.checked) }} name="showms" />
                                    }
                                    className='m-0'
                                    label="Show MS"
                                />
                            </Box>
                            <Box className={"mr-3"}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={showTimePercentage} size="small" onChange={(e) => { setShowTimePercentage(e.target.checked) }} name="showtimePercentage" />
                                    }
                                    className='m-0'
                                    label="Show %"
                                />
                            </Box>
                            <Box>
                                <Tooltip title="Back">
                                    <IconButton onClick={() => {
                                        history.push(`/qa/${feedSource}/${databaseCode}`)
                                    }}>
                                        <Reply />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>
                    {/* <Typography className={classes.TableSubTitle}>{subTitle}</Typography> */}
                </Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.Table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Code</TableCell>
                            <TableCell>
                                <Box display={'flex'}>
                                    <span>Status</span>
                                    <IconButton
                                        aria-label="delete"
                                        className={classes.margin}
                                        size="small"
                                        onClick={onOpenFilterPop('status')}>
                                        <ExpandMoreIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </TableCell>
                            <TableCell>TimeIncrement</TableCell>
                            <TableCell align="right">Asset Load Time</TableCell>
                            <TableCell align="right">Meta Data Load Time</TableCell>
                            <TableCell align="right">Blob Load Time</TableCell>
                            <TableCell align="right">Draw Time</TableCell>
                            <TableCell align="right">Total Time</TableCell>
                            <TableCell align="right">
                                <Box display={'flex'}>
                                    <span>Score</span>
                                    <IconButton
                                        aria-label="delete"
                                        className={classes.margin}
                                        size="small"
                                        onClick={onOpenFilterPop('score')}>
                                        <ExpandMoreIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ? (
                                <TableRow>
                                    <TableCell colSpan={9}><Box className={"text-center"}>Loading...</Box></TableCell>
                                </TableRow>
                            ) : (
                                sortedResults.length > 0 ? (
                                    <>
                                        {sortedResults.map((item, idx) => (
                                            // row.items?.map((item, iIdx) => (
                                                <TableRow
                                                    key={idx}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        <Tooltip title="Product Code">
                                                            <Box>
                                                                {item.product_code}
                                                            </Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            item.last_error && item.last_error.trim() !== '' ?
                                                                <Chip size='small' label="Error" color="error" />
                                                                : (
                                                                    item.category === 'good' ? <Chip size='small' label="Good" color="primary" /> :
                                                                        item.category === 'average' ? <Chip size='small' label="Average" color="info" /> :
                                                                            <Chip size='small' label="Below Average" color="warning" />
                                                                )
                                                        }
                                                        {/* // row.staging === 0 ? <Chip size='small' label="In Progress" color="warning" /> :
                                                //     row.staging === -1 ? <Chip size='small' label="Error" color="error" /> :
                                                //         <Chip size='small' label="Done" color="primary" /> */}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Tooltip title="Time Increment">
                                                            <Box>
                                                                {item.time_increment}
                                                            </Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right" className={evaluateThreshold('MAX_AL', item.initial_load_seconds)}>
                                                        <Tooltip title="Asset Load Time">
                                                            <Box>
                                                                {
                                                                    showTimePercentage ?
                                                                        getPercentageOfTotal(item.total_seconds, item.initial_load_seconds) :
                                                                        convertToSeconds(item.initial_load_seconds, showMiliSeconds)
                                                                }
                                                            </Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right" className={evaluateThreshold('MAX_MDL', item.metadata_load_seconds)}>
                                                        <Tooltip title="Meta Data Load Time">
                                                            <Box>
                                                                {
                                                                    showTimePercentage ?
                                                                        getPercentageOfTotal(item.total_seconds, item.metadata_load_seconds) :
                                                                        convertToSeconds(item.metadata_load_seconds, showMiliSeconds)}
                                                            </Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right" className={evaluateThreshold('MAX_BDL', item.blob_load_seconds)}>
                                                        <Tooltip title="Blob Load Time">
                                                            <Box>
                                                                {
                                                                    showTimePercentage ?
                                                                        getPercentageOfTotal(item.total_seconds, item.blob_load_seconds) :
                                                                        convertToSeconds(item.blob_load_seconds, showMiliSeconds)}
                                                            </Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right" className={evaluateThreshold('MAX_TDT', item.time_to_draw_seconds)}>
                                                        <Tooltip title="Time to Draw">
                                                            <Box>
                                                                {
                                                                    showTimePercentage ?
                                                                        getPercentageOfTotal(item.total_seconds, item.time_to_draw_seconds) :
                                                                        convertToSeconds(item.time_to_draw_seconds, showMiliSeconds)}
                                                            </Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right" className={evaluateThreshold('TOTAL', item.total_seconds)}>
                                                        <Tooltip title="Total Time">
                                                            <Box>{convertToSeconds(item.total_seconds, showMiliSeconds)}</Box>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {
                                                            !(item.last_error && item.last_error.trim() !== '') && (
                                                                <Tooltip title="Score">
                                                                    <Box>{item.score}</Box>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            // ))
                                        ))}
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={9}><Box className={"text-center"}>There is no Data</Box></TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <DataTableColumnFilterPopover
                open={openFilterPop}
                anchorEl={fAnchorEl}
                handleClose={handleClose}
                handleSelection={(items) => {
                    console.log(items, 'items')
                }}
                hasFilter={false}
                direction={columnSort[openedColumn] || null}
                filterValues={[]}
                handleSortClick={(direction) => {
                    setColumnSort({
                        ...columnSort, [openedColumn]: direction
                    })
                }}
            />
        </>
    );
}
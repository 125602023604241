import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { paramsToQueryParam } from 'store/UrlUtils';
import API from 'store/services/AxiosService'
import axios from 'axios';
import {
  EOD_FETCH_FEED_SOURCES,
  EOD_FETCH_FEED_GROUPS,
  EOD_FETCH_STOCK_PRODUCTS,
  EOD_FETCH_FEED_SECTIONS
} from 'store/UrlConstants';
var CancelToken = axios.CancelToken;
let fetchFeedDatabaseCodeSource;
let fetchFeedSectionSource;
let fetchProductCodeSource;

const initialState = {
  sources: [],
  databaseCodes: [],
  isDatabaseCodesFull: false,
  filterDatabaseCodes: [],
  isFilterDatabaseCodesFull: false,
  sections: [],
  filteredSections: [],
  stockProducts: [],
  isStockProductsFull: false,
  eodData: [],
  feedSource: undefined,
  feedDatabaseCode: '',
  feedProductCode: '',
  feedSectionName: '',
  loadingInProgress: false,
  loadingFeedSources: false,
  loadingDatabaseCodes: false,
  loadingSections: false,
  topSearchWord: '',
  searchWords: {
    feedSource: '',
    databaseCode: '',
    productCode: ''
  },
  qaSearchWords: {
    feedSource: '',
    databaseCode: '',
    productCode: ''
  },
  qaSearchRows: [],
  filteredWords: {
    databaseCode: '',
    productCode: '',
  },
  step2Index: 0,
};

export const fetchFeedSources = createAsyncThunk('eod/fetch_feed_sources', async () => {
  const response = await API.get(EOD_FETCH_FEED_SOURCES);
  return response.data;
});
export const fetchFeedDatabaseCodes = createAsyncThunk('eod/fetch_feed_groups', async (params, thunkAPI) => {
  if (fetchFeedDatabaseCodeSource) {
    fetchFeedDatabaseCodeSource.cancel()
  }
  fetchFeedDatabaseCodeSource = CancelToken.source();
  try {
    const response = await API.get(EOD_FETCH_FEED_GROUPS + paramsToQueryParam(params), {
      cancelToken: fetchFeedDatabaseCodeSource.token
    });
    const { rows, search: { qaRows } } = response.data
    return {
      data: rows,
      searchWord: params?.searchWord || '',
      isDatabaseCodesFull: rows.length > 0 ? false : true,
      qaRows: qaRows,
    };
  } catch (e) {
    console.log(e, 'errr')
    return thunkAPI.rejectWithValue(e)
  }
});
export const fetchFeedSections = createAsyncThunk('eod/fetch_feed_sections', async (params, thunkAPI) => {
  if (fetchFeedSectionSource) {
    fetchFeedSectionSource.cancel()
  }
  fetchFeedSectionSource = CancelToken.source();
  try {
    const response = await API.get(EOD_FETCH_FEED_SECTIONS + paramsToQueryParam(params), {
      cancelToken: fetchFeedSectionSource.token
    });
    return {
      data: response.data,
      searchWord: params?.searchWord || ''
    };
  } catch (e) {
    console.log(e, 'errr')
    return thunkAPI.rejectWithValue(e)
  }
});
export const fetchStockProducts = createAsyncThunk('eod/fetch_stock_products', async (params, thunkAPI) => {
  if (fetchProductCodeSource) {
    fetchProductCodeSource.cancel()
  }
  fetchProductCodeSource = CancelToken.source();
  try {
    const response = await API.get(EOD_FETCH_STOCK_PRODUCTS + paramsToQueryParam(params), {
      cancelToken: fetchProductCodeSource.token
    });
    return {
      ...params,
      data: response.data
    };
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
});

const eodDataSlice = createSlice({
  name: 'eodData',
  initialState,
  reducers: {
    setFeedPath(state, action){
      state = {
        ...state,
        ...action.payload
      }
    },
    setFeedSource(state, action) {
      state.feedSource = action.payload
    },
    setFeedDatabaseCode(state, action) {
      state.feedDatabaseCode = action.payload
    },
    setFeedProductCode(state, action) {
      state.feedProductCode = action.payload
    },
    setFeedSectionName(state, action) {
      state.feedSectionName = action.payload
    },
    setProductData(state, action) {
      state.productName = action.product_code;
      state.timeIncrements = action.valid_times;
    },
    setTopSearchWord(state, action) {
      state.topSearchWord = action.payload
    },
    setSearchWord: (state, action) => {
      const { field, value } = action.payload;
      state.searchWords[field] = value
    },
    setQaSearchWord: (state, action) => {
      const { field, value } = action.payload;
      state.qaSearchWords[field] = value
    },
    setFilteredWord: (state, action) => {
      const { field, value } = action.payload;
      state.filteredWords[field] = value
    },
    clearStockProducts(state, action) {
      state.stockProducts = []
      state.isStockProductsFull = false
    },
    clearDatabaseCodes(state, action) {
      if (action?.payload?.flag === 'search') {
        state.filterDatabaseCodes = [];
        state.isFilterDatabaseCodesFull = false
        state.filteredWords.databaseCode = ''
      } else {
        state.databaseCodes = []
        state.isDatabaseCodesFull = false
      }
    },
    clearSections(state, action) {
      if (action?.payload?.flag === 'search') {
        state.filteredSections = [];
      } else {
        state.sections = []
      }
    },
    changeStep2Index(state, action) {
      state.step2Index = action.payload
    },
  },
  extraReducers: {
    [fetchFeedSources.pending]: (state, action) => {
      state.loadingFeedSources = true;
    },
    [fetchFeedSources.fulfilled]: (state, action) => {
      if (action.payload) {
        const _sources = action.payload?.sort()
        state.sources = [..._sources]
      }
      state.loadingFeedSources = false
    },
    [fetchFeedDatabaseCodes.pending]: (state, action) => {
      state.loadingDatabaseCodes = true;
    },
    [fetchFeedDatabaseCodes.fulfilled]: (state, action) => {
      const { data = [], qaRows = [], searchWord, isDatabaseCodesFull } = action.payload
      if (searchWord !== '') {
        if (isDatabaseCodesFull) {
          state.isFilterDatabaseCodesFull = isDatabaseCodesFull
        } else {
          if (searchWord === state.filteredWords.databaseCode) {
            state.filterDatabaseCodes = [...state.filterDatabaseCodes.concat([...data])]
          } else {
            state.filterDatabaseCodes = [...data]
            state.filteredWords.databaseCode = searchWord
          }
        }
        state.qaSearchRows = qaRows
      } else {
        if (isDatabaseCodesFull) {
          state.isDatabaseCodesFull = isDatabaseCodesFull
        } else {
          state.databaseCodes = [...state.databaseCodes.concat([...data])]
        }
      }
      state.loadingDatabaseCodes = false
    },
    [fetchFeedDatabaseCodes.rejected]: (state, action) => {
      state.loadingDatabaseCodes = false
    },
    [fetchFeedSections.pending]: (state, action) => {
      state.loadingSections = true;
    },
    [fetchFeedSections.fulfilled]: (state, action) => {
      const { data = [], searchWord } = action.payload
      if (searchWord && searchWord !== '') {
          state.filteredSections = [...data]
      } else {
        state.sections = [...data]
      }
      state.loadingSections = false
    },
    [fetchFeedSections.rejected]: (state, action) => {
      state.loadingSections = false
    },
    [fetchStockProducts.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchStockProducts.fulfilled]: (state, action) => {
      const { data } = action.payload
      if(action.payload.feed_source === state.feedSource && action.payload.database_code === state.feedDatabaseCode){
        if (data && Array.isArray(data)) {
          if (data.length < 1) {
            state.isStockProductsFull = true
          } else {
            const _products = [...state.stockProducts]
            state.stockProducts = [..._products.concat([...data])]
          }
        }
      }
      state.loadingInProgress = false
    },
    [fetchStockProducts.rejected]: (state, action) => {
      state.loadingInProgress = false
    }
  },
});

export const prepareSelector = state => state.eodData;

export const {
  setFeedSource,
  setFeedDatabaseCode,
  setFeedProductCode,
  setProductData,
  setTopSearchWord,
  setSearchWord,
  setQaSearchWord,
  clearStockProducts,
  clearDatabaseCodes,
  clearSections,
  changeStep2Index,
  setFeedPath,
  setFeedSectionName
} = eodDataSlice.actions
export default eodDataSlice.reducer;

import React, { useState } from 'react';
import clsx from 'clsx';

import _ from "underscore";
import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {makeStyles} from "@mui/styles";

import {
    MenuItem
} from "@mui/material";
import Draggable from 'react-draggable';
import MuiAlert from '@mui/lab/Alert';

import { setTimeRange } from 'store/slices/StockProductSlice'
import { blue } from '@mui/material/colors';


const useStyles = makeStyles((theme) => ({
    root:{
        listStyle: 'none',
        display: 'flex',
        margin: '0',
        position: 'absolute',
        top: '0',
        left: '-11px',
        zIndex: '1000',
        '& > li': {
            cursor: 'pointer',
            fontSize: '15px',
            borderRight: 'solid 1px #756c7c',
            padding: '0 15px',
            lineHeight: 1,
            fontWeight: 300,
            '&:last-child': {
                borderRight: 0
            },
            '&.selected':{
                color: 'blue',
                position: 'relative',
                '&::before': {
                    width: '80%',
                    bottom: '-8px',
                    left: 'calc(20%/2)',
                    height: '3px',
                    content: '""',
                    position: 'absolute',
                    background: '#1d8cf8',
                    borderTopRightRadius: '3px',
                    borderTopLeftRadius: '3px'
                }
            }
        }
    }
}))
const ChartTimeRanger = (props) => {
    const {
        timeRange
    } = props
    const classes = useStyles()
    const rangers = ['1d', '5d', '1m', '6m', 'ytd','1y', '5y', 'max']
    const handleRangeClick = (newVal) => {
        props.setTimeRange(newVal)
    }
    return (
        <ul className={classes.root}>
            {
                rangers.map((r, i) => (
                    <li key={i} onClick={() => handleRangeClick(r)} className={timeRange === r ? 'selected': ''}>{r.toUpperCase()}</li>
                ))
            }
        </ul>    
    );
  };

const mapStateToProps = (state) => ({
    user: state.user.user,
    productData: state.productData
});

const mapDispatchToProps = (dispatch) => ({
    setTimeRange: (payload) => dispatch(setTimeRange(payload))
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(ChartTimeRanger);
import React from "react";
import { BrowserRouter as Router, Switch} from "react-router-dom";
import {Route} from "react-router";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import {defaultRoutes, authRoutes} from "./routes"
import withTracker from "./withTracker"
import DefaultLayout from "./layouts/Default"
import AuthLayout from "./layouts/AuthLayout"

// import "bootstrap/dist/css/bootstrap.min.css";
// import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./styles/App.scss";
import './styles/index';
import theme from "./themeConfig";
import NotFound from "views/error/NotFound";
import CustomSnack from 'components/common/Base/CustomSnackbar'
import CustomAlertDialog from 'components/common/Base/CustomAlertDialog'

import ProductProvider from "providers/product/ProductProvider";
import AuthProvider from "providers/AuthProvider"

const App = () => {
    let mainPaths = []
    defaultRoutes.map(item => {
        mainPaths.push(item.path)
    })
    let authPaths = []
    authRoutes.map(item => {
        authPaths.push(item.path)
    })
    console.log("APP is deployed")

    return (
        <Router>
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <AuthProvider>
                        <Switch>
                            <Route exact component={AuthLayout} path={authPaths}>
                                {authRoutes.map((route, index) => {
                                    return (
                                        <Route
                                            key={index}
                                            path={route.path}// exact={route.exact}
                                            exact// componentDidMount={requireLogin()}
                                            component={withTracker(props => {
                                                return (
                                                    <route.layout navOpt={route.navigationOptions} {...props}>
                                                        <route.component {...props} />
                                                    </route.layout>
                                                )
                                            })}
                                        />
                                    )
                                })}
                            </Route>
                            <Route exact path={mainPaths}>
                                <DefaultLayout>
                                    {defaultRoutes.map((route, index) => {
                                        return (
                                            <Route
                                                key={index}
                                                path={route.path}// exact={route.exact}
                                                exact// componentDidMount={requireLogin()}
                                                component={withTracker(props => {
                                                    return (
                                                        // <route.layout navOpt={route.navigationOptions} {...props}>
                                                        <route.component {...props} />
                                                        // </route.layout>
                                                    )
                                                })}
                                            />
                                        )
                                    })}
                                </DefaultLayout>
                            </Route>
                            <Route
                                component={withTracker(props => {
                                    return (
                                        <DefaultLayout> <NotFound {...props} /> </DefaultLayout>
                                    )
                                })}
                            />
                        </Switch>
                    </AuthProvider>
                    <CustomAlertDialog />
                    <CustomSnack />
                </StyledEngineProvider>
            </ThemeProvider>
        </Router>
    )
}
export default App

import React from "react";
import FusionCharts from "fusioncharts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from "react-fusioncharts";

ReactFC.fcRoot(FusionCharts, TimeSeries);

const jsonify = res => res.json();
const dataFetch = fetch(
    "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/data/single-event-overlay-in-multivariate-data.json"
).then(jsonify);
const schemaFetch = fetch(
    "https://s3.eu-central-1.amazonaws.com/fusion.store/ft/schema/single-event-overlay-in-multivariate-schema.json"
).then(jsonify);

const dataSource = {
    chart: {},
    caption: {
        text: "Interest Rate vs Inflation Rate"
    },
    yaxis: [
        {
            plot: {
                value: "Interest Rate",
                type: "area"
            },
            title: "Interest Rate",
            format: {
                suffix: "%"
            }
        },
        {
            plot: {
                value: "Inflation Rate",
                type: "column"
            },
            title: "Inflation Rate",
            format: {
                suffix: "%"
            }
        }
    ],
    xaxis: {
        plot: "Time",
        timemarker: [
            {
                start: "Sep-1981",
                timeformat: "%b-%Y",
                label:
                    "Efforts by the FED ensured that inflation reduces from 10% to 5% gradually.",
                style: {
                    marker: {
                        fill: "#D083FF"
                    }
                }
            },
            {
                start: "Dec-1986",
                timeformat: "%b-%Y",
                label:
                    " “Inflation took it’s worst being in 25 years”, said the Labour Department",
                style: {
                    marker: {
                        fill: "#D083FF"
                    }
                }
            },
            {
                start: "Oct-1990",
                timeformat: "%b-%Y",
                label: "Inflation Rate moved out of the desired band of 4% - 6%",
                style: {
                    marker: {
                        fill: "#D083FF"
                    }
                }
            },
            {
                start: "Jul-1990",
                label:
                    "This eight month recession period {br} was characterized by a sluggish employment recovery, {br} most commonly referred to as a jobless recovery.",
                timeformat: "%b-%Y"
            },
            {
                start: "Jun-2004",
                label:
                    "The Fed after raising interest rates {br} at 17 consecutive meetings, ends its campaign {br} to slow the economy and forestall inflation.",
                timeformat: "%b-%Y"
            },
            {
                start: "Dec-2007",
                label:
                    "Recession caused by the worst {br} collapse of financial system in recent {br} times.",
                timeformat: "%b-%Y"
            }
        ]
    }
};

class TimeSeriesStockEventsChart extends React.Component {
    constructor(props) {
        super(props);
        this.onFetchData = this.onFetchData.bind(this);
        this.state = {
            timeseriesDs: {
                type: "timeseries",
                renderAt: "container",
                width: "100%",
                height: "500",
                dataSource
            }
        };
    }

    componentDidMount() {
        this.onFetchData();
    }

    onFetchData() {
        Promise.all([dataFetch, schemaFetch]).then(res => {
            const data = res[0];
            const schema = res[1];
            const fusionTable = new FusionCharts.DataStore().createDataTable(
                data,
                schema
            );
            const timeseriesDs = Object.assign({}, this.state.timeseriesDs);
            timeseriesDs.dataSource.data = fusionTable;
            this.setState({
                timeseriesDs
            });
        });
    }

    render() {
        return (
            <>
                {this.state.timeseriesDs.dataSource.data ? (
                    <ReactFC {...this.state.timeseriesDs} />
                ) : (
                    "loading"
                )}
            </>
        );
    }
}

export default TimeSeriesStockEventsChart;
import React, { useEffect, useState, useMemo } from "react";
// import { useSelector } from 'react-redux'

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import moment from 'moment'
import _ from 'underscore'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

import Highcharts from 'highcharts/highstock'
import StockData from 'highcharts/modules/data'
import PatternFill from 'highcharts/modules/pattern-fill'
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import ReactHighcharts from 'highcharts-react-official'
import { Box } from "@mui/system";
import { Settings } from "@mui/icons-material";
import ChartSettingDialog from "views/product-module/containers/Graph/Components/ChartSettingDialog";
StockData(Highcharts)
HighchartsMore(Highcharts);
PatternFill(Highcharts)
PatternFill(Highcharts)
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

function getRandomColor() {
  // Generate random values for red, green, and blue channels
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Combine the channels into a CSS color string
  const color = `rgb(${red}, ${green}, ${blue})`;

  return color;
}

function LineChart(props) {
  const { data: initialData, xAxis, yAxisFields, chartLines, chartLinesData } = props
  const [openSettingDialog, setOpenSettingDialog] = useState(false)
  const [periodType, setPeriodType] = useState('yearDuration');
  const [periodValue, setPeriodValue] = useState({
    from: '2010-01-01',
    to: new Date(),
    year: new Date()
  });

  const categories = useMemo(() => {
    let _categories = []
    if (periodType === 'yearDuration') {
      const _from = new Date(periodValue.from).getFullYear()
      const _to = new Date(periodValue.to).getFullYear()
      if (periodValue.from) {
        if (!periodValue.to) {
          _categories.push([_from])
        } else {
          for (let i = _from; i <= _to; i++) {
            _categories.push(i)
          }
        }
      }
    } else {
      _categories = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    }
    return _categories
  }, [
    periodType, periodValue
  ])
  const series = useMemo(() => {
    const _initialSeries = []
    const duelSeries = {}
    const _pFrom = new Date(periodValue.from).getFullYear()
    const _pTo = new Date(periodValue.to).getFullYear()
    const _pYear = new Date(periodValue.year).getFullYear()
    // const _yAxis = Array.isArray(yAxis) && yAxis[0] !== undefined ? yAxis[0] : yAxis

    if (!(xAxis && yAxisFields && yAxisFields.length > 0)) return [{}]
    const _data = initialData.sort((a, b) => {
      if (a[xAxis] && b[xAxis]) {
        return new Date(a[xAxis]).getTime() - new Date(b[xAxis]).getTime()
      }
      return 1;
    })
    if (chartLines?.length > 0) {
      _data.map(item => {
        let _dateVal
        if (periodType === 'yearDuration') {
          _dateVal = new Date(item[xAxis]).getTime()
        } else {
          _dateVal = new Date(item[xAxis]).getMonth()
          _dateVal = categories[_dateVal]
        }
        // let dateVal = isNaN(item[_yAxis] * 1) ? null : item[_yAxis] * 1
        chartLines.map((chartLineId, idx) => {
          const chartLine = chartLinesData?.filter(item => item.id === chartLineId)[0]
          if (chartLine) {
            if (item[chartLineId]) {
              if (duelSeries[chartLineId] === undefined) {
                duelSeries[chartLineId] = {
                  name: chartLine.label.toUpperCase(),
                  color: getRandomColor(),
                  data: []
                }
              }
              const duelPoint = [_dateVal, isNaN(item[chartLineId]) ? 0 : item[chartLineId]]
              duelSeries[chartLineId].data.push(duelPoint)
            }
          }
        })
      })
    } else {
      _data.map(item => {
        let _x
        if (periodType === 'yearDuration') {
          _x = new Date(item[xAxis]).getTime()
        } else {
          _x = new Date(item[xAxis]).getMonth()
          _x = categories[_x]
        }
        // const _chkIdx = _initialSeries[item[groupByField]].findIndex(item => item.x === _x)
        // if(_chkIdx > -1){
        //   _initialSeries[item[groupByField]][_chkIdx].y += item[_yAxis] * 1 
        // }else{

        // if (!isNaN(item[_yAxis] * 1) && item[_yAxis] !== null) {
        //   _initialSeries.push({
        //     x: _x,
        //     y: isNaN(item[_yAxis] * 1) ? null : item[_yAxis] * 1,
        //   })
        // }
        yAxisFields.map((_yAxis, idx) => {
          if (item[_yAxis]) {
            if (duelSeries[_yAxis] === undefined) {
              duelSeries[_yAxis] = {
                name: _yAxis.toUpperCase(),
                color: getRandomColor(),
                data: []
              }
            }
            const duelPoint = [_x, isNaN(item[_yAxis]) ? 0 : item[_yAxis]]
            duelSeries[_yAxis].data.push(duelPoint)
          }
        })
        // }
      })
      // console.log(_initialSeries, '_initialsereis')
      // let _series = {}
      // for(let key in _initialSeries){
      //   if(!_series[key]) {
      //     _series[key] = {
      //       name: key,
      //       data: []
      //     }
      //   }
      //   categories.map(ca => {
      //     let _chk = _initialSeries[key].filter(item => item.x == ca)[0]
      //     if(_chk){
      //       _series[key].data.push(_chk.y)
      //     }else{
      //       _series[key].data.push(null)
      //     }
      //   })
      // }
      // const _values = _.values(_series)
      // return _values
      // console.log(_data, '_data')
      // console.log(_yAxis, xAxis, _initialSeries, '_initialSeries')
    }
    console.log(duelSeries, 'duelSeries')
    console.log(initialData, 'initialData')
    console.log(xAxis, yAxisFields, 'yAxisFields')
    return Object.values(duelSeries)

  }, [
    periodType,
    periodValue,
    categories,
    initialData,
    yAxisFields,
    xAxis,
    chartLines,
    chartLinesData
  ])
  const configStock = useMemo(() => {
    return {
      chart: {
        type: 'line',
        zoomType: 'x'
      },
      xAxis: {
        // categories: categories,
        crosshair: true,
        type: "datetime",
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b/%e/%Y', this.value);
          }
        }
      },
      yAxis: {
        min: 0,
      },
      stockTools: {
        gui: {
          enabled: false,
          buttons: [
            // 'typeChange',
            // 'separator',

            // 'thresholds',
            // 'separator',
            // 'indicators',
            // 'separator',
            // 'simpleShapes',
            // 'lines',
            // 'crookedLines',
            // 'measure',
            // 'advanced',
            // 'toggleAnnotations',
            // 'separator',
            // 'verticalLabels',
            // 'flags',
            // 'separator',
            // 'zoomChange',
            // 'fullScreen',
            // 'separator',
            // 'currentPriceIndicator',
            // 'saveChart'
          ]
        }
      },
      plotOptions: {
        line: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: series
    }
  }, [categories, series])

  return (
    <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
      <Box display="flex" sx={{ flexDirection: 'column', height: '100%' }}>
        {/* <Grid container spacing={2}>
          <Grid item container lg={12} md={12} xs={12} spacing={2}>
            <Grid item lg={2} md={2} xs={12}>
              <Box display="flex" justifyContent="end">
                <Tooltip title="Chart Settings">
                  <IconButton onClick={() =>{
                    setOpenSettingDialog(true)
                  }}>
                    <Settings />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
        <Box flex={1}>
          <ReactHighcharts
            highcharts={Highcharts}
            constructorType={"chart"}
            options={configStock}
            containerProps={{ style: { height: "100%" } }}
          />
        </Box>
        {
          openSettingDialog && (
            <ChartSettingDialog
              open={openSettingDialog}
              handleClose={() => {
                setOpenSettingDialog(false)
              }}
            />
          )
        }
      </Box>
    </LocalizationProvider>
  )
}

export default LineChart
import React, {useEffect, useState } from "react";


import { Box } from "@mui/material"
import Chart from 'kaktana-react-lightweight-charts'
import TradingViewWidget from 'react-tradingview-widget';

const TradingViewEmbedder = (props) => {
    const [configs, setConfigs] = useState({
        "autosize": true,
        "symbol": null,
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "Light",
        "style": "1",
        "locale": "en",
        "toolbar_bg": "#f1f3f6",
        "enable_publishing": false,
        "withdateranges": true,
        "hide_side_toolbar": false,
        "allow_symbol_change": false,
    })
    useEffect(() => {
        setConfigs({
            ...configs, symbol: `${decodeURIComponent(props.feedSource)}/${decodeURIComponent(props.databaseCode)}/${decodeURIComponent(props.productCode)}`
        })
    }, [
        props.feedSource,
        props.databaseCode,
        props.productCode,
    ])
    
    return <>
        {
            configs.symbol == null ? (
                <></>
            ) : (
                <Box height={600}>
                    <TradingViewWidget {...configs}/>
                </Box>
            )
        }
    </>

}

export default TradingViewEmbedder
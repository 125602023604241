import React from "react";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
    "error-mark": {
        height: '115px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center'
    },
    warning: {
        position: 'relative',
        padding: 0,
        margin: 0,
        position: 'relative',
        display: 'inline-block',
        fontSize: '6em',
        top: '0.225em',
        width: '1.15em',
        height: '1.15em',
        overflow: 'hidden',
        border: 'none',
        backgroundColor: 'transparent',
        borderRadius: '0.625em',
        '&::before': {
            content: '""',
            // content: '"\f071"',
            display: 'block',
            top: '-0.08em',
            left: '0.0em',
            position: 'absolute',
            border: `transparent 0.6em solid`,
            borderBottomColor: theme.palette.warning.main, // Adjust with your theme color
            borderBottomWidth: '1em',
            borderTopWidth: 0,
            boxShadow: `${theme.palette.grey[500]} 0 1px 1px`, // Adjust with your theme color
        },
        "&::after": {
            display: 'block',
            position: 'absolute',
            top: '5px',
            left: 1,
            width: '100%',
            padding: '0 1px',
            textAlign: 'center',
            fontFamily: 'Garamond',
            content: '"!"',
            fontSize: '0.65em',
            fontWeight: 'bold',
            color: 'white', // Adjust with your theme color
        }
    },

}))
const ErrorMark = () => {
    const classes = useStyles()
    return (
        <div className={classes["error-mark"]}>
            <div className={`${classes.warning}`}>
            </div>
        </div>
    );
};

export default ErrorMark;

import React, { useCallback, useEffect, useState } from 'react'
import _ from "underscore"

import { useParams } from 'react-router-dom'

import { useSelector } from 'react-redux';
import { selectProductDataByRawPath } from 'store/selectors/ProductData';
import TransformContainer from '../TransformContainer';
import { sortData, customizeData } from '../utils';

function IODataTableView(props) {
    const { feedSource, databaseCode, productCode = '' } = useParams()
    const { blob } = props

    const rawData = useSelector((state) => selectProductDataByRawPath(state, {
        feedSource,
        databaseCode,
        productCode,
        blob
    }))
    const displaySchemas = useSelector(state => state.productData.displaySchemas)

    const [isInitialized, setIsInitialized] = useState(false)
    const [productResource, setProductResource] = useState({
        years: [],
        lines: [],
        data: []
    })
    // const [formattedData, setData] = useState([])

    const customizeDataMemoized = useCallback(customizeData, [])
    useEffect(() => {
        setIsInitialized(false)
    }, [
        feedSource,
        databaseCode,
        productCode,
        blob
    ])
    useEffect(() => {
        if (!isInitialized) {
            let _years = [];
            let _formattedData = {}
            let _lines = []
            if (Object.keys(displaySchemas).length > 0) {
                for (let schemaKey in displaySchemas) {
                    const displaySchema = displaySchemas[schemaKey]
                    if (Object.keys(displaySchema).length > 0) {
                        for (let _lineLabel in displaySchema) {
                            const _line = displaySchema[_lineLabel] || {}
                            if (['FLOAT', 'NUMBER', 'INTEGER'].indexOf(_line.type) > -1 && _lines.findIndex(item => item.id === _lineLabel) < 0) {
                                const _lineKey = _lineLabel?.toLocaleLowerCase()?.split(" ")?.join('_')
                                const _lineParents = _line?.parents || []
                                _lines.push({
                                    id: _lineLabel,
                                    label: _lineLabel,
                                    column: _lineKey + '_value',
                                    parentId: _lineParents.length > 0 ? _lineParents[_lineParents.length - 1] : null,
                                    additionalSortKey: _lineParents.length
                                })
                            }
                        }
                    }
                }
            }

            if (Array.isArray(rawData)) {
                const { years: rYears, rows } = customizeDataMemoized(rawData);
                _years = [...new Set(rYears)];
                _formattedData = rows
                // _lines = lines
            } else {
                for (let path in rawData) {
                    const { years: rYears, rows } = customizeData(rawData[path], 'time_period_start');
                    _years = [...new Set(_years.concat(rYears))];
                    _formattedData[path] = rows
                }
            }
            setProductResource({
                years: _years.sort((a, b) => b - a),
                data: _formattedData,
                lines: sortData(_lines)
            });
            setIsInitialized(true)
        }
    }, [
        rawData,
        displaySchemas,
        isInitialized
    ]);
    console.log(rawData, 'rawData')
    console.log(productResource, 'productResource')

    return (
        <>
            <TransformContainer
                {...props}
                productResource={productResource}
            />
        </>
    )
}

export default IODataTableView
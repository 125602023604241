import React from "react";

import {
    LineSeries,
} from "libs/rrag/lib/series";
import {
    CurrentCoordinate
} from "libs/rrag/lib/coordinates";
import {
    MovingAverageTooltip,
} from "libs/rrag/lib/tooltip";

export default function MovingAverage(props) {
    let maToolTipOptions = [];
    props.maIndicatorEdges.map(edge => {
        const edgeCalculator = edge['edgeCalc']
        maToolTipOptions.push({
            yAccessor: edgeCalculator.accessor(),
            type: edge.edgeName,
            stroke: edgeCalculator.stroke(),
            windowSize: edgeCalculator.options().windowSize,
            echo: "some echo here",
        })
    })
    return (
        <>
            {
                props.maIndicatorEdges.map((edge, index) => {
                    const edgeCalculator = edge['edgeCalc']
                    return <>
                        <LineSeries yAccessor={edgeCalculator.accessor()} stroke={edgeCalculator.stroke()} strokeWidth={2}/>
                        <CurrentCoordinate yAccessor={edgeCalculator.accessor()} fill={edgeCalculator.stroke()}/>
                    </>
                })
            }
            <MovingAverageTooltip
                onClick={e => {}}
                origin={[2, 15]}
                options={maToolTipOptions}
                {...props.appearance}
            />
        </>
    );
}
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from "underscore"

import { makeStyles } from "@mui/styles";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBeaMneSeries } from 'store/slices/StockProductSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 22,
        '& .MuiFormControlLabel-root': {
            margin: 0,
            textTransform: 'capitalize',
            '& .MuiButtonBase-root': {
                padding: 4,
            }
        }
    },
}))

function InvestmentStep(props) {
    const classes = useStyles()
    const { selectedInvestment, onChange, selectedDirection, onChangeDirection } = props
    const investments = useSelector(state => state.productData.beaMneInvestments)
    const directions = useSelector(state => state.productData.beaMneDirections)

    const onValueChange = useCallback((e) => {
        onChange(e.target.value)
    }, [
        onChange
    ])
    const onDirectionValueChange = useCallback((e) => {
        onChangeDirection(e.target.value)
    }, [
        onChange
    ])

    return <>
        <Box className={classes.root}>
            <Grid container spacing={1}>
                <Grid item lg={6} md={6} xs={12}>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Investments *</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={selectedInvestment || ''}
                            name="radio-buttons-group"
                            onChange={onValueChange}
                        >
                            {
                                investments?.map((item, idx) => {
                                    return <FormControlLabel key={idx} value={item.investment} control={<Radio />} label={item.investment.trim()} />
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Directions *</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={selectedDirection || ''}
                            name="radio-buttons-group"
                            onChange={onDirectionValueChange}
                        >
                            {
                                directions?.map((item, idx) => {
                                    return <FormControlLabel key={idx} value={item.direction} control={<Radio />} label={item.direction.trim()} />
                                })
                            }
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    </>
}

export default InvestmentStep
import React, { useState } from 'react'
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';

import axios from 'axios';
import API from 'store/Api'

import {makeStyles} from "@mui/styles";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Grid, Paper, Box, TextField } from "@mui/material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import HomeIcon from '@mui/icons-material/Home';
import { Typography } from 'antd';
import { Button } from 'react-bootstrap';
import { blue } from '@mui/material/colors';

import ErrorBoundary from 'shared/system/error/ErrorBoundary';
import { StyledBreadcrumb } from 'components/common/Breadcrumb'
import { showSnackbarMessage, showAlertDialog } from 'store/slices/MessagesSystemSlice';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
//   textAlign: 'center',
  color: theme.palette.text.secondary,
//   height: 60,
//   lineHeight: '60px',
  padding: 30,
}));

const useStyles = makeStyles((theme) => ({
    TextFieldItem: {
        marginBottom: 10,
    },
    AddDataSet: {
        background: blue[400],
        '&:hover, &:active, &:focus, &:active:focus': {
            backgroundColor: `${blue[500]} !important`,
            transform: 'initial !important',
            backgroundImage: 'unset !important'
        }
    }
}));
const UrlModule = () => {
    const classes = useStyles()
    const history = useHistory();
    const dispatch = useDispatch()
    const [dataset, setDataset] = useState({
        dataset_url: '',
        dataset_name: '',
        sub_table: '',
        table_name: '',
    })
    const sendDataSet = async () => {
        try{
            console.log(dataset, 'add dataset')
            if(dataset.dataset_url == '') return
            if(dataset.dataset_name == '') return
            const params = {
                url: dataset.dataset_url,
                database_code: dataset.dataset_name,
            }
            if(dataset.sub_table !== '') params.product_code = dataset.sub_table
            if(dataset.table_name !== '') params.split_by = dataset.table_name
            const { data: res } = await API.post('api/eod/add_dataset', params)
            if(res && res.code === 400){
                dispatch(showSnackbarMessage({
                    message: res.message,
                    severity: 'warning'
                }))  
            }else if(res && res.code === 200){
                dispatch(showSnackbarMessage({
                    message: 'Successfully Added',
                    severity: 'success'
                }))
                let url  = `/product/detail/${encodeURIComponent('URL')}/${encodeURIComponent(params.database_code)}/`
                if(dataset.sub_table !== ''){
                    url += `${encodeURIComponent(params.product_code)}`;
                }else{
                    url += `${encodeURIComponent(params.database_code)}`;
                }
                history.push(url)
            }
        }catch (e){
            dispatch(showSnackbarMessage({
                message: 'Something went wrong',
                severity: "error"
            }))
            console.log(e, 'error in adding dataset')
        }
    }
    return (
        <ErrorBoundary>
            <Breadcrumbs aria-label="breadcrumb">
                <Link to={'/home'}>
                    <StyledBreadcrumb
                        component='a'
                        href='#'
                        label="Home"
                        icon={<HomeIcon fontSize="small" />}
                    />
                </Link>
                <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="URL OPTION"
                />
            </Breadcrumbs>
            <Box maxWidth={500} mr={'auto'} mt={4} ml={'auto'} p={2}>
                <Item elevation={6}>
                    <Grid container>
                        <Grid item lg={12} md={12} xs={12} className={classes.TextFieldItem}>
                            <Typography>Enter DataSet URL</Typography>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='dataset_url'
                                value={dataset?.dataset_url || ''}
                                onChange={(e) => {
                                    setDataset({
                                        ...dataset, dataset_url: e.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} className={classes.TextFieldItem}>
                            <Typography>Enter Dataset Name</Typography>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='dataset_name'
                                value={dataset?.dataset_name || ''}
                                onChange={(e) => {
                                    setDataset({
                                        ...dataset, dataset_name: e.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} className={classes.TextFieldItem}>
                            <Typography>Enter Sub Table Name</Typography>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='sub_table'
                                value={dataset?.sub_table || ''}
                                onChange={(e) => {
                                    setDataset({
                                        ...dataset, sub_table: e.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} className={classes.TextFieldItem}>
                            <Typography>Enter Table Name</Typography>
                            <TextField
                                fullWidth
                                variant='outlined'
                                size='small'
                                name='table_name'
                                value={dataset?.table_name || ''}
                                onChange={(e) => {
                                    setDataset({
                                        ...dataset, table_name: e.target.value
                                    })
                                }}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <Box display={'flex'} sx={{ justifyContent: 'center' }}>
                                <Button className={classes.AddDataSet} onClick={sendDataSet}>Add DATASET</Button>
                                {/* <Button className={classes.AddDataSet} onClick={() => {
                                    // dispatch(showAlertDialog({
                                    //     title: "Test Popup",
                                    //     message: <Box width={200}>Hi</Box>,
                                    //     buttonLabel: {  },
                                    //     dialogAction: "TestDialogAction",
                                    //     data: { id: 'testData' }
                                    // }))
                                    dispatch(showSnackbarMessage({
                                        message: 'Something went wrong',
                                        severity: "error"
                                    }))
                                }}>Add DATASET</Button> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Item>
            </Box>
        </ErrorBoundary>
    )
}

export default UrlModule;
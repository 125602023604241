import React, {useMemo, useState} from 'react';
import { makeStyles } from "@mui/styles";

import {
  Grid,
} from "@mui/material";
import ExpandablePanelBox from "components/common/Base/ExpandablePanelBox";
import {
  TextFeature,
  Appearance,
  HorizontalAxis,
  VerticalAxis,
  TooltipFeature,
  LegendFeature,
  DataLabelsFeature,
  AnnotationTextLabels,
  AnnotationHighlights,
  AnnotationLines
} from "./features";
import {SeriesSpecificType} from "./features/SeriesSpecificType";
import {useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '.75rem'
  }
}));

export const BasicCustomizePanel = () => {
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState('series1')
  const series = useSelector(state => state.productData.series)

  const chartType = useMemo(() => {
    const idx = series?.findIndex(item => item.id === 'default')
    if(idx > -1){
      return series[idx]?.chartType || 'line'
    }
    return 'line'
  }, [
    series
  ])


  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item lg={12} md={12} xs={12}>
        <TextFeature />
        {
          chartType !== 'pie' && (
            <>
              <Appearance />
              <ExpandablePanelBox panel={{label: 'Axes'}} collapsed={true}>
                <HorizontalAxis />
                <VerticalAxis />
                {/*<BaseAccordion*/}
                {/*  seriesLabel="Second Axis"*/}
                {/*  isExpanded={expandedPanel === 'second-axis'}*/}
                {/*  className={classes.AccordionItem}*/}
                {/*  onChange={() => onPanelChange('second-axis')}*/}
                {/*>*/}
                {/*  <h1>Second Axis</h1>*/}
                {/*</BaseAccordion>*/}
              </ExpandablePanelBox>
            </>
          )
        }

        <TooltipFeature />
        <ExpandablePanelBox panel={{label: 'Chart Type Specific'}} collapsed={true}>
          <SeriesSpecificType />
        </ExpandablePanelBox>
        {
          chartType !== 'pie' && (
            <ExpandablePanelBox panel={{label: 'Legend'}} collapsed={true}>
              <LegendFeature />
            </ExpandablePanelBox>
          )
        }
        <ExpandablePanelBox panel={{label: 'Data labels'}} collapsed={true}>
          <DataLabelsFeature />
        </ExpandablePanelBox>
        {
          chartType !== 'pie' && (
            <ExpandablePanelBox panel={{label: 'Annotations'}} collapsed={false}>
              <AnnotationTextLabels />
              <AnnotationHighlights />
              <AnnotationLines />
            </ExpandablePanelBox>
          )
        }
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Text"*/}
        {/*  isExpanded={expandedPanel === 'text'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('text')}*/}
        {/*>*/}
        {/*  <h1>Text</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Appearance"*/}
        {/*  isExpanded={expandedPanel === 'appearance'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('appearance')}*/}
        {/*>*/}
        {/*  <h1>Appearance</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Axes"*/}
        {/*  isExpanded={expandedPanel === 'axes'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('axes')}*/}
        {/*>*/}
        {/*  <h1>axes</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="ToolTip"*/}
        {/*  isExpanded={expandedPanel === 'toolTip'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('toolTip')}*/}
        {/*>*/}
        {/*  <h1>toolTip</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Legend"*/}
        {/*  isExpanded={expandedPanel === 'legend'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('legend')}*/}
        {/*>*/}
        {/*  <h1>legend</h1>*/}
        {/*</BaseAccordion>*/}
        {/*<BaseAccordion*/}
        {/*  seriesLabel="Data labels"*/}
        {/*  isExpanded={expandedPanel === 'data-labels'}*/}
        {/*  className={classes.AccordionItem}*/}
        {/*  onChange={() => onPanelChange('data-labels')}*/}
        {/*>*/}
        {/*  <h1>Data labels</h1>*/}
        {/*</BaseAccordion>*/}
      </Grid>
    </Grid>
  )
};

import React from "react";

import {
    RSISeries
} from "../../../../../libs/rrag/lib/series";
import {
    RSITooltip
} from "../../../../../libs/rrag/lib/tooltip";

export default function RsiIndicator(props) {
    const {indicator} = props

    const rsiAppearance = {
        ...props.seriesApperance,
        stroke: {
            line: indicator?.stroke?.line ? indicator?.stroke?.line : "#FF0000",
            top: props.seriesApperance?.stroke?.top || "black",
            middle: props.seriesApperance?.stroke?.middle || "black",
            bottom: props.seriesApperance?.stroke?.bottom || "black",
            outsideThreshold: "#b300b3",
            insideThreshold: "#ffccff",
        },
        strokeWidth: {
            outsideThreshold: 2,
            insideThreshold: 2,
            top: 1,
            middle: 1,
            bottom: 1,
        }
    };
    return (
        <>
            <RSISeries yAccessor={d => d.rsi} {...rsiAppearance}/>

            <RSITooltip origin={[-38, 15]}
                        yAccessor={d => d.rsi}
                        options={indicator['calc'].options()} {...props.toolTipApperance} />
        </>
    );
}
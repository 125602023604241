import React from 'react'
import { SvgIcon } from "@mui/material";

const DashboardIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="32" height="32" viewBox="0 0 32 32" fill="none"
    >
      <path d="M22.5 9.5V22.5M16 14.375V22.5M9.5 19.25V22.5M6.25 29H25.75C27.5449 29 29 27.5449 29 25.75V6.25C29 4.45507 27.5449 3 25.75 3H6.25C4.45507 3 3 4.45507 3 6.25V25.75C3 27.5449 4.45507 29 6.25 29Z" stroke="#F5F8FF" strokeWidth="2.67" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
    // fill="none" xmlns="http://www.w3.org/2000/svg"
  );
};

export default DashboardIcon;

import React, { useCallback, useEffect, useRef, useState } from 'react';
// import {useParams} from "react-router-dom"
// import { useHistory } from "react-router-dom"
// import { clearDatabaseCodes, fetchFeedDatabaseCodes } from 'store/slices/EodDataSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { Box, Typography } from "@mui/material";
// import ListItem from './Details/ListItem';
import StockDatabaseCodeQA from './StockDatabaseCodesQA';
import StockDatabaseCodes from './StockDatabaseCodes';

export default function StockDatabaseCodeContainer({
    feedSource,
    searchWord,
    step2Index,
    changeStep2Index,
    isQa
}) {
    const dispatch = useDispatch();
    
    
    return (
        <>
            {
                !isQa ? 
                    <StockDatabaseCodes
                        changeStep2Index={changeStep2Index}
                        feedSource={feedSource}
                        searchWord={searchWord}
                    /> : 
                    <StockDatabaseCodeQA
                        changeStep2Index={() => {
                            // changeStep2Index()
                        }}
                        feedSource={feedSource}
                        searchWord={searchWord}
                    />
            }
        </>
    );
}
import React  from "react";

import {makeStyles} from "@mui/styles";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import { StyledBreadcrumb } from 'components/common/Breadcrumb'
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';

import DataFeeds from "./DetailView/DataFeeds";
import AcpLayoutImg from 'assets/images/home/acp-layouts.png'

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        color: 'black',
        padding: '23px 20px'
    },
    section1: {
        display: 'flex',
    },
    SearchBox: {
        marginTop: '50px',
        maxWidth: '422px',
        '& h1': {
            color: '#616161',
            marginBottom: '13px',
            fontFamily: 'Trebuchet MS,roboto,ubuntu,sans-serif',
            fontSize: '40px',
            textTransform: 'uppercase'
        },
        '& h2':{
            color: '#616161',
            color: '#616161',
            marginTop: 10,
            textTransform: 'uppercase',
            marginBottom: 5
        }
    },
    SearchBar: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '40px',
        padding: '16px',
        boxShadow: '0 0 5px -2px black',
        color: 'black',
        fontSize: '21px',
        paddingLeft: '10px',
        transition: 'all ease-in .3s',
        '&:hover': {
            transform: 'scale(1.03)'
        },
        '& svg': {
            padding: '4px',
            background: '#5e72e4',
            color: 'white',
            borderRadius: '50%',
            height: '38px',
            width: '38px',
            margin: '0 10px'
        }
    },
    homeBannerImg: {
        maxWidth: '709px',
        // marginLeft: 'auto',
        marginTop: '37px'
    }
}))

/* Home component */
function Home(props){
    const classes = useStyles()

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Box className={classes.root}>
            <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="Home"
                    icon={<HomeIcon fontSize="small" />}
                />
            </Breadcrumbs>
            <div className={classes.section1}>
                <div className={classes.homeBannerImg}>
                    <h2></h2>
                    <img src={AcpLayoutImg} />
                </div>
                <div className={classes.SearchBox}>
                    <h1>Better Charting...</h1>
                    <p>
                        Plotter delivers the charts, tools and resources you need to succeed in the markets. 
                    </p>
                    {/* <h2>Look For</h2>
                    <a href="#" className={classes.SearchBar} onClick={handleClickOpen}>
                        <SearchIcon />
                        <span>Search Markets here...</span>
                    </a> */}
                </div>
            </div>
            {/* <div>
                <DataFeeds />
            </div> */}
            {/* <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                maxWidth={'md'}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Filter Market Data</DialogTitle>
                <DialogContent>
                    <DataFeeds />
                </DialogContent>
            </Dialog> */}
        </Box>
    )
}
export default Home;
import React  from "react";
import {
    NavLink
} from "react-router-dom";

import '../../styles/custom/auth/login.scss';
import DemoPage from '../../assets/images/auth/demo.png';
/* Home component */
class Demo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render(){
        return <div className='demo-page'>
            <div className='demo-page-inner'>
                <NavLink to='/login' className='btn btn-info demo-back-link'>Back</NavLink>
                <div className='demo-header'>
                    <h1>Demo</h1>
                </div>
                <div className='demo-body'>
                    <img src={DemoPage} />
                </div>
            </div>
        </div>;
    }
}

export default Demo;
const {timeParse} = require("d3-time-format")

const parsableFormats = ["%Y-%m-%d", '%d%m%Y','%Y%m%d']
const utils = {
    getFieldType(arr, operator, value){

    },
    getSafe(fn, defaultValue) {
        try{
            return fn()
        }catch(e){
            return defaultValue
        }
    },
    replaceSpace(obj) {
        Object.keys(obj).forEach(function(key){
        var newKey = key.trim();
        obj[newKey] = obj[key]
        if(key !== newKey)
            delete obj[key]
        })
        return obj
    },
    parseData(parse) {
        return function(d) {
            d.date = parse(d.date);
            d.open = +d.open;
            d.high = +d.high;
            d.low = +d.low;
            d.close = +d.close;
            d.volume = +d.volume;
            return d;
        };
    },
    isDate(val){
        let flag = false
        if (isNaN(val)) {
            flag = !isNaN(Date.parse(val))
        }
        return flag
    },
    isEmpty(val){
        // const emptyOpts = ['', null, 'null', 'undefined', undefined, 0, '0']
        const emptyOpts = [null, 'null', 'undefined', undefined]
        return emptyOpts.indexOf(val) > -1
    },
    evaluateDefaultSetting(config, field){
        let defaultField = null
        for(let c of config){
            if(defaultField) break;
            for(let opt of c.options){
                if(c.operator === 'ends_with'){
                    const regx = new RegExp(opt+"$");
                    if(regx.test(field)){
                        defaultField = field
                        break;
                    }
                }else if(c.operator === 'contains'){
                    // console.log(field, opt, 'opt')
                    if(field.indexOf(opt) > -1) {
                        defaultField = field
                        break;
                    }
                }
            }
        } 
        return defaultField
    },
    attributeTransformer(arr){
        const fields = [];
        if(Array.isArray(arr) && arr[0] !== undefined){
            const keys = Object.keys(arr[0])
            for(let item of arr){
                if(fields.length === keys.length) break;
                for(const col in item){
                    const value = item[col]
                    if(col === 'nId' || this.isEmpty(value) || fields.find(f => f.id === col) !== undefined) continue;
                    let fieldType = typeof value;
                    if(col === 'date' || col === 'timestamp'){
                        fieldType = 'date'
                    } else if(fieldType === 'object' && value instanceof Date){
                        fieldType = 'date'
                    }else if(!isNaN(value)) {
                        const valueStr = value.toString();
                        const isPossibleTimestamp = (valueStr.length > 9);
                        if(isPossibleTimestamp){
                            const minTimestamp = new Date('1970-01-01').getTime();
                            const maxTimestamp = new Date('2100-01-01').getTime();
                            console.log(value, col, maxTimestamp, minTimestamp)
                            if (value >= minTimestamp && value <= maxTimestamp) {
                                fieldType = 'datenumber';
                            }else{
                                fieldType = 'number';
                            }
                        }
                    }else if(this.isDate(value)) {
                        fieldType = 'date'
                    }else if(!isNaN(Date.parse(value))){
                        fieldType = 'date'
                    }
                    fields.push({
                        id: col, type: fieldType
                    })
                }
            }
        }
        return {
            fields
        }
    },
    checkIfDateSensible(date){
        return (
            date.getFullYear() > 1971 && // Below this its likely a number
            date.getFullYear() < 2050 // Likely read a year from something that was not a year
        )
    },
    parsePotentialData(value){
        if (isNaN(Date.parse(value))) {
            try {
                const fDate = new Date(value)
                if (this.checkIfDateSensible()) {
                    return fDate
                }
            } catch (error) {
            }
            for (let id in parsableFormats){
                try {
                    let parsingFunction = timeParse(parsableFormats[id])
                    let potential = parsingFunction(value)
                    if (this.checkIfDateSensible(potential)) {
                        return potential
                    }
                } catch (error){
                }
            }
        }
        return value
    }
}
export default utils

// Fonction qui prend un nom de parametre et un array de valeurs et retourne le tout en query string
export function listToQueryParam(paramName, values, queryString = true) {
    return (queryString ? '?' : '') + values.map(function (value) { return `${paramName}=` + value; }).join('&');
}

// Fonction qui prend un objet de clés:valeurs et retourne un query string
// Supporte les arrays via listToQueryParam
export function paramsToQueryParam(params) {
    let queryString = [];
    for (let [key, value] of Object.entries(params)) {
        if(Array.isArray(value)) {
            queryString.push(listToQueryParam(key, value, false));
        } else {
            queryString.push(`${key}=` + value);
        }
    }
    return '?' + queryString.join('&');
}

export function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}
import React from "react";

//import reducer
import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import TimeSeriesChart from "../components/common/Fusion/TimeSeriesChart";
import Pie3dChart from "../components/common/Fusion/Pie3dChart";
import Column2dChart from "../components/common/Fusion/Column2dChart";
import PlottedSeriesChart from "../components/common/Fusion/PlottedSeriesChart";
import TimeSeriesLineChart from "../components/common/Fusion/TimeSeriesLineChart";
import TimeSeriesColumnChart from "../components/common/Fusion/TimeSeriesColumnChart";
import TimeSeriesAreaChart from "../components/common/Fusion/TimeSeriesAreaChart";
import TimeSeriesDashedLineChart from "../components/common/Fusion/TimeSeriesDashedLineChart";
import TimeSeriesStepLineChart from "../components/common/Fusion/TimeSeriesStepLineChart";
import TimeSeriesCandelChart from "../components/common/Fusion/TimeSeriesCandelChart";
import TimeSeriesCandelOhlcChart from "../components/common/Fusion/TimeSeriesCandelOhlcChart";
import TestChart from "../components/common/Fusion/TestChart";
import TimeSeriesStockEventsChart from "../components/common/Fusion/TimeSeriesStockEventsChart";

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.renderChart = this.renderChart.bind(this);
    }
    renderChart(){
        const {chartType, plotType, theme, bgColor, timeseriesPatternColor} = this.props.chartConfig;
        if (chartType === 'column2d'){
            return <Column2dChart
                theme={theme}
                bgColor={bgColor}
            />;
        }else if(chartType === 'pie3d'){
            return <Pie3dChart
                theme={theme}
                bgColor={bgColor}
            />;
        }else if(chartType === 'timeseries'){
            /*return <TimeSeriesChart
                theme={theme}
                bgColor={bgColor}
                plotType={plotType}
            />;*/
            if (plotType === 'area'){
                return <TimeSeriesAreaChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor}
                />
            }else if(plotType === 'column'){
                return <TimeSeriesColumnChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor} />
            }else if(plotType === 'dashed-line'){
                return <TimeSeriesDashedLineChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor} />
            }else if(plotType === 'step-line'){
                return <TimeSeriesStepLineChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor} />
            }else if(plotType === 'candlestick'){
                return <TimeSeriesCandelChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor}
                    candelType={plotType}
                />
            }else if(plotType === 'ohlc'){
                return <TimeSeriesCandelOhlcChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor}
                />
            }else if(plotType === 'stock_events'){
                return <TimeSeriesStockEventsChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor}
                />
            }else{
                /*return <TimeSeriesLineChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor} />*/
                return <TimeSeriesCandelChart
                    theme={theme}
                    bgColor={bgColor}
                    patternColor={timeseriesPatternColor}
                    candelType={plotType}
                />
                // return <TestChart />
            }
            // return <PlottedSeriesChart />
        }
    }
    render() {
        //Ploter
        // dataSource.chart.palettecolors = fusion.ploter.ploterColor;
        return (
            <div className="">
                {
                    this.renderChart()
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    chartConfig: state.stockChart
});

const mapDispatchToProps = (dispatch) => ({
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(Category);

import Numeral from 'numeral'
export const toK = num => {
  return Numeral(num).format('0.[00]a')
}
var priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})
const Common = {
  // Common.formatNumber(123456789.12345, 2, ',', '.') outputs 123,456,789.12
  formatNumber: function (number, decimal, comma, point) { //n, c, d, t
    decimal = isNaN(decimal = Math.abs(decimal)) ? 0 : decimal;
    point = point === undefined ? "." : point;
    comma = comma === undefined ? "," : comma;
    let s = number < 0 ? "-" : "",
      i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decimal))),
      j = i.length;
    j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + comma : "") + i.substr(j)
      .replace(/(\d{3})(?=\d)/g, "$1" + comma) + (decimal ? point + Math.abs(number - i).toFixed(decimal).slice(2) : "");
  }
};

Common.utcToLocal = (utcDate) => {
  const timezoneOffsetStamp = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(new Date(utcDate).getTime() + timezoneOffsetStamp);
};

Common.localToUtc = (date) => {
  const timezoneOffsetStamp = new Date().getTimezoneOffset() * 60 * 1000;
  return new Date(new Date(date).getTime() - timezoneOffsetStamp);
};

Common.formattedNum = (number, usd = false, acceptNegatives = false) => {
  if (isNaN(number) || number === '' || number === undefined) {
    return usd ? '$0' : 0
  }
  let num = parseFloat(number)

  if (num > 500000000) {
    return (usd ? '$' : '') + toK(num.toFixed(0), true)
  }

  if (num === 0) {
    if (usd) {
      return '$0'
    }
    return 0
  }

  if (num < 0.0001 && num > 0) {
    return usd ? '< $0.0001' : '< 0.0001'
  }

  if (num > 1000) {
    return usd
      ? '$' + Number(parseFloat(num).toFixed(0)).toLocaleString()
      : '' + Number(parseFloat(num).toFixed(0)).toLocaleString()
  }

  if (usd) {
    if (num < 0.1) {
      return '$' + Number(parseFloat(num).toFixed(4))
    } else {
      let usdString = priceFormatter.format(num)
      return '$' + usdString.slice(1, usdString.length)
    }
  }

  return Number(parseFloat(num).toFixed(5))
}

export const removeObjectProperties = function (obj, props) {
  if (typeof obj === 'undefined' || obj === null)
    return;

  for (let i = 0; i < props.length; i++) {
    if (obj.hasOwnProperty(props[i])) {
      delete obj[props[i]];
    }
  }
};

export const getRandomColor = () => {
  // Generate random values for red, green, and blue channels
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Combine the channels into a CSS color string
  return `rgb(${red}, ${green}, ${blue})`;
}

export default Common;

import axios from 'axios';
import axiosRetry from 'axios-retry'
import { getAuth, signOut } from "firebase/auth"
// import { getAuth } from "firebase/auth";

import LocalStorageService from 'store/services/LocalStorageService';
const storageService = LocalStorageService.getService()

// Use interceptor to inject the token to requests
axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryCount) => {
      console.log(`retry attempt: ${retryCount}`);
      return retryCount * 2000;
    },
    retryCondition: async (error) => {
      if(error.response.status === 401){
        const auth = getAuth();
        const idToken = await auth?.currentUser?.getIdToken(true)
        if(idToken) storageService.setAccessToken(idToken)
        return true;
      }
      return false
    },
    onRetry: (error) => {
      console.log('onRetry')
    }
});
axios.interceptors.request.use(request => {
    const goesToPython = request?.url?.includes("/user_api")
    if(goesToPython){
      const uId = storageService.getPlotterUID()
      const accessToken = storageService.getAccessToken()
      // const accessToken = `eyJhbGciOiJSUzI1NiIsImtpZCI6ImNlOWI4ODBmODE4MmRkYTU1N2Y3YzcwZTIwZTRlMzcwZTNkMTI3NDciLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiRGV2IFZEIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FMbTV3dTNQUlYwWnhwRHB2NjNMNklkVFlfcGZ3NmFjbktiblo4NTQ2RWVXPXM5Ni1jIiwiaXNzIjoiaHR0cHM6Ly9zZWN1cmV0b2tlbi5nb29nbGUuY29tL3Bsb3R0ZXItZGV2ZWxvcG1lbnQiLCJhdWQiOiJwbG90dGVyLWRldmVsb3BtZW50IiwiYXV0aF90aW1lIjoxNjcxNDY1MTU5LCJ1c2VyX2lkIjoiNndTR1FjQ0hqRlRaVUZzNGNtNjRsaGtmRndVMiIsInN1YiI6IjZ3U0dRY0NIakZUWlVGczRjbTY0bGhrZkZ3VTIiLCJpYXQiOjE2NzI4NDIzNjQsImV4cCI6MTY3Mjg0NTk2NCwiZW1haWwiOiJkZXZzb3VuZDIwMUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwMTgxOTIwMTU4NDI5NDUwNTIxOSJdLCJlbWFpbCI6WyJkZXZzb3VuZDIwMUBnbWFpbC5jb20iXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.KhqvElCvkdwz77izsCuAGgwE9EqXUI0WomfZ_PdgFf2XYweJl9DqLMhfqVrClTYRNplr6piZxj1vCgEcO2wPuk4CylSBCzu5VxQeVUy42Y5xzel_JaOo_yAIlzjAtqOMO_O60XZIy9JFoSOJXLZdiIuEFTAi9QABiCRcTUL4uQWofNu0Yyc3oZMgLmofWVXcosl2Gf2FnSW4AVCNTyeiLWhk4Zaz9_P_jpU1bB7K3vew20Q3XUW9atmLE2XVKVX77KtDBhxBMYSFH0p6njf48n7N0LQZoZEt1FWqKILuPpkxm_n0NxwfirQVmkbPC0jnhMg4bo-ua3ko6OPk-o6TVg`
      if (uId && accessToken) {
        request.headers['X-PLOTTER-USER'] = uId
        request.headers['X-PLOTTER-ACCESS-TOKEN'] = accessToken;
      }else{
        throw new axios.Cancel('Operation canceled.');
      }
    }
    return request;
});
axios.interceptors.response.use(response => {
  return response;
}, error => {
 if (error?.response?.status === 401) {
  const auth = getAuth();
  const _rememberedPath = window.location.pathname
  storageService.setRememberedPath(_rememberedPath)
  // console.log(auth, 'auth')
  // signOut(auth)
  console.log(401, auth, '401')
  // console.log(401, 'here 401')
  throw new axios.Cancel('Operation canceled.');
 }
 return Promise.reject(error);
});

export default axios;

import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  text: {
    lineHeight: '20px',
  },
  collapsedTxt: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'initial',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
  },
  ShowMoreLink: {
    color: theme.palette.secondary.main,
  },
}));
const CollapsableTextWrap = ({ txt, txtClassName, showToggleLink = true }) => {
  const classes = useStyles();
  const [showMoreDescr, setShowMoreDescr] = useState(false);
  const [ref, setRef] = useState(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = () => {
    if (!isOverflowing) {
      const el = ref;
      let _isOverflowing = false;
      if (el && el.style) {
        const curOverflow = el?.style.overflow;

        if (!curOverflow || curOverflow === 'visible')
          el.style.overflow = 'hidden';

        _isOverflowing =
          el?.clientWidth < el?.scrollWidth ||
          el?.clientHeight < el?.scrollHeight;

        el.style.overflow = curOverflow;
      }
      if (_isOverflowing) setIsOverflowing(true);
      return _isOverflowing;
    }
    return isOverflowing;
  };

  return (
    <Box>
      <Typography
        ref={(r) => {
          setRef(r);
        }}
        className={`${classes.text} ${txtClassName} ${
          !showMoreDescr ? classes.collapsedTxt : ''
        }`}
      >
        {txt}
      </Typography>
      {checkOverflow() && showToggleLink && (
        <Typography
          className={classes.ShowMoreLink}
          onClick={() => setShowMoreDescr(!showMoreDescr)}
        >
          {showMoreDescr ? 'Show less' : 'Show more'}
        </Typography>
      )}
    </Box>
  );
};

export default CollapsableTextWrap;

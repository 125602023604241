import React, { useState, forwardRef, useMemo, useEffect } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Slide,
    Box,
    TableContainer,
    Typography,
    Tooltip,
    FormControlLabel,
    Switch,
    TableCell,
    TableRow,
    Chip,
    Table,
    TableHead,
    TableBody,
} from "@mui/material";
import TimeLineChart from './TimeLine';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    },
    productStaticBarButton: {
        color: '#4e4b4b',
        cursor: 'pointer',
        margin: '0 3px',
        position: 'relative',
        boxShadow: '0 0 1px 1px #e5e5f1',
        transition: 'all ease-in .2s',
        borderRadius: '4px',
        height: '37px',
        width: '37px',
        fontSize: '13px',
        textAlign: 'center',
        '&.selected': {
            boxShadow: '0 0 1px 1px #646467'
        },
        '&:hover': {
            background: '#ececec'
        },
        '&.selectedViewMode': {
            background: '#3c54db',
            color: 'white',
            '&:hover': {
                background: '#5e72e4'
            },
        },
        '& svg': {
            display: 'block',
            margin: '0 auto',
            fontSize: '35px'
        },
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     height: '1px',
        //     borderTop: 'solid 1px #e2dcdc',
        //     bottom: '-6px',
        //     width: 'calc(100% - 6px)'
        // },
        // '&:last-child': {
        //     '&::after': {
        //         content: 'initial'
        //     }
        // }
    },
    
    TableCap: {
        padding: '13px',
        alignItems: 'center',
        background: 'white',
        boxShadow: '14px 6px 4px -21px rgba(0,0,0,0.2), 0px -4px 2px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    Table: {
        marginTop: '11px',
        background: 'white'
    },
    TableTitle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '140%',
        margin: '0px',
        fontSize: '14px',
        letterSpacing: '-0.006em',
        fontWeight: '600',
        color: 'rgb(67, 74, 84)',
        marginRight: '10px'
    },
    TableRow: {
        cursor: 'pointer',
        "&.selected": {
            background: theme.palette.primary.main,
            '& td': {
                color: 'white'
            }
        }
    }
}));

const Transition = forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const convertToSeconds = (milliseconds, showMiliSeconds = true) => {
    if (!milliseconds) return ''
    const _seconds = Math.round(milliseconds / 1000 * 100) / 100;
    if (!showMiliSeconds) {
        return `${_seconds}s`
    }
    return `${milliseconds}ms`
}

const getPercentageOfTotal = (total, time) => {
    return time > 0 ? `${(time / total * 100).toFixed(2)}%` : 'N/A'
}

const IndividualResultDialog = (props) => {
    const {
        open,
        handleClose,
        qaResults,
        feedSource,
        databaseCode,
        productCode,
    } = props
    const classes = useStyles();

    const [blobOpt, setBlobOpt] = React.useState('cloud-storage')
    const [timeIncrements, setTimeIncrements] = React.useState([])
    const [selectedInc, setSelectedInc] = React.useState('')
    const [showMiliSeconds, setShowMiliSeconds] = React.useState(false)
    const [showTimePercentage, setShowTimePercentage] = React.useState(false)
    const [sortOption, setSortOption] = React.useState(null)

    const filteredResults = useMemo(() => {
        if(qaResults){
            return qaResults.filter(item => item.loading_source === blobOpt)
        }
        return []
    }, [
        blobOpt,
        qaResults,
        selectedInc
    ])
    const timeLineData = useMemo(() => {
        if(qaResults){
            return qaResults.filter(item => item.loading_source === blobOpt && item.time_increment === selectedInc)
        }
        return []
    }, [
        blobOpt,
        qaResults,
        selectedInc
    ])
    useEffect(() => {
        const _results = qaResults.filter(item => item.loading_source === blobOpt)
        const _timeIncrements = _results.map(item => item.time_increment)
        setTimeIncrements([..._timeIncrements])
        if(_timeIncrements.length > 0){
            setSelectedInc(_timeIncrements[0])
        }else{
            setSelectedInc('')
        }
    }, [
        qaResults,
        blobOpt
    ])
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'md'}
        >

            <DialogContent>
                <TableContainer className={classes.root}>
                    <Box display="flex" className={classes.TableCap}>
                        <Box display={"flex"} sx={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography className={classes.TableTitle}>{`${feedSource}/${databaseCode}${productCode !== '' ? '/' + productCode : ''}`}</Typography>
                            </Box>
                            {/* <Chip label={rows.length} /> */}
                            <Box display={"flex"} sx={{ alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'end' }} className={"mr-3"}>
                                    <Tooltip title="Cloud Storage">
                                        <Box
                                            sx={{ padding: '10px !important' }}
                                            className={`${classes.productStaticBarButton} ${blobOpt === 'cloud-storage' ? 'selected' : ''}`}
                                            mr={1}
                                            onClick={() => setBlobOpt('cloud-storage')}
                                        >CS</Box>
                                    </Tooltip>
                                    <Tooltip title="Big Query">
                                        <Box
                                            sx={{ padding: '10px !important' }}
                                            className={`${classes.productStaticBarButton} ${blobOpt === 'big-query' ? 'selected' : ''}`}
                                            onClick={() => setBlobOpt('big-query')}
                                        >BG</Box>
                                    </Tooltip>
                                </Box>
                                <Box className={"mr-3"}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={showMiliSeconds} size="small" onChange={(e) => { setShowMiliSeconds(e.target.checked) }} name="showms" />
                                        }
                                        className='m-0'
                                        label="Show MS"
                                    />
                                </Box>
                                <Box className={"mr-3"}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={showTimePercentage} size="small" onChange={(e) => { setShowTimePercentage(e.target.checked) }} name="showtimePercentage" />
                                        }
                                        className='m-0'
                                        label="Show %"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {/* <Typography className={classes.TableSubTitle}>{subTitle}</Typography> */}
                    </Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table" className={classes.Table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Product Code</TableCell>
                                <TableCell>
                                    <Box display={'flex'}>
                                        <span>Status</span>
                                    </Box>
                                </TableCell>
                                <TableCell>TimeIncrement</TableCell>
                                <TableCell align="right">Asset Load Time</TableCell>
                                <TableCell align="right">Meta Data Load Time</TableCell>
                                <TableCell align="right">Blob Load Time</TableCell>
                                <TableCell align="right">Draw Time</TableCell>
                                <TableCell align="right">Total Time</TableCell>
                                <TableCell align="right">
                                    <Box display={'flex'}>
                                        <span>Score</span>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filteredResults.length > 0 ? (
                                    <>
                                        {filteredResults.map((item, idx) => (
                                            // row.items?.map((item, iIdx) => (
                                            <TableRow
                                                key={idx}
                                                className={`${classes.TableRow} ${ selectedInc === item.time_increment && 'selected' }`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                onClick={() => {
                                                    setSelectedInc(item.time_increment)
                                                }}
                                            >
                                                <TableCell component="td" scope="row">
                                                    {item.product_code}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        item.last_error && item.last_error.trim() !== '' ?
                                                            <Chip size='small' label="Error" color="error" />
                                                            : (
                                                                item.category === 'good' ? <Chip size='small' label="Good" color="primary" /> :
                                                                    item.category === 'average' ? <Chip size='small' label="Average" color="info" /> :
                                                                        <Chip size='small' label="Below Average" color="warning" />
                                                            )
                                                    }
                                                    {/* // row.staging === 0 ? <Chip size='small' label="In Progress" color="warning" /> :
                                            //     row.staging === -1 ? <Chip size='small' label="Error" color="error" /> :
                                            //         <Chip size='small' label="Done" color="primary" /> */}
                                                </TableCell>
                                                <TableCell>{item.time_increment}</TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Asset Load Time">
                                                        <Box>
                                                            {
                                                                showTimePercentage ?
                                                                    getPercentageOfTotal(item.total_seconds, item.initial_load_seconds) : convertToSeconds(item.initial_load_seconds, showMiliSeconds)
                                                            }
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Meta Data Load Time">
                                                        <Box>
                                                            {showTimePercentage ? getPercentageOfTotal(item.total_seconds, item.metadata_load_seconds) : convertToSeconds(item.metadata_load_seconds, showMiliSeconds)}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Blob Load Time">
                                                        <Box>
                                                            {showTimePercentage ? getPercentageOfTotal(item.total_seconds, item.blob_load_seconds) : convertToSeconds(item.blob_load_seconds, showMiliSeconds)}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Time to Draw">
                                                        <Box>
                                                            {showTimePercentage ? getPercentageOfTotal(item.total_seconds, item.time_to_draw_seconds) : convertToSeconds(item.time_to_draw_seconds, showMiliSeconds)}
                                                        </Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Total Time">
                                                        <Box>{convertToSeconds(item.total_seconds, showMiliSeconds)}</Box>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {
                                                        !(item.last_error && item.last_error.trim() !== '') && (
                                                            <Tooltip title="Score">
                                                                <Box>{item.score > 0 ? item.score.toFixed(2) : ''}</Box>
                                                            </Tooltip>
                                                        )
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            // ))
                                        ))}
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={9}><Box className={"text-center"}>There is no Data</Box></TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TimeLineChart
                    timeIncrements={[]}
                    data={timeLineData}
                    hasCloudStorage={true}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

IndividualResultDialog.propTypes = {
};

export default IndividualResultDialog

import { set } from "d3-collection";
import { scaleOrdinal, schemeCategory10, scaleLinear, scaleLog } from  "d3-scale";
import { format } from "d3-format";
import { extent } from "d3-array";

import React from "react";
import PropTypes from "prop-types";

import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import { ChartCanvas, Chart } from "../../libs/rrag";
import { ScatterSeries, CircleMarker } from "../../libs/rrag/lib/series";
import { XAxis, YAxis } from "../../libs/rrag/lib/axes";
import {
	CrossHairCursor,
	MouseCoordinateX,
	MouseCoordinateY,
} from "../../libs/rrag/lib/coordinates";

import { fitWidth } from "../../libs/rrag/lib/helper";

class BubbleChart extends React.Component {
	constructor(props){
		super(props)
		this.drawChartTheme = this.drawChartTheme.bind(this);
	}
	drawChartTheme(){
        const { themeBg } = this.props.chartData
        const themeProps = {
            XAxis:{ tickStrokeOpacity: 0.5},
            YAxis: { tickStrokeOpacity: 0.5, tickStroke:"black", stroke: "black" }, 
            toolTipApperance: {},
        }
        if(themeBg?.name !== undefined){
            switch(themeBg.name){
                case 'white':
                    break;
                // case 'dark':
                default:
                    themeProps.XAxis = { tickStrokeOpacity: 0.5, tickLabelFill:'#fff', stroke: "#fff" };
                    themeProps.YAxis = { tickStrokeOpacity: 0.5, tickLabelFill:'#fff', stroke: "#fff" };
                    themeProps.toolTipApperance = { labelFill: '#7698ce', textFill: '#a5a2a2' }
                    break
            }
        }
        return themeProps
    }
	render() {
		const { data: unsortedData, type, width, ratio, gridProps } = this.props;
		const { themeBg, grid } = this.props.chartData;
		
        const { 
            XAxis: xAixProps, YAxis: yAixProps,
            toolTipApperance
		} = this.drawChartTheme();

		const data = unsortedData.slice().sort((a, b) => a.income - b.income);
		const r = scaleLinear()
			.range([2, 20])
			.domain(extent(data, d => d.population));

		const f = scaleOrdinal(schemeCategory10)
			.domain(set(data.map(d => d.region)));

		const fill = d => f(d.region);
		const radius = d => r(d.population);

		const height = 400;
		const gridHeight = height;
		const gridWidth = width - 70 - 70;

		const showGrid = grid?.show || false;
		const yGrid = showGrid ? { innerTickSize: -1 * gridWidth } : {};
		const xGrid = showGrid ? { innerTickSize: -1 * gridHeight } : {};
        xAixProps.tickStroke = grid?.tickStroke
        yAixProps.tickStroke = grid?.tickStroke
		return (
			<ChartCanvas ratio={ratio} width={width} height={400} bg={themeBg && themeBg?.color ? themeBg?.color: ''}
					margin={{ left: 70, right: 70, top: 20, bottom: 30 }} type={type}
					seriesName="Wealth & Health of Nations"
					data={data}
					xAccessor={d => d.income}
					xScale={scaleLog()}
					padding={{ left: 20, right: 20 }}
					>
				<Chart id={1}
						yExtents={d => d.lifeExpectancy}
						yMousePointerRectWidth={45}
						padding={{ top: 20, bottom: 20 }}>
					<XAxis axisAt="bottom" orient="bottom" ticks={2} tickFormat={format(",d")} 
                        {...gridProps}
						{...xGrid}
                        {...xAixProps}
					/>
					<YAxis axisAt="left" orient="left" 
						{...gridProps}
						{...yGrid}
                        {...yAixProps}
					/>
					<ScatterSeries yAccessor={d => d.lifeExpectancy} marker={CircleMarker}
						fill={fill}
						markerProps={{ r: radius, fill: fill }}
						/>

					<MouseCoordinateX snapX={false}
						at="bottom"
						orient="bottom"
						rectWidth={50}
						displayFormat={format(".0f")} />
					<MouseCoordinateY
						at="left"
						orient="left"
						displayFormat={format(".2f")} />
				</Chart>
				<CrossHairCursor snapX={false} />
			</ChartCanvas>

		);
	}
}

BubbleChart.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

BubbleChart.defaultProps = {
	type: "svg",
};
BubbleChart = fitWidth(BubbleChart);

const mapStateToProps = (state) => ({
    chartData: state.chartData
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(BubbleChart);
import {createSlice,createAsyncThunk,createEntityAdapter} from '@reduxjs/toolkit';
import { setPath } from 'shared/utilities/helpers'



const initialState = {
  // chartLibrary: 'light-weight',
  // chartLibrary: 'tradingview-widget',
  chartLibrary: 'highchart',
  feedSourceType: 'stock',
  loadedFeedSourceType: 'stock',
  
  timeseries: {
    entityField: null,
    checked: [],
    tableEntityId: null,

    chartType: 'line',
    xAxis: null,
    yAxis: null,
    tooltips: []
  },
};

const chartDataSlice = createSlice({
  name: 'chartData',
  initialState,
  reducers: {
    setChartOpt: (state, action) => {
      const {path, value} = action.payload
      setPath(state, path, value)
    },
    setChartLibrary: (state, action) => {
      state.chartLibrary = action.payload
    },
    setFeedSourceType: (state, action) => {
      state.feedSourceType = action.payload
    },
    setLoadedFeedSourceType: (state, action) => {
      state.loadedFeedSourceType = action.payload
    },
    initiTimeseriesData: (state, action) => {
      state.timeseries = {
        ...state.timeseries,
        checked: [],
        tooltips: [],
        xAxis: null,
        yAxis: null,
      }
    }
  },
  extraReducers: {},
});

export const prepareSelector = state => state.chartData;
export const {
  setChartOpt,
  setChartLibrary, 
  setFeedSourceType,
  setLoadedFeedSourceType,
  initiTimeseriesData
} = chartDataSlice.actions
export default chartDataSlice.reducer;

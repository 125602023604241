import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: '#003d79',
  //   },
  //   secondary: {
  //     main: '#47aa42',
  //     contrastText: '#fff'
  //   },
  //   background: {
  //     default: '#f6f6f6',
  //     paper: '#fff',
  //   },
  // },
  // typography: {
  //   fontSize: 13,
  //   fontFamily: 'Arial',
  // },
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 960,
  //     lg: 1280,
  //     xl: 1921,
  //     xxl: 2560,
  //   },
  // },
  // components: {
  //   MUIDataTableToolbar: {
  //     styleOverrides: {
  //       icon: {
  //         '&:hover': {
  //           color: "#47aa42"
  //         }
  //       },
  //     },
  //   },
  // },
});

export default theme;
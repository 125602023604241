import React  from "react";
import PropTypes from 'prop-types';

import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import {makeStyles} from "@mui/styles";

import {
    Button,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Chip,
    Avatar
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';



const useTabPanelStyles = makeStyles(theme => ({
    root: {
        minHeight: '400px',
        border: 'solid 1px #dedede',
        borderTop: 'none'
    }
}))
function TabPanel(props) {
    const classes = useTabPanelStyles()
    const { children, value, index, ...other } = props;
    
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
        className={classes.root}
      >
        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const products = [
    {
        "product_code": "APU0000701111    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000701312    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000701322    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000702111    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000702112    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000702212    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000702421    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000702611    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703111    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703112    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703113    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703211    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703212    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703213    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703311    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703312    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703411    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703422    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703425    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703431    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703432    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703511    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703512    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703612    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000703613    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000704111    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000704211    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000704212    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000704311    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000704312    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000704413    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000704421    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000705111    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000705121    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000706111    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000706211    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000706212    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000706311    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000707111    ",
        "series_type": "j_cloud"
    },
    {
        "product_code": "APU0000708111    ",
        "series_type": "j_cloud"
    }
]

const useProductListStyle = makeStyles((theme) => ({
    root: {
        maxHeight: '400px',
        overflow: 'auto',
        marginTop: '10px',
        listStyle: 'none',
        padding: '0',
        marginTop: '10px',
        borderTop: 'solid 1px #d1d1d1'
    },
    listItem:{
        background: 'white',
        color: 'black',
        padding: '10px',
        border: 'solid 1px #d1d1d1',
        borderTopWidth: 0,
        cursor: 'pointer',
        '&::first-item': {
            borderTopWidth: 1
        }
    }
}))
function PrductList(props) {
    const classes = useProductListStyle()
    // const { products } = props;
    return (
        <ul className={classes.root}>
            {
                products?.map((item, index) => {
                    return <li className={classes.listItem}>
                        {
                            item.product_code
                        }
                    </li>
                })
            }
        </ul>
    ) 
}
  
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        '& .MuiTabs-flexContainer .MuiButtonBase-root:focus': {
            outline: 'none'
        }
    },
    Chip: {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    productSearchBar: {
        position: 'relative',
        marginTop: '12px',
        '& .productSearchBarInner': {
            width: '300px',
            borderRadius: '50px',
            padding: '4px',
            background: '#5e72e4',
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            borderTop: 'solid 1px #d1d1d1',
            top: '-9px',
            width: '100%'
        },
        '& input': {
            borderRadius: '50px',
            width: 'calc(100% - 31px)',
            border: 'none',
            padding: '4px',
            fontSize: '13px',
            textIndent: '10px',
            '&:focus': {
                outline: 'none'
            }
        },
        '& a': {
            color: 'black',
            transition: 'all ease-out .2s',
            '&:hover': {
                transform: 'scale(1.1)'
            },
            '& svg': {
                color: 'white',
                width: '30px',
                height: '30px',
                padding: '5px',
                borderRadius: '50%'
            }
        }
    }
}));

/* DataFeeds component */
function DataFeeds(props){
    const { sources, groups } = props.feed

    const classes = useStyles();
    const [value, setValue] = React.useState(sources && sources[0] ? sources[0] : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if(value === 0){
        return <div>Loading....</div>
    }
    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    {
                        sources?.map((item, index) => (
                            <Tab label={item} key={index} value={item} {...a11yProps(index)} />
                        ))
                    }
                </Tabs>
            </AppBar>
            {
                sources?.map((item, index) => {
                    const databaseCodes = groups.filter(g => g.feed_source === item);
                    return <TabPanel value={value} index={item} key={index}>
                        {
                            databaseCodes.map((d, i) => (
                                <Chip
                                    avatar={<Avatar>{d.database_code[0]}</Avatar>}
                                    className={classes.Chip} 
                                    label={d.database_code} 
                                    key={i} component="a" 
                                    href="#chip" 
                                    clickable 
                                    // color="primary"
                                    variant="outlined" />
                            ))
                        }
                        <div className={classes.productSearchBar}>
                            <div className='productSearchBarInner'>
                                <input placeholder={'Search...'} />
                                <a href={'#'}>
                                    <SearchIcon />
                                </a>
                            </div>
                        </div>
                        <PrductList />
                    </TabPanel>
                })
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    feed: state.feedReducer
});

const mapDispatchToProps = (dispatch) => ({
});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(DataFeeds);
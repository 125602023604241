import React, { useState } from "react";
import { Link } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth"
import { initializeApp } from "firebase/app"

import { Box, Divider, List, ListItem, ListItemText, Popover, ListItemButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchComponent from "components/layout/header/Search";

import PlotterLogo from 'assets/images/plotter_logo.png';
import UserAvatar from 'assets/images/avatar/user.png';

let firebaseConfig = null
// console.log(process.env.REACT_APP_IS_DEV, 'hi???')
if (process.env.REACT_APP_IS_DEV !== 'False') {
    // console.log("Hi??")
    firebaseConfig = {
        apiKey: "AIzaSyADoj1zqULSVKjdxa5LOh3Opp3jyxDITpw",
        authDomain: "plotter-development.firebaseapp.com",
        projectId: "plotter-development",
        storageBucket: "plotter-development.appspot.com",
        messagingSenderId: "473093143182",
        appId: "1:473093143182:web:7344e0f679c90f29eee7df"
    }
} else {
    firebaseConfig = {
        apiKey: "AIzaSyCrMFrMnzCzNV9KxQleJScNkayhrp1H9Bk",
        authDomain: "celestial-smile-235321.firebaseapp.com",
        projectId: "celestial-smile-235321",
        storageBucket: "celestial-smile-235321.appspot.com",
        messagingSenderId: "1012059516846",
        appId: "1:1012059516846:web:eb29254b263ed1fbde623f"
    }
}
const app = initializeApp(firebaseConfig)

const auth = getAuth();
const useStyles = makeStyles(() => ({
    root: {},
    PlotterLogo: {
        width: '111px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: '18px',
        '& a': {
            color: '#00acac',
            '& img': {
                width: '36px',
            },
            '& svg': {
                fontSize: '29px',
            }
        }
    },
    SearchBox: {
        // flexGrow: 1,
        // width: '390px',
        // maxWidth: '390px'
        flex: 1,
        marginRight: '27px'
    },
    SearchBoxInner: {
        width: '100%',
        // maxWidth: '700px',
        margin: '0 auto'
    },
    NavigationBox: {
        flexGrow: 'initial'
    },
    NavigationList: {
        alignItems: 'center'
    },
    NavItem: {
        cursor: 'pointer',
        borderRadius: '50%',
        background: 'white',
        height: '40px',
        width: '40px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'solid 1px white',
        marginRight: 10,
        padding: '0 !important',
        transition: 'all ease-out .3s',
        '&:hover': {
            transform: 'scale(1.03)'
        },
        '& svg': {
            width: '20px',
            height: '20px'
        }
    },
    NavItemPic: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        '& img': {
            width: '100%',
            borderRadius: '50%',
        }
    },
    ListItem: {
        padding: 0,
        width: 200,
    }
}))

/* Home component */
export default function MainHeader(props) {
    const classes = useStyles()

    const logout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        })
    }
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <nav className="navbar navbar-expand-lg navbar-absolute navbar-transparent">
            <div className="container-fluid">
                <Box
                    display="flex"
                    sx={{
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'space-between',
                        marginTop: '8px'
                    }}
                >
                    <Box display="flex" sx={{ alignItems: 'center', flex: 1 }}>
                        <div className={classes.PlotterLogo}>
                            <Link to={'/home'}>
                                <img src={PlotterLogo} width='47px' />
                            </Link>
                        </div>
                        <Box className={classes.SearchBox}>
                            <Box className={classes.SearchBoxInner}>
                                <SearchComponent {...props} />
                            </Box>
                        </Box>
                    </Box>
                    <div className={`collapse navbar-collapse ${classes.NavigationBox}`} id="navigation">
                        <ul className={`navbar-nav ml-auto ${classes.NavigationList}`}>
                            <li className={classes.NavItem}>
                                <Box className={classes.NavItemPic} onClick={handleClick}>
                                    <img src={UserAvatar} alt="Profile Photo" />
                                </Box>
                            </li>
                        </ul>
                    </div>
                </Box>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List>
                    <ListItem className={classes.ListItem}>
                        <ListItemButton>
                            <ListItemText primary="Profile" />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.ListItem}>
                        <ListItemButton onClick={logout}>
                            <ListItemText primary="Log out" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>
        </nav>
    )
}

/* eslint no-restricted-globals:0 */

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { compose } from "recompose"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router"
import { useParams, useLocation } from 'react-router-dom'
import Axios from "axios"

import { makeStyles } from "@mui/styles";

import {
    Paper,
    CircularProgress,
    Snackbar,
    Breadcrumbs,
    Typography,
    Button,
    ButtonGroup,
    Slide,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip
} from "@mui/material"

import TrendingUpIcon from '@mui/icons-material/TrendingUp'

import MarketContainer from "./Graph/Market"
import ErrorBoundary from "shared/system/error/ErrorBoundary"
import Alert from 'components/common/Base/Alert'


import {
    setFeedSourceType,
    setLoadedFeedSourceType
} from 'store/slices/ChartDataSlice'
import {
    setProductOpt,
    setXAxisFields,
    setYAxisFields,
    setXAxis,
    setYAxis,
    fetchProductSQL,
    fetchStockProductData,
    clearStockProductData,
    initProductMetaData,
    fetchStockProductOfBigQuery,
    fetchStockProductSignedUrl,
    fetchStockProductDataForTransform,
    fetchBQDataForTransform,
    setSelectedFrequencies,
    setSelectedBlobTimeIncrement
} from 'store/slices/StockProductSlice'

import LocalStorageService from 'store/services/LocalStorageService';

import { resolvePath } from 'shared/utilities/helpers'
// import FilterPop from "./Components/FilterPop"
import { Box } from "@mui/system"
// import News from "./News"
import FavoriteButton from "components/common/Favorites/FavoriteButton"
import DataTableView from "./Table"
import ChartGraphView from "./Graph"
import { Autorenew, RefreshSharp } from "@mui/icons-material"
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice"
import CustomMuiDataTable from "components/common/Base/CustomMuiDataTable"
import BEADataTableView1 from "./Table/Transform/BEADataTableView1"
import IODataTableView from "./Table/Transform/IODataTableView"
import NIPATableView from "./Table/Transform/NIPA"
import RegionalTableView from "./Table/Transform/Regional"
import MNETableView from "./Table/Transform/MNETableView"
import TimeLineChart from "../../../components/layout/sidebar/qa/ProductCode/TimeLine"
import { triggerFrontendProductQA } from "store/slices/FrontendQA"
import useRunIndividualQA from "hooks/RunIndividualQA"
import IndividualResultDialog from "components/layout/sidebar/qa/ProductCode/IndividualResultDialog"

const TIMESEARIES_SOURCES = ['BLS', 'BOE', 'BARCHART', 'BEA', 'BCB', 'BP', 'CFTC', 'HKEX', 'RATEINF', 'ML', 'FISCAL_DATA', 'OECD', 'MULTPL', 'EIA', 'USTREASURY', 'ECONODAY', 'QUANDL', 'URL', 'FRED', 'BATS', 'YC', 'QOR', 'ZILLOW', 'SGE', 'ODA', 'FINRA', 'WB', 'WASDE', 'ECONOMIST']
const NDL_SOURCES = ['BEA', 'ECONODAY', 'FRED', 'BATS', 'BCIW', 'YC', 'BCMX', 'QOR', 'ZILLOW', 'BCEUX', 'SGE', 'ODA', 'FINRA', 'WB', 'WASDE']
const storageService = LocalStorageService.getService()

const convertToSeconds = (milliseconds) => {
    return Math.round(milliseconds / 1000 * 100) / 100;
}

const Transition = React.forwardRef(function Transition(
    props,
    ref,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const StockDetailComponent = (props) => {
    const { chartLibrary } = props.chartData
    const { feedSource, databaseCode, productCode = '' } = useParams()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const bookMarkedTimeIncrement = searchParams.get('timeIncrement');

    const dispatch = useDispatch()
    const [qaMode, setQaMode] = useState();

    const { productData, user: userData, loadedStocks } = props
    const { selectedBlobTimeIncrement: timeIncrement } = productData

    const [state, setState] = useState({
        feedSource: feedSource || '',
        databaseCode: databaseCode || '',
        productCode: productCode || '',
        notification: {
            type: null,
            message: '',
            open: false
        },
        // defaultX: null,
        cachedItems: {},

        xConfiguration: {},
        xAxis: null,
        yAxis: null,
    })
    const [selectedDataOpt, setSelectedDataOpt] = useState(null)
    const [showQAResult, setShowQAResult] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [openModifyDialog, setOpenModifyDialog] = useState(false)

    const [triggerIndividualQA, {
        isLoading: inMeasuring, qaResults = []
    }] = useRunIndividualQA(dispatch, () => {
        setShowQAResult(true)
    });

    const identifier = useMemo(() => {
        let _identifier = `${selectedDataOpt === 'big-query' ? 'bg/' : ''}${feedSource}/${databaseCode}/${productCode}`
        if (timeIncrement) {
            _identifier += `/${timeIncrement}`
        }
        return _identifier
    }, [feedSource, databaseCode, productCode, timeIncrement, selectedDataOpt])
    const beaDatabaseCode = useMemo(() => {
        const transformeds = ['NIPA', 'FixedAssets', 'NIUnderlyingDetail', 'IO', 'GDPbyIndustry', 'underlyingGDPbyIndustry', 'IIP', 'IntlServTrade', 'ITA', 'MNE']
        if (databaseCode === 'ITA' && productCode === 'TOTAL') return ''
        if (feedSource === 'BEA' && transformeds.indexOf(databaseCode) > -1) {
            return databaseCode
        }
        return ''
    }, [
        feedSource, databaseCode
    ])

    const feedSourceType = useMemo(() => {
        let _feedSourceType = 'stock'
        if (TIMESEARIES_SOURCES.indexOf(feedSource.toUpperCase()) > -1) {
            _feedSourceType = 'timeseries'
        }
        return _feedSourceType
    }, [
        feedSource
    ])

    const handleCloseQAResultDialog = () => {
        setShowQAResult(false)
    }

    const fetchProductData = async (_timeIncrement) => {
        const bgTables = productData.bgTables
        props.clearStockProductData()
        if (!(loadedStocks && loadedStocks[identifier] && loadedStocks[identifier].length > 0)) {
            setLoading(false)
            // if (timeIncrement) {
            // let tPromise =  new Promise(resolve => {
            const _params = {
                feed_source: feedSource,
                database_code: databaseCode,
                product_code: productCode,
                timeIncrement: _timeIncrement,
                bg_table_location: bgTables[_timeIncrement] || ''
            }
            try {
                if (selectedDataOpt === 'big-query') {
                    await props.fetchStockProductOfBigQuery(_params)
                } else {
                    await props.fetchStockProductData(_params)
                }
            } catch (e) {
                console.log('Error:', e)
                setLoading(false)
            }
            // });
            // await tPromise
            // }
        }
    }
    const fetchAllProductData1 = async () => {
        const timeIncrements = productData.timeIncrements
        const bgTables = productData.bgTables
        props.clearStockProductData()
        // console.log(loadedStocks, 'loadedStocks')
        // console.log(timeIncrements, 'timeIncrements')
        if (timeIncrements) {
            try {
                setLoading(true)
                for (let t of timeIncrements) {
                    let _identifier = `${selectedDataOpt === 'big-query' ? 'bg/' : ''}${feedSource}/${databaseCode}/${productCode}`
                    if (t) {
                        _identifier += `/${t}`
                    }
                    // console.log(_identifier, '_identifier')
                    if (!(loadedStocks && loadedStocks[_identifier] && loadedStocks[_identifier].length > 0)) {
                        const _params = {
                            feed_source: feedSource,
                            database_code: databaseCode,
                            product_code: productCode,
                            timeIncrement: t,
                            bg_table_location: bgTables[t] || ''
                        }
                        if (selectedDataOpt === 'big-query') {
                            await props.fetchStockProductOfBigQuery(_params)
                        } else {
                            await props.fetchStockProductData(_params)
                        }
                    }
                }
                setLoading(false)
            } catch (e) {
                console.log('Error:', e)
                setLoading(false)
            }
        }
    }
    const fetchAllProductData = async () => {
        const timeIncrements = productData.timeIncrements
        const bgTables = productData.bgTables
        props.clearStockProductData()
        // console.log(loadedStocks, 'loadedStocks')
        // console.log(timeIncrements, 'timeIncrements')
        if (timeIncrements) {
            try {
                setLoading(true)
                for (let t of timeIncrements) {
                    let _identifier = `${selectedDataOpt === 'big-query' ? 'bg/' : ''}${feedSource}/${databaseCode}/${productCode}`
                    if (t) {
                        _identifier += `/${t}`
                    }
                    // console.log(_identifier, '_identifier')
                    // if (!(loadedStocks && loadedStocks[_identifier] && loadedStocks[_identifier].length > 0)) {
                    const _params = {
                        feed_source: feedSource,
                        database_code: databaseCode,
                        product_code: productCode,
                        timeIncrement: t,
                        bg_table_location: bgTables[t] || ''
                    }
                    if (selectedDataOpt != 'big-query') {
                        await props.fetchStockProductSignedUrl(_params)
                    }
                    // }
                }
                setLoading(false)
            } catch (e) {
                console.log('Error:', e)
                setLoading(false)
            }
        }
    }
    const fetchSignedUrl = useCallback(async (defaultFrequency = null) => {
        // console.log(productData.selectedFrequencies, 'productData.selectedFrequencies')
        let selectedFrequencies = productData.selectedFrequencies
        if (defaultFrequency) {
            // dispatch(setSelectedFrequencies(defaultFrequency))
            // selectedFrequencies = defaultFrequency
        }
        if (selectedFrequencies.length > 0) {
            const bgTables = productData.bgTables
            for (let fr of selectedFrequencies) {
                if (!productData.signedUrls[fr]) {
                    const _params = {
                        feed_source: feedSource,
                        database_code: databaseCode,
                        product_code: productCode,
                        timeIncrement: fr,
                        bg_table_location: bgTables[fr] || ''
                    }
                    await props.fetchStockProductSignedUrl(_params)
                }
            }
        }
    }, [
        productData.selectedFrequencies,
        productData.signedUrls,
        productData.bgTables,
        feedSource,
        productCode,
        databaseCode
    ])

    const executeDataTransformLogic = useCallback(async () => {
        let isValid = true
        let dataBlobLocations = {}
        // for(let fr of productData.selectedFrequencies){
        //     if(selectedDataOpt === 'cloud-storage'){
        //         if(!productData.signedUrls[fr]){
        //             isValid = false
        //             break;
        //         }
        //         dataBlobLocations[fr] = productData.signedUrls[fr]
        //     }else{
        //         if(!productData.bgTables[fr]){
        //             isValid = false
        //             break;
        //         }
        //         dataBlobLocations[fr] = productData.bgTables[fr]
        //     }
        // }
        const loadedData = loadedStocks[identifier]
        if (!isValid) return
        if (productData?.selectedFrequencies?.length > 0 && isValid) {
            setLoading(true)
            if (!isValid) return
            let dateField = 'time_period_start';
            let hierarchyColumn = 'line';
            let labelColumn = 'line'
            if (databaseCode === 'underlyingGDPbyIndustry') {
                dateField = 'time_period'
                labelColumn = 'industrydescription'
                hierarchyColumn = 'industry'
            } else if (databaseCode === 'GDPbyIndustry') {
                labelColumn = 'industry'
                hierarchyColumn = 'industry'
            } else if (databaseCode === 'IO') {
                labelColumn = 'col'
                hierarchyColumn = 'col'
            } else if (databaseCode === 'IIP') {
                labelColumn = 'investment'
                hierarchyColumn = 'investment'
            } else if (databaseCode === 'IntlServTrade') {
                labelColumn = 'typeofservice';
                hierarchyColumn = 'typeofservice'
            } else if (databaseCode === 'ITA') {
                labelColumn = 'code';
                hierarchyColumn = 'code'
            } else if (databaseCode === 'MNE') {
                labelColumn = 'column'
                hierarchyColumn = 'column'
                dateField = 'year';
            }
            if (selectedDataOpt === 'cloud-storage') {
                await props.fetchStockProductDataForTransform({
                    signedUrls: dataBlobLocations,
                    feedSource,
                    productCode,
                    databaseCode,
                    selectedFrequencies: productData.selectedFrequencies,
                    selectedYears: productData.selectedYears,
                    selectedLines: productData.selectedLines,
                    selectedAreas: productData.selectedAreas,
                    selectedSeries: productData.selectedSeries,
                    selectedRows: productData.selectedRows,
                    selectedColumns: productData.selectedColumns,
                    dateField,
                    hierarchyColumn,
                    labelColumn
                })
            } else {
                await props.fetchBQDataForTransform({
                    bgTables: dataBlobLocations,
                    feedSource,
                    productCode,
                    databaseCode,
                    selectedFrequencies: productData.selectedFrequencies,
                    selectedYears: productData.selectedYears,
                    selectedLines: productData.selectedLines,
                    selectedAreas: productData.selectedAreas,
                    selectedSeries: productData.selectedSeries,
                    selectedRows: productData.selectedRows,
                    selectedColumns: productData.selectedColumns,
                    dateField,
                    hierarchyColumn,
                    labelColumn,
                    hasYears: loadedData && loadedData.years ? true : false
                })
            }
            setLoading(false)
        }
    }, [
        productData.bgTables,
        productData.selectedFrequencies,
        productData.selectedYears,
        productData.selectedLines,
        productData.signedUrls,
        productData.selectedSeries,
        productData.selectedRows,
        productData.selectedColumns,
        feedSource,
        productCode,
        databaseCode,
        loadedStocks,
        identifier,
        selectedDataOpt
    ])
    // useEffect(() => {
    //     // if (Object.keys(productData.signedUrls).length > 0 && productData?.timeIncrements?.length === Object.keys(productData.signedUrls).length) {
    //         executeDataTransformLogic()
    //     // }
    // }, [
    //     productData.selectedFrequencies,
    //     productData.selectedYears,
    //     productData.selectedLines
    // ])

    useEffect(() => {
        const _qa = JSON.parse(new URLSearchParams(location.search).get('qa'));
        setQaMode(_qa ?? false)
        let url = 'https://storage.googleapis.com/plotter-development-public-test/gzip_test3.json'
        // fetch(url).then( r => r.text() ).then( t => console.log(t) )
        // fetch(url).then( r => console.log(r, 'r') )
        const headers = {
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        };

        // Axios.get(
        //     url,
        //     { headers}
        // )
        //     .then(
        //         function (response) {
        //             console.log(response)
        //             // response.data.text().then(
        //             //     function (as_text) {
        //             //     console.log(as_text)
        //             //     }
        //             // )
        //         })
    }, [location.search]);

    useEffect(() => {
        if (qaMode !== undefined) {
            if (!qaMode) {
                // if (NDL_SOURCES.indexOf(feedSource.toUpperCase()) > -1) {
                // setSelectedDataOpt('big-query')
                // } else {
                setSelectedDataOpt('cloud-storage')
                // }
            } else {
                setSelectedDataOpt(null)
                dispatch(setSelectedBlobTimeIncrement(null))
            }
        }
    }, [
        qaMode,
        feedSource,
    ])

    useEffect(() => {
        try {
            props.initProductMetaData();
            if (chartLibrary !== 'tradingview-widget' && qaMode !== undefined && !qaMode) {
                props.fetchProductSQL({
                    feed_source: feedSource,
                    database_code: databaseCode,
                    product_code: productCode
                })
            }
            props.setFeedSourceType(feedSourceType)
            props.setLoadedFeedSourceType(feedSourceType)
        } catch (e) {
            console.log(e, 'error')
        }
    }, [
        feedSourceType,
        productCode,
        databaseCode,
        feedSource,
        qaMode,
        beaDatabaseCode
    ])
    const initialFetchingData = useCallback(async () => {
        const timeIncrements = productData.timeIncrements
        if (!qaMode) {
            if (beaDatabaseCode !== '') {
                if (timeIncrements) {
                    // fetchSignedUrl([timeIncrements[0]])
                    // await fetchAllProductData()
                    dispatch(setSelectedFrequencies([timeIncrements[0]]))
                    // console.log(productData.signedUrls, 'productData.signedUrls')
                    // executeDataTransformLogic()
                }
            } else {
                if (timeIncrements) {
                    if (bookMarkedTimeIncrement) {
                        dispatch(setSelectedBlobTimeIncrement(bookMarkedTimeIncrement))
                    }else if (!timeIncrement && timeIncrements && (timeIncrements[0] !== undefined)) {
                    // }else if (!timeIncrement && timeIncrements && (timeIncrements !== [''])) {
                        dispatch(setSelectedBlobTimeIncrement(timeIncrements[0]))
                    }
                }
            }
        }
    }, [
        productData.timeIncrements,
        productData.signedUrls,
        timeIncrement,
        qaMode,
        beaDatabaseCode,
        selectedDataOpt,
        bookMarkedTimeIncrement
    ])
    useEffect(() => {
        initialFetchingData()
    }, [
        productData.timeIncrements,
        timeIncrement,
        qaMode,
        selectedDataOpt
    ])
    useEffect(() => {
        if (selectedDataOpt === 'cloud-storage' && productData.selectedFrequencies.length > 0) {
            // fetchSignedUrl()
        }
    }, [
        productData.selectedFrequencies,
        selectedDataOpt
    ])
    useEffect(() => {
        if (timeIncrement !== null || (qaMode && selectedDataOpt)) {
            if (beaDatabaseCode == '') {
                fetchProductData(timeIncrement)
            } else if (qaMode) {
            }
        }
    }, [
        timeIncrement,
        selectedDataOpt,
        qaMode,
        beaDatabaseCode
    ])

    useEffect(() => {
        if (selectedDataOpt && productData.selectedFrequencies.length > 0) {
            executeDataTransformLogic()
        }
    }, [
        productData.selectedFrequencies,
        productData.selectedLines,
        productData.selectedYears,
        productData.selectedSeries,
        productData.selectedRows,
        productData.selectedColumns,
        productData.selectedAreas,
        productData.signedUrls,
        selectedDataOpt
    ])
    // useEffect(() => {
    //     if(selectedDataOpt === 'cloud-storage'){
    //         formatStockData()
    //     }
    // }, [loadedStocks, identifier, chartLibrary, selectedDataOpt])

    const setNotification = (obj) => {
        setState({ ...state, notification: obj })
    }

    const closeNotification = () => {
        setState({
            ...state, notification: {
                ...state.notification, message: '', open: false
            }
        })
    }

    const clickBlobHandler = (blob) => {
        const timeIncrements = productData.timeIncrements
        if (timeIncrements && !timeIncrement) {
            if (timeIncrements && (timeIncrements[0] !== undefined) && beaDatabaseCode === '' && timeIncrement === null) {
            // if (timeIncrements && (timeIncrements !== ['']) && beaDatabaseCode === '' && timeIncrement === null) {
                dispatch(setSelectedBlobTimeIncrement(timeIncrements[0]))
            }
        }
        console.log(blob, 'clicked??')
        setSelectedDataOpt(blob)
    }

    const clickMeasurePerformance = async () => {
        triggerIndividualQA({
            feedSource,
            databaseCode,
            productCode
        })
    }

    const renderChart = useCallback((viewMode) => {
        if (chartLibrary === 'tradingview-widget' && feedSourceType === 'stock') {
            return <MarketContainer
                chartLibrary={chartLibrary}
                feedSource={feedSource}
                databaseCode={databaseCode}
                productCode={productCode}
            />
        } else {
            try {
                let filteredData = loadedStocks[identifier] || []
                const { timeRange } = productData
                // const columnFilters = selectedFilter ? resolvePath(filters, selectedFilter + '.column_filters', []) : []
                // const columnOrders = selectedFilter ? resolvePath(filters, selectedFilter + '.orders', {}) : {}
                // for (let i in columnFilters) {
                //     const f = columnFilters[i]
                //     if (f && f.json) {
                //         const filterJson = JSON.parse(f.json)
                //         if (filterJson.length > 0) {
                //             filteredData = filteredData.filter(item => item[f.head_cell] && filterJson.indexOf(item[f.head_cell].toString()) > -1)
                //         }
                //     }
                // }
                return (
                    <ChartGraphView
                        feedSource={feedSource}
                        databaseCode={databaseCode}
                        productCode={productCode}
                        feedSourceType={feedSourceType}
                        data={filteredData}
                        chartLibrary={chartLibrary}
                        timeRange={timeRange}
                        identifier={identifier}
                        selectedDataOpt={selectedDataOpt}
                        isBeaDatabaseCode={beaDatabaseCode !== ''}
                    />
                )
            } catch (error) {
                console.error(error)
            }
        }
    }, [
        chartLibrary,
        identifier,
        productData.timeRange,
        feedSourceType,
        feedSource,
        databaseCode,
        productCode,
        loadedStocks,
        selectedDataOpt,
        beaDatabaseCode
    ])

    const renderTable = useCallback(() => {
        if (qaMode && !selectedDataOpt) return <></>
        return ['GDPbyIndustry', 'NIPA', 'ITA', 'FixedAssets', 'NIUnderlyingDetail', 'IO', 'IIP', 'IntlServTrade', 'MNE'].indexOf(beaDatabaseCode) > -1 ? (
            <NIPATableView
                feedSource={feedSource}
                databaseCode={databaseCode}
                productCode={productCode}
                blob={selectedDataOpt}
                openModifyDialog={openModifyDialog}
                handleMidifyDialog={(value) => {
                    setOpenModifyDialog(value)
                }}
                identifier={identifier}
            />
        ) : beaDatabaseCode === 'underlyingGDPbyIndustry' ? (
            <NIPATableView
                feedSource={feedSource}
                databaseCode={databaseCode}
                productCode={productCode}
                blob={selectedDataOpt}
                openModifyDialog={openModifyDialog}
                handleMidifyDialog={(value) => {
                    setOpenModifyDialog(value)
                }}
                dateColumn="time_period"
                identifier={identifier}
            />
        ) : ['NIUnderlyingDetail'].indexOf(beaDatabaseCode) > -1 ? (
            <BEADataTableView1
                // rows={rows[identifier] || []}
                // fields={columns[identifier] || []}
                // tableId={`${identifier}Inner`}
                blob={selectedDataOpt}
                openModifyDialog={openModifyDialog}
                handleMidifyDialog={(value) => {
                    setOpenModifyDialog(value)
                }}
                identifier={identifier}
            />
        ) : ['MNE1'].indexOf(beaDatabaseCode) > -1 ? (
            <MNETableView
                blob={selectedDataOpt}
                openModifyDialog={openModifyDialog}
                handleMidifyDialog={(value) => {
                    setOpenModifyDialog(value)
                }}
                identifier={identifier}
            />
        ) : beaDatabaseCode === 'IO' ? (
            <IODataTableView
                blob={selectedDataOpt}
                openModifyDialog={openModifyDialog}
                handleMidifyDialog={(value) => {
                    setOpenModifyDialog(value)
                }}
                identifier={identifier}
            />
        ) : beaDatabaseCode === 'Regional1' ? (
            <RegionalTableView
                blob={selectedDataOpt}
                openModifyDialog={openModifyDialog}
                handleMidifyDialog={(value) => {
                    setOpenModifyDialog(value)
                }}
            />
        ) : (
            <Box id={loadedStocks[identifier] ? identifier : ''} sx={{ display: 'flex', height: '100%' }}>
                <DataTableView
                    blob={selectedDataOpt}
                    data={loadedStocks[identifier] || []}
                    identifier={identifier}
                />
            </Box>
        )
    }, [
        feedSource,
        productCode,
        databaseCode,
        openModifyDialog,
        selectedDataOpt,
        loadedStocks,
        beaDatabaseCode,
        identifier,
        setOpenModifyDialog,
        qaMode
    ])

    const { notification } = state
    const { classes, setProductOpt } = props
    const { viewMode } = productData
    // console.log(productData.timeIncrements, 'productData?.timeIncrements')
    // console.log(productData.productName, productCode)
    // console.log(decodeURIComponent(productData.productName), 'decodeURIComponent(productData.productName)')

    const productName = useMemo(() => {
        try {
            return productData.productName ?
                decodeURIComponent(productData.productName) :
                decodeURIComponent(productCode)
        } catch (e) {
            return productData.productName ? productData.productName : productCode
        }
    }, [
        productData.productName,
        productCode
    ])
    return (
        <>
            <div className={classes.root}>
                <div className={classes.productContainer}>
                    <div className={classes.productHeader}>
                        <Box display="flex" sx={{ alignItems: 'center' }}>
                            <Box display="flex" sx={{ alignItems: 'center' }}>
                                <Typography className={classes.ProductCodeHeaderTxt} id={productData.productName ? 'QAProductName' : ''}>
                                    {
                                        productName
                                    }
                                </Typography>
                            </Box>
                            <Box display="flex" sx={{ alignItems: 'center', flex: 1, justifyContent: 'end' }}>
                                {/* <FilterPop className={classes.productStaticBarButton} data={[]} /> */}
                                <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box display="flex">
                                        {(beaDatabaseCode === '' || qaMode) && productData.timeIncrements && productData?.timeIncrements?.filter(item => item !== '').length > 0 && (
                                            <ButtonGroup disableElevation variant="contained" id="QATimeIncrementHandler">
                                                {productData?.timeIncrements?.map((inc) => (
                                                    <Button
                                                        color={timeIncrement === inc ? 'primary' : 'inherit'}
                                                        key={inc}
                                                        onClick={() => {
                                                            dispatch(setSelectedBlobTimeIncrement(inc))
                                                            if (beaDatabaseCode !== '') {
                                                                dispatch(setSelectedFrequencies([inc]))
                                                            }
                                                        }}
                                                        size="small"
                                                        className='QATimeIncrement'
                                                        data-time-increment={inc}
                                                    >{inc}</Button>
                                                ))}
                                            </ButtonGroup>
                                        )}
                                    </Box>
                                    {
                                        qaMode && (
                                            <Button
                                                id="QaMetaDataLoader"
                                                onClick={() => {
                                                    console.log('clicked QA???')
                                                    props.fetchProductSQL({
                                                        feed_source: feedSource,
                                                        database_code: databaseCode,
                                                        product_code: productCode
                                                    })
                                                }}
                                            >Fetch Product MetaData</Button>
                                        )
                                    }
                                </Box>
                                <Box>
                                    <Tooltip title="Start Quality Analytic">
                                        {/* <a
                                            href="#"
                                            onClick={clickMeasurePerformance}
                                            disabled={inMeasuring}
                                        >
                                            <PlayArrow />
                                        </a> */}
                                        <a
                                            
                                            href="#"
                                            onClick={clickMeasurePerformance}
                                            disabled={inMeasuring}
                                            className={`${classes.productStaticBarButton}`}>
                                                {
                                                    inMeasuring ?
                                                        <Autorenew className={classes.QualityAnaylicProgress}/> :
                                                        <Typography className={classes.QualityAnaylicCap}>Q</Typography>
                                                }
                                        </a>
                                    </Tooltip>
                                </Box>
                                <FavoriteButton
                                    isBeaDatabaseCode={beaDatabaseCode !== ''}
                                    feedSourceType={feedSourceType}
                                />
                                <Tooltip title="Modify">
                                    <Box
                                        sx={{ padding: '10px !important' }}
                                        mr={1}
                                        className={`${classes.productStaticBarButton}`}
                                        onClick={() => {
                                            setOpenModifyDialog(true)
                                        }}
                                    >
                                        <RefreshSharp style={{ fontSize: '22px' }} />
                                    </Box>
                                </Tooltip>
                                {
                                    NDL_SOURCES.indexOf(feedSource.toUpperCase()) > -1 ? (
                                        <Box sx={{ display: 'flex', justifyContent: 'end' }} id="QaBlobHandler">
                                            <Tooltip title="Cloud Storage">
                                                <Box
                                                    sx={{ padding: '10px !important' }}
                                                    className={`${classes.productStaticBarButton} ${selectedDataOpt === 'cloud-storage' ? 'selected' : ''}`}
                                                    mr={1}
                                                    onClick={() => clickBlobHandler('cloud-storage')}
                                                    id="cloud-storage-data-opt"
                                                >CS</Box>
                                            </Tooltip>
                                            <Tooltip title="Big Query">
                                                <Box
                                                    sx={{ padding: '10px !important' }}
                                                    className={`${classes.productStaticBarButton} ${selectedDataOpt === 'big-query' ? 'selected' : ''}`}
                                                    onClick={() => clickBlobHandler('big-query')}
                                                    id="big-query-data-opt"
                                                >BG</Box>
                                            </Tooltip>
                                        </Box>
                                    ) : qaMode ? (
                                        <Box sx={{ display: 'flex', justifyContent: 'end' }} id="QaDefaultBlobHandler">
                                            <Box className={classes.DataSwitcher}>
                                                <Box className={`${selectedDataOpt === 'cloud-storage' ? classes.SelectedDataSwitchOpt : ''}`}
                                                    mr={1}
                                                    onClick={() => clickBlobHandler('cloud-storage')}
                                                    id="default-cloud-storage-data-opt"
                                                >CS</Box>
                                            </Box>
                                        </Box>
                                    ) : (<></>)
                                }
                                <Box onClick={() => {
                                    setProductOpt({
                                        path: 'viewMode',
                                        value: viewMode === 'chart' ? 'datatable' : 'chart'
                                    })
                                }}>
                                    <a href="#"
                                        className={`${classes.productStaticBarButton} ${viewMode === 'chart' && 'selectedViewMode'}`}>
                                        <TrendingUpIcon />
                                        <span></span>
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                        {/* <div>
                            <Breadcrumbs separator="›" aria-label="breadcrumb"> <Typography key="3"
                                                                                            color="primary">
                                {decodeURIComponent(feedSource)}
                            </Typography> <Typography key="3" color="primary">
                                {decodeURIComponent(databaseCode)}
                            </Typography> </Breadcrumbs>
                        </div> */}
                    </div>
                    <div className={classes.productBody}>
                        {/* {
                            viewMode === 'chart' && feedSourceType !== 'stock' &&
                            <Box display="flex" sx={{justifyContent: 'end'}}> <Box sx={{width: 200, mr: 1}}>
                                <CustomFormSelect
                                    options={xAxisFields.map(item => item.id)}
                                    value={xAxis}
                                    label={'X-Axis'}
                                    fieldName="xAxis"
                                    onChange={(field, value) => props.setXAxis(value)}
                                /> </Box> <Box sx={{width: 200}}>
                                <CustomFormSelect
                                    options={yAxisFields.map(item => item.id)}
                                    value={yAxis}
                                    label={'Y-Axis'}
                                    fieldName="yAxis"
                                    onChange={(field, value) => props.setYAxis(value)}
                                />
                            </Box>
                            </Box>
                        } */}
                        <div className={classes.productStaticBar}>
                        </div>
                        <ErrorBoundary>
                            <Paper className={classes.paper}>
                                <div className={classes.container}>
                                    {
                                        props.loadingStockData || props.loadingInProgress || isLoading ? (
                                            <CircularProgress className={classes.CircularProgress} color="secondary" />
                                        ) : (
                                            (viewMode === 'datatable' || qaMode) ? (
                                                renderTable()
                                            ) : (
                                                renderChart(viewMode)
                                            )
                                        )
                                    }
                                </div>
                            </Paper>
                        </ErrorBoundary>
                        {/* <News feed_source={feedSource} database_code={databaseCode} product_code={productCode}/> */}
                    </div>
                </div>
            </div>
            <Snackbar
                open={notification.open}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={closeNotification}>
                <Alert onClose={closeNotification} severity={notification.type}>
                    {
                        notification.message
                    }
                </Alert>
            </Snackbar>
            {
                showQAResult && <IndividualResultDialog
                    open={showQAResult}
                    handleClose={handleCloseQAResultDialog}
                    feedSource={feedSource}
                    databaseCode={databaseCode}
                    productCode={productCode}
                    qaResults={qaResults}
                />
            }
            {
                1 === 2 && (
                    <Dialog
                        open={showQAResult}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseQAResultDialog}
                        aria-describedby="alert-dialog-slide-description"
                        maxWidth={'md'}
                    >
                        <DialogTitle>{"QA Result"}</DialogTitle>
                        <DialogContentText sx={{ padding: '0 24px' }}>
                            <Box display="flex">
                                <Box className={classes.qaMetricItemLabel}>Initial Load Seconds:</Box>
                                <Box>{qaResults[0]?.initial_load_seconds ? `${convertToSeconds(qaResults[0]?.initial_load_seconds)}s (${qaResults[0]?.initial_load_seconds}ms)` : ''}</Box>
                            </Box>
                            <Box display="flex">
                                <Box className={classes.qaMetricItemLabel}>Meta Data Load Seconds:</Box>
                                <Box>{qaResults[0]?.metadata_load_seconds ? `${convertToSeconds(qaResults[0]?.metadata_load_seconds)}s (${qaResults[0]?.metadata_load_seconds}ms)` : ''}</Box>
                            </Box>
                        </DialogContentText>
                        <DialogContent>
                            <CustomMuiDataTable
                                data={qaResults}
                                columns={[
                                    { id: 'loading_source', name: 'loading_source', label: 'Blob Source' },
                                    { id: 'time_increment', name: 'time_increment', label: 'Time Increment' },
                                    {
                                        id: 'blob_load_seconds',
                                        name: 'blob_load_seconds',
                                        label: 'Blob Load Seconds',
                                        options: {
                                            customBodyRender: (value, tableMeta) => {
                                                if (value) {
                                                    return <>{`${convertToSeconds(value)}s (${value}ms)`}</>
                                                }
                                            },
                                        }
                                    },
                                    {
                                        id: 'time_to_draw_seconds',
                                        name: 'time_to_draw_seconds',
                                        label: 'Time to Draw',
                                        options: {
                                            customBodyRender: (value, tableMeta) => {
                                                if (value) {
                                                    return <>{`${convertToSeconds(value)}s (${value}ms)`}</>
                                                }
                                            },
                                        }
                                    },
                                    { id: 'last_error', name: 'last_error', label: 'Note' }
                                ]}
                                options={{
                                    print: false,
                                    download: false,
                                    delete: false,
                                    selectableRows: false,
                                    pagination: false,
                                    search: false,
                                    filter: false,
                                    viewColumns: false,
                                    selectableRowsOnClick: false,
                                    selectableRowsHeader: false,
                                    selectableRowsHideCheckboxes: true,
                                }}
                            />
                            <TimeLineChart
                                timeIncrements={productData?.timeIncrements || []}
                                data={qaResults}
                                hasCloudStorage={NDL_SOURCES.indexOf(feedSource.toUpperCase()) > -1}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseQAResultDialog}>Close</Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex'
    },
    ProductCodeHeaderTxt: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '-0.02em',
        color: '#374151',
    },
    CircularProgress: {
        margin: '100px auto'
    },
    productContainer: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
        minWidth: 0
    },
    productStaticBar: {
        display: 'flex',
        alignItems: 'center',
        '& ul': {
            padding: '0',
            listStyle: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: '7px',
            flex: 1,
        }
    },
    productStaticBarButton: {
        cursor: 'pointer',
        padding: '3px 3px',
        position: 'relative',
        transition: 'all ease-in .2s',
        borderRadius: '4px',
        margin: '0 6px',
        boxShadow: '0 0 1px 1px #e5e5f1',
        display: 'flex',
        flexFlow: 'column',
        color: '#4e4b4b',
        '&.selected': {
            boxShadow: '0 0 1px 1px #646467'
        },
        '&:hover': {
            background: '#ececec'
        },
        '&.selectedViewMode': {
            background: '#3c54db',
            color: 'white',
            '&:hover': {
                background: '#5e72e4'
            },
        },
        '& svg': {
            display: 'block',
            margin: '0 auto',
            fontSize: '35px'
        },
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     height: '1px',
        //     borderTop: 'solid 1px #e2dcdc',
        //     bottom: '-6px',
        //     width: 'calc(100% - 6px)'
        // },
        // '&:last-child': {
        //     '&::after': {
        //         content: 'initial'
        //     }
        // }
    },
    productHeader: {
        padding: '14px 12px',
        '& h2': {
            marginBottom: 0,
            wordBreak: 'break-all'
        },
        // borderBottom: '1px solid #E5ECFA'
    },
    productBody: {
        flex: 1,
        padding: '0 12px 10px',
        display: 'flex',
        flexFlow: 'column',
        overflow: 'auto'
    },
    paper: {
        flex: 1,
        display: 'flex',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 3px 0px rgb(0 0 0 / 12%)',
        overflow: 'auto'
    },
    container: {
        flex: 1,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexFlow: 'column'
    },
    DataSwitcher: {
        display: 'inline-flex',
        alignItems: 'center',
        border: 'solid 1px #a79c9c',
        borderRadius: '17px',
        marginTop: '15px',
        padding: '2px',
    },
    DataSwitchOpt: {
        cursor: 'pointer',
        padding: '4px 12px',
        borderRadius: '15px'
    },
    SelectedDataSwitchOpt: {
        background: '#1976d2',
        color: 'white'
    },
    qaMetricItemLabel: {
        fontWeight: 'bold',
        fontSize: '14px',
        marginRight: '10px'
    },
    '@keyframes rotate-circle': {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
    QualityAnaylicCap: {
        // fontSize: '34px',
        padding: '5px 9px',
        lineHeight: 1,
        fontSize: '24px'
    },
    QualityAnaylicProgress: {
        // fontSize: '39px !important',
        // padding: 0,
        fontSize: '27px !important',
        margin: '3px !important',
        animation: '$rotate-circle 2s linear infinite',
    }
}))

function StockDetail(props) {
    const classes = useStyles()
    return (
        <StockDetailComponent classes={classes} {...props} />
    )
}

const mapStateToProps = (state) => ({
    chartData: state.chartData,
    productData: state.productData,
    user: state.user.user,
    loadedStocks: state.productData.loadedProductData,
    loadingInProgress: state.productData.loadingInProgress,
    loadingStockData: state.productData.loadingStockData
})

const mapDispatchToProps = (dispatch) => ({
    setProductOpt: (payload) => dispatch(setProductOpt(payload)),
    setXAxisFields: (payload) => dispatch(setXAxisFields(payload)),
    setYAxisFields: (payload) => dispatch(setYAxisFields(payload)),
    setXAxis: (payload) => dispatch(setXAxis(payload)),
    setYAxis: (payload) => dispatch(setYAxis(payload)),
    setFeedSourceType: (payload) => dispatch(setFeedSourceType(payload)),
    setLoadedFeedSourceType: (payload) => dispatch(setLoadedFeedSourceType(payload)),
    fetchProductSQL: (payload) => dispatch(fetchProductSQL(payload)),
    fetchStockProductData: (payload) => dispatch(fetchStockProductData(payload)),
    fetchStockProductSignedUrl: (payload) => dispatch(fetchStockProductSignedUrl(payload)),
    fetchStockProductDataForTransform: (payload) => dispatch(fetchStockProductDataForTransform(payload)),
    fetchBQDataForTransform: (payload) => dispatch(fetchBQDataForTransform(payload)),
    fetchStockProductOfBigQuery: (payload) => dispatch(fetchStockProductOfBigQuery(payload)),
    showSnackbarMessage: (payload) => dispatch(showSnackbarMessage(payload)),
    clearStockProductData: () => dispatch(clearStockProductData()),
    initProductMetaData: () => dispatch(initProductMetaData())
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(StockDetail)

/* eslint no-restricted-globals:0 */

import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  Button,
  ButtonGroup
} from "@mui/material"

import {
  setSelectedFrequencies,
  setSelectedBlobTimeIncrement
} from 'store/slices/StockProductSlice'

import { Box } from "@mui/system"
import {useProductContext} from "providers/product/ProductProvider";
import CustomFormSelect from "components/common/Base/CustomFormSelect";

const TimeIncrementsBox = (props) => {
  const {
    beaDatabaseCode,
    feedSource,
    databaseCode,
    productCode,
  } = useProductContext();

  const dispatch = useDispatch()
  const [qaMode, setQaMode] = useState();

  const {
    selectedBlobTimeIncrement,
    timeIncrements,
  } = useSelector(state => state.productData)

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={{ maxWidth: '100px' }}>
      {
        selectedBlobTimeIncrement && (
          <CustomFormSelect
            size="small"
            options={timeIncrements || []}
            value={selectedBlobTimeIncrement}
            label={''}
            fieldName="info"
            onChange={(field, value) => {
              dispatch(setSelectedBlobTimeIncrement(value))
              if (beaDatabaseCode !== '') {
                dispatch(setSelectedFrequencies([value]))
              }
              // dispatch(setChartFeature({ path: 'scatterLineMarker', value: value }))
            }}
          />
        )
      }
      <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between', display: 'none' }}>
        <Box display="flex">
          {timeIncrements && timeIncrements?.filter(item => item !== '').length > 0 && (
            <ButtonGroup disableElevation variant="contained" id="QATimeIncrementHandler">
              {timeIncrements?.filter(item => item !== '').map((inc) => (
                <Button
                  color={selectedBlobTimeIncrement === inc ? 'primary' : 'inherit'}
                  key={inc}
                  onClick={() => {
                    dispatch(setSelectedBlobTimeIncrement(inc))
                    if (beaDatabaseCode !== '') {
                      dispatch(setSelectedFrequencies([inc]))
                    }
                  }}
                  size="small"
                  className='QATimeIncrement'
                  data-time-increment={inc}
                >{inc}</Button>
              ))}
            </ButtonGroup>
          )}
        </Box>
        {
          qaMode && (
            <Button
              id="QaMetaDataLoader"
              onClick={() => {
                props.fetchProductSQL({
                  feed_source: feedSource,
                  database_code: databaseCode,
                  product_code: productCode
                })
              }}
            >Fetch Product MetaData</Button>
          )
        }
      </Box>
    </Box>
  )
}

export default TimeIncrementsBox

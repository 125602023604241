import { tsvParse, csvParse } from  "d3-dsv";
import {format} from "d3-format";
import { timeParse, timeFormat } from "d3-time-format";

const dateFormat = timeFormat("%Y-%m-%d");
const numberFormat = format(".2f");

function parseData(parse) {
    return function(d) {
        d.date = parse(d.date);
        d.open = +d.open;
        d.high = +d.high;
        d.low = +d.low;
        d.close = +d.close;
        d.volume = +d.volume;

        return d;
    };
}

const parseDate = timeParse("%Y-%m-%d");

export function getDataFromTSV() {
    const promiseMSFT = fetch("https://cdn.rawgit.com/rrag/react-stockcharts/master/docs/data/MSFT.tsv")
        .then(response => response.text())
        .then(data => tsvParse(data, parseData(parseDate)))
    return promiseMSFT;
}

export function getIntervalNumber(d, days) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    // d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()|| days));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/days);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}

export function getTooltipContent(ys, isStock = true) {
	let defautlY = [];
	return ({ currentItem, xAccessor }) => {
		if(isStock){
			defautlY = [
				{
					label: "open",
					value: currentItem.open && numberFormat(currentItem.open)
				},
				{
					label: "high",
					value: currentItem.high && numberFormat(currentItem.high)
				},
				{
					label: "low",
					value: currentItem.low && numberFormat(currentItem.low)
				},
				{
					label: "close",
					value: currentItem.close && numberFormat(currentItem.close)
				}
			]
		}
		return {
			x: dateFormat(xAccessor(currentItem)),
			y: defautlY.concat(
					ys.map(each => ({
						label: each.label,
						value: each.value(currentItem),
						stroke: each.stroke
					}))
				)
				.filter(line => line.value)
		};
	};
}
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isStep2Shown: true,
  step2Sidebar: 'source',
  step1Sidebar: 'data',
};

const appLayouSlice = createSlice({
  name: 'appLayout',
  initialState,
  reducers: {
    setVisibleStep2: (state, action) => {
      state.isStep2Shown = action.payload
    },
    selectStep2Sidebar: (state, action) => {
      state.step2Sidebar = action.payload
    },
    selectStep1Sidebar: (state, action) => {
      state.step1Sidebar = action.payload
    },
  },
  extraReducers: {},
});

export const prepareSelector = state => state.appLayout;
export const {
  setVisibleStep2,
  selectStep2Sidebar,
  selectStep1Sidebar
} = appLayouSlice.actions

export default appLayouSlice.reducer;

import React, { useState }  from "react";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth'
import {useHistory} from "react-router-dom"
import {
    NavLink,
} from "react-router-dom";
import PlotterVideo from '../../assets/videos/Plotter_MP4.mp4';
import '../../styles/custom/auth/login.scss';

import { Box, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

/* Home component */
const QALogin = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [isVideoLoading, setIsVideoLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleLogin = () => {
        const authentication = getAuth()
        if(email === '' || password === '') return;
        signInWithEmailAndPassword(authentication, email, password)
            .then((response) => {
                history.push('/home')
            })
            .catch(e => {
                console.log(JSON.stringify(e), 'e')
                if(e.code === 'auth/wrong-password' || e.code === 'auth/user-not-found'){
                    dispatch(showSnackbarMessage({
                        message: 'Incorrect Email or Password',
                        severity: 'error'
                    }))   
                }
            })
    }
    return <>
        <div className={`full-page login-page login-fade-page`}>
            <div className="login-page-inner">
                <NavLink className='login-demo-button btn btn-info' to='/demo'>Demo</NavLink>
                <div className="login-page-logo">
                    <video
                        muted
                        autoPlay
                        src={PlotterVideo}
                        preload={'auto'}
                        type={'video/mp4'}
                        onLoadedData={() => {
                            setIsVideoLoading({isVideoLoading: false});
                        }}>
                    </video>
                </div>
                <div className="login-page-content">
                    <form className="form" id="login-form" method="POST" action="#">
                        <div className="login-header">
                            <h1>Hello, <br/> Welcome back</h1>
                        </div>
                        <Box
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField id="email" label="Enter the Email*" variant="outlined" value={email} onChange={e => setEmail(e.target.value)} />
                            <TextField id="password" label="Enter the Password*" type="password" variant="outlined" value={password} onChange={e => setPassword(e.target.value)}/>
                        </Box>
                        <div className="login-btns">
                            <a href="#" className={`btn btn-lg btn-block mb-3 loginBtn`} id="QaLogin" onClick={handleLogin}>Log in</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>;
}


export default QALogin;

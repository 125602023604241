import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom"
import { clearSections, fetchFeedSections } from 'store/slices/EodDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from "@mui/material";
import ListItem from './Details/ListItem';
import LoadingOverlay from 'components/common/LoadingOverlay';

const ListItemComponent = ({item, active, clickHandler, setHovered}) => (
    <Box
        className={`item ${active ? "active" : ""}`}
        onClick={() => clickHandler(item)}
        onMouseLeave={() => setHovered(undefined)}
    >
        <ListItem
            selected={active}
        >
            {item.section_name} 
        </ListItem>
    </Box>
)

const Lists = (props) => {
    const { sections: items = [] } = props;
    const { feedSource: pFeedSource } = useParams()
    const [cursor, setCursor] = useState(0)
    const [hovered, setHovered] = useState(undefined)
    const {feedSource} = props

    useEffect(() => {
        if (items.length && hovered) {
            setCursor(items.indexOf(hovered))
        }
    }, [hovered])

    const clickHandler = (item) => {
        if (feedSource === '' || item.section_name === '') {
            return
        }
        props.changeStep2Index(3, item.section_name)
        setCursor(items.indexOf(item))
    }

    return (
        <>
            {items.map((item, i) => (
                <ListItemComponent
                    key={i}
                    active={cursor === 0 ? (feedSource === pFeedSource) : i === cursor}
                    item={item}
                    clickHandler={clickHandler}
                    setHovered={setHovered}
                />
            ))}
        </>
    )
}

const FeedSectionBox = (props) => {
    const { isLoading } = props;

    const sections = useSelector(state => state.eodData.sections)
    
    return <Box
        style={{ overflow: 'auto', height: '100%' }}
    >
        {
            sections?.length > 0 ? (
                <Lists
                    sections={sections}
                    {...props}
                />
            ) : !isLoading ? (
                <Box className='text-center'>There is no Section</Box>
            ) : <></>
        }
    </Box>
}

export default function StockSections(props) {
    const { feedSource, feedDatabaseCode: databaseCode, searchWord = '' } = props
    const dispatch = useDispatch();
    
    const isLoading = useSelector(state => state.eodData.loadingSections)
    
    
    const triggerFetchFeedSections = (params = {}) => {
        if(feedSource && feedSource !== ''){
            dispatch(fetchFeedSections({
                feedSource,
                databaseCode,
                ...params
            })).then(r => {
                console.log(r, 'r')
            })
        }
    }
    useEffect(() => {
        if(feedSource && feedSource !== '' && databaseCode && databaseCode !== ''){
            if(!searchWord || searchWord === ''){
                dispatch(clearSections())
                triggerFetchFeedSections()
            }else{
                dispatch(clearSections({ flag: 'search' }))
                triggerFetchFeedSections({searchWord})
            }
        }
    }, [
        feedSource,
        databaseCode,
        searchWord
    ])
    
    return (
        <Box style={{ overflow: 'auto', height: '100%' }} id="Qa-FeedSectionWrapper">   
            <LoadingOverlay isLoading={isLoading}>
                <FeedSectionBox
                    triggerFetchFeedSections={triggerFetchFeedSections}
                    isLoading={isLoading}
                    {...props}
                />
            </LoadingOverlay>
        </Box>
    );
}
import React from "react";
import {removeObjectProperties} from "shared/utilities/common";
import clsx from "clsx";
import LabelIcon from '@mui/icons-material/Label';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {
  TextField,
  IconButton,
  MenuItem,
  Badge,
  Grid,
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
  TextField: {
    '& .MuiInputBase-root': {
      background: 'white'
    },
    "& .MuiOutlinedInput-notchedOutline legend": { display: "none", }
  }
}))

export default function CustomTextField(_props) {
  const classes = useStyles()
  const props = {..._props};
  let { emptyChoice, select, value, inputProps, SelectProps, InputLabelProps, emptyChoiceDisabled, denseListItems, showHelpButtons } = props;
  removeObjectProperties(props, ['emptyChoice', 'value', 'inputProps', 'SelectProps', 'InputLabelProps', 'emptyChoiceDisabled', 'denseListItems', showHelpButtons])

  let {style: inputPropsStyle, className: inputPropsClassName} = inputProps ?? {};
  removeObjectProperties(inputProps, [ 'style', 'className' ]);
  
  let {style: SelectPropsStyle, className: SelectPropsClassName} = SelectProps ?? {};
  removeObjectProperties(SelectProps, [ 'style', 'className' ]);

  let {style: InputLabelPropsStyle, className: InputLabelPropsClassName} = InputLabelProps ?? {};
  removeObjectProperties(InputLabelProps, [ 'style', 'className' ]);

  // Default values initialization
  let inputBackgroundColor;
  let inputColor;
  let inputVisible = true;
  let inputFontSize = null;
  let required = false;
  let disabled = false;
  let inputHelpText;
  let inputClasses;
  let values = [];

  let labelColor;
  let labelVisible = true;
  let labelFontSize = null;
  let labelText = props.label;
  let labelHelpText;
  let labelClasses;
  
  if(!select && value === null) value = '';

  if(props.disabled) {
    disabled = props.disabled;
  }
  
  return (
    <>
    { inputVisible &&
        <Grid container direction={"row"} align={"left"}>
          <Grid item xs>
            <TextField
              {...props}
              fullWidth
              inputProps={{...inputProps, style:{...inputPropsStyle, fontSize: inputFontSize + "pt", backgroundColor: inputBackgroundColor, color: inputColor}, className: clsx(inputPropsClassName, inputClasses)}}
              SelectProps={{...SelectProps, style:{...SelectPropsStyle, fontSize: inputFontSize + "pt", backgroundColor: inputBackgroundColor, color: inputColor}, className: clsx(SelectPropsClassName, inputClasses)}}
              InputLabelProps={{...InputLabelProps,
                style:{...InputLabelPropsStyle, fontSize: labelFontSize + "pt", color: labelColor, zIndex: 1},
                className: clsx(InputLabelPropsClassName, labelClasses),
                shrink: true
              }}
              label={labelVisible ? labelText : ''}
              required={props.required || required}
              disabled={disabled}
              value={value}
              className={classes.TextField}
            >
              { emptyChoice && <MenuItem value="" disabled={emptyChoiceDisabled} dense={denseListItems}> { emptyChoice ? emptyChoice : "&nbsp;" } </MenuItem> }

              { values.length > 0 &&
                values.map((value) => ( <MenuItem key={value.id} value={value.id} dense={denseListItems}> {value.value} </MenuItem> ))
              }

              { values.length === 0 &&
                props.children
              }
              </TextField>
            </Grid>
          { showHelpButtons && ( labelHelpText || inputHelpText) &&
            <Grid item lg={3} xs={2} align={"center"} style={{alignSelf:"center"}}>
                <Badge badgeContent={<ContactSupportIcon/>} >
                { labelHelpText && <IconButton size="small" color="secondary" onClick={ () => {}}><LabelIcon /></IconButton> }
                { inputHelpText && <IconButton size="small" color="secondary" onClick={ () => {}}><TextFieldsIcon /></IconButton> }
                </Badge>
            </Grid>
          }
        </Grid>
      }
    </>
  );
}

import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Chip
} from "@mui/material";
import { Close, MailOutline } from '@mui/icons-material';
import CustomFormInput from "components/common/Base/CustomFormInput";

class TextDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			text: props.text,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			text: nextProps.text,
		});
	}
	handleChange(field, value) {
		this.setState({
			text: value
		});
	}
	handleSave() {
		this.props.onSave(this.state.text);
	}
	render() {
		const {
			open,
			onClose,
		} = this.props;
		const { text } = this.state;

		return (
			<>
				<Dialog
					open={open}
					onClose={onClose}
					aria-labelledby="form-dialog-title"
					// maxWidth={'lg'}
				>
					<DialogTitle id="form-dialog-title">Interactive Text</DialogTitle>
					<DialogContent>
						<CustomFormInput
							label="Text"
							fieldName="text"
							value={text} onChange={this.handleChange}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.handleSave}
							color="primary"
						>
							<MailOutline />
							Save
						</Button>
						<Button onClick={onClose} color="primary">
							<Close />
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default TextDialog
import React from 'react'
import { SvgIcon } from "@mui/material";

const EditIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M6.7647 2.64706H2.64706C1.73741 2.64706 1 3.38447 1 4.29412V13.3529C1 14.2626 1.73741 15 2.64706 15H11.7059C12.6155 15 13.3529 14.2626 13.3529 13.3529V9.23529M12.1883 1.48241C12.8315 0.839196 13.8744 0.839196 14.5176 1.48241C15.1608 2.12563 15.1608 3.16849 14.5176 3.8117L7.44694 10.8824H5.11765L5.11765 8.55306L12.1883 1.48241Z" stroke="#6B7280" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};

export default EditIcon;

import React, { useMemo } from "react";

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import moment from 'moment'
import _ from 'underscore'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Highcharts from 'highcharts/highcharts'
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import ReactHighcharts from 'highcharts-react-official'
import { Box } from "@mui/system";
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

function TestChart(props) {
    const configStock = useMemo(() => {
      return {
        title: {
            text: 'Test Chart for view'
        },
        xAxis: {
            categories: ['Jet fuel', 'Duty-free diesel', 'Petrol', 'Diesel', 'Gas oil']
        },
        yAxis: {
            title: {
                text: 'Million liter'
            }
        },
        labels: {
            items: [{
                html: 'Total liter',
                style: {
                    left: '50px',
                    top: '18px',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'black'
                }
            }]
        },
        series: [{
            type: 'column',
            name: '2020',
            data: [59, 83, 65, 228, 184]
        }, {
            type: 'column',
            name: '2021',
            data: [24, 79, 72, 240, 167]
        }, {
            type: 'column',
            name: '2022',
            data: [58, 88, 75, 250, 176]
        }, {
            type: 'spline',
            name: 'Average',
            data: [47, 83.33, 70.66, 239.33, 175.66],
            marker: {
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[3],
                fillColor: 'white'
            }
        }, {
            type: 'pie',
            name: 'Liter',
            data: [{
                name: '2020',
                y: 619,
                color: Highcharts.getOptions().colors[0] // 2020 color
            }, {
                name: '2021',
                y: 586,
                color: Highcharts.getOptions().colors[1] // 2021 color
            }, {
                name: '2022',
                y: 647,
                color: Highcharts.getOptions().colors[2] // 2022 color
            }],
            center: [100, 80],
            size: 100,
            showInLegend: false,
            dataLabels: {
                enabled: false
            }
        }]
      }
    }, [])
    
    return (
      <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
        <Box display="flex" sx={{ flexDirection: 'column', height: '100%' }}>
          <Box flex={1}>
            <ReactHighcharts
              highcharts={Highcharts}
              constructorType={"chart"}
              options={configStock}
              containerProps={{ style: { height: "100%" } }}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    )
}

export default TestChart
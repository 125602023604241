import {createSlice,createAsyncThunk,createEntityAdapter} from '@reduxjs/toolkit';



const initialState = {
  theme: null,
  chartType:'line',
  timeSeriesChartType: 'line',
  scatterEnabled: false,
  scatterRadius: 3,
  step: false,
  shadow: false,
  spline: false,
  pointDataLabelEnabled: false,
  dashStyle: null,
  zones: [],
  plotBands: [],
  plotLines: [],
  fieldConfigs: {
    "BEA/MNE/Debt Instruments Inflows": {
      "xAxis": "year",
      "yAxis": [
        "datavalue"
      ]
    },
    'Econoday/Events/CPI': {
      xAxis: "year",
      yAxis: ['actual']
    }
  },
  arearange: {
    "BEA/MNE/Debt Instruments Inflows": {
      "groupByField": "row",
      "xAxis": "year",
      "yAxis": [
        "datavalue",
      ],
      "minimumField": "datavalueunformatted",
      "maximumField": "datavalueunformatted"
    }
  },
  column: {
    "BEA/MNE/Debt Instruments Inflows": {
      "groupByField": "row",
      "xAxis": "year",
      "yAxis": [
        "datavalue"
      ]
    }
  },
};

const highChartDataSlice = createSlice({
  name: 'highChartData',
  initialState,
  reducers: {
    setChartType: (state, action) => {
      state.chartType = action.payload
    },
    setTimeSeriesChartType: (state, action) => {
      state.timeSeriesChartType = action.payload
    },
    setChartTheme: (state, action) => {
      state.theme = action.payload
    },
    setScatterEnabled: (state, action) => {
      state.scatterEnabled = action.payload
    },
    setScatterRadius: (state, action) => {
      state.scatterRadius = action.payload
    },
    setStepEnabled: (state, action) => {
      state.step = action.payload
    },
    setShadowEnabled: (state, action) => {
      state.shadow = action.payload
    },
    setSplineEnabled: (state, action) => {
      state.spline = action.payload
    },
    setPointDataLabelEnabled: (state, action) => {
      state.pointDataLabelEnabled = action.payload
    },
    setDashStyle: (state, action) => {
      state.dashStyle = action.payload
    },
    setSeriesZones: (state, action) => {
      state.zones = [...action.payload]
    },
    setPlotBands: (state, action) => {
      state.plotBands = [...action.payload]
    },
    setPlotLines: (state, action) => {
      state.plotLines = [...action.payload]
    },
    setAreaRangeConfig: (state, action) => {
      const { path, configs } = action.payload
      state.arearange[path] = {...configs}
    },
    setColumnConfig: (state, action) => {
      const { path, configs } = action.payload
      state.column[path] = {...configs}
    },
    setFieldConfig: (state, action) => {
      const { path, configs } = action.payload
      state.fieldConfigs[path] = {...configs}
    }
  },
  extraReducers: {},
});

export const prepareSelector = state => state.chartData;
export const {
  setChartType,
  setTimeSeriesChartType,
  setChartTheme,
  setScatterEnabled,
  setScatterRadius,
  setStepEnabled,
  setShadowEnabled,
  setSplineEnabled,
  setPointDataLabelEnabled,
  setDashStyle,
  setSeriesZones,
  setPlotBands,
  setPlotLines,

  setAreaRangeConfig,
  setColumnConfig,
  setFieldConfig
} = highChartDataSlice.actions
export default highChartDataSlice.reducer;

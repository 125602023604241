import React, { useEffect } from "react";
import { compose } from "recompose";
import { connect, useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { withRouter } from "react-router";

import { 
  Box
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Alert from '@mui/lab/Alert';

import RragNonMarketChart from "components/common/Rrag/NonMarketChart";
import HighNonMarketChart from "components/common/High/NonMarket";
import CustomFormSelect from "components/common/Base/CustomFormSelect";

import {
  setXAxis, setYAxis
} from "../../../store/actions"

import _ from 'underscore'
import { TypeChooser } from "libs/rrag/lib/helper";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100%',
    padding: '10px'
  }
}));

function NonMarketContainer(props) {
  const classes = useStyles();
  const { data, chartLibrary } = props
  const { feedSource, databaseCode, productCode } = useParams()
  
  const nonMarket = useSelector((state) => state.chartData.timeseries)
  const xAxis = useSelector(state => state.productData.xAxis || null)
  const yAxis = useSelector(state => state.productData.yAxis || null)
  
  const chartType = nonMarket['chartType'];
  const checked = nonMarket['checked'] || [];
  const tooltips = nonMarket['tooltips'] || [];
  const entityField = nonMarket['entityField'] || null;

  console.log(xAxis, yAxis, ' x & y')
  if(!(chartType && xAxis && yAxis)){
    return <Alert severity="info">Please check Values of Object, Chart Type and Axis of Tool Bar!</Alert>
  }

  return (
    <div className={classes.root}>
      {
        chartLibrary === 'rrag' ? (
          <TypeChooser>
            {type => <RragNonMarketChart
              type={type}
              chartType={chartType} xAxis={xAxis} yAxis={yAxis}
              tooltips={tooltips}
              checked={checked}
              entityField={entityField}
              {...props} />
            }
          </TypeChooser>
        ) : (
            <HighNonMarketChart
              data={data}
              path={`${feedSource}/${databaseCode}/${productCode}`}
              xAxis={xAxis} yAxis={yAxis}
            />
          )
          // <HighNonMarketChart 
          //   data={data}
          //   xAxis={xAxis} yAxis={yAxis}
          //   entityField={entityField}
          //   checked={checked}
          // />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  nonMarket: state.chartData.timeseries,
  chartData: state.chartData
});

const mapDispatchToProps = (dispatch) => ({

});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NonMarketContainer);
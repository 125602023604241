import React, { useState, useMemo } from 'react';

import {
  Grid,
  Box,
  Button,
} from "@mui/material";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import SeriesAccordion from '../../details/series/SeriesAccordion'
import {useDispatch, useSelector} from "react-redux";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {addSeries, setXAxis, updateSeries} from "store/slices/StockProductSlice";
import {useProductContext} from "providers/product/ProductProvider";

const DefaultPanel = () => {
  const dispatch = useDispatch()
  const { addChartColors } = useProductContext()
  const { yAxisFields, fields, series, xAxis } = useSelector(state => state.productData)
  const [expandedPanel, setExpandedPanel] = useState('series1')

  const onPanelChange = (val) => {
    if(expandedPanel === val){
      setExpandedPanel('')
    }else{
      setExpandedPanel(val)
    }
  }
  const usedFields = useMemo(() => {
    return series.map(item => item.value)
  })
  console.log(series, 'series')
  const addNewSeries = () => {
    console.log(usedFields, 'usedFields')
    console.log(yAxisFields, 'yAxisFields')
    const field = yAxisFields.filter(item => usedFields.indexOf(item.id) < 0)[0]
    console.log(field, 'field')
    if(field) {
      dispatch(addSeries({
        value: field.id
      }))
      addChartColors()
    }else{
      alert('No available field')
    }
  }

  return (
    <Grid item lg={12} md={12} xs={12}>
      <Box>
        <DropdownSelect
          selectedValue={xAxis}
          onChange={(val) => dispatch(setXAxis(val))}
          options={fields}
          placeholder={'X Axis'}
          style={{ marginBottom: '10px' }}
        />
        {
          series.map((item, idx) => (
            <SeriesAccordion
              isExpanded={expandedPanel === item.id}
              onChange={() => onPanelChange(item.id)}
              style={{ marginBottom: '10px' }}
              key={idx}
              seriesLabel={`Series ${idx + 1}`}
              seriesItem={item}
              onUpdateSerie={(field, val) => dispatch(updateSeries({ id: item.id, field, newValue: val }))}
            />
          ))
        }
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          component="label"
          variant="contained"
          tabIndex={-1}
          startIcon={<AddCircleIcon />}
          style={{ marginLeft: 'auto', marginTop: '10px' }}
          onClick={addNewSeries}
        >
          Add Series
        </Button>
      </Box>
    </Grid>
  )
};

export default DefaultPanel
import LocalStorageService from "store/services/LocalStorageService";
// import {setLocale} from "../slices/PersoSlice";

const storageService = LocalStorageService.getService();

const localStorage = store => next => action => {
  // console.log(action.type, 'action.type')
  // const accessToken = storageService.getAccessToken()
  // console.log(accessToken, 'accessToken')
  switch (action.type) {
    // case 'authentication/login/fulfilled':
    //  localStorageService.setToken('token')
    //   break;
    // case 'authentication/logout/fulfilled':
    //   localStorageService.clearToken();
    //   break;
    // no default
  }

  return next(action);
}

export default localStorage;
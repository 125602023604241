import React, {Component}  from "react";

/* Covid component */
class CovidView extends Component{
    constructor(props) {
        super(props);
        this.state = {
            covidStates: []
        }
    }
    async componentDidMount() {
        const url = 'eod/covid/fetch_data';
    }

    render() {
        return (
            <h1>Hi</h1>
        );
    }
}
export default CovidView;
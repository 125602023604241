import React, { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { Box, IconButton, styled } from '@mui/material'
import { makeStyles } from "@mui/styles";
import { InputAdornment, Popper } from "@mui/material"
import TextField from '@mui/material/TextField'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import {
    Lock,
    LockOpen,
    Search as SearchIcon
} from '@mui/icons-material';

import { cancleSearchStock, fetchSearchData } from "store/slices/SearchSlice"
import { setSearchWord, setTopSearchWord } from "store/slices/EodDataSlice"
import API from 'store/services/AxiosService'
import { paramsToQueryParam } from "store/UrlUtils";

const useStyles = makeStyles(() => ({
    Autocomplete: {
        borderRadius: 20
    },
    Txt: {
        color: 'black',
        fontSize: '13px'
    },
    TextField: {
        '& .MuiInputBase-root': {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            paddingRight: '16px !important',
            '& fieldset': {
                border: '0 !important'
            },
            '& .MuiIconButton-root:not(.SearchLocker-Btn)': {
                display: 'none'
            },
            '& .SearchLocker-Btn': {
                padding: 0
            }
        }
    },
    SearchLocker: {
        borderLeft: 'solid 1px #c9c9c9',
        padding: '4px 11px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minWidth: '62px',
        transform: 'translate(16px, 0)',
        cursor: 'pointer'
    }
}))

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
})

/* Home component */
function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

const Search = (props) => {
    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    const step2Index = useSelector(state => state.eodData.step2Index)
    const searchResults = useSelector(state => state.search.searchResults)
    const { feedSource, feedDatabaseCode } = useSelector(state => state.eodData);

    let suggestions = []
    const [state, setState] = useState({
        searchWord: null,
        redirect: false
    })
    const [isLocked, setIsLocked] = useState(false)
    const [loading, setLoading] = useState(false)

    // const handleClick = useCallback(() => {
    //     dispatch(cancleSearchStock())
    //     if(step2Index === 1){
    //         dispatch(setSearchWord({
    //             field: 'databaseCode',
    //             value: state.searchWord
    //         }))
    //     }else if(step2Index === 2){
    //         dispatch(setSearchWord({
    //             field: 'productCode',
    //             value: state.searchWord
    //         }))
    //     }
    // }, [
    //     step2Index,
    //     state.searchWord
    // ]);

    async function findSuggestions(value) {
        try {
            const response = await API.get('/eod/search_suggestions' + paramsToQueryParam({ "suggestion": value }))
            if (response) {
                response.data.forEach(element => suggestions.push(element[0]))
                // setOptions(suggestions)
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const search = useCallback(async (value) => {
        try {
            let results = []
            if (value) {
                setLoading(true)
                const params = {
                    search: value,
                    global: true
                }
                if (feedSource && feedSource !== '') {
                    params.global = false;
                    params.feed_source = feedSource
                }
                if (feedDatabaseCode && feedDatabaseCode !== '') {
                    params.database_code = feedDatabaseCode
                }
                await dispatch(
                    fetchSearchData(params)
                )
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }, [
        feedSource,
        feedDatabaseCode,
        dispatch,
        setLoading
    ])

    function suggest(value) {
        if (value === '') {
            return ''
        }
        search(value)
        setState({
            ...state, searchWord: value
        })
    }

    const change = (event, value, reason) => {
        if (reason === "selectOption") {
            const url = `/product/detail/${encodeURIComponent(value.feed_source)}/${encodeURIComponent(value.database_code)}/${encodeURIComponent(value.product_code)}`
            history.push(url)
        }
    }
    const handleSearch = async (event) => {
        if (event.key === 'Enter') {
            if (state.searchWord !== '') {
                await search(state.searchWord)
                dispatch(setTopSearchWord(state.searchWord))
            }
        }
    }

    const [open, setOpen] = useState(false)


    useEffect(() => {
        let active = true

        if (!loading) {
            return undefined
        }

        (async () => {
            await sleep(1e3) // For demo purposes.
        })()

        return () => {
            active = false
        }
    }, [loading])

    useEffect(() => {
        if (!open) {
            // setOptions([])
        }
    }, [open])
    
    return (
        <>
            <Autocomplete
                sx={{
                    width: '100%',
                    background: 'white'
                }}
                open={open}
                onOpen={() => {
                    setOpen(true)
                }}
                PopperComponent={StyledPopper}
                onClose={() => {
                    setOpen(false)
                }}
                value={state.searchWord}
                filterOptions={(x) => x}
                onInputChange={
                    (event, value, reason) => {
                        suggest(value)
                    }}
                onChange={change}
                onKeyPress={handleSearch}
                isOptionEqualToValue={(option, value) => option.label === value}
                className={classes.Autocomplete}
                options={searchResults}
                loading={loading}
                renderInput={(params) => (
                    <TextField {...params}
                        className={classes.TextField}
                        placeholder="Search anything here"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <React.Fragment>
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                            // endAdornment: (
                            //     <React.Fragment>
                            //         {loading && (
                            //             <CircularProgress color="inherit" size={20} />
                            //         )}
                            //         {params.InputProps.endAdornment}
                            //         <Box className={classes.SearchLocker} onClick={handleClick}>
                            //             <IconButton className="SearchLocker-Btn">
                            //                 <Lock />
                            //             </IconButton>
                            //         </Box>
                            //     </React.Fragment>
                            // ),
                        }}
                    />
                )}
            />
        </>
    )
}

export default Search

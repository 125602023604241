

import React, { Component } from "react";
import PropTypes from "prop-types";

import GenericChartComponent from "../GenericChartComponent";

import {format} from "d3-format";
import {timeFormat} from "d3-time-format";
import { HoverTooltip } from "../tooltip";
const dateFormat = timeFormat("%Y-%m-%d");
const numberFormat = format(".2f");

function tooltipContent(ys) {
	return ({ currentItem, xAccessor }) => {
		return {
			x: dateFormat(xAccessor(currentItem)),
			y: [
				{
					label: "open",
					value: currentItem.open && numberFormat(currentItem.open)
				},
				{
					label: "high",
					value: currentItem.high && numberFormat(currentItem.high)
				},
				{
					label: "low",
					value: currentItem.low && numberFormat(currentItem.low)
				},
				{
					label: "close",
					value: currentItem.close && numberFormat(currentItem.close)
				}
			]
				.concat(
					ys.map(each => ({
						label: each.label,
						value: each.value(currentItem),
						stroke: each.stroke
					}))
				)
				.filter(line => line.value)
		};
	};
}

class Annotate extends Component {
	constructor(props) {
		super(props);
		this.renderSVG = this.renderSVG.bind(this);
		this.renderSVG1 = this.renderSVG1.bind(this);
	}
	render() {
		return <> 
			{/* <HoverTooltip
				// yAccessor={maYExtents}
				tooltipContent={tooltipContent([
					{
						label: 'a',
						value: d => 'd',
						stroke: 'red'
					}
				])}
				fontSize={15}
			/> */}
			{/* <GenericChartComponent
				svgDraw={this.renderSVG1}
				drawOn={['pan']}
			/> */}
			<GenericChartComponent
				svgDraw={this.renderSVG}
				drawOn={["pan"]}
			/>
		</>
	}
	renderSVG1(){
		return (
			<>
				<g fill="white" stroke="green" stroke-width="5">
					<rect fill="blue" stroke-width="2" height="112" width="84" y="55" x="55" stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null" stroke="#000000"/>
				</g>
			</>
		)
	}
	renderSVG(moreProps) {
		const { xAccessor } = moreProps;
		// console.log(moreProps, 'more props')
		const { xScale, chartConfig: { yScale }, plotData } = moreProps;

		const { className, usingProps, with: Annotation } = this.props;
		const data = helper(this.props, plotData);

		return (
			<g className={`react-stockcharts-enable-interaction ${className}`}>
				{data.map((d, idx) => <Annotation key={idx}
					{...usingProps}
					xScale={xScale}
					yScale={yScale}
					xAccessor={xAccessor}
					plotData={plotData}
					datum={d} />)}
			</g>
		);
	}
}

Annotate.propTypes = {
	className: PropTypes.string,
	with: PropTypes.func,
	when: PropTypes.func,
	usingProps: PropTypes.object,
};

Annotate.defaultProps = {
	className: "react-stockcharts-annotate react-stockcharts-default-cursor",
};

function helper({ when }, plotData) {
	return plotData.filter(when);
}

export default Annotate;

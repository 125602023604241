const LocalStorageService = (function () {
    let _service;

    function _getService() {
      if (!_service) {
        _service = this;
        return _service
      }
      return _service
    }

    // State -------------------------------------------------------------------------------------//

    function _saveState(state) {
        try {
            const serializedState = JSON.stringify(state);
            localStorage.setItem('state', serializedState);
        } catch (error) {
            // Ignore write errors
        }
    }

    function _getState() {
        try {
            const serializedState = localStorage.getItem('state');
            if (serializedState === null) {
                return undefined;
            }
            return JSON.parse(serializedState);
        } catch (error) {
            return undefined;
        }
    }
    function _clearState() {
        localStorage.setItem('state')
    }

    // Token -------------------------------------------------------------------------------------//
    function _setDefaultGoogleUser(token) {
      localStorage.setItem('defaultGoogleUser', token);
    }

    function _getDefaultGoogleUser() {
        return localStorage.getItem('defaultGoogleUser');
    }

    function _clearDefaultGoogleUser() {
        localStorage.removeItem('defaultGoogleUser');
    }

    // PlotterUID --------------------------------------------------------------------------------//
    function _setPlotterUID(plotterUID) {
        localStorage.setItem('plotterUID', plotterUID);
    }

    function _getPlotterUID() {
        return localStorage.getItem('plotterUID');
    }

    function _clearPlotterUID() {
      localStorage.removeItem('plotterUID');
    }
    // Plotter Access Token --------------------------------------------------------------------------------//
    function _setAccessToken(accessToken) {
        localStorage.setItem('accessToken', accessToken);
    }

    function _getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    function _clearAccessToken() {
      localStorage.removeItem('accessToken');
    }

    // Firebase Auth --------------------------------------------------------------------------------//
    function _setAuth(authData) {
        localStorage.setItem('authData', JSON.stringify(authData));
    }

    function _getAuth() {try {
        const serializedAuth = localStorage.getItem('authData');
            if (serializedAuth === null) {
                return undefined;
            }
            return JSON.parse(serializedAuth);
        } catch (error) {
            return undefined;
        }
    }

    function _clearAuth() {
      localStorage.removeItem('authData');
    }

    
    // Firebase Auth --------------------------------------------------------------------------------//
    function _addLogCount() {
        const _logCount = localStorage.getItem('logCount') || 0
        localStorage.setItem('logCount', _logCount * 1 + 1);
    }

    function _getLogCount() {
        return localStorage.getItem('logCount') || 0
    }

    function _clearLogCount() {
      localStorage.removeItem('logCount');
    }

    function _setRememberedPath(path) {
        localStorage.setItem('rememberedPath', path)
    }
    function _getRememberedPath(){
        return localStorage.getItem('rememberedPath')
    }
    function _clearRememberedPath(){
        return localStorage.removeItem('rememberedPath')
    }


    return {
        getAuth: _getAuth,
        setAuth: _setAuth,
        clearAuth: _clearAuth,
        getService: _getService,
        setState: _saveState,
        getState: _getState,
        clearState: _clearState,
        setDefaultGoogleUser: _setDefaultGoogleUser,
        getDefaultGoogleUser: _getDefaultGoogleUser,
        clearDefaultGoogleUser: _clearDefaultGoogleUser,
        setPlotterUID: _setPlotterUID,
        getPlotterUID: _getPlotterUID,
        clearPlotterUID: _clearPlotterUID,
        setAccessToken: _setAccessToken,
        getAccessToken: _getAccessToken,
        clearAccessToken: _clearAccessToken,
        addLogCount: _addLogCount,
        getLogCount: _getLogCount,
        clearLogCount: _clearLogCount,
        setRememberedPath: _setRememberedPath,
        getRememberedPath: _getRememberedPath,
        clearRememberedPath: _clearRememberedPath,
    }
  })();

  export default LocalStorageService;

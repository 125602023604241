import React, { useEffect, useState } from 'react'
import _ from "underscore"

import { makeStyles } from "@mui/styles";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Select,
    Grid,
    Typography
} from '@mui/material';
import { Settings } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectTimeIncrements } from 'store/selectors/ProductData';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flex: '1',
        flexFlow: 'column',
        overflow: 'auto',
    },
    Typography: {
        color: 'black'
    },
    InputLabel: {
        marginTop: '10px',
        fontWeight: 'bold'
    }
}))

function DataTableOptionsDialog(props) {
    const classes = useStyles()
    const {
        open,
        onClose,
        years,
        lines,
        options: initialOptions,
        handleOptions,
        blob,
        areas,
        databaseCode,
        rows,
        columns
    } = props

    const timeIncrements = useSelector(selectTimeIncrements)
    const frequencyLabels = {
        'A': 'Annual',
        'Q': 'Quarterly seasonally adjusted',
        'M': 'Monthly seasonally adjusted'
    }

    const [options, setOptions] = useState({ ...initialOptions })
    const onChangeOptions = (key, value) => {
        setOptions({
            ...options, [key]: value
        })
    }

    useEffect(() => {
        if (!options.frequency && timeIncrements[0]) {
            onChangeOptions('frequency', [timeIncrements[0]])
        }
    }, [
        options.frequency,
        timeIncrements
    ])


    const handleChangeMultiple = (event) => {
        const { options: opts } = event.target;
        const value = [];
        for (let i = 0, l = opts.length; i < l; i += 1) {
            if (opts[i].selected) {
                value.push(opts[i].value);
            }
        }
        const inputName = event.target.name
        onChangeOptions(inputName, value)
    };
    return (
        <Dialog
            open={open}
            keepMounted
            onClose={onClose}
            maxWidth={'lg'}
        >
            <DialogTitle>
                <Box display="flex" sx={{ alignItems: 'center' }}>
                    <Settings />
                    <Typography ml={1} className={classes.Typography}>Data Table Options</Typography>
                </Box>
            </DialogTitle>
            <DialogContentText sx={{ padding: '0 24px' }}>
            </DialogContentText>
            <DialogContent>
                <Box minWidth={500}>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={6}>
                            <Typography className={`${classes.Typography} ${classes.InputLabel}`}>Years</Typography>
                        </Grid>
                        <Grid item lg={8} md={6}>
                            <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                <Select
                                    multiple
                                    native
                                    value={options.year}
                                    name="year"
                                    // @ts-ignore Typings are not considering `native`
                                    onChange={handleChangeMultiple}
                                    // label="Native"
                                    inputProps={{
                                        id: 'select-multiple-native',
                                    }}
                                >
                                    {years.map((y) => (
                                        <option key={y} value={y}>
                                            {y}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            databaseCode === 'IntlServTrade' ? (
                                <>
                                    <Grid item lg={3} md={6}>
                                        <Typography className={`${classes.Typography} ${classes.InputLabel}`}>Areas</Typography>
                                    </Grid>
                                    <Grid item lg={8} md={6}>
                                        <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                            <Select
                                                multiple
                                                native
                                                value={options.area}
                                                name="area"
                                                onChange={handleChangeMultiple}
                                            >
                                                {areas.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {item.formattedLabel}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            ) : databaseCode === 'MNE' ? (
                                <>
                                    <Grid item lg={3} md={6}>
                                        <Typography className={`${classes.Typography} ${classes.InputLabel}`}>Rows</Typography>
                                    </Grid>
                                    <Grid item lg={8} md={6}>
                                        <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                            <Select
                                                multiple
                                                native
                                                value={options.row}
                                                name="row"
                                                onChange={handleChangeMultiple}
                                            >
                                                {rows.map((item) => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={3} md={6}>
                                        <Typography className={`${classes.Typography} ${classes.InputLabel}`}>Columns</Typography>
                                    </Grid>
                                    <Grid item lg={8} md={6}>
                                        <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                            <Select
                                                multiple
                                                native
                                                value={options.column}
                                                name="column"
                                                onChange={handleChangeMultiple}
                                            >
                                                {columns.map((item) => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                            ) : (
                                timeIncrements.length > 0 && timeIncrements[0] != '' && (
                                    <>
                                        <Grid item lg={3} md={6}>
                                            <Typography className={`${classes.Typography} ${classes.InputLabel}`}>Frequency</Typography>
                                        </Grid>
                                        <Grid item lg={8} md={6}>
                                            <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                                <Select
                                                    multiple
                                                    native
                                                    value={options.frequency}
                                                    name="frequency"
                                                    onChange={handleChangeMultiple}
                                                >
                                                    {timeIncrements.map((t) => (
                                                        <option key={t} value={t}>
                                                            {frequencyLabels[t]}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )
                            )
                        }
                        <Grid item lg={3} md={6}>
                            <Typography className={`${classes.Typography} ${classes.InputLabel}`}>Lines</Typography>
                        </Grid>
                        <Grid item lg={8} md={6}>
                            <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
                                <Select
                                    multiple
                                    native
                                    value={options.line}
                                    name="line"
                                    onChange={handleChangeMultiple}
                                >
                                    {lines.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent={"end"} padding={1}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            handleOptions(options)
                            onClose()
                        }}>Refresh Table</Button>
                    <Button onClick={onClose} color="error">Close</Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default DataTableOptionsDialog
import React, { useState } from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    drawer: {
        position: 'fixed',
        top: 0,
        right: 0,
        height: '100%',
        width: 0,
        backgroundColor: 'white',
        overflowX: 'hidden',
        transition: '0.3s',
        zIndex: 1111,
        boxShadow: '0px 29px 14px 10px #dddbdb',
      },
      opened: {
        width: '500px',
      },
      
      innerOverlay: {
        width: '100%',
        height: '100%',
      },
      
      drawerContent: {
        width: '500px',
        height: '100%',
        backgroundColor: '#fff',
      },
      
      closeButton: {
        position: 'absolute',
        top: '15px',
        right: '10px',
        width: '30px',
        textAlign: 'center',
        cursor: 'pointer',
        margin: '0px',
        fontSize: '14px',
        letterSpacing: '0.005em',
        padding: '3px 5px',
        borderRadius: '5px',
        fontWeight: 600,
        lineHeight: '16px',
        border: '1px solid rgb(230, 233, 237)',
        transition: 'all 100ms ease 0ms, padding-top 0ms ease 0ms, padding-bottom 0ms ease 0ms',
        color: 'rgb(67, 74, 84)',
        backgroundColor: 'rgb(255, 255, 255)',
        zIndex: 1,
      },
      
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgb(241 240 240 / 50%)',
        zIndex: 1100,
      }
}))
const RightSideDrawer = ({ isOpen, onClose, children }) => {
    const styles = useStyles()
    const [isOpened, setIsOpened] = useState(isOpen);

    const handleClose = () => {
        // setIsOpened(false);
        onClose();
    };

    return (
        <>
            {isOpen && <div className={styles.overlay} onClick={handleClose} />}
            <div className={`${styles.drawer} ${isOpen ? styles.opened : ''}`}>
                <div className={styles.drawerContent}>
                    <div className={`${styles.closeButton}`} onClick={handleClose}>
                        &times;
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default RightSideDrawer;

import * as React from 'react';
import { useHistory } from 'react-router-dom';

import {
    Box,
    Button,
    IconButton,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    AlertTitle,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import StartQADialog from './StartQADialog';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '@mui/icons-material';
import { resetAlertDialog, showAlertDialog } from 'store/slices/MessagesSystemSlice';
import useFetchQAReports from 'hooks/FetchQAReports';
import { checkFrontendQAReport, deleteFrontendQABatch, updateFrontendQABatch } from 'store/slices/FrontendQA';
import ResumeDialog from './components/ResumeDialog';
import Alert from 'components/common/Base/Alert';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "10px"
    },
    TableCap: {
        padding: '13px',
        alignItems: 'center',
        background: 'white',
        boxShadow: '14px 6px 4px -21px rgba(0,0,0,0.2), 0px -4px 2px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    },
    Table: {
        marginTop: '11px',
        background: 'white'
    },
    TableTitle: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '140%',
        margin: '0px',
        fontSize: '14px',
        letterSpacing: '-0.006em',
        fontWeight: '600',
        color: 'rgb(67, 74, 84)',
        marginRight: '10px'
    },
    TableSubTitle: {

    }
}))

export default function QAReportTable({
    title,
    feedSource,
    databaseCode,
    onClickView
}) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const history = useHistory()

    const {
        fetchQaReports,
        deleteQAReport,
        state: {
            qaReports: reportData,
            loading: loading
        }
    } = useFetchQAReports(dispatch)
    const [opend, setOpend] = React.useState(false)
    const [openResume, setOpenResume] = React.useState(false)
    const [anlayticDialogConfig, setAnalyticDialogConfig] = React.useState({})
    const [endPercentage, setEndPercentage] = React.useState(0)
    const [inProgressReport, setInProgressReport] = React.useState({
    })

    const checkReportProcess = React.useCallback(async (successCallback = () => {}) => {
        const { payload: { batchGroup: batchGroup, status, errorReport = {}  } } = await dispatch(checkFrontendQAReport({
            feedSource,
            databaseCode
        }))

        if (!status) {
            if(errorReport.id){
                setInProgressReport({ ...errorReport })
                setOpenResume(true)
            }
            // setOpenSkipConfirmDialog(true)
            // setTempBatchGroupId(batchGroup.id)
            // setTempBatchIndex(batchGroup.completed_batch_size * 1 + 1)
            // setTempBatchSize(batchGroup.batch_size)
            return;
        }
        if(successCallback){
            successCallback()
        }
    }, [

    ])

    React.useEffect(() => {
        fetchQaReports({
            feedSource,
            databaseCode
        })
        checkReportProcess()
    }, [
        feedSource,
        databaseCode
    ])
    const alertDialog = useSelector(state => state.messagesSystem.alertDialog)
    React.useEffect(() => {
        const { dialogAction, clickedButton, data } = alertDialog;
        if (clickedButton === "alertDialogButtonLeft") {
            if (dialogAction === 'deleteQAReport') {
                deleteQAReport(data)
            }
        }
        dispatch(resetAlertDialog());
    }, [alertDialog.clickedButton]);

    const onSkipReport = async () => {
        await dispatch(updateFrontendQABatch({
            feedSource,
            databaseCode,
            status: 'skipped',
            id: inProgressReport.id
        })).then(() => {
            setOpenResume(false)
            setInProgressReport({})
        })
    }
    const onClickReset = async () => {
        await deleteQAReport({
            id: inProgressReport.id,
            batchGroupId: inProgressReport.batch_group_id
        })
        setOpenResume(false)
        setInProgressReport({})
    }
    const onClickResume = async () => {
        setOpenResume(false)
        setOpend(true)
    }

    return (
        <>
            <TableContainer className={classes.root}>
                <Box display="flex" sx={{ justifyContent: 'space-between' }} className={classes.TableCap}>
                    <Box>
                        <Typography className={classes.TableTitle}>{title}</Typography>
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            size='small'
                            onClick={() => {
                                checkReportProcess(() => {
                                    setOpend(true)
                                })
                            }}
                        >New Analytic</Button>
                    </Box>
                </Box>
                {/* <Alert
                    severity="error"
                    className='mt-3'
                    action={
                        <Box display="flex" sx={{ alignItems: 'center' }}>
                            <Button color="inherit" size="small">
                                Retry
                            </Button>
                            <Button color="inherit" size="small">
                                Reset
                            </Button>
                        </Box>
                    }
                >
                    <AlertTitle>503 Error</AlertTitle>
                    <Box>
                        <span>Error happened in the Batch 2/3.</span>
                    </Box>
                </Alert>
                <Alert
                    severity="info"
                    className='mt-3'
                    action={
                        <Box display="flex" sx={{ alignItems: 'center' }}>
                            <Button color="inherit" size="small">
                                Next
                            </Button>
                        </Box>
                    }
                >
                    <AlertTitle>Manual Batch</AlertTitle>
                    <Box>
                        <span>Batch 2/3 is done.</span>
                    </Box>
                </Alert>
                <Alert
                    severity="warning"
                    className='mt-3'
                    action={
                        <Box display="flex" sx={{ alignItems: 'center' }}>
                            <Button color="inherit" size="small">
                                Retry
                            </Button>
                        </Box>
                    }
                >
                    <AlertTitle>Automatic Batch</AlertTitle>
                    <Box>
                        <span>Automatic Batch(of 4 batch size) has been stopped unexpectedly(batch 3/3, batch 3/4)</span>
                    </Box>
                </Alert> */}
                <Table sx={{ minWidth: 650 }} className={classes.Table} aria-label="qa report table">
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography>Last QA Date</Typography></TableCell>
                            <TableCell>
                                <Typography className='pl-1'>Report</Typography>
                            </TableCell>
                            <TableCell><Typography>No Error/Error</Typography></TableCell>
                            <TableCell><Typography>Count of Good</Typography></TableCell>
                            <TableCell><Typography>Count of Average</Typography></TableCell>
                            <TableCell><Typography>Count of Below Average</Typography></TableCell>
                            <TableCell><Typography>Batch Process</Typography></TableCell>
                            <TableCell><Typography>Type</Typography></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ? (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell colSpan={7} className="text-center">
                                        <Typography>Loading...</Typography>
                                    </TableCell>
                                </TableRow>

                            ) : (
                                reportData && reportData.length > 0 ? (
                                    reportData.map((r, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <Tooltip title="Last QA Date">
                                                    <span>
                                                    {new Date(r.last_frontend_qa_date).toLocaleDateString(undefined, {
                                                        year: 'numeric',
                                                        month: 'numeric',
                                                        day: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                        second: 'numeric',
                                                    })}
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Button color='error' onClick={() => {
                                                    let url = `/qa/result/${feedSource}/${databaseCode}/${r.id}`
                                                    // if (r.product_code && r.product_code !== '') {
                                                    //     url += `?productCode=${r.product_code}`
                                                    // }
                                                    history.push(url)
                                                }}>View Report</Button>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="No Error/Error">
                                                    <Box display={'flex'}>
                                                        <Typography sx={{ color: '#1976d2' }}>{r.pass_count}</Typography>/<Typography sx={{ color: '#df3838' }}>{r.fail_count}</Typography>
                                                    </Box>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Count of Good">
                                                    <Box display={'flex'}>
                                                        <Typography sx={{ color: '#1976d2' }}>{r.count_of_good}</Typography>
                                                    </Box>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Count of Average">
                                                    <Box display={'flex'}>
                                                        <Typography sx={{ color: '#1976d2' }}>{r.count_of_average}</Typography>
                                                    </Box>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Count of Below Average">
                                                    <Box display={'flex'}>
                                                        <Typography sx={{ color: '#1976d2' }}>{r.count_of_below_average}</Typography>
                                                    </Box>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {
                                                    r.type == 3 && (
                                                        <Tooltip title="Batch Process">
                                                            <span>{r.batch_index}/{r.batch_size} {r.status === 'skipped' && '(skipped)'}</span>
                                                        </Tooltip>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                <Tooltip title="Type">
                                                    <span>
                                                    {
                                                        r.type == 1 ? 'RUN All at Once' :
                                                        r.type == 2 ? 'Individual' : 'Run Batch'
                                                    }
                                                    </span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={e => {
                                                        dispatch(showAlertDialog({
                                                            title: "Confirmation",
                                                            message: "Are you sure you want to delete?",
                                                            buttonLabel: { left: "Ok", right: "Cancel" },
                                                            dialogAction: "deleteQAReport",
                                                            data: {
                                                                id: r.id,
                                                                batchGroupId: r.batch_group_id
                                                            }
                                                        }));
                                                    }}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell colSpan={7}>
                                            There is no Data
                                        </TableCell>
                                    </TableRow>
                                )
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {
                opend && <StartQADialog
                    feedSource={feedSource}
                    databaseCode={databaseCode}
                    open={opend}
                    endPercentage={endPercentage}
                    handleClose={(isUpdated) => {
                        setOpend(false)
                        setEndPercentage(0)
                        setAnalyticDialogConfig({})
                        
                        if(isUpdated){
                            fetchQaReports({
                                feedSource,
                                databaseCode
                            })
                        }
                        setInProgressReport({})
                    }}
                    resumeData={inProgressReport}
                    type={anlayticDialogConfig.type}
                    productCode={anlayticDialogConfig.productCode}
                />
            }
            {
                openResume && <ResumeDialog
                    open={openResume}
                    errorType={inProgressReport.error_type}
                    errorDate={inProgressReport.last_frontend_qa_date}
                    onSkip={onSkipReport}
                    onReset={onClickReset}
                    onRestart={onClickResume}
                />
            }
        </>
    );
}
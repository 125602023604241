import React  from "react";
import {
    NavLink,
    withRouter
} from "react-router-dom";
import {connect} from "react-redux";

import { errorNotification } from '../../shared/system/notifications/store/reducers';

import PlotterVideo from '../../assets/videos/Plotter_MP4.mp4';
import '../../styles/custom/auth/login.scss';

import GoogleLogin from "../../components/common/FirebaseAuth"

/* Home component */
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVideoLoading: false
        };
        this.renderView = this.renderView.bind(this);
    }
    componentDidMount() {
        this.setState({isVideoLoading: true})
    }
    renderView(){
        return  <>
                <div className={`full-page login-page login-fade-page`}>
                    <div className="login-page-inner">
                        <NavLink className='login-demo-button btn btn-info' to='/demo'>Demo</NavLink>
                        <div className="login-page-logo">
                            <video
                                muted
                                autoPlay
                                src={PlotterVideo}
                                preload={'auto'}
                                type={'video/mp4'}
                                onLoadedData={() => {
                                    this.setState({isVideoLoading: false});
                                }}>
                            </video>
                        </div>
                        <div className="login-page-content">
                            <form className="form" id="login-form" method="POST" action="#">
                                <div className="login-header">
                                    <h1>Hello, <br/> Welcome back</h1>
                                </div>
                                    <GoogleLogin/>
                            </form>
                        </div>
                    </div>
                </div>
            </>;
    }
    render(){
        return this.renderView();
    }
}

const mapState = (state) => ({
    userState: state.user
});

const mapDispatch = (dispatch) => ({
    notifyError: (params) => dispatch(errorNotification(params)),
});

export default withRouter(connect(mapState, mapDispatch)(Login));

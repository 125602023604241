import React, { useMemo, useState } from "react";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { makeStyles } from "@mui/styles";
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";

// import { removeObjectProperties, propValue, isPropEquals } from "../../../Utils/CommonFunctions";

import {
  IconButton,
  Popover
} from "@mui/material";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import FilterListIcon from '@mui/icons-material/FilterList';

import SwipeableViews from "react-swipeable-views";
import _ from 'underscore'

import DateFilter from 'components/common/PlotterDateFilter'
import DatumFilter from 'components/common/PlotterDatumFilter'

import { ProductContext } from 'providers/product/ProductContext';


const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '357px',
    '& >*': {
      color: 'black'
    }
  },
  catalogs: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    padding: '17px',
    '& > li': {
      cursor: 'pointer',
      padding: '10px 0',
      borderBottom: 'solid 1px #d5d5d5',
      '&:last-child': {
        borderBottom: 'none'
      },
      '& > span': {
        paddingLeft: '10px'
      },
      '& > svg': {
        position: 'relative',
        bottom: '1px'
      }
    }
  },
  sorting: {
    color: '#9e9e9e',
    '&.active': {
      color: 'black'
    }
  },
  typography: {
    padding: theme.spacing(2),
  },
  bold: { fontWeight: "bold" },
  italic: { fontStyle: "italic" },
  underline: { textDecoration: "underline" }
}));

function DataTableColumnFilterPopover(_props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const props = { ..._props };

  const [swipIndex, setSwipIndex] = useState(0)
  const [swipName, setSwipName] = useState('catalog')

  const handleSwipChange = (sIndex, sName) => {
    setSwipIndex(sIndex)
    setSwipName(sName)
  }
  const {
    open,
    anchorEl,
    headCell,
    order,
    handleClose,
    handleSelection,
    direction,
    handleSortClick,
    filterValues,
    hasFilter = true
  } = props

  let distincts = ['(Blanks)'];

  return (
    <>
      <Popover
        id={'data-table-column-filter-popover'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {
          <div className={classes.root}>
            <SwipeableViews
              axis='x'
              index={swipIndex}
              style={{ overflowX: 'hidden' }}
            >
              <ul className={classes.catalogs}
                value={swipIndex}
                index={0}
              >
                <li>
                  <SortByAlphaIcon fontSize="inherit" />
                  <span className={clsx(classes.sorting, direction === 'asc' && 'active')} onClick={() => {
                    handleSortClick('asc')
                  }}>Ascending</span>,
                  <span className={clsx(classes.sorting, direction === 'desc' && 'active')} onClick={() => {
                    handleSortClick('desc')
                  }}>Descending</span>
                </li>
                {
                  hasFilter && (
                    <li onClick={() => {
                      handleSwipChange(1, 'filter')
                    }}>
                      <FilterListIcon fontSize="inherit" />
                      <span>Filter</span>
                    </li>
                  )
                }
              </ul>
              {
                swipName === 'filter' ?
                  <DatumFilter
                    value={swipIndex}
                    index={1}
                    distincts={distincts}
                    goBack={handleSwipChange}
                    onSelect={(checkedItems) => {
                      handleSelection(checkedItems)
                    }}
                    selected={filterValues}
                    {...props}
                  /> :
                  swipName === 'test' ?
                    <div value={swipIndex} index={1} style={{ width: '100px' }}>
                      <h1>Ascending, Descending</h1>
                    </div> :
                    <h1>Other</h1>
              }
            </SwipeableViews>
          </div>
        }
      </Popover>
    </>
  );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DataTableColumnFilterPopover);
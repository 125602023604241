import React, {useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setProductOpt} from "store/slices/StockProductSlice";
import ExpandablePanelBox from "components/common/Base/ExpandablePanelBox";
import CustomFormInput from "components/common/Base/CustomFormInput";
import BaseAccordion from "../../BaseAccordion";
import {DropdownSelectWithInput} from "components/common/Product/DropdownSelectWithInput";
import {makeStyles} from "@mui/styles";
import {CustomizeItem, EndNode, StartNode} from "components/common/Product/CustomizeItem";
import {Box, Checkbox, FormControlLabel, Button, IconButton} from "@mui/material";
import {BpCheckbox} from "../../BpCheckbox";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import ColorPicker from "components/common/ColorPicker";
import {North, NorthEast, NorthWest, South, SouthEast, SouthWest} from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  AccordionItem: {
    marginBottom: 10
  },
  Button: {
    width: '35px',
    height: '35px',
    padding: 0,
    minWidth: 0,
    fontSize: '19px'
  }
}));
const bgColors = [
  '#fff','#fce4ec', '#ffcdd2', '#f3e5f5', '#e8eaf6', '#e3f2fd', '#d1c4e9',
  '#c5cae9', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#e0f2f1', '#e6ee9c', '#fff9c4',
  '#eeeeee', '#ffccbc'
]

export const LegendFeature = () => {
  const dispatch = useDispatch()
  const { legend } = useSelector(state => state.productData.chartSettings)
  const classes = useStyles()

  const [expandedPanel, setExpandedPanel] = useState('')
  const [isLabelStyleOpened, setIsLabelStyleOpened] = useState(false)
  const [isPlacementOpened, setIsPlacementOpened] = useState(true)
  const onPanelChange = (val) => {
    if(expandedPanel === val){
      setExpandedPanel('')
    }else{
      setExpandedPanel(val)
    }
  }
  const axisFormatOptions = [
    { label: 'YYYY/mm/dd', value: '{value:%Y/%m/%d}' },
    { label: 'Sep 12, 2024({Value:%b %e, %Y})', value: '{value:%b %e, %Y}' },
    { label: 'MMM DD, YYYY', value: '{value:%m %d, %Y}' },
    { label: '14-12-2020 ({Value:%D-%M-%Y})', value: '{value:%d-%m-%Y}' },
    { label: '12-14-2020 ({Value:%M-%D-%Y})', value: '{value:%m-%d-%Y}' },
    { label: '2020-12-14 ({Value:%Y-%M-%D})', value: '{value:%Y-%m-%d}' },
    { label: '{Value}', value: '{value}' },
    { label: '$12 (${Value})', value: '${value}' },
    { label: '12 USD ({Value} USD)', value: '{value} USD' },
    { label: '12.00 ({Value:.2f})', value: '{value:.2f}' },
  ]
  const [axisFormat, setAxisFormat] = useState({
    selected: '{value}',
    modified: '{value}'
  })

  const onAlignPlacement = (position) => {
    const [verticalAlign, align] = position.split('-')
    dispatch(setProductOpt({
      path: 'chartSettings.legend.align', value: align
    }))
    dispatch(setProductOpt({
      path: 'chartSettings.legend.verticalAlign', value: verticalAlign
    }))
  }
  const items = [
    { icon: () =>  <NorthWest />, position: 'top-left' },
    { icon: () =>  <SouthWest />, position: 'bottom-left' },
    { icon: () =>  <North />, position: 'top-center' },
    { icon: () =>  <South />, position: 'bottom-center' },
    { icon: () =>  <NorthEast />, position: 'top-right' },
    { icon: () =>  <SouthEast />, position: 'bottom-right' },
  ]

  return (
      <Box display="flex" sx={{ flexFlow: 'column', gap: '5px' }}>
        <CustomizeItem>
          <StartNode>Visibility</StartNode>
          <EndNode>
            <FormControlLabel
              value="visibility"
              control={<BpCheckbox
                sx={{ padding: '4px 6px' }}
                onChange={(e) => {
                  dispatch(setProductOpt({
                    path: 'chartSettings.legend.enabled', value: !!e.target.checked
                  }))
                }}
                checked={Boolean(legend?.enabled)}
              />}
              label={legend?.enabled ? 'On' : 'Off'}
              labelPlacement="start"
              sx={{ margin: 0, fontSize: '12px' }}
            />
          </EndNode>
        </CustomizeItem>
        <DropdownSelect
          onChange={(newValue) => {
            dispatch(setProductOpt({
              path: 'chartSettings.legend.layout', value: newValue
            }))
          }}
          selectedValue={legend?.layout}
          placeholder="Layout"
          options={[
            {label: 'Horizontal', id: 'horizontal'},
            {label: 'Vertical', id: 'vertical'},
          ]}
        />

        <BaseAccordion
          seriesLabel="Placement"
          isExpanded={isPlacementOpened}
          className={classes.AccordionItem}
          onChange={() => setIsPlacementOpened(!isPlacementOpened)}
        >
          <Box sx={{ gap: '10px', columnCount: 3 }}>
            {
              items.map((item, idx) => (
                <IconButton color={item.position === `${legend.verticalAlign}-${legend.align}` ? 'error' : 'default'} onClick={() => onAlignPlacement(item.position)}>
                  <item.icon />
                </IconButton>
              ))
            }
          </Box>
        </BaseAccordion>
        <BaseAccordion
          seriesLabel="Text Style"
          isExpanded={isLabelStyleOpened}
          className={classes.AccordionItem}
          onChange={() => setIsLabelStyleOpened(!isLabelStyleOpened)}
        >
          <Box display="flex" sx={{ gap: '10px', alignItems: 'center' }}>
            <Button
              className={classes.Button}
              variant={legend?.itemStyle?.fontWeight === 'normal' ? 'outlined' : 'contained'}
              onClick={() => {
                dispatch(setProductOpt({
                  path: 'chartSettings.legend.itemStyle.fontWeight',
                  value: legend?.itemStyle?.fontWeight === 'normal' ? 'bold' : 'normal'
                }))
              }}
            >
              B
            </Button>
            <Button
              className={classes.Button}
              variant={legend?.itemStyle?.fontStyle === 'normal' ? 'outlined' : 'contained'}
              onClick={() => {
                dispatch(setProductOpt({
                  path: 'chartSettings.legend.itemStyle.fontStyle',
                  value: legend?.itemStyle?.fontStyle === 'normal' ? 'italic' : 'normal'
                }))
              }}
              sx={{ fontStyle: 'italic' }}
            >I</Button>
            <ColorPicker
              color={legend?.itemStyle?.color}
              pickerLable={''}
              defaultColor="black"
              handleChange={(color) => {
                // dispatch(setProductOpt({
                //   path: `chartSettings.colors.${key}`, value: color.hex
                // }))
                dispatch(setProductOpt({
                  path: 'chartSettings.legend.itemStyle.color',
                  value: color.hex
                }))
              }}
            />
          </Box>
        </BaseAccordion>
        <CustomizeItem>
          <StartNode>Background Color</StartNode>
          <EndNode>
            <span>{legend?.backgroundColor || '#fff'}</span>
            <ColorPicker
              colors={bgColors}
              color={legend?.backgroundColor}
              pickerLable={''}
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: 'chartSettings.legend.backgroundColor',
                  value: color.hex
                }))
              }}
            />
          </EndNode>
        </CustomizeItem>
        <CustomizeItem>
          <StartNode>Border Color</StartNode>
          <EndNode>
            <span>{legend?.borderColor || '#fff'}</span>
            <ColorPicker
              colors={bgColors}
              color={legend?.borderColor}
              pickerLable={''}
              handleChange={(color) => {
                dispatch(setProductOpt({
                  path: 'chartSettings.legend.borderColor',
                  value: color.hex
                }))
              }}
            />
          </EndNode>
        </CustomizeItem>
      </Box>
  )
};

import { useState } from 'react';
import { getDatabaseCodesQA } from 'store/slices/FrontendQA';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';

const useRunDatabaseCodesQA = (dispatch, callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [qaResults, setQAResults] = useState([])

    const errorBlock = (error) => {
        setIsLoading(false)
        setQAResults({})
        dispatch(showSnackbarMessage({
            message: `Something went wrong. Please kindly try again later`,
            severity: 'error'
        }))
        console.log(error, 'in the run individual qa hook')
    }

    const triggerRequest = async () => {
        if (isLoading) return
        setIsLoading(true)
        const { payload: qaData } = await dispatch(getDatabaseCodesQA())
        setIsLoading(false)
        console.log(qaData, 'qaData')
        if (!(qaData)) {
            dispatch(showSnackbarMessage({
                message: 'Error is happened in running Database Codes QA process',
                severity: 'error'
            }))
            return
        };
        setQAResults(qaData.results)
    };

    return [triggerRequest, {
        isLoading,
        qaResults
    }];
};

export default useRunDatabaseCodesQA;
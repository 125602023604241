import React, { useMemo } from "react";
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import AreaRangeChart from "./AreaRange";
import ColumnChart from "./Column";
import LineChart from "./Line";
import { selectConfigByTypeAndPath, selectConfigByPath } from 'store/selectors/HighChart';
import { selectBEAProductDataByPath } from "store/selectors/ProductData";

function NonMarketChartContainer(props) {
    const { feedSource, databaseCode, productCode } = useParams()
    const { data: initialData, path, xAxis, yAxis} = props
    const highChartData = useSelector(state => state.highChartData) || {}
    const {
        timeSeriesChartType: chartType
    } = highChartData
    const configs = useMemo(() => {
        let _configs = highChartData[chartType] || {}
        return _configs[path] || {}
    }, [highChartData[chartType], path])
    const configData = useSelector(state => selectConfigByPath(state, `${feedSource}/${databaseCode}/${productCode}`)) || {}
    const chartData = useSelector(state => state.highChartData)
    const chartLines = useSelector(state => state.productData.selectedChartLines) || []    
    const rawData = useSelector((state) => selectBEAProductDataByPath(state, {
        feedSource,
        databaseCode,
        productCode,
        blob: 'cloud-storage'
    }))
    const yAxisFields = useSelector(state => state.productData.yAxis)
    // console.log(rawData, 'rawData')
    // console.log(productData, 'productData')
    // console.log(configData, 'configData')
    // console.log(chartData, 'chartData')
    return (
        <>
            {
                chartType === 'arearange' ? (
                    <AreaRangeChart
                        data={initialData}
                        configs={configs}
                    />
                ) : chartType === 'column' ? (
                    <ColumnChart
                        data={initialData}
                        configs={configData}
                    />
                ) : chartType === 'line' ? (
                    <LineChart
                        data={initialData}
                        // configs={configData}
                        xAxis={xAxis}
                        yAxisFields={yAxisFields}
                        chartLines={chartLines}
                        chartLinesData={rawData.selectedLinesData}
                    />
                ) : (
                    <></>
                )
            }
        </>
    )
}

export default NonMarketChartContainer
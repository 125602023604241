import React, { useEffect, useState, useMemo } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, TextField,
  Typography,
  Box,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import {
  BookmarkBorder as BookmarkIcon,
  Close as CloseIcon,
  TurnedIn
} from '@mui/icons-material'
import axios from 'axios'
import { Autocomplete } from "@mui/lab"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { USER_FAVORITE_GROUP, USER_FAVORITES } from "store/UrlConstants"
import { appendFavoriteGroup, appendToFav, fetchUserFavorites } from "store/slices/UserSlice"

const useStyles = makeStyles(() => ({
  FavoriteButton: {
    cursor: 'pointer',
    position: 'relative',
    transition: 'all ease-in .2s',
    borderRadius: '4px',
    marginRight: '12px',
    boxShadow: '0 0 1px 1px #e5e5f1',
    display: 'flex',
    flexFlow: 'column',
    color: '#4e4b4b',
    padding: '8px',
    '& svg': {
      display: 'block',
      margin: '0 auto',
    },
  },
  DialogTitleTxt: {
    flex: 1,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    fontFeatureSettings: `'salt' on`,
    color: '#374151',
  },
  DialogContent: {
    width: 512,
    minHeight: 200,
  },
  TextField: {
    backgroundColor: '#F3F4F6',
    border: 'none'
  },
  SaveBtn: {
    color: 'white',
    backgroundColor: '#6790e3',
    padding: '6px 15px',
    "&:hover": {
      backgroundColor: '#C3D6FD'
    }
  },
  bookmarkList: {
    width: '100%',
    bgcolor: 'background.paper',
    maxHeight: '300px',
    overflow: 'auto',
    marginTop: '10px',
    marginBottom: '10px',
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '11px'
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888'
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    },
  }
}));

const FavoriteButton = (props, feedSourceType) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const productData = useSelector(state => state.productData)

  const favs = useSelector(state => state.user.favs) || {}
  const favKeys = useSelector(state => state.user.favKeys) || []
  const { feedSource, databaseCode, productCode } = useParams()
  const CURRENT_LOCATION = useMemo(() => {
    return `${feedSource}/${databaseCode}/${productCode}`
  }, [feedSource, databaseCode, productCode])
  const DEFAULT_TEXT = null
  const [shouldShow, setShouldShow] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [pending, setPending] = useState(false)
  const [done, setDone] = useState(false)
  const [statusTxt, setStatusTxt] = useState(DEFAULT_TEXT)

  useEffect(() => {
    if (Object.keys(favs).length < 1) {
      dispatch(fetchUserFavorites())
    }
  }, [])


  function createGroup(groupName, configuration) {
    axios.put(USER_FAVORITES, {
      'favorite': CURRENT_LOCATION,
      'group_name': groupName,
      'configurationItem': configuration
    })
      .then((response) => {
        dispatch(appendFavoriteGroup(response.data))
        setPending(false)
        setStatusTxt('Done!')
        setDone(true)
      })
      .catch((error) => {
        setPending(false)
        setStatusTxt('Something went wrong...')
        setDone(true)
      })
  }

  function appendToGroup(groupName) {
    axios.post(USER_FAVORITE_GROUP(favs[groupName].id), { 'favorite': CURRENT_LOCATION })
      .then((response) => {
        dispatch(appendToFav({ groupName, fav: CURRENT_LOCATION }))
        setPending(false)
        setStatusTxt('Done!')
        setDone(true)
      })
      .catch((error) => {
        setPending(false)
        setStatusTxt('Something went wrong...')
        setDone(true)
      })
  }

  function SaveFav() {
    if (props.isBeaDatabaseCode) {
      if (!(productData.selectedChartTimeIncrement && productData.selectedChartLine)) return

      setPending(true)
      setStatusTxt('Loading...')
      createGroup(inputValue, {
        line: productData.selectedChartLine,
        timeIncrement: productData.selectedChartTimeIncrement
      })
    } else {
      if (feedSourceType === 'timeseries') {
        if (!(productData.xAxis && productData.yAxis)) return
      }
      setPending(true)
      setStatusTxt('Loading...')
      createGroup(inputValue, {
        xAxis: productData.xAxis,
        yAxis: productData.yAxis,
        timeIncrement: productData.selectedBlobTimeIncrement
      })
    }

    // if (favKeys.includes(inputValue)) {
    //   appendToGroup(inputValue)
    // } else {
    // }
  }

  function closeDialog() {
    setStatusTxt(DEFAULT_TEXT)
    setShouldShow(false)
    setDone(false)
  }

  // console.log(favKeys, 'favKeys')
  return (
    <div>
      <Dialog
        open={shouldShow}
        maxWidth={'md'}
      >
        <DialogTitle>
          <Box
            display="flex"
            sx={{ alignItems: 'center' }}
            variant="div"
          >
            <Typography className={classes.DialogTitleTxt}>Bookmark</Typography>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.DialogContent}>
          {
            statusTxt && statusTxt !== '' && (
              <DialogContentText>
                <Typography style={{ color: 'black' }}>{statusTxt}</Typography>
              </DialogContentText>
            )
          }
          {!pending && !done && (
            <Autocomplete
              disablePortal
              freeSolo
              id="favorite-auto-complete"
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
              }}
              options={favKeys}
              // sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="" variant='outlined' />}
            />)
          }
          <Divider sx={{ marginTop: '10px' }} />
          <Box className={classes.bookmarkList}>
            <nav aria-label="main mailbox folders">
              <List>
                {
                  favKeys.map((fav, idx) => (
                    <ListItem disablePadding key={idx}>
                      <ListItemButton>
                        <ListItemIcon>
                          <TurnedIn />
                        </ListItemIcon>
                        <ListItemText primary={fav} />
                      </ListItemButton>
                    </ListItem>
                  ))
                }
              </List>
            </nav>
          </Box>
          <Divider />
        </DialogContent>
        <DialogActions>
          {
            !pending && (
              <Button onClick={() => closeDialog()} variant='outlined'>Close</Button>
            )
          }
          {
            !done && (
              <Button
                disabled={pending}
                onClick={() => SaveFav()}
                className={classes.SaveBtn}
              >
                {pending && (<CircularProgress />)}
                {!pending && (<div>Save</div>)}
              </Button>
            )
          }
        </DialogActions>
      </Dialog>
      <Box>
        <Tooltip title="Bookmark">
          <a
            id="PlotterBookMarkBtn"
            href="#"
            className={classes.FavoriteButton}
            onClick={() => setShouldShow(true)}
          >
            <BookmarkIcon />
          </a>
        </Tooltip>
      </Box>
    </div>
  )
}

export default FavoriteButton

import React, { Component } from "react";

import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import { selectStep1Sidebar } from "store/slices/AppLayoutSlice";

const withTracker = (WrappedComponent, options = {}) => {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
    }
    componentWillMount() {
      // const user = localStorage.getItem('user');
      // const accessToken = storageService.getAccessToken()
      // const uId = storageService.getPlotterUID() 
      // if(!(uId && accessToken) && window.location.pathname !== '/demo') {
      //   this.props.history.replace({pathname: '/login'});
      // }
      let _step1Sidebar = null
      const pathname = window.location.pathname
      if(['/', '/home'].includes(pathname)){
        // _step1Sidebar = 'dashboard'
      }
      if(pathname.indexOf('/qa') === 0){
        _step1Sidebar = 'qa'
      }
      if(_step1Sidebar){
        this.props.selectStep1Sidebar(_step1Sidebar)
      }
    }
    render() {
      return <><WrappedComponent {...this.props} /></>;
    }
  };


  const mapDispatchToProps = (dispatch) => ({
    selectStep1Sidebar: (payload) => dispatch(selectStep1Sidebar(payload)),
  });

  return compose(
    withRouter,
    connect(null, mapDispatchToProps),
  )(HOC);
};

export default withTracker;

import React from "react";

import {
    BollingerSeries
} from "../../../../../libs/rrag/lib/series";

import {
    BollingerBandTooltip
} from "../../../../../libs/rrag/lib/tooltip";


export default function BollingerBands(props) {
    const { bb } = props
    const bbStroke = {
        top: "#964B00",
        middle: "#000000",
        bottom: "#964B00",
    };
    const bbFill = "#4682B4";
    return (
        <>
            <BollingerSeries yAccessor={d => d.bb}
                             stroke={bbStroke}
                             fill={bbFill} />
            <BollingerBandTooltip
                origin={[2, 60]}
                yAccessor={d => d.bb}
                options={bb.options()}
                {...props.appearance}
            />
        </>
    );
}
import React from "react";

import {format} from "d3-format";

import {
    AreaSeries, StraightLine, AlternatingFillAreaSeries
} from "../../../../../libs/rrag/lib/series";
import {
    SingleValueTooltip
} from "../../../../../libs/rrag/lib/tooltip";

export default function FiIndicator(props) {
    const {fiCalc} = props.indicator

    return (
        <>
            <AreaSeries baseAt={scale => scale(0)} yAccessor={fiCalc.accessor()} />
            <StraightLine yValue={0} />

            <SingleValueTooltip
                yAccessor={fiCalc.accessor()}
                yLabel="ForceIndex (1)"
                yDisplayFormat={format(".4s")}
                origin={[-40, 15]}
            />
        </>
    );
}
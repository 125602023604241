import React from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import GammelTheme from 'fusioncharts/themes/fusioncharts.theme.gammel';
import CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import ZuneTheme from 'fusioncharts/themes/fusioncharts.theme.zune';
import OceanTheme from 'fusioncharts/themes/fusioncharts.theme.ocean';
import CarbonTheme from 'fusioncharts/themes/fusioncharts.theme.carbon';
import UmberTheme from 'fusioncharts/themes/fusioncharts.theme.umber';
import ReactFC from "react-fusioncharts";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, GammelTheme, CandyTheme, ZuneTheme, OceanTheme, CarbonTheme,UmberTheme);

FusionCharts.options.license({
    key:
        "BzA11vmaG5E1E3A16A3A5A3C1A1B3D1E2gvxC3E5qyuiI-7B6B1mA-13tF5H2E4J2A4D4C3C6E2A2F4F1B1A7C8A3cE-11B-9F2A2H2vB2D3D2knyE1C3C11gapB4E4D4kH-7C-21D7E4F4C1H5G1G1I4B6C1E3pzzC1G4AB3zD-13mD1e1H3A33fb1qD8B1B4B1B1B1G1B17A1B3B1D6C4A2i== ",
    creditLabel: false
});


class Pie3dChart extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {theme, bgColor} = this.props;
        const dataSource = {
            caption: { text: "Web visits & downloads" },
            chart: {
                theme: theme,
                bgColor: bgColor,
                caption: "Monthly revenue for last year",
                subCaption: "Harry's SuperMart",
                xAxisName: "Month",
                yAxisName: "Revenues (In USD)",
                numberPrefix: "$",

                /*"captionFont": "Arial",
                "captionFontSize": "18",
                "captionFontColor": "#993300",
                "captionFontBold": "1",
                "alignCaptionWithCanvas": "1",
                "captionHorizontalPadding": "2",
                "captionOnTop": "1",
                "captionAlignment": "left",
                "xAxisPosition": "right",
                "yAxisPosition": "right",

                "xAxisNameFont": "Arial",
                "xAxisNameFontSize": "12",
                "xAxisNameFontColor": "#993300",
                "xAxisNameFontBold": "1",
                "xAxisNameFontItalic": "1",
                "yAxisNameFont": "Arial",
                "yAxisNameFontSize": "12",
                "yAxisNameFontColor": "#993300",
                "yAxisNameFontBold": "1",
                "yAxisNameFontItalic": "1",

                "xAxisNameBgColor": "#3399ff",
                "xAxisNameBgAlpha": "20",
                "xAxisNameFontAlpha": "50",
                "xAxisNameBorderPadding": "10",

                "pYAxisNameBgColor": "#3399ff",
                "pYAxisNameBgAlpha": "20",
                "pYAxisNameBorderPadding": "6",
                "pYAxisNameAlpha": "50",
                "sYAxisNameFont": "Arial",
                "sYAxisNameFontSize": "12",
                "sYAxisNameBgColor": "#3399ff",
                "sYAxisNameBgAlpha": "20",
                "sYAxisNameBorderPadding": "6",
                "sYAxisNameAlpha": "50",

                palettecolors: "5d62b5,29c3be,f2726f",
                "usePlotGradientColor": 1,
                "plotGradientColor": "#ffffff",
                "showPlotBorder": "1",

                "plotHoverEffect": "1",
                "plotFillHoverColor": "#d41b1b",
                "plotBorderHoverThickness": "1",
                "plotBorderHoverDashed": "1",
                "plotBorderHoverDashLen": "6",
                "plotBorderHoverDashGap": "2"*/
            },
            subcaption: { text: "since 2015" },
            yAxis: [
                {
                    plot: [
                        {
                            value: "Downloads",
                            type: "column"
                        },
                        {
                            value: "Web Visits",
                            type: "line"
                        }
                    ]
                }
            ],
            data: [
                {
                    label: "Jan",
                    value: "420000",
                    HoverColor: "#0e0101"
                },
                {
                    label: "Feb",
                    value: "810000"
                },
                {
                    label: "Mar",
                    value: "720000"
                },
                {
                    label: "Apr",
                    value: "550000"
                },
                {
                    label: "May",
                    value: "910000",
                    color: '#e44a00'
                },
                {
                    label: "Jun",
                    value: "510000"
                },
                {
                    label: "Jul",
                    value: "680000"
                },
                {
                    label: "Aug",
                    value: "620000"
                },
                {
                    label: "Sep",
                    value: "610000"
                },
                {
                    label: "Oct",
                    value: "490000"
                },
                {
                    label: "Nov",
                    value: "900000"
                },
                {
                    label: "Dec",
                    value: "730000"
                }
            ],
            trendlines: [
                {
                    line: [
                        {
                            startvalue: "700000",
                            valueOnRight: "1",
                            displayvalue: "Monthly Target",
                        }
                    ]
                }
            ]
        };
        //Ploter
        // dataSource.chart.palettecolors = fusion.ploter.ploterColor;
        const chartConfigs = {
            type: 'pie3d',
            width: 600,
            height: 400,
            dataFormat: "json",
            dataSource: dataSource
        };
        return (
            <div className="">
                <h1>Fusion Theme</h1>
                <ReactFC {...chartConfigs} />
            </div>
        );
    }
}
export default Pie3dChart;
/* eslint no-restricted-globals:0 */

import React, { useState } from "react"
import { compose } from "recompose"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import { makeStyles } from "@mui/styles";

import {
    Paper,
    CircularProgress,
} from "@mui/material"

import ErrorBoundary from "shared/system/error/ErrorBoundary"

import {
    fetchProductSQL
} from 'store/slices/StockProductSlice'

import {useProductContext} from "providers/product/ProductProvider";
import ProductHeader from "./details/ProductHeader";
import NonMarketComponent from './non-market'
import MarketComponent from './market'

const StockDetail = (props) => {
    const classes = useStyles()
    const {
        feedSourceType,
        identifier
    } = useProductContext();

    const [notification, setNotification] = useState({
        type: null,
        message: '',
        open: false
    })

    const openNotification = (obj) => {
        setNotification(obj)
    }

    const closeNotification = () => {
        setNotification({ ...notification, message: '', open: false })
    }

    return (
        <div className={classes.root}>
            <ProductHeader />
            <div className={classes.productBody}>
                <ErrorBoundary identifier={identifier}>
                    <Paper className={classes.paper}>
                        {
                            props.loadingInProgress ? (
                              <CircularProgress className={classes.CircularProgress} color="secondary" />
                            ) : (
                                feedSourceType === 'stock' ?
                                    <MarketComponent/> :
                                    <NonMarketComponent/>
                            )
                        }
                    </Paper>
                </ErrorBoundary>
                {/* <News feed_source={feedSource} database_code={databaseCode} product_code={productCode}/> */}
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        background: 'white',
        padding: 0,
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        minWidth: 0,
        '& ::-webkit-scrollbar': {
            width: '10px',
            height: '11px'
        },
        /* Track */
        '& ::-webkit-scrollbar-track': {
            background: '#f1f1f1'
        },

        /* Handle */
        '& ::-webkit-scrollbar-thumb': {
            background: '#888'
        },

        /* Handle on hover */
        '& ::-webkit-scrollbar-thumb:hover': {
            background: '#555'
        }
    },
    CircularProgress: {
        margin: '100px auto'
    },
    productBody: {
        flex: 1,
        padding: '0 12px 10px',
        display: 'flex',
        flexFlow: 'column',
        overflow: 'auto'
    },
    paper: {
        flex: 1,
        // boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 0px 3px 0px rgb(0 0 0 / 12%)',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
        borderRadius: 0,
        height: '100%',
        overflow: 'auto',
        display: 'flex',
        flexFlow: 'column'
    }
}))

const mapStateToProps = (state) => ({
    loadingInProgress: state.productData.loadingInProgress,
    loadingStockData: state.productData.loadingStockData
})

const mapDispatchToProps = (dispatch) => ({
    fetchProductSQL: (payload) => dispatch(fetchProductSQL(payload)),
})

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(StockDetail)

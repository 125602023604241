import React from "react";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from "@mui/material";
import CustomFormInput from 'components/common/Base/CustomFormInput'


class CoordinateDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: props.alert,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSave = this.handleSave.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			alert: nextProps.alert,
		});
	}
	handleChange(field, value) {
		const { alert } = this.state;
		this.setState({
			alert: {
				...alert,
				draggable: true,
				yValue: Number(value),
			}
		});
	}
	handleSave() {
		this.props.onSave(this.state.alert, this.props.chartId);
	}
	render() {
		const {
			open,
			onClose,
			onDeleteAlert,
		} = this.props;
		const { alert } = this.state;

		if (!open) return null;
		return (
			<>
				<Dialog
					open={open}
					onClose={onClose}
					aria-labelledby="form-dialog-title"
					// maxWidth={'lg'}
				>
					<DialogTitle id="form-dialog-title">Edit Alert</DialogTitle>
					<DialogContent>
						<CustomFormInput
							label="Alert When Crossing"
							fieldName="text"
							value={alert.yValue} onChange={this.handleChange}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={onDeleteAlert}
							color="primary"
						>
							Delete Alert
						</Button>
						<Button onClick={this.handleSave} color="primary">
							Save
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

export default CoordinateDialog;

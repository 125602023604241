import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { removeObjectProperties } from 'shared/utilities/common';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CustomAutocomplete(props) {
    const { multiple = false, checkboxOption = false } = props
    removeObjectProperties(props, [ multiple, checkboxOption ])

    const _checkboxRenderOption = (_props, _option, { selected: _selected }) => (
        <li {..._props}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={_selected}
            />
            {_option.label}
        </li>
    )
    const _renderOption = (_props, _option) => (
        <li {..._props}>
            {_option.label}
        </li>
    )
    return (
        <Autocomplete
            multiple={multiple}
            options={props.options || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            onChange={(e, newValue) => {
                if(checkboxOption){
                    const valuesList = newValue.map(item => {
                        return item.value
                    })
                    props.onChange(valuesList)
                }else{
                    props.onChange(newValue.value)
                }
            }}
            renderOption={checkboxOption ? _checkboxRenderOption : _renderOption}
            style={{ width: 500 }}
            renderInput={(params) => (
                <TextField {...params} label={props.label} />
            )}
        />
    );
}
import React, { useState, useMemo } from 'react';
import { makeStyles } from "@mui/styles";

import {
  Grid,
  Box,
  Button,
} from "@mui/material";
import {DropdownSelect} from "components/common/Product/DropdownSelect";
import OhlcSeriesAccordion from '../details/series/OhlcSeriesAccordion'
import {useDispatch, useSelector} from "react-redux";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {addSeries, setProductOpt, setXAxis, updateSeries} from "store/slices/StockProductSlice";
import SeriesAccordion from "../details/series/SeriesAccordion";
import RangeSeriesAccordion from "../details/series/RangeSeriesAccordion";
import {useProductContext} from "providers/product/ProductProvider";

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px'
  }
}));

export const DataPanel = () => {
  const dispatch = useDispatch()
  const { yAxisFields, fields, series, xAxis } = useSelector(state => state.productData)
  const { addChartColors } = useProductContext()
  const classes = useStyles();
  const [expandedPanel, setExpandedPanel] = useState('series1')

  const onPanelChange = (val) => {
    if(expandedPanel === val){
      setExpandedPanel('')
    }else{
      setExpandedPanel(val)
    }
  }
  const usedFields = useMemo(() => {
    return series.map(item => item.value)
  })
  const addNewSeries = () => {
    const field = yAxisFields.filter(item => usedFields.indexOf(item.id) < 0)[0]
    if(field) {
      dispatch(addSeries({
        value: field.id
      }))
      addChartColors()
    }else{
      alert('No available field')
    }
  }

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item lg={12} md={12} xs={12}>
        <Box className={classes.BorderedBox}>
          <DropdownSelect
            selectedValue={xAxis}
            onChange={(val) => dispatch(setXAxis(val))}
            options={fields}
            placeholder={'X Axis'}
            style={{ marginBottom: '10px' }}
          />
          {
            series.map((item, idx) => (
              item.type === 'ohlc' ?
                <OhlcSeriesAccordion
                  key={idx}
                  isExpanded={expandedPanel === item.id}
                  onChange={() => onPanelChange(item.id)}
                  style={{ marginBottom: '10px' }}
                  seriesLabel={`Series ${idx + 1}`}
                  seriesItem={item}
                  canDelete={false}
                  onUpdateSerie={(field, val) => dispatch(updateSeries({ id: item.id, field, newValue: val }))}
                /> :
              item.type === 'range' ?
                <RangeSeriesAccordion
                  key={idx}
                  isExpanded={expandedPanel === item.id}
                  onChange={() => onPanelChange(item.id)}
                  style={{ marginBottom: '10px' }}
                  seriesLabel={`Series ${idx + 1}`}
                  seriesItem={item}
                  onUpdateSerie={(field, val) => dispatch(updateSeries({ id: item.id, field, newValue: val }))}
                /> :
                <SeriesAccordion
                  key={idx}
                  isExpanded={expandedPanel === item.id}
                  onChange={() => onPanelChange(item.id)}
                  style={{ marginBottom: '10px' }}
                  seriesLabel={`Series ${idx + 1}`}
                  seriesItem={item}
                  onUpdateSerie={(field, val) => dispatch(updateSeries({ id: item.id, field, newValue: val }))}
                />
            ))
          }
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            component="label"
            variant="contained"
            tabIndex={-1}
            startIcon={<AddCircleIcon />}
            style={{ marginLeft: 'auto', marginTop: '10px' }}
            onClick={addNewSeries}
          >
            Add Series
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
};

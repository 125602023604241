import React from 'react';
import { lighten} from '@mui/material';
import { makeStyles } from "@mui/styles";

import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root:{
        overflow: 'auto',
        padding: '10px',
        display: 'flex',
        flexFlow: 'column'
    },
    Buttons:{
        display: 'flex',
        flexDirection: 'initial',
        borderBottom: 'solid 1px #f1f1f1',
        marginBottom: '3px',
        '& > button': {
            fontSize: '12px',
            padding: '3px 4px',
            marginRight: '2px'
        }
    },
    FieldSet: {
        margin: 0,
        flex: 1,
        overflow: 'auto'
    },
    filterWrap: {
        padding: '17px'
        // display: 'flex',
        // '& > div': {
        //     flex: 1
        // }
    },
    FormGroup: {
        marginBottom: '10px'
    },
    FormLabel: {
        display: 'block',
        marginBottom: '3px',
        fontFamily: 'system-ui',
        textTransform: 'uppercase'
    },
    FormControl: {
        width: '100%',
        padding: '5px 4px',
        borderRadius: '4px',
        border: 'solid 1px #a8a8a8',
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 4px -1px #2bffc6'
        }
    },
    CheckboxControl:{
        marginBottom: '0',
        '& span': {
            fontSize: '13px'
        }
    }
}));
  
const ColumnSetting = (props) => {
    const { 
        headCells, 
        hiddenColumns, setHiddenColumns, 
    } = props
    const classes = useStyles();

    const handleColumnChange = (event) => {
        const col = event.target.name
        const colIndex = hiddenColumns.indexOf(col);
        let newSelected = [];
    
        if (colIndex === -1) {
            newSelected = newSelected.concat(hiddenColumns, col);
        } else if (colIndex === 0) {
            newSelected = newSelected.concat(hiddenColumns.slice(1));
        } else if (colIndex === hiddenColumns.length - 1) {
            newSelected = newSelected.concat(hiddenColumns.slice(0, -1));
        } else if (colIndex > 0) {
            newSelected = newSelected.concat(
                hiddenColumns.slice(0, colIndex),
                hiddenColumns.slice(colIndex + 1),
            );
        }
        setHiddenColumns(newSelected);
    }
    
    const [anchorEl, setAnchorEl] = React.useState({
        columns: null
    });

    const handleClick = type => (event) => {
        setAnchorEl({ ...anchorEl, [type]: event.currentTarget });
    };
  
    const handleClose = () => {
      setAnchorEl({
          columns: null
      });
    };
  
    const openColumn = Boolean(anchorEl.columns);

    const columnPopid = openColumn ? 'column-popover' : undefined;

    const handleChange1 = (event) => {
        setHiddenColumns([event.target.checked, event.target.checked]);
    };

    const [allCheck, setAllCheck] = React.useState(false)
    const [allCleared, setAllCleared] = React.useState(false)

    const clearAll = () => {
        setHiddenColumns([])
        setAllCheck(false);
    }
    const selectAll = () => {
        const newArr = []
        headCells.map(h => {
            newArr.push(h.id)
        })
        setHiddenColumns(newArr)
        setAllCheck(true);
        setAllCleared(false);
    }
    return (
        <Box className={classes.root}>
            <FormControl component="fieldset" className={classes.Buttons}>
                <FormControlLabel
                    className={classes.CheckboxControl}
                    control={<Checkbox value={1} checked={allCheck} onChange={selectAll} name={'selectedAll'} />}
                    label={'Select All'}
                />
                <FormControlLabel
                    className={classes.CheckboxControl}
                    control={
                        <Checkbox value={1} checked={allCleared} 
                            onChange={clearAll} name={'clearedAll'}  disabled={hiddenColumns.length < 1}
                            indeterminate={hiddenColumns.length > 0}
                            />
                    }
                    label={'Clear All'}
                />
            </FormControl>
            <FormControl component="fieldset" className={classes.FieldSet}>
                {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                <FormGroup>
                    {
                        headCells?.map((h, i) => (
                            <FormControlLabel
                                key={i}
                                className={classes.CheckboxControl}
                                control={<Checkbox value={h.id} checked={hiddenColumns.indexOf(h.id) > -1} onChange={handleColumnChange} name={h.id} />}
                                label={h.label}
                            />
                        ))
                    }
                </FormGroup>
            </FormControl>
        </Box>
    );
  };
  
ColumnSetting.propTypes = {
};

export default ColumnSetting
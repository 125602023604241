import * as React from 'react';
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '@mui/icons-material';
import { resetAlertDialog, showAlertDialog } from 'store/slices/MessagesSystemSlice';
import useFetchQAReports from 'hooks/FetchQAReports';
import { useParams } from 'react-router-dom';

import ReportStatusArea from "./details/ReportStatusArea";
import StartQADialog from "./details/StartQADialog";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10px"
  },
  TableCap: {
    padding: '13px',
    alignItems: 'center',
    background: 'white',
    boxShadow: '14px 6px 4px -21px rgba(0,0,0,0.2), 0px -4px 2px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  Table: {
    marginTop: '9px',
    background: 'white',
  },
  TableTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '140%',
    margin: '0px',
    fontSize: '14px',
    letterSpacing: '-0.006em',
    fontWeight: '600',
    color: 'rgb(67, 74, 84)',
    marginRight: '10px'
  },
  TableSubTitle: {
  },
  UpperCase: {
    textTransform: 'uppercase'
  }
}))

export default function QAReportTable() {
  const { feedSource, databaseCode } = useParams();
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const {
    getQaData,
    saveQAReport,
    deleteQAReport,
    state: {
      loading: loading,
      qaData: {
        totalProducts,
        qaReports: reportData,
        statusRows = []
      }
    }
  } = useFetchQAReports(dispatch)
  React.useEffect(() => {
    getQaData({
      feedSource,
      databaseCode
    })
  }, [
    feedSource,
    databaseCode
  ])
  const alertDialog = useSelector(state => state.messagesSystem.alertDialog)
  React.useEffect(() => {
    const { dialogAction, clickedButton, data } = alertDialog;
    if (clickedButton === "alertDialogButtonLeft") {
      if (dialogAction === 'deleteQAReportData') {
        deleteQAReport(data)
      }
    }
    dispatch(resetAlertDialog());
  }, [alertDialog.clickedButton]);

  const [opend, setOpend] = React.useState(false)

  return (
    <Box className="p-3 pt-5">
      {/* <Box>
        <StatusArea />
      </Box> */}
      <TableContainer className={classes.root}>
        <Box display="flex" sx={{ justifyContent: 'space-between' }} className={classes.TableCap}>
          <Box>
            <Typography className={classes.TableTitle}>QA reports</Typography>
            <span>{totalProducts} Products</span>
          </Box>
          <Box>
            <Button
              variant="contained"
              size='small'
              onClick={() => {
                setOpend(true)
              }}
            >Start Analytic</Button>
          </Box>
        </Box>
        {
          statusRows && statusRows?.length > 0 && (
            <ReportStatusArea
              rows={statusRows}
            />
          )
        }
        <Table sx={{ minWidth: 650 }} className={classes.Table} aria-label="qa report table">
          <TableHead>
            <TableRow>
              <TableCell><Typography>Last QA Date</Typography></TableCell>
              <TableCell><Typography>Percentage</Typography></TableCell>
              <TableCell>
                <Typography className='pl-1'>Report</Typography>
              </TableCell>
              <TableCell><Typography>Score</Typography></TableCell>
              <TableCell><Typography>Process</Typography></TableCell>
              <TableCell><Typography>Type</Typography></TableCell>
              <TableCell><Typography>Maximum Seconds</Typography></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              loading ? (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell colSpan={8} className="text-center">
                    <Typography>Loading...</Typography>
                  </TableCell>
                </TableRow>

              ) : (
                reportData && reportData.length > 0 ? (
                  reportData.map((r, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Tooltip title="Last QA Date">
                          <span>
                            {new Date(r.last_qa_date).toLocaleDateString(undefined, {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                            })}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Percentage">
                          <span>
                            {r.range_percentage}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Button color='error' onClick={() => {
                          let url = `/qa-result-mockup/${r.id}`
                          // if (r.product_code && r.product_code !== '') {
                          //     url += `?productCode=${r.product_code}`
                          // }
                          history.push(url)
                        }}>View Report</Button>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Score">
                          <span>
                            {r.average_score ? r.average_score?.toFixed(2) : 'N/A'}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Process">
                          <span>
                            {r.passed_range_num || 0}/{Math.ceil(100 / r.range_percentage)}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Type">
                          <span className={classes.UpperCase}>
                            {r.run_type}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Tooltip title="Maximum seconds">
                          <span className={classes.UpperCase}>
                            {r.maximum_sec ? `${r.maximum_sec}ms` : 'N/A'}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={e => {
                            dispatch(showAlertDialog({
                              title: "Confirmation",
                              message: "Are you sure you want to delete?",
                              buttonLabel: { left: "Ok", right: "Cancel" },
                              dialogAction: "deleteQAReportData",
                              data: {
                                id: r.id
                              }
                            }));
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell colSpan={7}>
                      There is no Data
                    </TableCell>
                  </TableRow>
                )
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      {
        opend && (
          <StartQADialog
            loading={loading}
            totalProducts={totalProducts}
            open={opend}
            handleClose={() => setOpend(false)}
            onClickSave={async (_payload) => {
              const qaReportData = await saveQAReport({
                ..._payload,
                feedSource,
                databaseCode
              })
              if (qaReportData) {
                history.push(`/qa-result-mockup/${qaReportData.id}`)
              }
            }}
          />
        )
      }
    </Box>
  );
}
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// import {
//     fetchStockProductDataPart, fetchStockProductData
// } from 'store/slices/StockProductSlice'

import { Box } from "@mui/material"
import Chart from 'kaktana-react-lightweight-charts'
// import TradingViewWidget from 'react-tradingview-widget';
import TradingViewWrapper from './trading-view'

const LiveUpdate = () => {
    const dispatch = useDispatch();
    const fetchDataPartly = async (start, end) => {
        const params = {
            "feed_source": "IB",
            "database_code": "STK%2FNASDAQ",
            "product_code": "AAON",
            "timeIncrement": "",
            start,
            end
        }
        // return await dispatch(fetchStockProductDataPart(params));
    }
    const [start, setStart] = useState(0)
    const [length, ] = useState(50000)
    const [data, setData] = useState([])
    const [chartRef, setChartRef] = useState(null);
    const [options, setOptions] = useState({
        alignLabels: true,
        timeScale: {
          rightOffset: 12,
          barSpacing: 3,
          fixLeftEdge: true,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          borderColor: "#fff000",
          visible: true,
          timeVisible: true,
          secondsVisible: false
        }
      });
    const [candlestickSeries, setCandlestickSeries] = useState([{
        data: [
          { time: '2018-10-19', open: 180.34, high: 180.99, low: 178.57, close: 179.85 },
          { time: '2018-10-22', open: 180.82, high: 181.40, low: 177.56, close: 178.75 },
          { time: '2018-10-23', open: 175.77, high: 179.49, low: 175.44, close: 178.53 },
          { time: '2018-10-24', open: 178.58, high: 182.37, low: 176.31, close: 176.97 },
          { time: '2018-10-25', open: 177.52, high: 180.50, low: 176.83, close: 179.07 },
          { time: '2018-10-26', open: 176.88, high: 177.34, low: 170.91, close: 172.23 },
          { time: '2018-10-29', open: 173.74, high: 175.99, low: 170.95, close: 173.20 },
          { time: '2018-10-30', open: 173.16, high: 176.43, low: 172.64, close: 176.24 },
          { time: '2018-10-31', open: 177.98, high: 178.85, low: 175.59, close: 175.88 },
          { time: '2018-11-01', open: 176.84, high: 180.86, low: 175.90, close: 180.46 },
          { time: '2018-11-02', open: 182.47, high: 183.01, low: 177.39, close: 179.93 },
          { time: '2018-11-05', open: 181.02, high: 182.41, low: 179.30, close: 182.19 }
        ]
      }])
    
    const deDuplicate = (res) =>{
        let list = []
        let deDuplicated = []
        res.forEach(element => {
            if (!list.includes(JSON.stringify(element))){
                deDuplicated.push(element)
                list.push(JSON.stringify(element))
            }
        })
        return deDuplicated
    }
    const formatStock = (res) => {
        if(res && Array.isArray(res)){
            let _res = JSON.parse(JSON.stringify(res))
            _res = deDuplicate(_res)
            _res.forEach((d, i) => {
                let sDate = null
                if (d.reading_date) {
                    sDate = new Date(d.reading_date)
                } else if (d.timestamp) {
                    sDate = new Date(d.timestamp)
                }
                d.date = sDate
                d.close = +d.close
                d.open = +d.open
                d.high = +d.high
                d.low = +d.low
                d.volume = +d.volume
                d.absoluteChange = ""
                d.dividend = ""
                d.percentChange = ""
                d.split = ""
            })
            return _res
        }else{
            console.log(res, 'res')
        }
        return []
    }

    const renderCandleSeries = useCallback(() => {
        // console.log('renderChart??', data)
        return <div>
            <Chart options={options} candlestickSeries={candlestickSeries} autoWidth height={320} />
        </div>
    }, [data])

    const renderChart = () => {
        const configs = {
            "autosize": true,
            "symbol": "FiscalData:120 Day Delinquent Debt Referral Compliance Report",
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "light",
            "style": "1",
            "locale": "en",
            "toolbar_bg": "#f1f3f6",
            "enable_publishing": false,
            "withdateranges": true,
            "hide_side_toolbar": false,
            "allow_symbol_change": true,
        }
        return <></>
        // return <TradingViewWidget {...configs}/>
    }


    useEffect(() => {
        try{
            // fetchDataPartly(start, start + length).then(({payload}) => {
            //     const { dataPoints, restStr } = payload
            //     const formattedStocks = formatStock(dataPoints)
            //     let _data = [...data].concat(formattedStocks)
            //     setData([..._data])
            //     if(start < 15000){
            //         setStart(start + length + 1)
            //     }else{
            //     }
            // })
        }catch (e){
            console.log(e, 'e.error')
        }
    }, [start, chartRef])
    return (
        <Box height={600}>
            {/* {renderChart()}
            {renderCandleSeries()} */}
            <TradingViewWrapper />
        </Box>
    );
}

export default LiveUpdate
import React from "react";

const cleanPercentage = (percentage) => {
    const tooLow = !Number.isFinite(+percentage) || percentage < 0;
    const tooHigh = percentage > 100;
    return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }) => {
    const r = 70;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - pct) * circ) / 100;
    return (
        <circle
            r={r}
            cx={100}
            cy={100}
            fill="transparent"
            stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
            strokeWidth={"2rem"}
            strokeDasharray={circ}
            strokeDashoffset={pct ? strokePct : 0}
            strokeLinecap="round"
        ></circle>
    );
};

const Text = ({ percentage, time }) => {
    return (
        <>
            <text x="50%" y="40%" dominantBaseline="middle" textAnchor="middle" fontSize="1.5em">
                {percentage.toFixed(0)}%
            </text>
            <line x1="30%" y1="50%" x2="70%" y2="50%" stroke="rgb(204, 209, 217)" strokeWidth="2" />
            <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle" fontSize="1em">
                {time}
            </text>
        </>
    );
};

const Pie = ({ percentage, colour, time = '' }) => {
    const pct = cleanPercentage(percentage);
    return (
        <svg width={200} height={200}>
            <g transform={`rotate(-90 ${"100 100"})`}>
                <Circle colour="lightgrey" />
                <Circle colour={colour} pct={pct} />
            </g>
            <Text percentage={pct} time={time} />
        </svg>
    );
};

export default Pie;

import {ema, sma, tma, wma, bollingerBand, macd, rsi, atr, compare, stochasticOscillator, sar, forceIndex} from "../../../../libs/rrag/lib/indicator";

export function getMAIndicatorEdge(edgeConfig) {
    let edge;
    switch (edgeConfig.edgeName){
        case 'ema':
            edge = ema()
            .options({
                windowSize: edgeConfig.edgeVal * 1, // optional will default to 10
                sourcePath: "close", // optional will default to close as the source
            })
            .skipUndefined(true) // defaults to true
            .merge((d, c) => {d['ema' + edgeConfig.edgeVal] = c;}) // Required, if not provided, log a error
            .accessor(d => d['ema' + edgeConfig.edgeVal]) // Required, if not provided, log an error during calculation
            .stroke(edgeConfig.lineColor); // Optional
            break;   
        case 'sma':
            edge = sma()
            .options({ windowSize:  edgeConfig.edgeVal * 1})
            .merge((d, c) => {d['sma' + edgeConfig.edgeVal] = c;})
            .accessor(d => d['sma' + edgeConfig.edgeVal])
            .stroke(edgeConfig.lineColor);
            break;   
        case 'wma':
            edge = wma()
                .options({ windowSize: edgeConfig.edgeVal * 1 })
                .merge((d, c) => {d['wma' + edgeConfig.edgeVal] = c;})
                .accessor(d => d['wma' + edgeConfig.edgeVal])
                .stroke(edgeConfig.lineColor);
            break;
        case 'tma':
            edge = tma()
                .options({ windowSize: edgeConfig.edgeVal * 1 })
                .merge((d, c) => {d['tma' + edgeConfig.edgeVal] = c;})
                .accessor(d => d['tma' + edgeConfig.edgeVal])
                .stroke(edgeConfig.lineColor);
            break;
        
    }
    return edge;
}

export function getBBIndicator() {
    const bb = bollingerBand()
        .merge((d, c) => {d.bb = c;})
        .accessor(d => d.bb);
    return bb;
}
export function getMacdIndicator(fast, slow, signal) {
    const macdCalculator = macd()
        .options({
            fast: fast? fast * 1 : 12,
            slow: slow? slow * 1 : 26,
            signal: signal? signal * 1 : 9
        })
        .merge((d, c) => {d.macd = c;})
        .accessor(d => d.macd);
    return macdCalculator;
}


export function getRsiIndicator(windowSize) {
    const rsiCalculator = rsi()
        .options({ windowSize: windowSize ? windowSize : 14, stroke: 'red' })
        .merge((d, c) => {d.rsi = c;})
        .accessor(d => d.rsi);
    return rsiCalculator
}
export function getAtrIndicator(windowSize, stroke) {
    let w = windowSize ? windowSize : 14;
    return atr()
        .options({ windowSize:  w})
        .merge((d, c) => {d['atr' + w] = c;})
        .accessor(d => d['atr' + w])
        .stroke(stroke ? stroke: '#fd5d93');
}

export function getCompareIndicator(){
    const compareCalculator = compare()
        .options({
            basePath: "close",
            mainKeys: ["open", "high", "low", "close"],
            compareKeys: ["AAPLClose", "SP500Close"],
        })
        .accessor(d => d.compare)
        .merge((d, c) => { d.compare = c; });
    return compareCalculator;
}


export function getStoIndicator(stoConfig){
    const slowSTO = stochasticOscillator()
        .options({ windowSize: stoConfig?.slow?.windowSize || 0, kWindowSize: stoConfig?.slow?.kWindowSize || 0 })
        .merge((d, c) => {d.slowSTO = c;})
        .accessor(d => d.slowSTO);
    const fastSTO = stochasticOscillator()
        .options({ windowSize: stoConfig?.fast?.windowSize || 0, kWindowSize: stoConfig?.fast?.kWindowSize || 0 })
        .merge((d, c) => {d.fastSTO = c;})
        .accessor(d => d.fastSTO);
    const fullSTO = stochasticOscillator()
        .options({ 
            windowSize: stoConfig?.full?.windowSize || 0, 
            kWindowSize: stoConfig?.full?.kWindowSize || 0, 
            dWindowSize: stoConfig?.full?.dWindowSize || 0 
        })
        .merge((d, c) => {d.fullSTO = c;})
        .accessor(d => d.fullSTO);
    return {
        slowSTO,
        fastSTO,
        fullSTO
    };
}

export function getSarCalc(indicator) {
    const accelerationFactor = indicator?.accelerationFactor || 0.02;
    const maxAccelerationFactor = indicator?.maxAccelerationFactor || 0.2;
    return sar()
			.options({
				accelerationFactor, maxAccelerationFactor
			})
			.merge((d, c) => {d.sar = c;})
			.accessor(d => d.sar);
}


export function getForceIndexCalc(windowSize) {
    const fi = forceIndex()
			.merge((d, c) => {d.fi = c;})
			.accessor(d => d.fi);

    const fiEMA = ema()
        .id(1)
        .options({ windowSize: windowSize || 1, sourcePath: "fi" })
        .merge((d, c) => {d[`fiEMA${windowSize || 1}`] = c;})
        .accessor(d => d[`fiEMA${windowSize || 1}`]);
    return {
        fi, fiEMA
    }
}
import React, { useEffect, useMemo, useState } from 'react'
import _ from "underscore"
import { useParams } from 'react-router-dom';

import { makeStyles } from "@mui/styles";
import MaterialTable from "material-table";
import { Box, Button, TableCell, TableHead, TableRow, Tooltip, Typography, drawerClasses } from '@mui/material';
import CustomFormInput from 'components/common/Base/CustomFormInput';
import CustomFormSelect from 'components/common/Base/CustomFormSelect';
import { Add, PlayArrow, Schedule } from '@mui/icons-material';
import Dialog from './Dialog';
import AnalyticForm from './Form';
import QAResultsTable from './QAResultsTable';
import QAReportTable from './QAReportTable';
import { useDispatch } from 'react-redux';
import { fetchFrontendQAReports } from 'store/slices/FrontendQA';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px',
        paddingTop: '20px',
    },
    FormRoot: {
        display: 'flex',
        padding: '30px',
        marginTop: '39px',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    "flex-1": {
        flex: 1,
    },
    "flex": {
        display: "flex"
    },
}))

function QARange(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { feedSource, databaseCode } = useParams();

    // const feedSource = useState(null)
    // const databaseCode = useState(null)
    // const productCode = useState(null)


    const [openDrawer, setOpenDrawer] = useState(false)
    const [title, setTitle] = useState('')

    const [inMeasuring, setInMeasuring] = useState(false)
    const [showResult, setShowResult] = useState(false)

    return <>
        <Box className={classes.root}>
            <Box className={`${classes.flex} mb-2`}>
                <Typography variant='h5'>Quality Analytic</Typography>
                {/* <Typography variant='h6' className='ml-1'>(0)</Typography> */}
            </Box>
            {/* <Box className="mb-2">
                <Button
                    variant="contained"
                    className={classes.flex}
                    onClick={() => setOpenDialog(true)}
                >
                    <Add />
                    <span>New</span>
                </Button>
            </Box> */}
            {/* <Box className={classes.FormRoot}>
                <div className={`${classes['flex-1']} ${classes.flex} ${classes.FormTool}`}>
                    <Tooltip title="Start Quality Analytic">
                        <a
                            href="#"
                            // onClick={clickMeasurePerformance}
                            disabled={inMeasuring}
                            className={`${classes.productStaticBarButton} ${inMeasuring ? 'disabled' : ''}`}
                        >
                            <PlayArrow />
                        </a>
                    </Tooltip>
                    <Tooltip title="Schedule Quality Analytic">
                        <a
                            href="#"
                            // onClick={clickMeasurePerformance}
                            // disabled={inMeasuring}
                            className={`${classes.productStaticBarButton}`}
                        >
                            <Schedule />
                        </a>
                    </Tooltip>
                </div>
            </Box> */}
            {
                props.view === 'report' ? (
                    <QAReportTable
                        feedSource={feedSource}
                        databaseCode={databaseCode}
                        title={`${feedSource}/${databaseCode}`}
                        onClickView={() => setShowResult(true)}
                    />
                ) : (
                    <QAResultsTable
                        feedSource={feedSource}
                        databaseCode={databaseCode}
                        title={`${feedSource}/${databaseCode}`}
                        onClickBack={() => setShowResult(false)}
                    />
                )
            }
            {/* <QAResultsTable
                title="Test"
                subTitle="20 Products"
            /> */}
        </Box>
        {/* {
            openDialog &&
            <Dialog
                open={openDialog}
                handleClose={() => setOpenDialog(false)}
                onUpdate={(newTitle) => {
                    setTitle(newTitle)
                    setOpenDrawer(true)
                }}
            />

        } */}
        {/* {
            openDrawer && <AnalyticForm
                isOpen={openDrawer}
                onClose={(hasUpdated, _analyticData) => {
                    setOpenDrawer(false)
                }}
                title={title}
            />
        } */}
    </>
}

export default QARange
/* 
import { showSnackbarMessage } from 'store/slices/AlertMessageSlice'


- Structure:
    dispatch(showSnackbarMessage({
      severity: "",
      autoHideDuration: 0,    
      anchorOrigin: { vertical: "", horizontal: "" },
    }))


- InitialState:
    snackbar: {
      show: false,
      message: "",
      severity: "info",
      autoHideDuration: 6000,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    }
    dispatch(showSnackbarMessage(
      {
        message: "Error Message",
        severity: "error"
      }
    ))

    
- Exemple Success:
    dispatch(showSnackbarMessage(
      {
        message: "Success message",
        severity: "info",
        autoHideDuration: 10000,
        anchorOrigin: { vertical: "bottom", horizontal: "left" }
      }
    )) 
*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { hideSnackbarMessage } from "store/slices/MessagesSystemSlice";
import {
  Alert,
  Snackbar
} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    whiteSpace: 'pre-line',
    maxWidth: '400px'
  }
}));

export default function CustomSnackbar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { message, severity, autoHideDuration, anchorOrigin, show } = useSelector((state) => state.messagesSystem.snackbar);

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideSnackbarMessage());
  };

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      classes={{
        root: classes.root
      }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

import React, { useState } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    lighten,
    Grid,
    Box,
    Popover,
    Button,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
} from "@mui/material";
import { ArrowDropDown } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    },
    Txt: {
        color: 'black'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    BorderedBox: {
        display: 'flex',
        flexFlow: 'column',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        minHeight: '100px',
        height: '100%',
        padding: '10px',
        maxHeight: '500px',
        overflow: 'auto',
        paddingBottom: '35px'
    },
    ListItemText: {
        fontSize: 14
    },
    ListItemButton: {
        padding: '5px 11px'
    },
    selectedLine: {
        background: '#dfdfdf'
    }
}));

const BEAChartConfigDialog = (props) => {
    const {
        open,
        handleClose,
        timeIncrements,
        lines,
        onUpdate
    } = props
    const classes = useStyles();
    const [selectedTimeIncrement, setSelectedTimeIncrement] = useState(props.selectedTimeIncrement || null)
    const [selectedLines, setSelectedLines] = useState(props.selectedLines || [])

    const [selectedPopField, setSelectedPopField] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const handleFieldClick = (event, field) => {
        setSelectedPopField(field)
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const openPop = Boolean(anchorEl);
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.DialogContent}
            maxWidth="md"
        >
            <DialogTitle>
                {"Chart Setting"}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Box className={classes.BorderedBox} minHeight={500}>
                            <Box>
                                <Typography className={classes.Txt} mt={1}>Line</Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        border: 'solid 1px #dfd4d4',
                                        borderRadius: '4px',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        height: '38px',
                                        alignItems: 'center',
                                        marginTop: '4px'
                                    }}
                                    onClick={(e) => handleFieldClick(e, 'line')}
                                >
                                    <Box flex={1}
                                        display="flex"
                                        sx={{
                                            height: '100%',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box sx={{
                                            height: '100%',
                                            width: '9px',
                                            background: '#1976d2',
                                            marginRight: '8px'
                                        }}></Box>
                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>{selectedLines && selectedLines.length > 0 ? selectedLines.join(', ').toUpperCase() : 'Select Line...'}</Typography>
                                    </Box>
                                    <ArrowDropDown />
                                </Box>
                            </Box>
                            <Box>
                                <Typography className={classes.Txt} mt={1}>Frequency</Typography>
                                <Box
                                    display="flex"
                                    sx={{
                                        border: 'solid 1px #dfd4d4',
                                        borderRadius: '4px',
                                        overflow: 'hidden',
                                        cursor: 'pointer',
                                        height: '38px',
                                        alignItems: 'center',
                                        marginTop: '4px'
                                    }}
                                    onClick={(e) => handleFieldClick(e, 'timeIncrement')}
                                >
                                    <Box flex={1}
                                        display="flex"
                                        sx={{
                                            height: '100%',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box sx={{
                                            height: '100%',
                                            width: '9px',
                                            background: '#1976d2',
                                            marginRight: '8px'
                                        }}></Box>
                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>{selectedTimeIncrement ? selectedTimeIncrement.toUpperCase() : 'Select Frequency...'}</Typography>
                                    </Box>
                                    <ArrowDropDown />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Popover
                    open={openPop}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                // transformOrigin={{
                //     vertical: 'bottom',
                //     horizontal: 'left',
                // }}
                >
                    <Box sx={{ width: '200px', bgcolor: 'background.paper', maxHeight: '273px' }}>
                        <Divider />
                        <nav>
                            <List>
                                {
                                    selectedPopField === 'line' ? (
                                        lines.map((item, idx) => (
                                            <ListItem key={idx} className={`${ selectedLines.indexOf(item.id) > -1 ? classes.selectedLine: '' }`} disablePadding>
                                                <ListItemButton
                                                    classes={{ root: classes.ListItemButton }}
                                                    onClick={() => {
                                                        // setSelectedLines(item.id)
                                                        // setAnchorEl(null)
                                                        // setSelectedPopField(null)
                                                        let _lines = [...selectedLines]
                                                        if(_lines.indexOf(item.id) > -1){
                                                            _lines = _lines.filter(l => l !== item.id)
                                                        }else{
                                                            _lines.push(item.id)
                                                        }
                                                        console.log(_lines, '_lines')
                                                        setSelectedLines([..._lines])
                                                    }}
                                                >
                                                    <ListItemText classes={{ primary: classes.ListItemText }} primary={item.label.toUpperCase()} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))
                                    ) : (
                                        timeIncrements.map((item, idx) => (
                                            <ListItem key={idx} disablePadding>
                                                <ListItemButton
                                                    classes={{ root: classes.ListItemButton }}
                                                    onClick={() => {
                                                        setSelectedTimeIncrement(item)
                                                        setAnchorEl(null)
                                                        setSelectedPopField(null)
                                                    }}
                                                >
                                                    <ListItemText classes={{ primary: classes.ListItemText }} primary={item.toUpperCase()} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))
                                    )
                                }
                            </List>
                        </nav>
                    </Box>
                </Popover>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    onUpdate(selectedLines, selectedTimeIncrement || " ")
                    handleClose()
                }} variant="contained">Refresh</Button>
                <Button onClick={handleClose} autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

BEAChartConfigDialog.propTypes = {
};

export default BEAChartConfigDialog
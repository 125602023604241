import { SMA, EMA, WMA, MACD } from 'technicalindicators'

export const smaInc = (data, period = 0) => {
    const d1 = data.map((d) => d.close);
    const results = SMA.calculate({
      period,
      values: d1
    })
    const d2 = results;
    const diff = data.length - d2.length;
    const emptyArray = [...(new Array(diff))].map((d) => '');
    const d3 = [...emptyArray, ...d2];
    data = data.map((d, i) => ({ ...d, sma: d3[i] }));
    return data;
};

export const emaInc = (data, period = 0) => {
  const d1 = data.map((d) => d.close);
  const results = EMA.calculate({
    period,
    values: d1
  })
  const d2 = results;
  const diff = data.length - d2.length;
  const emptyArray = [...(new Array(diff))].map((d) => '');
  const d3 = [...emptyArray, ...d2];
  data = data.map((d, i) => ({ ...d, ema: d3[i] }));
  return data;
};


export const wmaInc = (data, period = 0) => {
  const d1 = data.map((d) => d.close);
  const results = WMA.calculate({
    period,
    values: d1
  })
  const d2 = results;
  const diff = data.length - d2.length;
  const emptyArray = [...(new Array(diff))].map((d) => '');
  const d3 = [...emptyArray, ...d2];
  data = data.map((d, i) => ({ ...d, wma: d3[i] }));
  return data;
};


export const macdInc = (data, period = 0) => {
  const d1 = data.map((d) => d.close);
  const results = MACD.calculate({
    period,
    values: d1
  })
  const d2 = results;
  const diff = data.length - d2.length;
  const emptyArray = [...(new Array(diff))].map((d) => '');
  const d3 = [...emptyArray, ...d2];
  data = data.map((d, i) => ({ ...d, macd: d3[i] }));
  return data;
};
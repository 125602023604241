import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from "underscore"

import { makeStyles } from "@mui/styles";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBeaMneSeries } from 'store/slices/StockProductSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 22,
        '& .MuiFormControlLabel-root': {
            margin: 0,
            textTransform: 'capitalize',
            marginBottom: 16,
            '& .MuiButtonBase-root': {
                padding: 4,
            }
        }
    },
}))

function SeriesStep(props) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { selectedInvestment, selectedSeries, selectedDirection, onChange } = props
    const seriesArr = useSelector(state => state.productData.beaMneSeries)

    useEffect(() => {
        dispatch(fetchBeaMneSeries({
            investment: selectedInvestment,
            direction: selectedDirection
        }))
    }, [
        selectedInvestment,
        selectedDirection,
        fetchBeaMneSeries
    ])

    const onValueChange = useCallback((e) => {
        onChange(e.target.value)
    }, [
        onChange
    ])

    return <>
        <Box className={classes.root}>
            <FormControl>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={selectedSeries || ''}
                    name="radio-buttons-group"
                    onChange={onValueChange}
                >
                    {
                        seriesArr?.map((item, idx) => {
                            return <FormControlLabel key={idx} value={item.series} control={<Radio />} label={item.series.trim()} />
                        })
                    }
                </RadioGroup>
            </FormControl>
        </Box>
    </>
}

export default SeriesStep
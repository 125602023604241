import React from "react";

import {
    StochasticSeries
} from "../../../../../libs/rrag/lib/series";
import {
    StochasticTooltip
} from "../../../../../libs/rrag/lib/tooltip";

export default function StoIndicator(props) {
    const {label, yAccessor, indicator, toolTipApperance, stoAppearance} = props;
    const appearance = {...stoAppearance, stroke: {
        dLine: indicator?.stroke?.line,
        kLine: indicator?.stroke?.line,
    }};
    return (
        <>
            <StochasticSeries
                yAccessor={yAccessor}
                {...stoAppearance} />    
            <StochasticTooltip
                origin={[-38, 15]}
                yAccessor={yAccessor}
                options={indicator['calc'].options()}
                appearance={appearance}
                label={label}
                labelFill={'red'}
                {...appearance}
                {...toolTipApperance}
                />
        </>
    );
}
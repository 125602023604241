import React from 'react';
import {makeStyles} from "@mui/styles";
import { Box } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    height: '100%'
  },
  loader: {
    color: '#1976d2',
    fontSize: '7px',
    position: 'relative',
    textIndent: '-9999em',
    transform: 'translateZ(0) translateY(7em)',
    animationDelay: '-0.16s',
    borderRadius: '50%',
    width: '2.5em',
    height: '2.5em',
    animationFillMode: 'both',
    animation: '$bblfadinout 1.8s infinite ease-in-out',
    "&::before, &::after": {
      content: "''",
      position: 'absolute',
      top: 0,
      borderRadius: '50%',
      width: '2.5em',
      height: '2.5em',
      animationFillMode: 'both',
      animation: '$bblfadinout 1.8s infinite ease-in-out',
    },
    "&::before": {
      left: '-3.5em',
      animationDelay: '-0.32s',
    },
    "&::after": {
      left: '3.5em',
    }
  },
  "@keyframes bblfadinout": {
    "0%, 80%, 100%": {
      boxShadow: '0 2.5em 0 -1.3em'
    },
    "40%": {
      boxShadow: '0 2.5em 0 0'
    }
  },
  loaderWrap: {
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: '#a3a3a314',
  },
  noDisplay: {
    display: 'none'
  }
}))

export default function LoadingOverlay(props) {
  const classes = useStyles();

  return (
    <>
      <Box className={`${classes.root} ${props.className}`}>
        {
          props.isLoading && (
            <Box className={classes.loaderWrap}>
              <Box className={classes.loader}></Box>
            </Box>
          )
        }
        {props.children}
      </Box>
    </>
  );
}
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import _ from 'underscore'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    lighten,
    IconButton,
    Tooltip,
    Grid,
    Box,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Popover,
    Button,
    Typography,
    RadioGroup,
    Radio,
    Autocomplete,
    TextField,
    ButtonGroup,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    Tabs,
    Tab
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import PieChartIcon from '@mui/icons-material/PieChart';
import CustomFormInput from 'components/common/Base/CustomFormInput';
import LineChart from './Preview/Line';

import BarChartIcon from '@mui/icons-material/BarChart';
import LineChartSvg from 'assets/images/chart/Line.svg'
import PointMarkerSvg from 'assets/images/chart/PointMarker.svg'
import ColumnRangeSvg from 'assets/images/chart/ColumnRange.svg'
import AreaChartSvg from 'assets/images/chart/Area.svg'
import AreaRangeChartSvg from 'assets/images/chart/AreaRange.svg'
import HorizontalBarChart from 'assets/images/chart/HorizontalBar.svg'
import ScatterLineChart from 'assets/images/chart/ScatterLine.svg'
import DepthChart from 'assets/images/chart/DepthChart.svg'
import { Add, ArrowDropDown, Delete, Edit, Filter, North, SignalCellularAlt, South, TableRows, VisibilityOff, Equalizer as EqualizerIcon, Toc, FormatPaint } from '@mui/icons-material';
import TestChart from './Preview/TestChart';
import PreviewChart1 from 'assets/images/chart/preview/PreviewChart1.png'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '90%',
            maxWidth: '100rem',
        }
    },
    MdDialogContent: {
        '& .MuiDialog-paper': {
            width: '90%',
            maxWidth: '500px',
        }
    },
    IconButton: {
        padding: 0,
        marginLeft: '10px'
    },
    Txt:{
        color: 'black'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    BorderedBox: {
        display: 'flex',
        flexFlow: 'column',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        minHeight: '100px',
        height: '100%',
        padding: '10px',
        maxHeight: '500px',
        overflow: 'auto',
    },
    PreviewBox: {
        backgroundColor: '#eef1f6'
    },
    PreviewTitle: {
        color: 'black',
        fontSize: '.75rem'
    },
    popoverInner:{
        height: 400,
        overflow: 'auto',
        padding: '10px',
        display: 'flex',
        flexFlow: 'column'
    },
    Buttons:{
        display: 'flex',
        flexDirection: 'initial',
        borderBottom: 'solid 1px #f1f1f1',
        marginBottom: '3px',
        '& > button': {
            fontSize: '12px',
            padding: '3px 4px',
            marginRight: '2px'
        }
    },
    FieldSet: {
        margin: 0,
        flex: 1,
        overflow: 'auto'
    },
    filterWrap: {
        padding: '17px'
        // display: 'flex',
        // '& > div': {
        //     flex: 1
        // }
    },
    FormGroup: {
        marginBottom: '10px'
    },
    FormLabel: {
        display: 'block',
        marginBottom: '3px',
        fontFamily: 'system-ui',
        textTransform: 'uppercase'
    },
    FormControl: {
        width: '100%',
        padding: '5px 4px',
        borderRadius: '4px',
        border: 'solid 1px #a8a8a8',
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 4px -1px #2bffc6'
        }
    },
    CheckboxControl:{
        marginBottom: '0',
        '& span': {
            fontSize: '13px'
        }
    },
    Section: {
        position: 'relative',
        borderBottom: 'solid 1px #ede2e2',
        paddingBottom: '11px',
        marginBottom: 10,
        '&::before': {
            content: "''",
            top: 24,
            left: '0',
            height: 'calc(100% - 24px)',
            position: 'absolute',
            background: '#ede2e2',
            width: '1px',
        },
    },
    SectionTitle: {
        color: 'black',
        fontSize: 13,
        paddingLeft: 19
    },
    SubSection: {
        position: 'relative',
        paddingLeft: '20px',
        paddingTop: '4px',
        '&::before': {
            content: "''",
            top: '15px',
            left: '1px',
            width: '13px',
            height: '1px',
            position: 'absolute',
            background: '#ede2e2',
        },
    },
    CategoryRadio: {
        '& .MuiFormControlLabel-root': {
            margin: 0,
            marginBottom: 6,
            '& .MuiRadio-root': {
                padding: 0,
                transform: 'scale(0.8)'
            },
            '& .MuiFormControlLabel-label': {
                fontSize: '0.75rem'
            }
        }
    },
    Text: {
        fontSize: '.75rem',
        color: 'black'
    },
    CategoryEntity: {
        paddingTop: '7px',
        paddingLeft: '24px',
    },
    ChartItem: {
        cursor: 'pointer',
        margin: '3px 2px',
        width: '40px',
        height: '40px',
        fontSize: '13px',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        backgroundColor: '#eef1f6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& a': {
            color: 'black'
        },
        '&.selected': {
            boxShadow: '0px 0px 3px -1px black',
            background: '#2196f3',
            '& a': {
                color: 'white'
            }
        },
        '& > img': {
            width: '23px',
            '&.Rotated90Deg': {
                transform: 'rotate(90deg)'
            }
        }
    },
    ListItemText: {
        fontSize: 14
    },
    ListItemButton: {
        padding: '5px 11px'
    },
    ListItemIcon: {
        minWidth: 40,
    },
    ViewModeBtn: {
        border: 'solid 1px #acb8c5',
        borderRadius: '5px',
        marginLeft: '8px',
        marginBottom: '9px',
        '&.selected': {
            background: '#1976d2',
            borderColor: '#1976d2',
            color: 'white',
        }
    },
    SelectedIcon: {
        color: '#1976d2',
    },
    Tabs: {
        borderBottom: 'solid 1px #e3e8ed'
    },
    ChartPreivewSlot: {
        width: '100%',
        border: 'solid 1px #c1a5a5',
        minHeight: '100px',
        background: '#eef1f6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
        borderRadius: '2px',
    },
    SideIconBtn: {
        margin: '10px 0'
    }
}));
  
const formulas = [
    { label: 'Sum', value: 'sum' },
    { label: 'Avg', value: 'avg' },
    { label: 'Maximum', value: 'Maximum' },
    { label: 'Minimum', value: 'Minimum' },
    { label: 'Count of Rows', value: 'count' },
]
const chartTypes = [
    { val: 'line', label: 'Line', svg: LineChartSvg},
    { val: 'donut', label: 'Donut', icon: () => <DonutLargeIcon />},
    { val: 'area', label: 'Area', svg: AreaChartSvg},
    { val: 'arearange', label: 'Area Range', svg: AreaRangeChartSvg},
    { val: 'column', label: 'Column', icon: () => <BarChartIcon />},
    { val: 'bar', label: 'Bar', svg: HorizontalBarChart },
    { val: 'columnrange', label: 'Column Range', svg: ColumnRangeSvg},
    { val: 'point-marker', label: 'Point Marker', svg: PointMarkerSvg},
    { val: 'scatter-line', label: 'Scatter Marker Line', svg: ScatterLineChart},
    { val: 'depth-chart', label: 'Depth Chart', svg: DepthChart},
];
const barChartTypes = [
    { val: 'column', label: 'Column', icon: () => <BarChartIcon />},
    { val: 'bar', label: 'Bar', svg: HorizontalBarChart },
    { val: 'columnrange', label: 'Column Range', svg: ColumnRangeSvg},
    { val: 'depth-chart', label: 'Depth Chart', svg: DepthChart},
];
const lineChartTypes = [
    { val: 'line', label: 'Line', svg: LineChartSvg},
    { val: 'point-marker', label: 'Point Marker', svg: PointMarkerSvg},
    { val: 'scatter-line', label: 'Scatter Marker Line', svg: ScatterLineChart},
];
const areaChartTypes = [
    { val: 'area', label: 'Area', svg: AreaChartSvg},
    { val: 'arearange', label: 'Area Range', svg: AreaRangeChartSvg},
];
const donutChartTypes = [
    { val: 'donut', label: 'Donut', icon: () => <DonutLargeIcon />},
    { val: 'pie', label: 'Pie', icon: () => <PieChartIcon />},
];

const DataLogistic = (props) => {
    const { 
        data,
        fields: initialFields = [],
    } = props
    const classes = useStyles();
    const [fields, setFields] = useState([])
    const [open, setOpen] = useState(false)
    const [chartType, setChartType] = useState('line')
    const [xAxis, setXAxis] = useState('year')
    const [formula, setFormula] = useState('sum')
    const [yAxis, setYAxis] = useState('actual')
    const [categoryBy, setCategorBy] = useState('uid')
    const [groupBy, setGroupBy] = useState('uid')
    const [entities, setEntities] = useState([])
    const [entityType, setEntityType] = useState('multiple')
    const [selectedEntity, setSelectedEntity] = useState([])
    const [viewMode, setViewMode] = useState('table')
    const [settingViewMode, setSettingViewMode] = useState(null)
    

    const [measureAnchorEl, setMeasureAnchorEl] = useState(null);
    const handleMeasureClick = (event) => {
        setMeasureAnchorEl(event.currentTarget);
    };
    const handleMeasurePopoverClose = () => {
        setMeasureAnchorEl(null);
    };
    const openMeasurePop = Boolean(measureAnchorEl);

    const [newMeasureAnchorEl, setNewMeasureAnchorEl] = useState(null);
    const handleNewMeasureClick = (event) => {
        setNewMeasureAnchorEl(event.currentTarget);
    };
    const handleNewMeasurePopoverClose = () => {
        setNewMeasureAnchorEl(null);
    };
    const openNewMeasurePop = Boolean(newMeasureAnchorEl);

    
    const [newGroupAnchorEl, setNewGroupAnchorEl] = useState(null);
    const handleNewGroupClick = (event) => {
        setNewGroupAnchorEl(event.currentTarget);
    };
    const handleNewGroupPopoverClose = () => {
        setNewGroupAnchorEl(null);
    };
    const openNewGroupPop = Boolean(newGroupAnchorEl);

    const [tabValue, setTabValue] = useState(0);

    const handleTabeChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const [openEdit, setOpenEdit] = useState(false)
    const handleEditClose = () => {
        setOpenEdit(false)
    }

    useEffect(() => {
        const _fields = []
        initialFields.map(f => {
            _fields.push({
                label: f.id,
                value: f.id
            })
        })
        setFields([..._fields])
    }, [initialFields])

    useEffect(() => {
        let splittedData = []
        if(categoryBy && Array.isArray(data)){
            splittedData = _.groupBy(data, function(i){ return i[categoryBy] });
            const _entities = _.keys(splittedData).map(item => {
                return { label: item, value: item }
            });
            setEntities([..._entities])
        }
    }, [
        data, categoryBy
    ])
    
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Tooltip title="Chart Setting">
                <IconButton className={classes.IconButton} variant="contained" color="primary" onClick={() => {
                    setOpen(true)
                }}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.DialogContent}
                maxWidth="lg"
            >
                <DialogTitle>
                    {"FORMAT DATA"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item lg={3} md={3} xs={12}>
                            <Box className={classes.BorderedBox} minHeight={500}>
                                <Box
                                    display="flex"
                                    sx={{
                                        borderBottom: 'solid 1px #1976d2',
                                        justifyContent: 'end'
                                    }}
                                >
                                    <ButtonGroup variant="text" aria-label="text button group">
                                        <Button>Setting</Button>
                                        <Button>Fields</Button>
                                    </ButtonGroup>
                                </Box>
                                {
                                    viewMode === 'chart' ? (
                                        <>
                                            <Box>
                                                <Typography className={classes.Txt} mt={1}>Y Axis</Typography>
                                                <Box
                                                    display="flex"
                                                    sx={{ 
                                                        border: 'solid 1px #dfd4d4',
                                                        borderRadius: '4px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                        height: '38px',
                                                        alignItems: 'center',
                                                        marginTop: '4px'
                                                    }}
                                                    onClick={handleMeasureClick}
                                                >
                                                    <Box flex={1}
                                                        display="flex"
                                                        sx={{ 
                                                            height: '100%',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            height: '100%',
                                                            width: '9px',
                                                            background: '#1976d2',
                                                            marginRight: '8px'
                                                        }}></Box>
                                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>Sum of DataValue</Typography>
                                                    </Box>
                                                    <ArrowDropDown />
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    sx={{ 
                                                        border: 'solid 1px #dfd4d4',
                                                        borderRadius: '4px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                        height: '38px',
                                                        alignItems: 'center',
                                                        marginTop: '4px'
                                                    }}
                                                    onClick={handleMeasureClick}
                                                >
                                                    <Box flex={1}
                                                        display="flex"
                                                        sx={{ 
                                                            height: '100%',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            height: '100%',
                                                            width: '9px',
                                                            background: '#1976d2',
                                                            marginRight: '8px'
                                                        }}></Box>
                                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>Maximum of DataValue</Typography>
                                                    </Box>
                                                    <ArrowDropDown />
                                                </Box>
                                                <Box marginTop={"3px"}>
                                                    <Button
                                                        startIcon={<Add />}
                                                        variant="outlined"
                                                        sx={{ width: '100%' }}
                                                        onClick={handleNewMeasureClick}
                                                    >
                                                        Add Y Axis
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box width="100%">
                                                <Divider />
                                            </Box>
                                            <Box>
                                                <Typography className={classes.Txt} mt={1}>X Axis</Typography>
                                                <Box
                                                    display="flex"
                                                    sx={{ 
                                                        border: 'solid 1px #dfd4d4',
                                                        borderRadius: '4px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                        height: '38px',
                                                        alignItems: 'center',
                                                        marginTop: '4px'
                                                    }}
                                                    onClick={handleMeasureClick}
                                                >
                                                    <Box flex={1}
                                                        display="flex"
                                                        sx={{ 
                                                            height: '100%',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            height: '100%',
                                                            width: '9px',
                                                            background: '#1976d2',
                                                            marginRight: '8px'
                                                        }}></Box>
                                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>Record Date</Typography>
                                                    </Box>
                                                    <ArrowDropDown />
                                                </Box>
                                                <Box marginTop={"3px"}>
                                                    <Button
                                                        startIcon={<Add />}
                                                        variant="outlined"
                                                        sx={{ width: '100%' }}
                                                        onClick={handleNewGroupClick}
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box width="100%">
                                                <Divider />
                                            </Box>
                                            
                                            <Box>
                                                <Typography className={classes.Txt} mt={1}>Segment By</Typography>
                                                <Box
                                                    display="flex"
                                                    sx={{ 
                                                        border: 'solid 1px #dfd4d4',
                                                        borderRadius: '4px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                        height: '38px',
                                                        alignItems: 'center',
                                                        marginTop: '4px'
                                                    }}
                                                    onClick={handleMeasureClick}
                                                >
                                                    <Box flex={1}
                                                        display="flex"
                                                        sx={{ 
                                                            height: '100%',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            height: '100%',
                                                            width: '9px',
                                                            background: '#1976d2',
                                                            marginRight: '8px'
                                                        }}></Box>
                                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>Average</Typography>
                                                    </Box>
                                                    <ArrowDropDown />
                                                </Box>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box>
                                                <Typography className={classes.Txt} mt={1}>Measures</Typography>
                                                <Box
                                                    display="flex"
                                                    sx={{ 
                                                        border: 'solid 1px #dfd4d4',
                                                        borderRadius: '4px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                        height: '38px',
                                                        alignItems: 'center',
                                                        marginTop: '4px'
                                                    }}
                                                    onClick={handleMeasureClick}
                                                >
                                                    <Box flex={1}
                                                        display="flex"
                                                        sx={{ 
                                                            height: '100%',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            height: '100%',
                                                            width: '9px',
                                                            background: '#1976d2',
                                                            marginRight: '8px'
                                                        }}></Box>
                                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>Sum of DataValue</Typography>
                                                    </Box>
                                                    <ArrowDropDown />
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    sx={{ 
                                                        border: 'solid 1px #dfd4d4',
                                                        borderRadius: '4px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                        height: '38px',
                                                        alignItems: 'center',
                                                        marginTop: '4px'
                                                    }}
                                                    onClick={handleMeasureClick}
                                                >
                                                    <Box flex={1}
                                                        display="flex"
                                                        sx={{ 
                                                            height: '100%',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            height: '100%',
                                                            width: '9px',
                                                            background: '#1976d2',
                                                            marginRight: '8px'
                                                        }}></Box>
                                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>Maximum of DataValue</Typography>
                                                    </Box>
                                                    <ArrowDropDown />
                                                </Box>
                                                <Box marginTop={"3px"}>
                                                    <Button
                                                        startIcon={<Add />}
                                                        variant="outlined"
                                                        sx={{ width: '100%' }}
                                                        onClick={handleNewMeasureClick}
                                                    >
                                                        Add Measure
                                                    </Button>
                                                </Box>
                                            </Box>
                                            <Box width="100%">
                                                <Divider />
                                            </Box>
                                            <Box>
                                                <Typography className={classes.Txt} mt={1}>Group By</Typography>
                                                <Box
                                                    display="flex"
                                                    sx={{ 
                                                        border: 'solid 1px #dfd4d4',
                                                        borderRadius: '4px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                        height: '38px',
                                                        alignItems: 'center',
                                                        marginTop: '4px'
                                                    }}
                                                    onClick={handleMeasureClick}
                                                >
                                                    <Box flex={1}
                                                        display="flex"
                                                        sx={{ 
                                                            height: '100%',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box sx={{
                                                            height: '100%',
                                                            width: '9px',
                                                            background: '#1976d2',
                                                            marginRight: '8px'
                                                        }}></Box>
                                                        <Typography className={classes.Txt} sx={{ fontSize: '13px' }}>Record Date</Typography>
                                                    </Box>
                                                    <ArrowDropDown />
                                                </Box>
                                                <Box marginTop={"3px"}>
                                                    <Button
                                                        startIcon={<Add />}
                                                        variant="outlined"
                                                        sx={{ width: '100%' }}
                                                        onClick={handleNewGroupClick}
                                                    >
                                                        Add
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </>
                                    )
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={9} md={9} xs={12}>
                            <Box display="flex" height="100%">
                                <Box flex={1} className={`${classes.BorderedBox} ${classes.PreviewBox}`}>
                                    <Box display="flex" sx={{ justifyContent: 'end' }}>
                                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                                            <Tooltip title="Chart Mode">
                                                <IconButton className={`${classes.ViewModeBtn} ${ viewMode === 'chart' ? 'selected' : '' }`} onClick={() => {setViewMode('chart')}}>
                                                    <SignalCellularAlt />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Table Mode">
                                                <IconButton className={`${classes.ViewModeBtn} ${ viewMode === 'table' ? 'selected' : '' }`} onClick={() => {setViewMode('table')}}>
                                                    <TableRows />
                                                </IconButton>
                                            </Tooltip>
                                        </ButtonGroup>
                                    </Box>
                                    <Box sx={{ bgcolor: 'background.paper'}}>
                                        {
                                            viewMode === 'chart' ? (
                                                <>
                                                    <TestChart />
                                                </>
                                            ) : (
                                                <TableContainer classes={{root: classes.TableContainerRoot}}>
                                                    <Table
                                                        stickyHeader
                                                        classes={{ root: classes.table }}
                                                        aria-labelledby="tableTitle"
                                                        size={'medium'}
                                                        aria-label="enhanced table"
                                                    >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align="left">Record Date</TableCell>
                                                                <TableCell align="left">Sum of Data Value</TableCell>
                                                                <TableCell align="left">Maximum of Data Value</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow hover>
                                                                <TableCell align="left">2022-02</TableCell>
                                                                <TableCell align="left">10,086,457</TableCell>
                                                                <TableCell align="left">11,086,457</TableCell>
                                                            </TableRow>
                                                            <TableRow hover>
                                                                <TableCell align="left">2022-03</TableCell>
                                                                <TableCell align="left">9,086,457</TableCell>
                                                                <TableCell align="left">9,186,457</TableCell>
                                                            </TableRow>
                                                            <TableRow hover>
                                                                <TableCell align="left">2022-04</TableCell>
                                                                <TableCell align="left">6,086,457</TableCell>
                                                                <TableCell align="left">9,186,457</TableCell>
                                                            </TableRow>
                                                            <TableRow hover>
                                                                <TableCell align="left">2022-04</TableCell>
                                                                <TableCell align="left">6,006,457</TableCell>
                                                                <TableCell align="left">7,186,457</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )
                                        }
                                    </Box>
                                </Box>
                                <Box display="flex" height="100%" maxWidth="350px" ml={'8px'}>
                                    {
                                        settingViewMode !== null && (
                                            settingViewMode === 'chart' ? (
                                                <Box width="300px" className={classes.BorderedBox} sx={{ padding: '10px 0px !important'}}>
                                                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                                        <Tabs classes={{ root: classes.Tabs }} value={tabValue} onChange={handleTabeChange} centered>
                                                            <Tab label="Charts" />
                                                            <Tab label="Suggested Charts" />
                                                        </Tabs>
                                                    </Box>
                                                    <Box sx={{ padding: '0px 10px' }}>
                                                        {
                                                            tabValue == 0 ? (
                                                                <>
                                                                    <Box mt={1}>
                                                                        <Typography className={classes.Txt}>Bar</Typography>
                                                                        <Box display="flex" sx={{ marginBottom: '10px', flexFlow: 'wrap' }}>
                                                                            {
                                                                                barChartTypes.map((item,index) => {
                                                                                    return <Box key={index} className={clsx(classes.ChartItem, chartType === item.val && 'selected')} onClick={() => {
                                                                                        setChartType(item.val)
                                                                                    }}>
                                                                                        <Tooltip title={item.label} arrow>
                                                                                        {
                                                                                            item.svg ? 
                                                                                                <img src={item.svg}/> :
                                                                                            item.icon ?
                                                                                                item.icon() :
                                                                                                item.label
                                                                                        }
                                                                                        </Tooltip>
                                                                                    </Box>
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                    <Box mt={1}>
                                                                        <Typography className={classes.Txt}>Line</Typography>
                                                                        <Box display="flex" sx={{ marginBottom: '10px', flexFlow: 'wrap' }}>
                                                                            {
                                                                                lineChartTypes.map((item,index) => {
                                                                                    return <Box key={index} className={clsx(classes.ChartItem, chartType === item.val && 'selected')} onClick={() => {
                                                                                        setChartType(item.val)
                                                                                    }}>
                                                                                        <Tooltip title={item.label} arrow>
                                                                                        {
                                                                                            item.svg ? 
                                                                                                <img src={item.svg}/> :
                                                                                            item.icon ?
                                                                                                item.icon() :
                                                                                                item.label
                                                                                        }
                                                                                        </Tooltip>
                                                                                    </Box>
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                    <Box mt={1}>
                                                                        <Typography className={classes.Txt}>Area</Typography>
                                                                        <Box display="flex" sx={{ marginBottom: '10px', flexFlow: 'wrap' }}>
                                                                            {
                                                                                areaChartTypes.map((item,index) => {
                                                                                    return <Box key={index} className={clsx(classes.ChartItem, chartType === item.val && 'selected')} onClick={() => {
                                                                                        setChartType(item.val)
                                                                                    }}>
                                                                                        <Tooltip title={item.label} arrow>
                                                                                        {
                                                                                            item.svg ? 
                                                                                                <img src={item.svg}/> :
                                                                                            item.icon ?
                                                                                                item.icon() :
                                                                                                item.label
                                                                                        }
                                                                                        </Tooltip>
                                                                                    </Box>
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                    <Box mt={1}>
                                                                        <Typography className={classes.Txt}>Donut & Pie</Typography>
                                                                        <Box display="flex" sx={{ marginBottom: '10px', flexFlow: 'wrap' }}>
                                                                            {
                                                                                donutChartTypes.map((item,index) => {
                                                                                    return <Box key={index} className={clsx(classes.ChartItem, chartType === item.val && 'selected')} onClick={() => {
                                                                                        setChartType(item.val)
                                                                                    }}>
                                                                                        <Tooltip title={item.label} arrow>
                                                                                        {
                                                                                            item.svg ? 
                                                                                                <img src={item.svg}/> :
                                                                                            item.icon ?
                                                                                                item.icon() :
                                                                                                item.label
                                                                                        }
                                                                                        </Tooltip>
                                                                                    </Box>
                                                                                })
                                                                            }
                                                                        </Box>
                                                                    </Box>
                                                                </>
                                                            ) : (
                                                                <Box>
                                                                    <Box className={classes.ChartPreivewSlot}>
                                                                        <img src={PreviewChart1} />
                                                                    </Box>
                                                                    <Box className={classes.ChartPreivewSlot}>
                                                                        Chart Preview element 2
                                                                    </Box>
                                                                    <Box className={classes.ChartPreivewSlot}>
                                                                        Chart Preview element 3
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        }
                                                    </Box>
                                                </Box>
                                            ) : settingViewMode === 'format' ? (
                                                <Box width="300px" className={classes.BorderedBox} sx={{ }}>
                                                    <Typography className={classes.Txt}>Legend</Typography>
                                                    <CustomFormInput />
                                                    <Typography className={classes.Txt}>Grid</Typography>
                                                    <CustomFormInput />
                                                    <Typography className={classes.Txt}>Tooltip</Typography>
                                                    <CustomFormInput />
                                                </Box>
                                            ) : (
                                                <Box width="300px" className={classes.BorderedBox} sx={{ padding: '10px 0px !important'}}>
                                                    <Typography className={classes.Txt}>Table Formation Types</Typography>
                                                </Box>
                                            )
                                        )
                                    }
                                    <Box display="flex" sx={{ alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                        <Tooltip title="Chart Type">
                                            <IconButton className={`${classes.SideIconBtn} ${ settingViewMode === 'chart' ? classes.SelectedIcon : '' }`} onClick={() => {
                                                if(settingViewMode === 'chart'){
                                                    setSettingViewMode(null)
                                                }else{
                                                    setSettingViewMode('chart')
                                                }
                                            }}>
                                                <EqualizerIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Table Type">
                                            <IconButton className={`${classes.SideIconBtn} ${ settingViewMode === 'table' ? classes.SelectedIcon : '' }`} onClick={() => {
                                                if(settingViewMode === 'table'){
                                                    setSettingViewMode(null)
                                                }else{
                                                    setSettingViewMode('table')
                                                }
                                            }}>
                                                <Toc />
                                            </IconButton>
                                        </Tooltip>
                                        
                                        <Tooltip title="Format Chart">
                                            <IconButton className={`${classes.SideIconBtn} ${ settingViewMode === 'format' ? classes.SelectedIcon : '' }`} onClick={() => {
                                                if(settingViewMode === 'format'){
                                                    setSettingViewMode(null)
                                                }else{
                                                    setSettingViewMode('format')
                                                }
                                            }}>
                                                <FormatPaint />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Popover
                        open={openMeasurePop}
                        anchorEl={measureAnchorEl}
                        onClose={handleMeasurePopoverClose} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ width: '200px', bgcolor: 'background.paper' }}>
                            <nav>
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton classes={{ root: classes.ListItemButton }}>
                                            <ListItemIcon classes={{ root: classes.ListItemIcon }}>
                                                <South />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.ListItemText }} primary="Sort Descending" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton classes={{ root: classes.ListItemButton }}>
                                            <ListItemIcon classes={{ root: classes.ListItemIcon }}>
                                                <North />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.ListItemText }} primary="Sort Ascending" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </nav>
                            <Divider />
                            <nav>
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton classes={{ root: classes.ListItemButton }}>
                                            <ListItemIcon classes={{ root: classes.ListItemIcon }}>
                                                <Add />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.ListItemText }} primary="Clone" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding onClick={() => {
                                        setOpenEdit(true)
                                        handleMeasureClick()
                                    }}>
                                        <ListItemButton classes={{ root: classes.ListItemButton }}>
                                            <ListItemIcon classes={{ root: classes.ListItemIcon }}>
                                                <Edit />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.ListItemText }} primary="Edit" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton classes={{ root: classes.ListItemButton }}>
                                            <ListItemIcon classes={{ root: classes.ListItemIcon }}>
                                                <Filter />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.ListItemText }} primary="Add Filter" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </nav>
                            <Divider />
                            <nav>
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton classes={{ root: classes.ListItemButton }}>
                                            <ListItemIcon classes={{ root: classes.ListItemIcon }}>
                                                <VisibilityOff />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.ListItemText }} primary="Hide" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton classes={{ root: classes.ListItemButton }}>
                                            <ListItemIcon classes={{ root: classes.ListItemIcon }}>
                                                <Delete />
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.ListItemText }} primary="Delete" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </nav>
                        </Box>
                    </Popover>
                    <Popover
                        open={openNewMeasurePop}
                        anchorEl={newMeasureAnchorEl}
                        onClose={handleNewMeasurePopoverClose} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ width: '250px', padding: '10px', bgcolor: 'background.paper' }}>  
                            <CustomFormInput
                                select
                                label="Field"
                                fieldName="field"
                                options={[]}
                                // options={fields}
                                value={null}
                                // onChange={(field, val) => { setFormula(val) }}
                            />
                            <CustomFormInput
                                select
                                label="Formula"
                                fieldName="formula"
                                options={formulas}
                                // options={fields}
                                value={formula}
                                onChange={(field, val) => { setFormula(val) }}
                            />
                        </Box>
                    </Popover>
                    <Popover
                        open={openNewGroupPop}
                        anchorEl={newGroupAnchorEl}
                        onClose={handleNewGroupPopoverClose} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Box sx={{ width: '250px', padding: '10px', bgcolor: 'background.paper' }}>  
                            <CustomFormInput
                                select
                                label="Field"
                                fieldName="field"
                                options={[]}
                                // options={fields}
                                value={null}
                                // onChange={(field, val) => { setFormula(val) }}
                            />
                        </Box>
                    </Popover>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Save</Button>
                    <Button onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openEdit}
                onClose={handleEditClose}
                className={classes.MdDialogContent}
                maxWidth="md"
            >
                <DialogTitle>
                    {"Edit Measure"}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography className={classes.Txt}>Label</Typography>
                        <CustomFormInput />
                    </Box>
                    {/* <Divider /> */}
                    <Box>
                        <Typography className={classes.Txt}>Calculation</Typography>
                        <CustomFormInput />
                    </Box>
                    <Box>
                        <Typography className={classes.Txt}>Formula</Typography>
                        <CustomFormInput />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
  };
  
DataLogistic.propTypes = {
};

export default DataLogistic
import React from 'react'
import { SvgIcon } from "@mui/material";

const DeleteIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path d="M13.4444 4.11111L12.7699 13.5553C12.7117 14.3693 12.0344 15 11.2183 15H4.78173C3.96563 15 3.28828 14.3693 3.23013 13.5553L2.55555 4.11111M6.44444 7.22222V11.8889M9.55555 7.22222V11.8889M10.3333 4.11111V1.77778C10.3333 1.34822 9.9851 1 9.55555 1H6.44444C6.01488 1 5.66666 1.34822 5.66666 1.77778V4.11111M1.77777 4.11111H14.2222" stroke="#6B7280" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
};

export default DeleteIcon;

import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  IconButton,
  Box,
  Grid,
  Typography,
  Popover,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material"
import { makeStyles } from "@mui/styles";

import { deleteUserFavoriteConfig, updateUserFavorite } from "store/slices/UserSlice"
import { showAlertDialog, resetAlertDialog } from 'store/slices/MessagesSystemSlice'
import DeleteIcon from 'components/icons/Delete'
import { Delete } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    width: '100%'
  },
  padding: {
    padding: '12px 12px 0 12px',
  },
  IconButton: {
    color: 'white',
    padding: 3,
    '& .MuiSvgIcon-root': {
      fontSize: 19
    }
  },
  TagGroupInner: {
  },
  TagItem: {
    borderBottom: 'solid 1px #c4c4c4',
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 10,
  },
  TagItemTxt: {
    paddingBottom: '20px',
    paddingTop: '11px',
    color: 'black',
    flex: 1,
    cursor: 'pointer',
    paddingLeft: '13px',
    wordBreak: 'break-all'
  },
  TagItemIconButton: {
    color: 'white',
    padding: 5,
    marginTop: 9,
    '& .MuiSvgIcon-root': {
      fontSize: 17,
    }
  },


  ListItemText: {
    fontSize: 14
  },
  ListItemButton: {
    padding: '5px 11px'
  },
  DeleteConfigIcon: {
    '& svg': {
      color: 'white',
      fontSize: '16px'
    }
  },
  AccordionSummary: {
    background: 'rgba(0, 0, 0, 0.03)',
    border: 'solid 1px #e3e3e3'
  }
}));

export default function FavGroupFolder(props) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const favs = useSelector(state => state.user.favs) || {}

  const { wrapStyle, favorites, configurations, parentFolderId, subFolderId } = props

  // console.log(parentFolderId, 'parentFolderId')
  // console.log(subFolderId, 'subFolderId')

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFav, setSelectedFav] = useState(null)

  const selectedConfiguration = useMemo(() => {
    return configurations[selectedFav] || []
  }, [
    selectedFav,
    configurations
  ])

  const handleFavClick = (event) => {
    // setSelectedPopField(field)
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPop = Boolean(anchorEl);

  const previousGroupName = useMemo(() => {
    if (parentFolderId) {
      if (subFolderId) {
        return `${parentFolderId}/${subFolderId}`
      }
      return parentFolderId
    }
    return ''
  }, [
    parentFolderId,
    subFolderId,
  ])

  useEffect(() => {
    // console.log(favorites, 'favorites')
    if(!selectedFav && favorites[0]){
      setSelectedFav(favorites[0])
    }
  }, [
    favorites
  ])

  const alertDialog = useSelector(state => state.messagesSystem.alertDialog)
  useEffect(() => {
    const { dialogAction, clickedButton, data } = alertDialog;
    if (clickedButton === "alertDialogButtonLeft") {
      if (dialogAction === "deleteFav") {
        let groupToDelete = favs[previousGroupName]
        if (data.flag === 'item') {
          let newFavorites = [...groupToDelete.favorites]
          const index = newFavorites.indexOf(data.item)
          if (index > -1) {
            newFavorites.splice(index, 1)
          }
          console.log(groupToDelete, 'groupToDelete')
          dispatch(updateUserFavorite({ groupToChange: groupToDelete, favorites: newFavorites }))
        }
      } else if (dialogAction === 'deleteFavConfig') {
        let groupToDelete = favs[previousGroupName]
        // let newFavorites = [...groupToDelete.favorites]
        // const index = newFavorites.indexOf(data.item)
        // if (index > -1) {
        //   newFavorites.splice(index, 1)
        // }
        console.log(groupToDelete, 'groupToDelete.groupName')
        dispatch(deleteUserFavoriteConfig({ group_id: groupToDelete.id, groupName: groupToDelete.groupName, favorite: data.favorite, config_id: data.config_id }))
      }
    }
    dispatch(resetAlertDialog());
  }, [alertDialog.clickedButton]);


  // const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    // setExpanded(newExpanded ? panel : false);
    setSelectedFav(newExpanded ? panel : null)
  };
  return (
    <>
      <Grid item lg={12} md={12} xs={12} className={classes.root} style={wrapStyle}>
        {
          favorites.map((item, idx) => (
            <Accordion key={idx} expanded={selectedFav === item} sx={{ marginTop: '10px' }} onChange={handleChange(item)}>
              <AccordionSummary className={classes.AccordionSummary} aria-controls={`${item}-content`} id={`${item}-header`}>
                <Typography>{item}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {
                  selectedConfiguration.map((config, cIdx) => {
                    return <ListItem disablePadding kye={cIdx}>
                      <ListItemButton
                        classes={{ root: classes.ListItemButton }}
                        onClick={() => {
                          // setSelectedLine(item.id)
                          // setAnchorEl(null)
                          // setSelectedPopField(null)
                          let queries = []
                          if (config.line) queries.push(`line=${config.line}`)
                          if (config.xAxis) queries.push(`xAxis=${config.xAxis}`)
                          if (config.yAxis) queries.push(`yAxis=${config.yAxis}`)
                          if (config.timeIncrement) queries.push(`timeIncrement=${config.timeIncrement}`)
                          history.push(`/product/detail/${selectedFav}?${queries.join('&')}`)
                        }}
                      >
                        <Box display={"flex"} sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                          <Box>
                            {
                              config.line ? (
                                <ListItemText classes={{ primary: classes.ListItemText }} primary={`Line: ${config.line}, TimeIncrement: ${config.timeIncrement}`} />
                              ) : (
                                <ListItemText
                                  classes={{ primary: classes.ListItemText }}
                                  primary={`X: ${config.xAxis}, Y: ${config.yAxis} ${config.timeIncrement ? 'TimeIncrement: ' + config.timeIncrement : ''}`}
                                />
                              )
                            }
                          </Box>
                          <IconButton className={classes.DeleteConfigIcon} onClick={(e) => {
                            e.stopPropagation()
                            dispatch(showAlertDialog({
                              title: "Confirmation",
                              message: "Are you sure you want to delete?",
                              buttonLabel: { left: "Ok", right: "Cancel" },
                              dialogAction: "deleteFavConfig",
                              data: {
                                flag: 'config',
                                favorite: selectedFav,
                                config_id: config.id
                              }
                            }));
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  })
                }
              </AccordionDetails>
            </Accordion>
          ))
        }
        {
          1 === 2 && (
            <Box className={classes.TagGroupInner} variant="div">
              <Box className={classes.TagGroupBody}>
                {
                  favorites.map((item, idx) => (
                    <Box className={classes.TagItem} key={idx}>
                      <Typography
                        className={classes.TagItemTxt}
                        onClick={(e) => {
                          handleFavClick(e)
                          setSelectedFav(item)
                        }}
                      >{item}</Typography>
                      <Box display="flex" sx={{ alignItems: 'center' }}>
                        {/* <IconButton className={classes.TagItemIconButton}>
                      <EditIcon />
                    </IconButton> */}
                        <IconButton
                          className={classes.TagItemIconButton}
                          onClick={() => {
                            dispatch(showAlertDialog({
                              title: "Confirmation",
                              message: "Are you sure you want to delete?",
                              buttonLabel: { left: "Ok", right: "Cancel" },
                              dialogAction: "deleteFav",
                              data: {
                                flag: 'item',
                                item: item
                              }
                            }));
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          )
        }
      </Grid>

      <Popover
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: '389px', bgcolor: 'background.paper', maxHeight: '273px' }}>
          <Typography className={classes.TagItemTxt} sx={{ paddingRight: '10px' }}>
            BEA/GDPbyIndustry/Percent Changes in Chain-Type Quantity Indexes for Value Added by Industry (A) (Q)
          </Typography>
          <Divider />
          <nav>
            <List>
              {
                selectedConfiguration.map((config, idx) => {
                  return <ListItem disablePadding>
                    <ListItemButton
                      classes={{ root: classes.ListItemButton }}
                      onClick={() => {
                        // setSelectedLine(item.id)
                        // setAnchorEl(null)
                        // setSelectedPopField(null)
                        let queries = []
                        if (config.line) queries.push(`line=${config.line}`)
                        if (config.xAxis) queries.push(`xAxis=${config.xAxis}`)
                        if (config.yAxis) queries.push(`yAxis=${config.yAxis}`)
                        if (config.timeIncrement) queries.push(`timeIncrement=${config.timeIncrement}`)
                        history.push(`/product/detail/${selectedFav}?${queries.join('&')}`)
                      }}
                    >
                      <Box display={"flex"} sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <Box>
                          {
                            config.line ? (
                              <ListItemText classes={{ primary: classes.ListItemText }} primary={`Line: ${config.line}, TimeIncrement: ${config.timeIncrement}`} />
                            ) : (
                              <ListItemText
                                classes={{ primary: classes.ListItemText }}
                                primary={`X: ${config.xAxis}, Y: ${config.yAxis} ${config.timeIncrement ? 'TimeIncrement: ' + config.timeIncrement : ''}`}
                              />
                            )
                          }
                        </Box>
                        <IconButton className={classes.DeleteConfigIcon} onClick={(e) => {
                          e.stopPropagation()
                          dispatch(showAlertDialog({
                            title: "Confirmation",
                            message: "Are you sure you want to delete?",
                            buttonLabel: { left: "Ok", right: "Cancel" },
                            dialogAction: "deleteFavConfig",
                            data: {
                              flag: 'config',
                              favorite: selectedFav,
                              config_id: config.id
                            }
                          }));
                        }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                })
              }
              {/* <ListItem disablePadding>
                <ListItemButton
                  classes={{ root: classes.ListItemButton }}
                  onClick={() => {
                    // setSelectedTimeIncrement(item)
                    // setAnchorEl(null)
                    // setSelectedPopField(null)
                  }}
                >
                  <ListItemText classes={{ primary: classes.ListItemText }} primary={'Test2'} />
                </ListItemButton>
              </ListItem> */}
            </List>
          </nav>
        </Box>
      </Popover>
    </>
  )
}

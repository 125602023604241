import React from "react";

import {
    MACDSeries
} from "../../../../../libs/rrag/lib/series";

import {
    MACDTooltip
} from "../../../../../libs/rrag/lib/tooltip";
import {Chart} from "../../../../../libs/rrag";


export default function MacdIndicator(props) {
    const { indicator } = props
    const macdAppearance = {
        ...props.seriesApperance,
        stroke: {
            macd: indicator?.macd?.stroke ? indicator?.macd?.stroke : "#FF0000",
            signal: indicator?.signal?.stroke ? indicator?.signal?.stroke : "#00F300",
        },
        fill: {
            divergence: indicator?.fill?.divergence ? indicator?.fill?.divergence : "#4682B4"
        }
    };
    return (
        <>
            <MACDSeries yAccessor={d => d.macd}
                        {...macdAppearance} />
            <MACDTooltip
                origin={[-38, 15]}
                yAccessor={d => d.macd}
                options={indicator['calc'].options()}
                appearance={macdAppearance}
            />
        </>
    );
}
import React, { useMemo, useState } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogActions,
    DialogContent,
    lighten,
    Grid,
    Button,
    TextField,
    Box,
    Typography,
} from "@mui/material";
import { ArrowDropDown } from '@mui/icons-material';
import ErrorMark from './ErrorMark';
import { parseDateAndTimeLocal } from 'shared/utilities/dateHelpers';

const useStyles = makeStyles((theme) => ({
    root: {
        // padding: 0
        height: 200
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    },
    alertBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: "#8080800a"
        background: "white"
    },
}));

const errorMsgs = {
    500: 'Internal Server Error',
    503: 'Service unavailable',
}

const ResumeDialog = (props) => {
    const {
        open,
        errorType,
        onReset = () => {},
        onSkip = () => {},
        onRestart = () => {},
        errorMessage = ''
    } = props
    const classes = useStyles();
    const formattedDate = useMemo(() => {
        return parseDateAndTimeLocal(props.errorDate)
    }, [
        props.errorDate
    ])

    return (
        <Dialog
            open={open}
            onClose={() => {}}
            className={classes.DialogContent}
            maxWidth="md"
        >
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} xs={12}>
                        <Box className={classes.alertBox}>
                            {
                                errorMessage !== '' ? (
                                    <Box>{ errorMessage }</Box>
                                ) : (
                                    <Box>
                                        <ErrorMark />
                                        <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                            <h3 style={{ marginRight: '3px', marginBottom: 0 }}>{errorType}</h3>
                                            <span>{errorMsgs[errorType] ?? ''}</span>
                                        </Box>
                                        <Box className="text-center">
                                            occured on { formattedDate }, during the last QA session
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Box display="flex" sx={{ width: '100%', justifyContent: 'center', marginBottom: '20px' }}>
                    <Button
                        variant='contained'
                        color='error'
                        className='mr-1'
                        onClick={onSkip}
                    >Skip</Button>
                    <Button
                        variant='contained'
                        className='mr-1'
                        onClick={onRestart}>Restart</Button>
                    <Button variant='contained' color='error' onClick={onReset}>Reset</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

ResumeDialog.propTypes = {
};

export default ResumeDialog
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { clearDatabaseCodes, fetchFeedDatabaseCodes } from 'store/slices/EodDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from "@mui/material";
import ListItem from '../Details/ListItem';
import LoadingOverlay from 'components/common/LoadingOverlay';
import QLink from '../Details/QLink';
import { parseDateToLocalFormat } from 'shared/utilities/dateHelpers';

const ListItemComponent = ({ item, active, clickHandler, clickQaHandler, setSelected, setHovered }) => (
    <Box
        className={`item ${active ? "active" : ""}`}
        onClick={() => clickHandler(item)}
        onMouseEnter={() => setSelected(item)}
        onMouseLeave={() => setHovered(undefined)}
    >
        <ListItem
            selected={active}
        >
            <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
                <Typography flex={1}>{item.title ?? item.database_code} { `(${ item.product_count || 0 })` } </Typography>
                <Box display="flex" sx={{ alignItems: 'center' }}>
                    <Typography className='mr-2 text-uppercase' sx={{ fontSize: '11px' }}>{item.last_frontend_qa_date ? parseDateToLocalFormat(item.last_frontend_qa_date) : ''}</Typography>
                    <QLink onClick={clickQaHandler} />
                </Box>
            </Box>

        </ListItem>
    </Box>
)

const Lists = (props) => {
    const { databaseCodes: items = [] } = props;
    const history = useHistory()
    const { feedSource: pFeedSource, databaseCode: pDatabaseCode } = useParams()
    const step1Sidebar = useSelector(state => state.appLayout.step1Sidebar)
    const [selected, setSelected] = useState(undefined)
    const [cursor, setCursor] = useState(0)
    const [hovered, setHovered] = useState(undefined)
    const { feedSource } = props
    const openStock = (item) => {
        if (item !== undefined) {
            if (feedSource === '' || item.database_code === '') {
                return
            }
            if (feedSource == 'BEA' && item.database_code === "MNE") {
                // history.push(`/bea_mne`)
            }
            if (item.not_have_product) {
                // if(feedSource === 'ZILLOW' && item.databaseCode === 'DATA'){
                //     props.changeStep2Index(2, item.database_code)    
                // }else{
                // history.push(`/product/detail/${encodeURIComponent(feedSource)}/${encodeURIComponent(item.database_code)}/${encodeURIComponent('')}`)
                // }
            }
        }
    }

    useEffect(() => {
        if (items.length && hovered) {
            setCursor(items.indexOf(hovered))
        }
    }, [hovered])

    const clickHandler = (item) => {
        if (feedSource === '' || item.database_code === '') {
            return
        }
        if (item.feed_source == 'BEA' && item.database_code === "MNE") {
            history.push(`/bea_mne`)
            return;
        }
        if (item.not_have_product) {
            // if(feedSource === 'ZILLOW' && item.databaseCode === 'DATA'){
                props.changeStep2Index(2, item.database_code)    
            // }else{
            // history.push(`/product/detail/${encodeURIComponent(feedSource)}/${encodeURIComponent(item.database_code)}/${encodeURIComponent('')}`)
            // }
        } else {
            if (item.section_count && item.section_count > 0) {
                props.changeStep2Index(2, item.database_code)
            } else {
                props.changeStep2Index(3, item.database_code, 'databaseCode')
            }
        }
        setCursor(items.indexOf(item))
    }

    let pressedKeys = {}

    //When use press key
    function keyDownHandler(e) {
        pressedKeys[e.keyCode] = true
        if (items.length) {
            if (e.key === 'ArrowDown') {
                setCursor(prevState => {
                    return prevState < items.length - 1 ? cursor + 1 : cursor
                })
            } else if (e.key === 'ArrowUp') {
                setCursor(prevState => {
                    return prevState > 0 ? prevState - 1 : prevState
                })
            }
        }
    }

    //When user release key pressing
    function keyUpHandler(e) {
        pressedKeys[e.keyCode] = false
        const item = items[cursor]
        openStock(item)
    }

    React.useEffect(() => {
        window.addEventListener("keydown", keyDownHandler)
        window.addEventListener("keyup", keyUpHandler)
        return () => {
            if (window && window.removeEventListener) {
                window.removeEventListener("keydown", keyDownHandler)
                window.removeEventListener("keyup", keyUpHandler)
            }
        }
    })

    return (
        <>
            {items.map((item, i) => (
                <ListItemComponent
                    key={i}
                    active={cursor === 0 ? (feedSource === pFeedSource && pDatabaseCode?.toLocaleLowerCase() === item?.database_code?.toLocaleLowerCase()) : i === cursor}
                    item={item}
                    setSelected={setSelected}
                    clickHandler={clickHandler}
                    clickQaHandler={(e) => {
                        e.stopPropagation();
                        // changeStep2Index(1, item.feed_source, null, true);
                        history.push(`/qa/${feedSource}/${item.database_code}`)
                        console.log(item, 'item')
                    }}
                    setHovered={setHovered}
                />
            ))}
        </>
    )
}

const DatabaseCodeBox = (props) => {
    const { isLoading, triggerFetchDatabaseCodes } = props;

    const databaseCodes = useSelector(state => state.eodData.databaseCodes)
    const isFull = useSelector(state => state.eodData.isDatabaseCodesFull)

    const ref = useRef(null)


    const loadMore = () => {
        const params = {}
        if (isFull || isLoading) return
        params.startIndex = databaseCodes?.length
        triggerFetchDatabaseCodes({
            ...params
        })
    }

    const scrollHandler = useCallback(() => {
        if (
            ref.current &&
            databaseCodes.length > 0 &&
            ref.current.scrollTop + ref.current.clientHeight >=
            ref.current.scrollHeight
        ) {
            if (isFull) return false
            loadMore()
        }
    }, [
        databaseCodes,
        isFull,
    ])

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("scroll", scrollHandler)
        }
        return () => {
            if (ref.current && ref.current.removeEventListener) {
                ref.current.removeEventListener('scroll', scrollHandler)
            }
        }
    }, [
        ref,
        databaseCodes
    ]);
    return <Box
        ref={ref}
        style={{ overflow: 'auto', height: '100%' }}
    >
        {
            databaseCodes?.length > 0 ? (
                <Lists
                    databaseCodes={databaseCodes}
                    {...props}
                />
            ) : !isLoading ? (
                <Box className='text-center'>There is no Database Codes</Box>
            ) : <></>
        }
    </Box>
}
const SearchResultBox = (props) => {
    const { isLoading, triggerFetchDatabaseCodes, searchWord } = props;
    const databaseCodes = useSelector(state => state.eodData.filterDatabaseCodes)
    const isFilterFull = useSelector(state => state.eodData.isFilterDatabaseCodesFull)

    const ref = useRef(null);

    const loadMore = () => {
        const params = {}
        if (isFilterFull || isLoading) return
        params.startIndex = databaseCodes?.length
        params.searchWord = searchWord
        triggerFetchDatabaseCodes({
            ...params
        })
    }

    const scrollHandler = useCallback(() => {
        if (
            ref.current &&
            databaseCodes.length > 0 &&
            ref.current.scrollTop + ref.current.clientHeight >=
            ref.current.scrollHeight
        ) {
            if (isFilterFull) return false
            loadMore()
        }
    }, [
        searchWord,
        databaseCodes,
        isFilterFull
    ])

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener("scroll", scrollHandler)
        }
        return () => {
            if (ref.current && ref.current.removeEventListener) {
                ref.current.removeEventListener('scroll', scrollHandler)
            }
        }
    }, [
        ref,
        databaseCodes
    ]);

    return <Box ref={ref} style={{ overflow: 'auto', height: '100%' }}>
        {
            databaseCodes?.length > 0 ? (
                <Lists
                    databaseCodes={databaseCodes}
                    {...props}
                />
            ) : !isLoading ? (
                <Box className='text-center'>There is no Database Codes</Box>
            ) : <></>
        }
    </Box>
}

export default function StockDatabaseCodes(props) {
    const { feedSource, searchWord = '', stepIndex } = props
    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.eodData.loadingDatabaseCodes)
    const step2Index = useSelector(state => state.eodData.step2Index)


    const triggerFetchDatabaseCodes = (params = {}) => {
        if (feedSource && feedSource !== '') {
            dispatch(fetchFeedDatabaseCodes({
                feedSource: feedSource,
                isQa: 1,
                ...params
            }))
        }
    }
    useEffect(() => {
        if (feedSource && feedSource !== '') {
            if (!searchWord || searchWord === '') {
                dispatch(clearDatabaseCodes())
                triggerFetchDatabaseCodes()
            } else {
                dispatch(clearDatabaseCodes({ flag: 'search' }))
                triggerFetchDatabaseCodes({
                    searchWord
                })
            }
        }
    }, [
        feedSource,
        step2Index,
        searchWord
    ])

    return (
        <Box style={{ overflow: 'auto', height: '100%' }} id="Qa-DatabaseCodeWrapper">
            <LoadingOverlay isLoading={isLoading}>
                {
                    searchWord === '' ? (
                        <DatabaseCodeBox
                            triggerFetchDatabaseCodes={triggerFetchDatabaseCodes}
                            isLoading={isLoading}
                            {...props}
                        />
                    ) : (
                        <SearchResultBox
                            triggerFetchDatabaseCodes={triggerFetchDatabaseCodes}
                            isLoading={isLoading}
                            {...props}
                        />
                    )
                }
            </LoadingOverlay>
        </Box>
    );
}
import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit'
import API from 'store/services/AxiosService';
import {EOD_FETCH_PRODUCT_NEWS} from 'store/UrlConstants'
import axios from 'axios'

createEntityAdapter()

export const fetchNewsData = createAsyncThunk(EOD_FETCH_PRODUCT_NEWS, async (params) => {
    let url = EOD_FETCH_PRODUCT_NEWS + `/${params.feed_source}/${params.database_code}/${params.product_code}`
    const {data: signedUrl} = await API.get(url)
    let {data: dataString} = await axios.get(signedUrl)
    dataString = dataString.replaceAll('}{', '},{')
    return JSON.parse('[' + dataString.split(/\r?\n/).filter(r => r).join(',') + ']')
})

const initialState = {
    news: [],
}

const newsSlice = createSlice({
    name: 'News',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchNewsData.pending]: (state, action) => {
            state.loadingInProgress = true
            state.news = []
        },
        [fetchNewsData.fulfilled]: (state, action) => {
            if (action.payload) {
                state.news = action.payload
            }
            state.loadingInProgress = false
        },
        [fetchNewsData.rejected]: (state, action) => {
            state.loadingInProgress = false
        },
    },
})

export const prepareSelector = state => state.News
export default newsSlice.reducer

import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  datumFilterOptions: [],
  selectedDatumFilterOptions: []
};

const ComponentSlice = createSlice({
  name: 'componentData',
  initialState,
  reducers: {
    setDatumFilterOptions: (state, action) => {
      state.datumFilterOptions = [...action.payload]
    },
    selectDatumFilterOptions: (state, action) => {
      state.selectedDatumFilterOptions = [...action.payload]
    },
  },
});

export const prepareSelector = state => state.chartData;
export const { setDatumFilterOptions, selectDatumFilterOptions } = ComponentSlice.actions
export default ComponentSlice.reducer;

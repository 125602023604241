import React, { useMemo } from 'react'
import _ from "underscore"

import { useParams } from 'react-router-dom'
import { Box } from '@mui/material';
import DataTableOptionsDialog from './DataTableOptionsDialog';
import TransformTable from './TransformTable';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSelectedFrequencies,
    setSelectedLines,
    setSelectedYears,
    setSelectedAreas,
    setSelectedSeries,
    setSelectedColumns,
    setSelectedRows
} from 'store/slices/StockProductSlice';
import { selectTimeIncrements } from 'store/selectors/ProductData';

const createHierarchy = (items, parentId = null) => {
    const result = [];
    for (const item of items) {
        if (item.parentId === parentId) {
            const children = createHierarchy(items, item.id);
            if (children.length) {
                item.children = children;
            }
            result.push(item);
        }
    }
    return result;
}
const makeColumnOfYear = (year, fr = '', prefix = '') => {
    let _column = {}
    let _prefix = prefix !== "" ? prefix + "_" : ""
    if (fr === 'A' || fr === '') {
        _column = {
            label: year,
            id: `${_prefix}annual_${year}`,
            // align: 'center',
            width: '150px'
        }
    } else if (fr === 'Q') {
        _column = {
            label: year,
            id: `${_prefix}parent_${year}_q`,
            align: 'center',
            width: 200,
            columns: [
                {
                    label: "Q1",
                    id: `${_prefix}year_${year}_q1`,
                    width: '150px'
                }, {
                    label: "Q2",
                    id: `${_prefix}year_${year}_q2`,
                    width: '150px'
                }, {
                    label: "Q3",
                    id: `${_prefix}year_${year}_q3`,
                    width: '150px'
                }, {
                    label: "Q4",
                    id: `${_prefix}year_${year}_q4`,
                    width: '150px'
                }
            ]
        }
    } else if (fr === 'M') {
        const monthsArray = []
        for (let i = 1; i <= 12; i++) {
            monthsArray.push({
                label: `M${i}`,
                id: `${_prefix}year_${year}_m${i}`,
                width: '150px'
            });
        }
        _column = {
            label: year,
            id: `${_prefix}parent_${year}_m`,
            width: '150px',
            align: 'center',
            columns: monthsArray
        }
    }
    return _column
}
const makeColumnOfArea = (item, years = [], isTotal = false) => {
    const itemId = item.id
    const itemLabel = isTotal ? "Total of" + (item.label || item.id) : (item.label || item.id)
    const _column = {
        id: itemId,
        label: itemLabel,
        columns: []
    };
    if(item.children){
        _column.columns = [];
        const _totalColumn = makeColumnOfArea({
            id: itemId,
            label: item.label
        }, years, true)
        _column.id = 'parent_' + itemId
        _column.columns.push(_totalColumn)
        for(const child of item.children){
            _column.columns.push(makeColumnOfArea(child, years, isTotal))
        }
    }else{
        for(const year of years){
            const _yearColumn = makeColumnOfYear(year, '', itemId)
            _column.columns.push(_yearColumn)
        }
    }
    return _column;
}

function TransformContainer(props) {
    const dispatch = useDispatch()
    const { feedSource, databaseCode, productCode = '', blob, openModifyDialog, handleMidifyDialog, productResource, dateField = 'time_period_start', identifier } = props

    const selectedLines = useSelector(state => state.productData.selectedLines)
    const selectedYears = useSelector(state => state.productData.selectedYears)
    const selectedSeries = useSelector(state => state.productData.selectedSeries)
    const selectedRows = useSelector(state => state.productData.selectedRows)
    const selectedColumns = useSelector(state => state.productData.selectedColumns)
    const selectedFrequencies = useSelector(state => state.productData.selectedFrequencies)
    const selectedAreas = useSelector(state => state.productData.selectedAreas)
    const timeIncrements = useSelector(selectTimeIncrements)

    const columns = useMemo(() => {
        const _columns = [{
            label: 'Line',
            id: 'lineNumber',
            width: '100px',
            sticky: true
        },
        {
            label: 'Current Account',
            id: 'line',
            width: '300px',
            sticky: true
        }]
        let years = [...selectedYears];
        let frequency = [...selectedFrequencies]
        let areaIds = [...selectedAreas]
        if (years.length < 1) {
            years = productResource?.years?.slice(0, 5) || [];
        }
        let areas = [];
        let formattedAreas = []
        if(databaseCode === 'IntlServTrade'){
            if (areaIds.length < 1) {
                areas = productResource?.areas?.slice(0, 10);
            }else{
                areas = productResource?.areas?.filter(item => areaIds.indexOf(item.id) > -1)
            }
            formattedAreas = createHierarchy(JSON.parse(JSON.stringify(areas)))
        }
        if (frequency.length < 1) {
            if (timeIncrements.length > 0) frequency = timeIncrements[0]
            else frequency = [""]
        }
        years = years?.sort((a, b) => a - b)
        for (let fr of frequency) {
            if (databaseCode === 'IntlServTrade') {
                for (const a of formattedAreas) {
                    const _column = makeColumnOfArea(a, years)
                    _columns.push(_column)
                }
            } else {
                for (let year of years) {
                    const _column = makeColumnOfYear(year, fr)
                    _columns.push(_column)
                }
            }
        }
        return _columns
    }, [
        productResource.years,
        timeIncrements,
        selectedYears,
        selectedFrequencies,
        selectedAreas
    ]);
    // useEffect(() => {
    // }, [options])
    const handleCloseOptionsModal = () => {
        handleMidifyDialog(false)
    }

    const rows = useMemo(() => {
        return JSON.parse(JSON.stringify(productResource.data))
    }, [
        productResource.data
    ])
    return (
        <Box id={blob === 'big-query' ? 'BigqueryDatatable' : 'CloudStorageDatatable'}>
            <TransformTable
                columns={columns}
                data={rows}
                tableId={`${identifier}Inner`}
            />
            {
                openModifyDialog && (
                    <DataTableOptionsDialog
                        open={openModifyDialog}
                        onClose={handleCloseOptionsModal}
                        feedSource={feedSource}
                        databaseCode={databaseCode}
                        productCode={productCode}
                        options={{
                            year: selectedYears,
                            line: selectedLines,
                            frequency: selectedFrequencies,
                            area: selectedAreas,
                            serie: selectedSeries,
                            column: selectedColumns,
                            row: selectedRows
                        }}
                        handleOptions={(newOptions) => {
                            const { year, frequency, line, area, row, serie, column } = newOptions
                            dispatch(setSelectedFrequencies(frequency))
                            console.log(serie, 'serie')
                            dispatch(setSelectedLines(line))
                            dispatch(setSelectedYears(year))
                            dispatch(setSelectedSeries(serie))
                            dispatch(setSelectedColumns(column))
                            dispatch(setSelectedRows(row))
                            console.log(area, 'in handleOptions')
                            dispatch(setSelectedAreas(area))
                        }}
                        blob={blob}
                        years={productResource.years}
                        lines={productResource.lines}
                        series={productResource.series}
                        columns={productResource.columns}
                        rows={productResource.rows}
                        areas={productResource?.areas || []}
                    />
                )
            }
        </Box>
    )
}

export default TransformContainer
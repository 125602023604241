import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, AuthLayout } from "./layouts";

// Route Views
import Home from "./views/home";
import UrlModule from "./views/summary/containers/UrlOption";

import Test from "./views/Test/index";
import Login from "./views/auth/Login";
import Demo from "./views/auth/Demo";
import Category from "./views/Category";

import SearchResult from "./views/SearchResult";
import ProductModule from "./views/product-module/containers";
import ProductPageComponent from "./views/product";
import QA from "./views/qa";
import CovidView from "./views/covid/CovidView";
import statistic from "./views/statistic";
import TestRrrag from "./views/Test/rrag";
import QALogin from "views/auth/QALogin";
import BeaMne from "views/bea-mne";
import MockupPage from "views/mockup"
import MockupQAResultsTable from "views/mockup/QAResultsTable"
import ProductProvider from "./providers/product/ProductProvider";

export const authRoutes = [
    {
        path: "/login",
        navigationOptions: {
            name:'login',
            title: 'Login',
        },
        layout: AuthLayout,
        component: Login
    },
    {
        path: "/qa-login",
        navigationOptions: {
            name:'qa-login',
            title: 'Login',
        },
        layout: AuthLayout,
        component: QALogin
    },
    {
        path: "/demo",
        navigationOptions: {
            name:'demo',
            title: 'Demo',
        },
        layout: AuthLayout,
        component: Demo
    },
    // {
    //     path: "/d3",
    //     navigationOptions: {
    //         name: 'd3',
    //         title: 'D3'
    //     },
    //     layout: AuthLayout,
    //     component: () => <></>
    // }
];
export const defaultRoutes = [
    {
        path: "/",
        exact: true,
        layout: DefaultLayout,
        component: Home
    },
    {
        path: "/home",
        navigationOptions: {
            name:'home',
            title: 'Home',
        },
        layout: DefaultLayout,
        component: Home
    },
    {
        path: "/qa/:feedSource/:databaseCode",
        navigationOptions: {
            name:'qa',
            title: 'QA',
        },
        layout: DefaultLayout,
        component: () => <MockupPage />
    },
    {
        path: "/qa/result/:qaReportId",
        navigationOptions: {
            name:'qa',
            title: 'QA',
        },
        layout: DefaultLayout,
        component: () => <MockupQAResultsTable />
    },
    {
        path: "/qa-mockup",
        navigationOptions: {
            name: 'qa-mockup',
            title: 'QA mockup'
        },
        component: () => <MockupPage />
    },
    {
        path: "/qa-result-mockup/:qaReportId",
        navigationOptions: {
            name: 'qa-result-mockup',
            title: 'QA Result mockup'
        },
        component: () => <MockupQAResultsTable />
    },
    {
        path: "/bea_mne",
        navigationOptions: {
            name:'bea_mne',
            title: 'BEA MNE',
        },
        layout: DefaultLayout,
        component: BeaMne
    },
    {
        path: "/url_module",
        navigationOptions: {
            name:'url_module',
            title: 'Url Module',
        },
        layout: DefaultLayout,
        component: UrlModule
    },
    {
        path: "/test",
        navigationOptions: {
            name:'test',
            title: 'Test',
        },
        layout: DefaultLayout,
        component: Test
    },
    // {
    //     path: "/category",
    //     navigationOptions: {
    //         name: 'category',
    //         title: 'Category',
    //     },
    //     layout: DefaultLayout,
    //     component: Category
    // },
    {
        path: "/statistic",
        navigationOptions: {
            name: 'statistic',
            title: 'Statistic',
        },
        layout: DefaultLayout,
        component: statistic
    },
    {
        path: "/test-rrag",
        navigationOptions: {
            name: 'statistic',
            title: 'Statistic',
        },
        layout: DefaultLayout,
        component: TestRrrag
    },
    {
        path: "/product/detail/:feedSource/:databaseCode/:productCode?",
        navigationOptions: {
            name: 'ProductModule',
            title: 'Product Module',
        },
        layout: DefaultLayout,
        component: () => <ProductProvider>
            <ProductModule />
        </ProductProvider>
    },
    {
        path: "/product/:feedSource/:databaseCode/:productCode?",
        navigationOptions: {
            name: 'Product',
            title: 'Product',
        },
        layout: DefaultLayout,
        component: () => <ProductProvider>
            <ProductPageComponent />
        </ProductProvider>
    },
    {
        path: "/search_result",
        navigationOptions: {
            name: 'search',
            title: 'Search',
        },
        layout: DefaultLayout,
        component: SearchResult
    },
    {
        path: "/covid",
        navigationOptions: {
            name: 'covid',
            title: 'Covid-19',
        },
        layout: DefaultLayout,
        component: CovidView
    }
];
export default defaultRoutes;

import moment from 'moment'
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import TimeSeries from "fusioncharts/fusioncharts.timeseries";
import ReactFC from 'react-fusioncharts';

import {
    testTheme
} from './theme/TestTheme.js';
FusionCharts.register('theme', [testTheme]);

ReactFC.fcRoot(FusionCharts, TimeSeries);

FusionCharts.options.license({
    key:
        "BzA11vmaG5E1E3A16A3A5A3C1A1B3D1E2gvxC3E5qyuiI-7B6B1mA-13tF5H2E4J2A4D4C3C6E2A2F4F1B1A7C8A3cE-11B-9F2A2H2vB2D3D2knyE1C3C11gapB4E4D4kH-7C-21D7E4F4C1H5G1G1I4B6C1E3pzzC1G4AB3zD-13mD1e1H3A33fb1qD8B1B4B1B1B1G1B17A1B3B1D6C4A2i== ",
    creditLabel: false
});

export {
    FusionCharts,
    ReactFC
};
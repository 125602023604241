import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from "@mui/styles";
import _ from 'underscore'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    lighten,
    IconButton,
    Tooltip,
    Grid,
    Box,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Popover,
    Button,
    Typography,
    RadioGroup,
    Radio,
    Autocomplete,
    TextField
} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import CustomFormInput from 'components/common/Base/CustomFormInput';
import LineChart from './Preview/Line';

import BarChartIcon from '@mui/icons-material/BarChart';
import LineChartSvg from 'assets/images/chart/Line.svg'
import PointMarkerSvg from 'assets/images/chart/PointMarker.svg'
import ColumnRangeSvg from 'assets/images/chart/ColumnRange.svg'
import AreaChartSvg from 'assets/images/chart/Area.svg'
import AreaRangeChartSvg from 'assets/images/chart/AreaRange.svg'
import HorizontalBarChart from 'assets/images/chart/HorizontalBar.svg'
import ScatterLineChart from 'assets/images/chart/ScatterLine.svg'
import DepthChart from 'assets/images/chart/DepthChart.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '90%',
            maxWidth: '60rem',
        }
    },
    IconButton: {
        padding: 0,
        marginLeft: '10px'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    BorderedBox: {
        display: 'flex',
        flexFlow: 'column',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        minHeight: '100px',
        height: '100%',
        padding: '10px',
        maxHeight: '500px',
        overflow: 'auto',
    },
    PreviewBox: {
        backgroundColor: '#eef1f6'
    },
    PreviewTitle: {
        color: 'black',
        fontSize: '.75rem'
    },
    popoverInner:{
        height: 400,
        overflow: 'auto',
        padding: '10px',
        display: 'flex',
        flexFlow: 'column'
    },
    Buttons:{
        display: 'flex',
        flexDirection: 'initial',
        borderBottom: 'solid 1px #f1f1f1',
        marginBottom: '3px',
        '& > button': {
            fontSize: '12px',
            padding: '3px 4px',
            marginRight: '2px'
        }
    },
    FieldSet: {
        margin: 0,
        flex: 1,
        overflow: 'auto'
    },
    filterWrap: {
        padding: '17px'
        // display: 'flex',
        // '& > div': {
        //     flex: 1
        // }
    },
    FormGroup: {
        marginBottom: '10px'
    },
    FormLabel: {
        display: 'block',
        marginBottom: '3px',
        fontFamily: 'system-ui',
        textTransform: 'uppercase'
    },
    FormControl: {
        width: '100%',
        padding: '5px 4px',
        borderRadius: '4px',
        border: 'solid 1px #a8a8a8',
        '&:focus': {
            outline: 'none',
            boxShadow: '0 0 4px -1px #2bffc6'
        }
    },
    CheckboxControl:{
        marginBottom: '0',
        '& span': {
            fontSize: '13px'
        }
    },
    Section: {
        position: 'relative',
        borderBottom: 'solid 1px #ede2e2',
        paddingBottom: '11px',
        marginBottom: 10,
        '&::before': {
            content: "''",
            top: 24,
            left: '0',
            height: 'calc(100% - 24px)',
            position: 'absolute',
            background: '#ede2e2',
            width: '1px',
        },
    },
    SectionTitle: {
        color: 'black',
        fontSize: 13,
        paddingLeft: 19
    },
    SubSection: {
        position: 'relative',
        paddingLeft: '20px',
        paddingTop: '4px',
        '&::before': {
            content: "''",
            top: '15px',
            left: '1px',
            width: '13px',
            height: '1px',
            position: 'absolute',
            background: '#ede2e2',
        },
    },
    CategoryRadio: {
        '& .MuiFormControlLabel-root': {
            margin: 0,
            marginBottom: 6,
            '& .MuiRadio-root': {
                padding: 0,
                transform: 'scale(0.8)'
            },
            '& .MuiFormControlLabel-label': {
                fontSize: '0.75rem'
            }
        }
    },
    Text: {
        fontSize: '.75rem',
        color: 'black'
    },
    CategoryEntity: {
        paddingTop: '7px',
        paddingLeft: '24px',
    },
    ChartItem: {
        cursor: 'pointer',
        margin: '3px 2px',
        width: '40px',
        height: '40px',
        fontSize: '13px',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        backgroundColor: '#eef1f6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& a': {
            color: 'black'
        },
        '&.selected': {
            boxShadow: '0px 0px 3px -1px black',
            background: '#2196f3',
            '& a': {
                color: 'white'
            }
        },
        '& > img': {
            width: '23px',
            '&.Rotated90Deg': {
                transform: 'rotate(90deg)'
            }
        }
    },
}));
  
const formulas = [
    { label: 'Sum', value: 'sum' },
    { label: 'Avg', value: 'avg' },
    { label: 'Maximum', value: 'Maximum' },
    { label: 'Minimum', value: 'Minimum' },
    { label: 'Count of Rows', value: 'count' },
]
const chartTypes = [
    { val: 'line', label: 'Line', svg: LineChartSvg},
    { val: 'donut', label: 'Donut', icon: () => <DonutLargeIcon />},
    { val: 'area', label: 'Area', svg: AreaChartSvg},
    { val: 'arearange', label: 'Area Range', svg: AreaRangeChartSvg},
    { val: 'column', label: 'Column', icon: () => <BarChartIcon />},
    { val: 'bar', label: 'Bar', svg: HorizontalBarChart },
    { val: 'columnrange', label: 'Column Range', svg: ColumnRangeSvg},
    { val: 'point-marker', label: 'Point Marker', svg: PointMarkerSvg},
    { val: 'scatter-line', label: 'Scatter Marker Line', svg: ScatterLineChart},
    { val: 'depth-chart', label: 'Depth Chart', svg: DepthChart},
];

const ChartSetting = (props) => {
    const { 
        data,
        fields: initialFields = [],
    } = props
    const classes = useStyles();
    const [fields, setFields] = useState([])
    const [open, setOpen] = useState(false)
    const [chartType, setChartType] = useState('line')
    const [xAxis, setXAxis] = useState('year')
    const [formula, setFormula] = useState('sum')
    const [yAxis, setYAxis] = useState('actual')
    const [categoryBy, setCategorBy] = useState('uid')
    const [groupBy, setGroupBy] = useState('uid')
    const [entities, setEntities] = useState([])
    const [entityType, setEntityType] = useState('multiple')
    const [selectedEntity, setSelectedEntity] = useState([])
    
    useEffect(() => {
        const _fields = []
        initialFields.map(f => {
            _fields.push({
                label: f.id,
                value: f.id
            })
        })
        setFields([..._fields])
    }, [initialFields])

    useEffect(() => {
        let splittedData = []
        if(categoryBy && Array.isArray(data)){
            splittedData = _.groupBy(data, function(i){ return i[categoryBy] });
            const _entities = _.keys(splittedData).map(item => {
                return { label: item, value: item }
            });
            setEntities([..._entities])
        }
    }, [
        data, categoryBy
    ])
    
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Tooltip title="Chart Setting">
                <IconButton className={classes.IconButton} variant="contained" color="primary" onClick={() => {
                    setOpen(true)
                }}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                className={classes.DialogContent}
                maxWidth="lg"
            >
                <DialogTitle>
                    {"Chart Settings"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item lg={5} md={5} xs={12}>
                            <Box className={classes.BorderedBox}>
                                <Box display="flex" sx={{ marginBottom: '10px', flexFlow: 'wrap' }}>
                                {
                                    chartTypes.map((item,index) => {
                                        return <Box key={index} className={clsx(classes.ChartItem, chartType === item.val && 'selected')} onClick={() => {
                                            setChartType(item.val)
                                        }}>
                                            <Tooltip title={item.label} arrow>
                                            {
                                                item.svg ? 
                                                    <img src={item.svg}/> :
                                                item.icon ?
                                                    item.icon() :
                                                    item.label
                                            }
                                            </Tooltip>
                                        </Box>
                                    })
                                }
                                </Box>
                                {
                                    chartType === 'donut' ? (
                                        <>
                                        <Box className={classes.Section}>
                                                <Typography className={classes.SectionTitle}>Split By</Typography>
                                                <Box className={classes.SubSection}>
                                                    <CustomFormInput
                                                        select
                                                        label=""
                                                        fieldName="categoryBy"
                                                        options={fields}
                                                        value={categoryBy}
                                                        onChange={(field, val) => { setCategorBy(val) }}
                                                    />
                                                </Box>
                                                <Box className={`${classes.SubSection} ${classes.CategoryEntity}`}>
                                                    <Typography className={classes.Text}></Typography>
                                                    <Autocomplete
                                                        id="size-small-outlined"
                                                        size="small"
                                                        options={entities || []}
                                                        getOptionLabel={(option) => option.label}
                                                        defaultValue={null}
                                                        // defaultValue={[]}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label="Entity Selection"
                                                                placeholder=""
                                                            />
                                                            // <div ref={params.InputProps.ref}>
                                                            //     <input type="text" {...params.inputProps} />
                                                            // </div>
                                                        )}
                                                        onChange={(e, newValue) => {
                                                            // if(entityType === 'single'){
                                                            //     setSelectedEntity([newValue.value])
                                                            // }else{
                                                            //     setSelectedEntity(newValue.map(item => item.value)) 
                                                            // }
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className={classes.Section}>
                                                <Typography className={classes.SectionTitle}>Segment By</Typography>
                                                <Box className={classes.SubSection}>
                                                    <CustomFormInput
                                                        select
                                                        label=""
                                                        fieldName="categoryBy"
                                                        options={fields}
                                                        value={categoryBy}
                                                        onChange={(field, val) => { setCategorBy(val) }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className={classes.Section}>
                                                <Typography className={classes.SectionTitle}>Segment Size</Typography>
                                                <Box className={`${classes.SubSection}`}>
                                                    <CustomFormInput
                                                        select
                                                        label=""
                                                        fieldName="formula"
                                                        options={formulas}
                                                        // options={fields}
                                                        value={formula}
                                                        onChange={(field, val) => { setFormula(val) }}
                                                    />
                                                </Box>
                                            </Box>
                                        </>     
                                    ) : (
                                        <>
                                            <Box className={classes.Section}>
                                                <Typography className={classes.SectionTitle}>Split By</Typography>
                                                <Box className={classes.SubSection}>
                                                    <CustomFormInput
                                                        select
                                                        label=""
                                                        fieldName="categoryBy"
                                                        options={fields}
                                                        value={categoryBy}
                                                        onChange={(field, val) => { setCategorBy(val) }}
                                                    />
                                                </Box>
                                                <Box className={`${classes.CategoryRadio} ${classes.SubSection}`}>
                                                    <FormControl>
                                                        <RadioGroup defaultValue={entityType} onChange={e => {
                                                            const _entityType = e.target.value
                                                            let _selectedEntity = []
                                                            if(_entityType === 'single'){
                                                                if(Array.isArray(selectedEntity) && selectedEntity.length > 0){
                                                                    _selectedEntity = selectedEntity[0]
                                                                }
                                                            }
                                                            if(_selectedEntity !== undefined){
                                                                setSelectedEntity(_selectedEntity)
                                                            }
                                                            setEntityType(_entityType)
                                                        }}>
                                                            <FormControlLabel value="none" control={<Radio />} label="None" />
                                                            <FormControlLabel value="single" control={<Radio />} label="Single Selection" />
                                                            <FormControlLabel value="multiple" control={<Radio />} label="Multiple Selection" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Box>
                                                <Box className={`${classes.SubSection} ${classes.CategoryEntity}`}>
                                                    <Typography className={classes.Text}></Typography>
                                                    <Autocomplete
                                                        id="size-small-outlined"
                                                        size="small"
                                                        options={entities || []}
                                                        getOptionLabel={(option) => option.label}
                                                        defaultValue={entityType === 'single' ? (selectedEntity[0] || null) : selectedEntity}
                                                        // defaultValue={[]}
                                                        multiple={entityType === 'multiple'}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                label="Entity Selection"
                                                                placeholder=""
                                                            />
                                                            // <div ref={params.InputProps.ref}>
                                                            //     <input type="text" {...params.inputProps} />
                                                            // </div>
                                                        )}
                                                        onChange={(e, newValue) => {
                                                            if(entityType === 'single'){
                                                                setSelectedEntity([newValue.value])
                                                            }else{
                                                                setSelectedEntity(newValue.map(item => item.value)) 
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className={classes.Section}>
                                                <Typography className={classes.SectionTitle}>Y Axis</Typography>
                                                <Box className={classes.SubSection}>
                                                    <CustomFormInput
                                                        select
                                                        label="Group By"
                                                        fieldName="groupBy"
                                                        options={fields}
                                                        value={groupBy}
                                                        onChange={(field, val) => { setGroupBy(val) }}
                                                    />
                                                </Box>
                                                <Box className={`${classes.SubSection}`}>
                                                    <CustomFormInput
                                                        select
                                                        label="Field"
                                                        fieldName="yAxis"
                                                        options={fields}
                                                        value={yAxis}
                                                        onChange={(field, val) => { setYAxis(val) }}
                                                    />
                                                </Box>
                                                <Box className={`${classes.SubSection}`}>
                                                    <CustomFormInput
                                                        select
                                                        label="Formula"
                                                        fieldName="formula"
                                                        options={formulas}
                                                        // options={fields}
                                                        value={formula}
                                                        onChange={(field, val) => { setFormula(val) }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Typography className={classes.SectionTitle}>X Axis</Typography>
                                                <Box sx={{ paddingLeft: '20px' }}>
                                                    <CustomFormInput
                                                        select
                                                        label=""
                                                        fieldName="xAxis"
                                                        options={fields}
                                                        value={xAxis}
                                                        onChange={(field, val) => { setXAxis(val) }}
                                                    />
                                                </Box>
                                            </Box>
                                        </>
                                    )
                                }
                            </Box>
                        </Grid>
                        <Grid item lg={7} md={7} xs={12}>
                            <Box className={`${classes.BorderedBox} ${classes.PreviewBox}`}>
                                <Typography className={classes.PreviewTitle}>Preview</Typography>
                                <Box>
                                    <LineChart
                                        data={data}
                                        xAxis={xAxis}
                                        yAxis={yAxis}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Save</Button>
                    <Button onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
  };
  
ChartSetting.propTypes = {
};

export default ChartSetting
import React from "react";
import PropTypes from "prop-types";

import {compose} from "recompose";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import {makeStyles} from "@mui/styles";

import MuiAlert from '@mui/lab/Alert';

import { scaleOrdinal, scaleTime, scalePoint, schemeCategory10 } from  "d3-scale";
import { timeParse } from "d3-time-format";
import { format } from "d3-format";
import moment from "moment";

import { discontinuousTimeScaleProvider, discontinuousTimeScaleProviderBuilder} from "libs/rrag/lib/scale";

import { ChartCanvas, Chart, ZoomButtons } from "libs/rrag";
import { XAxis, YAxis } from "libs/rrag/lib/axes";
import {
	CrossHairCursor,
	MouseCoordinateX,
	MouseCoordinateY,
} from "libs/rrag/lib/coordinates";

import { fitWidth } from "libs/rrag/lib/helper";
import { last, strokeDashTypes } from "libs/rrag/lib/utils";
import { HoverTooltip} from "libs/rrag/lib/tooltip";
import { LineChart, AreaChart } from "./partials/series";
import { Brush, ClickCallback } from "libs/rrag/lib/interactive";
import {
	GroupedBarSeries,
} from "libs/rrag/lib/series";

import {
    getTooltipContent
} from "./utils";
import { saveInteractiveNode } from './utils/interactiveUtils'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const parseDate = timeParse("%Y-%m-%d");
class NonMarektChartComponent extends React.Component {
	constructor(props){
		super(props)
		this.drawChartTheme = this.drawChartTheme.bind(this);
		this.parseData = this.parseData.bind(this);
		this.handleReset = this.handleReset.bind(this);
        this.state = {
			xExtents:[],
			brushEnabled: true,
		};
        this.handleBrush = this.handleBrush.bind(this)
		this.saveInteractiveNode = saveInteractiveNode.bind(this);
	}
	componentWillMount() {
		this.setState({
			suffix: 1
		});
	}
	handleReset() {
		this.setState({
			suffix: this.state.suffix + 1
		});
	}
    
    handleBrush(brushCoords, moreProps) {
		const { start, end } = brushCoords;
		const left = Math.min(start.xValue, end.xValue);
		const right = Math.max(start.xValue, end.xValue);

		const low = Math.min(start.yValue, end.yValue);
		const high = Math.max(start.yValue, end.yValue);

		// uncomment the line below to make the brush to zoom
		this.setState({
			xExtents: [left, right],
			brushEnabled: false,
		});
	}
	drawChartTheme(){
        const { themeBg } = this.props.chartData
        const themeProps = {
            XAxis:{ tickStrokeOpacity: 0.5},
            YAxis: { tickStrokeOpacity: 0.5, tickStroke:"black", stroke: "black" }, 
            toolTipApperance: {},
        }
        if(themeBg?.name !== undefined){
            switch(themeBg.name){
                case 'white':
                    break;
                // case 'dark':
                default:
                    themeProps.XAxis = { tickStrokeOpacity: 0.5, tickLabelFill:'#fff', stroke: "#fff" };
                    themeProps.YAxis = { tickStrokeOpacity: 0.5, tickLabelFill:'#fff', stroke: "#fff" };
                    themeProps.toolTipApperance = { labelFill: '#7698ce', textFill: '#a5a2a2' }
                    break
            }
        }
        return themeProps
    }
    parseData(){
        const { data: initialData, xAxis, yAxis, entityField, checked, tooltips} = this.props;
        let newData = {};
        initialData.map(item => {
            // if(item[xAxis]){
                let xAxisValue;
                if(item[xAxis] instanceof Date){
                    xAxisValue = moment(item[xAxis]).format('YYYY-MM-DD');
                }else {
                    const _date = new Date(xAxisValue)
                    const momentDate = moment(item[xAxis])
                    if(momentDate.isValid()){
                        xAxisValue = momentDate.format('YYYY-MM-DD')
                    }else{
                        xAxisValue = moment().format('YYYY-MM-DD')
                    }
                }
                if(!newData[xAxisValue]) {
                    newData[xAxisValue] = {
                        record_date: parseDate(xAxisValue) 
                    }
                    tooltips.map((tItem) => {
                        newData[xAxisValue][tItem] = item[tItem]
                    })
                    // checked.map((ch) => {
                    //     newData[xAxisValue][ch + '_' + yAxis] = 0.0000000000001
                    // })
                }
                if(entityField){
                    checked.map((ch) => {
                        if(ch === item[entityField]) {
                            newData[xAxisValue][ch + '_' + yAxis] = item[yAxis]
                        }
                    })
                }else{
                    newData[xAxisValue]['ch_' + yAxis] = item[yAxis]
                }

            // }
        })
        newData = Object.values(newData);
        return newData;
    }
	render() {
		const { 
            classes, checked,
            type, width, ratio, gridProps, 
            chartType, entityField,yAxis, tooltips 
        } = this.props;
		const { themeBg, grid } = this.props.chartData;
        const { 
            XAxis: xAixProps, YAxis: yAixProps,
            toolTipApperance
		} = this.drawChartTheme();

		const height = 400;
		const gridHeight = height;
		const gridWidth = width - 70 - 70;

		const showGrid = grid?.show || false;
		const yGrid = showGrid ? { innerTickSize: -1 * gridWidth } : {};
		const xGrid = showGrid ? { innerTickSize: -1 * gridHeight } : {};
        xAixProps.tickStroke = grid?.tickStroke
        yAixProps.tickStroke = grid?.tickStroke

        let initialData = this.parseData();
        const xScaleProvider = discontinuousTimeScaleProvider
                .inputDateAccessor(d => d.record_date);
        const {
                data,
                xScale,
                xAccessor,
                displayXAccessor,
            } = xScaleProvider(initialData);
            
        if(data.length < 2){
            return <Alert severity="error">Unable To draw Chart</Alert>
        }

        const entities = entityField ? checked : ['ch']
            
    // let xAccessor = d => d.record_date;
    // let xScale = scaleTime();
        let yAccessor = d => {
            const accessors = [];
            entities.map(ch => {
                accessors.push(d[ch + '_' + yAxis])
            })
            return accessors
        }
        let barYAcessors = []
        if(chartType === 'bar'){
            yAccessor = [0];
            entities.map(ch => {
                barYAcessors.push(d => d[ch + '_' + yAxis])
            })
            yAccessor.push(d => {
                const accs = []  
                entities.map(ch => {
                    accs.push(d[ch + '_' + yAxis])
                })
                return accs
            })
        }
        let xExtents = [
            xAccessor(last(data)),
            xAccessor(data[data.length - (data.length > 20 ? 20 : data.length)])
        ];
        const tooltipProps = {
            fontSize: 13,
            fill: 'white',
            opacity: 1,
            stroke: 'grey'
        }
        let tooltipYs = [
        ]
        entities.map(item => {
            tooltipYs.push({
                label: item.split('_').join(' ').toUpperCase(), value: d => d[item + '_' + yAxis]
            })
        })
        if(Array.isArray(tooltips)){
            tooltips.map(item => {
                tooltipYs.push({
                    label: item.split('_').join(" ").toUpperCase(), value: d => d[item]
                })
            })
        }
        const strokes = ['#f5365c', '#ffd600', '#11cdef','#5e72e4', '#00f2c3']
        const areaStorkes = [
            ['#eccbd2', '#f18298', '#f5365c'], 
            ['#f5eab1', '#f1d95e', '#ffd600'],
            ['#bce9f1', '#6ae5fb', '#11cdef'],
            ['#cdd3f5', '#a2aff5', '#5e72e4'],
            ['#d1fbf3', '#73f1d8', '#00f2c3'],
        ]

        //test
        // data = [
        //     { "x": "xApple", "y1": 10, "y2": 5, "y3": 15, "y4": 10 },
        //     { "x": "Banana", "y1": 15, "y2": 10, "y3": 5, "y4": 10 },
        //     { "x": "Strawberry", "y1": 20, "y2": 15, "y3": 5, "y4": 10 },
        //     { "x": "Lemon", "y1": 25, "y2": 20, "y3": 5, "y4": 10 },
        //     { "x": "Cherry", "y1": 30, "y2": 25, "y3": 10, "y4": 10 },
        //     { "x": "Peach", "y1": 35, "y2": 30, "y3": 15, "y4": 10 }
        // ]
        // xExtents= list => list.map(d => d.x)
        // xAccessor = d => d.x
        // xScale=scalePoint()
        // yAccessor = [0, d => [d.y1, d.y2, d.y3, d.y4]]
        // barYAcessors = [d => d.y1, d => d.y2, d => d.y3, d => d.y4];

        const {brushEnabled} = this.state
        const BRUSH_TYPE = '2D'
		return (
            <>
                <ChartCanvas ratio={1} width={width} height={400} bg={themeBg && themeBg?.color ? themeBg?.color: ''}
                        margin={{ left: 70, right: 70, top: 20, bottom: 30 }} 
                        type={type}
                        seriesName="Countries' Financial Rate"
                        data={data}
                        xScale={xScale}
                        xExtents={xExtents}
                        // xExtents={[new Date(2001, 0, 1), new Date(2009, 0, 2)]}
                        xAccessor={xAccessor}
                        displayXAccessor={displayXAccessor}
                        // padding={1}
                        >
                    <Chart id={1}
                            yExtents={yAccessor}
                            yMousePointerRectWidth={45}
                            padding={{ top: 20, bottom: 20 }}>
                        <XAxis axisAt="bottom" orient="bottom" 
                            {...gridProps}
                            {...xGrid}
                            {...xAixProps}
                        />
                        <YAxis axisAt="left" orient="left" 
                            {...gridProps}
                            {...yGrid}
                            {...yAixProps}
                        />
                        {
                            chartType === 'bar' ?
                                <GroupedBarSeries yAccessor={barYAcessors}
                                    fill={(d, i) => strokes[i]}
                                    spaceBetweenBar={3}/>:
                                    entities.map((yField, i) => {
                                        const chYAcessor = d => d[yField + '_' + yAxis]
                                        if(chartType === 'area'){
                                            return <AreaChart key={i} yAccessor={chYAcessor} strokes={areaStorkes[i]} highlightOnHover={true}/>
                                        }
                                        return <LineChart key={i} yAccessor={chYAcessor} stroke={strokes[i]} highlightOnHover={true}/>
                                    })
                        }
                        {/* <LineChart yAccessor={yAccessor} /> */}
                        <MouseCoordinateX snapX={false}
                            at="bottom"
                            orient="bottom"
                            rectWidth={50}
                            displayFormat={format(".0f")} />
                        <MouseCoordinateY
                            at="left"
                            orient="left"
                            displayFormat={format(".2f")} />
                        <ZoomButtons
                            onReset={this.handleReset}
                        />
                        <HoverTooltip
                            yAccessor={yAccessor}
                            tooltipContent={getTooltipContent(tooltipYs, false)}
                            {...tooltipProps}
                        />
                        <Brush
                            ref={this.saveInteractiveNode(1)}
                            enabled={brushEnabled}
                            type={BRUSH_TYPE}
                            onBrush={this.handleBrush1}/>
                        <ClickCallback
                            onMouseMove={ (moreProps, e) => {  } }
                            onMouseDown={ (moreProps, e) => {  } }
                            onClick={ (moreProps, e) => {  } }
                            onDoubleClick={ (moreProps, e) => {  } }
                            onContextMenu={ (moreProps, e) => {  } }
                            onPan={ (moreProps, e) => {  } }
                            onPanEnd={ (moreProps, e) => {  } }
                        />
                    </Chart>
                    <CrossHairCursor snapX={false} />
                </ChartCanvas>
                {
                    entityField && <ul className={classes.entityLists}>
                    {
                        checked.map((ch,i) => (
                            <li key={i}>
                                <span style={{backgroundColor: strokes[i]}}></span>{ch}
                            </li>
                        ))
                    }
                </ul>
                }
            </>
		);
	}
}

const useStyles = makeStyles((theme) => ({
    entityLists: {
        display: 'flex',
        flexFlow: 'wrap',
        padding: '0',
        marginTop: '10px',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            '& span': {
                display:'inline-block',
                width: '18px',
                height: '18px',
                margin: '5px 10px',
                boxShadow: '0 0 6px -1px #9a9696',
                borderRadius: '2px'
            }
        }
    }
}));

function NonMarektChart(props){
    const classes = useStyles();
    return (
        <NonMarektChartComponent classes={classes} {...props} />
    )
}
NonMarektChart.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
    xAxis: PropTypes.string.isRequired,
    yAxis: PropTypes.string.isRequired,
    chartType: PropTypes.string.isRequired
};

NonMarektChart.defaultProps = {
	type: "svg",
};
NonMarektChart = fitWidth(NonMarektChart);

const mapStateToProps = (state) => ({
    chartData: state.chartData
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(NonMarektChart);
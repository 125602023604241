import {createSlice,createAsyncThunk,createEntityAdapter} from '@reduxjs/toolkit';
import { paramsToQueryParam } from 'store/UrlUtils';
import API from 'store/services/AxiosService';
import {
  EOD_DELETE_DATATABLE_FILTERS,
  EOD_FETCH_DATATABLE_FILTERS,
  EOD_FETCH_SUMMARY_DATASET,
  EOD_FETCH_SUMMARY_DATA_TABLE,
  EOD_SAVE_DATATABLE_FILTERS
} from 'store/UrlConstants';



const initialState = {
  loadingInProgress: false,
  dataTableFilters: []
};

export const fetchSummaryDataSet = createAsyncThunk('eod/summary/dataset', async () => {
  const response = await API.get(EOD_FETCH_SUMMARY_DATASET);
  return response.data;
});
export const fetchSummaryDataTable = createAsyncThunk('eod/summary/data_table', async (payload) => {
  let url = `${EOD_FETCH_SUMMARY_DATA_TABLE}/${payload.dataset_name}${paramsToQueryParam(payload.params)}`;
  const response = await API.get(EOD_FETCH_SUMMARY_DATA_TABLE);
  return response.data;
});

export const fetchEodDataDatatableFilters = createAsyncThunk('eod/datatable_filters/fetch', async (payload) => {
  let url = `${EOD_FETCH_SUMMARY_DATA_TABLE}/${payload.dataset_name}${paramsToQueryParam(payload.params)}`;
  const response = await API.get(url, EOD_FETCH_DATATABLE_FILTERS);
  return response.data;
});
export const saveEodDataDatatableFilters = createAsyncThunk('eod/datatable_filters/save', async (payload) => {
  const response = await API.post(EOD_SAVE_DATATABLE_FILTERS, payload);
  return response.data;
});
export const deleteEodDataDatatableFilters = createAsyncThunk('eod/datatable_filters/delete', async (payload) => {
  const response = await API.post(EOD_DELETE_DATATABLE_FILTERS, payload);
  return response.data;
});
export const searchSummaryDataTables = createAsyncThunk('eod/datatable_filters/search', async (payload) => {
  const { datasetName } = payload
  let url = `${EOD_FETCH_SUMMARY_DATA_TABLE}/${datasetName}/search`;
  const queries = []
  function addOptional(field, value){
      if(value) {queries.push(`${field}=${value}`)}
  }
  if(payload.offset !== undefined) queries.push('offset=' + payload.offset)
  if(payload.count) queries.push('count=' + payload.count)
  addOptional('sub_table', payload.subTable)
  addOptional('table_name', payload.tableName)
  addOptional('order_by_size', payload.sizeOrder)
  addOptional('order_by_columns', payload.columnsOrder)
  addOptional('order_by_rows', payload.rowsOrder)
  addOptional('order_by_last_read_rows', payload.lastReadRowsOrder)
  addOptional('order_by_last_updated', payload.updatedOrder)
  addOptional('order_by_load_time_in_seconds', payload.loadTimeInSecondsOrder)
  if(queries.length > 0){
      url += '?' + queries.join('&')
  }
  const { data } = await API.get(url)
  return JSON.parse(data)
})

const SummaryDataSlice = createSlice({
  name: 'summaryData',
  initialState,
  reducers: {
    setSummaryDataTableFilters: (state, action) => {
      const { column_name } = action.payload
      const datatableFilterIndex = state.dataTableFilters.findIndex((item => item.column_name == column_name));
      if(datatableFilterIndex > -1){
          state.dataTableFilters[datatableFilterIndex] = action.payload
      }else{
          state.dataTableFilters.push(action.payload)
      }
    }
  },
  extraReducers: {
    [fetchSummaryDataSet.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchSummaryDataSet.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchSummaryDataSet.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchSummaryDataTable.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchSummaryDataTable.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchSummaryDataTable.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchEodDataDatatableFilters.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchEodDataDatatableFilters.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchEodDataDatatableFilters.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [saveEodDataDatatableFilters.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [saveEodDataDatatableFilters.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [saveEodDataDatatableFilters.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [deleteEodDataDatatableFilters.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [deleteEodDataDatatableFilters.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [deleteEodDataDatatableFilters.rejected]: (state, action) => {
      state.loadingInProgress = false
    }
  },
});

export const prepareSelector = state => state.eodData;

export const { setSummaryDataTableFilters } = SummaryDataSlice.actions
export default SummaryDataSlice.reducer;

import React, {
  useEffect,
  useMemo, useState,
} from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  CircularProgress,
  IconButton
} from "@mui/material"
import { makeStyles } from "@mui/styles";
import {
  Grain as GridIcon,
  GraphicEq as GraphicEqIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from "@mui/icons-material"

import CustomTreeView from 'components/common/Base/CustomTreeView'
import { deleteUserFavorite, updateUserFavorite } from 'store/slices/UserSlice';
import CustomTextField from 'components/common/Base/CustomTextField';
import { resetAlertDialog, showAlertDialog } from 'store/slices/MessagesSystemSlice';
import EditIcon from 'components/icons/Edit'
import DeleteIcon from 'components/icons/Delete'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'absolute',
    width: '100%',
    '& .MuiTreeItem-content': {
      "&:hover": {
        backgroundColor: 'transparent'
      }
    },
    '& .MuiTreeItem-content.Mui-selected': {
      backgroundColor: 'transparent'
    },
    "& .MuiTreeItem-root[selected=true]": {
      backgroundColor: 'rgba(25, 118, 210, 0.08)'
    }
  },
  IconButton: {
    color: 'white',
    padding: 3,
    '& .MuiSvgIcon-root': {
      fontSize: 19
    }
  },
  TreeView: {
    height: '100%',
    '& .MuiTreeItem-group': {
      margin: 0,
    }
  },
  TagGroupParent: {
    '& > .MuiTreeItem-content': {
      padding: '10px 5px',
      '& .MuiTreeItem-label': {
        backgroundColor: 'transparent !important',
      },
      '& .MuiTreeItem-label:hover': {
        backgroundColor: 'transparent'
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
      },
    },
    '&.Mui-selected:not(.hasChildren) > .MuiTreeItem-content': {
      backgroundColor: '#2378c5',
      '& .MuiTreeItem-content .MuiTreeItem-label': {
        background: 'transparent'
      },
      '& .TagGroupItemTxt': {
        color: 'white'
      },
      '& .TagGroupItemIcon': {
        color: 'white'
      }
    }
  },
  TagGroupParentInner: {
    display: 'flex',
    width: '100%',
    '.MuiTreeItem-root &': {
      paddingLeft: '5px',
    }
  },
  TagGroupItem: {
    width: '100%',
    padding: '10px 0',
    '& .MuiTreeItem-label:hover': {
      backgroundColor: 'transparent'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '&.Mui-selected': {
      background: '#2378c5',
      '& .MuiTreeItem-content .MuiTreeItem-label': {
        background: 'transparent'
      },
      '& .TagGroupItemTxt': {
        color: 'white'
      },
      '& .TagGroupItemIcon': {
        color: 'white'
      }
    },
    '& .MuiTreeItem-iconContainer': {
      display: 'none'
    },
    '& .MuiTreeItem-label': {
      padding: 0
    }
  },

  TagGroupItemInner: {
    display: 'flex',
    width: '100%',
    '.MuiTreeItem-root &': {
      paddingLeft: '25px',
    }
  },
  TagGroupHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '13px 4px 12px 12px',
    borderBottom: 'solid 1px #bebfbf',
  },
  TagItem: {
    paddingBottom: '20px',
    borderBottom: 'solid 1px #c4c4c4',
    marginLeft: '34px',
    paddingTop: '11px',
    display: 'flex',
    // alignItems: 'center',
    paddingRight: 15
  },
  TagItemTxt: {
    color: 'black',
    flex: 1
  },
  TagItemIconButton: {
    color: 'white',
    padding: 5,
    '& .MuiSvgIcon-root': {
      fontSize: 17,
    }
  },
  DialogTitleTxt: {
    flex: 1,
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    fontFeatureSettings: `'salt' on`,
    color: '#374151',
  },
  DialogContent: {
    width: 512
  },
  TextField: {
    backgroundColor: '#F3F4F6',
    border: 'none'
  },
  SaveBtn: {
    color: 'white',
    backgroundColor: '#6790e3',
    padding: '6px 15px',
    "&:hover": {
      backgroundColor: '#C3D6FD'
    }
  }
}));

export default function FavDisplay(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()

  const isFavsLoading = useSelector(state => state.user.favsLoading)
  const favs = useSelector(state => state.user.favs) || {}
  const { favGroups } = props
  // console.log(favs, 'favs')

  const [parentFolderId, setSelectedParentFolderId] = useState(null)
  const [subFolderId, setSelectedSubFolderId] = useState(null)
  const [openEditGroup, setOpenEditGroup] = useState(false)
  const [groupName, setGroupName] = useState(null)

  useEffect(() => {
    if (subFolderId) {
      setGroupName(subFolderId)
    } else if (parentFolderId) {
      setGroupName(parentFolderId)
    }
  }, [parentFolderId, subFolderId])

  const previousGroupName = useMemo(() => {
    if (parentFolderId) {
      if (subFolderId) {
        return `${parentFolderId}/${subFolderId}`
      }
      return parentFolderId
    }
    return ''
  }, [
    parentFolderId,
    subFolderId,
  ])
  const newGroupName = useMemo(() => {
    if (parentFolderId) {
      if (subFolderId) {
        return `${parentFolderId}/${groupName}`
      }
      return groupName
    }
  }, [
    parentFolderId,
    subFolderId,
    groupName,
  ])

  const saveFav = async () => {
    const keysOfGroupToChange = Object.keys(favs).filter(item => item.startsWith(previousGroupName))
    try {
      for (let key of keysOfGroupToChange) {
        const groupToChange = favs[key]
        const _newGroupName = key.replace(previousGroupName, newGroupName)
        await dispatch(updateUserFavorite({ groupToChange, name: _newGroupName }))
      }
      setOpenEditGroup(false)
    } catch (e) {
      setOpenEditGroup(false)
    }
  }
  const handleClose = () => {
    setOpenEditGroup(false)
    if (subFolderId) {
      setGroupName(subFolderId)
    } else if (parentFolderId) {
      setGroupName(parentFolderId)
    }
    setSelectedSubFolderId(null)
    setSelectedParentFolderId(null)
  }

  const onClickTagGroup = ({ parentFolderId, subFolderId }) => {
    // setOpenTagPopover(true)
    // setTagAnchorEl(e.currentTarget)
    props.changeStep2Index(1, parentFolderId, subFolderId)
  }

  const alertDialog = useSelector(state => state.messagesSystem.alertDialog)
  useEffect(() => {
    const { dialogAction, clickedButton, data } = alertDialog;
    if (dialogAction === "deleteFav" && clickedButton === "alertDialogButtonLeft") {
      if (data.flag === 'group') {
        const keysOfGroupToChange = Object.keys(favs).filter(item => item.startsWith(previousGroupName))
        for (let key of keysOfGroupToChange) {
          const groupToDelete = favs[key]
          dispatch(deleteUserFavorite(groupToDelete)).then(r => {
            console.log(r, 'r')
          })
        }
      }
    }
    dispatch(resetAlertDialog());
  }, [alertDialog.clickedButton]);

  const TagGroupFolder = (_props) => {
    return <Box className={classes.TagGroupParentInner} onClick={() => {
      if (!_props.hasChildren) onClickTagGroup({ parentFolderId: _props.id })
    }}>
      <Box flex={1} display="flex" sx={{ alignItems: 'center' }}>
        <Box padding={'0'} mr={1}>
          {/* {
            _props.hasChildren ? (
              <GridIcon className='TagGroupItemIcon' />
            ) : (
              <GraphicEqIcon className='TagGroupItemIcon' />
            )
          } */}
        </Box>
        <Typography className='TagGroupItemTxt'>{_props.label}</Typography>
      </Box>
      <Box variant="div">
        <IconButton
          className={classes.IconButton}
          onClick={(e) => {
            e.stopPropagation()
            setOpenEditGroup(true)
            setSelectedParentFolderId(_props.id)
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          className={classes.IconButton}
          onClick={(e) => {
            e.stopPropagation()
            setSelectedParentFolderId(_props.id)
            dispatch(showAlertDialog({
              title: "Confirmation",
              message: "Are you sure you want to delete?",
              buttonLabel: { left: "Ok", right: "Cancel" },
              dialogAction: "deleteFav",
              data: {
                flag: 'group'
              }
            }));
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  }

  const TagSubFolder = (_props) => {
    return <Box
      className={classes.TagGroupItemInner}
      onClick={() => onClickTagGroup({
        parentFolderId: _props.parentFolderId,
        subFolderId: _props.subFolderId
      })}>
      <Box flex="1" display="flex" sx={{ alignItems: 'center' }}>
        {/* <Box padding={'0 10px'}>
          <GraphicEqIcon className='TagGroupItemIcon' />
        </Box> */}
        <Typography className='TagGroupItemTxt'>{_props.label}</Typography>
      </Box>
      <Box variant="div">
        <IconButton
          className={classes.IconButton}
          onClick={(e) => {
            e.stopPropagation()
            setOpenEditGroup(true)
            setSelectedParentFolderId(_props.parentFolderId)
            setSelectedSubFolderId(_props.subFolderId)
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          className={classes.IconButton}
          onClick={(e) => {
            e.stopPropagation()
            setSelectedParentFolderId(_props.parentFolderId)
            setSelectedSubFolderId(_props.subFolderId)
            dispatch(showAlertDialog({
              title: "Confirmation",
              message: "Are you sure you want to delete?",
              buttonLabel: { left: "Ok", right: "Cancel" },
              dialogAction: "deleteFav",
              data: {
                flag: 'group'
              }
            }));
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  }

  const formattedFavs = useMemo(() => {
    const _treeTemplates = []
    if (Object.keys(favGroups)) {
      for (let groupId in favGroups) {
        const _item = favGroups[groupId]
        const itemTemplate = {
          id: _item.id,
          label: <TagGroupFolder
            id={_item.id}
            label={_item.label}
            hasChildren={_item.children}
          />,
          className: `${classes.TagGroupParent}`,
          children: []
        }
        if (Array.isArray(_item.children)) {
          // console.log(_item, 'item')
          // if (_item.favs) {
          //   itemTemplate.children.push({
          //     id: _item.id,
          //     label: 'hi',
          //     className: classes.TagGroupItem
          //   })
          // }
          if (_item.children) {
            const _defaultItemTemplate = { ...itemTemplate }
            delete _defaultItemTemplate['children']
            _defaultItemTemplate.label = <TagGroupFolder
              id={_item.id}
              label={_item.label}
            />
            _treeTemplates.push(_defaultItemTemplate)
          }
          _item.children.map(s => {
            itemTemplate.children.push({
              id: s.id,
              label: <TagSubFolder
                label={s.label}
                parentFolderId={_item.id}
                subFolderId={s.id}
                isDefault={_item.isDefault || false}
              />,
              className: classes.TagGroupItem
            })
          })
        }
        _treeTemplates.push(itemTemplate)
      }
    }
    return _treeTemplates
  }, [favGroups])

  return (
    <Grid item lg={12} md={12} xs={12} className={classes.root}>
      <Box>
        {
          isFavsLoading ? (
            <>
              <Box sx={{ paddingLeft: 15 }}>Loading...</Box>
            </>
          ) : (
            // <CustomAccordion
            //   items={formattedFavGroups}
            // />
            <CustomTreeView
              data={formattedFavs}
              className={classes.TreeView}
            />
          )
        }
      </Box>
      <Dialog
        open={openEditGroup}
        maxWidth={'md'}
      >
        <DialogTitle>
          <Box
            display="flex"
            sx={{ alignItems: 'center' }}
            variant="div"
          >
            <Typography className={classes.DialogTitleTxt}>Bookmark</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              <CustomTextField
                variant="outlined"
                label=""
                value={groupName}
                className={classes.TextField}
                onChange={(e) => {
                  setGroupName(e.target.value)
                }}
                margin="dense"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='outlined'
          >
            Cancel
          </Button>
          <Button
            className={classes.SaveBtn}
            disabled={isFavsLoading}
            onClick={saveFav}
            startIcon={isFavsLoading ? (<CircularProgress />) : <FilterListIcon />}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}

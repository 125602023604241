import React, { useState } from 'react';
import RightSideDrawer from "./RightSideDrawer"
import { makeStyles } from "@mui/styles";
import { Box, Button, Divider, FormControlLabel, IconButton, List, ListItemButton, ListItemText, ListSubheader, Popover, TextField } from '@mui/material';
import { Feed, MoreVert, Search } from '@mui/icons-material';
import CustomFormSelect from 'components/common/Base/CustomFormSelect';
import CustomFormInput from 'components/common/Base/CustomFormInput';
import Checkbox from '@mui/material/Checkbox';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const checkboxLabel = { inputProps: { 'aria-label': 'Checkbox demo' } };


const useStyles = makeStyles((theme) => ({
    DrawerHeader: {
        padding: '23px 42px',
        paddingLeft: '70px',
        position: 'relative',
    },
    DrawerHeaderAction: {
        position: 'absolute',
        top: '16px',
        right: '66px',
        padding: '2px',
    },

    DrawerHeaderTitle: {
        position: 'relative',
        bottom: '2px',
        zIndex: 0,
    },

    DrawerHeaderTitle: {
        '& *': {
            border: 'none',
            boxShadow: 'none',
        }
    },

    DrawerSubTitle: {
        lineHeight: '140%',
        margin: '0px',
        fontSize: '12px',
        letterSpacing: '0.005em',
        fontWeight: '600',
        color: 'rgb(170, 178, 189)',
    },

    DrawerHeaderTitle: {
        '& input': {
            width: '100%',
            outlineWidth: '0px',
            borderWidth: '0px',
            margin: '0px',
            padding: '0px 20px',
            paddingLeft: 0,
            fontSize: '16px',
            position: 'absolute',
            inset: '0px',
            fontWeight: '600',
            backgroundColor: 'transparent',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            paddingLeft: 0,
        },
        '& fieldset': {
            border: 'none'
        }
    },

    DrawerHeaderTitle: {
        "& input: focus": {
            border: 'none',
            boxShadow: 'none',
        }
    },

    DrawerSubHeader: {
        lineHeight: '140%',
        margin: '0px',
        fontSize: '12px',
        letterSpacing: '0005em',
        fontWeight: '600',
        color: 'rgb(170, 178, 189)',
    },

    Body: {
        padding: '20px 25px',
        paddingLeft: '70px',
    },

    DrawerFormField: {
        display: 'inline-block',
        marginBottom: '10px',
        width: '100%'
    },

    DrawerFormFieldLabel: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        fontWeight: 600,
        color: 'rgb(35, 38, 40)',
        fontSize: '14px',
        /* right: '32px', */
        '& svg': {
            width: '21px',
            marginRight: '13px',
        }
    },
    'DrawerFormFieldLabel-LogoIcon': {
        position: 'absolute',
        left: '-45px',
    },
    InputField: {
        // width: 'auto',
        '& select': {
            fontSize: '12px',
            // width: '170px',
            padding: '6px',
            marginBottom: 0
        },
        '& input': {
            fontSize: '12px',
            // width: '182px',
            padding: '6px',
            marginBottom: 0
        }
    },
    ProductList: {
        maxHeight: '300px',
        overflow: 'auto'
    }
}))
const SchedulePicker = ({ }) => {
    return <>
        <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
                label="Schedule At"
                // inputFormat="yyyy"
                // value={periodValue?.from || null}
                views={['year']}
                onChange={(newVal) => {
                    // setPeriodValue({
                    //     ...periodValue, from: newVal
                    // })
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    </>
}
const AnalyticForm = ({
    isOpen,
    onClose,
    title
}) => {
    const styles = useStyles()
    const [hasUpdated, setHasUpdated] = useState(false)
    const [analyticData, setAnalyticData] = useState({})
    const [isSearched, setIsSearched] = useState(false)
    const [isScheduled, setIsScheduled] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const handleMoreVertClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const openPop = Boolean(anchorEl);

    return <RightSideDrawer isOpen={isOpen} onClose={() => {
        setIsSearched(false)
        onClose(hasUpdated, analyticData)
    }}>
        <div className={styles.DrawerHeader}>
            <span className={styles.DrawerSubTitle}>
                Quality Analytic
            </span>
            <div className={styles.DrawerHeaderTitle}>
                <TextField
                    fullWidth
                    // value={analyticData.name}
                    value={title}
                    onKeyDown={e => e.stopPropagation()}
                    onChange={(e) => {
                        e.stopPropagation()
                        // onChangePropertyData(e)
                    }}
                    onFocus={(e) => e.stopPropagation()}
                />
            </div>
            {/* <div className={styles.DrawerSubHeader}></div>
          <div>{ selectedEventPropertyTitle }</div> */}
        </div>
        <Divider />

        <div className={styles.Body}>
            <Box className={styles.DrawerFormField}>
                <span className={styles.DrawerFormFieldLabel}>
                    <Feed className={styles['DrawerFormFieldLabel-LogoIcon']} />
                    <span className={styles.DrawerLabel}>Feed Source</span>
                </span>
                <CustomFormSelect
                    options={[]}
                    // value={''}
                    fieldName="feedSource"
                    className={styles.InputField}
                />
            </Box>
            <Box className={styles.DrawerFormField}>
                <span className={styles.DrawerFormFieldLabel}>
                    {/* <Feed className={styles['DrawerFormFieldLabel-LogoIcon']} /> */}
                    <span className={styles.DrawerLabel}>Database Code</span>
                </span>
                <CustomFormSelect
                    options={[]}
                    // value={''}
                    fieldName="feedSource"
                    className={styles.InputField}
                />
            </Box>
            <Box className={styles.DrawerFormField}>
                <span className={styles.DrawerFormFieldLabel}>
                    {/* <Feed className={styles['DrawerFormFieldLabel-LogoIcon']} /> */}
                    <span className={styles.DrawerLabel}>Product Code</span>
                </span>
                <CustomFormInput
                    placeholder="Product code..."
                    fieldName="productCode"
                    className={styles.InputField}
                />
            </Box>
            <Box className="d-flex">
                <Button
                    variant="contained"
                    className='ml-auto'
                    onClick={() => {
                        setIsSearched(true)
                    }}
                >
                    <Search />
                    <span>Search</span>
                </Button>
            </Box>
            {
                isSearched &&
                <Box>
                    <List component="nav">
                        <ListSubheader>
                            <ListItemText primary="Total 200 products" />
                        </ListSubheader>
                    </List>
                    <Divider />
                    <List component="nav" className={styles.ProductList}>
                        <ListItemButton
                        // selected={selectedIndex === 2}
                        // onClick={(event) => handleListItemClick(event, 2)}
                        >
                            <ListItemText primary="AAMC" />
                        </ListItemButton>
                        <ListItemButton
                        // selected={selectedIndex === 3}
                        // onClick={(event) => handleListItemClick(event, 3)}
                        >
                            <ListItemText primary="AAU" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="ACAQ1" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="ACAQ2" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="ACAQ3" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="ACAQ4" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="ACAQ5" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemText primary="ACAQ6" />
                        </ListItemButton>
                    </List>
                    <Divider />
                    <FormControlLabel control={<Checkbox />} label="Do Schedule" onChange={(e) => {
                        console.log(e.target.checked)
                        setIsScheduled(e.target.checked)
                    }} />
                    {
                        isScheduled && (
                            <Box>
                                <SchedulePicker />
                            </Box>
                        )
                    }
                    <Box className="mt-2 d-flex align-items-center">
                        <Button
                            variant="contained"
                        >{
                                isScheduled ? 'Schedule QA' : "Start Analytic"
                            }</Button>
                        <IconButton onClick={(e) => handleMoreVertClick(e)}>
                            <MoreVert />
                        </IconButton>
                    </Box>

                    <Popover
                        open={openPop}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <List component="nav">
                            {
                                isScheduled ? (
                                    <ListItemButton>
                                        <ListItemText primary="Create Another Schedule" />
                                    </ListItemButton>
                                ) : (
                                    <ListItemButton>
                                        <ListItemText primary="Create Another QA Process" />
                                    </ListItemButton>
                                )
                            }
                        </List>
                    </Popover>
                </Box>
            }
        </div>
    </RightSideDrawer>
}

export default AnalyticForm;
import React, {useState} from "react";

import {makeStyles} from "@mui/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 0px !important'
    },
    PanelHeader: {
        display: 'flex',
        alignItems: 'center',
        // backgroundColor: '#d7e5f3',
        color: '#4a5568',
        padding: '0.25rem',
        cursor: 'pointer',
        transition: 'all ease-in .3s',
        '& :hover': {
            // backgroundColor: '#cfe0f1',
        }
    },
    PanelHeaderLabel: {
        fontSize: '15px',
        flex: 1
    },
    PanelHeaderIconButton: {
        border: 'none',
        background: 'none',
        display: 'flex',
        '-webkit-box-align': 'center',
        alignItems: 'center',
        outline: 'none',
        padding: '4px',
        borderRadius: '3px',
        cursor: 'pointer',
        '& svg': {
            width: '17px',
            color: '#4a5467',
            transition: 'all ease-in .3s',
        },
        '&:hover svg': {
            transform: 'scale(1.2)',
        } 
    },
    PanelBody: {
        transition: 'all ease-in .3s',
        '&.collapsed': {
            display: 'none'
        },
        padding: '2px 9px',
        paddingLeft: '26px'
    }
}))

function ExpandablePanelBox(props) {
    const { panel, className, isCollapsable, headerIcon1, headerIcon2 } = props
    const classes = useStyles()
    const [collapsed, setCollapsed] = React.useState(props.collapsed || false)
    return (
        <>
            <div className={classes.root + ` ${className}`}>
                <div className={classes.PanelHeader} onClick={() => {
                    if(!isCollapsable) return false;
                    setCollapsed(!collapsed)
                }}>
                    {
                        isCollapsable && 
                        <button className={classes.PanelHeaderIconButton}>
                            { collapsed ? (headerIcon1 ? headerIcon1 : <KeyboardArrowRightIcon />) : (headerIcon2 ? headerIcon2 :  <KeyboardArrowDownIcon/>)}
                        </button> 
                    }
                    <div className={classes.PanelHeaderLabel}>{panel.label}</div>
                </div>
                <div className={classes.PanelBody + ` ${collapsed ? 'collapsed' : ''}`}>
                    {props.children}
                </div>
            </div>
        </>
    );
}

ExpandablePanelBox.defaultProps = {
  panel: {
      label: 'Default Panel Card'
  },
  className: '',
  isCollapsable: true,
  headerIcon1: null,
  headerIcon2: null,
}

export default ExpandablePanelBox
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from "underscore"

import { makeStyles } from "@mui/styles";
import {
    Box, CircularProgress, Tooltip
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchBQDataForTransform,
    fetchBeaMneProductData,
    fetchStockProductDataForTransform,
    initProductMetaData,
    setProductOpt
} from 'store/slices/StockProductSlice';
import NIPATableView from 'views/product-module/containers/Table/Transform/NIPA';
import { RefreshSharp, TrendingUp } from '@mui/icons-material';
import ChartGraphView from 'views/product-module/containers/Graph';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 22,
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        maxHeight: '100%',
        '& .MuiFormControlLabel-root': {
            margin: 0,
            textTransform: 'capitalize',
            marginBottom: 16,
            '& .MuiButtonBase-root': {
                padding: 4,
            }
        }
    },
    CircularProgress: {
        margin: '100px auto'
    },
    productStaticBarButton: {
        cursor: 'pointer',
        padding: '3px 3px',
        position: 'relative',
        transition: 'all ease-in .2s',
        borderRadius: '4px',
        margin: '0 6px',
        boxShadow: '0 0 1px 1px #e5e5f1',
        display: 'flex',
        flexFlow: 'column',
        color: '#4e4b4b',
        '&.selected': {
            boxShadow: '0 0 1px 1px #646467'
        },
        '&:hover': {
            background: '#ececec'
        },
        '&.selectedViewMode': {
            background: '#3c54db',
            color: 'white',
            '&:hover': {
                background: '#5e72e4'
            },
        },
        '& svg': {
            display: 'block',
            margin: '0 auto',
            fontSize: '35px'
        },
        // '&::after': {
        //     content: '""',
        //     position: 'absolute',
        //     height: '1px',
        //     borderTop: 'solid 1px #e2dcdc',
        //     bottom: '-6px',
        //     width: 'calc(100% - 6px)'
        // },
        // '&:last-child': {
        //     '&::after': {
        //         content: 'initial'
        //     }
        // }
    },
}))

function VisualizationStep(props) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { selectedInvestment, selectedSeries, selectedClassification, selectedDirection, onChange } = props
    // const classifications = useSelector(state => state.productData.beaMneClassifications)
    const productData = useSelector(state => state.productData)
    const chartLibrary = useSelector(state => state.chartData.chartLibrary)

    const [openModifyDialog, setOpenModifyDialog] = useState(false)
    const {
        productName: productCode,
        loadedProductData: loadedStocks,
        selectedLines,
        selectedYears,
        selectedRows,
        selectedColumns,
        selectedFrequencies,
        selectedAreas,
        viewMode,
        timeRange,
        loadingBeaMne
    } = productData

    const [selectedDataOpt, setSelectedDataOpt] = useState('cloud-storage')
    const [isLoading, setLoading] = useState(false)
    const feedSource = 'BEA'
    const databaseCode = 'MNE'

    const identifier = useMemo(() => {
        let _identifier = `${selectedDataOpt === 'big-query' ? 'bg/' : ''}BEA/MNE/${productCode}`
        return _identifier
    }, [selectedDataOpt, productCode])

    useEffect(() => {
        dispatch(initProductMetaData())
    }, [])
    useEffect(() => {
        dispatch(fetchBeaMneProductData({
            investment: selectedInvestment,
            series: selectedSeries,
            classification: selectedClassification,
            direction: selectedDirection
        }))
    }, [
        selectedInvestment,
        fetchBeaMneProductData,
        selectedSeries,
        selectedClassification,
        selectedDirection
    ])

    const clickBlobHandler = (blob) => {
        setSelectedDataOpt(blob)
    }


    const executeDataTransformLogic = useCallback(async () => {
        let isValid = true
        const loadedData = loadedStocks[identifier]
        if (!isValid) return
        if (selectedFrequencies?.length > 0 && isValid) {
            setLoading(true)
            if (!isValid) return
            const labelColumn = 'column'
            const hierarchyColumn = 'column'
            const dateField = 'year';
            const valueField = 'datavalueunformatted'
            if (selectedDataOpt === 'cloud-storage') {
                await dispatch(fetchStockProductDataForTransform({
                    feedSource,
                    productCode,
                    databaseCode,
                    selectedFrequencies: selectedFrequencies,
                    selectedYears: selectedYears,
                    selectedLines: selectedLines,
                    selectedAreas: selectedAreas,
                    selectedSeries: selectedSeries,
                    selectedRows: selectedRows,
                    selectedColumns: selectedColumns,
                    dateField,
                    hierarchyColumn,
                    labelColumn,
                    valueField
                }))
            } else {
                await dispatch(fetchBQDataForTransform({
                    feedSource,
                    productCode,
                    databaseCode,
                    selectedFrequencies: selectedFrequencies,
                    selectedYears: selectedYears,
                    selectedLines: selectedLines,
                    selectedAreas: selectedAreas,
                    selectedSeries: selectedSeries,
                    selectedRows: selectedRows,
                    selectedColumns: selectedColumns,
                    dateField,
                    hierarchyColumn,
                    labelColumn,
                    hasYears: loadedData && loadedData.years ? true : false,
                    valueField
                }))
            }
            setLoading(false)
        }
    }, [
        selectedFrequencies,
        selectedYears,
        selectedLines,
        selectedSeries,
        selectedRows,
        selectedColumns,
        loadedStocks,
        identifier,
        selectedDataOpt
    ])

    useEffect(() => {
        console.log(selectedDataOpt, 'selectedDataOpt')
        console.log(selectedFrequencies, 'selectedFrequencies')
        if (selectedDataOpt && selectedFrequencies.length > 0) {
            executeDataTransformLogic()
        }
    }, [
        selectedFrequencies,
        selectedLines,
        selectedYears,
        selectedSeries,
        selectedRows,
        selectedColumns,
        selectedAreas,
        selectedDataOpt
    ])

    const filteredData = loadedStocks[identifier] || []
    return <>
        <Box className={classes.root}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }} id="QaBlobHandler">
                <Tooltip title="Modify">
                    <Box
                        sx={{ padding: '10px !important' }}
                        mr={1}
                        className={`${classes.productStaticBarButton}`}
                        onClick={() => {
                            setOpenModifyDialog(true)
                        }}
                    >
                        <RefreshSharp style={{ fontSize: '22px' }} />
                    </Box>
                </Tooltip>
                <Tooltip title="Cloud Storage">
                    <Box
                        sx={{ padding: '10px !important' }}
                        className={`${classes.productStaticBarButton} ${selectedDataOpt === 'cloud-storage' ? 'selected' : ''}`}
                        mr={1}
                        onClick={() => clickBlobHandler('cloud-storage')}
                        id="cloud-storage-data-opt"
                    >CS</Box>
                </Tooltip>
                <Tooltip title="Big Query">
                    <Box
                        sx={{ padding: '10px !important' }}
                        className={`${classes.productStaticBarButton} ${selectedDataOpt === 'big-query' ? 'selected' : ''}`}
                        onClick={() => clickBlobHandler('big-query')}
                        id="big-query-data-opt"
                    >BG</Box>
                </Tooltip>
                <Box onClick={() => {
                    dispatch(setProductOpt({
                        path: 'viewMode',
                        value: viewMode === 'chart' ? 'datatable' : 'chart'
                    }))
                }}>
                    <a href="#"
                        className={`${classes.productStaticBarButton} ${viewMode === 'chart' && 'selectedViewMode'}`}>
                        <TrendingUp />
                        <span></span>
                    </a>
                </Box>
            </Box>
            <Box className={'mt-2'} style={{ flex: 1, overflow: 'auto' }}>
                {
                    isLoading || loadingBeaMne ? (
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress className={classes.CircularProgress} color="secondary" />
                        </Box>
                    ) : (
                        viewMode == 'chart' ? (
                            <ChartGraphView
                                feedSource={feedSource}
                                databaseCode={databaseCode}
                                productCode={productCode}
                                feedSourceType={'timeseries'}
                                data={filteredData}
                                chartLibrary={chartLibrary}
                                timeRange={timeRange}
                                identifier={identifier}
                                selectedDataOpt={selectedDataOpt}
                            />
                        ) : (
                            <NIPATableView
                                feedSource={feedSource}
                                databaseCode={databaseCode}
                                productCode={productCode}
                                blob={selectedDataOpt}
                                openModifyDialog={openModifyDialog}
                                handleMidifyDialog={(value) => {
                                    setOpenModifyDialog(value)
                                }}
                                identifier={identifier}
                            />
                        )
                    )
                }
            </Box>
        </Box>
    </>
}

export default VisualizationStep
import React, { useMemo } from "react";

import 'highcharts/css/stocktools/gui.scss'
import 'highcharts/css/annotations/popup.scss'

import moment from 'moment'
import _ from 'underscore'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import Highcharts from 'highcharts/highcharts'
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import ReactHighcharts from 'highcharts-react-official'
import { Box } from "@mui/system";
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

function LineChart(props) {
    const {
      data: initialData,
      xAxis,
      yAxis,
    } = props
    
    const series = useMemo(() => {
      const _initialSeries = []
      const _yAxis = Array.isArray(yAxis) && yAxis[0] !== undefined ? yAxis[0] : yAxis
      
      console.log(_yAxis, yAxis, '_yAxis')
      initialData.sort((a, b) => {
        if(a[xAxis] && b[xAxis]){
            return new Date(a[xAxis]).getTime() - new Date(b[xAxis]).getTime()
        }
        return 1;
      })
      .map(item => {
        let _x = new Date(item[xAxis]).getTime()
        if(!isNaN(item[_yAxis] * 1) && item[_yAxis] !== null){
          _initialSeries.push({
            x: _x,
            y: isNaN(item[_yAxis] * 1) ? null : item[_yAxis] * 1,
          })
        }
      })
      console.log(_yAxis, xAxis, _initialSeries, '_initialSeries')
      return [{
        data: _initialSeries
      }]
    }, [
      initialData,
      yAxis,
      xAxis,
    ])
    const configStock = useMemo(() => {
      return {
        chart: {
          type: 'line',
          zoomType: 'x',
        },
        title: {
          text: ''
        },
        xAxis: {
          crosshair: true,
          type: "datetime",
          labels: {
            formatter: function() {
              return Highcharts.dateFormat('%b/%e/%Y', this.value);
            }
          } 
        },
        yAxis: {
          min: 0,
          title: {
            text: yAxis ? yAxis.split('_').join(" ") : ''
          },
        },
        plotOptions: {
          line: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: series
      }
    }, [series, yAxis])
    
    return (
      <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterDateFns}>
        <Box display="flex" sx={{ flexDirection: 'column', height: '100%' }}>
          <Box flex={1}>
            <ReactHighcharts
              highcharts={Highcharts}
              constructorType={"chart"}
              options={configStock}
              containerProps={{ style: { height: "100%" } }}
            />
          </Box>
        </Box>
      </LocalizationProvider>
    )
}

export default LineChart
import React, { useState, useMemo } from 'react';
import { makeStyles } from "@mui/styles";

import {
  Box,
  Grid,
  Tab
} from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useDispatch, useSelector} from "react-redux";
import {BasicCustomizePanel} from "../details/customize/BasicCustomizePanel";
import {AdvancedCustomizePanel} from "./AdvancedCustomizePanel";

const useStyles = makeStyles(() => ({
  root: {
    padding: 10
  },
  TabPanel: {
    padding: 0,
    paddingTop: 10,
  }
}));

export const CustomizePanel = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item lg={12} md={12} xs={12}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Basic" value="1" />
                <Tab label="Advanced" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1" className={classes.TabPanel}>
              <BasicCustomizePanel />
            </TabPanel>
            <TabPanel value="2" className={classes.TabPanel}>
              <AdvancedCustomizePanel />
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  )
};

import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit';
import axios from 'store/services/AxiosService';
import {LOGIN, LOGOUT, EMP_LOGGED} from 'store/UrlConstants';

const initialState = {
};

export const testCall = createAsyncThunk('authentication/testcall', async () => {
  console.log('yes')
  const response = await axios.post('TestCall', {});

  return response.data;
});
export const login = createAsyncThunk('authentication/login', async ({ user, pass, history }, thunkAPI) => {
  const response = await axios.post(LOGIN, { Username: user, Password: pass });

  return response.data;
});

export const logout = createAsyncThunk('authentication/logout', async () => {
  const response = await axios.get(LOGOUT);
  return response.data;
});

export const fetchLoggedUserData = createAsyncThunk("authentication/loggedUserData", async () => {
  const response = await axios.get(EMP_LOGGED);
  return response.data;
});

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    bootstrapLogin(state, action) {
      state.status = 'loggedIn';
    },
    setSessionExpired(state, action) {
      state.expiredSession = action.payload;
    },
    setRefreshingToken(state, action) {
      state.refreshingToken = action.payload;
    },
    setReloadLocale(state, action) {
      state.reloadLocale = action.payload;
    },
    manualLogout(state, action) {
      state.status = 'loggedOut';
    }
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.status = 'loggedIn';
    },
    [login.pending]: (state, action) => {
      state.status = 'loading';
    },
    [login.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [logout.fulfilled]: (state, action) => {
      state.status = 'loggedOut';
    },
    [logout.rejected]: (state, action) => {
      state.status = 'loggedOut';
    },
    [fetchLoggedUserData.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchLoggedUserData.fulfilled]: (state, action) => {
      state.loggedUserData = action.payload;
      state.loadInProgress = false;
    }
  },
});

export const getLoggedUserId = createSelector(state => state.authentication.loggedUserData, loggedUserData => loggedUserData.employeId)

export const { bootstrapLogin, setSessionExpired, setReloadLocale, setRefreshingToken, manualLogout } = authenticationSlice.actions;

export default authenticationSlice.reducer;
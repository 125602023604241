import moment from 'moment';


/**
 * @param date
 * @returns 2020-06-01
 */
export function parseDateForNoteFeed(date: string | Date | undefined) {
  if(date && moment(date).isValid()) {
    return moment(date).format('DD MMMM YYYY');
  }
}

/**
 * @param date
 * @returns 2020-06-01
 */
export function parseDateToLocalFormat(date: string | Date | undefined) {
  if(date && moment(date).isValid()) {
    return moment(date).format('MM/DD/YYYY');
  }
}

/**
 * @param date
 * @returns "Sunday, February 14th 2010, 3:25:50 pm"
 */
export function parseDateAndTimeLocal(date: string | Date | undefined) {
  if(date && moment(date).isValid()) {
    return moment(date).format('dddd, MMMM Do YYYY, h:mm:ss a');
  }
}

/**
 * @param date
 * @returns "04/09/1986 8:30:00 PM"
 */
export function parseDateLocalizedHoursAndSeconds(date: string | Date | undefined) {
  if(date && moment(date).isValid()) {
    return moment(date).format('DD/MM/YYYY LTS');
  }
}

/**
 * @param date
 * @returns "04/09/1986 8:30:00 PM"
 */
export function parseDateLocalizedHours(date: string | Date | undefined) {
  if(date && moment(date).isValid()) {
    return moment(date).format('DD/MM/YYYY LT');
  }
}

export function timeAgo(date: Date) {
  const now = new Date();
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    if(interval > 1){
      return `${interval} ${ interval > 1 ? 'years' : 'year' } ago`;
    }
  }
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
      return `${interval} ${ interval > 1 ? 'months' : 'month' } ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return `${interval} ${ interval > 1 ? 'days' : 'day' } ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return `${interval} ${ interval > 1 ? 'hours' : 'hour' } ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return `${interval} ${ interval > 1 ? 'minutes' : 'minute' } ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
}
import React from 'react';

class CategoryOptions extends React.Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <div className='step2SidebarHeader'>Categories</div>
                <div className='step2SidebarBody'>
                    <ul className='nav' style={{margin: '0'}}>
                        <li>
                            <a href='#'>Covid-19</a>
                        </li>
                    </ul>
                </div>
            </>
        );
    }
}

export default CategoryOptions;
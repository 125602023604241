import React, { useMemo, useState } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogContent,
    Grid,
    Button,
    Box,
} from "@mui/material";
import CustomFormInput from 'components/common/Base/CustomFormInput';
import LoadingOverlay from 'components/common/LoadingOverlay';
import CustomFormSelect from 'components/common/Base/CustomFormSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        // padding: 0,
        position: 'relative',
        minHeight: '100px',
        display: 'flex',
        flexFlow: 'column'
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    },
    SelectField: {
        '& select': {
            height: "34px !important"
        }
    },
    LoadingOverlay: {
        '& > div': {
            zIndex: 1000
        }
    },
}));

const StartQADialog = (props) => {
    const {
        open,
        handleClose,
        totalProducts = 0,
        loading,
        onClickSave = () => {}
    } = props
    const classes = useStyles();
    const [runType, setRunType] = useState('automatic')
    const [percentage, setPercentage] = useState(10);
    const [maximumSeconds, setMaximumSeconds] = useState(3000);
    const onClose = () => {
        if (loading) return;
        handleClose(false)
    }

    const rangeProducts = useMemo(() => {
        if(percentage > 0){
            return Math.ceil(totalProducts/100*percentage)
        }
        return 0
    }, [
        percentage,
        totalProducts
    ])

    // React.useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         // Perform any necessary actions before the page is unloaded
    //         // For example, you can show a confirmation dialog
    //         const confirmationMessage = 'Are you sure you want to leave this page?';
    //         event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    //         alert(confirmationMessage)
    //         return confirmationMessage; // Gecko, WebKit, Chrome <34
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []); // Empty dependency array ensures the effect runs only once, like componentDidMount


    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                className={classes.DialogContent}
                maxWidth="md"
            >
                <DialogContent>
                    <LoadingOverlay className={classes.LoadingOverlay} isLoading={loading}>
                        <Grid container spacing={1}>
                            <Grid item lg={12} md={12} xs={12} className={'mt-2'}>
                                <Box className={classes.root}>
                                    <Box sx={{
                                        background: '#f4f5f7',
                                        borderRadius: '4px',
                                        padding: '2px 11px',
                                        paddingBottom: '12px',
                                    }}>
                                        <div className="mt-1">
                                            <Box display="flex" sx={{ alignItems: 'center' }}>
                                                <div style={{ width: '100%' }} className='mr-2'>
                                                    <div>By Percent(%) { (rangeProducts ? ` - ${rangeProducts} products` : '') }</div>
                                                    <CustomFormInput
                                                        value={percentage}
                                                        onChange={(_field, _value) => {
                                                            console.log(_value, '_value')
                                                            setPercentage(_value)
                                                        }}
                                                    />
                                                </div>
                                            </Box>
                                            <CustomFormSelect
                                                size="small"
                                                options={[
                                                    {label: 'Automatic', value: 'automatic'},
                                                    { label: 'Manual', value: 'manual' }
                                                ]}
                                                className={classes.SelectField}
                                                label={'Run Type'}
                                                fieldName="runType"
                                                onChange={(field, value) => {
                                                    setRunType(value)
                                                }}
                                            />
                                            <Box display="flex" sx={{ alignItems: 'center', marginTop: '10px' }}>
                                                <div style={{ width: '100%' }} className='mr-2'>
                                                    <div>Maximum QA Duration (MS)</div>
                                                    <CustomFormInput
                                                        value={maximumSeconds}
                                                        onChange={(_field, _value) => {
                                                            setMaximumSeconds(_value)
                                                        }}
                                                    />
                                                </div>
                                            </Box>
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={12} md={12} xs={12}>
                                <Box display="flex" sx={{ justifyContent: 'flex-end' }}>
                                    <Button
                                        onClick={() => {
                                            onClickSave({
                                                rangePercentage: percentage,
                                                runType: runType,
                                                maximumSeconds
                                            })
                                        }}
                                        disabled={loading}
                                        variant="contained"
                                    >
                                        Save
                                    </Button>
                                    <Button onClick={onClose}>
                                        Close
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </LoadingOverlay>
                </DialogContent>
            </Dialog>
        </>
    );
};

StartQADialog.propTypes = {
};

export default StartQADialog
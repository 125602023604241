import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _ from "underscore"

import { makeStyles } from "@mui/styles";
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBeaMneDirections, fetchBeaMneInvestments, initProductMetaData } from 'store/slices/StockProductSlice';
import InvestmentStep from './steps/InvestmentStep';
import SeriesStep from './steps/SeriesStep';
import ClassificationStep from './steps/ClassificationStep';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { showSnackbarMessage } from 'store/slices/MessagesSystemSlice';
import VisualizationStep from './steps/VisualizationStep';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '65px 10px 20px',
        height: '100%',
        '& .progress-bar': {
            width: '100%',
            backgroundColor: '#f1f1f1',
            borderRadius: '4px',
            marginBottom: '10px',
            padding: '0 30px'
        },

        '& .progress-bar-item': {
            backgroundColor: '#4caf50',
            height: '20px',
            borderRadius: '4px',
            marginBottom: '5px',
            transition: 'width 0.3s ease',
            textAlign: 'end'
        }
    },
    ProductName: {
        color: 'black',
        textAlign: 'left'
    },
    FormRoot: {
        display: 'flex',
        padding: '30px',
        marginTop: '39px',
    },
    customProductInput: {
        width: '638px',
        '& input': {
            height: '42px',
        },
        "& label": {
            fontSize: '14px'
        }
    }
}))
const steps = ['Investment/Direction', 'Series', 'Classificatoins', 'Visualization'];


function BeaMne(props) {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [activeStep, setActiveStep] = React.useState(0);
    const isLoading = useSelector(state => state.productData.loadingBeaMne)
    // const [selectedInvestment, setSelectedInvestment] = useState(`Assets`)
    // const [selectedDirection, setSelectedDirection] = useState(`inward`)
    // const [selectedSeries, setSelectedSeries] = useState(`Debt Instruments Inflows - Transactions in debt instruments (previously called intercompany debt flows) are the result of changes in net outstanding loans between foreign parent companies and their U.S. affiliates, including loans by parents to affiliates and loans by affiliates to parents.`)
    // const [selectedClassification, setSelectedClassification] = useState(`CountryByIndustry`)

    const [selectedInvestment, setSelectedInvestment] = useState(null)
    const [selectedDirection, setSelectedDirection] = useState(null)
    const [selectedSeries, setSelectedSeries] = useState(null)
    const [selectedClassification, setSelectedClassification] = useState(null)

    useEffect(() => {
        dispatch(initProductMetaData())
        dispatch(fetchBeaMneInvestments())
        dispatch(fetchBeaMneDirections())
    }, [
    ])

    const handleNext = useCallback(() => {

        let inValid = false
        if (activeStep === 0) {
            if (!(selectedInvestment && selectedDirection)) {
                inValid = true
            }
            setSelectedSeries(null)
        } else if (activeStep === 1) {
            if (!selectedSeries) inValid = true
            setSelectedClassification(null)
        } else if (activeStep === 2) {
            if (!selectedClassification) inValid = true
            dispatch(initProductMetaData())
        }
        if (inValid) {
            dispatch(showSnackbarMessage({
                message: 'Please select the requried fields',
                severity: 'warning'
            }))
            return
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, [
        activeStep,
        selectedInvestment,
        selectedDirection,
        selectedSeries,
        selectedClassification,
        setActiveStep,

    ]);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onChangeInvestment = useCallback((val) => {
        setSelectedInvestment(val)
    }, [
        setSelectedInvestment
    ])
    const onChangeDirection = useCallback((val) => {
        setSelectedDirection(val)
    }, [
        setSelectedDirection
    ])
    const onChangeSeries = useCallback((val) => {
        setSelectedSeries(val)
    }, [
        setSelectedSeries
    ])
    const onChangeClassification = useCallback((val) => {
        setSelectedClassification(val)
    }, [
        setSelectedClassification
    ])

    return <>
        <Box className={classes.root}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexFlow: 'column' }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div style={{ flex: 1, overflow: 'auto' }}>
                    {
                        activeStep !== 3 ? (
                            <LoadingOverlay isLoading={isLoading}>
                                {
                                    activeStep === 0 ? (
                                        <InvestmentStep
                                            selectedInvestment={selectedInvestment}
                                            selectedDirection={selectedDirection}
                                            onChange={onChangeInvestment}
                                            onChangeDirection={onChangeDirection}
                                        />
                                    ) : activeStep === 1 ? (
                                        <SeriesStep
                                            selectedInvestment={selectedInvestment}
                                            selectedSeries={selectedSeries}
                                            selectedDirection={selectedDirection}
                                            onChange={onChangeSeries}
                                        />
                                    ) : activeStep === 2 ? (
                                        <ClassificationStep
                                            selectedInvestment={selectedInvestment}
                                            selectedSeries={selectedSeries}
                                            selectedClassification={selectedClassification}
                                            selectedDirection={selectedDirection}
                                            onChange={onChangeClassification}
                                        />
                                    ) : <></>
                                }
                            </LoadingOverlay>
                        ) : (
                            <VisualizationStep
                                selectedInvestment={selectedInvestment}
                                selectedDirection={selectedDirection}
                                selectedSeries={selectedSeries}
                                selectedClassification={selectedClassification}
                                onChange={() => { }}
                            />
                        )
                    }
                </div>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            <Box sx={{ flex: '1 1 auto' }} />
                            {
                                activeStep !== steps.length - 1 && <Button onClick={handleNext}>
                                    Next
                                </Button>
                            }
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </Box>
    </>
}

export default BeaMne
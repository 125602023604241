import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Chip, IconButton, Typography } from "@mui/material";
import ListItem from './Details/ListItem';
import useFetchQAReports from 'hooks/FetchQAReports';
import useFetchQAResults from 'hooks/FetchQAResults';
import IndividualResultDialog from './ProductCode/IndividualResultDialog';
import { Delete } from '@mui/icons-material';
import { resetAlertDialog, showAlertDialog } from 'store/slices/MessagesSystemSlice';
import { parseDateToLocalFormat } from 'shared/utilities/dateHelpers';

const loadingMoreStyle = {
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '20px'
}

export default function SavedIndividual(props) {
    const { searchWord = '', value: currentStepIndex, index: swipeIndex } = props
    const dispatch = useDispatch();

    const [productInQA, setProductInQa] = useState(null)
    const [showQAResult, setShowQAResult] = useState(false)

    const {
        fetchQaReports,
        deleteQAReport,
        state: {
            qaReports: reports,
            loading: loadingReports
        }
    } = useFetchQAReports(dispatch)
    const [fetchQaResults, {
        qaResults: results,
        loading: loadingResults
    }] = useFetchQAResults(dispatch)


    useEffect(() => {
        if(currentStepIndex === swipeIndex){
            fetchQaReports({
                type: 2,
                searchWord
            })
        }
    }, [
        currentStepIndex,
        swipeIndex,
        searchWord
    ])
    
    const alertDialog = useSelector(state => state.messagesSystem.alertDialog)
    useEffect(() => {
        const { dialogAction, clickedButton, data } = alertDialog;
        if (clickedButton === "alertDialogButtonLeft") {
            if (dialogAction === 'deleteQAReport') {
                deleteQAReport(data)
            }
        }
        dispatch(resetAlertDialog());
    }, [alertDialog.clickedButton]);

    const onClickProduct = (product) => {
        setProductInQa({ ...product })
        fetchQaResults({
            reportId: product.id,
            blobOpt: 'all',
        }).then(r => {
            if (r && r.payload) {
                setShowQAResult(true)
            }
        })
    }

    return (
        <Box style={{ overflow: 'auto', height: '100%' }}>
            {
                loadingReports ? <Box style={loadingMoreStyle}>Loading...</Box> : (
                    reports?.map((item, idx) => (
                        <ListItem key={idx} onClick={() => onClickProduct(item)}>
                            <Box display={'flex'} sx={{ justifyContent: 'space-between' }}>
                                <Box flex={1}>
                                    <Typography sx={{ wordBreak: 'break-word' }}>{item.feed_source}/{item.database_code}/{item.product_code} </Typography>
                                    <Typography sx={{
                                        fontSize: '14px',
                                        color: '#757474'
                                    }}>{item.last_frontend_qa_date ? parseDateToLocalFormat(item.last_frontend_qa_date) : ''}</Typography>
                                </Box>
                                <Box display="flex" sx={{ alignItems: 'center', gap: '2px' }}>
                                    {
                                        item.pass_count && item.pass_count > 0 && <Chip label={item.pass_count} color="primary" size="small" sx={{ fontSize: '10px', height: '17px' }} />
                                    }
                                    {
                                        item.fail_count && item.fail_count > 0 && <Chip label={item.fail_count} color="error" size="small" sx={{ fontSize: '10px', height: '17px' }} />
                                    }
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation()
                                        dispatch(showAlertDialog({
                                            title: "Confirmation",
                                            message: "Are you sure you want to delete?",
                                            buttonLabel: { left: "Ok", right: "Cancel" },
                                            dialogAction: "deleteQAReport",
                                            data: {
                                                id: item.id,
                                                batchGroupId: item.batch_group_id
                                            }
                                        }));
                                    }}>
                                        <Delete />
                                    </IconButton>
                                </Box>
                            </Box>
                        </ListItem>
                    ))
                )
            }
            {
                showQAResult && <IndividualResultDialog
                    open={showQAResult}
                    handleClose={() => {
                        setShowQAResult(false)
                    }}
                    feedSource={productInQA.feed_source}
                    databaseCode={productInQA.database_code}
                    productCode={productInQA.product_code}
                    qaResults={results}
                />
            }
        </Box>
    );
}
import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteUserFavorite, fetchUserFavorites, updateUserFavorite } from "store/slices/UserSlice"
import SwipeableViews from "react-swipeable-views";

import {
    Typography,
    Box,
    Grid,
    IconButton,
    Divider
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
    ChevronLeft as ChevronLeftIcon,
    Home as HomeIcon
} from '@mui/icons-material';

import FavDisplay from "./FavDisplay";
import FavGroupFolder from "./FavGroupFolder";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'absolute',
    },
    padding: {
        padding: '12px 12px 0 12px',
    },
    HeaderLabel: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '28px',
        letterSpacing: '-0.02em',
        color: '#374151',
        marginBottom: 8,
    },
    SwipeableViewsContainer: {
        flex: 1,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: 9,
        },
        /* Track */
        '&::-webkit-scrollbar-track': {
            background: '#e4e5e9',
            borderRadius: 4,
        },
        /* Handle */
        '&::-webkit-scrollbar-thumb': {
            background: '#b0b3bd',
            borderRadius: 4,
        },
        /* Handle on hover */
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#9e1e1e',
        },
    },
    BackIconButton: {
        boxShadow: '0px 0px 6px 0px black',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        "&:focus": {
            outline: 'none'
        }
    },
    BreadcrumbItem: {
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 8px',
        gap: '8px',
        height: '20px',
        background: '#5A88ED',
        borderRadius: '24px',
        color: 'white',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '20px',
        marginRight: 6,
        cursor: 'pointer'
    },
    BreadCrumbDivider: {
        background: '#E5ECFA',
        marginBottom: 12,
    },
    SelectedBreadcrumbItem: {
        background: '#2255C3',
        cursor: 'initial'
    },
}))

const BreadcrumbItem = (props) => {
    const classes = useStyles()
    return <Box
        className={`${classes.BreadcrumbItem} ${props.selected ? classes.SelectedBreadcrumbItem : ''}`}
        onClick={() => {
            if (props.onClick) props.onClick()
        }}
    >
        {props.labelTxt}
    </Box>
}

/* Step2Sidebar's Source Container */
const StepTagsContainer = ({ wrapStyle }) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const appLayout = useSelector(state => state.appLayout);
    const initialFavs = useSelector(state => state.user.favs) || {}
    // console.log(initialFavs, 'initialFavs')

    const [state, setState] = useState({
        step2Index: 0,
        feedSource: '',
        parentFolderId: null,
        subFolderId: null
    })
    const handelStep2Index = (index, parentFolderId, subFolderId) => {
        let updates = {
            ...state,
            step2Index: index,
            parentFolderId,
            subFolderId
        };
        setState({ ...updates })
    }
    useEffect(() => {
        if (Object.keys(initialFavs).length < 1) {
            dispatch(fetchUserFavorites())
        }
    }, [])

    const favGroups = useMemo(() => {
        const _treeFormats = {}
        if (Object.keys(initialFavs).length > 0) {
            for (let _groupName in initialFavs) {
                const _fav = initialFavs[_groupName]
                const _spliceIdx = _groupName.indexOf('/')
                let parentId = _groupName, subFolderid
                if (_spliceIdx > -1) {
                    parentId = _groupName.substring(0, _spliceIdx)
                    subFolderid = _groupName.substring(_spliceIdx + 1)
                }
                if (_treeFormats[parentId] === undefined) {
                    _treeFormats[parentId] = {
                        id: parentId,
                        label: parentId,
                    }
                }
                if (subFolderid) {
                    if (_treeFormats[parentId].children === undefined) {
                        _treeFormats[parentId].children = []
                    }
                    const _subFolderIdx = _treeFormats[parentId].children.findIndex(item => item.id == subFolderid)
                    if (_subFolderIdx < 0) {
                        _treeFormats[parentId].children.push({
                            id: subFolderid,
                            label: subFolderid,
                            favs: _fav.favorites
                        })
                    } else {
                        const _subFolder = _treeFormats[parentId].children[_subFolderIdx]
                        _treeFormats[parentId].children[_subFolderIdx].favs = _subFolder.favs.concat(_fav.favorites)
                    }
                } else {
                    if (_treeFormats[parentId].favs === undefined) {
                        _treeFormats[parentId].favs = []
                    }
                    // if(_fav.favorites){
                    //     _treeFormats[parentId].children.unshift({
                    //         id: parentId, label: parentId, favs: _fav.favorites, isDefault: true
                    //     })
                    // }
                    _treeFormats[parentId].favs = _treeFormats[parentId].favs.concat(_fav.favorites)
                }
            }
        }
        // console.log(_treeFormats, '_treeFormats')
        return _treeFormats
    }, [initialFavs])

    const favorites = useMemo(() => {
        let _favorites = []
        if (state.parentFolderId && state.subFolderId) {
            _favorites = initialFavs[`${state.parentFolderId}/${state.subFolderId}`]?.favorites || []
        } else if (state.parentFolderId) {
            _favorites = initialFavs[state.parentFolderId]?.favorites || []
        }
        return _favorites
    }, [
        initialFavs,
        state.parentFolderId,
        state.subFolderId
    ])
    const configurations = useMemo(() => {
        let _configurations = []
        if (state.parentFolderId && state.subFolderId) {
            _configurations = initialFavs[`${state.parentFolderId}/${state.subFolderId}`]?.configuration || []
        } else if (state.parentFolderId) {
            _configurations = initialFavs[state.parentFolderId]?.configuration || []
        }
        return _configurations
    }, [
        initialFavs,
        state.parentFolderId,
        state.subFolderId
    ])

    const renderBreadCrumb = useMemo(() => {
        return <Box>
            <Divider className={classes.BreadCrumbDivider} />
            {
                state.step2Index > 0 && (
                    <BreadcrumbItem labelTxt="Data" onClick={() => { handelStep2Index(0) }} />
                )
            }
            {
                state.step2Index >= 1 && (
                    <>
                        {
                            state.parentFolderId && (
                                <BreadcrumbItem
                                    labelTxt={state.parentFolderId}
                                    selected={!state.subFolderId}
                                    onClick={() => {
                                        if (state.subFolderId) handelStep2Index(0)
                                    }}
                                />
                            )
                        }
                        {
                            state.subFolderId && (
                                <BreadcrumbItem
                                    labelTxt={state.subFolderId}
                                    selected={true}
                                    onClick={() => { }}
                                />
                            )
                        }
                    </>
                )
            }
        </Box>
    }, [
        state.parentFolderId,
        state.subFolderId,
        state.step2Index
    ])

    return (
        <Grid item lg={12} md={12} xs={12} className={classes.root} style={wrapStyle}>
            <Box className={classes.padding}>
                <Box display="flex" sx={{ alignItems: 'center' }}>
                    <Box flex="1">
                        <Typography className={classes.HeaderLabel}>
                            Bookmarks
                        </Typography>
                        {renderBreadCrumb}
                    </Box>
                    {/* {
                        state.step2Index > 0 && (
                            <IconButton onClick={() => handelStep2Index(0)} className={classes.BackIconButton}>
                                <ChevronLeftIcon />
                            </IconButton>
                        )
                    } */}
                </Box>
            </Box>
            <Box className={classes.SwipeableViewsContainer}>
                <SwipeableViews
                    axis='x'
                    index={state.step2Index}
                    style={{ overflowX: 'hidden', display: appLayout.step2Sidebar === 'tags' ? '' : 'none', height: '100%', width: '100%' }}
                >
                    <FavDisplay
                        value={state.step2Index}
                        changeStep2Index={handelStep2Index}
                        index={0}
                        dir={'rtl'}
                        favGroups={favGroups}
                    />
                    <FavGroupFolder
                        value={state.step2Index}
                        changeStep2Index={handelStep2Index}
                        onUpdateBookmark={(newVal) => {
                            if (state.subFolderId) {
                                setState({
                                    ...state, subFolderId: newVal
                                })
                            } else if (state.parentFolderId) {
                                setState({
                                    ...state, parentFolderId: newVal
                                })
                            }
                        }}
                        index={1}
                        favorites={favorites}
                        configurations={configurations}
                        parentFolderId={state.parentFolderId}
                        subFolderId={state.subFolderId}
                        dir={'rtl'} />
                </SwipeableViews>
            </Box>
        </Grid>
    );
}

export default StepTagsContainer;

import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from "@mui/styles";

import {
    Dialog,
    DialogActions,
    DialogContent,
    lighten,
    Grid,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    Box,
} from "@mui/material";
import { ArrowDropDown } from '@mui/icons-material';
import CustomFormInput from 'components/common/Base/CustomFormInput';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchFrontendQAProducts,
    fetchFrontendQAReports,
    testTrigger,
    triggerFrontendProductQA,
    triggerFrontendQAReport,
    checkFrontendQAReport,
    skipFrontendQABatch,
    updateFrontendQABatch,
    stopFrontendQAProcess,
    updateFrontendQABatchGroup,
    deleteFrontendQABatchGroup,
    deleteFrontendQAReport,
    checkFrontendQABatch
} from 'store/slices/FrontendQA';
import Pie from './PieChart'
import config from 'config.json'
import { resetAlertDialog, showAlertDialog, showSnackbarMessage } from 'store/slices/MessagesSystemSlice';
import SuccessCheck from './components/SuccessCheck';
import { Typography } from 'antd';
import SearchIndividual from './components/SearchIndividual';
import LoadingOverlay from 'components/common/LoadingOverlay';
import SkipConfirmationDialog from './components/SkipConfirmationDialog';
import ErrorMark from './components/ErrorMark';

const cluster = process.env.REACT_APP_QA_CLUSTER

const useStyles = makeStyles((theme) => ({
    root: {
        // padding: 0,
        position: 'relative',
        minHeight: '200px',
        display: 'flex',
        flexFlow: 'column'
    },
    alertBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1000,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // background: "#8080800a"
        background: "white",
        paddingBottom: '20px',
    },
    DialogContent: {
        '& .MuiDialog-paper': {
            width: '500px',
            maxWidth: '100rem',
        }
    },
    Txt: {
        color: 'black'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    BorderedBox: {
        display: 'flex',
        flexFlow: 'column',
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        minHeight: '100px',
        height: '100%',
        padding: '10px',
        maxHeight: '500px',
        overflow: 'auto',
        paddingBottom: '35px'
    },
    ListItemText: {
        fontSize: 14
    },
    ListItemButton: {
        padding: '5px 11px'
    },
    selectedLine: {
        background: '#dfdfdf'
    },

    InputField: {
        // width: 'auto',
        '& input': {
            fontSize: '12px',
            // width: '182px',
            padding: '6px',
            marginBottom: 0
        }
    },
    score: {
        fontSize: '20px'
    },
    LoadingOverlay: {
        '& > div': {
            zIndex: 1000
        }
    },
    BatchConfirmBox: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        background: '#ffffffed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        zIndex: 1
    },
    BatchconfirmBoxText: {
        fontSize: '18px',
        marginBottom: '27px'
    }
}));

const formatTimeDifference = (timeDifference) => {
    // Convert to hours and minutes
    const totalDiff = timeDifference / 1000
    const hours = Math.floor(totalDiff / 3600);
    const restMinutes = Math.floor((totalDiff % 3600) / 60);

    if (hours > 0) {
        if (restMinutes > 0) {
            return `${hours}:${restMinutes} h`
        }
        return `${hours} h`
    } else {
        const minutes = Math.round((totalDiff / 60));
        if (minutes > 0) {
            return `${minutes} mins`
        }
    }
    return '0h'
}

// const formatTimeDifference = (timeDifference) => {
//     // Convert to hours and minutes
//     var hours = Math.floor((timeDifference / 1000) / 3600);
//     var minutes = Math.round(((timeDifference / 1000) / 60));
//     var secs = Math.round(((timeDifference / 1000)));
//     console.log(hours, minutes, secs, 'secs')

//     if(hours > 0){
//         if(minutes > 0){
//             return `${hours}:${minutes} h`
//         }
//         return `${hours} h`
//     }else{
//         if(minutes > 0){
//             return `${minutes} mins`
//         }
//     }
//     return '0h'
// }

const errorMsgs = {
    500: 'Internal Server Error',
    503: 'Service unavailable',
}

const StartQADialog = (props) => {
    const {
        open,
        handleClose,
        feedSource,
        databaseCode,
        productCode: initialProductCode = '',
        type: initialOption = 'all',
        endPercentage: initialEndPercentage = 0,
        onUpdate,
        resumeData = {}
    } = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const [option, setOption] = useState(initialOption)
    const [percentage, setPercentage] = useState(10);
    const [tempBatchGroupId, setTempBatchGroupId] = useState(null);
    const [tempBatchSize, setTempBatchSize] = useState(0);
    const [tempBatchIndex, setTempBatchIndex] = useState(0);
    const [tempReportId, setTempReportId] = useState(null);
    const [batchSize, setBatchSize] = useState(0);
    const [batchIndex, setBatchIndex] = useState(0)
    const [productCount, setProductCount] = useState(0)
    // const [endPercentage, setEndPercentage] = useState(initialEndPercentage)
    const [score, setScore] = useState(0)
    const [tempScore, setTempScore] = useState(0)

    // const loading = useSelector(state => state.frontendQA.inProgressOfQA)
    const [loading, setLoading] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [hasError, setHasError] = useState(false);
    const [errorType, setErrorType] = useState(null)

    const [openSkipConfirmDialog, setOpenSkipConfirmDialog] = useState(false);


    const loadingProducts = useSelector(state => state.frontendQA.loadingProducts)
    const inProgressOfBatch = useSelector(state => state.frontendQA.inProgressOfBatch)
    const batchesInProgress = useSelector(state => state.frontendQA.batchesInProgress)
    const [selectedProductCode, setSelectedProductCode] = useState(initialProductCode || null)
    // const products = useSelector(state => state.frontendQA.products)
    const onClose = () => {
        if (loading) return;
        if (score > 0 || batchIndex > 0) {
            handleClose(true)
        }
        handleClose(false)
    }
    const [progressVal, setProgressVal] = useState(0)
    const [expectTime, setExpectTime] = useState(0)
    const [consumedTime, setConsumedTime] = useState(0)

    async function retryWith503Handling(action, maxRetries, errorCallBack) {
        let retries = 0;
        let _errorType = null;

        while (retries < maxRetries) {
            try {
                const response = await dispatch(action);
                // console.log(response, 'response')
                if ((response.payload && response.payload.status == 503) || !response.payload) {
                    retries++;
                    console.log(retries, 'retries???')
                    _errorType = 503
                } else if (response.payload.status == 500) {
                    dispatch(showSnackbarMessage({
                        message: `500 error happened in running QA process. Please kindly try again later`,
                        severity: 'error'
                    }))
                    _errorType = 500
                    return
                } else if (response.payload.status === "cancelled") {
                    return
                } else {
                    return response;
                }
            } catch (e) {
                setErrorType(e.response.status)
                setHasError(true)
                _errorType = e.response.status
                console.log(e, 'error')
                return
                //     console.log(error, 'error')
                //     if (error.response && error.response.status === 503) {
                //         // Handle 503 error, e.g., log it
                //         console.log('503 error occurred. Retrying...');
                //         retries++;
                //     } else {
                //         // Handle other errors
                throw e;
                //     }
            }
        }
        if(errorCallBack){
            console.log('error callback')
            errorCallBack(_errorType)
        }
        setErrorType(_errorType)
        setHasError(true)
        dispatch(showSnackbarMessage({
            message: `Something went wrong in running QA process. Please kindly try again later`,
            severity: 'error'
        }))
        // Max retries reached
        throw new Error('Max retries reached for 503 errors.');
    }


    const runQA = useCallback(async (reportId, startPercentage = 0, endPercentage = 0) => {
        try {
            const r = await dispatch(fetchFrontendQAProducts({
                feedSource,
                databaseCode,
                pickOption: option,
                startPercentage,
                endPercentage,
                reportId
            }))

            if (r && r.payload) {
                const { products } = r.payload
                setProductCount(products.length)
                setProgressVal(0)
                setScore(0)
                let failed = false;
                let _totalAmount = 0
                let _expectTime = 0;
                let _consumedTime = 0;
                for (let p of products) {
                    _totalAmount += p.memory_size * 1
                    _expectTime += p.memory_size / config.averageLoadCapacity * 1 + 5000
                }
                setExpectTime(_expectTime)

                let _progressVal = 0
                let _score = 0
                for (let _product of products) {
                    const _s = Date.now()
                    // const r = await dispatch(testTrigger({}))
                    // if (!payload) break;
                    const r = await retryWith503Handling(
                        triggerFrontendProductQA({
                            payload: {
                                cluster,
                                feedSource,
                                databaseCode,
                                productCode: _product.product_code,
                                reportId
                                // pickOption: option,
                                // percentage: percentage
                            },
                        }),
                        3, // Maximum number of retries for this product
                        async (_errorType) => {
                            setTempReportId(reportId)
                            console.log(reportId, 'in errorcallback')
                            await dispatch(updateFrontendQABatch({
                                feedSource,
                                databaseCode,
                                status: 'error',
                                errorLog: _product.product_code,
                                errorType: _errorType,
                                id: reportId
                            }))
                        }    
                    );
                    console.log(r, 'r????')
                    if (!(r && r.payload && r.payload[0])) {
                        setLoading(false)
                        failed = true
                        await dispatch(updateFrontendQABatch({
                            feedSource,
                            databaseCode,
                            status: 'error',
                            errorLog: `Error in the running the QA: ${_product.product_code}`,
                            errorType: 400,
                            id: reportId
                        }))


                        // asdf;sldkjfaslkdj
                        break
                    }
                    _score += r.payload[0].score
                    _consumedTime += Date.now() - _s
                    console.log(_product.memory_size, _totalAmount)
                    const _val = _product.memory_size / _totalAmount * 100
                    // console.log(_val, "_val")
                    _progressVal = _progressVal + _val
                    setProgressVal(_progressVal)
                    setConsumedTime(_consumedTime)
                }
                if (!failed) {
                    // console.log(_score, "_score")
                    // console.log(_products.length, '_products.length')
                    // console.log((_score / _products.length).toFixed(2), 'passed')

                    console.log(tempScore, 'score in run by batch')
                    console.log(_score, '_score in the run bybatch')
                    console.log(products.length, 'products length in the run bybatch')
                    return {
                        score: option === 'byBatch' ?
                            ((tempScore * 1) + (_score / products.length)).toFixed(2) :
                            (_score / products.length).toFixed(2)
                    }
                    if (option === 'byBatch') {
                        setTempScore()
                    } else {
                        setScore((_score / products.length).toFixed(2))
                    }
                    // console.log(r.payload, 'r.payload')
                    if (r.payload.endPercentage) {
                        // setEndPercentage(r.payload.endPercentage)
                    }
                    const r = await dispatch(updateFrontendQABatch({
                        feedSource,
                        databaseCode,
                        pickOption: option,
                        endPercentage: option === 'byPercentage' ? r.payload.endPercentage : null,
                        type: 3,
                        // batchSize: batchSize,
                        // batchIndex: newBatchIndex
                    }))
                    if (r.payload) {
                        setLoading(false)
                        setProgressVal(0)
                    }
                    return r
                    // .then(r => {
                    //     if (r.payload) {
                    //         setLoading(false)
                    //         setProgressVal(0)
                    //         if (newBatchIndex === batchSize) {
                    //             setShowConfirm(false)
                    //             setScore(((tempScore * 1 + (_score / products.length)) / batchSize).toFixed(2))
                    //             setTempScore(0)
                    //         } else {
                    //             setShowConfirm(true)
                    //             setLoading(false)
                    //             setScore(0)
                    //         }
                    //     }
                    // })
                }
            }
            return false
        } catch (e) {
            // await dispatch(updateFrontendQABatch({
            //     feedSource,
            //     databaseCode,
            //     productCode: 
            //     status: 'error',
            //     error_log: `Error in the running the QA: ${_product.product_code}` ,
            //     id: reportId
            // }))
            console.log(e, 'error')
            return false
        }
    }, [
        option,
        batchSize,
        tempScore
    ])

    const onRunByIndividual = async () => {
        try {
            let _score = 0
            let failed = false
            setLoading(true)
            const r = await dispatch(fetchFrontendQAProducts({
                feedSource,
                databaseCode,
                pickOption: option,
                productCode: selectedProductCode
            }))
            if (r.payload) {
                setProgressVal(0)
                setScore(0)
                const { products: _products } = r.payload
                let _expectTime = 0;
                let _consumedTime = 0;
                for (let p of _products) {
                    _expectTime += p.memory_size / config.averageLoadCapacity * 1 + 5000
                }
                setExpectTime(_expectTime)

                for (let _product of _products) {
                    const _s = Date.now()
                    const r = await dispatch(triggerFrontendProductQA({
                        payload: {
                            cluster,
                            feedSource,
                            databaseCode,
                            productCode: _product.product_code,
                            isIndividual: 1
                        },
                        onDownloadProgress: (progressEvent) => {
                            // console.log(progressEvent.loaded, progressEvent.total);
                            setProgressVal(((progressEvent.loaded / progressEvent.total) * 100).toFixed(2))
                            _consumedTime += 1000 * 1
                            setConsumedTime(_consumedTime)
                        }
                    }))
                    if (!(r && r.payload)) {
                        setLoading(false)
                        failed = true
                        break
                    }
                    _score += r.payload[0].score
                }
                if (!failed) {
                    // console.log(_score, "_score")
                    // console.log(_products.length, '_products.length')
                    // console.log((_score / _products.length).toFixed(2), 'passed')
                    setScore((_score / _products.length).toFixed(2))
                    dispatch(updateFrontendQABatch({
                        feedSource,
                        databaseCode,
                        productCode: selectedProductCode,
                        pickOption: option,
                        type: 2
                    }))
                }
            }

            setLoading(false)
            // console.log(r, 'r')
            return
        } catch (e) {
            console.log(e, 'error')
            dispatch(showSnackbarMessage({
                message: `Something went wrong. Please kindly try again later`,
                severity: 'error'
            }))
            setLoading(false)
            setProgressVal(0)
            setScore(0)
        }
    }

    const onRunByAll = useCallback(async (reportId = null) => {
        try {
            const qaReportPayload = {
                feedSource: feedSource,
                databaseCode: databaseCode,
                type: 1,
                status: 'in-progress'
            }
            if(reportId){
                qaReportPayload.id = reportId
            }
            setLoading(true)
            const { payload: { row: batchData } } = await dispatch(updateFrontendQABatch(qaReportPayload))
            console.log(batchData, 'batchData')
            if (!(batchData && batchData.id !== '')) {
                setLoading(false)
                return
            };
            const {
                score: newScore
            } = await runQA(batchData.id)
            if (!newScore) {
                setLoading(false)
                setScore(0)
                setTempScore(0)
                setBatchIndex(0)
                return;
            }
            // console.log(reportId, 'reportId')
            // const { payload: batchData } = await dispatch(updateFrontendQABatch({
            //     id: reportId,
            //     feed_source: feedSource,
            //     database_code: databaseCode,
            //     status: 1,
            //     batch_size: batchSize,
            //     batch_index: newBatchIndex
            // }))
            setTempReportId(null)
            setLoading(false)
            setScore(newScore)
        } catch (e) {
            console.log(e, 'e')
            setLoading(false)
            setScore(0)
        }
    },
        [
            feedSource,
            databaseCode,
            batchSize,
            batchesInProgress,
            runQA,
        ]
    )

    const onRunByBatch = useCallback(async (batchGroupId, newBatchIndex, newBatchSize = 0, reportId = null) => {
        try {
            setLoading(true);
            console.log(batchSize, 'batchSize')
            const _batchSize = newBatchSize || batchSize;
            let _reportId = reportId
            if(!_reportId){
                const { payload: { row: batchData } } = await dispatch(updateFrontendQABatch({
                    feedSource,
                    databaseCode,
                    status: 'in-progress',
                    batchSize: _batchSize,
                    batchIndex: newBatchIndex,
                    batchGroupId: batchGroupId,
                    type: 3
                }))
                _reportId = batchData.id;
                setTempReportId(_reportId)
            }
            // console.log(_reportId, '_reportId')
            setBatchIndex(newBatchIndex)
            let startPercentage, endPercentage;
            console.log(_batchSize, newBatchIndex, 'checking percentage')
            startPercentage = Math.floor(100 / _batchSize * (newBatchIndex - 1))
            endPercentage = Math.floor(100 / _batchSize * newBatchIndex)
            console.log(startPercentage, endPercentage)
            const {
                score: newScore
            } = await runQA(_reportId, startPercentage, endPercentage)
            if (!newScore) {
                setLoading(false)
                setScore(0)
                setTempScore(0)
                setBatchIndex(0)
                return;
            }

            setTempReportId(null)
            const batchGroupData = {
                id: batchGroupId,
                completedBatchSize: newBatchIndex,
                batchSize: _batchSize,
                status: _batchSize == newBatchIndex ? 'success' : 'in-progress'
            }
            await dispatch(updateFrontendQABatch({
                id: _reportId,
                batchIndex: newBatchIndex,
                status: 'done'
            }))
            await dispatch(updateFrontendQABatchGroup(batchGroupData))
            console.log(newBatchIndex, _batchSize)
            let _tempScore = tempScore > 0 ? (newScore / 2).toFixed(2) : newScore
            console.log(newScore, 'New Score')
            console.log(tempScore, 'tempScore')
            console.log(_tempScore, '_tempscore')
            if (newBatchIndex == _batchSize) {
                console.log(_tempScore, '_tempscore')
                setShowConfirm(false)
                setScore(_tempScore)
                setTempScore(0)
                setTempBatchGroupId(null)
                setTempReportId(null)
                setTempBatchIndex(0)
                setLoading(false)
            } else {
                setShowConfirm(true)
                setLoading(false)
                setScore(0)
                setTempScore(_tempScore)
            }
            return
            if (!1 == 1) {

            } else {
                console.log(batchesInProgress, 'batchesInProgress')
                const batch = batchesInProgress.filter(item => item.batch_index == newBatchIndex)[0]
                console.log(batch, "here skipped??")
                if (batch) {
                    _reportId = batch.id
                }
            }
            if (_reportId === '') return;
            console.log(_reportId, 'reportId')
            // const { payload: batchData } = await dispatch(updateFrontendQABatch({
            //     id: _reportId,
            //     feed_source: feedSource,
            //     database_code: databaseCode,
            //     status: 1,
            //     batch_size: batchSize,
            //     batch_index: newBatchIndex
            // }))

        } catch (e) {
            console.log(e, 'e')
            setLoading(false)
            setBatchIndex(0)
            setScore(0)
            setTempScore(0)
        }
    },
        [
            feedSource,
            databaseCode,
            batchSize,
            batchesInProgress,
            tempScore,
            runQA,
        ])

    const checkBatchProcess = useCallback(async () => {
        const { payload: { batchGroup: batchGroup, status } } = await dispatch(checkFrontendQABatch({
            feedSource,
            databaseCode
        }))

        if (!status) {
            setOption('byBatch')
            setOpenSkipConfirmDialog(true)
            setTempBatchGroupId(batchGroup.id)
            setTempBatchIndex(batchGroup.completed_batch_size * 1 + 1)
            setTempBatchSize(batchGroup.batch_size)
            return;
        }
    }, [

    ])

    const onClickStart = async () => {
        try {
            let _score = 0
            let failed = false

            if (option === 'byBatch' && (batchSize < 1 || batchSize > 10)) {
                dispatch(showSnackbarMessage({
                    message: `Batch number should be between 1 and 10 for the selected option.`,
                    severity: 'error'
                }))
                setLoading(false)
                return
            }
            if (option === 'byBatch') {
                if (batchSize < 1 || batchSize > 10) {
                    dispatch(showSnackbarMessage({
                        message: `Batch number should be between 1 and 10 for the selected option.`,
                        severity: 'error'
                    }))
                    return
                }
                const { payload: { batchGroup: batchGroup, status } } = await dispatch(checkFrontendQAReport({
                    feedSource,
                    databaseCode
                }))
                if (!status) {
                    setOpenSkipConfirmDialog(true)
                    setTempBatchGroupId(batchGroup.id)
                    setTempBatchIndex(batchGroup.completed_batch_size * 1 + 1)
                    setTempBatchSize(batchGroup.batch_size)
                    return;
                } else {
                    const { payload: { row: batchGroup } } = await dispatch(updateFrontendQABatchGroup({
                        feedSource,
                        databaseCode,
                        batchSize,
                        status: 'initiated'
                    }))
                    if (batchGroup.id) {
                        setTempBatchGroupId(batchGroup.id)
                        const r = await onRunByBatch(batchGroup.id, 1)
                    }
                }
            } else if (option === 'all') {
                const r = await onRunByAll()
            }
            return

            if (option === 'byPercentage' && percentage < 1) {
                return
            }
            if (option === 'byPercentage') return;
            if (option === 'byBatch') {
                const r = await runQA(1)
            }
            return

            // dispatch(triggerFrontendQAReport({
            //     cluster: 'server',
            //     feedSource,
            //     databaseCode,
            //     pickOption: option,
            //     percentage: percentage
            // })).then(r => {
            //     onClose()
            // })
            setLoading(false)
            // console.log(r, 'r')
            return
        } catch (e) {
            console.log(e, 'error')
            dispatch(showSnackbarMessage({
                message: `Something went wrong. Please kindly try again later`,
                severity: 'error'
            }))
            setLoading(false)
            setProgressVal(0)
            setScore(0)
        }
    }
    const onClickReset = async () => {
        dispatch(stopFrontendQAProcess())
        if(option === 'all'){
            dispatch(deleteFrontendQAReport({
                id: tempReportId
            })).then(() => {
                dispatch(showSnackbarMessage({
                    message: `QA stopped successfully.`,
                    severity: 'success'
                }))
                setTempBatchGroupId(null)
                setTempReportId(null)
                setProgressVal(0)
                onClose()
            })
        }else{
            dispatch(deleteFrontendQABatchGroup({
                id: tempBatchGroupId
            })).then(({ payload : { status } }) => {
                if (status) {
                    dispatch(showSnackbarMessage({
                        message: `Batch stopped successfully.`,
                        severity: 'success'
                    }))
                    setTempBatchGroupId(null)
                    setTempReportId(null)
                    setProgressVal(0)
                    onClose()
                }else{
                    dispatch(showSnackbarMessage({
                        message: `Something went wrong in stopping batch. Please kindly try again later`,
                        severity: 'error'
                    }))
                }
            })
        }
        
    }
    const onClickSkip = async () => {
        console.log(batchIndex, 'batchIndex')
        console.log(tempBatchIndex, 'tempBatchIndex')

        const nextBatchIndex = batchIndex * 1 + 1;
        // console.log(tempBatchGroupId, 'tempBatchGroupId')
        // return
        if(option === 'all'){
            dispatch(updateFrontendQABatch({
                id: tempReportId,
                feedSource,
                databaseCode,
                status: 'skipped',
                type: 1,
            })).then(({ payload: { row: batchData } }) => {
                if(batchData.id){
                    dispatch(showSnackbarMessage({
                        message: `QA skipped successfully.`,
                        severity: 'success'
                    }))
                    setShowConfirm(false)
                    setScore(tempScore)
                    setTempScore(0)
                    setTempReportId(null)
                    setTempBatchGroupId(null)
                    setTempBatchIndex(0)
                    setLoading(false)
                }else{
                    dispatch(showSnackbarMessage({
                        message: `Something went wrong in skipping batch. Please kindly try again later!`,
                        severity: 'error'
                    }))
                }
            })
        }else{
            dispatch(updateFrontendQABatchGroup({
                id: tempBatchGroupId,
                batchSize: batchSize,
                status: 'skipped',
                completedBatchSize: nextBatchIndex
            })).then(({ payload: { status } }) => {
                if(status){
                    dispatch(updateFrontendQABatch({
                        id: tempReportId,
                        type: 3,
                        feedSource,
                        databaseCode,
                        status: 'skipped',
                        batchSize: batchSize,
                        batchIndex: nextBatchIndex,
                        batchGroupId: tempBatchGroupId
                    })).then(({ payload: { row: batchData } }) => {
                        if(batchData.id){
                            dispatch(showSnackbarMessage({
                                message: `Batch (${nextBatchIndex}/${batchSize}) skipped successfully.`,
                                severity: 'success'
                            }))
                            setShowConfirm(false)
                            if(nextBatchIndex < batchSize){
                                onRunByBatch(tempBatchGroupId, nextBatchIndex + 1, batchSize)
                            }else{
                                setScore(tempScore)
                                setTempScore(0)
                                setTempReportId(null)
                                setTempBatchGroupId(null)
                                setTempBatchIndex(0)
                                setLoading(false)
                            }
                        }else{
                            dispatch(showSnackbarMessage({
                                message: `Something went wrong in skipping batch. Please kindly try again later!`,
                                severity: 'error'
                            }))
                        }
                    })
                }else{
                    dispatch(showSnackbarMessage({
                        message: `Something went wrong in skipping batch. Please kindly try again later2!`,
                        severity: 'error'
                    }))
                }
            })
        }
    }
    const onClickContinue = () => {
        if (batchIndex == batchSize) {
            // setLoading(false)
            // setTempScore(0)
        } else {
            onRunByBatch(tempBatchGroupId, batchIndex + 1)
        }
        setShowConfirm(false)
    }
    const onClickRestart = async () => {
        setHasError(false)
        setErrorType(null)
        if(option === 'all'){
            onRunByAll(tempReportId)
        }else{
            onRunByBatch(tempBatchGroupId, batchIndex + 1, 0, tempReportId)
        }
    }

    React.useEffect(() => {
        if(resumeData.id){
            setTempReportId(resumeData.id)
            if(resumeData.type == 1){
                setOption('all')
                onRunByAll(resumeData.id)
            }else{
                setOption('byBatch')
                setTempBatchGroupId(resumeData.batch_group_id)
                setTempBatchIndex(resumeData.batch_index)
                onRunByBatch(resumeData.batch_group_id, resumeData.batch_index + 1, 0, resumeData.id)
            }
        }else{
            checkBatchProcess();
        }
    }, [
        resumeData.type,
        resumeData.id,
        resumeData.batchGroupId
    ])
    const alertDialog = useSelector(state => state.messagesSystem.alertDialog)
    React.useEffect(() => {
        const { dialogAction, clickedButton, data } = alertDialog;
        try {
            if (dialogAction === 'skipQABatch') {
                if (clickedButton === "alertDialogButtonLeft") {

                    // dispatch(skipFrontendQABatch(data)).then(r => {
                    //     const { payload: { status } } = r
                    //     dispatch(resetAlertDialog());
                    //     if (status) {
                    //         console.log('skipped???')
                    //         const checkIndex = batchesInProgress.findIndex(item => item.batch_index == data.batchIndex)
                    //         onRunByBatch(data.batchIndex, checkIndex > - 1)
                    //     } else {
                    //         setLoading(false)
                    //     }
                    // })
                } else if (clickedButton === 'alertDialogButtonRight') {
                    setLoading(false)
                    dispatch(resetAlertDialog());
                }
            }
        } catch (e) {
            console.log(e, 'error')
            dispatch(showSnackbarMessage({
                message: `Something went wrong. Please kindly try again later`,
                severity: 'error'
            }))
            setLoading(false)
            dispatch(resetAlertDialog());
        }
    }, [
        alertDialog.clickedButton,
        batchesInProgress
    ]);

    const calcualteScores = (qaResults) => {
        let totalTime = 0
        for (let qaResult of qaResults) {
            const { blob_load_seconds, initial_load_seconds, metadata_load_seconds, time_to_draw_seconds } = qaResult
            totalTime += blob_load_seconds * 1 + initial_load_seconds * 1 + metadata_load_seconds * 1 + time_to_draw_seconds * 1
        }
        if (totalTime <= config.standardProcessTime) {
            return 100
        } else {
            return (config.standardProcessTime / totalTime * 100).toFixed(2)
        }
    }

    const disableStart = useMemo(() => {
        if (loading) return true
        if (option === 'byPercentage' && percentage < 1) {
            return true
        }
        if (option === 'byIndividual' && !selectedProductCode) {
            return true
        }
        return false
    }, [
        option,
        loading,
        percentage,
        selectedProductCode
    ])

    React.useEffect(() => {
        const handleBeforeUnload = (event) => {
          // Perform any necessary actions before the page is unloaded
          // For example, you can show a confirmation dialog
          const confirmationMessage = 'Are you sure you want to leave this page?';
          event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
          alert(confirmationMessage)
          return confirmationMessage; // Gecko, WebKit, Chrome <34
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []); // Empty dependency array ensures the effect runs only once, like componentDidMount


    // const allMemoryAmount = useMemo(() => {
    //     let _amount = 0;
    //     for(let p of products){
    //         _amount += p.memory_size * 1
    //     }
    //     console.log(_amount, '_amount')
    //     return _amount
    // }, [
    //     products
    // ])


    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                className={classes.DialogContent}
                maxWidth="md"
            >
                <DialogContent>
                    <LoadingOverlay className={classes.LoadingOverlay} isLoading={loading && option === 'byIndividual'}>
                        <Grid container spacing={1}>
                            <Grid item lg={12} md={12} xs={12} className={'mt-2'}>
                                <Box className={classes.root}>
                                    <Box>
                                        <FormControl sx={{ width: '100%' }}>
                                            {/* <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel> */}
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={option}
                                                onChange={(e) => {
                                                    setOption(e.target.value)
                                                }}
                                            >
                                                <FormControlLabel value="all" control={<Radio />} label="Run All at Once" />
                                                <FormControlLabel value="byBatch" control={<Radio />} label="Run Batch" />
                                                {
                                                    option === 'byBatch' && (
                                                        <CustomFormInput
                                                            placeholder="Enter Number of Batch..."
                                                            fieldName="batchSize"
                                                            className={classes.InputField}
                                                            value={batchSize}
                                                            type="number"
                                                            onChange={(fieldName, value) => {
                                                                setBatchSize(value * 1)
                                                            }}
                                                        />
                                                    )
                                                }
                                                {/* <FormControlLabel value="byPercentage" control={<Radio />} label={`Run Consecutively`} />
                                            {
                                                option === 'byPercentage' && (
                                                    <CustomFormInput
                                                        placeholder="Enter Percentage..."
                                                        fieldName="percentage"
                                                        className={classes.InputField}
                                                        value={percentage}
                                                        type="number"
                                                        onChange={(fieldName, value) => {
                                                            setPercentage(value * 1)
                                                        }}
                                                    />
                                                )
                                            } */}
                                                {/* <FormControlLabel value="byIndividual" control={<Radio />} label="Run Individually" />
                                            {
                                                option === 'byIndividual' && (
                                                    <SearchIndividual
                                                        feedSource={feedSource}
                                                        databaseCode={databaseCode}
                                                        onSelectProduct={(value) => {
                                                            setSelectedProductCode(value)
                                                        }}
                                                        value={selectedProductCode}
                                                    />
                                                )
                                            } */}
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>
                                    {
                                        // QA processing
                                        (loading && option !== 'byIndividual') && (
                                            <Box className={classes.alertBox} sx={{ flexFlow: 'column' }} >
                                                <Box>
                                                    <Pie
                                                        percentage={progressVal}
                                                        colour="blue"
                                                        time={`${formatTimeDifference(consumedTime)}/${formatTimeDifference(expectTime)}`}
                                                    />
                                                </Box>
                                                <Box>
                                                    <span></span>{ `${productCount} products` }
                                                    {
                                                        option=== 'byBatch' && <span>({batchIndex}/{batchSize})</span>
                                                    }
                                                </Box>
                                                <Box display="flex" sx={{ justifyContent: 'end', padding: '0 10px', width: '100%' }}>
                                                    <Button variant='contained' color='error' onClick={onClickReset}>Reset</Button>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    {
                                        // Finished
                                        (!loading && score > 0) && (
                                            <Box className={classes.alertBox}>
                                                <Box>
                                                    <SuccessCheck />
                                                    {/* <Typography>Successfully Done!</Typography> */}
                                                    <Typography className={classes.score}>{score} Score</Typography>
                                                    <Typography color=''>{
                                                        score >= 90 ? "The product code performance is good." :
                                                            score >= 70 ? "The product code performance is average." :
                                                                "The product code performance needs improvement."
                                                    }</Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    {
                                        // when error happened
                                        (hasError) && (
                                            <Box className={classes.alertBox}>
                                                <Box>
                                                    <ErrorMark />
                                                    <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                                        <h3 style={{ marginRight: '3px', marginBottom: 0 }}>{errorType}</h3>
                                                        <span>{errorMsgs[errorType] ?? ''}</span>
                                                    </Box>
                                                    {
                                                        option === 'all' ? (
                                                            <Typography>Something weng wrong while running QA with the "All" option</Typography>
                                                        ) : (
                                                            <Typography>Something weng wrong in Batch of {`${batchIndex}/${batchSize}`}</Typography>
                                                        )
                                                    }
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    {
                                        //
                                        showConfirm && (
                                            <Box className={classes.BatchConfirmBox}>
                                                <Box className={classes.BatchconfirmBoxText}>Batch {`${batchIndex}/${batchSize}`} complete, run next?</Box>
                                                <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button
                                                        variant='contained'
                                                        color='error'
                                                        className='mr-1'
                                                        onClick={onClickSkip}
                                                    >Skip</Button>
                                                    <Button
                                                        variant='contained'
                                                        onClick={onClickContinue}>Continue</Button>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                    <Box display="flex" className="p-3 mt-auto" sx={{ width: '100%', justifyContent: 'end', gapX: '4px'}}>
                                    {
                                        score < 1 && !loading && <>
                                            <>
                                                {
                                                    hasError ? <>
                                                        <Box display="flex">
                                                            <Button
                                                                variant='contained'
                                                                color='error'
                                                                className='mr-1'
                                                                onClick={() => {
                                                                    setErrorType(null)
                                                                    setHasError(false)
                                                                    onClickSkip()
                                                                }}
                                                            >Skip</Button>
                                                            <Button
                                                                variant='contained'
                                                                className='mr-1'
                                                                onClick={onClickRestart}>Restart</Button>
                                                            <Button variant='contained' color='error' onClick={onClickReset}>Reset</Button>
                                                        </Box>
                                                    </> : <Button
                                                        disabled={disableStart || (option === 'byBatch' && inProgressOfBatch)}
                                                        onClick={onClickStart} variant="contained">
                                                        {loading ? 'Running...' : 'Start'}
                                                    </Button>
                                                }
                                            </>
                                        </>
                                    }
                                    {
                                        !loading && <>
                                            <Button onClick={onClose} autoFocus>
                                                Close
                                            </Button>
                                        </>

                                    }
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </LoadingOverlay>
                </DialogContent>
            </Dialog>
            {
                openSkipConfirmDialog && <SkipConfirmationDialog
                    open={openSkipConfirmDialog}
                    batchSize={tempBatchSize}
                    batchIndex={tempBatchIndex}
                    handleCancel={() => {
                        handleClose()
                        setOpenSkipConfirmDialog(false)
                    }}
                    handleSkip={() => {
                        setOpenSkipConfirmDialog(false);
                        setBatchSize(tempBatchSize)
                        setBatchIndex(tempBatchIndex)
                        dispatch(updateFrontendQABatchGroup({
                            id: tempBatchGroupId,
                            batchSize: tempBatchSize,
                            status: 'skipped',
                            completedBatchSize: tempBatchIndex
                        })).then(({ payload: { status } }) => {
                            if(status){
                                dispatch(updateFrontendQABatch({
                                    id: tempReportId,
                                    type: 3,
                                    feedSource,
                                    databaseCode,
                                    status: 'skipped',
                                    batchSize: tempBatchSize,
                                    batchIndex: tempBatchIndex,
                                    batchGroupId: tempBatchGroupId
                                })).then(({ payload: { row: batchData } }) => {
                                    if(batchData.id){
                                        dispatch(showSnackbarMessage({
                                            message: `Batch (${tempBatchIndex}/${tempBatchSize}) skipped successfully.`,
                                            severity: 'success'
                                        }))
                                        setShowConfirm(false)
                                        if(tempBatchIndex < tempBatchSize){
                                            onRunByBatch(tempBatchGroupId, tempBatchIndex + 1, tempBatchSize)
                                        }else{
                                            setScore(tempScore)
                                            setTempScore(0)
                                            setTempReportId(null)
                                            setTempBatchGroupId(null)
                                            setTempBatchIndex(0)
                                            setLoading(false)
                                        }
                                    }else{
                                        dispatch(showSnackbarMessage({
                                            message: `Something went wrong in skipping batch. Please kindly try again later!`,
                                            severity: 'error'
                                        }))
                                    }
                                })
                            }else{
                                dispatch(showSnackbarMessage({
                                    message: `Something went wrong in skipping batch. Please kindly try again later2!`,
                                    severity: 'error'
                                }))
                            }
                        })
                    }}
                    handleRun={() => {
                        setOpenSkipConfirmDialog(false)
                        if (tempBatchGroupId && tempBatchIndex) {
                            setBatchSize(tempBatchSize);
                            onRunByBatch(tempBatchGroupId, tempBatchIndex, tempBatchSize)
                        }
                    }}
                />
            }
        </>
    );
};

StartQADialog.propTypes = {
};

export default StartQADialog
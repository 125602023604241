import * as React from 'react';

import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Stack,
  Popover,
  ButtonGroup,
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px"
  },
  TableCap: {
    padding: '13px',
    alignItems: 'center',
    background: 'white',
    boxShadow: '14px 6px 4px -21px rgba(0,0,0,0.2), 0px -4px 2px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  Table: {
    marginTop: '9px',
    background: 'white'
  },
  TableTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '140%',
    margin: '0px',
    fontSize: '14px',
    letterSpacing: '-0.006em',
    fontWeight: '600',
    color: 'rgb(67, 74, 84)',
    marginRight: '10px'
  },
  th: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 1,
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor: '#1d8cf81f'
  },
  Popover: {
    '& .MuiPaper-root': {
      transform: 'translateY(4px) !important',
    }
  },
  retryLink: {
    textDecoration: 'underline'
  }
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export default function ResultStatusArea({
  selectedRangeId,
  total,
  completedCount,
  results,
  ongoingQACount,
  onClickConfirm,
  onClickRange,
  onClickRetry,
  onClickResume
}) {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [anchorRangeId, setAnchorRangeId] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorRangeId(null)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const reportData = React.useMemo(() => {
    return Object.values(results)
  }, [
    results
  ])
  // console.log(results, 'results')

  return (
    <>
    <TableContainer className={classes.root}>
      <Box display="flex" sx={{ justifyContent: 'space-between' }} className={classes.TableCap}>
        <Box display={"flex"} alignItems="center" justifyContent={'space-between'}>
          <Box display={"flex"} alignItems="center">
            <Typography className={classes.TableTitle}>Status of Ongoing  {ongoingQACount} QAs</Typography>
            <Typography className={classes.TableTitle}>{`(${completedCount}/${total})`}</Typography>
          </Box>
        </Box>
      </Box>
      <Table sx={{ minWidth: 650 }} className={classes.Table} aria-label="qa report table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.th}>Range Percentage</TableCell>
            <TableCell className={classes.th}>Started At</TableCell>
            <TableCell className={classes.th}>% Completed</TableCell>
            <TableCell className={classes.th}>No Error/Error</TableCell>
            <TableCell className={classes.th}>Good</TableCell>
            <TableCell className={classes.th}>Average</TableCell>
            <TableCell className={classes.th}>Below Average</TableCell>
            <TableCell className={classes.th}>Score</TableCell>
            <TableCell className={classes.th}>Note</TableCell>
            <TableCell className={classes.th}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            reportData && reportData.length > 0 ? (
              <>
                {
                  reportData.map((r, idx) => (
                    <>
                      <TableRow
                        key={idx}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                        onClick={() => onClickRange(r.id)}
                        className={selectedRangeId === r.id ? classes.highlight : ''}
                      >
                        <TableCell>
                          <span>{r.rangePercentage}</span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Tooltip title="Created At">
                            <span>
                              {new Date(r.created_at).toLocaleDateString(undefined, {
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                              })}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <BorderLinearProgress variant="determinate" value={Number(r.completedPercentage || 0)} />
                            <Box display="flex" sx={{ justifyContent: 'space-between' }}>
                              <span>{`${r.completedPercentage || 0}%`}</span>
                              <span>{`${r.completedProducts || 0}/${r.rangeProducts}`}</span>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="No Error/Error">
                            <Box display={'flex'}>
                              <Typography sx={{ color: '#1976d2' }}>{r.pass_count}</Typography>/<Typography sx={{ color: '#df3838' }}>{r.fail_count}</Typography>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Count of Good">
                            <Box display={'flex'}>
                              <Typography sx={{ color: '#1976d2' }}>{r.count_of_good}</Typography>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Count of Average">
                            <Box display={'flex'}>
                              <Typography sx={{ color: '#1976d2' }}>{r.count_of_average}</Typography>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Count of Below Average">
                            <Box display={'flex'}>
                              <Typography sx={{ color: '#1976d2' }}>{r.count_of_below_average}</Typography>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {r.score ? Number(r.score).toFixed(2) : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <p style={{ maxWidth: '200px' }}>
                            {r.note}
                          </p>
                        </TableCell>
                        <TableCell>
                          {
                            r.paused_product && !r.loading ? (
                              <Button
                                size="small"
                                color="error"
                                variant='outlined'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  onClickResume({ uid: r.paused_product }, r.id, r.range_index)
                                }}
                              >
                                Resume
                              </Button>
                            ) : (
                              r.neededToConfirm && !r.loading ? (
                                <Button
                                  size="small"
                                  color="error"
                                  variant='outlined'
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    onClickConfirm({ uid: r.start_product }, r.id, r.range_index)
                                  }}
                                >
                                  Continue
                                </Button>
                              ) : (
                                r.loading ?
                                  <span>Processing...</span> :
                                  <span
                                    size="small"
                                    className={`mr-1 ${classes.retryLink}`}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleClick(e)
                                      setAnchorRangeId(r.id)
                                    }}
                                  >Retry</span>
                              )
                            )
                          }

                        </TableCell>
                      </TableRow>
                      {
                        r.loading && (
                          <TableRow key={`i-${idx}`}>
                            <TableCell colSpan={10} sx={{
                              padding: '0 !important',
                              border: 'none !important',
                              position: 'relative',
                              bottom: '1px',
                            }}>
                              <Stack sx={{ width: '100%', color: 'grey.500', height: '1px' }} spacing={2}>
                                <LinearProgress color="success" />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    </>
                  ))
                }
              </>
            ) : (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={10}>
                  There is no Data
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className={classes.Popover}
    >
      <ButtonGroup variant="text" aria-label="Basic button group">
        <Button onClick={() => {
          onClickRetry(anchorRangeId, 'all')
          handleClose()
        }}>All</Button>
        <Button onClick={() => {
          onClickRetry(anchorRangeId, 'errors')
          handleClose()
        }}>Fix Errors</Button>
      </ButtonGroup>  
    </Popover>
    </>
  );
}
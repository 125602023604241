import React, { useMemo } from "react";

import {makeStyles} from "@mui/styles";
import {removeObjectProperties} from "shared/utilities/common";
import MUIDataTable from "mui-datatables";

const useStyles = makeStyles(theme => ({
  bold: { fontWeight: "bold" },
  italic: { fontStyle: "italic" },
  underline: { textDecoration: "underline" }
}));

export default function CustomMuiDataTable(_props) {
  const classes = useStyles();
  const props = {..._props};

  let { columns } = props;
  removeObjectProperties(props, ['columns'])
  
  const memoTransformedColumns = useMemo(() => {
    let transformedColumns = [];
    columns.forEach(column =>{

        let { options } = column;
        removeObjectProperties(column, ['options' ])
        let bVisible = true;

        if(bVisible)
          transformedColumns.push({...column, options:{...options}});
      });

      return transformedColumns;
    }, [columns]);
  

  return (
    <>
      { 
        <MUIDataTable
          {...props}
          columns={memoTransformedColumns}
        />
      }
    </>
  );
}
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import API from 'store/services/AxiosService';
import { EOD_SEARCH, EOD_SEARCH_SUGGESTIONS } from 'store/UrlConstants'
import axios from 'axios'
import { removeObjectProperties } from 'shared/utilities/common';

var CancelToken = axios.CancelToken;
let fetchSearchDataSource;

createEntityAdapter()

export const fetchSuggestionData = createAsyncThunk(EOD_SEARCH_SUGGESTIONS, async (params) => {
    let url = EOD_SEARCH_SUGGESTIONS
    const { data: suggestions } = await API.get(url, { params })
    return suggestions
})

export const fetchSearchData = createAsyncThunk(EOD_SEARCH, async (params) => {
    if (fetchSearchDataSource) {
        fetchSearchDataSource.cancel()
    }
    fetchSearchDataSource = CancelToken.source();
    let url = EOD_SEARCH
    const { global = true } = params
    removeObjectProperties(params, ['global'])
    const response = await API.get(
        url, {
        params: params,
        cancelToken: fetchSearchDataSource.token
    })
    const { data: suggestions } = response
    return {
        suggestions,
        global
    }
})

const initialState = {
    searchSuggestions: [],
    loadingInProgress: true,
    loadingSearch: false,
    searchResults: [],
}

const searchSlice = createSlice({
    name: 'Search',
    initialState,
    reducers: {
        cancleSearchStock(state, action) {
            if (fetchSearchDataSource) {
                fetchSearchDataSource.cancel()
            }
        },
    },
    extraReducers: {
        [fetchSuggestionData.pending]: (state, action) => {
            state.loadingInProgress = true
        },
        [fetchSuggestionData.fulfilled]: (state, action) => {
            if (action.payload) {
                state.news = action.payload
            }
            state.loadingInProgress = false
        },
        [fetchSuggestionData.rejected]: (state, action) => {
            state.loadingInProgress = false
        },
        [fetchSearchData.pending]: (state, action) => {
            state.loadingSearch = true
        },
        [fetchSearchData.fulfilled]: (state, action) => {
            const { suggestions, global } = action.payload
            if (suggestions) {
                let formatted = []
                suggestions.forEach(element => {
                    let label = element.value.feed_source + '/' + element.value.database_code + '/' + element.value.product_code
                    element.value.label = label
                    formatted.push(element.value)
                })
                state.searchResults = formatted
            }
            state.loadingSearch = false
        },
        [fetchSearchData.rejected]: (state, action) => {
            state.loadingSearch = false
        },
    },
})
export const {
    cancleSearchStock
} = searchSlice.actions
export const prepareSelector = state => state.Search
export default searchSlice.reducer

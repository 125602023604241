import { createSelector } from "@reduxjs/toolkit";

export const selectProductDataByPath = createSelector(
    (state) => state.productData?.loadedProductData,
    (state, params) => params,
    (data, { feedSource, databaseCode, productCode, blob, timeIncrement }) => {
        if (typeof data === "undefined")
            return {};
        let path = `${blob === 'big-query' ? 'bg/' : ''}${feedSource}/${databaseCode}/${productCode}`
        if (timeIncrement) {
            path += `/${timeIncrement}`
        }
        return data[path] || []
    }
);
export const selectProductDataByRawPath = createSelector(
    (state) => state.productData,
    (state, params) => params,
    (data, { feedSource, databaseCode, productCode, blob }) => {
        if (typeof data === "undefined")
            return {};
        const { loadedProductData = {}, timeIncrements = []} = data
        let path = `${blob === 'big-query' ? 'bg/' : ''}${feedSource}/${databaseCode}/${productCode}`
        let matchingData = {}
        if(timeIncrements){
            for(let timeIncrement of timeIncrements){
                let _key = path;
                if(timeIncrement && timeIncrement !== ''){
                    _key = `${path}/${timeIncrement}`
                }
                if(loadedProductData[_key]){
                    matchingData[timeIncrement] = loadedProductData[_key]
                }
            }
        }else{
            matchingData = loadedProductData[path] || []
        }
        return matchingData
    }
);

export const selectBEAProductDataByPath = createSelector(
    (state) => state.productData.loadedProductData,
    (state, params) => params,
    (data, { feedSource, databaseCode, productCode, blob }) => {
        if (typeof data === "undefined")
            return {};
        let path = `${blob === 'big-query' ? 'bg/' : ''}${feedSource}/${databaseCode}/${productCode}`
        return data[path] || {
            lines: [],
            years: [],
            data: [],
            areas: [],
            series: [],
            transformColumns: [],
            transformRows: []
        }
    }
);

export const selectTimeIncrements = createSelector(
    (state) => state.productData.timeIncrements,
    (state, params) => params,
    (data, params) => {
        return data || []
    }
);
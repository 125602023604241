import { configureStore } from '@reduxjs/toolkit';

import localStorage from "./middlewares/LocalStorage";
import rejectionHandler from "./middlewares/RejectionHandler";

import messagesSystemReducer from './slices/MessagesSystemSlice';
import appMessageReducer from './slices/AppMessageSlice';
import authenticationReducer from './slices/AuthenticationSlice';

import UserReducer from './slices/UserSlice';
import AppLayoutReducer from './slices/AppLayoutSlice';
import EodDataReducer from './slices/EodDataSlice';
import ChartDataReducer from './slices/ChartDataSlice';
import RragChartDataReducer from './slices/RragChartDataSlice';
import LightWeightChartDataReducer from './slices/LightWeightChartDataSlice';
import HighChartDataReducer from './slices/HighChartDataSlice';
import StockProductReducer from './slices/StockProductSlice';
import SummaryDataReducer from './slices/SummaryDataSlice';
import ComponentReducer from './slices/ComponentSlice'
import NewsReducer from './slices/News'
import SearchReducer from './slices/SearchSlice'
import FrontendQAReducer from './slices/FrontendQA';

// const ignoredPaths = ["security", "home", "universe", "perso"];
const ignoredPaths = [];
const ignoredActionPaths = [];

export default configureStore({
  reducer: {
    messagesSystem: messagesSystemReducer,
    // appMessage: appMessageReducer,
    authentication: authenticationReducer,
    appLayout: AppLayoutReducer,
    eodData: EodDataReducer,
    chartData: ChartDataReducer,
    rragChartData: RragChartDataReducer,
    lightWeightChartData: LightWeightChartDataReducer,
    highChartData: HighChartDataReducer,
    productData: StockProductReducer,
    news: NewsReducer,
    summaryData: SummaryDataReducer,
    user: UserReducer,
    componentData: ComponentReducer,
    search: SearchReducer,
    frontendQA: FrontendQAReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: {
      ignoredPaths: ignoredPaths
    }
  }).concat(localStorage, rejectionHandler)
})

import React from "react"

import { getAuth, signInWithPopup, GoogleAuthProvider} from "firebase/auth";
import {useHistory} from "react-router-dom"
import LocalStorageService from "../../store/services/LocalStorageService"
import { useDispatch } from "react-redux";
import { setActiveAllStep1Sidebar } from "store/slices/AppLayoutSlice";
const googleProvider = new GoogleAuthProvider();
const storageService = LocalStorageService.getService()
googleProvider.setCustomParameters({login_hint: storageService.getDefaultGoogleUser()})

export default function GoogleLogin(props) {
  const history = useHistory()
  const dispatch = useDispatch()

  function doGoogleLogin() {
    try {
      const auth = getAuth()
      signInWithPopup(auth, googleProvider)
      .then((result) => {
        const { user: {
          uid,
          accessToken
        }} = result
        storageService.setPlotterUID(uid)
        storageService.setAccessToken(accessToken)
        const _rememberedPath = storageService.getRememberedPath()
        if(_rememberedPath && _rememberedPath !== ''){
          history.replace({pathname: _rememberedPath});
          storageService.clearRememberedPath()
        }else{
          history.push('/')
        }
        // dispatch(setActiveAllStep1Sidebar(true))
        storageService.addLogCount()
      }).catch((error) => {
        console.error(error)
      })
    }catch(e){
      console.log(e, 'firebase authorization')
    }
  }

  return (
    <>
      <div className="login-btns">
        <a href="#" className={`btn btn-lg btn-block mb-3 loginBtn`} onClick={doGoogleLogin}>Google login</a>
      </div>
    </>
  );
}

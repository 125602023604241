import React, { useState, useEffect } from "react";
import {
    Tooltip,
    styled,
} from "@mui/material";
import { tooltipClasses } from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }) => {
    const [open, setOpen] = useState(false)
    useEffect(() => {
        setOpen(props.open)
    }, [props.open])
    return <Tooltip
        {...props}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        arrow
        classes={{ popper: className }}
    />
})(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        // border: '1px solid #dadde9',
        transform: 'translateX(-35px) !important',
        padding: '12px 10px',
        borderRadius: '7px',
        boxShadow: '1px 1px 9px -3px #717171'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
        // border: '1px solid #dadde9',
    },
}));

export default StyledTooltip